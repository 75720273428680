import { API, encode } from "@lysaab/ui-2";
import { InvestmentAccountId, SavingsAccountId } from "./dataAccounts";
import { Isin } from "./dataFunds";
import { FundTransactionType } from "./dataTransactions";
import { LegalEntityId } from "./dataUser";

enum TaxType {
  ISK = "ISK",
}

export interface IskTax {
  taxType: TaxType.ISK;
  id: string;
  legalEntityId: LegalEntityId;
  accountId: InvestmentAccountId;
  year: string;
  q1: number;
  q2: number;
  q3: number;
  q4: number;
  deposits: number;
}

export interface IskTaxResponse extends IskTax {
  // Stadslåneräntan
  slr: number;
  // Scablonbeskattnigen
  taxMultiplier: number;
  taxMultiplierIncrement: number;
  // Kapitalunderlag
  taxableCapital: number;
  // Schablonintäkt
  taxableIncome: number;
}

export interface FiTaxHoldingsResponse {
  holdings: FiTaxHolding[];
  taxYear: number;
}

export interface FiTaxFeesResponse {
  fees: number;
  taxYear: number;
}

export interface FiTaxHolding {
  shares: number;
  isin: Isin;
  navPrice: number;
  worth: number;
  worth70pct: number;
  navTradeDate: string;
}

export interface FiTaxRedemptionsResponse {
  redemptionParts: RedemptionPart[];
  taxYear: number;
}

export interface RedemptionPart {
  id: string;
  isin: string;
  redemptionAccountId: string;
  redemptionAmount: number;
  redemptionDate: string;
  redemptionOrderId: string;
  redemptionPrice: number;
  redemptionVolume: number;
  subscriptionAccountId: string;
  subscriptionAmount: number;
  subscriptionDate: string;
  subscriptionOrderId: string;
  subscriptionPrice: number;
}

export interface DkTaxDetails {
  isin: Isin;
  realizedCapitalIncome: DkTaxRealized[];
}

export interface DkTaxRealized {
  accountId: InvestmentAccountId;
  isin: Isin;
  referenceNumber: string;
  taxYear: number;
  tin: string;
  volume: number;
  fundTransactionType: FundTransactionType;
  orderId: string;
  tradeDate: string;
  valueDate: string;
  amount: number;
}

export interface DkTaxSummary {
  isin: Isin;
  acquisitionValue: number;
  gainLoss: number;
  totalValue: number;
  volume: number;
  initialValue: number;
}
export interface DeTaxResponse {
  taxYear: number;
  taxInformationByIsins: DeTaxInformation[];
}
interface DeTaxInformation {
  isin: Isin;
  netProfit: number;
  vap: number;
  redemptionParts: RedemptionPartResponse[];
  subVapParts: SubVapPartResponse[];
}

export interface RedemptionPartResponse {
  id: number;
  isin: Isin;
  subscriptionOrderId: number;
  subscriptionAccountId: number;
  subscriptionDate: string;
  subscriptionPrice: number;
  redemptionOrderId: string;
  redemptionAccountId: string;
  redemptionDate: string;
  redemptionVolume: number;
  redemptionPrice: number;
  preTaxedVap: number;
  taxableGainLoss: number;
}
export interface SubVapPartResponse {
  id: number;
  isin: Isin;
  taxTear: number;
  legalEntityId: LegalEntityId;
  subscriptionDate: string;
  shares: number;
  vapPerFundUnit: number;
  totalVap: number;
  redemptionDate?: string;
}

export interface SavingsAccountTaxDetail {
  year: number;
  legalEntityId: LegalEntityId;
  accountId: SavingsAccountId;
  interestIncome: number;
}

export interface TaxAccountYears {
  accountId: InvestmentAccountId;
  taxYears: number[];
}

export const dataTax = {
  getIskTaxYears: () => {
    return API.get<TaxAccountYears[]>("/tax/isk/years");
  },
  getSavingsAccountTaxYears: () => {
    return API.get<number[]>("/tax/savings/year");
  },
  getIskTax: (year: number, accountId: InvestmentAccountId) => {
    return API.get<IskTaxResponse>(
      encode`/tax/isk?taxYear=${year}&accountId=${accountId}`
    );
  },
  getSavingsAccountTax: (year: number) => {
    return API.get<SavingsAccountTaxDetail[]>(
      encode`/tax/savings?year=${year}`
    );
  },
  getFiTaxYears: () => {
    return API.get<string[]>(encode`/tax/fi/years`);
  },
  getFiTaxHoldings: (year: string) => {
    return API.get<FiTaxHoldingsResponse>(
      encode`/tax/fi/holdings?taxYear=${year}`
    );
  },
  getFiTaxFees: (year: string) => {
    return API.get<FiTaxFeesResponse>(encode`/tax/fi/fees?taxYear=${year}`);
  },
  getFiTaxRedemptions: (year: string) => {
    return API.get<FiTaxRedemptionsResponse>(
      encode`/tax/fi/subred?taxYear=${year}`
    );
  },
  getDkTaxYears: () => {
    return API.get<TaxAccountYears[]>("/tax/dk/years");
  },
  getDkTaxSummary: (year: number) => {
    return API.get<DkTaxSummary[]>(encode`/tax/dk/summary?taxYear=${year}`);
  },
  getDkTaxDetails: (year: number) => {
    return API.get<DkTaxDetails[]>(encode`/tax/dk/details?taxYear=${year}`);
  },
  getDeTaxYears: () => {
    return API.get<number[]>("/tax/de/years");
  },
  getDeTaxDetails: (year: number) => {
    return API.get<DeTaxResponse>(
      encode`/tax/de/tax-information?taxYear=${year}`
    );
  },
};
