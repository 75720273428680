import { Page } from "../../../../../../pages/Page";
import {
  GridRow,
  JustifyContentValues,
} from "../../../../../../components/grid/gridRow/GridRow";
import { GridCol } from "../../../../../../components/grid/gridCol/GridCol";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { Link } from "react-router-dom";
import { MIGRATION_REQUEST_KF_PAGE_URL } from "../request/MigrationKfRequestPage";

export const MIGRATION_KF_INFO_PAGE_URL = "/migration/kf/info";

export const MigrationInfo = () => {
  return (
    <Page>
      <GridRow justifyContent={JustifyContentValues.CENTER}>
        <GridCol xsmall={12} medium={6}>
          <h2>
            Kapitalförsäkring i egen regi - möjlighet att byta från Futur
            Pension
          </h2>
          <p>
            Nu kan Lysa erbjuda försäkringssparande från det egna
            försäkringsbolaget - Lysa Life Försäkring AB (Lysa Life). Först ut
            är Lysas företagsägda kapitalförsäkring.
          </p>
          <p>
            Med ett eget försäkringsbolag kan vi på ett mer effektivt sätt både
            möta våra kunders efterfrågan om nya produkter och göra
            förbättringar i de produkter som vi redan erbjuder. Som företagare
            har du kunnat spara med Lysa i en kapitalförsäkring sedan 2020 via
            vår externa samarbetspartner Futur Pension.
          </p>
          <p>
            Från och med januari 2024 kommer Lysa, via det egna
            försäkringsbolaget Lysa Life, istället kunna erbjuda
            kapitalförsäkring i egen regi. Företagaren tecknar produkten i
            inloggat läge på lysa.se i ett enkelt och digitaliserat flöde.
          </p>
          <p>
            Avgiften för kapitalförsäkringen är 0,2% av det förvaltade
            kapitalet, vilket är samma kostnad som när vi samarbetade med Futur.{" "}
          </p>
          <h4>
            Erbjudande till befintliga kunder att “flytta” sin försäkring till
            Lysa Life över årsskiftet
          </h4>
          <p>
            På grund av hur skatten tas ut ur kapitalförsäkringar är det bra att
            byta försäkringsbolag över ett årsskifte. Lysa har tagit fram en
            enkel metod för dig som vill byta försäkringsbolag till Lysa Life
            där vi tar hand om hela processen och där du som firmatecknare
            skriver under med Mobilt BankID.
          </p>
          <p>
            Lysa kommer att sälja dina fondandelar i din “Futur-försäkring” runt
            den 20:e december och återinvestera dem i din nya försäkring i
            början av januari. Inga pengar kommer att betalas ut till ditt
            företags bankkonto, utan de förvaras på ett klientmedelskonto hos
            Lysa AB över årsskiftet.{" "}
          </p>
          <p>
            Åtgärden medför inte någon skatteeffekt men företaget kommer att
            vara utanför marknaden ett antal dagar runt jul och nyår. Det
            innebär att företaget inte tar del av kursrörelser - vare sig
            positiva eller negativa. Företaget behöver dessutom i bokföringen
            ange att pengarna fanns placerade på ett bankkonto. Lysa kommer att
            tillhandahålla ett underlag till bokföringen.{" "}
          </p>
          <p>
            Kunder som genomför flytten innan den 15:e december 2024 kommer att
            få hela försäkringsavgiften (0,2%) rabatterad för helåret 2024 -
            <strong>
              försäkringen kommer alltså att vara kostnadsfri hela nästa år.
            </strong>
          </p>
          <p>
            I samband med årsskiftet 2024/2025 upphör samarbetet med Futur och
            försäkringar som då inte ännu har flyttats till Lysa Life kommer att
            avslutas och pengarna kommer att betalas ut till företagets
            bankkonto.{" "}
          </p>
          <Link to={getNavLink(MIGRATION_REQUEST_KF_PAGE_URL)}>
            Tillbaka till flyttanmälan
          </Link>
        </GridCol>
      </GridRow>
    </Page>
  );
};
