const MINIMUM_MONTHS_COVERAGE = 6;

export default class SuitabilityAssessmentUtils {
  static isValid(
    liquidAssets: number,
    monthlyPayments: number,
    otherAssets: number,
    debts: number,
    monthlyEarnings: number
  ) {
    if (monthlyPayments === 0 || monthlyEarnings === 0) {
      return false;
    }

    return (
      !this.hasInsufficientBuffert(liquidAssets, monthlyPayments) &&
      !this.hasInsufficientMeans(
        liquidAssets,
        otherAssets,
        debts,
        monthlyEarnings
      ) &&
      !this.isRunningOutOfMeans(liquidAssets, monthlyEarnings, monthlyPayments)
    );
  }

  static hasInsufficientBuffert(liquidAssets: number, monthlyPayments: number) {
    if (liquidAssets === 0) {
      return true;
    }

    if (monthlyPayments === 0) {
      return false;
    }

    const savingsBuffertFactor = liquidAssets / monthlyPayments;
    if (savingsBuffertFactor >= 1) {
      return false;
    }

    return true;
  }

  static hasInsufficientMeans(
    liquidAssets: number,
    otherAssets: number,
    debts: number,
    monthlyEarnings: number
  ) {
    const netWorth = liquidAssets + otherAssets - debts;

    if (netWorth >= 0) {
      return false;
    }

    const montlySalariesToRepayDebt = Math.abs(netWorth) / monthlyEarnings;
    if (montlySalariesToRepayDebt <= 6) {
      return false;
    }

    return true;
  }

  static isRunningOutOfMeans(
    liquidAssets: number,
    monthlyEarnings: number,
    monthlyPayments: number
  ) {
    if (monthlyEarnings > monthlyPayments) {
      return false;
    }

    const monthlyNetDebt = Math.abs(monthlyEarnings - monthlyPayments);
    const monthsBeforeMonthlyDeptOutrunsAssets = liquidAssets / monthlyNetDebt;

    if (monthsBeforeMonthlyDeptOutrunsAssets >= MINIMUM_MONTHS_COVERAGE) {
      return false;
    } else {
      return true;
    }
  }
}
