import { API, cache, encode } from "@lysaab/ui-2";
import { Language } from "../context/LocalizationContext";
import { GET_BASE_WITHDRAWAL_URL } from "./dataWithdrawals";
import { AccessRightsId } from "./dataUser";
import { LysaCountry } from "@lysaab/shared";
import { dataLegalEntity } from "./dataLegalEntity";
import { dataUserState } from "./dataUserState";
import { useLoadSignInExperiments } from "../experimentConfig";
import { useCallback } from "react";
import { queryClient } from "../ReactQueryProvider";

export type ResetId = string & { readonly isResetId: true };

export enum LegalEntityType {
  PERSON = "PERSON",
  CORPORATION = "CORPORATION",
}

export enum InitVerificationResult {
  /**
   * Misslyckades att logga in, säg inget annat
   */
  FAILED = "FAILED",
  /**
   * Nåt gick fel, nån tjänst är nere
   */
  ERROR = "ERROR",
  /**
   * Försökt för många gånger, måste vänta lite
   */
  LOGIN_TEMP_LOCKED = "LOGIN_TEMP_LOCKED",
  /**
   * Hammrat på APIt, måste lugna ner sig. Kan vara abuse
   */
  RATE_LIMIT = "RATE_LIMIT",
  /**
   * Ange email-koden
   */
  EMAIL_CODE = "EMAIL_CODE",
  /**
   * Ange TOTP koden
   */
  TOTP_CODE = "TOTP_CODE",
}

export enum VerificationResult {
  /**
   * Allt gick bra, det finns en cookie med i svaret
   */
  SUCCESS = "SUCCESS",
  /**
   * Cool your pants
   */
  RATE_LIMIT = "RATE_LIMIT",
  /**
   * Misslyckades att logga in, säg inget annat
   */
  FAILED = "FAILED",
  /**
   * Nåt gick fel, nån tjänst är nere
   */
  ERROR = "ERROR",
}

export enum IbanValidationResult {
  SUCCESS = "SUCCESS",
  INVALID = "INVALID",
  INCORRECT_LENGTH = "INCORRECT_LENGTH",
  INCORRECT_COUNTRY = "INCORRECT_COUNTRY",
  UNSUPPORTED = "UNSUPPORTED",
}

export interface BootstrapResponse {
  readOnly: boolean;
  impersonator?: string;
  multipleRights: boolean;
  features?: [];
}

interface InitOtpResponse {
  result: InitVerificationResult;
}

export interface VerifyOtpResponse<T = void> {
  result: VerificationResult;
  explanation?: T;
}

export interface LysaRight {
  name: string;
  accessRightsId: AccessRightsId;
  primary: boolean;
  active: boolean;
  tin: string;
}

export enum VerificationType {
  SINGLE_EXTERNAL_ACCOUNT = "SINGLE_EXTERNAL_ACCOUNT",
}

interface ExternalAccountVerification {
  verificationScenario: VerificationType.SINGLE_EXTERNAL_ACCOUNT;
  payload: string;
}

/**
 * For a correct typing off all types of verification data
 * just add on all the above types into the VerificationData type
 */
type VerificationData = ExternalAccountVerification;

export enum ChangePasswordResult {
  OK = "OK",
  MISMATCH = "MISMATCH",
  FAILED = "FAILED",
}

interface ResetTotpResponse {
  secret: string;
  signature: string;
}

interface ResetTotpConfirmResponse {
  signedSecret: string;
}

interface ResetLoginRequest {
  loginId: string;
  country: LysaCountry;
  signedEmail: string;
  signedSecret?: string;
  password: string;
}

interface ChangePasswordResponse {
  result: ChangePasswordResult;
}

export enum LoginResetStatus {
  /** Authentication reset not found */
  NOT_FOUND = "NOT_FOUND",
  /** Waiting on Onfido to validate photos sent in by customer */
  ONFIDO_PENDING = "ONFIDO_PENDING",
  /**
   * Waiting on Onfido's manual review on documentation sent in by customer.
   * This could take up to 24h.
   */
  ONFIDO_PENDING_MANUAL_REVIEW = "ONFIDO_PENDING_MANUAL_REVIEW",
  /** Undefined error from Lysa's Onfido service */
  ONFIDO_FAILED = "ONFIDO_FAILED",
}

interface ResetStatusResponse {
  status: LoginResetStatus;
}

export enum TwoFaType {
  EMAIL = "EMAIL",
  TOTP = "TOTP",
}

interface ChangeTotpAvailableResponse {
  availableTwoFaType: TwoFaType[];
}

interface ChangeTotpConfirmRequest {
  code: string;
  secret: string;
  signature: string;
}

interface ChangeTotpFinalizeRequest {
  signedTotpSecret: string;
}

type UsernameResponse = string;

export const dataLogin = {
  getBootstrap: () => {
    return API.get<BootstrapResponse>(
      encode`/login/bootstrap?hash=${process.env.REACT_APP_GIT_HASH}`,
      true
    );
  },

  keepAlive: () => {
    return API.get("/login/keep-alive");
  },

  refresh: () => {
    cache.delete("/login/keep-alive");
    return API.get("/login/keep-alive");
  },

  login: (username: string, password: string, country: LysaCountry) => {
    return API.post<InitOtpResponse>("/login/username", {
      username,
      password,
      country,
    });
  },
  getUsername: () => {
    return API.get<UsernameResponse>("/login/username");
  },
  loginEmail: (username: string, country: LysaCountry, code: string) => {
    return API.post<VerifyOtpResponse>("/login/username/email", {
      username,
      country,
      code,
    });
  },
  loginTotp: (username: string, country: LysaCountry, code: string) => {
    return API.post<VerifyOtpResponse>("/login/username/totp", {
      username,
      country,
      code,
    });
  },
  impersonateUser: (token: string) => {
    return API.get("/login/impersonation?token=" + token).then(() => {
      cache.clear();
      queryClient.removeQueries();
    });
  },
  // urlPostfix is a "temporary" (== will stay forever) quick fix for Finland.
  // Agreed with Möller and Clara to just find the fastest solution. It's
  // equally bad on the BE and we all want to get rid of this as soon as
  // possible
  postDataWithVerification: (data: VerificationData, urlPostfix = "") => {
    return API.post<InitOtpResponse>(
      "/login/username/2fa-verification" + urlPostfix,
      {
        verificationScenario: data.verificationScenario,
        payload: data.payload,
      }
    ).then((resp) => {
      if (
        data.verificationScenario === VerificationType.SINGLE_EXTERNAL_ACCOUNT
      ) {
        cache.delete(GET_BASE_WITHDRAWAL_URL);
        return resp;
      }
    });
  },
  verifyEmailCode<T = void>(code: string) {
    return API.post<VerifyOtpResponse<T>>(
      "/login/username/2fa-verification/email",
      { code }
    );
  },
  verifyTotpCode<T = void>(code: string) {
    return API.post<VerifyOtpResponse<T>>(
      "/login/username/2fa-verification/totp",
      { code }
    );
  },
  getRights() {
    return API.get<LysaRight[]>(`/login/rights`);
  },
  switchUser: (id: string) => {
    return API.post<undefined>("/login/switch", {
      accessRightsId: id,
    }).then((response) => {
      cache.clear();
      queryClient.removeQueries();
      return response;
    });
  },

  resetLogin: ({
    loginId,
    country,
    signedEmail,
    signedSecret,
    password,
  }: ResetLoginRequest) => {
    return API.post<ResetId>("/login/username/reset", {
      loginId,
      country,
      login: {
        signedEmail,
        signedSecret,
        password,
      },
    });
  },

  resetStatus: (resetId: ResetId) => {
    const url = encode`/login/username/reset/status?authenticationResetId=${resetId}`;
    return API.get<ResetStatusResponse>(url, true);
  },

  passwordChange: (currentPassword: string, newPassword: string) => {
    const url = encode`/login/username/change/password`;
    return API.post<ChangePasswordResponse>(url, {
      existingPassword: currentPassword,
      newPassword,
    });
  },

  changeTotpAvailable: () => {
    return API.get<ChangeTotpAvailableResponse>(
      "/login/username/change/totp/available"
    );
  },

  changeTotp: () => {
    return API.get<ResetTotpResponse>("/login/username/change/totp");
  },

  changeTotpConfirm: (requestData: ChangeTotpConfirmRequest) => {
    return API.post<ResetTotpConfirmResponse>(
      "/login/username/change/totp/confirm",
      requestData
    );
  },

  changeTotpFinalize: (requestData: ChangeTotpFinalizeRequest) => {
    return API.post<{}>("/login/username/change/totp", requestData);
  },

  changePrimaryLogin: (accessRightsId: AccessRightsId) => {
    return API.post<LysaRight[]>("/login/primary", { accessRightsId });
  },

  usernameReminder: (
    email: string,
    birthday: string,
    country: LysaCountry,
    language: Language
  ) => {
    return API.post("/login/username/remind", {
      email,
      birthday,
      country,
      language,
    });
  },
};

/* Before our app is loaded, these must be done loading */
export const useBootstrapUser = ({ shouldFetchExperiments = true } = {}) => {
  const loadSignInExperiments = useLoadSignInExperiments();

  return useCallback(() => {
    return Promise.all([
      dataLogin.getBootstrap(),
      dataLegalEntity.getLegalEntity(),
      dataUserState.getUserStates(),
      shouldFetchExperiments && loadSignInExperiments(),
    ]);
  }, [loadSignInExperiments, shouldFetchExperiments]);
};
