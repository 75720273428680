import { StoryDisclaimer } from "../../../../../components/storyDisclaimer/StoryDisclaimer";
import { TranslatedText } from "../../../../../components/TranslatedText";

export const DisclaimerIskToSavings = () => {
  return (
    <StoryDisclaimer>
      <TranslatedText id="withdrawal.isk-to-savings.story.disclaimer" />
    </StoryDisclaimer>
  );
};
