import { useContext, useEffect, useRef, useState } from "react";
import {
  Card,
  Form,
  LysaFormRef,
  Button,
  ServerError,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { BASE_ROUTES } from "../MonthlyStory";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { LocalizationContext } from "../../../../../context/LocalizationContext";
import { dataDirectDebit } from "../../../../../data/dataDirectDebit";
import { EventTracker } from "../../../../../components/eventTracker/EventTracker";
import { MandateConfirm } from "./mandateConfirm/MandateConfirm";
import { useMonthlyContext } from "../MonthlyContext";
import { TrackerEvent } from "../../../../../data/dataCustomerTracking";
import { useIsReadOnly } from "../../../../../hooks/useIsReadOnly";

import "./Confirm.scss";

const messages = defineMessages({
  perMonth: {
    id: "deposits.monthly.story.confirm.amountPerMonth",
  },
});

interface Props {
  next: () => void;
}

export const Confirm = ({ next }: Props) => {
  const monthlyContext = useMonthlyContext();
  const localizationContext = useContext(LocalizationContext);
  const intl = useIntl();
  const history = useHistory();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showConsentConditions, setShowConsentConditions] = useState(false);
  const formRef = useRef<LysaFormRef>();
  const isReadOnly = useIsReadOnly();

  useEffect(() => {
    if (
      (typeof monthlyContext.state.externalAccount === "undefined" &&
        typeof monthlyContext.state.selectedKlarnaAccount === "undefined") ||
      typeof monthlyContext.state.selectedLysaAccount === "undefined" ||
      !monthlyContext.state.amount
    ) {
      return history.replace(getNavLink(BASE_ROUTES.INTRO));
    }
    dataDirectDebit
      .getLoadAllActiveMandates()
      .then((response) => {
        setShowConsentConditions(
          !response.some(
            (activeMandate) =>
              activeMandate.externalAccountNumber ===
              monthlyContext.state.externalAccount
          )
        );
      })
      .finally(() => setIsLoading(false));
  }, [
    history,
    monthlyContext.state.amount,
    monthlyContext.state.externalAccount,
    monthlyContext.state.selectedKlarnaAccount,
    monthlyContext.state.selectedLysaAccount,
  ]);

  const getSender = () => {
    if (
      typeof monthlyContext.state.selectedKlarnaAccount === "undefined" ||
      typeof monthlyContext.state.bank === "undefined"
    ) {
      return `${monthlyContext.state.externalAccount}`;
    } else {
      return `${monthlyContext.state.selectedKlarnaAccount.bankAccount}, ${
        monthlyContext.state.bank.displayName
      }${
        monthlyContext.state.selectedKlarnaAccount.name &&
        ` - ${monthlyContext.state.selectedKlarnaAccount.name}`
      }`;
    }
  };

  if (
    typeof monthlyContext.state.selectedLysaAccount === "undefined" ||
    typeof monthlyContext.state.amount === "undefined"
  ) {
    return null;
  }

  const confirmMonthlyPayment = () => {
    if (
      monthlyContext.state.amount &&
      monthlyContext.state.externalAccount &&
      typeof monthlyContext.state.selectedLysaAccount !== "undefined" &&
      monthlyContext.state.withdrawalDay
    ) {
      setIsLoading(true);
      dataDirectDebit
        .postAddMonthlyDeposit({
          amount: Number(monthlyContext.state.amount),
          externalAccountNumber: monthlyContext.state.externalAccount,
          accountId: monthlyContext.state.selectedLysaAccount.accountId,
          targetPaymentDay: monthlyContext.state.withdrawalDay,
        })
        .then(() => {
          next();
        })
        .catch((error: ServerError<unknown>) => {
          EventTracker.track({
            event: TrackerEvent.ERROR,
            message: error.message,
          });
          setIsError(true);
        })
        .finally(() => setIsLoading(false));
    }
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="monthly-story-confirm">
      <h2>
        <TranslatedText id="deposits.monthly.story.confirm.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (
            !formRef.current?.isValid ||
            isReadOnly ||
            !monthlyContext.state.externalAccount
          ) {
            return;
          }
          if (showConsentConditions) {
            setIsLoading(true);
            dataDirectDebit
              .postAddMandate(monthlyContext.state.externalAccount)
              .then(() => {
                confirmMonthlyPayment();
              })
              .catch(() => {
                setIsError(true);
              });
          } else {
            confirmMonthlyPayment();
          }
        }}
      >
        <Card>
          {isError && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="deposits.monthly.story.confirm.error" />
            </Snackbar>
          )}
          <dl>
            <div className="monthly-story-confirm__entry">
              <dt>
                <TranslatedText id="deposits.monthly.story.confirm.sender" />
              </dt>
              <dd>{getSender()}</dd>
            </div>
            <div className="monthly-story-confirm__entry">
              <dt>
                <TranslatedText id="deposits.monthly.story.confirm.receiver" />
              </dt>
              <dd>
                <span className="monthly-story-confirm__element--bold">
                  {monthlyContext.state.selectedLysaAccount.name}
                </span>{" "}
                -{" "}
                {intl.formatNumber(
                  monthlyContext.state.selectedLysaAccount.worth,
                  {
                    style: "currency",
                    currency: localizationContext.state.currency,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
              </dd>
            </div>
            <div className="monthly-story-confirm__entry">
              <dt>
                <TranslatedText id="deposits.monthly.story.confirm.amount" />
              </dt>
              <dd>
                {`${intl.formatNumber(Number(monthlyContext.state.amount), {
                  style: "currency",
                  currency: localizationContext.state.currency,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} / ${intl.formatMessage(messages.perMonth)}`}
              </dd>
            </div>
            <div className="monthly-story-confirm__entry">
              <dt>
                <TranslatedText id="deposits.monthly.story.confirm.day" />
              </dt>
              <dd>{monthlyContext.state.withdrawalDay}</dd>
            </div>
          </dl>
          <Snackbar type={SNACKBAR_TYPES.INFO} icon>
            <TranslatedText id="deposits.monthly.story.confirm.information" />
          </Snackbar>
        </Card>
        {showConsentConditions && <MandateConfirm />}
        <p>
          <TranslatedText id="deposits.monthly.story.confirm.nextText" />
        </p>
        <Button
          block
          type="submit"
          label={<TranslatedText id="deposits.monthly.story.confirm.next" />}
        />
      </Form>
    </div>
  );
};
