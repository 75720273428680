import { useEffect } from "react";
import { Store, DARK_MODE } from "../Store";

function setDarkMode(dark: boolean) {
  if (dark) {
    document.documentElement.setAttribute("data-theme", "dark");
  } else {
    document.documentElement.setAttribute("data-theme", "light");
  }
}

function darkModeEventListener(e: MediaQueryListEvent) {
  const dark = e.matches;

  if (Store.getValue(DARK_MODE) === "SYSTEM") {
    setDarkMode(dark);
  }
}

export const useDarkMode = () => {
  useEffect(() => {
    let dark = false;

    if (Store.getValue(DARK_MODE) === "SYSTEM") {
      dark = window.matchMedia("(prefers-color-scheme: dark)").matches;
    } else if (Store.getValue(DARK_MODE) === "DARK") {
      dark = true;
    }

    setDarkMode(dark);

    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    if (mediaQuery?.addEventListener) {
      mediaQuery.addEventListener("change", darkModeEventListener);
    } else {
      // Needed for older Safari on iOS
      // https://stackoverflow.com/a/75517985
      mediaQuery.addListener(darkModeEventListener);
    }

    return () => {
      if (mediaQuery?.removeEventListener) {
        mediaQuery.removeEventListener("change", darkModeEventListener);
      } else {
        mediaQuery.removeListener(darkModeEventListener);
      }
    };
  }, []);
};
