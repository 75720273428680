import { VoidFunctionComponent } from "react";
import { Page, PageHeader } from "../Page";
import { TranslatedText } from "../../components/TranslatedText";
import { HistoricTransactions } from "./historicTransactions/HistoricTransactions";
import { CompoundAccountId } from "../../data/dataAccounts";

export const HISTORIC_TRANSACTIONS_PAGE_URL = "/historic-transactions";

export interface HistoricTransactionsPageLocationState {
  accountId?: CompoundAccountId;
}

export const HistoricTransactionsPage: VoidFunctionComponent = () => (
  <Page>
    <PageHeader>
      <h1>
        <TranslatedText id="historicTransactionsPage.header" />
      </h1>
    </PageHeader>
    <HistoricTransactions />
  </Page>
);
