import { createContext } from "./CreateContext";
import {
  EligibilityCorporation,
  EligibilityPerson,
  isEligibilityCorporation,
  isEligibilityPerson,
  isValidEligibilityCorporation,
  isValidEligibilityPerson,
  RecursivePartial,
} from "../data/dataInvestments";
import { CorporationExpenses } from "../pageComponents/economicSituation/economicSituation/corporation/Expenses";
import { CorporationInsolvencyRisk } from "../pageComponents/economicSituation/economicSituation/corporation/InsolvencyRisk";

interface EligibilityStateBase {
  showWarning: boolean;
  hasBadEconomy: boolean;
}

type EligibilityStatePerson = EligibilityPerson & EligibilityStateBase;

export function isEligibilityStatePerson(
  state: unknown
): state is RecursivePartial<EligibilityStatePerson> {
  if (typeof state !== "object" || state === null) {
    return false;
  }

  if (!isEligibilityPerson(state)) {
    return false;
  }
  return true;
}

export function isValidEligibilityStatePerson(
  state: unknown
): state is EligibilityStatePerson {
  if (typeof state !== "object" || state === null) {
    return false;
  }

  if (!isEligibilityStatePerson(state)) {
    return false;
  }

  if (!isValidEligibilityPerson(state)) {
    return false;
  }

  return true;
}

type EligibilityStateCorporation = {
  insolvencyRisk: CorporationInsolvencyRisk;
  expenses: CorporationExpenses;
} & EligibilityCorporation &
  EligibilityStateBase;

export function isEligibilityStateCorporation(
  state: unknown
): state is RecursivePartial<EligibilityStateCorporation> {
  if (typeof state !== "object" || state === null) {
    return false;
  }

  return isEligibilityCorporation(state);
}

export function isValidEligibilityStateCorporation(
  state: unknown
): state is EligibilityStateCorporation {
  if (typeof state !== "object" || state === null) {
    return false;
  }

  if (!isEligibilityStateCorporation(state)) {
    return false;
  }

  if (typeof state.expenses === "undefined") {
    return false;
  }

  if (typeof state.insolvencyRisk === "undefined") {
    return false;
  }

  if (!isValidEligibilityCorporation(state)) {
    return false;
  }

  return true;
}

export type EligibilityState =
  | RecursivePartial<EligibilityStatePerson>
  | RecursivePartial<EligibilityStateCorporation>;

export const [EligibilityContextProvider, useEligibilityContext] =
  createContext<EligibilityState>("EligibilityContext", {});
