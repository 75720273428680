import {
  Button,
  ServerError,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  generatePath,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router";
import { TranslatedText } from "../../components/TranslatedText";
import {
  dataAttorney,
  isShareAccountReceivedInvitation,
  ShareAccountInvitationId,
  ShareAccountInvitationStatus,
  ShareAccountReceivedInvitation,
} from "../../data/dataAttorney";
import { getNavLink } from "../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../hooks/useSafeNavigation";
import { OVERVIEW_PAGE_URL } from "../overview/OverviewPage";
import { Page, PageHeader } from "../Page";
import { ShareAccountInviteSummary } from "./ShareAccountInviteSummary";
import "./ShareAccountInvitationPage.scss";
import { LayoutGroup, motion } from "framer-motion";
import { ShareAccountInvitationDocs } from "./ShareAccountInvitationDocs";
import { SHARE_ACCOUNT_SENT_INVITATION_URL } from "../shareAccountSentInvitation/ShareAccountSentInvitationPage";

export const SHARE_ACCOUNT_INVITATION_URL =
  "/share-account-invitation/:invitationId";

interface Match {
  invitationId?: ShareAccountInvitationId;
}
export interface ShareAccountInvitationPageLocationState {
  returnUrl?: string;
}

enum InvitationError {
  NOT_FOUND = "NOT_FOUND",
  ALREADY_SHARED = "ALREADY_SHARED",
}

export const ShareAccountInvitationPage: React.VFC = () => {
  const [invitation, setInvitation] =
    useState<ShareAccountReceivedInvitation>();
  const match = useRouteMatch<Match>();
  const [error, setError] = useState<InvitationError>();
  const location = useLocation<
    ShareAccountInvitationPageLocationState | undefined
  >();
  const history = useHistory();
  const [isActionTaken, setIsActionTaken] = useState(false);

  const showOverviewButton = useMemo(
    () =>
      (invitation &&
        invitation.status !== ShareAccountInvitationStatus.CREATED) ||
      isActionTaken,
    [isActionTaken, invitation]
  );

  const returnUrl = location.state?.returnUrl;

  useEffect(() => {
    if (typeof match.params.invitationId === "undefined") {
      setError(InvitationError.NOT_FOUND);
      return;
    }
    dataAttorney
      .viewReceivedInvitation(match.params.invitationId)
      .then((invitation) => {
        if (isShareAccountReceivedInvitation(invitation)) {
          setInvitation(invitation);
          setError(undefined);
        } else {
          setError(InvitationError.NOT_FOUND);
        }
      })
      .catch((error) => {
        if (error instanceof ServerError) {
          if (error.status === 409) {
            setError(InvitationError.ALREADY_SHARED);
          }
          if (error.status === 302 && match.params.invitationId) {
            history.replace({
              pathname: getNavLink(
                generatePath(SHARE_ACCOUNT_SENT_INVITATION_URL, {
                  invitationId: match.params.invitationId,
                })
              ),
            });
          }
        }
        setError(InvitationError.NOT_FOUND);
      });
  }, [history, match.params.invitationId]);

  if (error) {
    return (
      <ShareAccountInvitationPageWrapper
        header={
          <FormattedMessage id="shareAccountInvitationPage.headerError" />
        }
      >
        {error === InvitationError.NOT_FOUND && (
          <Snackbar type={SNACKBAR_TYPES.WARNING}>
            <FormattedMessage id="shareAccountInvitationPage.notFoundError" />
          </Snackbar>
        )}
        {error === InvitationError.ALREADY_SHARED && (
          <Snackbar type={SNACKBAR_TYPES.WARNING}>
            <FormattedMessage id="shareAccountInvitationPage.alreadySharedError" />
          </Snackbar>
        )}
        <ReturnButton returnUrl={returnUrl} />
      </ShareAccountInvitationPageWrapper>
    );
  }

  if (!invitation) {
    return (
      <ShareAccountInvitationPageWrapper>
        <Spinner />
      </ShareAccountInvitationPageWrapper>
    );
  }

  const onAccept = () => {
    return dataAttorney.acceptInvitation(invitation.invitationId).then(() => {
      setIsActionTaken(true);
      return true;
    });
  };

  const onDecline = () => {
    return dataAttorney.declineInvitation(invitation.invitationId).then(() => {
      setIsActionTaken(true);
      return true;
    });
  };

  return (
    <ShareAccountInvitationPageWrapper
      header={
        <TranslatedText
          id="shareAccountInvitationPage.header"
          values={{
            ownerName: invitation.ownerName,
            accountName: invitation.accountName,
          }}
        />
      }
    >
      <LayoutGroup>
        <p>
          <FormattedMessage id="shareAccountInvitationPage.informationParagraph" />
        </p>
        <ShareAccountInvitationDocs
          documentVariables={{
            ownerName: invitation.ownerName,
            accountId: invitation.accountId,
            accountName: invitation.accountName,
          }}
        />
        <h3>
          <TranslatedText
            id="shareAccountInvitationPage.summaryHeader"
            values={{
              accountName: invitation.accountName,
            }}
          />
        </h3>
        <ShareAccountInviteSummary
          invitation={invitation}
          onAccept={onAccept}
          onDecline={onDecline}
        />
        <motion.div layout="position">
          <ReturnButton
            returnUrl={returnUrl}
            showOverviewButton={showOverviewButton}
          />
        </motion.div>
      </LayoutGroup>
    </ShareAccountInvitationPageWrapper>
  );
};

interface PageProps {
  header?: JSX.Element | string;
}

const ShareAccountInvitationPageWrapper: React.FC<
  React.PropsWithChildren<PageProps>
> = ({ children, header }) => {
  return (
    <Page>
      {header && (
        <PageHeader className="share-account-invitation-page">
          <h2>{header}</h2>
        </PageHeader>
      )}
      <div className="share-account-invitation-page">{children}</div>
    </Page>
  );
};

interface ReturnButtonProps {
  returnUrl?: string;
  showOverviewButton?: boolean;
}

const ReturnButton: React.FC<ReturnButtonProps> = ({
  returnUrl,
  showOverviewButton,
}) => {
  const safeNavigation = useSafeNavigation();

  if (returnUrl || showOverviewButton) {
    return (
      <Button
        variant="secondary"
        block
        onClick={() =>
          safeNavigation(returnUrl || getNavLink(OVERVIEW_PAGE_URL))
        }
        label={
          <FormattedMessage id="shareAccountInvitationPage.returnButtonText" />
        }
      />
    );
  }

  return (
    <Button
      variant="secondary"
      block
      onClick={() => safeNavigation(getNavLink(OVERVIEW_PAGE_URL))}
      label={
        <FormattedMessage id="shareAccountInvitationPage.laterButtonText" />
      }
    />
  );
};
