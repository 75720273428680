import {
  API,
  BankIdInitResponse,
  BankIdStandardResponses,
  cache,
  OrderRef,
  Status,
} from "@lysaab/ui-2";
import { encode } from "@lysaab/ui-2";
import { Isin } from "./dataFunds";
import { InvestmentAccountId, AccountType } from "./dataAccounts";
import { CashTransactionType, FundTransactionType } from "./dataTransactions";
import { LysaCountry } from "@lysaab/shared";

export enum ControllingShare {
  SHARES_0_25 = "SHARES_0_25",
  SHARES_25_50 = "SHARES_25_50",
  SHARES_50_75 = "SHARES_50_75",
  SHARES_75_100 = "SHARES_75_100",
  SHARES_100 = "SHARES_100",
}

export enum ControlType {
  ART_10 = "ART_10",
  ART_14 = "ART_14",
  ART_17 = "ART_17",
  ART_20 = "ART_20",
  ART_25 = "ART_25",
  ART_30 = "ART_30",
  ART_40 = "ART_40",
  ART_74 = "ART_74",
}

export interface CoporateUser {
  identificationNumber: string;
  email: string;
  readOnly: boolean;
  name: string;
  currentUser: boolean;
  admin: boolean;
}

export interface CorporateOwner {
  tin: string;
  controllingShare: ControllingShare;
  taxResidence: LysaCountry;
  citizenships: LysaCountry[];
  controlTypes: ControlType[];
  controllingOrganisations: { name: string; organisationNumber: string }[];
  name: string;
  designatedOwner?: string;
  exactOwnedShare?: number;
  pep?: { registered: string; position: string; relation: string };
}

export interface CompanyInformation {
  users: CoporateUser[];
  owners: CorporateOwner[];
  feeExternalBankAccount: string;
  bank: string;
}

export enum FeeType {
  DISC = "DISC",
  INSURANCE = "INSURANCE",
}

export interface DeclarationDocumentation {
  organisationNumber: string;
  accountType: AccountType;
  start: Holding[];
  end: Holding[];
  fundTransactions: FundTransaction[];
  cashTransactions: CashTransaction[];
  feeTransactions: FeeTransaction[];
}

export interface Holding {
  gav: number;
  isin: Isin;
  price: number;
  shares: number;
}

export interface FundTransaction {
  booked: string;
  date: string;
  fund: Isin;
  orderId: string;
  price: number;
  type: FundTransactionType;
  volume: number;
  worth: number;
  gav?: number;
  profit?: number;
}

export interface CashTransaction {
  amount: number;
  booked: string;
  counterpart: InvestmentAccountId;
  transactionId: string;
  transactionType: CashTransactionType;
}

export interface FeeTransaction {
  booked: string;
  fee: number;
  feeExcludingVat: number;
  feeType: FeeType;
  transactionId: string;
}

export const dataCorporate = {
  saveFeeAccount: function (externalBankAccount: string) {
    return API.post("/corporate/feeExternalBankAccount", {
      externalBankAccount,
    }).then((response) => {
      cache.delete("/corporate");
      return response;
    });
  },

  getAccountDeclarationDocumentation: (
    accountId: string,
    start: string,
    end: string
  ) => {
    return API.get<DeclarationDocumentation>(
      encode`/corporate/tax?accountId=${accountId}&start=${start}&end=${end}`
    );
  },

  getCompanyData: () => {
    return API.get<CompanyInformation>("/corporate");
  },

  upsertCompanyUser: (
    identificationNumber: string,
    email: string,
    admin: boolean
  ) => {
    return API.post<BankIdInitResponse>(`/corporate/users`, {
      identificationNumber,
      email,
      admin,
    });
  },

  upsertCompanyUserPoll: (orderRef: OrderRef) => {
    return API.get<BankIdStandardResponses>(
      encode`/corporate/users?orderReference=${orderRef}`,
      true
    ).then((response) => {
      if (response.status === Status.COMPLETE) {
        cache.delete("/corporate");
      }

      return response;
    });
  },

  deleteCompanyUser: (identificationNumber: string) => {
    return API.delete<BankIdInitResponse>(
      `/corporate/users/${identificationNumber}`
    ).then((response) => {
      cache.delete("/corporate");
      return response;
    });
  },

  pollQrCode: (orderRef?: OrderRef) => {
    if (!orderRef) {
      return Promise.resolve(undefined);
    }

    return API.get<{ code: string }>(
      encode`/corporate/users/qr/${orderRef}`,
      true
    );
  },
};
