import { Card, Button } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { useIsReadOnly } from "../../../../../hooks/useIsReadOnly";

interface Props {
  next: () => void;
}

export function Intro({ next }: Props) {
  const isReadOnly = useIsReadOnly();
  return (
    <div>
      <h1>
        <TranslatedText id="deposits.monthly.story.intro.header" />
      </h1>
      <Card>
        <p>
          <TranslatedText id="deposits.monthly.story.intro.text" />
        </p>
        {isReadOnly && (
          <p>
            <TranslatedText id="deposits.monthly.story.intro.textReadOnly" />
          </p>
        )}
      </Card>
      {!isReadOnly && (
        <Button
          block
          onClick={next}
          label={<TranslatedText id="deposits.monthly.story.intro.next" />}
        />
      )}
    </div>
  );
}
