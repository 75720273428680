import {
  BankIDStatus,
  Button,
  FailedHintCode,
  Form,
  OrderRef,
  PendingHintCode,
  Typography,
  useBankId,
  useDisablePollInBackground,
} from "@lysaab/ui-2";
import { FunctionComponent, useCallback, useMemo } from "react";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { useTransfer } from "../TransferContext";
import {
  PensionMoveSigningOptions,
  dataLifePensionMove,
} from "../../../../../data/dataLifePensionMove";
import { IntlShape, useIntl } from "react-intl";
import { useHistory, useLocation, useParams } from "react-router";
import { Location } from "history";
import { parse, stringify } from "query-string";
import {
  signFailedMessages,
  signPendingMessages,
  signingMessages,
} from "./BankIDMessages";

import "./BankID.scss";
import { MoveCard } from "../components/moveCard/MoveCard";

interface Props {
  next: (caseId: string) => void;
}
interface SearchParams {
  orderRef?: string;
  at?: string;
  caseId?: string;
  id?: string;
  t?: string;
}

type RouteParams = {
  caseId: string;
};

export const BankID: FunctionComponent<Props> = ({ next }) => {
  const intl = useIntl();
  const [transfer] = useTransfer();

  const history = useHistory();
  const location = useLocation();
  const urlParams = getParamsFromUrl(location);
  const { caseId } = useParams<RouteParams>() || transfer.caseId;

  const onComplete = useCallback(
    // TODO: Type this
    (_response: any, reset: any) => {
      reset();
      next(transfer.caseId);
    },
    [next, transfer]
  );

  const onPollError = useCallback((error: any) => {
    console.log(error);
  }, []);

  const pollFnRaw = useMemo(() => {
    const caseId = urlParams.caseId;
    const orderRef = urlParams.orderRef;
    if (caseId && orderRef) {
      return () => dataLifePensionMove.pollSigning(caseId, orderRef);
    }
    return undefined;
  }, [urlParams.caseId, urlParams.orderRef]);

  const pollFn = useDisablePollInBackground(pollFnRaw);

  const qrCodePollFn = useCallback(
    () => dataLifePensionMove.pollQrCode(urlParams.caseId, urlParams.orderRef),
    [urlParams.caseId, urlParams.orderRef]
  );

  const {
    initiate,
    pollStatus,
    latestResponse,
    reset,
    qrCode,
    setOpenOnOtherDevice,
  } = useBankId({
    onComplete,
    onPollError,
    initPollFn: dataLifePensionMove.startSigning,
    pollFn,
    qrCodePollFn,
  });

  const initSigning = useCallback(
    (caseId: string) => {
      if (pollStatus === "IDLE" || pollStatus === "FAILED") {
        initiate(caseId).then((response) => {
          if (response) {
            const { orderRef, autoStartToken } = response;
            history.push(
              getUrlWithParams(location, orderRef, caseId, autoStartToken)
            );
          }
        });
      }
    },
    [pollStatus, initiate, history, location]
  );

  const moves = transfer.moves.filter(
    (move) => move.signing === PensionMoveSigningOptions.BANKID
  );

  if (pollStatus === "PENDING" || pollStatus === "FAILED") {
    return (
      <BankIDStatus
        qrCode={qrCode}
        setOpenOnOtherDevice={setOpenOnOtherDevice}
        retry={() => {
          if (urlParams.caseId) {
            initSigning(urlParams.caseId);
          } else {
            reset();
            history.goBack();
          }
        }}
        response={latestResponse}
        getMessages={getMessages(intl)}
        getPendingMessages={getPendingMessages(intl)}
        getFailedMessages={getFailedMessages(intl)}
        autoStartToken={urlParams.autoStartToken}
        reset={() => {
          reset();
          history.goBack();
        }}
      />
    );
  }

  return (
    <article className="transfer-pension-bank-id">
      <Form onSubmit={() => next(transfer.caseId)}>
        <Typography type="h2">
          <TranslatedText id={"sweden.transfer-pension.bank-id.header"} />
        </Typography>
        <Typography type="body">
          <TranslatedText id={"sweden.transfer-pension.bank-id.ingress"} />
        </Typography>
        <section className="pensions">
          {moves &&
            moves.map((move) => {
              return (
                <MoveCard
                  key={move.id}
                  insuranceCompany={move.institute}
                  insuranceNumber={move.insuranceNumber}
                  currentWorth={move.currentWorth}
                  insuranceHolderName={move.employer}
                  insuranceHolderTin={move.employerTin}
                  moveAccountType={move.type}
                />
              );
            })}
        </section>
        <Button
          className="cta"
          block
          variant="primary"
          type="button"
          icon="BankId"
          label={intl.formatMessage({
            id: "sweden.transfer-pension.bank-id.sign.button.bankid",
          })}
          onClick={() => {
            initSigning(caseId);
          }}
        />
      </Form>
    </article>
  );
};

function getMessages(intl: IntlShape) {
  return () => {
    return {
      qrInfo1: intl.formatMessage(signingMessages.qrInfo1),
      qrInfo2: intl.formatMessage(signingMessages.qrInfo2),
      qrInfo3: intl.formatMessage(signingMessages.qrInfo3),
      buttonOpen: intl.formatMessage(signingMessages.buttonOpen),
      buttonErrorHeader: intl.formatMessage(signingMessages.buttonErrorHeader),
      buttonRetry: intl.formatMessage(signingMessages.buttonRetry),
      buttonClose: intl.formatMessage(signingMessages.buttonClose),
      buttonOtherDevice: intl.formatMessage(signingMessages.buttonOtherDevice),
    };
  };
}

function getPendingMessages(intl: IntlShape) {
  return (hintCode: PendingHintCode) =>
    intl.formatMessage(signPendingMessages[hintCode]);
}

function getFailedMessages(intl: IntlShape) {
  return (hintCode: FailedHintCode) =>
    intl.formatMessage(signFailedMessages[hintCode]);
}

function getParamsFromUrl(location: Location) {
  const search = parse(location.search) as SearchParams;
  return {
    orderRef: search.orderRef,
    autoStartToken: search.at,
    caseId: search.caseId,
  };
}

function getUrlWithParams(
  location: Location,
  orderRef: OrderRef | undefined,
  caseId: string,
  autoStartToken: string
) {
  const search = parse(location.search) as SearchParams;
  search.orderRef = orderRef;
  search.caseId = caseId;
  search.at = autoStartToken;

  return {
    pathname: location.pathname,
    search: stringify(search as Record<string, any>, { skipEmptyString: true }),
  };
}
