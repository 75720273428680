import { FunctionComponent, useContext } from "react";
import { Slider, MinValidator } from "@lysaab/ui-2";
import {
  FormattedMessage,
  FormattedNumber,
  defineMessages,
  useIntl,
} from "react-intl";
import "./Slider.scss";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import { useIncomeSteps } from "../../hooks/useIncomeSteps";

const messages = defineMessages({
  minValue: {
    id: "sliders.income.minvalue",
  },
});

interface Props {
  value: number;
  setValue: (newValue: number) => void;
}

export const MonthlyEarningsSlider: FunctionComponent<Props> = ({
  value,
  setValue,
}) => {
  const localizationContext = useContext(LocalizationContext);
  const intl = useIntl();

  const steps = useIncomeSteps();

  if (!steps) {
    return null;
  }

  const label = (
    <div>
      <div className="situation-slider-text">
        <span>
          <FormattedMessage id="situation.slider.income.label" />
        </span>
        <span>
          <FormattedNumber
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            value={value || 0}
            currency={localizationContext.state.currency}
            minimumFractionDigits={0}
            maximumFractionDigits={0}
          />
        </span>
      </div>
      <p>
        <FormattedMessage id="situation.slider.income.description" />
      </p>
    </div>
  );

  return (
    <div className="situation-slider">
      <Slider
        min={0}
        max={steps.length - 1}
        step={1}
        value={steps.indexOf(value || 0)}
        onChange={(value) => setValue(steps[value])}
        label={label}
        validators={[
          new MinValidator(1, intl.formatMessage(messages.minValue)),
        ]}
        data-test-id="income-slider"
      />
    </div>
  );
};
