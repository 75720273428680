import { FunctionComponent, useEffect } from "react";
import { Alternative, Button, Select, Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { defineMessages, useIntl } from "react-intl";
import { DEFAULT_PAYOUT_AGE, useTransfer } from "../TransferContext";
import { useHistory } from "react-router";
import { useUser } from "../../../../../context/UserContext";
import { getUserAge } from "../utils/userAge";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { TRANSFER_PENSIONS_URL } from "../TransferPensionsStory";
import { GlidePathGraph } from "../../../../../components/glidePathGraph/GlidePathGraph";
import { usePensionAllocation } from "../../../../../hooks/usePensionAllocation";

import "./WithdrawalAge.scss";

const messages = defineMessages({
  customPayoutAgePreference: {
    id: "sweden.transfer-pension.advice.payout-age.alternative",
  },
  customPayoutAgeDefaultPreference: {
    id: "sweden.transfer-pension.advice.payout-age.default-alternative",
  },
});

export const WithdrawalAge: FunctionComponent = () => {
  const intl = useIntl();
  const [transfer, setTransfer] = useTransfer();
  const history = useHistory();
  const user = useUser();
  const age = getUserAge(user.tin);
  const { allocation } = usePensionAllocation({ ...transfer, age });

  /**
   * Safe guard in case we loose context. On refresh etc. Send user back to start of story.
   */
  useEffect(() => {
    if (typeof transfer?.moves[0]?.institute === "undefined") {
      history.replace(getNavLink(TRANSFER_PENSIONS_URL));
    }
  }, [history, transfer?.moves]);

  if (!age) {
    return null;
  }

  const payoutAgeAlternatives = generateYearArray(Math.max(age, 55), 80).map(
    (age): Alternative<number> => ({
      text: intl.formatMessage(
        age === DEFAULT_PAYOUT_AGE
          ? messages.customPayoutAgeDefaultPreference
          : messages.customPayoutAgePreference,
        {
          age: age,
        }
      ),
      value: age,
    })
  );

  return (
    <article className="transfer-pension-story-withdrawal-age">
      <Typography type="h3" className="header">
        <TranslatedText id="sweden.transfer-pension.withdrawal-age.header" />
      </Typography>
      <section className="glide-path">
        {allocation && (
          <GlidePathGraph
            glidePath={allocation.glidePath}
            reallocationAge={allocation.reallocationAge}
            withdrawalAge={allocation.withdrawalAge}
          />
        )}
      </section>
      <Typography type="h3" className="heading">
        <TranslatedText id="sweden.transfer-pension.withdrawal-age.heading" />
      </Typography>
      <Select
        alternatives={payoutAgeAlternatives}
        label=" "
        placeholder={intl.formatMessage({
          id: "sweden.transfer-pension.withdrawal-age.select.placeholder",
        })}
        value={payoutAgeAlternatives.find(
          (alternative) => alternative.value === transfer.withdrawalAge
        )}
        onChange={(newValue) => setTransfer({ withdrawalAge: newValue.value })}
      />
      <Button
        className="save-button"
        block
        type="button"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.withdrawal-age.save",
        })}
        onClick={history.goBack}
      />
    </article>
  );
};

function generateYearArray(start: number, end: number) {
  const years = [];
  for (let i = start; i <= end; i++) {
    years.push(i);
  }
  return years;
}
