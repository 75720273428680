import * as React from "react";
import { Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { RiskReaction } from "../../../data/dataInvestments";
import { messagesRiskReaction } from "./messages";

interface Props {
  value?: RiskReaction;
  setValue: (newValue: RiskReaction) => void;
  messages: {
    header: { id: string };
    required: { id: string };
  };
}

export const Reaction: React.VFC<Props> = ({ value, setValue, messages }) => {
  const intl = useIntl();

  return (
    <Card>
      <RadioGroup
        header={intl.formatMessage(messages.header)}
        alternatives={Object.values(RiskReaction)
          .filter((val) => typeof val === "number")
          .map((value) => {
            return {
              text: intl.formatMessage(
                messagesRiskReaction[Number(value) as RiskReaction]
              ),
              value: Number(value),
            };
          })}
        onChange={(riskReaction) => setValue(riskReaction.value)}
        value={
          // Value can be 0 here for really old accounts. 0 is no longer a
          // valid value
          typeof value !== "undefined" && value !== (0 as RiskReaction)
            ? {
                text: intl.formatMessage(messagesRiskReaction[value]),
                value: Number(value),
              }
            : undefined
        }
        validators={[
          new RequiredValidator(intl.formatMessage(messages.required)),
        ]}
      />
    </Card>
  );
};
