import { FunctionComponent } from "react";

import { useIntl } from "react-intl";
import { LysaTable } from "../../../../components/lysaTable/LysaTable";
import { HistoricFeeClosedSavingsAccountResponse } from "../../../../data/dataFees";
import { LysaTableTextCell } from "../../../../components/lysaTableTextCell/LysaTableTextCell";
import { FeeHeaderCell } from "../components/feeHeaderCell/FeeHeaderCell";
import { CostHeaderCell } from "../components/costHeaderCell/CostHeaderCell";
import { FeeBodyCell } from "../components/feeBodyCell/FeeBodyCell";
import { CostBodyCell } from "../components/costBodyCell/CostBodyCell";

interface Props {
  accountsData: HistoricFeeClosedSavingsAccountResponse[];
}

export const ClosedSavingsAccountTable: FunctionComponent<Props> = ({
  accountsData,
}) => {
  const intl = useIntl();

  return (
    <LysaTable>
      <thead>
        <tr>
          <LysaTableTextCell
            value={intl.formatMessage({
              id: "fees.feeTables.savingsAccountTable.closedAccount.accountHeader",
            })}
            header={true}
          />
          <FeeHeaderCell
            text={intl.formatMessage({
              id: "fees.feeTables.savingsAccountTable.closedAccount.rebatedFeeHeader",
            })}
          />
          <FeeHeaderCell
            text={intl.formatMessage({
              id: "fees.feeTables.savingsAccountTable.closedAccount.feeHeader",
            })}
          />
          <CostHeaderCell
            text={intl.formatMessage({
              id: "fees.feeTables.savingsAccountTable.closedAccount.avgCostHeader",
            })}
          />
        </tr>
      </thead>
      <tbody>
        {accountsData &&
          accountsData.map((account) => {
            return (
              <tr key={account.accountId}>
                <LysaTableTextCell
                  value={account.accountName}
                  label={intl.formatMessage({
                    id: "fees.feeTables.savingsAccountTable.closedAccount.accountHeader",
                  })}
                />
                <FeeBodyCell
                  value={account.rebatedFee}
                  label={intl.formatMessage({
                    id: "fees.feeTables.savingsAccountTable.closedAccount.rebatedFeeHeader",
                  })}
                />
                <FeeBodyCell
                  value={account.fee}
                  label={intl.formatMessage({
                    id: "fees.feeTables.savingsAccountTable.closedAccount.feeHeader",
                  })}
                />
                <CostBodyCell
                  value={account.avgCost}
                  label={intl.formatMessage({
                    id: "fees.feeTables.savingsAccountTable.closedAccount.avgCostHeader",
                  })}
                />
              </tr>
            );
          })}
      </tbody>
    </LysaTable>
  );
};
