import { Card, Button, Spinner, Typography } from "@lysaab/ui-2";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../components/TranslatedText";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { useMonthlyInternalTransferContext } from "../MonthlyInternalTransferContext";
import { OVERVIEW_PAGE_URL } from "../../overview/OverviewPage";

export function Done() {
  const monthlyInternalTransferContext = useMonthlyInternalTransferContext();

  if (
    typeof monthlyInternalTransferContext.state.fromAccountId === "undefined"
  ) {
    return <Spinner />;
  }

  return (
    <div>
      <Typography type="h2">
        <TranslatedText id="monthly-internal-transfer.story.done.header" />
      </Typography>
      <Card>
        <Typography type="body" component="span">
          <TranslatedText id="monthly-internal-transfer.story.done.text" />
        </Typography>
      </Card>
      <Button
        component={Link}
        to={getNavLink(OVERVIEW_PAGE_URL)}
        label={
          <TranslatedText id="monthly-internal-transfer.story.done.button" />
        }
        block
      />
    </div>
  );
}
