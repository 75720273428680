import { VoidFunctionComponent } from "react";
import { Link } from "react-router-dom";
import { useDocumentInformation } from "../../hooks/useDocumentInformation";
import {
  DocumentGroupType,
  DOCUMENT_GROUP,
  generateDocumentLink,
} from "./DocumentsPage";

interface Props {
  group: DOCUMENT_GROUP;
  document: string;
  documentGroupType?: DocumentGroupType;
  additionalText?: string;
}

export const DocumentLink: VoidFunctionComponent<Props> = ({
  group,
  document,
  documentGroupType,
  additionalText,
}) => {
  const documentInformation = useDocumentInformation(
    document.replace(".md", "")
  );

  if (typeof documentInformation === "undefined") {
    return <div>{document}</div>;
  }

  return (
    <li>
      <Link to={generateDocumentLink(group, document, documentGroupType)}>
        {documentInformation.title} {additionalText ? additionalText : null}
      </Link>
    </li>
  );
};
