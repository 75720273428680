import { API, cache } from "@lysaab/ui-2";

export enum StateName {
  SAVINGS_ACCOUNT_INTEREST_ADJUSTMENT_V7 = "SAVINGS_ACCOUNT_INTEREST_ADJUSTMENT_V7",
  INVITE_A_FRIEND = "INVITE_A_FRIEND",
}

const defaultStateValues: StateValue = {
  [StateName.SAVINGS_ACCOUNT_INTEREST_ADJUSTMENT_V7]: true,
  [StateName.INVITE_A_FRIEND]: true,
};

export interface StateValue {
  [StateName.SAVINGS_ACCOUNT_INTEREST_ADJUSTMENT_V7]: boolean;
  [StateName.INVITE_A_FRIEND]: boolean;
}

export interface SetUserStateRequest<T extends StateName> {
  stateName: T;
  stateValue: StateValue[T];
}

interface SetUserStateRequestString<T extends StateName> {
  stateName: T;
  stateValue: string;
}

type UserStateResponse = Record<StateName, string>;

export const dataUserState = {
  getUserStates: () => {
    return API.get<Partial<UserStateResponse>>("/user-state")
      .then<StateValue>((response) => {
        const parsedUserStates = Object.entries(response).map(
          ([name, value]) => [name, JSON.parse(value)]
        );
        /* Populate user-state with default values if they're not in response */
        Object.entries(defaultStateValues).forEach(
          ([defaultStateKey, defaultStateValue]) => {
            const isInResponse = parsedUserStates.findIndex(
              ([key]) => key === defaultStateKey
            );
            if (isInResponse === -1) {
              parsedUserStates.push([defaultStateKey, defaultStateValue]);
            }
          }
        );

        return Object.fromEntries(parsedUserStates);
      })
      .catch(() => defaultStateValues); // If we get an error from user-state, we return the default values
  },

  setUserState: <K extends StateName>(
    userStateRequest: SetUserStateRequest<K>
  ) => {
    const stringifiedStateValue = JSON.stringify(userStateRequest.stateValue);
    const request: SetUserStateRequestString<K> = {
      stateName: userStateRequest.stateName,
      stateValue: stringifiedStateValue,
    };

    return API.post<void>("/user-state", request).then((res) => {
      cache.delete("/user-state");
      return res;
    });
  },
};
