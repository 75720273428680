import {
  Alternative,
  Form,
  LysaFormRef,
  RequiredValidator,
  Select,
  Snackbar,
  SNACKBAR_TYPES,
  Icon,
  Button,
} from "@lysaab/ui-2";
import { useContext, useMemo, useRef, useState } from "react";
import AnimateHeight from "react-animate-height";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { AttachFile, FileState } from "../../../components/messages/AttachFile";
import {
  dataMessages,
  MessagesAuthor,
  MessagesStatus,
  MessageSubject,
  ThreadId,
} from "../../../data/dataMessages";
import cx from "classnames";

import "./WriteMessage.scss";
import { TranslatedText } from "../../../components/TranslatedText";
import { MessagesContext } from "../../../context/MessagesContext";

const messages = defineMessages({
  subjectRequired: {
    id: "messages.writeMessage.subjectRequired",
  },
});

const alternativeMessages = defineMessages({
  questionPlaceholder: {
    id: "messages.writeMessage.alternatives.placeholder",
  },
  howLysaWork: {
    id: "messages.writeMessage.alternatives.howLysaWork",
  },
  fees: {
    id: "messages.writeMessage.alternatives.fees",
  },
  accountsAndAllocation: {
    id: "messages.writeMessage.alternatives.accountsAndAllocation",
  },
  roi: {
    id: "messages.writeMessage.alternatives.roi",
  },
  depositsWithdrawals: {
    id: "messages.writeMessage.alternatives.depositsWithdrawals",
  },
  feedback: {
    id: "messages.writeMessage.alternatives.feedback",
  },
  funds: {
    id: "messages.writeMessage.alternatives.funds",
  },
  other: {
    id: "messages.writeMessage.alternatives.other",
  },
});

export const LOCAL_ONLY_THREAD_ID = "-1" as ThreadId;

export const WriteMessage = () => {
  const [edit, setEdit] = useState(false);
  const [text, setText] = useState<string>("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [subject, setSubject] = useState<Alternative<MessageSubject>>();
  const [isPostingMessage, setIsPostingMessage] = useState(false);
  const messagesContext = useContext(MessagesContext);
  const [fileStatuses, setFileStatuses] = useState<Record<string, FileState>>(
    {}
  );
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();

  const isEmptyMessage = useMemo(() => text.trim().length === 0, [text]);

  const messagesSubject: Alternative<MessageSubject>[] = [
    {
      text: intl.formatMessage(alternativeMessages.howLysaWork),
      value: MessageSubject.HOW_LYSA_WORK,
    },
    {
      text: intl.formatMessage(alternativeMessages.fees),
      value: MessageSubject.FEES,
    },
    {
      text: intl.formatMessage(alternativeMessages.accountsAndAllocation),
      value: MessageSubject.ACCOUNTS_AND_ALLOCATION,
    },
    {
      text: intl.formatMessage(alternativeMessages.roi),
      value: MessageSubject.ROI,
    },
    {
      text: intl.formatMessage(alternativeMessages.depositsWithdrawals),
      value: MessageSubject.DEPOSITS_WITHDRAWALS,
    },
    {
      text: intl.formatMessage(alternativeMessages.feedback),
      value: MessageSubject.FEEDBACK,
    },
    {
      text: intl.formatMessage(alternativeMessages.funds),
      value: MessageSubject.FUNDS,
    },
    {
      text: intl.formatMessage(alternativeMessages.other),
      value: MessageSubject.OTHER,
    },
  ];

  const nbrOfActiveThreads = messagesContext.state.messages.filter(
    (thread) => thread.status !== MessagesStatus.CLOSED
  ).length;

  const showOverlay = () => {
    if (document.activeElement === textareaRef.current) {
      return;
    }
    setEdit(!!text);
  };

  const save = () => {
    if (!subject?.value || isPostingMessage) {
      return;
    }
    setIsPostingMessage(true);

    const attachmentIds: number[] = [];
    const attachmentNameAndIds: {
      id: number;
      filename: string;
    }[] = [];
    Object.entries(fileStatuses).forEach(([filename, file]) => {
      if (file.id !== undefined) {
        attachmentNameAndIds.push({ id: file.id, filename });
        attachmentIds.push(file.id);
      }
    });

    const threads = [...messagesContext.state.messages];
    threads.push({
      id: LOCAL_ONLY_THREAD_ID,
      messages: [
        {
          message: text,
          attachments: attachmentNameAndIds,
          date: new Date().toISOString(),
          author: MessagesAuthor.CUSTOMER,
        },
      ],
      readByCustomer: true,
      status: MessagesStatus.WAITING_FOR_ADMIN,
      subject: subject.value,
    });
    messagesContext.setState({ messages: threads });

    dataMessages
      .createThread({
        subject: subject?.value,
        attachments: attachmentIds,
        message: text,
      })
      .then(() => {
        setSuccess(true);
        setTimeout(() => setSuccess(false), 3000);
        dataMessages.getMessages().then((data) => {
          setText("");
          setEdit(false);
          setSubject(undefined);
          messagesContext.setState({ messages: data });
        });
      })
      .catch(() => {
        setError(true);
        setTimeout(() => setError(false), 5000);
        const threads = [...messagesContext.state.messages];
        const indexToRemove = threads.findIndex(
          (thread) => thread.id === LOCAL_ONLY_THREAD_ID
        );
        if (indexToRemove >= 0) {
          threads.splice(indexToRemove, 1);
        }
        messagesContext.setState({ messages: threads });
      })
      .finally(() => {
        setFileStatuses({});
        setIsPostingMessage(false);
      });
  };

  return (
    <div
      className={cx("write-messages", {
        "is-edit": edit,
        "has-error": error,
        "is-saved": success,
        "max-no-threads": nbrOfActiveThreads >= 15,
      })}
    >
      <Snackbar type={SNACKBAR_TYPES.SUCCESS}>
        <TranslatedText id="messages.writemessage.snackbar.success" />
      </Snackbar>

      <Snackbar type={SNACKBAR_TYPES.ERROR}>
        <TranslatedText id="messages.writemessage.snackbar.error" />
      </Snackbar>

      <Form
        lysaFormRef={formRef}
        onSubmit={(event) => {
          event.preventDefault();
          if (formRef.current?.isValid) {
            save();
          }
        }}
      >
        <div className={cx("textarea-wrapper", { "is-edit": edit })}>
          <Snackbar type={SNACKBAR_TYPES.WARNING}>
            <TranslatedText id="messages.writemessage.snackbar.warning" />
          </Snackbar>
          <div className="textarea-placeholder">
            <Icon.Close />{" "}
            <FormattedMessage id="messages.actions.overlay.create" />
          </div>
          <textarea
            onChange={(event) => setText(event.target.value)}
            value={text}
            onMouseEnter={() => setEdit(true)}
            onMouseOut={showOverlay}
            onFocus={() => setEdit(true)}
            onBlur={showOverlay}
            ref={textareaRef}
          />
        </div>

        <AnimateHeight
          animateOpacity
          height={isEmptyMessage ? 0 : "auto"}
          duration={250}
        >
          <Select
            placeholder={intl.formatMessage(
              alternativeMessages.questionPlaceholder
            )}
            alternatives={messagesSubject}
            value={subject}
            onChange={setSubject}
            label=""
            validators={
              isPostingMessage
                ? []
                : [
                    new RequiredValidator(
                      intl.formatMessage(messages.subjectRequired)
                    ),
                  ]
            }
          />

          <div className="flex write-message-buttons">
            <Button
              type="submit"
              size="small"
              disabled={isPostingMessage}
              className="send"
              label={<FormattedMessage id="messages.actions.send" />}
            />

            <AttachFile
              id="messageFileInput"
              fileStatuses={fileStatuses}
              onChange={(data) => setFileStatuses(data)}
              iconSize={26}
            />
          </div>
        </AnimateHeight>
      </Form>
    </div>
  );
};
