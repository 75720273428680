import { Story } from "@lysaab/ui-2";
import { useEffect, useState } from "react";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router";
import { Route } from "../../components/route/Route";
import { Switch } from "../../components/route/Switch";
import { CompoundAccountId } from "../../data/dataAccounts";
import { getNavLink } from "../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../hooks/useSafeNavigation";
import { useStoryValues } from "../../hooks/useStoryValues";
import { PageStripped } from "../../pages/PageStripped";
import { OVERVIEW_PAGE_URL } from "../overview/OverviewPage";
import { ShareAccountConfirm } from "./ShareAccountConfirm";
import {
  ShareAccountContextProvider,
  useShareAccountContext,
} from "./ShareAccountContext";
import { ShareAccountDone } from "./ShareAccountDone";
import { ShareAccountSelection } from "./ShareAccountSelection";

export const SHARE_ACCOUNT_URL = "/share-account";

export const BASE_ROUTES = {
  ACCOUNT_SELECTION: `${SHARE_ACCOUNT_URL}/`,
  CONFIRM: `${SHARE_ACCOUNT_URL}/confirm`,
  DONE: `${SHARE_ACCOUNT_URL}/done`,
};

const messages = defineMessages({
  header: {
    id: "shareAccountStory.header",
  },
  ariaProgressLabel: {
    id: "shareAccountStory.ariaProgressLabel",
  },
});

export interface ShareAccountLocationState {
  accountId?: CompoundAccountId;
  returnUrl?: string;
}

export const ShareAccountStoryInternal: React.VFC = () => {
  const location = useLocation<ShareAccountLocationState | undefined>();
  const history = useHistory();
  const intl = useIntl();
  const safeNavigation = useSafeNavigation();
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);
  const [returnUrl, setReturnUrl] = useState<string>();
  const { state: shareAccountState, setState: setShareAccountState } =
    useShareAccountContext();

  useEffect(() => {
    if (currentIndex > 0 && typeof shareAccountState.account === "undefined") {
      return history.replace(getNavLink(SHARE_ACCOUNT_URL));
    }
  }, [currentIndex, history, shareAccountState.account]);

  useEffect(() => {
    if (!returnUrl && location.state?.returnUrl) {
      setReturnUrl(location.state.returnUrl);
    }
  }, [location.state, returnUrl]);

  useEffect(() => {
    setShareAccountState({ invitation: undefined });
  }, [setShareAccountState, shareAccountState.account]);

  return (
    <PageStripped>
      <Story
        ariaLabelProgress={() =>
          intl.formatMessage(messages.ariaProgressLabel, {
            current: currentIndex + 1,
            total: storyLength,
          })
        }
        header={intl.formatMessage(messages.header)}
        progress={storyProgress}
        showBack={currentIndex === 1}
        showClose={true}
        transitionKey={currentIndex.toString()}
        onExit={() => {
          if (returnUrl) {
            safeNavigation(returnUrl);
          } else {
            safeNavigation(getNavLink(OVERVIEW_PAGE_URL));
          }
        }}
        onBack={() => history.goBack()}
      >
        <Switch
          location={location}
          {...{
            order: currentIndex,
          }}
        >
          <Route path={ROUTES.ACCOUNT_SELECTION} exact>
            <ShareAccountSelection
              next={() => {
                safeNavigation(ROUTES.CONFIRM);
              }}
            />
          </Route>
          <Route path={ROUTES.CONFIRM} exact>
            <ShareAccountConfirm
              next={() => {
                safeNavigation(ROUTES.DONE);
              }}
            />
          </Route>
          <Route path={ROUTES.DONE} exact>
            <ShareAccountDone />
          </Route>
        </Switch>
      </Story>
    </PageStripped>
  );
};

export const ShareAccountStory: React.VFC = () => (
  <ShareAccountContextProvider>
    <ShareAccountStoryInternal />
  </ShareAccountContextProvider>
);
