import { defineMessages } from "react-intl";

export const depositMessages = defineMessages({
  cancelButtonText: { id: "monthly.overview.confirm.cancel" },
  confirmButtonText: { id: "monthly.overview.confirm.confirm" },
  confirmText: { id: "monthly.overview.confirm.text" },
  amountLabel: { id: "monthly.overview.card.amount.label" },
  preferredDayLabel: { id: "monthly.overview.preferred-day.label" },
  preferredDayPlaceholder: {
    id: "monthly.overview.preferred-placeholder.label",
  },
  amountRequired: {
    id: "monthly.overview.card.amount.required",
  },
  amountMin: {
    id: "monthly.overview.card.amount.min",
  },
  amountMax: {
    id: "monthly.overview.card.amount.max",
  },
});
