import { Button } from "@lysaab/ui-2";
import { useState } from "react";
import * as React from "react";
import { TranslatedText } from "../../../../../../../components/TranslatedText";
import { Isin } from "../../../../../../../data/dataFunds";
import { RedemptionPartResponse } from "../../../../../../../data/dataTax";
import { TaxRedemptionModal } from "./components/TaxRedemptionModal";

export const TaxRedemption: React.VFC<{
  redemptionPart: RedemptionPartResponse[];
  isin: Isin;
}> = ({ redemptionPart, isin }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <Button
        size="small"
        inline
        onClick={() => {
          setShowModal(true);
        }}
        label={<TranslatedText id="germany.tax.details.view.transactions" />}
        variant="outlined"
        icon="Search"
      />
      <TaxRedemptionModal
        redemptionPart={redemptionPart}
        isin={isin}
        setShowModal={setShowModal}
        showModal={showModal}
      />
    </div>
  );
};
