import { Story } from "@lysaab/ui-2";
import { useEffect } from "react";
import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router";
import { InitVerificationResult } from "../../../data/dataLogin";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../hooks/useSafeNavigation";
import { useStoryValues } from "../../../hooks/useStoryValues";
import { PageStripped } from "../../PageStripped";
import { WITHDRAWAL_PAGE_URL } from "../overview/WithdrawalPage";
import { AccountInformation } from "./accountInformation/AccountInformation";
import { Done } from "./done/Done";
import OtpVerification from "./otpVerification/OtpVerification";

export const ADD_EXTERNAL_MANUAL_URL = "/withdrawals/add/manual";

export const BASE_ROUTES = {
  ACCOUNT_INFORMATION: `${ADD_EXTERNAL_MANUAL_URL}/`,
  OTP_VERIFICATION: `${ADD_EXTERNAL_MANUAL_URL}/totp`,
  DONE: `${ADD_EXTERNAL_MANUAL_URL}/done`,
};

const messages = defineMessages({
  header: {
    id: "withdrawal.add.manual.story.header",
  },
  ariaProgressLabel: {
    id: "withdrawal.add.manual.story.ariaProgressLabel",
  },
});

export interface AddManualAccountLocationState {
  returnUrl?: string;
}

export function AddAccountManualStory() {
  const location = useLocation<AddManualAccountLocationState>();
  const safeNavigation = useSafeNavigation();
  const intl = useIntl();
  const [returnUrl, setReturnUrl] = useState<string>();
  const history = useHistory();
  const [otpType, setOtpType] = useState<
    InitVerificationResult.EMAIL_CODE | InitVerificationResult.TOTP_CODE
  >();
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);

  useEffect(() => {
    if (!returnUrl && location.state?.returnUrl) {
      setReturnUrl(location.state.returnUrl);
    }
  }, [location.state, returnUrl]);

  return (
    <PageStripped>
      <div className="add-manual-account">
        <Story
          ariaLabelProgress={() =>
            intl.formatMessage(messages.ariaProgressLabel, {
              current: currentIndex + 1,
              total: storyLength,
            })
          }
          header={intl.formatMessage(messages.header)}
          progress={storyProgress}
          showBack={
            Object.values(ROUTES).indexOf(ROUTES.OTP_VERIFICATION) ===
            currentIndex
          }
          showClose={true}
          transitionKey={currentIndex.toString()}
          onExit={() => {
            if (returnUrl) {
              safeNavigation(returnUrl);
            } else {
              safeNavigation(getNavLink(WITHDRAWAL_PAGE_URL));
            }
          }}
          onBack={() => {}}
        >
          <Switch
            location={location}
            {...{
              order: currentIndex,
            }}
          >
            <Route
              path={ROUTES.ACCOUNT_INFORMATION}
              exact
              render={() => (
                <AccountInformation
                  next={(otpType) => {
                    setOtpType(otpType);
                    history.push(ROUTES.OTP_VERIFICATION);
                  }}
                />
              )}
            />
            <Route
              path={ROUTES.OTP_VERIFICATION}
              exact
              render={() => {
                if (otpType) {
                  return (
                    <OtpVerification
                      next={() => {
                        if (returnUrl) {
                          safeNavigation(returnUrl);
                        } else {
                          safeNavigation(ROUTES.DONE);
                        }
                      }}
                      goBack={() => {
                        safeNavigation(ROUTES.ACCOUNT_INFORMATION);
                      }}
                      result={otpType}
                    />
                  );
                } else {
                  <Redirect to={ROUTES.ACCOUNT_INFORMATION} />;
                }
              }}
            />
            <Route path={ROUTES.DONE} exact render={() => <Done />} />
          </Switch>
        </Story>
      </div>
    </PageStripped>
  );
}
