import { Story } from "@lysaab/ui-2";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { Route, useHistory, useLocation } from "react-router";
import { Switch } from "../../components/route/Switch";
import { CompoundAccountId, SavingsAccount } from "../../data/dataAccounts";
import {
  InternalTransferSavingsAccount,
  MergedInternalTransferAccount,
} from "../../data/dataTransfer";
import * as H from "history";
import { getNavLink } from "../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../hooks/useSafeNavigation";
import { useStoryValues } from "../../hooks/useStoryValues";
import { OVERVIEW_PAGE_URL } from "../overview/OverviewPage";
import { PageStripped } from "../PageStripped";
import { MergedInvestmentAccount } from "../withdrawal/request/WithdrawalContext";
import {
  MonthlyInternalTransferContextProvider,
  useMonthlyInternalTransferContext,
} from "./MonthlyInternalTransferContext";
import { FromAccount } from "./fromAccount/FromAccount";
import { ToAccount } from "./toAccount/ToAccount";
import { Amount } from "./amount/Amount";
import { Confirm } from "./confirm/Confirm";
import { dataPeriodicInternalTransfer } from "../../data/dataPeriodicInternalTransfer";
import { Done } from "./done/Done";
import { useIsPerson } from "../../hooks/useIsPerson";

export const MONTHLY_INTERNAL_TRANSFER_PAGE_URL = "/monthly-internal-transfer";

export const BASE_ROUTES = {
  FROM_ACCOUNT: `${MONTHLY_INTERNAL_TRANSFER_PAGE_URL}/`,
  TO_ACCOUNT: `${MONTHLY_INTERNAL_TRANSFER_PAGE_URL}/to-account`,
  AMOUNT: `${MONTHLY_INTERNAL_TRANSFER_PAGE_URL}/amount`,
  CONFIRM: `${MONTHLY_INTERNAL_TRANSFER_PAGE_URL}/confirm`,
  DONE: `${MONTHLY_INTERNAL_TRANSFER_PAGE_URL}/done`,
};

export interface FromInternalTransfer {
  fromAccount: MergedInvestmentAccount;
}

interface FromInternalTransferRedirect {
  returnLocation?: H.History.LocationDescriptor<FromInternalTransfer>;
}

interface EditMonthlyInternalTransferState {
  id: string;
  accountId: CompoundAccountId;
}

function InnerMonthlyInternalTransferStory() {
  const intl = useIntl();
  const history = useHistory<EditMonthlyInternalTransferState | undefined>();
  const safeNavigation = useSafeNavigation();
  const location = useLocation<FromInternalTransferRedirect>();
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);
  const monthlyInternalTransferContext = useMonthlyInternalTransferContext();
  const isPerson = useIsPerson();

  useEffect(() => {
    if (!isPerson) {
      history.goBack();
    }
    if (
      typeof monthlyInternalTransferContext.state.fromAccountId !== "undefined"
    ) {
      return;
    }
    const id = history.location.state?.id;
    const accountId = history.location.state?.accountId;
    if (typeof id === "undefined" || typeof accountId === "undefined") {
      return;
    }

    dataPeriodicInternalTransfer
      .getMonthlyInternalTransfers(accountId)
      .then((response) => {
        const monthlyInternalTransfer = response.find(
          (monthlyInternalTransfer) => monthlyInternalTransfer.id === id
        );
        if (typeof monthlyInternalTransfer === "undefined") {
          return;
        }
        monthlyInternalTransferContext.setState({
          id: monthlyInternalTransfer.id,
          fromAccountId: monthlyInternalTransfer.fromAccountId,
          toAccountId: monthlyInternalTransfer.toAccountId,
          amount: monthlyInternalTransfer.amount,
          transferDay: monthlyInternalTransfer.transferDay,
        });
      });
  }, [
    history,
    history.location.state?.accountId,
    history.location.state?.id,
    isPerson,
    monthlyInternalTransferContext,
  ]);

  const onBack = (currentIndex: number) => {
    if (
      currentIndex === 0 ||
      currentIndex >= Object.values(ROUTES).indexOf(ROUTES.DONE)
    ) {
      return;
    } else {
      safeNavigation(Object.values(ROUTES)[currentIndex - 1]);
      return;
    }
  };

  return (
    <PageStripped>
      <Story
        ariaLabelProgress={() =>
          intl.formatMessage(
            { id: "monthly-internal-transfer.story.ariaProgressLabel" },
            {
              current: currentIndex + 1,
              total: storyLength,
            }
          )
        }
        header={intl.formatMessage({
          id: "monthly-internal-transfer.story.header",
        })}
        onExit={() => {
          safeNavigation(getNavLink(OVERVIEW_PAGE_URL));
        }}
        showClose={true}
        showBack={
          currentIndex > 0 && currentIndex < Object.values(ROUTES).length - 1
        }
        onBack={() => {
          if (location.state?.returnLocation) {
            return safeNavigation(location.state.returnLocation);
          }
          onBack(currentIndex);
        }}
        transitionKey={currentIndex.toString()}
        progress={storyProgress}
      >
        <Switch
          location={location}
          {...{
            order: currentIndex,
          }}
        >
          <Route path={ROUTES.FROM_ACCOUNT} exact>
            <FromAccount next={() => safeNavigation(ROUTES.TO_ACCOUNT)} />
          </Route>
          <Route path={ROUTES.TO_ACCOUNT} exact>
            <ToAccount next={() => safeNavigation(ROUTES.AMOUNT)} />
          </Route>
          <Route path={ROUTES.AMOUNT} exact>
            <Amount next={() => safeNavigation(ROUTES.CONFIRM)} />
          </Route>
          <Route path={ROUTES.CONFIRM} exact>
            <Confirm next={() => safeNavigation(ROUTES.DONE)} />
          </Route>
          <Route path={ROUTES.DONE} exact>
            <Done />
          </Route>
        </Switch>
      </Story>
    </PageStripped>
  );
}

export const isMergedSavingsAccount = (
  account?: MergedInternalTransferAccount
): account is SavingsAccount & InternalTransferSavingsAccount => {
  if (typeof account === "undefined") {
    return false;
  }
  return "savingsAccountDistributions" in account;
};

export const MonthlyInternalTransferStory = () => {
  return (
    <MonthlyInternalTransferContextProvider>
      <InnerMonthlyInternalTransferStory />
    </MonthlyInternalTransferContextProvider>
  );
};
