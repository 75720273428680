import {
  VoidFunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useRouteMatch } from "react-router-dom";
import {
  dataDanica,
  KF_WITHDRAWAL_STATUS,
} from "../../../../../../data/dataDanica";
import { KfMigrationContext } from "./KfMigrationContext";
import { Error } from "./SigningStates/Error";
import { Expired } from "./SigningStates/Expired";
import { PendingDanica } from "./SigningStates/PendingDanica";
import { PendingDanicaManualVerification } from "./SigningStates/PendingDanicaManualVerification";
import { Rejected } from "./SigningStates/Rejected";
import { SigningPending } from "./SigningStates/SigningPending";

interface Params {
  signingId: string;
}
const TIMER = 3000;

interface Props {
  navigateToDone: () => void;
  navigateToOngoing: () => void;
  navigateToSign: (signingId: string, userId: number) => void;
  linkToFrom: string;
}

export const Signers: VoidFunctionComponent<Props> = ({
  navigateToDone,
  navigateToOngoing,
  navigateToSign,
  linkToFrom,
}) => {
  const { params } = useRouteMatch<Params>();
  const { signingId } = params;
  const timer = useRef<number | undefined>();
  const { setState: setKfWithdrawalState } = useContext(KfMigrationContext);

  const pollStatus = useCallback(
    (activeSigningId: any) => {
      dataDanica
        .getKfMigrationStatus(activeSigningId)
        .then((data) => {
          if (
            data.status === KF_WITHDRAWAL_STATUS.PENDING_SIGNING ||
            data.status === KF_WITHDRAWAL_STATUS.PENDING_DANICA
          ) {
            timer.current = window.setTimeout(() => {
              pollStatus(activeSigningId);
            }, TIMER);
          } else if (
            data.status === KF_WITHDRAWAL_STATUS.WITHDRAWAL_COMPLETED
          ) {
            navigateToDone();
          } else if (data.status === KF_WITHDRAWAL_STATUS.WITHDRAWAL_ONGOING) {
            navigateToOngoing();
          }

          setKfWithdrawalState({
            signingStatus: data,
          });
        })
        .catch(() => {
          // Just retry
          timer.current = window.setTimeout(() => {
            pollStatus(activeSigningId);
          }, TIMER);
        });
    },
    [setKfWithdrawalState, navigateToDone, navigateToOngoing]
  );

  useEffect(() => {
    pollStatus(signingId);

    return () => {
      clearTimeout(timer.current);
    };
  }, [pollStatus, signingId]);

  const retry = useCallback(() => {
    pollStatus(signingId);
  }, [pollStatus, signingId]);

  return (
    <div className="withdrawal-request-page-kf-signers">
      <Error retry={retry} />
      <Expired linkToFrom={linkToFrom} />
      <PendingDanica />
      <PendingDanicaManualVerification />
      <Rejected />
      <SigningPending navigateToSign={navigateToSign} />
    </div>
  );
};
