import { createContext } from "../context/CreateContext";

export interface FeatureState {
  yearlyReviewLockRoutes: boolean;
}

export const [FeatureContextProvider, useFeatureContext] =
  createContext<FeatureState>("FeatureContext", {
    yearlyReviewLockRoutes: false,
  });
