import { useCallback, useContext, useRef, VoidFunctionComponent } from "react";
import {
  Button,
  Form,
  LysaFormRef,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import { SavingsHorizonLengthInput } from "../horizon/questions/SavingsHorizonLengthInput";
import { NeedEarlierProbabilityInput } from "../horizon/questions/NeedEarlierProbabilityInput";
import { FormattedMessage } from "react-intl";
import { SustainabilityQuestion } from "../sustainability/SustainabilityQuestion";
import { PAIQuestion } from "../sustainability/PAIQuestion";
import { SFDRQuestion } from "../sustainability/SFDRQuestion";
import { TaxonomyQuestion } from "../sustainability/TaxonomyQuestion";
import { AnimatePresence, motion } from "framer-motion";
import {
  isSustainabilityImportantSpecific,
  SustainabilityImportance,
} from "../../data/dataInvestments";
import { Amount } from "../../components/amount/Amount";
import { LocalizationContext } from "../../context/LocalizationContext";
import { LysaCountry } from "@lysaab/shared";
import { SustainabilityPreferenceQuestion } from "../sustainability/SustainabilityPreferenceQuestion";
import { AccountPreferences } from "../../context/AccountsContext";
import { KfHorizonDissuasion } from "../../components/kfDissuasion/KfHorizonDissuasion";

interface Props {
  account: AccountPreferences;
  setAccount: (account: AccountPreferences) => void;
  next: () => void;
}

export const AccountSituation: VoidFunctionComponent<Props> = ({
  account,
  setAccount,
  next,
}) => {
  const formRef = useRef<LysaFormRef>();
  const localizationContext = useContext(LocalizationContext);

  const onSubmit = useCallback(() => {
    if (
      account &&
      formRef.current?.isValid &&
      (localizationContext.state.country === LysaCountry.SWEDEN ||
        account.sustainability !== SustainabilityImportance.IMPORTANT)
    ) {
      next();
    }
  }, [account, localizationContext.state.country, next]);

  if (!account) {
    throw new Error("AccountSituation - Could not find accountId");
  }

  return (
    <Form lysaFormRef={formRef} onSubmit={onSubmit}>
      <h2>
        <FormattedMessage id="accountSituation.title" />
      </h2>
      <p>
        <FormattedMessage id="accountSituation.description" />
      </p>

      <h3>{account.name}</h3>
      <p>
        <Amount amount={account.worth} />
      </p>

      <h3>
        <FormattedMessage id="accountSituation.horizon.title" />
      </h3>
      <SavingsHorizonLengthInput
        savingsHorizonLength={account.savingsHorizon}
        setSavingsHorizonLength={(savingsHorizon) => {
          setAccount({ ...account, savingsHorizon: savingsHorizon.value });
        }}
      />
      <KfHorizonDissuasion
        accountType={account.type}
        horizonLength={account.savingsHorizon}
      />
      <NeedEarlierProbabilityInput
        needEarlierProbability={account.needEarlier}
        setNeedEarlierProbability={(needEarlier) => {
          setAccount({ ...account, needEarlier: needEarlier.value });
        }}
      />
      <SustainabilityQuestion
        sustainability={account.sustainability}
        setSustainability={(sustainability) => {
          setAccount({ ...account, sustainability } as AccountPreferences);
        }}
      />
      {localizationContext.state.country !== LysaCountry.SWEDEN &&
        account.sustainability === SustainabilityImportance.IMPORTANT && (
          <Snackbar type={SNACKBAR_TYPES.ERROR}>
            <FormattedMessage id="accountSituation.esg.IMPORTANT.error" />
          </Snackbar>
        )}
      <AnimatePresence>
        {localizationContext.state.country === LysaCountry.SWEDEN &&
          account.sustainability === SustainabilityImportance.IMPORTANT && (
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: "auto" }}
              exit={{ height: 0 }}
            >
              <SustainabilityPreferenceQuestion
                sustainabilityPreference={account.sustainabilityPreference}
                setSustainabilityPreference={(sustainabilityPreference) => {
                  setAccount({ ...account, sustainabilityPreference });
                }}
              />
            </motion.div>
          )}
      </AnimatePresence>
      <AnimatePresence>
        {localizationContext.state.country === LysaCountry.SWEDEN &&
          isSustainabilityImportantSpecific(account) && (
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: "auto" }}
              exit={{ height: 0 }}
            >
              <h2>
                <FormattedMessage id="accountSituation.esg.title" />
              </h2>
              <SFDRQuestion
                sfdr={account.sfdr}
                setSfdr={(sfdr) => {
                  setAccount({ ...account, sfdr: sfdr.value });
                }}
              />
              <TaxonomyQuestion
                taxonomy={account.taxonomy}
                setTaxonomy={(taxonomy) => {
                  setAccount({ ...account, taxonomy: taxonomy.value });
                }}
              />
              <PAIQuestion
                pai={account.pai}
                setPai={(pai) => {
                  setAccount({ ...account, pai: pai.value });
                }}
              />
            </motion.div>
          )}
      </AnimatePresence>
      <Button
        block
        type="submit"
        label={<FormattedMessage id="accountSituation.button.next" />}
      />
    </Form>
  );
};
