import { createContext } from "../../../../context/CreateContext";
import { AccountType } from "../../../../data/dataAccounts";
import { SustainabilityQuestions } from "../../../../data/dataInvestments";
import {
  Institute,
  PensionMoveSigningOptions,
  PensionMoveStatus,
} from "../../../../data/dataLifePensionMove";
import { getRandomUUID } from "../../../../utils/getRandomUUID";

export const MAX_AGE = 80;
export const DEFAULT_PAYOUT_AGE = 65;
export const DEFAULT_REBALANCE_OFFSET = 55;
export const DEFAULT_WITHDRAWAL_MONTHS = 10 * 12; // 10 years
export const DEFAULT_RISK_DEVIATION = 0;
export const DEFAULT_REPAYMENT = true;
export const PAYOUT_PLAN_THRESHOLD_AGE = 60;

export interface Move {
  institute?: Institute;
  insuranceNumber?: string;
  employer?: string;
  employerTin?: string;
  repayment?: boolean;
  id?: string;
  signing?: PensionMoveSigningOptions;
  state?: PensionMoveStatus;
  value?: number;
  type?: AccountType;
  checked?: boolean;
  currentWorth?: number;
  pensionId?: string;
  isManuallyAdded?: boolean;
}

export type TransferContextState = Partial<SustainabilityQuestions> & {
  withdrawalAge: number;
  withdrawalMonths: number;
  moves: Move[];
  takenRiskDeviation: number;
  caseId: string;
  repayment: boolean;
  collection?: string;
  hasVisitedInsuranceSigning?: boolean;
};

export const [TransferContextProvider, useTransfer] =
  createContext<TransferContextState>("TransferContext", {
    withdrawalAge: DEFAULT_PAYOUT_AGE,
    withdrawalMonths: DEFAULT_WITHDRAWAL_MONTHS,
    moves: [],
    takenRiskDeviation: DEFAULT_RISK_DEVIATION,
    caseId: getRandomUUID(),
    repayment: DEFAULT_REPAYMENT,
  });
