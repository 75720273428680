import { Spinner } from "@lysaab/ui-2";
import {
  useContext,
  useState,
  useCallback,
  VoidFunctionComponent,
  useRef,
  useEffect,
} from "react";
import { useHistory } from "react-router";
import { LocalizationContext } from "../../../context/LocalizationContext";
import {
  AccountType,
  AddAccountRequest,
  CreationId,
  dataAccounts,
  isValidAddAccountRequest,
} from "../../../data/dataAccounts";
import { getAccountQuestions } from "../../../data/dataInvestments";
import { Purpose } from "../../../data/dataKyc";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { AccountConfirmation } from "../../../pageComponents/accountConfirmation/AccountConfirmation";
import { CreateAccountContext } from "../../../pages/createAccount/CreateAccountContext";
import { CREATE_ACCOUNT_URL } from "../../../pages/createAccount/CreateAccountStory";

interface Props {
  showInvestmentType?: boolean;
  next: () => void;
}

const POLL_INTERVAL = 3000;

export const ConfirmationWrapper: VoidFunctionComponent<Props> = ({
  showInvestmentType,
  next,
}) => {
  const creationId = useRef<CreationId>();
  const timeout = useRef<NodeJS.Timeout>();
  const createAccountContext = useContext(CreateAccountContext);
  const localizationContext = useContext(LocalizationContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (typeof createAccountContext.state.takenRisk === "undefined") {
      history.replace(getNavLink(CREATE_ACCOUNT_URL));
    }
  }, [createAccountContext.state.takenRisk, history]);

  const poll = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      if (!creationId.current) {
        return;
      }
      dataAccounts
        .pollAddAccount(creationId.current)
        .then((resp) => {
          if (resp.complete) {
            next();
          } else {
            poll();
          }
        })
        .catch(() => {
          setError(true);
          setLoading(false);
        });
    }, POLL_INTERVAL);
  }, [next]);

  const onNext = useCallback(() => {
    let purpose: Purpose[] | undefined;
    if (
      createAccountContext.state.accountType === AccountType.ISK_SWE ||
      createAccountContext.state.accountType === AccountType.VP
    ) {
      purpose = createAccountContext.state.purpose?.map((purpose) => purpose);
    }
    if (
      createAccountContext.state.accountType === AccountType.VP_SWE ||
      createAccountContext.state.accountType === AccountType.DANICA_KF
    ) {
      purpose = createAccountContext.state.corporatePurpose?.map(
        (purpose) => purpose
      );
    }

    if (
      !purpose ||
      !createAccountContext.state.depositInterval ||
      !createAccountContext.state.withdrawalInterval
    ) {
      return;
    }

    const advice: Partial<AddAccountRequest["advice"]> = {
      language: localizationContext.state.language,
      takenRisk: createAccountContext.state.takenRisk,
      ...getAccountQuestions(createAccountContext.state),
    };

    if (!isValidAddAccountRequest(advice)) {
      return;
    }

    const data: AddAccountRequest = {
      accountName: createAccountContext.state.accountName,
      advice,
      kyc: {
        accountKyc: {
          version: "3",
          questionAnswers: [
            {
              question: "PURPOSE",
              answers: purpose,
            },
            {
              question: "DEPOSIT_INTERVAL",
              answers: [createAccountContext.state.depositInterval],
            },
            {
              question: "WITHDRAWAL_INTERVAL",
              answers: [createAccountContext.state.withdrawalInterval],
            },
          ],
        },
      },
    };
    setLoading(true);
    setError(false);
    dataAccounts
      .addAccount(data)
      .then((resp) => {
        creationId.current = resp.creationId;
        poll();
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, [createAccountContext.state, localizationContext.state.language, poll]);

  if (
    !createAccountContext.state.savingsHorizon ||
    typeof createAccountContext.state.takenRisk === "undefined" ||
    !createAccountContext.state.advise?.investmentType
  ) {
    return <Spinner />;
  }

  return (
    <AccountConfirmation
      risk={createAccountContext.state.takenRisk}
      accountType={createAccountContext.state.accountType}
      accountName={createAccountContext.state.accountName}
      investmentType={createAccountContext.state.advise.investmentType}
      horizonLength={createAccountContext.state.savingsHorizon}
      loading={loading}
      error={error}
      showInvestmentType={showInvestmentType}
      next={onNext}
    />
  );
};
