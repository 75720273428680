import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { PerformanceContext } from "../../../context/PerformanceContext";
import {
  InvestmentAccount,
  AllAccountResponse,
} from "../../../data/dataAccounts";
import { dataPerformance, Interval } from "../../../data/dataPerformance";
import { useAccounts } from "../../../hooks/useAccounts";

interface SummaryData {
  totalEarnings: number;
  totalChange: number;
}

const TIMER = 10000;

export function useBalanceAndEarnings(account: InvestmentAccount | undefined) {
  const { accounts } = useAccounts();
  const { interval, customStartDate, customEndDate } =
    useContext(PerformanceContext);
  const timer = useRef<number>();
  const [data, setData] = useState<SummaryData>();

  const load = useCallback(
    (
      accounts: AllAccountResponse,
      interval: Interval,
      account?: InvestmentAccount,
      customStartDate?: Date | null,
      customEndDate?: Date | null
    ) => {
      if (!accounts) {
        return;
      }

      let start;
      let end;

      if (interval === Interval.CUSTOM) {
        if (!customStartDate) {
          return;
        }
        if (!customEndDate) {
          return;
        }
        if (customStartDate > customEndDate) {
          return;
        }
        start = customStartDate;
        end = customEndDate;
      } else {
        if (account) {
          start = dataPerformance.getStartDateFromAccount(interval, account);
        } else {
          start = dataPerformance.getStartDateFromUser(interval, [
            ...accounts.investmentAccounts,
            ...accounts.savingsAccounts,
          ]);
        }
        end = dataPerformance.getEndDate();
      }

      let promise;
      if (account) {
        promise = dataPerformance.getAccountPerformance(
          start,
          end,
          account.accountId
        );
      } else {
        promise = dataPerformance.getOverviewPerformance(start, end);
      }

      promise
        .then((graphData) => {
          if (account) {
            const item = graphData.accounts[account.accountId];
            setData({
              totalEarnings: item.earnings,
              totalChange: item.change,
            });
          } else {
            setData({
              totalEarnings: graphData.totalEarnings,
              totalChange: graphData.totalChange,
            });
          }
        })
        .catch((err) => {
          timer.current = window.setTimeout(() => {
            load(accounts, interval, account, customStartDate, customEndDate);
          }, TIMER);
        });
    },
    []
  );

  useEffect(() => {
    if (!accounts) {
      return;
    }
    load(accounts, interval, account, customStartDate, customEndDate);
    return () => {
      if (timer.current) {
        window.clearTimeout(timer.current);
      }
    };
  }, [load, accounts, account, interval, customStartDate, customEndDate]);

  return { data, interval };
}
