import { LysaLink } from "@lysaab/ui-2";
import { defineMessages, MessageDescriptor, useIntl } from "react-intl";
import { generatePath, Link } from "react-router-dom";
import {
  AccountType,
  InvestmentAccountId,
} from "../../../../data/dataAccounts";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { DOCUMENT_PAGE_URL } from "../../../documents/DocumentsPage";

const PPF_DOCUMENTS = [
  "legal/se/sv/portfoljforvaltningsavtal-pensionsforsakring",
  "legal/se/en/LysaLife-Insurelyvillkor-out",
  "legal/se/en/LysaLife-forkopsinformation-PP-out",
  "legal/se/en/LysaLife-villkor-PP-out",
  "legal/se/en/LysaLife-faktablad-PP-out",
] as const;
const TJP_DOCUMENTS = [
  "legal/se/sv/portfoljforvaltningsavtal-pensionsforsakring",
  "legal/se/en/LysaLife-Insurelyvillkor-out",
  "legal/se/en/LysaLife-forkopsinformation-TP-out",
  "legal/se/en/LysaLife-villkor-TP-out",
  "legal/se/en/LysaLife-faktablad-TP-out",
] as const;

const DOCUMENT_PPF_TEXT_MAP: Record<
  typeof PPF_DOCUMENTS[number],
  MessageDescriptor
> = defineMessages({
  "legal/se/sv/portfoljforvaltningsavtal-pensionsforsakring": {
    id: "accountPage.docs.guidelines.pension.portfoljforvaltningsavtal-pensionsforsakring",
  },
  "legal/se/en/LysaLife-Insurelyvillkor-out": {
    id: "accountPage.docs.guidelines.pension.LysaLife-Insurelyvillkor-out",
  },
  "legal/se/en/LysaLife-forkopsinformation-PP-out": {
    id: "accountPage.docs.guidelines.pension.LysaLife-forkopsinformation-PP-out",
  },
  "legal/se/en/LysaLife-villkor-PP-out": {
    id: "accountPage.docs.guidelines.pension.LysaLife-villkor-PP-out",
  },
  "legal/se/en/LysaLife-faktablad-PP-out": {
    id: "accountPage.docs.guidelines.pension.LysaLife-faktablad-PP-out",
  },
});
const DOCUMENT_TJP_TEXT_MAP: Record<
  typeof TJP_DOCUMENTS[number],
  MessageDescriptor
> = defineMessages({
  "legal/se/sv/portfoljforvaltningsavtal-pensionsforsakring": {
    id: "accountPage.docs.guidelines.pension.portfoljforvaltningsavtal-pensionsforsakring",
  },
  "legal/se/en/LysaLife-Insurelyvillkor-out": {
    id: "accountPage.docs.guidelines.pension.LysaLife-Insurelyvillkor-out",
  },
  "legal/se/en/LysaLife-forkopsinformation-TP-out": {
    id: "accountPage.docs.guidelines.pension.LysaLife-forkopsinformation-TP-out",
  },
  "legal/se/en/LysaLife-villkor-TP-out": {
    id: "accountPage.docs.guidelines.pension.LysaLife-villkor-TP-out",
  },
  "legal/se/en/LysaLife-faktablad-TP-out": {
    id: "accountPage.docs.guidelines.pension.LysaLife-faktablad-TP-out",
  },
});

export const PensionDocs = ({
  accountType,
  accountId,
}: {
  accountType: AccountType.LYSA_TJP | AccountType.LYSA_PPF;
  accountId: InvestmentAccountId;
}) => {
  const intl = useIntl();
  let documents = null;

  if (accountType === AccountType.LYSA_TJP) {
    documents = TJP_DOCUMENTS.map((document) => {
      return (
        <div className="account-page-doc" key={document}>
          <LysaLink
            component={Link}
            to={getNavLink(
              generatePath(DOCUMENT_PAGE_URL, {
                group: accountId,
                document: document,
              })
            )}
          >
            {intl.formatMessage(DOCUMENT_TJP_TEXT_MAP[document])}
          </LysaLink>
        </div>
      );
    });
  }

  if (accountType === AccountType.LYSA_PPF) {
    documents = PPF_DOCUMENTS.map((document) => {
      return (
        <div className="account-page-doc" key={document}>
          <LysaLink
            component={Link}
            to={getNavLink(
              generatePath(DOCUMENT_PAGE_URL, {
                group: accountId,
                document: document,
              })
            )}
          >
            {intl.formatMessage(DOCUMENT_PPF_TEXT_MAP[document])}
          </LysaLink>
        </div>
      );
    });
  }

  return <>{documents}</>;
};
