import { useEffect } from "react";
import { useHistory } from "react-router";
import {
  EligibilityState,
  isValidEligibilityStateCorporation,
  isValidEligibilityStatePerson,
  useEligibilityContext,
} from "../../context/EligibilityContext";
import { CorporationExpenses } from "../../pageComponents/economicSituation/economicSituation/corporation/Expenses";
import { CorporationInsolvencyRisk } from "../../pageComponents/economicSituation/economicSituation/corporation/InsolvencyRisk";
import SuitabilityAssessmentUtils from "../../pages/suitabilityAssessment/SuitabilityAssessmentUtils";

/**
 * Access guard for state, should be used to prevent skipping steps with browser navigation.
 * Mostly used on critical areas such as validating that the users economic is suited for the platform
 */
interface Props {
  children: React.ReactNode;
  fallbackRoute: string;
  isValid: (state: EligibilityState) => boolean;
}

export const EligibilityAccessGuard: React.FC<
  React.PropsWithChildren<Props>
> = ({ children, fallbackRoute, isValid }) => {
  const [eligibilityState] = useEligibilityContext();
  const history = useHistory();

  useEffect(() => {
    if (!isValid(eligibilityState)) {
      history.replace(fallbackRoute);
    }
  }, [history, eligibilityState, isValid, fallbackRoute]);

  return <>{children}</>;
};

export function isValidEconomy(state: EligibilityState) {
  if (isValidEligibilityStatePerson(state)) {
    return SuitabilityAssessmentUtils.isValid(
      state.financial?.liquidAssets,
      state.financial?.monthlyPayments,
      state.financial?.otherAssets,
      state.financial?.debts,
      state.financial?.monthlyEarnings
    );
  }

  if (isValidEligibilityStateCorporation(state)) {
    return (
      state.expenses === CorporationExpenses.more &&
      state.insolvencyRisk === CorporationInsolvencyRisk.yes
    );
  }

  return true;
}
