import { FlashContext, SNACKBAR_TYPES, Spinner } from "@lysaab/ui-2";
import {
  useCallback,
  useContext,
  useEffect,
  useState,
  VoidFunctionComponent,
} from "react";
import { defineMessages, useIntl } from "react-intl";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import {
  AdviceResponse,
  dataInvestments,
  getAccountQuestions,
  isValidRecalculateAdviceWithEligibilityRequest,
  RecalculateAdviceWithEligibilityRequest,
  Eligibility,
  RecursivePartial,
} from "../../../../data/dataInvestments";
import { useAccounts } from "../../../../hooks/useAccounts";
import { Advise, AdviceState } from "../../../../pageComponents/advise/Advise";
import {
  AccountUpdateAction,
  useReviewAccountContext,
} from "../../ReviewAccountContext";

const messages = defineMessages({
  retry: {
    id: "yearly.advice.retry",
  },
  assessmentErrorFlash: {
    id: "yearly.advice.assessment.error.flash",
  },
});

interface Props {
  next: () => void;
  navigateToEditAllocation: () => void;
  navigateToFees: () => void;
  eligibility?: RecursivePartial<Eligibility>;
}

export const ReviewAdviseWrapper: VoidFunctionComponent<Props> = ({
  next,
  navigateToEditAllocation,
  navigateToFees,
  eligibility,
}) => {
  const intl = useIntl();
  const [reviewAccountState, setReviewAccountState] = useReviewAccountContext();
  const localizationContext = useContext(LocalizationContext);
  const pushFlash = useContext(FlashContext).pushFlash;
  const [loadingState, setLoadingState] = useState(AdviceState.INITIAL);
  const [advice, setAdvice] = useState<AdviceResponse>();
  const [retryMsg, setRetryMsg] = useState<string>();
  const accounts = useAccounts();

  const investmentType =
    reviewAccountState.reviewAccount?.newAdvice.investmentType;

  useEffect(() => {
    if (
      loadingState !== AdviceState.INITIAL ||
      !reviewAccountState.reviewAccount
    ) {
      return;
    }

    if (!reviewAccountState.reviewAccount) {
      throw new Error("ReviewAdviseWrapper - Missing review account");
    }

    const data: RecursivePartial<RecalculateAdviceWithEligibilityRequest> = {
      accountId: reviewAccountState.reviewAccount.accountId,
      language: localizationContext.state.language,
      ...getAccountQuestions(reviewAccountState.reviewAccount?.newAdvice),
      eligibility,
    };

    if (!isValidRecalculateAdviceWithEligibilityRequest(data)) {
      throw new Error("RecalculateAdviceWithEligibilityRequest - not valid");
    }

    dataInvestments
      .recalculateAdviceWithEligibility(data)
      .then((advice) => {
        setAdvice(advice);
        setLoadingState(AdviceState.DONE);
      })
      .catch((error) => {
        //     // We don't get a status when the network is down
        if (!error.status || error.status >= 500) {
          setRetryMsg(intl.formatMessage(messages.retry));
        } else {
          pushFlash({
            type: SNACKBAR_TYPES.ERROR,
            text: intl.formatMessage(messages.assessmentErrorFlash),
          });
          return;
        }
      });
  }, [
    intl,
    loadingState,
    localizationContext.state.language,
    pushFlash,
    reviewAccountState.reviewAccount,
    eligibility,
  ]);

  const onRetry = useCallback(() => {
    setRetryMsg(undefined);
    setLoadingState(AdviceState.INITIAL);
  }, []);

  const onNext = useCallback(() => {
    if (!reviewAccountState.reviewAccount) {
      throw new Error("onNext - ReviewAdviseWrapper - Missing review account");
    }

    setReviewAccountState({
      reviewAccount: {
        ...reviewAccountState.reviewAccount,
        action: AccountUpdateAction.ADVICE,
      },
    });
    next();
  }, [next, reviewAccountState.reviewAccount, setReviewAccountState]);

  const onNavigateToEditAllocation = useCallback(() => {
    navigateToEditAllocation();
  }, [navigateToEditAllocation]);

  const onNavigateToFees = useCallback(() => {
    navigateToFees();
  }, [navigateToFees]);

  if (
    !investmentType ||
    !reviewAccountState.reviewAccount ||
    !accounts.accounts
  ) {
    return <Spinner />;
  }

  const account = accounts.accounts.investmentAccounts.find(
    (account) =>
      account.accountId === reviewAccountState.reviewAccount?.accountId
  );

  if (!account) {
    throw new Error("ReviewAdviseWrapper - Missing account");
  }

  return (
    <Advise
      advise={advice}
      retryMsg={retryMsg}
      investmentType={investmentType}
      retry={onRetry}
      next={onNext}
      navigateToEditAllocation={onNavigateToEditAllocation}
      navigateToFees={onNavigateToFees}
      accountQuestions={getAccountQuestions(
        reviewAccountState.reviewAccount.newAdvice
      )}
      eligibility={eligibility}
      accountType={account.type}
    />
  );
};
