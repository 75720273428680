import { useState, useEffect, useRef } from "react";

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

export function useWidth() {
  let [width, setWidth] = useState(getWidth());
  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const resizeListener = () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => setWidth(getWidth()), 150);
    };
    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return width;
}
