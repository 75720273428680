import { useContext } from "react";
import * as React from "react";
import { Button, Card } from "@lysaab/ui-2";
import { TranslatedText } from "../../../components/TranslatedText";
import { defineMessages, useIntl } from "react-intl";
import { LegalEntityType } from "../../../data/dataLogin";
import { UserContext } from "../../../context/UserContext";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { WITHDRAWALS_PERIODIC_STORY_URL } from "../withdrawalPeriodic/WithdrawalPeriodicStory";
import { Link } from "react-router-dom";

const messages = defineMessages({
  [LegalEntityType.PERSON]: {
    id: "withdrawals.periodic.body.person",
  },
  [LegalEntityType.CORPORATION]: {
    id: "withdrawals.periodic.body.corporation",
  },
});

export const PeriodicWithdrawalCard: React.FunctionComponent = () => {
  const intl = useIntl();
  const userContext = useContext(UserContext);
  const user = userContext.state;

  return (
    <div className="withdrawal-page-periodic">
      <Card>
        <div>
          <h3>
            <TranslatedText id="withdrawals.periodic.header" />
          </h3>
          <p>
            {intl.formatMessage(
              messages[user.legalEntityType || LegalEntityType.PERSON]
            )}
          </p>
        </div>
        <Button
          component={Link}
          to={getNavLink(WITHDRAWALS_PERIODIC_STORY_URL)}
          label={<TranslatedText id="withdrawals.periodic.button.next" />}
          block
        />
      </Card>
    </div>
  );
};
