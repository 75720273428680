import { LysaCountry } from "@lysaab/shared";
import { useContext } from "react";
import {
  PendingDeposit,
  PendingDepositStatus,
} from "../components/deposits/pendingDeposits/PendingDepositCard";
import { LocalizationContext } from "../context/LocalizationContext";
import {
  AutogiroPaymentResponse,
  dataAutogiro,
  isAutogiroCorporateFeeDeductionResponse,
} from "../data/dataAutogiro";
import { dataIsk } from "../data/dataIsk";

const countryPendingDepositMap: Record<
  LysaCountry,
  () => Promise<PendingDeposit[]>
> = {
  [LysaCountry.DENMARK]: () => {
    return Promise.resolve<PendingDeposit[]>([]);
  },
  [LysaCountry.FINLAND]: () => {
    return Promise.resolve<PendingDeposit[]>([]);
  },
  [LysaCountry.SWEDEN]: () => {
    return Promise.all([
      dataAutogiro.getPendingAutogiroPayments(),
      dataIsk.getTransferHistory(),
    ]).then(([pendingAutogiros, iskTransfers]): PendingDeposit[] => {
      return [
        ...pendingAutogiros
          .filter(
            (pendingAutogiro): pendingAutogiro is AutogiroPaymentResponse =>
              !isAutogiroCorporateFeeDeductionResponse(pendingAutogiro)
          )
          .map((pendingAutogiro) => {
            return {
              status: pendingAutogiro.paymentStatus,
              abort: () => {
                return dataAutogiro.removePayment(
                  pendingAutogiro.transactionId
                );
              },
              accountId: pendingAutogiro.accountId,
              amount: pendingAutogiro.amount,
              transactionId: pendingAutogiro.transactionId,
              dateAdded: pendingAutogiro.created,
            };
          }),
        ...iskTransfers
          .filter((iskTransfer) => typeof iskTransfer.completed === "undefined")
          .map((pendingIskTransfer) => {
            return {
              status: PendingDepositStatus.ISK_TRANSFER,
              accountId: pendingIskTransfer.accountId,
              amount: pendingIskTransfer.amount ? pendingIskTransfer.amount : 0,
              transactionId: String(pendingIskTransfer.transferId),
              dateAdded: pendingIskTransfer.created,
            };
          }),
      ];
    });
  },
  [LysaCountry.GERMANY]: () => {
    return Promise.resolve<PendingDeposit[]>([]);
  },
  [LysaCountry.SPAIN]: () => {
    return Promise.resolve<PendingDeposit[]>([]);
  },
};

export function usePendingDeposits() {
  const localizationContext = useContext(LocalizationContext);

  if (typeof localizationContext.state.country === "undefined") {
    return;
  }
  return countryPendingDepositMap[localizationContext.state.country];
}
