import { useCallback, useEffect, useState } from "react";
import { AllAccountResponse, dataAccounts } from "../data/dataAccounts";

export function useAccounts() {
  const [accounts, setAccounts] = useState<AllAccountResponse>();

  const getAccounts = useCallback(() => {
    return dataAccounts.getAllAccounts().then(setAccounts);
  }, []);

  useEffect(() => {
    let isCurrentPromise = true;
    dataAccounts.getAllAccounts().then((response) => {
      if (isCurrentPromise) {
        setAccounts(response);
      }
    });
    return () => {
      isCurrentPromise = false;
    };
  }, []);

  return { accounts, getAccounts };
}
