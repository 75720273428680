import { defineMessages, IntlShape } from "react-intl";
import { Isin } from "../../data/dataFunds";

export const holdingsMessages = defineMessages({
  LU0908500753: { id: "holdings.fund.LU0908500753" },
  IE00B9F5YL18: { id: "holdings.fund.IE00B9F5YL18" },
  IE00B5BMR087: { id: "holdings.fund.IE00B5BMR087" },
  IE00B95PGT31: { id: "holdings.fund.IE00B95PGT31" },
  IE00B42W4L06: { id: "holdings.fund.IE00B42W4L06" },
  IE00B3VVMM84: { id: "holdings.fund.IE00B3VVMM84" },
  IE00B945VV12: { id: "holdings.fund.IE00B945VV12" },
  IE00B3XXRP09: { id: "holdings.fund.IE00B3XXRP09" },
  IE00BKX55R35: { id: "holdings.fund.IE00BKX55R35" },
  LU1109942653: { id: "holdings.fund.LU1109942653" },
  IE00BFG1RC24: { id: "holdings.fund.IE00BFG1RC24" },
  IE00BJBLQB74: { id: "holdings.fund.IE00BJBLQB74" },
  IE00BJBLQC81: { id: "holdings.fund.IE00BJBLQC81" },
  SE0007491287: { id: "holdings.fund.SE0007491287" },
  SE0000522518: { id: "holdings.fund.SE0000522518" },
  SE0001472952: { id: "holdings.fund.SE0001472952" },
  SE0000493496: { id: "holdings.fund.SE0000493496" },
  IE00B18GC888: { id: "holdings.fund.IE00B18GC888" },
  SE0000739187: { id: "holdings.fund.SE0000739187" },
  SE0010049213: { id: "holdings.fund.SE0010049213" },
  SE0010049197: { id: "holdings.fund.SE0010049197" },
  SE0006887196: { id: "holdings.fund.SE0006887196" },
  SE0001184961: { id: "holdings.fund.SE0001184961" },
  SE0000577470: { id: "holdings.fund.SE0000577470" },
  SE0000543043: { id: "holdings.fund.SE0000543043" },
  SE0000493504: { id: "holdings.fund.SE0000493504" },
  SE0004951283: { id: "holdings.fund.SE0004951283" },
  IE00BFPM9W02: { id: "holdings.fund.IE00BFPM9W02" },
  IE00BGYWT403: { id: "holdings.fund.IE00BGYWT403" },
  IE00BDFB5F89: { id: "holdings.fund.IE00BDFB5F89" },
  IE00BG47KH54: { id: "holdings.fund.IE00BG47KH54" },
  IE00BH04GL39: { id: "holdings.fund.IE00BH04GL39" },
  IE00B4WXJJ64: { id: "holdings.fund.IE00B4WXJJ64" },
  IE0031080751: { id: "holdings.fund.IE0031080751" },
  IE00BDBRDM35: { id: "holdings.fund.IE00BDBRDM35" },
  IE00B3N38C44: { id: "holdings.fund.IE00B3N38C44" },
  IE00BFG1R338: { id: "holdings.fund.IE00BFG1R338" },
  LU1650491282: { id: "holdings.fund.LU1650491282" },
  SE0009268584: { id: "holdings.fund.SE0009268584" },
  SE0014478947: { id: "holdings.fund.SE0014478947" },
  SE0014478954: { id: "holdings.fund.SE0014478954" },
  SE0009268394: { id: "holdings.fund.SE0009268394" },
  SE0012703700: { id: "holdings.fund.SE0012703700" },
  SE0013282712: { id: "holdings.fund.SE0013282712" },
  SE0013282720: { id: "holdings.fund.SE0013282720" },
  SE0015192968: { id: "holdings.fund.SE0015192968" },
  SE0015192950: { id: "holdings.fund.SE0015192950" },
  IE0007472990: { id: "holdings.fund.IE0007472990" },
  IE00BH65QP47: { id: "holdings.fund.IE00BH65QP47" },
  IE00B04FFJ44: { id: "holdings.fund.IE00B04FFJ44" },
  IE00BD0NC037: { id: "holdings.fund.IE00BD0NC037" },
  IE00B04GQR24: { id: "holdings.fund.IE00B04GQR24" },
  SE0009268477: { id: "holdings.fund.SE0009268477" },
  IE00BF11F565: { id: "holdings.fund.IE00BF11F565" },
  IE00BKPT2S34: { id: "holdings.fund.IE00BKPT2S34" },
  SE0017231947: { id: "holdings.fund.SE0017231947" },
  LYSA_CASH: { id: "holdings.fund.LYSA_CASH" },
});

export const getFundName = (isin: Isin, intl: IntlShape) => {
  const isinString = isin.toString();
  if (isTranslatedFund(isinString)) {
    return intl.formatMessage(holdingsMessages[isinString]);
  } else {
    return isin;
  }
};

export function isTranslatedFund(
  key: string
): key is keyof typeof holdingsMessages {
  return key in holdingsMessages;
}
