import { RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { useCreateKFAccount } from "../../KFAccountContext";

export const HasExperience = () => {
  const intl = useIntl();
  const [KFAccount, setKFAccount] = useCreateKFAccount();

  return (
    <RadioGroup
      header={intl.formatMessage({
        id: "sweden.kf.intro.experience.label",
      })}
      alternatives={[
        {
          text: intl.formatMessage({
            id: "sweden.kf.intro.experience.yes",
          }),
          value: true,
        },
        {
          text: intl.formatMessage({
            id: "sweden.kf.intro.experience.no",
          }),
          value: false,
        },
      ]}
      value={KFAccount.hasExperience}
      onChange={(value) =>
        setKFAccount({
          hasExperience: value,
        })
      }
      validators={[
        new RequiredValidator(
          intl.formatMessage({
            id: "sweden.kf.intro.experience.required",
          })
        ),
      ]}
    />
  );
};
