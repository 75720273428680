import { useContext, useEffect } from "react";
import { Route as RouterRoute, RouteProps } from "react-router-dom";
import { RouteContext } from "./RouteContext";

interface Props extends RouteProps {}

export function Route({ children, ...props }: Props) {
  const { addRoute } = useContext(RouteContext);

  useEffect(() => {
    const remove = addRoute({
      path: props.path,
      exact: props.exact,
      strict: props.strict,
    });
    return () => {
      remove();
    };
  }, [props.path, props.exact, props.strict, addRoute]);

  return <RouterRoute {...props}>{children}</RouterRoute>;
}
