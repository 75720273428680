import { useCallback } from "react";
import { useHistory } from "react-router";
import { LocationDescriptorObject } from "history";

export function useSafeNavigation() {
  const history = useHistory();

  return useCallback(
    <T>(routeObj: string | LocationDescriptorObject<T>) => {
      if (
        typeof routeObj === "string" &&
        routeObj !== history.location.pathname
      ) {
        history.push(routeObj);
      } else if (
        typeof routeObj === "object" &&
        routeObj.pathname &&
        routeObj.pathname !== history.location.pathname
      ) {
        history.push(routeObj);
      }
    },
    [history]
  );
}
