import { Spinner } from "@lysaab/ui-2";
import { useCallback, useEffect, useState, VoidFunctionComponent } from "react";
import { InvestmentAccount, CompoundAccount } from "../../../data/dataAccounts";
import {
  CashTransaction,
  CashTransactionType,
  dataTransactions,
  instanceOfCashTransaction,
  InternalTransfer,
  Transaction,
} from "../../../data/dataTransactions";
import { dataUser } from "../../../data/dataUser";
import { PositionsData } from "./positionsData/PositionsData";
import { PositionsExample } from "./positionsExample/PositionsExample";

interface Props {
  account: InvestmentAccount | undefined;
  accounts: CompoundAccount[];
}

export const Positions: VoidFunctionComponent<Props> = ({
  account,
  accounts,
}) => {
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] =
    useState<(Transaction | InternalTransfer)[]>();

  const load = useCallback(
    (accounts: CompoundAccount[], account: InvestmentAccount) => {
      const filter = { ...dataTransactions.getDefaultFilter() };
      filter.start = dataUser.getCreated(accounts);
      Promise.all([
        dataTransactions.getTransactions(filter),
        dataTransactions.getInternalTransfers(filter.start, filter.end),
      ])
        .then(([transactions, transfers]) => {
          const accountTransactions = transactions.filter(
            (item): item is CashTransaction =>
              instanceOfCashTransaction(item) &&
              item.accountId === account.accountId &&
              (item.type === CashTransactionType.DEPOSIT ||
                item.type === CashTransactionType.WITHDRAWAL)
          );
          const accountTransfers = transfers.filter(
            (transfer) => transfer.toAccountId === account.accountId
          );
          setTransactions([...accountTransactions, ...accountTransfers]);
        })
        .catch(() => {})
        .finally(() => setLoading(false));
    },
    []
  );

  useEffect(() => {
    if (!account) {
      return;
    }
    load(accounts, account);
  }, [account, accounts, load]);

  if (loading) {
    return <Spinner />;
  }

  if (transactions?.length === 0) {
    return <PositionsExample account={account} />;
  }

  return (
    <>
      <PositionsData account={account} />
    </>
  );
};
