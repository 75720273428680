import {
  ValidationResponse,
  VALIDATION_STATE,
  BaseValidator,
} from "@lysaab/ui-2";

const DK_COUNTRY_CALLING_CODE = "+45";

export class DanishPhoneNumberValidator extends BaseValidator {
  protected name = "DanishPhoneNumberValidator";

  getId() {
    return this.getBaseId();
  }

  validate(value: string): Promise<ValidationResponse> {
    if (value.startsWith(DK_COUNTRY_CALLING_CODE)) {
      return Promise.resolve({ status: VALIDATION_STATE.SUCCESS });
    }
    return Promise.resolve({
      status: VALIDATION_STATE.FAILED,
      message: this.error,
      hideError: true,
    });
  }
}
