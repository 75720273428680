import { useCallback, useContext, useEffect, useState } from "react";
import * as React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Icon } from "@lysaab/ui-2";
import { getLinkPrefix } from "../../hooks/useCountryUrls";
import { parse } from "query-string";
import { useHistory } from "react-router";
import { setUserState, UserContext } from "../../context/UserContext";
import { FlagIcon } from "../../components/flagIcon/FlagIcon";
import { COUNTRY_SELECT_PAGE_URL } from "../countrySelect/CountrySelectPage";
import { netsInitiateLogin, NetsSelect } from "@lysaab/lysa-nets";
import { EventTrack } from "@lysaab/lysa-nets";
import "./LoginNetsPage.scss";
import { LysaCountry } from "@lysaab/shared";
import { dataUnauthenticatedTrackingService as uts } from "../../data/dataUnauthenticatedTracking";
import { UtsTracker } from "../../components/eventTracker/UtsTracker";
import { useBootstrapUser } from "../../data/dataLogin";

export const LOGIN_NETS_PAGE_URL = "/login";

// https://stackoverflow.com/a/25490531
const getCookieValue = (name: string) =>
  document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop();

interface HistoryState {
  from?: string;
}

interface SearchParams {
  redirect?: string;
  t?: string;
  feature?: string;
}

interface Props {
  donePath: string;
  country: LysaCountry;
}

export const LoginNetsPage: React.VFC<Props> = ({ donePath, country }) => {
  const userContext = useContext(UserContext);
  const history = useHistory<HistoryState>();
  const [netsState, setNetsState] = useState<string>();
  const bootstrapUser = useBootstrapUser();

  useEffect(() => {
    // TODO: Pass to Nets
    // console.log("authenticator", getCookieValue("lysa_nets_authenticator"));
    // console.log(
    //   "sub_authenticator",
    //   getCookieValue("lysa_nets_sub_authenticator")
    // );
  }, []);

  const initiateLogin = useCallback(async () => {
    const initResponse = await netsInitiateLogin(country, netsState);

    setNetsState(initResponse.state);

    // This will connect the email (from the lysa_nets_email cookie) to this
    // login attempt in our tracking
    await uts.loginInitiate({
      loginId: initResponse.state,
      email: getCookieValue("lysa_nets_email"),
      loginType: "NETS",
      countryCode: country,
    });

    UtsTracker.setLogId(initResponse.state);

    return initResponse;
  }, [country, netsState]);

  const login = useCallback(() => {
    bootstrapUser().then(([bootstrap, legalEntity]) => {
      const searchParams = parse(history.location.search) as SearchParams;
      const rawRedirect = searchParams.redirect;

      // We expire the cookie in one year + one month. We ask the customers to
      // login at least once a year to fill out the yearly-review, so this should
      // be long enough for them to login again and refresh the cookie
      const maxAge = 60 * 60 * 24 * 395;

      // signup.lysa.se -> lysa.se
      // signup.lysa-test.se -> lysa-test.se
      // localhost -> localhost
      const domain = window.location.hostname.split(".").slice(-2).join(".");
      document.cookie = `lysa_nets_email=${legalEntity.email}; domain=${domain}; path=/; max-age=${maxAge}`;

      setUserState(bootstrap, legalEntity, userContext.setState);

      if (!rawRedirect) {
        return;
      }

      history.replace(decodeURIComponent(rawRedirect));
    });
  }, [bootstrapUser, history, userContext.setState]);

  const track = useCallback((event: EventTrack) => {
    UtsTracker.track(event);
  }, []);

  const hasPid = getCookieValue("lysa_nets_pid");

  return (
    <div className="login-nets-page">
      <header className="login-nets-page-header">
        <Icon.Logo />
      </header>
      <div className="center-wrapper">
        <NetsSelect
          initiate={initiateLogin}
          netsDonePath={donePath}
          next={login}
          track={track}
          country={country}
          // Have to ask for SSN in every country except, potentially, Denmark
          askForSsn={country !== LysaCountry.DENMARK || !hasPid}
        />
        <div>
          <p>
            <FormattedMessage
              id="login-nets.footer"
              values={{
                link: (txt) => (
                  <a href={process.env.REACT_APP_SIGNUP_SITE + getLinkPrefix()}>
                    {txt}
                  </a>
                ),
              }}
            />
          </p>
          <p>
            <FlagIcon code={country.toLocaleLowerCase()} />
            <FormattedMessage
              id="login-nets.country-select"
              values={{
                link: (txt) => <Link to={COUNTRY_SELECT_PAGE_URL}>{txt}</Link>,
              }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};
