import { useCallback, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { EventTracker } from "../../../../../../components/eventTracker/EventTracker";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { TrackerEvent } from "../../../../../../data/dataCustomerTracking";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { useIsPerson } from "../../../../../../hooks/useIsPerson";
import { OVERVIEW_PAGE_URL } from "../../../../../../pages/overview/OverviewPage";
import { ADD_DEPOSIT_AUTOGIRO_URL } from "../../Autogiro/addAccount/AddDepositAccountAutogiroStory";
import { MonthlyContext } from "../MonthlyContext";
import { BASE_ROUTES } from "../MonthlyStory";
import { TinkAvailableBank } from "../../../../../../data/dataTink";
import { BankSelection } from "../../../../../../components/tink/BankSelection";

interface Props {
  next: () => void;
}

export function TinkBankSelectionWrapper({ next }: Props) {
  const monthlyContext = useContext(MonthlyContext);
  const setState = monthlyContext.setState;
  const isPerson = useIsPerson();
  const history = useHistory();

  useEffect(() => {
    if (!isPerson) {
      console.error("Company reached Monthly Klarna flow");
      history.replace(OVERVIEW_PAGE_URL);
    }
  }, [history, isPerson]);

  useEffect(() => {
    if (typeof monthlyContext.state.selectedLysaAccount === "undefined") {
      history.replace(getNavLink(BASE_ROUTES.INTRO));
    }
  }, [history, monthlyContext.state.selectedLysaAccount]);

  const onComplete = useCallback(
    (bank: TinkAvailableBank) => {
      setState({ tinkBank: bank });
      EventTracker.track({
        event: TrackerEvent.ADD_MONTHLY_TINK_BANK,
        message: bank.name,
      });
      setTimeout(() => {
        next();
      }, 0);
    },
    [next, setState]
  );

  return (
    <BankSelection
      onComplete={onComplete}
      intro={
        <TranslatedText id="sweden.deposits.monthly.story.bank-selection.intro" />
      }
      missingBank={{
        pathname: getNavLink(ADD_DEPOSIT_AUTOGIRO_URL),
        state: {
          returnUrl: getNavLink(BASE_ROUTES.EXTERNAL_ACCOUNT),
          returnState: monthlyContext.state,
        },
      }}
      missingBankHeader={
        <TranslatedText id="klarna.bank-selection.missing-bank.header" />
      }
      missingBankDescription={
        <TranslatedText id="klarna.bank-selection.missing-bank.description" />
      }
    />
  );
}
