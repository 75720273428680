import { Spinner } from "@lysaab/ui-2";
import { useEffect, useState, VoidFunctionComponent } from "react";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { useLoadingContext } from "../../../../../context/LoadingContext";
import { SavingsAccountId } from "../../../../../data/dataAccounts";
import {
  dataMonthlyPayments,
  isMonthlyPaymentWithBankInfo,
  MonthlyPayment,
} from "../../../../../data/dataMonthlyPayments";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { MONTHLY_DEPOSITS_URL } from "../../../../deposits/monthly/create/MonthlyStory";
import { MONTHLY_OVERVIEW_PAGE } from "../../../../deposits/monthly/overview/MonthlyOverview";
import { MonthlyEntry } from "../../../MonthlyEntry";
import { AccountPageCard } from "../accountPageCard/AccountPageCard";

interface Props {
  accountId: SavingsAccountId;
  showAction?: boolean;
}

export const MonthlyCard: VoidFunctionComponent<Props> = ({
  accountId,
  showAction = true,
}) => {
  const [monthlyPayments, setMonthlyPayments] = useState<MonthlyPayment[]>([]);
  const { isLoading, setIsLoading } = useLoadingContext();

  useEffect(() => {
    dataMonthlyPayments
      .getMonthlyPayments()
      .then((response) => {
        setMonthlyPayments(
          response.filter((item) => item.accountId === accountId)
        );
      })
      .finally(() => setIsLoading(false));
  }, [accountId, setIsLoading]);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <AccountPageCard
      bottomLink={
        showAction ? (
          monthlyPayments.length > 0 ? (
            <Link to={getNavLink(MONTHLY_OVERVIEW_PAGE)}>
              <TranslatedText id="savingsAccountPage.monthlyCard.updateMonthlyLink" />
            </Link>
          ) : (
            <Link to={getNavLink(MONTHLY_DEPOSITS_URL)}>
              <TranslatedText id="savingsAccountPage.monthlyCard.addMonthlyLink" />
            </Link>
          )
        ) : null
      }
    >
      {monthlyPayments.length > 0 ? (
        monthlyPayments.map((entry) => (
          <MonthlyEntry
            monthly={entry}
            key={
              isMonthlyPaymentWithBankInfo(entry)
                ? entry.accountId + entry.externalBankAccount
                : entry.accountId + entry.amount
            }
          />
        ))
      ) : (
        <TranslatedText id="savingsAccountPage.monthlyCard.noMonthlyEntries" />
      )}
    </AccountPageCard>
  );
};
