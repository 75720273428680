import { defineMessages } from "react-intl";

const messages = defineMessages({
  swedbank: {
    id: "account-information-hints.hint.swedbank",
  },
  handelsbanken: {
    id: "account-information-hints.hint.handelsbanken",
  },
  nordeaPersonkonto: {
    id: "account-information-hints.hint.nordeaPersonkonto",
  },
  danskeBank1: {
    id: "account-information-hints.hint.danskeBank1",
  },
  danskeBank2: {
    id: "account-information-hints.hint.danskeBank2",
  },
  danskeBank3: {
    id: "account-information-hints.hint.danskeBank3",
  },
  plusgirotBank: {
    id: "account-information-hints.hint.plusgirotBank",
  },
  sparbankenSyd: {
    id: "account-information-hints.hint.sparbankenSyd",
  },
  sparbankenGripenFinn: {
    id: "account-information-hints.hint.sparbankenGripenFinn",
  },
});

interface AccountNumberHint {
  name: string;
  test: (value: string) => boolean;
  message: { id: string };
}

export const accountNumberHints: AccountNumberHint[] = [
  {
    name: "Swedbank",
    test: (value) => value.startsWith("8"),
    message: messages.swedbank,
  },
  {
    name: "Handelsbanken",
    test: (value) => value.startsWith("6"),
    message: messages.handelsbanken,
  },
  {
    name: "Nordea (personkonto)",
    test: (value) => value.startsWith("3300"),
    message: messages.nordeaPersonkonto,
  },
  {
    name: "Danske Bank",
    test: (value) => /^(4993|336[34567]).*$/.test(value),
    message: messages.danskeBank1,
  },
  {
    name: "Danske Bank",
    test: (value) => /^(12|13).*$/.test(value),
    message: messages.danskeBank2,
  },
  {
    name: "Danske Bank",
    test: (value) => /^(6044993|604336[34567]).*$/.test(value),
    message: messages.danskeBank3,
  },
  {
    name: "Plusgirot Bank",
    test: (value) => /^(95[0-4]|996).*$/.test(value),
    message: messages.plusgirotBank,
  },
  {
    name: "Sparbanken syd",
    test: (value) => value.startsWith("957"),
    message: messages.sparbankenSyd,
  },
  {
    name: "Sparbanken Gripen / Sparbanken Finn",
    test: (value) => /^93[0-4][0-9].*$/.test(value),
    message: messages.sparbankenGripenFinn,
  },
];
