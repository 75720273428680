import { useEffect, useState } from "react";
import { dataDocuments, DocumentInformation } from "../data/dataDocuments";

export function useDocumentInformation(documentName: string) {
  const [document, setDocument] = useState<DocumentInformation>();

  useEffect(() => {
    dataDocuments.getDocumentsInformation().then((information) => {
      const matchingDocument = information.find(
        (doc) => doc.fileName === `${documentName}.md`
      );
      if (!matchingDocument) {
        console.error(`Couldn't find information for document ${documentName}`);
      }
      setDocument(matchingDocument);
    });
  }, [documentName]);

  return document;
}
