import { LysaCountry } from "@lysaab/shared";
import { useContext, useMemo } from "react";
import { LocalizationContext } from "../../../context/LocalizationContext";
import {
  generateTranslatedArray,
  Step,
} from "../economicSituation/person/Interval";

const stepData: Record<LysaCountry, Step[]> = {
  [LysaCountry.GERMANY]: [
    {
      step: 1000,
      stop: 100000,
    },
    {
      step: 5000,
      stop: 600000,
    },
    {
      step: 10000,
      stop: 1000000,
    },
  ],
  [LysaCountry.SPAIN]: [
    {
      step: 1000,
      stop: 100000,
    },
    {
      step: 5000,
      stop: 600000,
    },
    {
      step: 10000,
      stop: 1000000,
    },
  ],
  [LysaCountry.DENMARK]: [
    {
      step: 10000,
      stop: 1000000,
    },
    {
      step: 50000,
      stop: 6000000,
    },
    {
      step: 100000,
      stop: 10000000,
    },
  ],
  [LysaCountry.FINLAND]: [
    {
      step: 1000,
      stop: 100000,
    },
    {
      step: 5000,
      stop: 600000,
    },
    {
      step: 10000,
      stop: 1000000,
    },
  ],
  [LysaCountry.SWEDEN]: [
    {
      step: 10000,
      stop: 1000000,
    },
    {
      step: 50000,
      stop: 6000000,
    },
    {
      step: 100000,
      stop: 10000000,
    },
  ],
};

export function useAssetSteps() {
  const localizationContext = useContext(LocalizationContext);

  return useMemo(() => {
    if (!localizationContext.state.country) {
      return [];
    }
    return generateTranslatedArray(stepData[localizationContext.state.country]);
  }, [localizationContext.state.country]);
}
