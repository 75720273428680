import { useContext, useReducer } from "react";
import * as React from "react";
import { LegalEntityType, BootstrapResponse } from "../data/dataLogin";
import { LegalEntityResponse } from "../data/dataLegalEntity";
import { LysaCountry } from "@lysaab/shared";
import { Language } from "./LocalizationContext";

export interface UserState {
  name?: string;
  legalEntityType?: LegalEntityType;
  readOnly?: boolean;
  country?: LysaCountry;
  previousLogin?: string;
  tin?: string;
  impersonator?: string;
  multipleRights?: boolean;
  created?: string;
  language?: Language;
}

export interface UserContextProps {
  state: UserState;
  setState: (newState: Partial<UserState>) => void;
}

export const UserContext = React.createContext<UserContextProps>(
  {} as UserContextProps
);

export interface withUserProps {
  user: UserContextProps;
}

export const withUser =
  <P extends object>(
    Component: React.ComponentType<React.PropsWithChildren<P>>
  ): React.FC<React.PropsWithChildren<Omit<P, keyof withUserProps>>> =>
  (props) =>
    (
      <UserContext.Consumer>
        {(contextProps) => <Component {...(props as P)} user={contextProps} />}
      </UserContext.Consumer>
    );

function stateReducer(state: UserState, newState: Partial<UserState>) {
  return { ...state, ...newState };
}

export const UserContextProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {});

  return (
    <UserContext.Provider value={{ state, setState }}>
      {children}
    </UserContext.Provider>
  );
};

export function setUserState(
  bootstrap: BootstrapResponse,
  legalEntity: LegalEntityResponse,
  setState: (newState: Partial<UserState>) => void
) {
  setState({
    country: legalEntity.country,
    impersonator: bootstrap.impersonator,
    legalEntityType: legalEntity.legalEntityType,
    name: legalEntity.name,
    previousLogin: legalEntity.lastLogin,
    tin: legalEntity.tin,
    readOnly: bootstrap.readOnly,
    multipleRights: bootstrap.multipleRights,
    created: legalEntity.created,
    language: legalEntity.language,
  });
}

export function useUser() {
  const user = useContext(UserContext);

  if (typeof user === "undefined") {
    throw new Error("Missing userContext provider");
  }

  return user.state;
}
