import { Card, Button, Snackbar, SNACKBAR_TYPES, Spinner } from "@lysaab/ui-2";
import { useContext, useEffect, useMemo, useState } from "react";
import * as React from "react";
import { useIntl } from "react-intl";
import { getFundName } from "../../../../components/fundName/FundName";
import { GridCol } from "../../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../../components/grid/gridRow/GridRow";
import { LysaTable } from "../../../../components/lysaTable/LysaTable";
import { LysaTableTextCell } from "../../../../components/lysaTableTextCell/LysaTableTextCell";
import { TranslatedText } from "../../../../components/TranslatedText";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import { Isin } from "../../../../data/dataFunds";
import {
  dataLegalEntity,
  LegalEntityResponse,
} from "../../../../data/dataLegalEntity";
import {
  DkTaxDetails,
  DkTaxRealized,
  DkTaxSummary,
} from "../../../../data/dataTax";
import { RealizedTaxCell } from "./RealizedTaxCell";
import { messages } from "./messages";
import "./TaxDetails.scss";

interface Props {
  year?: number;
  summary?: DkTaxSummary[];
  details?: DkTaxDetails[];
  loading?: boolean;
}

export const TaxDetails: React.VFC<Props> = ({
  year,
  summary,
  details,
  loading,
}) => {
  const [legalEntity, setLegalEntity] = useState<LegalEntityResponse>();
  const intl = useIntl();

  useEffect(() => {
    dataLegalEntity.getLegalEntity().then(setLegalEntity);
  }, []);

  const sortedTaxDetails = useMemo(() => {
    if (typeof details === "undefined") {
      return undefined;
    }
    return details.map(({ isin, realizedCapitalIncome }) => ({
      isin: isin as Isin,
      realizedCapitalIncome: realizedCapitalIncome.sort(
        (a, b) =>
          new Date(a.tradeDate).getTime() - new Date(b.tradeDate).getTime()
      ),
    }));
  }, [details]);

  if (typeof year === "undefined") {
    return (
      <>
        <h2>
          <span>
            <TranslatedText id="denmark.tax.details.details-header" />
          </span>
        </h2>
        <Card>
          <TranslatedText id="denmark.tax.details.placeholder" />
        </Card>
      </>
    );
  }

  if (
    typeof year === "undefined" ||
    typeof summary === "undefined" ||
    typeof details === "undefined" ||
    typeof legalEntity === "undefined" ||
    loading
  ) {
    return (
      <>
        <h2>
          <span>
            <TranslatedText id="denmark.tax.details.details-header" />
          </span>
        </h2>
        <Card>
          <Spinner />
        </Card>
      </>
    );
  }

  return (
    <div className="denmark-tax-details">
      <h2 className="title">
        <span>
          <TranslatedText id="denmark.tax.details.details-header" /> - {year}
        </span>
      </h2>
      <Card>
        <GridRow className="reverse-wrap">
          <GridCol>
            <div className="tax-header">
              <div className="tax-header-left">
                <dl>
                  <dt>
                    <TranslatedText id="denmark.tax.details.name" />
                  </dt>
                  <dd>{legalEntity.name}</dd>
                  <dt>
                    <TranslatedText id="denmark.tax.details.tin" />
                  </dt>
                  <dd>{legalEntity.tin}</dd>
                </dl>
              </div>
            </div>
          </GridCol>
          <GridCol>
            <div className="heading">
              <div className="tax-print-button">
                <Button
                  size="small"
                  onClick={window.print}
                  icon="Print"
                  label={
                    <TranslatedText id="denmark.tax.details.print-button" />
                  }
                />
              </div>
            </div>
          </GridCol>
        </GridRow>
        <div className="tax-reporting-information">
          <Snackbar type={SNACKBAR_TYPES.INFO} icon={true}>
            <TranslatedText id="denmark.tax.details.information" />
          </Snackbar>
        </div>
        <h4>
          <TranslatedText id="denmark.tax.details.summaryHeader" />
        </h4>
        <div className="tax-wrapper">
          <LysaTable>
            <thead>
              <tr>
                <LysaTableTextCell
                  className="text-left"
                  value={intl.formatMessage(messages.fundName)}
                  header
                />
                <LysaTableTextCell
                  className="text-left"
                  value={intl.formatMessage(messages.isin)}
                  header
                />
                <LysaTableTextCell
                  className="text-left"
                  value={intl.formatMessage(messages.summaryValueDate)}
                  header
                />
                <LysaTableTextCell
                  className="text-right"
                  value={intl.formatMessage(messages.summaryVolume)}
                  header
                />
                <LysaTableTextCell
                  className="text-right"
                  value={intl.formatMessage(messages.summaryInitialValue)}
                  header
                />
                <LysaTableTextCell
                  className="text-right"
                  value={intl.formatMessage(messages.summaryAcquisitionValue)}
                  header
                />
                <LysaTableTextCell
                  className="text-right"
                  value={intl.formatMessage(messages.summaryTotalValue)}
                  header
                />
                <LysaTableTextCell
                  className="text-right"
                  value={intl.formatMessage(messages.summaryTotalGain)}
                  header
                />
                <LysaTableTextCell
                  className="text-right"
                  value={intl.formatMessage(messages.summaryTransactions)}
                  header
                />
              </tr>
            </thead>
            <tbody>
              {summary.map((summaryEntry) => (
                <TaxSummaryEntry
                  key={summaryEntry.isin}
                  summary={summaryEntry}
                  year={year}
                  realizedCapitalIncome={
                    sortedTaxDetails?.find(
                      ({ isin }) => isin === summaryEntry.isin
                    )?.realizedCapitalIncome
                  }
                />
              ))}

              {summary.length === 0 && (
                <tr>
                  <td colSpan={3} className="text-center">
                    {intl.formatMessage(messages.noEntriesFound)}
                  </td>
                </tr>
              )}
            </tbody>
          </LysaTable>
        </div>
      </Card>
    </div>
  );
};

interface TaxSummaryEntryProps {
  summary: DkTaxSummary;
  realizedCapitalIncome?: DkTaxRealized[];
  year: number;
}

const TaxSummaryEntry: React.VFC<TaxSummaryEntryProps> = ({
  summary,
  realizedCapitalIncome,
  year,
}) => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);

  return (
    <tr>
      <LysaTableTextCell
        className="text-left"
        label={intl.formatMessage(messages.fundName)}
        value={getFundName(summary.isin, intl)}
      />
      <LysaTableTextCell
        className="text-left"
        label={intl.formatMessage(messages.isin)}
        value={summary.isin}
      />
      <LysaTableTextCell
        className="text-left"
        label={intl.formatMessage(messages.summaryValueDate)}
        value={`${year}-12-31`}
      />
      <LysaTableTextCell
        className="text-right"
        label={intl.formatMessage(messages.summaryVolume)}
        value={intl.formatNumber(summary.volume, {
          minimumFractionDigits: 4,
          maximumFractionDigits: 4,
        })}
      />
      <LysaTableTextCell
        className="text-right"
        label={intl.formatMessage(messages.summaryInitialValue)}
        value={intl.formatNumber(summary.initialValue, {
          currency: localizationContext.state.currency,
          style: "currency",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}
      />
      <LysaTableTextCell
        className="text-right"
        label={intl.formatMessage(messages.summaryAcquisitionValue)}
        value={intl.formatNumber(summary.acquisitionValue, {
          currency: localizationContext.state.currency,
          style: "currency",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}
      />
      <LysaTableTextCell
        className="text-right"
        label={intl.formatMessage(messages.summaryTotalValue)}
        value={intl.formatNumber(summary.totalValue, {
          currency: localizationContext.state.currency,
          style: "currency",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}
      />
      <LysaTableTextCell
        className="text-right"
        label={intl.formatMessage(messages.summaryTotalGain)}
        value={intl.formatNumber(summary.gainLoss, {
          currency: localizationContext.state.currency,
          style: "currency",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}
      />
      {realizedCapitalIncome && (
        <LysaTableTextCell
          className="text-right"
          label={intl.formatMessage(messages.summaryTransactions)}
        >
          <RealizedTaxCell
            realizedTax={realizedCapitalIncome}
            isin={summary.isin}
          />
        </LysaTableTextCell>
      )}
    </tr>
  );
};
