import { Typography } from "@lysaab/ui-2";
import cx from "classnames";

import "./HorizontalDivider.scss";

interface Props {
  text?: string;
  className?: string;
}

export const HorizontalDivider = ({ text, className }: Props) => {
  return (
    <div className={cx("horizontal-divider-wrapper", className)}>
      <div className="horizontal-line" />
      {text && (
        <span className="divider-text">
          <Typography type="label">{text}</Typography>
        </span>
      )}

      <div className="horizontal-line" />
    </div>
  );
};

export default HorizontalDivider;
