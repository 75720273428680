import {
  Alternative,
  Button,
  Card,
  Form,
  LysaFormRef,
  Select,
  Typography,
} from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { defineMessages, useIntl } from "react-intl";
import {
  DEFAULT_PAYOUT_AGE,
  DEFAULT_REBALANCE_OFFSET,
  DEFAULT_WITHDRAWAL_MONTHS,
  MAX_AGE,
  useTransfer,
} from "../TransferContext";
import { FunctionComponent, useEffect, useRef } from "react";
import { useUser } from "../../../../../context/UserContext";
import { getUserAge } from "../utils/userAge";

import "./PayoutPlan.scss";
import { useHistory } from "react-router";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { TRANSFER_PENSIONS_URL } from "../TransferPensionsStory";

const messages = defineMessages({
  customPayoutAgePreference: {
    id: "sweden.transfer-pension.payout-age.alternative",
  },
  customPayoutTimePreference: {
    id: "sweden.transfer-pension.payout-time.alternative",
  },
  customPayoutAgeDefaultPreference: {
    id: "sweden.transfer-pension.payout-age.default-alternative",
  },
  customPayoutTimeDefaultPreference: {
    id: "sweden.transfer-pension.payout-time.default-alternative",
  },
});

interface Props {
  next: () => void;
}

export const PayoutPlan: FunctionComponent<Props> = ({ next }) => {
  const intl = useIntl();
  const formRef = useRef<LysaFormRef>();
  const user = useUser();
  const age = getUserAge(user.tin);
  const [transfer, setTransfer] = useTransfer();
  const history = useHistory();

  /**
   * Safe guard in case we loose context. On refresh etc. Send user back to start of story.
   */
  useEffect(() => {
    if (typeof transfer?.moves[0]?.institute === "undefined") {
      history.replace(getNavLink(TRANSFER_PENSIONS_URL));
    }
  }, [history, transfer?.moves]);

  if (!age) {
    return null;
  }

  const payoutAge = DEFAULT_PAYOUT_AGE - DEFAULT_REBALANCE_OFFSET;

  const payoutAgeAlternatives = generateYearArray(
    Math.max(age, payoutAge),
    MAX_AGE
  ).map(
    (age): Alternative<number> => ({
      text: intl.formatMessage(
        age === DEFAULT_PAYOUT_AGE
          ? messages.customPayoutAgeDefaultPreference
          : messages.customPayoutAgePreference,
        {
          age: age,
        }
      ),
      value: age,
    })
  );

  const timeAlternatives = generateYearArray(5, 15).map(
    (year): Alternative<number> => ({
      text: intl.formatMessage(
        year === DEFAULT_WITHDRAWAL_MONTHS / 12
          ? messages.customPayoutTimeDefaultPreference
          : messages.customPayoutTimePreference,
        {
          years: year,
        }
      ),
      value: year,
    })
  );

  return (
    <article className="transfer-pension-story-payout-plan">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            return;
          }
          next();
        }}
      >
        <Typography type="h2" className="header">
          <TranslatedText id="sweden.transfer-pension.payout-plan.header" />
        </Typography>
        <Card className="selections-card">
          <Select
            alternatives={payoutAgeAlternatives}
            label={intl.formatMessage({
              id: "sweden.transfer-pension.payout-age.select.label",
            })}
            placeholder={intl.formatMessage({
              id: "sweden.transfer-pension.payout-age.select.placeholder",
            })}
            value={payoutAgeAlternatives.find(
              (alternative) => alternative.value === transfer.withdrawalAge
            )}
            onChange={(newValue) =>
              setTransfer({ withdrawalAge: newValue.value })
            }
          />
          <Select
            alternatives={timeAlternatives}
            label={intl.formatMessage({
              id: "sweden.transfer-pension.payout-time.select.label",
            })}
            placeholder={intl.formatMessage({
              id: "sweden.transfer-pension.payout-time.select.placeholder",
            })}
            value={timeAlternatives.find(
              (alternative) =>
                alternative.value === transfer.withdrawalMonths / 12
            )}
            onChange={(newValue) =>
              setTransfer({ withdrawalMonths: newValue.value * 12 })
            }
          />
        </Card>
        <ul className="list">
          <li>
            <TranslatedText id="sweden.transfer-pension.payout-time.list-item.first" />
          </li>
          <li>
            <TranslatedText id="sweden.transfer-pension.payout-time.list-item.second" />
          </li>
        </ul>
        <Button
          block
          type="submit"
          label={intl.formatMessage({
            id: "sweden.transfer-pension.payout-plan.next",
          })}
        />
      </Form>
    </article>
  );
};

function generateYearArray(start: number, end: number) {
  const years = [];
  for (let i = start; i <= end; i++) {
    years.push(i);
  }
  return years;
}
