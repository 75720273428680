import { API, cache, encode } from "@lysaab/ui-2";
import { CompoundAccountId, GET_ACCOUNTS_URL } from "./dataAccounts";
import { dataLogin } from "./dataLogin";

const BASE_URL_INVITATION = "/attorney/invitation";

export const BASE_URL_ACCOUNT_SHARE = "/attorney/account/share";

export const SHARE_ACCOUNT_INVITATION_TTL_DAYS = 7;

export enum ShareAccountInvitationStatus {
  CREATED = "CREATED",
  ACCEPTED_INVITEE = "ACCEPTED_INVITEE",
  ACCEPTED_OWNER = "ACCEPTED_OWNER",
  TIMED_OUT = "TIMED_OUT",
  DECLINED_OWNER = "DECLINED_OWNER",
  DECLINED_INVITEE = "DECLINED_INVITEE",
}

export enum ShareAccountPermission {
  LEVEL1 = "LEVEL1",
}

export type ShareAccountInvitationId = string & {
  readonly isShareAccountInvitationId: true;
};

export type AccountShareId = string & {
  readonly isAccountShareId: true;
};

export interface ShareAccountInvitation {
  invitationId: ShareAccountInvitationId;
  created: string;
  status: ShareAccountInvitationStatus;
  permission: ShareAccountPermission;
  accountName?: string;
  inviteeAssigned?: boolean;
  expiresAt?: string;
  accountId?: CompoundAccountId;
}

export interface ShareAccountSentInvitation
  extends Omit<ShareAccountInvitation, "status"> {
  status: ShareAccountInvitationStatus.ACCEPTED_INVITEE;
  inviteeName: string;
  inviteeDoB: string;
  accountId: CompoundAccountId;
  accountName: string;
}

export interface ShareAccountReceivedInvitation extends ShareAccountInvitation {
  ownerName: string;
  ownerDoB: string;
  accountName: string;
  accountId: CompoundAccountId;
}

export interface AccountShareBase {
  accountShareId: AccountShareId;
  accountName: string;
  created: string;
  permission: ShareAccountPermission;
  accountId: CompoundAccountId;
}

export type AccountShare = AccountShareByMe | AccountShareWithMe;

export interface AccountShareByMe extends AccountShareBase {
  inviteeName: string;
}

export interface AccountShareWithMe extends AccountShareBase {
  ownerName: string;
}

export type AllShareAccountInvitation =
  | ShareAccountInvitation
  | ShareAccountSentInvitation
  | ShareAccountReceivedInvitation;

const invitationEndpoints = {
  createInvitation(
    accountId: CompoundAccountId,
    permission?: ShareAccountPermission
  ) {
    return API.post<ShareAccountInvitation>(BASE_URL_INVITATION + "/create", {
      accountId,
      permission,
    }).then((response) => {
      cache.delete(`${BASE_URL_INVITATION}/created`);
      return response;
    });
  },
  getSentInvitations() {
    return API.get<ShareAccountSentInvitation[]>(
      BASE_URL_INVITATION + "/created"
    );
  },
  getReceivedInvitations() {
    return API.get<ShareAccountReceivedInvitation[]>(
      BASE_URL_INVITATION + "/received"
    );
  },
  declineInvitation(invitationId: ShareAccountInvitationId) {
    return API.post<ShareAccountInvitation>(
      BASE_URL_INVITATION + encode`/decline/${invitationId}`
    ).then((response) => {
      cache.delete(`${BASE_URL_INVITATION}/received`);
      cache.delete(`${BASE_URL_INVITATION}/created`);
      cache.delete(`${BASE_URL_INVITATION}/view/${invitationId}`);
      return response;
    });
  },
  acceptInvitation(invitationId: ShareAccountInvitationId) {
    return API.post<ShareAccountReceivedInvitation>(
      BASE_URL_INVITATION + encode`/accept/${invitationId}/invitee`
    ).then((response) => {
      cache.delete(`${BASE_URL_INVITATION}/received`);
      cache.delete(`${BASE_URL_INVITATION}/view/${invitationId}`);
      return response;
    });
  },
  acceptInvitationResponse(invitationId: ShareAccountInvitationId) {
    return API.post<ShareAccountSentInvitation>(
      BASE_URL_INVITATION + encode`/accept/${invitationId}/owner`
    ).then((response) => {
      cache.delete(`${BASE_URL_INVITATION}/created`);
      cache.delete(`${BASE_URL_ACCOUNT_SHARE}/created`);
      cache.delete(`${BASE_URL_INVITATION}/view/${invitationId}`);
      return response;
    });
  },
  viewReceivedInvitation(invitationId: ShareAccountInvitationId) {
    return API.get<ShareAccountReceivedInvitation | ShareAccountInvitation>(
      BASE_URL_INVITATION + encode`/view/${invitationId}/invitee`,
      true
    );
  },
  getSentInvitation(invitationId: ShareAccountInvitationId) {
    return API.get<ShareAccountSentInvitation | ShareAccountInvitation>(
      BASE_URL_INVITATION + encode`/view/${invitationId}/owner`,
      true
    );
  },
};

const accountEndpoints = {
  getAccountSharesByMe() {
    return API.get<AccountShareByMe[]>(BASE_URL_ACCOUNT_SHARE + "/created");
  },
  getAccountSharesWithMe() {
    return API.get<AccountShareWithMe[]>(BASE_URL_ACCOUNT_SHARE + "/received");
  },
  getAccountShareByMe(accountShareId: AccountShareId) {
    return API.get<AccountShareByMe>(
      BASE_URL_ACCOUNT_SHARE + encode`/view/${accountShareId}/owner`,
      true
    );
  },
  getAccountShareWithMe(accountShareId: AccountShareId) {
    return API.get<AccountShareWithMe>(
      BASE_URL_ACCOUNT_SHARE + encode`/view/${accountShareId}/invitee`,
      true
    );
  },
  getAccountShareByMeForAccount(accountId: CompoundAccountId) {
    return API.get<AccountShareByMe[]>(
      BASE_URL_ACCOUNT_SHARE + encode`/view/account/${accountId}/owner`
    );
  },
  getAccountShareWithMeForAccount(accountId: CompoundAccountId) {
    return API.get<AccountShareWithMe[]>(
      BASE_URL_ACCOUNT_SHARE + encode`/view/account/${accountId}/invitee`
    );
  },
  cancelAccountShare(accountShareId: AccountShareId) {
    return API.delete(
      BASE_URL_ACCOUNT_SHARE + encode`/cancel/${accountShareId}`
    ).then(async () => {
      cache.delete("/login");
      cache.delete(BASE_URL_ACCOUNT_SHARE);
      await dataLogin.keepAlive().then(() => cache.delete(GET_ACCOUNTS_URL));
    });
  },
  hasShareFeature(): Promise<boolean> {
    return API.get<{ active: boolean }>(
      BASE_URL_ACCOUNT_SHARE + "/feature/active"
    ).then((response) => {
      return response.active;
    });
  },
};

export const dataAttorney = {
  ...invitationEndpoints,
  ...accountEndpoints,
};

export const isShareAccountReceivedInvitation = (
  invitation: ShareAccountReceivedInvitation | ShareAccountInvitation
): invitation is ShareAccountReceivedInvitation => {
  return (
    typeof (invitation as ShareAccountReceivedInvitation).ownerName !==
    "undefined"
  );
};

export const isShareAccountSentInvitation = (
  invitation: ShareAccountSentInvitation | ShareAccountInvitation
): invitation is ShareAccountSentInvitation => {
  return (
    typeof (invitation as ShareAccountSentInvitation).inviteeName !==
    "undefined"
  );
};

export const isShareAccountInvitationInFinalState = (
  invitation: ShareAccountSentInvitation | ShareAccountInvitation
) => {
  return ![
    ShareAccountInvitationStatus.CREATED,
    ShareAccountInvitationStatus.ACCEPTED_INVITEE,
  ].includes(invitation.status);
};
