import { LysaCountry } from "@lysaab/shared";
import { NewIcon, Spinner, Typography } from "@lysaab/ui-2";
import { useContext, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { TranslatedText } from "../../components/TranslatedText";
import { LocalizationContext } from "../../context/LocalizationContext";
import { dataInvite } from "../../data/dataInvite";
import "./InviteInformation.scss";
import { InviteQrCode } from "./inviteQrCode/InviteQrCode";
import { InviteButtons } from "./inviteButtons/InviteButtons";

export const messages = defineMessages({
  disclaimer: {
    id: "invite.inviteInformation.disclaimer",
  },
});

const MAXIMUM_DISCOUNT_AMOUNT = {
  [LysaCountry.DENMARK]: 200000,
  [LysaCountry.FINLAND]: 20000,
  [LysaCountry.GERMANY]: 20000,
  [LysaCountry.SPAIN]: 20000,
  [LysaCountry.SWEDEN]: 200000,
};

const DISCOUNT_PERCENTAGE = 0.2;

export const InviteInformation = () => {
  const localizationContext = useContext(LocalizationContext);
  const intl = useIntl();
  const [inviteId, setInviteId] = useState("");
  const [rebateMonths, setRebateMonths] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    dataInvite
      .getId()
      .then(({ inviteId }) => {
        setInviteId(inviteId);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    dataInvite.getRebateMonths().then(setRebateMonths);
  }, []);

  if (isLoading) return <Spinner />;

  return (
    <div className="invite-information">
      <div className="invite-information-code-header">
        <NewIcon.InviteFriend size={80} />
        <InviteQrCode inviteId={inviteId} error={isError} />
        <NewIcon.Recieve size={80} />
      </div>
      <div className="invite-information-card">
        <TranslatedText
          id="invite.inviteInformation.ingress"
          values={{
            discountPercentage: intl.formatNumber(DISCOUNT_PERCENTAGE, {
              style: "percent",
            }),
            paragraph: (parts) => <p>{parts}</p>,
            rebateMonths,
          }}
        />
        <ul>
          <li>
            <TranslatedText id="invite.inviteInformation.ingressListFirst" />
          </li>
          <li>
            <TranslatedText id="invite.inviteInformation.ingressListSecond" />
          </li>
          <li>
            <TranslatedText id="invite.inviteInformation.ingressListThird" />
          </li>
        </ul>
        <InviteButtons inviteId={inviteId} />
      </div>
      <Typography type="body-small">
        {intl.formatMessage(messages.disclaimer, {
          minAmount: intl.formatNumber(
            MAXIMUM_DISCOUNT_AMOUNT[
              localizationContext.state.country || LysaCountry.SWEDEN
            ],
            {
              style: "currency",
              currency: localizationContext.state.currency,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }
          ),
          discountPercentage: intl.formatNumber(DISCOUNT_PERCENTAGE, {
            style: "percent",
          }),
          rebateMonths,
        })}
      </Typography>
    </div>
  );
};
