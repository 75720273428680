import { VoidFunctionComponent, useState, useRef, useCallback } from "react";
import * as React from "react";
import { Form, FormErrors, LysaFormRef, Button, Spinner } from "@lysaab/ui-2";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { AccountKyc } from "../../../../components/kyc/accountType/AccountKyc";
import { AccountCorporationKyc } from "../../../../components/kyc/accountType/AccountCorporationKyc";
import { InvestmentAccountId } from "../../../../data/dataAccounts";
import {
  DepositInterval,
  PurposeAccountCorporation,
  PurposeAccount,
  WithdrawalInterval,
  dataKyc,
  AccountKycType,
} from "../../../../data/dataKyc";
import {
  AccountKycAccountCorporationInternal,
  AccountKycAccountInteral,
  AccountKycInternal,
  AccountKycSavingsAccountInternal,
  mapAccountKycToExternal,
  useAccountKyc,
} from "../../../../hooks/useAccountsKyc";
import { EventTracker } from "../../../../components/eventTracker/EventTracker";
import { SavingsAccountKyc } from "../../../../components/kyc/accountType/SavingsAccountKyc";
import { useUser } from "../../../../context/UserContext";
import { TrackerEvent } from "../../../../data/dataCustomerTracking";

export interface KycData {
  accountId: InvestmentAccountId;
  purpose?: PurposeAccount[];
  corporationPurpose?: PurposeAccountCorporation[];
  depositInterval?: DepositInterval;
  withdrawalInterval?: WithdrawalInterval;
}

const messages = defineMessages({
  errorTitle: {
    id: "yearly.account-kyc.error.title",
  },
});

type AccountTypeRenderProps = (
  accountKyc: AccountKycInternal,
  setAccountsKyc: (accountKyc: Partial<AccountKycInternal>) => void
) => React.ReactNode;

const accountTypeRender: Record<AccountKycType, AccountTypeRenderProps> = {
  [AccountKycType.ACCOUNT]: (accountKyc, setAccountKyc) => (
    <AccountKyc
      key={accountKyc.accountId}
      accountKyc={accountKyc as AccountKycAccountInteral}
      setAccountKyc={setAccountKyc}
    />
  ),
  [AccountKycType.SAVINGS_ACCOUNT]: (accountKyc, setAccountKyc) => (
    <SavingsAccountKyc
      key={accountKyc.accountId}
      accountKyc={accountKyc as AccountKycSavingsAccountInternal}
      setAccountKyc={setAccountKyc}
    />
  ),
  [AccountKycType.ACCOUNT_CORPORATION]: (accountKyc, setAccountKyc) => (
    <AccountCorporationKyc
      key={accountKyc.accountId}
      accountKyc={accountKyc as AccountKycAccountCorporationInternal}
      setAccountKyc={setAccountKyc}
    />
  ),
};

interface Props {
  next: () => void;
}

export const AccountsKyc: VoidFunctionComponent<Props> = ({ next }) => {
  const intl = useIntl();
  const formRef = useRef<LysaFormRef>();
  const [loading, setLoading] = useState(false);
  const [accountsKyc, setAccountsKyc] = useAccountKyc();
  const user = useUser();

  const onSubmit = useCallback(() => {
    if (user.readOnly) {
      return;
    }
    if (formRef.current?.isValid && accountsKyc) {
      setLoading(true);

      Promise.all(
        accountsKyc.map((accountKyc) => {
          return dataKyc.saveAccountKyc(
            accountKyc.accountId,
            mapAccountKycToExternal(accountKyc)
          );
        })
      )
        .then(() => {
          EventTracker.track({
            event: TrackerEvent.YEARLY_REVIEW_ACCOUNTS_KYC,
            message: { numberOfAccountsUpdated: accountsKyc.length },
          });
          next();
        })
        .finally(() => setLoading(false));
    }
  }, [accountsKyc, next, user.readOnly]);

  if (!accountsKyc || loading) {
    return <Spinner />;
  }

  return (
    <Form lysaFormRef={formRef} onSubmit={onSubmit}>
      <h2>
        <FormattedMessage id="yearly.account-kyc.title" />
      </h2>
      <p>
        <FormattedMessage id="yearly.account-kyc.description" />
      </p>
      {accountsKyc.length > 3 && (
        <FormErrors
          title={intl.formatMessage(messages.errorTitle)}
          lysaFormRef={formRef}
        />
      )}
      {accountsKyc?.map((accountKyc) =>
        accountTypeRender[accountKyc.accountKycType](accountKyc, setAccountsKyc)
      )}
      <Button
        type="submit"
        block
        label={<FormattedMessage id="yearly.account-kyc.next" />}
      />
    </Form>
  );
};
