import { FunctionComponent } from "react";
import * as React from "react";
import { FormattedMessage } from "react-intl";

type FormattedMessageProps = React.ComponentProps<typeof FormattedMessage>;

interface Props extends FormattedMessageProps {}

export const defaultFormatters = {
  bold: (parts: React.ReactNode[]) => <b>{parts}</b>,
  italic: (parts: React.ReactNode[]) => <i>{parts}</i>,
  underline: (parts: React.ReactNode[]) => <u>{parts}</u>,
  newline: () => <br />,
  break: () => (
    <>
      <br />
      <br />
    </>
  ),
};

export const TranslatedText: FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ values, children, ...args }) => (
  <FormattedMessage
    {...args}
    values={{
      ...defaultFormatters,
      ...values,
    }}
  />
);
