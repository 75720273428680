import { useReducer, createContext, FunctionComponent } from "react";
import * as React from "react";
import { CompoundAccount } from "../../../../../../data/dataAccounts";
import { Consent } from "../../../../../../data/dataAutogiro";
import {
  AvailableBank,
  KlarnaAccount,
} from "../../../../../../data/dataKlarna";
import { ExternalAccount } from "../../../../../../data/dataWithdrawals";
import { TinkAccount } from "../../../../../../data/dataTink";
import { TinkAvailableBank } from "../../../../../../data/dataTink";

export interface AutogiroDepositState {
  lysaAccount?: CompoundAccount;
  bank?: AvailableBank;
  tinkBank?: TinkAvailableBank;
  externalAccount?: ExternalAccount | Consent;
  amount?: number;
  klarnaAccounts?: KlarnaAccount[];
  selectedKlarnaAccount?: KlarnaAccount;
  tinkAccounts?: TinkAccount[];
  selectedTinkAccount?: TinkAccount;
  isSentToServer: boolean;
}

export interface AutogiroDepositContextProps {
  state: AutogiroDepositState;
  setState: (newState: Partial<AutogiroDepositState>) => void;
}

export const AutogiroDepositContext =
  createContext<AutogiroDepositContextProps>({} as AutogiroDepositContextProps);

function stateReducer(
  state: AutogiroDepositState,
  newState: Partial<AutogiroDepositState>
) {
  return { ...state, ...newState };
}

export const AutogiroDepositContextProvider: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, { isSentToServer: false });

  return (
    <AutogiroDepositContext.Provider value={{ state, setState }}>
      {children}
    </AutogiroDepositContext.Provider>
  );
};
