import { FunctionComponent, useRef, useState } from "react";
import {
  Button,
  Form,
  LysaFormRef,
  // RadioGroupCard,
  // RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Typography,
} from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
// import { useTransfer } from "../TransferContext";
// import { AnimatePresence, motion } from "framer-motion";
import { Modal } from "../../../../../components/modal/Modal";
import { TranslatedText } from "../../../../../components/TranslatedText";
// import { ExpandableContent } from "../../../../../components/expandableContent/ExpandableContent";

import "./Repayment.scss";

// type Beneficiary = "SPOUSE_CHILDREN" | "CHILDREN_SPOUSE" | "NONE" | "OTHER";

export const Repayment: FunctionComponent = () => {
  const intl = useIntl();
  const history = useHistory();
  // const [transfer, setTransfer] = useTransfer();
  const formRef = useRef<LysaFormRef>();
  const [showModal, setShowModal] = useState(false);
  // const [beneficiary, setBeneficiary] = useState<Beneficiary>("NONE");

  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  /**
   * TODO: Add beneficiary to transfer context when Hampus and Tobias has made is possible to send it to.
   */

  return (
    <section className="transfer-pension-repayment">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (formRef.current?.isValid) {
            history.goBack();
          }
        }}
      >
        <Typography type="h3">
          <TranslatedText id="sweden.transfer-pension.repayment.header" />
        </Typography>
        <Typography type="body">
          <TranslatedText
            id="sweden.transfer-pension.repayment.body"
            values={{
              readMore: (text) => {
                return (
                  <Button
                    inline
                    variant="outlined"
                    size="small"
                    label={text}
                    className="read-more"
                    onClick={handleModalOpen}
                  />
                );
              },
            }}
          />
        </Typography>
        {showModal && (
          <Modal
            header={intl.formatMessage({
              id: "sweden.transfer-pension.repayment.read-more.modal.header",
            })}
            showModal={!!showModal}
            onClose={handleModalClose}
            closeOnOverlayClick
          >
            <Typography type="body">
              <TranslatedText id="sweden.transfer-pension.repayment.read-more.modal.first-paragraph" />
            </Typography>
            <Typography type="body">
              <TranslatedText id="sweden.transfer-pension.repayment.read-more.modal.second-paragraph" />
            </Typography>
          </Modal>
        )}
        <Snackbar type={SNACKBAR_TYPES.INFO} icon textAlign="left">
          <TranslatedText id="sweden.transfer-pension.repayment.snackbar" />
        </Snackbar>
        <Button
          variant="primary"
          type="submit"
          block
          label={intl.formatMessage({
            id: "sweden.transfer-pension.repayment.button",
          })}
        />
      </Form>
    </section>
  );
};
