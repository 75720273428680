import { useContext, VoidFunctionComponent } from "react";
import { DateTime } from "luxon";
import { FormattedMessage } from "react-intl";
import { PERIODIC_TYPE } from "../../../../../data/dataWithdrawals";
import {
  WithdrawalPeriodicContext,
  WithdrawalPeriodicState,
} from "../../WithdrawalPeriodicContext";
import { WithdrawalItem } from "../../../../../components/withdrawalItem/WithdrawalItem";
import "./PeriodicWithdrawalExamples.scss";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { Card } from "@lysaab/ui-2";

export const PeriodicWithdrawalExamples: VoidFunctionComponent = () => {
  const withdrawalPeriodicContext = useContext(WithdrawalPeriodicContext);

  const { monthlyAmount, months } = getPaymentPlan(
    withdrawalPeriodicContext.state
  );

  if (
    !withdrawalPeriodicContext.state.paymentMethod ||
    monthlyAmount === undefined ||
    monthlyAmount === 0 ||
    months === undefined ||
    monthlyAmount === Infinity
  ) {
    return null;
  }

  const exampleDates = Array.from(Array(3).keys()).map((index) =>
    getExampleDate(withdrawalPeriodicContext.state.paymentDay.value, index)
  );

  const lysaAccount = withdrawalPeriodicContext.state.lysaAccount;
  const externalAccount = withdrawalPeriodicContext.state.externalAccount;

  if (!lysaAccount || !externalAccount) {
    return null;
  }

  return (
    <>
      <h2>
        <FormattedMessage id="withdrawalPeriodic.amountPeriodConfiguration.example.header" />
      </h2>
      <Card className="periodic-withdrawal-examples">
        <div className="examples-container">
          {exampleDates.map((date, index) => {
            if (
              index > months ||
              lysaAccount.worth - monthlyAmount * index <= 0
            ) {
              return null;
            }

            let amount = monthlyAmount;

            if (lysaAccount.worth - monthlyAmount * (index + 1) < 0) {
              amount = lysaAccount.worth - monthlyAmount * index;
            }

            return (
              <WithdrawalItem
                moneyOnAccount={lysaAccount.worth}
                key={date.toISOString()}
                name={lysaAccount.name}
                externalBankAccount={externalAccount.externalBankAccount}
                externalBank={externalAccount.bank}
                withdrawalAmount={amount.toString()}
                date={date}
              />
            );
          })}
        </div>
        <p>
          <TranslatedText id="withdrawalPeriodic.amountPeriodConfiguration.example.payments.description" />
        </p>
      </Card>
    </>
  );
};

function getPaymentPlan(state: WithdrawalPeriodicState): {
  monthlyAmount?: number;
  months?: number;
} {
  if (!state.paymentMethod || !state.lysaAccount) {
    return {};
  }

  switch (state.paymentMethod.value) {
    case PERIODIC_TYPE.FIXED_AMOUNT: {
      if (isNaN(Number(state.FIXED_AMOUNT))) {
        return {};
      }

      const amount = Number(state.FIXED_AMOUNT);
      return {
        monthlyAmount: amount,
        months: Math.ceil(state.lysaAccount.worth / amount),
      };
    }
    case PERIODIC_TYPE.PERCENTAGE: {
      if (!state.PERCENTAGE || isNaN(Number(state.PERCENTAGE.value))) {
        return {};
      }

      const percent = state.PERCENTAGE.value;
      return {
        monthlyAmount: (state.lysaAccount.worth * (percent / 100)) / 12,
        months: Math.ceil(state.lysaAccount.worth / percent),
      };
    }
    case PERIODIC_TYPE.TARGET_DATE: {
      if (state.TARGET_DATE instanceof Date) {
        const today = DateTime.now().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        let firstPayment = today.set({
          day:
            today.get("daysInMonth") < state.paymentDay.value
              ? today.get("daysInMonth")
              : state.paymentDay.value,
        });
        if (today.get("day") > state.paymentDay.value) {
          firstPayment = firstPayment.plus({ months: 1 });
        }

        const targetDate = DateTime.fromJSDate(state.TARGET_DATE);
        let lastPayment = targetDate.set({
          day:
            targetDate.get("daysInMonth") < state.paymentDay.value
              ? targetDate.get("daysInMonth")
              : state.paymentDay.value,
          hour: 8,
        });

        if (
          targetDate.get("day") < state.paymentDay.value &&
          targetDate.get("daysInMonth") !== targetDate.get("day")
        ) {
          lastPayment = lastPayment.minus({ months: 1 });
        }

        const diff = lastPayment.diff(firstPayment, "months");
        let months = Math.ceil(diff.months);

        return {
          monthlyAmount: state.lysaAccount.worth / months,
          months: months,
        };
      }
      return {};
    }
    default: {
      return {};
    }
  }
}

function getExampleDate(paymentDate: number, monthsFromNow: number) {
  const months =
    paymentDate < DateTime.now().get("day") ? monthsFromNow + 1 : monthsFromNow;
  const date = DateTime.now().set({ day: 1 }).plus({ months });
  return date
    .set({
      day: date.daysInMonth < paymentDate ? date.daysInMonth : paymentDate,
    })
    .toJSDate();
}
