import { VoidFunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../../overview/OverviewPage";
import { WITHDRAWAL_PAGE_URL } from "../../overview/WithdrawalPage";
import { Link } from "react-router-dom";
import { Button } from "@lysaab/ui-2";

export const DonePeriodicWithdrawal: VoidFunctionComponent = () => {
  return (
    <>
      <h3>
        <FormattedMessage id="withdrawalPeriodic.donePeriodicWithdrawal.header" />
      </h3>
      <p>
        <FormattedMessage id="withdrawalPeriodic.donePeriodicWithdrawal.description" />
      </p>
      <Button
        block
        component={Link}
        to={getNavLink(WITHDRAWAL_PAGE_URL)}
        label={
          <FormattedMessage id="withdrawalPeriodic.donePeriodicWithdrawal.withdrawal" />
        }
      />
      <Button
        block
        variant="secondary"
        component={Link}
        to={getNavLink(OVERVIEW_PAGE_URL)}
        label={
          <FormattedMessage id="withdrawalPeriodic.donePeriodicWithdrawal.overview" />
        }
      />
    </>
  );
};
