import { useCallback, useEffect, useState } from "react";
import {
  dataAccounts,
  SavingsAccountId,
  SavingsAccount,
} from "../data/dataAccounts";

export function useSavingsAccount(accountId?: SavingsAccountId) {
  const [account, setAccount] = useState<SavingsAccount>();

  const getAccount = useCallback((accountId: SavingsAccountId) => {
    dataAccounts.getAllAccounts().then((data) => {
      const account = data.savingsAccounts.find(
        (account) => account.accountId === accountId
      );
      if (typeof account !== "undefined") {
        setAccount(account);
      }
    });
  }, []);

  useEffect(() => {
    if (typeof accountId === "undefined") {
      return;
    }
    getAccount(accountId);
  }, [accountId, getAccount]);

  return { account, getAccount };
}
