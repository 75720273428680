import { useCallback, useContext } from "react";
import { PepContext } from "@lysaab/lysa-pep";
import { useHistory } from "react-router-dom";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../hooks/useSafeNavigation";
import { ReviewAccountHistoryState } from "../../reviewAccount/ReviewAccountStory";
import { useYearlyReviewContext } from "../contexts/YearlyReviewContext";
import { useMultiPepContext } from "../contexts/MultiPepContext";
import { YearlyReviewReturnState } from "../AccessGuardRoute";
import { useAccountsContext } from "../../../context/AccountsContext";
import { useReviewAccountContext } from "../../reviewAccount/ReviewAccountContext";
import { useEligibilityContext } from "../../../context/EligibilityContext";
import {
  getEligibility,
  isValidEligibility,
} from "../../../data/dataInvestments";
import { UpdateInvestmentAccountComposition } from "../../../pageComponents/accountsAllocation/accountAdviseCard/AccountAdviceCard";

export const useNavigateToReviewAccount = () => {
  const safeNavigation = useSafeNavigation();
  const history = useHistory<YearlyReviewReturnState | undefined>();
  const pepContext = useContext(PepContext);
  const [yearlyReviewState] = useYearlyReviewContext();
  const [multiPepState] = useMultiPepContext();
  const [accountsState] = useAccountsContext();
  const [eligibilityState] = useEligibilityContext();
  const [reviewAccountState] = useReviewAccountContext();

  const navigateToReviewAccount = useCallback(
    (pathname: string, reviewAccount: UpdateInvestmentAccountComposition) => {
      const state: YearlyReviewReturnState = {
        accountsState,
        pepState: pepContext.state,
        multiPepState,
        yearlyReviewState,
        eligibilityState,
        reviewAccountState: {
          ...reviewAccountState,
          reviewAccount,
        },
      };

      // Replace current history state with saved contexts so if user uses browser back it doesn't crash
      history.replace({
        pathname: history.location.pathname,
        state,
      });

      const eligibility = getEligibility(eligibilityState);
      if (!isValidEligibility(eligibility)) {
        throw new Error("Can't move to substory. Eligibility not complete");
      }

      const returnState: ReviewAccountHistoryState<YearlyReviewReturnState> = {
        returnUrl: history.location.pathname,
        returnState: state,
        eligibility,
      };
      safeNavigation({
        pathname: getNavLink(pathname),
        state: returnState,
      });
    },
    [
      accountsState,
      pepContext.state,
      multiPepState,
      yearlyReviewState,
      eligibilityState,
      reviewAccountState,
      history,
      safeNavigation,
    ]
  );

  return navigateToReviewAccount;
};
