import { Story } from "@lysaab/ui-2";
import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { generatePath, useHistory, useLocation } from "react-router";
import { Route } from "../../components/route/Route";
import { Switch } from "../../components/route/Switch";
import { getNavLink } from "../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../hooks/useSafeNavigation";
import { PageStripped } from "../../pages/PageStripped";
import { END_INVESTMENT_URL } from "../endInvestment/EndInvestment";
import { CloseLysaCustomerConfirmation } from "./closeLysaCustomerConfirmation/CloseLysaCustomerConfirmation";
import { YourData } from "./yourData/YourData";
import { Intro } from "./intro/Intro";
import * as H from "history";
import { CloseCustomerScenario } from "../../data/dataProfile";
import { useStoryValues } from "../../hooks/useStoryValues";

export const CLOSE_LYSA_CUSTOMER_URL = "/close-lysa-customer";

export const BASE_ROUTES = {
  INTRO: `${CLOSE_LYSA_CUSTOMER_URL}/`,
  YOUR_DATA: `${CLOSE_LYSA_CUSTOMER_URL}/your-data`,
  CLOSE_LYSA_CUSTOMER_CONFIRMATION: `${CLOSE_LYSA_CUSTOMER_URL}/confirm`,
};

const messages = defineMessages({
  header: {
    id: "closeLysaCustomerAccountStory.story.header",
  },
  ariaProgressLabel: {
    id: "closeLysaCustomerAccountStory.story.ariaProgressLabel",
  },
});

export interface CloseAccountState<T = H.History.LocationState> {
  scenario?: CloseCustomerScenario;
  returnUrl: string;
  returnState?: T;
}

export function CloseLysaCustomerAccountStory({
  addWithdrawalAccountUrl,
}: {
  addWithdrawalAccountUrl?: string;
}) {
  const location = useLocation();
  const history = useHistory<CloseAccountState | undefined>();
  const intl = useIntl();
  const safeNavigation = useSafeNavigation();
  const [returnState] = useState<CloseAccountState | undefined>(
    history.location.state as CloseAccountState | undefined
  );
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);

  const onBack = (currentIndex: number) => {
    if (
      currentIndex ===
      Object.values(ROUTES).indexOf(ROUTES.CLOSE_LYSA_CUSTOMER_CONFIRMATION)
    ) {
      safeNavigation(ROUTES.INTRO);
      return;
    } else {
      safeNavigation(generatePath(Object.values(ROUTES)[currentIndex - 1]));
      return;
    }
  };

  return (
    <PageStripped>
      <Story
        ariaLabelProgress={() =>
          intl.formatMessage(messages.ariaProgressLabel, {
            current: currentIndex + 1,
            total: storyLength,
          })
        }
        header={intl.formatMessage(messages.header)}
        progress={storyProgress}
        showBack={currentIndex !== 0 && currentIndex < 3}
        showClose={true}
        transitionKey={currentIndex.toString()}
        onExit={() => {
          if (returnState) {
            safeNavigation({
              pathname: returnState.returnUrl,
              state: returnState.returnState,
            });
          } else {
            safeNavigation(getNavLink(END_INVESTMENT_URL));
          }
        }}
        onBack={() => onBack(currentIndex)}
      >
        <Switch
          location={location}
          {...{
            order: currentIndex,
          }}
        >
          <Route path={ROUTES.INTRO} exact>
            <Intro
              next={() => {
                history.replace(ROUTES.CLOSE_LYSA_CUSTOMER_CONFIRMATION);
              }}
              addWithdrawalAccountUrl={addWithdrawalAccountUrl}
              returnState={returnState}
            />
          </Route>
          <Route path={ROUTES.YOUR_DATA} exact>
            <YourData
              next={() => {
                history.replace(ROUTES.CLOSE_LYSA_CUSTOMER_CONFIRMATION);
              }}
            />
          </Route>
          <Route path={ROUTES.CLOSE_LYSA_CUSTOMER_CONFIRMATION} exact>
            <CloseLysaCustomerConfirmation
              addWithdrawalAccountUrl={addWithdrawalAccountUrl}
              returnState={returnState}
            />
          </Route>
        </Switch>
      </Story>
    </PageStripped>
  );
}
