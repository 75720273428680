import { RefObject, VoidFunctionComponent } from "react";
import { SavingsHorizonLength } from "../../../../data/dataInvestments";
import { UpdateInvestmentAccountComposition } from "../AccountAdviceCard";
import { ForceSelection } from "./ForceSelection";
import { UpdateOrKeep } from "./UpdateOrKeep";
import { InvestAsLysaAdvice } from "./InvestAsLysaAdvice";
import { InvestedAsAdvisedOrActionTaken } from "./InvestedAsAdvisedOrActionTaken";
import { AccountUpdateAction } from "../../../../pages/reviewAccount/ReviewAccountContext";
import { ReviewUnmatchedEsg } from "./ReviewUnmatchedEsg";

const SHORT_INVESTMENT_MAX_RISK = 50;

interface ButtonProps {
  containerRef: RefObject<HTMLDivElement>;
  account: UpdateInvestmentAccountComposition;
  updateAccount: (accountAction: AccountUpdateAction) => void;
}

export const CardButtons: VoidFunctionComponent<ButtonProps> = ({
  containerRef,
  account,
  updateAccount,
}) => {
  const isInvestedAsAdvised =
    account.newAdvice.takenRisk === account.newAdvice.advice &&
    (account.newAdvice.investmentType === account.oldAdvice.investmentType ||
      account.action === AccountUpdateAction.ADVICE);
  const willBeInvestedAsAdvised = account.action === AccountUpdateAction.ADVICE;

  const isAdviceRiskUpdated =
    account.newAdvice.advice !== account.oldAdvice.advisedRisk;
  const isAdviceInvestmentTypeUpdated =
    account.newAdvice.investmentType !== account.oldAdvice.investmentType;

  const hasUnhandledUnmatchedEsgPreferences =
    typeof account.newAdvice.esgResult.esgBestMatch !== "undefined" &&
    !account.hasReviewedUnmatchedEsgPreferences;

  const isAccountAndAdviceNotUpdated =
    !isAdviceRiskUpdated && !isAdviceInvestmentTypeUpdated;

  const hasTakenActionOnAdvice = typeof account.action !== "undefined";

  const isShortInvestmentHorizon =
    account.newAdvice.savingsHorizon === SavingsHorizonLength.SHORT;

  const isValidAdvice =
    !isShortInvestmentHorizon || account.risk <= SHORT_INVESTMENT_MAX_RISK;

  // Show optional action "Change"
  if (
    isValidAdvice &&
    (isInvestedAsAdvised || willBeInvestedAsAdvised) &&
    !hasUnhandledUnmatchedEsgPreferences
  ) {
    return <InvestedAsAdvisedOrActionTaken updateAccount={updateAccount} />;
  }

  // Show optional action "Change to Lysa advice"
  if (
    isValidAdvice &&
    (isAccountAndAdviceNotUpdated || hasTakenActionOnAdvice) &&
    !hasUnhandledUnmatchedEsgPreferences
  ) {
    return <InvestAsLysaAdvice updateAccount={updateAccount} />;
  }

  // Show forced action "Update" || "Change to lysa advice"
  if (!isValidAdvice || isAdviceInvestmentTypeUpdated) {
    return (
      <ForceSelection
        containerRef={containerRef}
        account={account}
        updateAccount={updateAccount}
      />
    );
  }

  // Show forced action "Update" || "Keep"
  if (isAdviceRiskUpdated && !hasTakenActionOnAdvice) {
    return (
      <UpdateOrKeep
        containerRef={containerRef}
        account={account}
        updateAccount={updateAccount}
      />
    );
  }

  // Show forced action "Update preferences"
  if (hasUnhandledUnmatchedEsgPreferences) {
    return (
      <ReviewUnmatchedEsg
        containerRef={containerRef}
        account={account}
        updateAccount={updateAccount}
      />
    );
  }

  // Show optional action "Change"
  return <InvestedAsAdvisedOrActionTaken updateAccount={updateAccount} />;
};
