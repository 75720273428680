import { Fragment } from "react";
import { FundType, Fund } from "../../../../data/dataFunds";
import { FormattedPercentage } from "../../../../components/formattedPercentage/FormattedPercentage";
import { InstrumentName } from "../../../../components/instrumentName/InstrumentName";
import "../components/PositionsList.scss";
import { isTranslatedFund } from "../../../../components/fundName/FundName";
import { RemainingPositions } from "../components/RemainingPositions";

interface Props {
  funds: Fund[];
  fundType: FundType;
}

export function PositionsExampleList(props: Props) {
  const { funds, fundType } = props;

  const positions = funds.filter(
    (fund) => fund.fundType === fundType && typeof fund.isin !== "undefined"
  );

  return (
    <div>
      {positions.map(
        ({
          isin,
          largePositions,
          remainingPositions,
          remainingPositionsPercent,
          fundShareClasses,
        }) => {
          const shareClass = fundShareClasses.find(
            (shareClass) => shareClass.isin === isin
          );

          return (
            <Fragment key={isin}>
              {largePositions.map((position) => {
                const translatedInstrument = position.instruments.find(
                  (instrument) => isTranslatedFund(instrument.isin)
                );
                return (
                  <Fragment key={position.instruments[0].isin}>
                    <dt>
                      <b>
                        {translatedInstrument ? (
                          <InstrumentName isin={translatedInstrument.isin} />
                        ) : (
                          position.instruments
                            .map(({ name }) => name)
                            .join(", ")
                        )}
                      </b>
                    </dt>
                    <dd>
                      <span>
                        <FormattedPercentage
                          value={position.percent || 0}
                          decimals={2}
                        />
                      </span>
                    </dd>
                  </Fragment>
                );
              })}
              <RemainingPositions
                positions={remainingPositions}
                percent={remainingPositionsPercent}
              />
              {isin && shareClass && (
                <dt className="summary-cell">
                  <b>
                    {isTranslatedFund(isin) ? (
                      <InstrumentName isin={isin} />
                    ) : (
                      shareClass.name
                    )}
                  </b>
                </dt>
              )}
              <dd className="summary-cell"></dd>
            </Fragment>
          );
        }
      )}
    </div>
  );
}
