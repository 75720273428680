import {
  Card,
  Form,
  LysaFormRef,
  MaxValidator,
  MinValidator,
  MoneyInput,
  Button,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { useContext, useEffect, useRef, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router";
import { LysaAccountSelectionCard } from "../../../../../../components/lysaAccountSelectionCard/LysaAccountSelectionCard";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { LocalizationContext } from "../../../../../../context/LocalizationContext";
import {
  dataAccounts,
  CompoundAccount,
} from "../../../../../../data/dataAccounts";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { useIsPerson } from "../../../../../../hooks/useIsPerson";
import { DEPOSITS_OVERVIEW_URL } from "../../../../../../pages/deposits/overview/Recommendation";
import { SwishDepositContext } from "../SwishDepositContext";
import { useMinDepositAmount } from "../../../../../../experiments/minDeposit/useMinDepositAmount";
import {
  FeatureDomain,
  SubDomain,
  dataCustomerTrackingService,
} from "../../../../../../data/dataCustomerTracking";

interface Props {
  next: () => void;
}

const messages = defineMessages({
  lysaAccountLabel: {
    id: "sweden.deposits.swish.story.account-selection.account-label",
  },
  lysaAccountRequired: {
    id: "sweden.deposits.swish.story.account-selection.account-required",
  },
  amountLabel: {
    id: "sweden.deposits.swish.story.account-selection.amount-label",
  },
  amountRequired: {
    id: "sweden.deposits.swish.story.account-selection.amount-required",
  },
  amountMin: {
    id: "sweden.deposits.swish.story.account-selection.amount-min",
  },
  amountMax: {
    id: "sweden.deposits.swish.story.account-selection.amount-max",
  },
});

const MAX_DEPOSIT = 150_000;

export function SwishDepositAccountSelection({ next }: Props) {
  const minDepositAmount = useMinDepositAmount();
  const history = useHistory();
  const formRef = useRef<LysaFormRef>();
  const [accounts, setAccounts] = useState<CompoundAccount[]>();
  const [error, setError] = useState(false);
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const swishDepositContext = useContext(SwishDepositContext);
  const isPerson = useIsPerson();

  useEffect(() => {
    dataAccounts
      .getAllAccounts()
      .then((response) =>
        setAccounts([
          ...response.investmentAccounts,
          ...response.savingsAccounts,
        ])
      )
      .catch(() => {
        setError(true);
      });
  }, []);

  useEffect(() => {
    if (!accounts || !swishDepositContext.state.accountId) {
      return;
    }
  }, [accounts, swishDepositContext.state.accountId]);

  if (error) {
    return (
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <TranslatedText
          id={"sweden.deposits.swish.story.account-selection.error"}
        />
      </Snackbar>
    );
  }
  if (!accounts) {
    return <Spinner />;
  }

  return (
    <div>
      <h2>
        <TranslatedText id="sweden.deposits.swish.story.account-selection.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (isPerson) {
            dataCustomerTrackingService.postEvent({
              domain: FeatureDomain.TRANSFERS,
              subDomain: SubDomain.DEPOSIT,
              eventName: "clickedSwishDepositButton",
              payload: {
                amount:
                  swishDepositContext.state.amount !== undefined &&
                  !isNaN(swishDepositContext.state.amount)
                    ? swishDepositContext.state.amount.toString()
                    : "",
              },
            });

            if (!formRef.current?.isValid) {
              return;
            }
            next();
          } else {
            history.push(getNavLink(DEPOSITS_OVERVIEW_URL));
          }
        }}
      >
        <LysaAccountSelectionCard
          accounts={accounts}
          selectedAccount={accounts.find(
            (account) =>
              account.accountId === swishDepositContext.state.accountId
          )}
          onChange={({ accountId }) =>
            swishDepositContext.setState({ accountId })
          }
          legend={intl.formatMessage(messages.lysaAccountLabel)}
        />
        <Card>
          <MoneyInput
            currency={localizationContext.state.currency}
            value={
              swishDepositContext.state.amount &&
              !isNaN(swishDepositContext.state.amount)
                ? swishDepositContext.state.amount.toString()
                : ""
            }
            onChange={(event) => {
              swishDepositContext.setState({
                amount: parseInt(event),
              });
            }}
            label={intl.formatMessage(messages.amountLabel)}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.amountRequired)
              ),
              new MinValidator(
                minDepositAmount,
                intl.formatMessage(messages.amountMin, {
                  minAmount: intl.formatNumber(minDepositAmount, {
                    style: "currency",
                    currency: localizationContext.state.currency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }),
                })
              ),
              new MaxValidator(
                MAX_DEPOSIT,
                intl.formatMessage(messages.amountMax, {
                  maxAmount: intl.formatNumber(MAX_DEPOSIT, {
                    style: "currency",
                    currency: localizationContext.state.currency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }),
                })
              ),
            ]}
          />
        </Card>
        {typeof swishDepositContext.state.amount !== "undefined" &&
          swishDepositContext.state.amount > 3_000 &&
          swishDepositContext.state.amount < 150_001 && (
            <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
              <FormattedMessage id="sweden.deposits.swish.story.account-selection.amount-warning" />
            </Snackbar>
          )}
        {isPerson ? (
          <Button
            type="submit"
            block
            label={
              <TranslatedText id="sweden.deposits.swish.story.account-selection.button" />
            }
          />
        ) : (
          <>
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="sweden.deposits.swish.story.account-selection.corporate-error" />
            </Snackbar>
            <Button
              type="submit"
              block
              label={
                <TranslatedText id="sweden.deposits.swish.story.account-selection.corporate-button" />
              }
            />
          </>
        )}
      </Form>
    </div>
  );
}
