import { lazy, ComponentType } from "react";
import { getNavLink } from "../../hooks/useCountryUrls";
import { CHUNK_LOAD_ERROR_PAGE_URL } from "../../pages/chunkLoadError/ChunkLoadErrorPage";

type PreloadableComponent<T extends ComponentType<any>> = T & {
  preload: () => void;
};

export const CHUNK_LOAD_ERROR = "ChunkLoadError";
const RELOADED_SEARCH_PARAM = "reloaded";

export function preloadableImport<T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>
): PreloadableComponent<T> {
  const module = lazy(factory) as unknown as PreloadableComponent<T>;
  module.preload = () =>
    factory().catch((error: Error) => {
      handleChunkLoadError(error);
    });
  return module;
}

export function handleChunkLoadError(error: Error) {
  if (error.name === CHUNK_LOAD_ERROR) {
    const url = new URL(window.location.href);

    if (url.searchParams.get(RELOADED_SEARCH_PARAM) === CHUNK_LOAD_ERROR) {
      console.error("ChunkLoadError: Reloading failed");
      window.setTimeout(() => {
        window.location.assign(getNavLink(CHUNK_LOAD_ERROR_PAGE_URL));
      }, 500);
    } else {
      console.error("ChunkLoadError: Reloading");
      url.searchParams.set(RELOADED_SEARCH_PARAM, CHUNK_LOAD_ERROR);
      window.setTimeout(() => {
        window.location.assign(url.toString());
      }, 500);
    }
  } else {
    throw error;
  }
}
