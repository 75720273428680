import { useCallback, useContext, useMemo, useReducer } from "react";
import * as React from "react";
import { Store, VIEW_CHANGE_IN_PERCENTAGE } from "../Store";

export interface LayoutState {
  showCookieConsent: boolean;
  hideNav: boolean;
  pageStripped: boolean;
  showCurrency: boolean;
  showBanner: boolean;
}

export interface LayoutContextProps {
  state: LayoutState;
  setState: (newState: Partial<LayoutState>) => void;
}

export const LayoutContext = React.createContext<LayoutContextProps>(
  {} as LayoutContextProps
);

export const withLayout =
  <P extends object>(
    Component: React.ComponentType<React.PropsWithChildren<P>>
  ): React.FC<React.PropsWithChildren<Omit<P, keyof LayoutContextProps>>> =>
  (props) =>
    (
      <LayoutContext.Consumer>
        {(contextProps) => <Component {...(props as P)} {...contextProps} />}
      </LayoutContext.Consumer>
    );

function stateReducer(state: LayoutState, newState: Partial<LayoutState>) {
  return { ...state, ...newState };
}

export const LayoutContextProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {
    pageStripped: false,
    hideNav: false,
    showCookieConsent: false,
    showCurrency: Store.getValue(VIEW_CHANGE_IN_PERCENTAGE),
    showBanner: true,
  });

  const contextValue = useMemo(() => {
    return {
      state,
      setState,
    };
  }, [state]);

  return (
    <LayoutContext.Provider value={contextValue}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useShowCurrency = () => {
  const layout = useContext(LayoutContext);

  if (typeof layout === "undefined") {
    throw new Error("Missing Layout Context Provider");
  }

  const toggleCurrency = useCallback(() => {
    Store.setValue(VIEW_CHANGE_IN_PERCENTAGE, !layout.state.showCurrency);
    layout.setState({
      showCurrency: !layout.state.showCurrency,
    });
  }, [layout]);

  return {
    showCurrency: layout.state.showCurrency,
    toggleCurrency,
  };
};
