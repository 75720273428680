import { useContext, VoidFunctionComponent } from "react";
import { generatePath, Link } from "react-router-dom";
import { LocalizationContext } from "../../../../../context/LocalizationContext";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { AccountPageCard } from "../accountPageCard/AccountPageCard";
import { LysaLink, Spinner } from "@lysaab/ui-2";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { DOCUMENT_PAGE_URL } from "../../../../documents/DocumentsPage";
import { SavingsAccountId } from "../../../../../data/dataAccounts";
import { useLoadingContext } from "../../../../../context/LoadingContext";

import "./DocsCard.scss";

interface Props {
  accountId: SavingsAccountId;
}

export const DocsCard: VoidFunctionComponent<Props> = ({ accountId }) => {
  const localizationContext = useContext(LocalizationContext);
  const { isLoading } = useLoadingContext(false);

  if (typeof localizationContext.state.country === "undefined") {
    return null;
  }

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <AccountPageCard
      className="docs-card"
      bottomLink={
        <Link
          to={getNavLink(
            generatePath(DOCUMENT_PAGE_URL, {
              group: undefined,
              document: undefined,
            })
          )}
        >
          <TranslatedText id="savingsAccountPage.docs.show" />
        </Link>
      }
    >
      <ul>
        <li className="docs-card__list-item">
          <LysaLink
            component={Link}
            to={getNavLink(
              generatePath(DOCUMENT_PAGE_URL, {
                group: accountId,
                document:
                  "legal/se/sv/avtal-och-allmänna-villkor-för-sparkonto",
              })
            )}
          >
            <TranslatedText id="savingsAccountPage.docs.termsAndConditions" />
          </LysaLink>
        </li>
        <li className="docs-card__list-item">
          <LysaLink
            component={Link}
            to={getNavLink(
              generatePath(DOCUMENT_PAGE_URL, {
                group: accountId,
                document: "legal/se/sv/information-till-insättare",
              })
            )}
          >
            <TranslatedText id="savingsAccountPage.docs.infoToDepositors" />
          </LysaLink>
        </li>
        <li className="docs-card__list-item">
          <LysaLink
            component={Link}
            to={getNavLink(
              generatePath(DOCUMENT_PAGE_URL, {
                group: accountId,
                document: "legal/se/sv/information-till-insättare-borgo",
              })
            )}
          >
            <TranslatedText id="savingsAccountPage.docs.infoToDepositorsBorgo" />
          </LysaLink>
        </li>
        <li className="docs-card__list-item">
          <LysaLink
            component={Link}
            to={getNavLink(
              generatePath(DOCUMENT_PAGE_URL, {
                group: accountId,
                document: "legal/se/sv/viktigt-om-insättningsgaranti",
              })
            )}
          >
            <TranslatedText id="savingsAccountPage.docs.importantAboutDeposits" />
          </LysaLink>
        </li>
        <li className="docs-card__list-item">
          <LysaLink
            component={Link}
            to={getNavLink(
              generatePath(DOCUMENT_PAGE_URL, {
                group: accountId,
                document: "legal/se/sv/distansavtalsinformation-lysa-sparkonto",
              })
            )}
          >
            <TranslatedText id="savingsAccountPage.docs.savingsAccountDistanceLink" />
          </LysaLink>
        </li>
      </ul>
    </AccountPageCard>
  );
};
