import { useEffect, useState } from "react";
import {
  InvestmentAccount,
  CompoundAccountId,
  dataAccounts,
  SavingsAccount,
} from "../data/dataAccounts";
import { ClosedAccount, dataProfile } from "../data/dataProfile";

export function useAccount(
  accountId: CompoundAccountId | undefined
): InvestmentAccount | SavingsAccount | ClosedAccount | undefined {
  const [accounts, setAccounts] = useState<
    (InvestmentAccount | SavingsAccount | ClosedAccount)[]
  >([]);
  const [account, setAccount] = useState<
    InvestmentAccount | SavingsAccount | ClosedAccount
  >();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      dataAccounts.getAllAccounts(),
      dataProfile.getClosedAccounts(),
    ]).then(([accounts, closedAccounts]) => {
      setAccounts([
        ...accounts.investmentAccounts,
        ...accounts.savingsAccounts,
        ...closedAccounts,
        ...accounts.sharedInvestmentAccounts,
        ...accounts.sharedSavingsAccounts,
      ]);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const account = accounts.find((account) => account.accountId === accountId);
    if (account) {
      setAccount(account);
    } else {
      setAccount(undefined);
    }
  }, [accounts, accountId]);

  if (!accountId || !account || loading) {
    return undefined;
  }

  return account;
}
