import { useCallback, VoidFunctionComponent } from "react";
import { Story } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { Switch, Route, useHistory, generatePath } from "react-router";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { UserManagementSelectUser } from "./userManagementSelectUser/UserManagementSelectUser";
import {
  useAddBankIdSign,
  useDeleteBankIdSign,
  UserManagementSign,
  useUpdateBankIdSign,
} from "./userManagementSign/UserManagementSign";
import { UserManagementDone } from "./userManagementDone/UserManagementDone";
import { UserManagementContextProvider } from "./UserManagementContext";
import { PageStripped } from "../../../../../pages/PageStripped";
import { messages } from "./Messages";
import { UserManagementDelete } from "./userManagementDelete/UserManagementDelete";
import { UserManagementEdit } from "./userManagementEdit/UserManagementEdit";
import { COMPANY_SETTINGS_PAGE_URL } from "../companySettings/CompanySettingsPage";
import { useStoryValues } from "../../../../../hooks/useStoryValues";

export const USER_MANAGEMENT_URL = "/user-management";

export const BASE_ROUTES = {
  HOME: USER_MANAGEMENT_URL,
  EDIT: `${USER_MANAGEMENT_URL}/:action(add|update)`,
  DELETE: `${USER_MANAGEMENT_URL}/delete`,
  SIGN_ADD: `${USER_MANAGEMENT_URL}/add/sign`,
  SIGN_UPDATE: `${USER_MANAGEMENT_URL}/update/sign`,
  SIGN_DELETE: `${USER_MANAGEMENT_URL}/delete/sign`,
  DONE: `${USER_MANAGEMENT_URL}/:action(add|update|delete)/done`,
};

export enum UserManagementBasePaths {
  ADD = "add",
  UPDATE = "update",
  DELETE = "delete",
}

const { ADD, UPDATE, DELETE } = UserManagementBasePaths;

export const UserManagementStory: VoidFunctionComponent = () => {
  const intl = useIntl();
  const history = useHistory();
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);

  const editAddPath = generatePath(ROUTES.EDIT, { action: ADD });
  const editUpdatePath = generatePath(ROUTES.EDIT, { action: UPDATE });
  const doneAddPath = generatePath(ROUTES.DONE, { action: ADD });
  const doneUpdatePath = generatePath(ROUTES.DONE, { action: UPDATE });
  const doneDeletePath = generatePath(ROUTES.DONE, { action: DELETE });

  const onBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const onSignAddComplete = useCallback(() => {
    history.replace(doneAddPath);
  }, [doneAddPath, history]);

  const onSignUpdateComplete = useCallback(() => {
    history.replace(doneUpdatePath);
  }, [doneUpdatePath, history]);

  const onSignDeleteComplete = useCallback(() => {
    history.replace(doneDeletePath);
  }, [doneDeletePath, history]);

  const { startSign: startAddSign, ...addBankIdRest } = useAddBankIdSign(
    onSignAddComplete,
    ROUTES.SIGN_ADD
  );
  const { startSign: startUpdateSign, ...updateBankIdRest } =
    useUpdateBankIdSign(onSignUpdateComplete, ROUTES.SIGN_UPDATE);
  const { startSign: startDeleteSign, ...deleteBankIdRest } =
    useDeleteBankIdSign(onSignDeleteComplete, ROUTES.SIGN_DELETE);

  return (
    <PageStripped className="user-management-story">
      <UserManagementContextProvider>
        <Story
          ariaLabelProgress={() =>
            intl.formatMessage(messages.ariaProgressLabel, {
              current: currentIndex + 1,
              total: storyLength,
            })
          }
          header={intl.formatMessage(messages.header)}
          progress={storyProgress}
          showBack={
            currentIndex > 0 &&
            currentIndex <
              Object.values(BASE_ROUTES).indexOf(BASE_ROUTES.SIGN_ADD)
          }
          showClose={true}
          transitionKey={currentIndex.toString()}
          onExit={() => {
            history.push(getNavLink(COMPANY_SETTINGS_PAGE_URL));
          }}
          onBack={onBack}
        >
          <Switch
            location={history.location}
            {...{
              order: currentIndex,
            }}
          >
            <Route path={ROUTES.HOME} exact>
              <UserManagementSelectUser
                add={() => {
                  history.push(editAddPath);
                }}
                edit={() => {
                  history.push(editUpdatePath);
                }}
              />
            </Route>

            <Route path={ROUTES.EDIT} exact>
              <UserManagementEdit
                next={(
                  action:
                    | UserManagementBasePaths.ADD
                    | UserManagementBasePaths.UPDATE,
                  { identificationNumber, email, admin }
                ) => {
                  if (action === UserManagementBasePaths.ADD) {
                    startAddSign(identificationNumber, email, admin);
                  } else if (action === UserManagementBasePaths.UPDATE) {
                    startUpdateSign(identificationNumber, email, admin);
                  }
                }}
                remove={() => {
                  history.push(ROUTES.DELETE);
                }}
              />
            </Route>

            <Route path={ROUTES.DELETE} exact>
              <UserManagementDelete
                next={(identificationNumber: string) => {
                  startDeleteSign(identificationNumber);
                }}
              />
            </Route>

            <Route path={ROUTES.SIGN_ADD} exact>
              <UserManagementSign
                action={UserManagementBasePaths.ADD}
                startSign={startAddSign}
                {...addBankIdRest}
              />
            </Route>

            <Route path={ROUTES.SIGN_UPDATE} exact>
              <UserManagementSign
                action={UserManagementBasePaths.UPDATE}
                startSign={startUpdateSign}
                {...updateBankIdRest}
              />
            </Route>

            <Route path={ROUTES.SIGN_DELETE} exact>
              <UserManagementSign
                action={UserManagementBasePaths.DELETE}
                startSign={startDeleteSign}
                {...deleteBankIdRest}
              />
            </Route>

            <Route path={ROUTES.DONE} exact>
              <UserManagementDone
                home={() => {
                  history.push(ROUTES.HOME);
                }}
              />
            </Route>
          </Switch>
        </Story>
      </UserManagementContextProvider>
    </PageStripped>
  );
};
