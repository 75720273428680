import { VoidFunctionComponent } from "react";
import { defineMessages, useIntl } from "react-intl";
import { HistoricFeeSummary } from "../../../../data/dataFees";
import { CostBodyCell } from "../components/costBodyCell/CostBodyCell";
import { CostHeaderCell } from "../components/costHeaderCell/CostHeaderCell";
import { FeeBodyCell } from "../components/feeBodyCell/FeeBodyCell";
import { FeeHeaderCell } from "../components/feeHeaderCell/FeeHeaderCell";
import { LysaTable } from "../../../../components/lysaTable/LysaTable";

const messages = defineMessages({
  fundManagementFee: {
    id: "fees.feeTables.summary.fundManagementFee",
  },
  paidDiscretionaryFee: {
    id: "fees.feeTables.summary.paidDiscretionaryFee",
  },
  rebatedDiscretionaryFee: {
    id: "fees.feeTables.summary.rebatedDiscretionaryFee",
  },
  underlyingAssetsFee: {
    id: "fees.feeTables.summary.underlyingAssetsFee",
  },
  underlyingTransactionsCost: {
    id: "fees.feeTables.summary.underlyingTransactionsCost",
  },
  underlyingTransactionsFee: {
    id: "fees.feeTables.summary.underlyingTransactionsFee",
  },
  averageUnderlyingAssetsCost: {
    id: "fees.feeTables.summary.averageUnderlyingAssetsCost",
  },
  avgDiscretionaryCost: {
    id: "fees.feeTables.summary.avgDiscretionaryCost",
  },
  averageManagementCost: {
    id: "fees.feeTables.summary.averageManagementCost",
  },
  total: {
    id: "fees.feeTables.summary.total",
  },
});

interface Props {
  summary: HistoricFeeSummary;
}

export const SummaryTable: VoidFunctionComponent<Props> = ({ summary }) => {
  const intl = useIntl();

  return (
    <LysaTable>
      <thead>
        <tr>
          <FeeHeaderCell
            text={intl.formatMessage(messages.paidDiscretionaryFee)}
          />
          <FeeHeaderCell
            text={intl.formatMessage(messages.fundManagementFee)}
          />
          <FeeHeaderCell
            text={intl.formatMessage(messages.underlyingAssetsFee)}
          />
          <FeeHeaderCell
            text={intl.formatMessage(messages.underlyingTransactionsFee)}
          />
          <FeeHeaderCell
            text={intl.formatMessage(messages.rebatedDiscretionaryFee)}
          />
          <CostHeaderCell
            text={intl.formatMessage(messages.avgDiscretionaryCost)}
          />
          <CostHeaderCell
            text={intl.formatMessage(messages.averageManagementCost)}
          />
          <CostHeaderCell
            text={intl.formatMessage(messages.averageUnderlyingAssetsCost)}
          />
          <CostHeaderCell
            text={intl.formatMessage(messages.underlyingTransactionsCost)}
          />
          <th>{intl.formatMessage(messages.total)}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <FeeBodyCell
            value={summary.paidDiscretionaryFee}
            label={intl.formatMessage(messages.paidDiscretionaryFee)}
          />
          <FeeBodyCell
            value={summary.fundManagementFee}
            label={intl.formatMessage(messages.fundManagementFee)}
          />
          <FeeBodyCell
            value={summary.underlyingAssetsFee}
            label={intl.formatMessage(messages.underlyingAssetsFee)}
          />
          <FeeBodyCell
            value={summary.underlyingTransactionsFee}
            label={intl.formatMessage(messages.underlyingTransactionsFee)}
          />
          <FeeBodyCell
            value={summary.rebatedDiscretionaryFee}
            label={intl.formatMessage(messages.rebatedDiscretionaryFee)}
          />
          <FeeBodyCell
            value={summary.total}
            label={intl.formatMessage(messages.total)}
          />
          <CostBodyCell
            value={summary.avgDiscretionaryCost}
            label={intl.formatMessage(messages.avgDiscretionaryCost)}
          />
          <CostBodyCell
            value={summary.averageManagementCost}
            label={intl.formatMessage(messages.averageManagementCost)}
          />
          <CostBodyCell
            value={summary.averageUnderlyingAssetsCost}
            label={intl.formatMessage(messages.averageUnderlyingAssetsCost)}
          />
          <CostBodyCell
            value={summary.underlyingTransactionsCost}
            label={intl.formatMessage(messages.underlyingTransactionsCost)}
          />
          <CostBodyCell
            value={summary.averageTotalCost}
            label={intl.formatMessage(messages.total)}
          />
        </tr>
      </tbody>
    </LysaTable>
  );
};
