import { useRef, useEffect, useCallback, useState } from "react";
import { DateTime } from "luxon";
import ReactModal from "react-modal";
import { Button, Icon } from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { dataLogin } from "../../data/dataLogin";
import "./LogoutWarning.scss";
import { useHistory } from "react-router";
import { getNavLink } from "../../hooks/useCountryUrls";
import { LOGOUT_PAGE_URL } from "../../pages/logout/LogoutPage";
import { useIsSignedIn } from "../../hooks/useIsSignedIn";
import { Modal } from "../modal/Modal";
import { getLoginCookie } from "../../utils/LoginCookie";

const WARNING_TIMER = 25 * 60 * 1000; // 25 minutes
const LOGOUT_TIMER = 30 * 60 * 1000 - 10; // 30 minutes - 10 sec to prevent bugs
const CONTROL_INTERVAL = 5 * 1000; // 5 seconds

ReactModal.setAppElement("#root");

const messages = defineMessages({
  header: {
    id: "logout.header",
  },
  stayButton: {
    id: "logout.button.stay",
  },
  logoutButton: {
    id: "logout.button.logout",
  },
});

export function LogoutWarning() {
  const startTime = useRef(DateTime.local());
  const timer = useRef<NodeJS.Timeout>();
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();
  const history = useHistory();
  const logoutNav = useRef(() => history.push(getNavLink(LOGOUT_PAGE_URL)));
  const isSignedIn = useIsSignedIn();

  const poll = useCallback(() => {
    if (
      startTime.current
        .plus({ milliseconds: LOGOUT_TIMER })
        .diffNow("milliseconds")
        .valueOf() < 0 ||
      getLoginCookie() === undefined
    ) {
      /**
       * Force logout
       */
      setIsOpen(false);
      logoutNav.current();
    } else if (
      startTime.current
        .plus({ milliseconds: WARNING_TIMER })
        .diffNow("milliseconds")
        .valueOf() < 0
    ) {
      /**
       * Show warning modal
       */
      setIsOpen(true);
    }
  }, []);

  useEffect(() => {
    if (isSignedIn) {
      document.addEventListener("visibilitychange", poll);
      return () => {
        document.removeEventListener("visibilitychange", poll);
      };
    }
  }, [isSignedIn, poll]);

  useEffect(() => {
    logoutNav.current = () =>
      history.push(getNavLink(LOGOUT_PAGE_URL) + "?timeout");
  }, [history]);

  useEffect(() => {
    startTime.current = DateTime.local();
    if (isSignedIn) {
      timer.current = setInterval(poll, CONTROL_INTERVAL);
    }
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [isSignedIn, poll]);

  const logout = () => {
    setIsOpen(false);
    history.push(getNavLink(LOGOUT_PAGE_URL));
  };

  const refreshToken = () => {
    setIsOpen(false);
    dataLogin
      .keepAlive()
      .then(() => {
        startTime.current = DateTime.local();
      })
      .catch(() => {
        logout();
      });
  };

  return (
    <Modal showModal={isOpen} hideHeader width={600}>
      <div className="logout-warning">
        <div className="logout-icon">
          <Icon.Error size={48} />
        </div>
        <h1>{intl.formatMessage(messages.header)}</h1>
        <Button
          block
          onClick={refreshToken}
          label={intl.formatMessage(messages.stayButton)}
        />

        <Button
          variant="secondary"
          block
          onClick={logout}
          label={intl.formatMessage(messages.logoutButton)}
        />
      </div>
    </Modal>
  );
}
