import { useCallback, useEffect, useRef } from "react";
import { EventTracker } from "../../../../../components/eventTracker/EventTracker";
import { TrackerEvent } from "../../../../../data/dataCustomerTracking";
import {
  getEligibility,
  isValidEligibilityPerson,
} from "../../../../../data/dataInvestments";
import {
  isValidEligibilityStatePerson,
  useEligibilityContext,
} from "../../../../../context/EligibilityContext";

export function useBadEconomyEventTracking() {
  const eventTrackerTimout = useRef<NodeJS.Timeout | "POSTED">();
  const [eligibilityState] = useEligibilityContext();

  const postBadEconomyEvent = useCallback(() => {
    const data = getEligibility(eligibilityState);
    if (
      eventTrackerTimout.current &&
      eventTrackerTimout.current !== "POSTED" &&
      isValidEligibilityPerson(data)
    ) {
      clearTimeout(eventTrackerTimout.current);
      EventTracker.track({
        event: TrackerEvent.REVIEW_ECONOMIC_SITUATION,
        message: {
          isBlocked: true,
          ...data,
        },
      });
    }
  }, [eligibilityState]);

  useEffect(() => {
    if (
      isValidEligibilityStatePerson(eligibilityState) &&
      eligibilityState.showWarning &&
      typeof eventTrackerTimout.current === "undefined"
    ) {
      eventTrackerTimout.current = setTimeout(() => {
        if (eligibilityState.hasBadEconomy) {
          eventTrackerTimout.current = "POSTED";
          EventTracker.track({
            event: TrackerEvent.REVIEW_ECONOMIC_SITUATION,
            message: {
              isBlocked: true,
              ...eligibilityState,
            },
          });
        } else {
          if (eventTrackerTimout.current !== "POSTED") {
            eventTrackerTimout.current = undefined;
          }
        }
      }, 20000);
    }
  }, [eligibilityState]);

  return postBadEconomyEvent;
}
