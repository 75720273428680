import { useCallback, useContext } from "react";
import { Redirect } from "react-router";
import { AccountLoading } from "../../../../components/klarna/accountLoading/AccountLoading";
import { KlarnaAccount } from "../../../../data/dataKlarna";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { AddAccountKlarnaContext } from "../AddAccountKlarnaContext";
import { ADD_EXTERNAL_KLARNA_URL } from "../AddAccountKlarnaStory";

interface Props {
  next: () => void;
}

export function AccountLoadingWrapper({ next }: Props) {
  const klarnaContext = useContext(AddAccountKlarnaContext);
  const bank = klarnaContext.state.bank;
  const setState = klarnaContext.setState;

  const onComplete = useCallback(
    (accounts: KlarnaAccount[]) => {
      setState({ klarnaAccounts: accounts });
      setTimeout(() => {
        next();
      }, 0);
    },
    [next, setState]
  );

  if (!bank) {
    return <Redirect to={getNavLink(ADD_EXTERNAL_KLARNA_URL)} />;
  }
  return <AccountLoading bank={bank} onComplete={onComplete} />;
}
