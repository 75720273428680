import { VoidFunctionComponent } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { Amount } from "../../../../../../../components/amount/Amount";
import { GridCol } from "../../../../../../../components/grid/gridCol/GridCol";
import {
  FeeTransaction,
  FeeType,
} from "../../../../../../../data/dataCorporate";
import { DataDisplay } from "../../../../../../../components/dataDisplay/DataDisplay";
import { feeTypesMessages } from "../TransactionUtils";

export const FeeDiscInsurance: VoidFunctionComponent<{
  transaction: FeeTransaction;
}> = ({ transaction }) => {
  const intl = useIntl();
  return (
    <>
      <GridCol xsmall={2} className="text-container">
        <DataDisplay
          type="list-item"
          title={
            <FormattedMessage id="sweden.accountingPage.dataDisplay.feeDiscInsurance.title.date" />
          }
          text={<FormattedDate value={transaction.booked} />}
        />
      </GridCol>

      {/* START NO PRINT */}

      <GridCol xsmall={2} className="text-container no-print">
        {transaction.feeType === FeeType.INSURANCE ? null : (
          <DataDisplay
            type="list-item"
            title={
              <FormattedMessage id="sweden.accountingPage.dataDisplay.feeDiscInsurance.title.receipt" />
            }
            text={
              <a
                download
                href={`${process.env.REACT_APP_API}/receipt?transactionId=${transaction.transactionId}`}
              >
                <FormattedMessage id="sweden.accountingPage.dataDisplay.feeDiscInsurance.title.getReceipt" />
              </a>
            }
          />
        )}
      </GridCol>

      <GridCol xsmall={3} className="text-container text-right no-print">
        <DataDisplay
          type="list-item"
          title={
            <FormattedMessage id="sweden.accountingPage.dataDisplay.feeDiscInsurance.title.feeExcludingVat" />
          }
          text={<Amount amount={transaction.feeExcludingVat} decimals={2} />}
        />
      </GridCol>

      <GridCol xsmall={3} className="text-container text-right no-print">
        <DataDisplay
          type="list-item"
          title={
            <FormattedMessage id="sweden.accountingPage.dataDisplay.feeDiscInsurance.title.feeIncludingVat" />
          }
          text={<Amount amount={transaction.fee} decimals={2} />}
        />
      </GridCol>

      {/* END NO PRINT */}

      <GridCol xsmall={4} className="text-container text-right only-print">
        <DataDisplay
          type="list-item"
          title={
            <FormattedMessage id="sweden.accountingPage.dataDisplay.feeDiscInsurance.title.feeExcludingVat" />
          }
          text={<Amount amount={transaction.feeExcludingVat} decimals={2} />}
        />
      </GridCol>

      <GridCol xsmall={4} className="text-container text-right only-print">
        <DataDisplay
          type="list-item"
          title={
            <FormattedMessage id="sweden.accountingPage.dataDisplay.feeDiscInsurance.title.feeIncludingVat" />
          }
          text={<Amount amount={transaction.fee} decimals={2} />}
        />
      </GridCol>

      <GridCol xsmall={2} className="text-container text-right">
        <DataDisplay
          type="list-item"
          title={
            <FormattedMessage id="sweden.accountingPage.dataDisplay.feeDiscInsurance.title.type" />
          }
          text={intl.formatMessage(feeTypesMessages[transaction.feeType])}
        />
      </GridCol>
    </>
  );
};
