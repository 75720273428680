import { useReducer, createContext, FunctionComponent } from "react";
import * as React from "react";
import { AvailableBank, KlarnaAccount } from "../../../data/dataKlarna";
import { TinkAccount, TinkAvailableBank } from "../../../data/dataTink";

export interface AddAccountKlarnaState {
  bank?: AvailableBank;
  tinkBank?: TinkAvailableBank;
  klarnaAccounts?: KlarnaAccount[];
  tinkAccounts?: TinkAccount[];
  selectedAccount?: KlarnaAccount;
  tinkSelectedAccount?: TinkAccount;
  accountVerificationReportId?: string;
}

export interface AddAccountKlarnaContextProps {
  state: AddAccountKlarnaState;
  setState: (newState: Partial<AddAccountKlarnaState>) => void;
}

export interface withAddAccountKlarnaProps {
  AddAccountKlarna: AddAccountKlarnaContextProps;
}

export const AddAccountKlarnaContext =
  createContext<AddAccountKlarnaContextProps>(
    {} as AddAccountKlarnaContextProps
  );

function stateReducer(
  state: AddAccountKlarnaState,
  newState: Partial<AddAccountKlarnaState>
) {
  return { ...state, ...newState };
}

export const AddAccountKlarnaContextProvider: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {});

  return (
    <AddAccountKlarnaContext.Provider value={{ state, setState }}>
      {children}
    </AddAccountKlarnaContext.Provider>
  );
};
