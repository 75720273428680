import {
  useCallback,
  useContext,
  useRef,
  useState,
  VoidFunctionComponent,
} from "react";
import { Button, Form, LysaFormRef, Spinner } from "@lysaab/ui-2";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import {
  dataInvestments,
  getAccountQuestions,
  isValidRecalculateAdvicesRequest,
  isValidUpdateInvestmentProfileRequest,
  RecalculateAdviceRequest,
  UpdateInvestmentProfileRequest,
} from "../../../data/dataInvestments";
import {
  AccountAdviceCard,
  UpdateInvestmentAccountComposition,
} from "../../../pageComponents/accountsAllocation/accountAdviseCard/AccountAdviceCard";
import { LocalizationContext } from "../../../context/LocalizationContext";
import { useAccountSituationContext } from "../context/AccountSituationContext";
import {
  AccountUpdateAction,
  useReviewAccountContext,
  useUpdateReviewAccounts,
} from "../../reviewAccount/ReviewAccountContext";
import { useAccountContext } from "../context/AccountContext";

interface Props {
  next: () => void;
  navigateReviewRiskWarning: (
    reviewAccount: UpdateInvestmentAccountComposition
  ) => void;
  navigateReview: (reviewAccount: UpdateInvestmentAccountComposition) => void;
}

export const AccountAllocation: VoidFunctionComponent<Props> = ({
  next,
  navigateReviewRiskWarning,
  navigateReview,
}) => {
  const localizationContext = useContext(LocalizationContext);
  const [accountState] = useAccountContext();
  const [accountSituationState, setAccountSituationState] =
    useAccountSituationContext();
  const [reviewAccountState] = useReviewAccountContext();
  const updateReviewAccounts = useUpdateReviewAccounts();
  const [loading, setLoading] = useState<boolean>(true);
  const formRef = useRef<LysaFormRef>();

  useEffect(() => {
    if (typeof accountState.account === "undefined") {
      return;
    }

    const recalculateRequest: Partial<RecalculateAdviceRequest> = {
      ...getAccountQuestions(accountState.account),
    };

    if (!isValidRecalculateAdvicesRequest(recalculateRequest)) {
      throw new Error("AccountAllocation - Missing data");
    }

    Promise.all([
      dataInvestments.recalculateAdvice(
        accountState.account.accountId,
        recalculateRequest
      ),
      dataInvestments.getAdviseAccounts(),
    ])
      .then(([recalculatedAdvice, adviseAccounts]) => {
        const adviseAccount = adviseAccounts.find(
          (adviceAccount) =>
            adviceAccount.accountId === recalculatedAdvice.accountId
        );

        if (!adviseAccount) {
          throw new Error("AccountAllocation - missing adviceAccount");
        }

        return [
          {
            accountId: recalculatedAdvice.accountId,
            risk: recalculatedAdvice.takenRisk,
            newAdvice: recalculatedAdvice,
            oldAdvice: adviseAccount,
          },
        ];
      })
      .then(updateReviewAccounts)
      .finally(() => setLoading(false));
  }, [accountState.account, updateReviewAccounts]);

  const onSubmit = useCallback(() => {
    const account = reviewAccountState.accounts[0];

    const investmentRequest: Partial<UpdateInvestmentProfileRequest> = {
      takenRisk: account.risk,
      language: localizationContext.state.language,
      ...getAccountQuestions(account.newAdvice),
    };

    if (
      formRef.current?.isValid &&
      isValidUpdateInvestmentProfileRequest(investmentRequest)
    ) {
      setLoading(true);

      return dataInvestments
        .updateAccountInvestment(account.accountId, investmentRequest)
        .then(next)
        .finally(() => setLoading(false));
    } else {
      setAccountSituationState({ forceErrorsAccountsAllocation: true });
    }
  }, [
    localizationContext.state.language,
    next,
    reviewAccountState.accounts,
    setAccountSituationState,
  ]);

  const handleAccountAction = useCallback(
    (
      action: AccountUpdateAction,
      account: UpdateInvestmentAccountComposition
    ) => {
      const reviewAccount: UpdateInvestmentAccountComposition = JSON.parse(
        JSON.stringify({ ...account, action })
      );

      if (action === AccountUpdateAction.KEEP) {
        navigateReviewRiskWarning(reviewAccount);
      } else {
        navigateReview(reviewAccount);
      }
    },
    [navigateReview, navigateReviewRiskWarning]
  );

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={onSubmit}
      forceValidation={accountSituationState.forceErrorsAccountsAllocation}
    >
      <h2>
        <FormattedMessage id="accountAllocation.header" />
      </h2>

      {loading ||
        typeof reviewAccountState.reviewAccount === "undefined" ||
        (reviewAccountState.accounts.length === 0 && <Spinner />)}

      {reviewAccountState.accounts.map((account) => (
        <AccountAdviceCard
          key={account.accountId}
          account={account}
          onAccountAction={(action) => handleAccountAction(action, account)}
        />
      ))}

      <Button
        block
        type="submit"
        label={<FormattedMessage id="accountAllocation.button.next" />}
      />
    </Form>
  );
};
