import { Button } from "@lysaab/ui-2";
import { Store, STORE_SETTINGS_COOKIE_CONSENT_OK } from "../Store";
import { Page, PageHeader } from "./Page";
import "./StorePage.scss";

export const STORE_PAGE_URL = "/store";

export function StorePage() {
  return (
    <Page className="store-page">
      <PageHeader>
        <h1>Store</h1>
      </PageHeader>

      <h4>Settings</h4>

      <div className="flex">
        <div>Cookie consent</div>
        <div>
          {Store.getValue(STORE_SETTINGS_COOKIE_CONSENT_OK)?.toString()}
          <Button
            size="small"
            onClick={() => {
              Store.setValue(STORE_SETTINGS_COOKIE_CONSENT_OK, null);
            }}
            label="Reset"
          />
        </div>
      </div>
    </Page>
  );
}
