import { useReducer } from "react";
import * as React from "react";
import { CrsResponse } from "../../../data/dataCrs";

export interface CrsState {
  crsInformation?: CrsResponse;
}

export interface CrsContextProps {
  state: CrsState;
  setState: (newState: Partial<CrsState>) => void;
}

export const CrsContext = React.createContext<CrsContextProps>(
  {} as CrsContextProps
);

function stateReducer(state: CrsState, newState: Partial<CrsState>) {
  return { ...state, ...newState };
}

export const CrsContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [state, setState] = useReducer(stateReducer, {});

  return (
    <CrsContext.Provider value={{ state, setState }}>
      {children}
    </CrsContext.Provider>
  );
};
