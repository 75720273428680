import { useEffect, useState, VoidFunctionComponent } from "react";
import { useIntl } from "react-intl";
import { TranslatedText } from "../../components/TranslatedText";
import { useCurrency } from "../../context/LocalizationContext";
import { AccountType } from "../../data/dataAccounts";
import { dataFees, Tax } from "../../data/dataFees";
import { DEFAULT_VALUES } from "./DefaultValues";
import "./KfTaxInformatio.scss";

interface Props {
  oneTimeInvestment: number;
  monthlyInvestment: number;
  accountType: AccountType;
}

export const KfTaxInformation: VoidFunctionComponent<Props> = ({
  monthlyInvestment,
  oneTimeInvestment,
  accountType,
}) => {
  const [yearlyTax, setYearlyTax] = useState<Tax>();
  const [monthlyTax, setMonthlyTax] = useState<Tax>();
  const currency = useCurrency();
  const intl = useIntl();

  useEffect(() => {
    if (
      accountType !== AccountType.DANICA_KF &&
      accountType !== AccountType.KF_SWE
    ) {
      return;
    }
    const amount = oneTimeInvestment
      ? oneTimeInvestment
      : DEFAULT_VALUES[currency].defaultInvestment;

    if (
      amount < DEFAULT_VALUES[currency].minInvestment ||
      amount > DEFAULT_VALUES[currency].maxInvestment
    ) {
      setYearlyTax(undefined);
      return;
    }

    dataFees
      .getEstimatedTaxSingleDeposit(oneTimeInvestment.toString())
      .then((data) => setYearlyTax(data))
      .catch(() => setYearlyTax(undefined));
  }, [accountType, currency, oneTimeInvestment]);

  useEffect(() => {
    if (
      !monthlyInvestment ||
      (accountType !== AccountType.DANICA_KF &&
        accountType !== AccountType.KF_SWE)
    ) {
      setMonthlyTax(undefined);
      return;
    }

    dataFees
      .getEstimatedTaxMonthlyDeposit(monthlyInvestment.toString())
      .then((data) => setMonthlyTax(data))
      .catch(() => setMonthlyTax(undefined));
  }, [accountType, monthlyInvestment]);

  return (
    <div className="allocation-account-corporation-tax">
      {yearlyTax ? (
        <>
          <h4>
            <TranslatedText id="edit-allocation-fees.kf-tax.header" />
          </h4>
          <div>
            <TranslatedText
              id="edit-allocation-fees.kf-tax.description"
              values={{
                year: new Date().getFullYear(),
                taxRate: intl.formatNumber(yearlyTax.taxPercentage / 100, {
                  minimumFractionDigits: 3,
                  maximumFractionDigits: 3,
                  style: "percent",
                }),
              }}
            />
          </div>
          <div>
            <TranslatedText
              id="edit-allocation-fees.kf-tax.one-time-deposit"
              values={{
                investment: intl.formatNumber(oneTimeInvestment, {
                  style: "currency",
                  currency,
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
                tax: intl.formatNumber(yearlyTax.taxAmount, {
                  style: "currency",
                  currency,
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
              }}
            />
          </div>
        </>
      ) : null}

      {monthlyTax ? (
        <div>
          <TranslatedText
            id="edit-allocation-fees.kf-tax.monthly-deposit"
            values={{
              investment: intl.formatNumber(monthlyInvestment, {
                style: "currency",
                currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
              tax: intl.formatNumber(monthlyTax.taxAmount, {
                style: "currency",
                currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
            }}
          />
        </div>
      ) : null}
    </div>
  );
};
