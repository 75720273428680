import { Button, Card, Typography } from "@lysaab/ui-2";
import * as React from "react";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { END_INVESTMENT_URL } from "../../../../../pages/endInvestment/EndInvestment";

export const Done: React.VFC = () => {
  return (
    <>
      <Typography type="h2">
        <TranslatedText id="closeSavingsAccountStory.done.header" />
      </Typography>
      <Card>
        <Typography type="body">
          <TranslatedText id="closeSavingsAccountStory.done.text" />
        </Typography>
      </Card>
      <Button
        component={Link}
        to={getNavLink(END_INVESTMENT_URL)}
        label={<TranslatedText id="closeSavingsAccountStory.done.back" />}
        block
      />
    </>
  );
};
