import { defineMessages } from "react-intl";

export const messages = defineMessages({
  taxableInOtherCountryLabel: {
    id: "sweden.crsFormFields.taxableInOtherCountry.label",
  },
  taxableInOtherCountryYes: {
    id: "sweden.crsFormFields.taxableInOtherCountry.yes",
  },
  taxableInOtherCountryNo: {
    id: "sweden.crsFormFields.taxableInOtherCountry.no",
  },
  taxableInOtherCountryRequired: {
    id: "sweden.crsFormFields.taxableInOtherCountry.required",
  },
  residenceCountryLabel: {
    id: "sweden.crsFormFields.residenceCountry.header",
  },
  residenceCountryPlaceholder: {
    id: "sweden.crsFormFields.residenceCountry.placeholder",
  },
  residenceCountryRequired: {
    id: "sweden.crsFormFields.residenceCountry.required",
  },
  streetLabel: {
    id: "sweden.crsFormFields.street.label",
  },
  streetRequired: {
    id: "sweden.crsFormFields.street.required",
  },
  streetLength: {
    id: "sweden.crsFormFields.street.length",
  },
  postCodeLabel: {
    id: "sweden.crsFormFields.postalCode.label",
  },
  postCodeRequired: {
    id: "sweden.crsFormFields.postalCode.required",
  },
  cityLabel: {
    id: "sweden.crsFormFields.city.label",
  },
  cityRequired: {
    id: "sweden.crsFormFields.city.required",
  },
  cityLength: {
    id: "sweden.crsFormFields.city.length",
  },
});
