import { API } from "@lysaab/ui-2/network/API";
import { LysaCountry } from "@lysaab/shared";
import type { TrackerEvent as NetsTrackerEvent } from "@lysaab/lysa-nets";

export interface TrackRequest {
  seq: number;
  uri: string;
  event?: TrackerEvent | NetsTrackerEvent;
  message?: string;
}

export enum TrackerEvent {
  USER_AGENT = "USER_AGENT",
}

type LoginType = "NETS";

interface InitializationData {
  loginId?: string;
  email?: string;
  loginType: LoginType;
  countryCode: LysaCountry;
}

interface InitializationResponse {
  loginId: string;
  email: string;
  loginType: LoginType;
  countryCode: LysaCountry;
  timeStamp: string;
}

export interface TrackRequest {
  seq: number;
  uri: string;
  event?: TrackerEvent | NetsTrackerEvent;
  message?: string;
}

export const dataUnauthenticatedTrackingService = {
  loginInitiate: (initializationData: InitializationData) => {
    return API.post<InitializationResponse>(
      "/uts/login/initiate",
      initializationData
    );
  },

  sendTracking: (id: string, queue: TrackRequest[]) => {
    return API.post(`/uts/login/${id}`, queue);
  },
};
