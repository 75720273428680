import { Card, Button } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../../../../../pages/overview/OverviewPage";

export function Done() {
  const history = useHistory();

  return (
    <div>
      <h1>
        <FormattedMessage id="sweden.deposits.monthly.story.done.header" />
      </h1>
      <Card>
        <p>
          <FormattedMessage id="sweden.deposits.monthly.story.done.text" />
        </p>
      </Card>
      <Button
        block
        onClick={() => history.push(getNavLink(OVERVIEW_PAGE_URL))}
        label={
          <FormattedMessage id="sweden.deposits.monthly.story.done.button" />
        }
      />
    </div>
  );
}
