import { Spinner } from "@lysaab/ui-2";
import { useEffect, useState, VoidFunctionComponent } from "react";
import { useLoadingContext } from "../../../../../context/LoadingContext";
import { SavingsInterestCard } from "../../../../../countries/sweden/components/savingsInterestCardContent/SavingsInterestCard";
import { SavingsAccountId } from "../../../../../data/dataAccounts";
import {
  dataSavingsAccount,
  EffectiveInterestRateWithAccountId,
} from "../../../../../data/dataSavingsAccount";

interface Props {
  accountId: SavingsAccountId;
}

export const InterestCardWrapper: VoidFunctionComponent<Props> = ({
  accountId,
}) => {
  const { isLoading, setIsLoading } = useLoadingContext();
  const [interestRate, setInterestRate] =
    useState<EffectiveInterestRateWithAccountId>();
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    dataSavingsAccount
      .getEffectiveInterestRateByAccount([accountId])
      .then((response) => {
        if (response.length === 0) {
          throw new Error(
            "InterestCardWrapper - No accrued interest for account"
          );
        }
        setInterestRate(response[0]);
      })
      .catch(() => setIsError(true))
      .finally(() => {
        setIsLoading(false);
      });
  }, [accountId, setIsLoading]);

  if (isLoading) {
    return <Spinner />;
  }

  return <SavingsInterestCard interestRate={interestRate} isError={isError} />;
};
