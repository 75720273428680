import { Card, Button } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../../overview/OverviewPage";
import { useHistory } from "react-router";
import { WITHDRAWAL_PAGE_URL } from "../../overview/WithdrawalPage";

export function Done() {
  const history = useHistory();

  return (
    <div>
      <h1>
        <FormattedMessage id="withdrawal.add.manual.done.header" />
      </h1>
      <Card>
        <p>
          <FormattedMessage id="withdrawal.add.manual.done.text" />
        </p>
      </Card>
      <Button
        block
        onClick={() => history.push(getNavLink(WITHDRAWAL_PAGE_URL))}
        label={
          <FormattedMessage id="withdrawal.add.manual.done.button.withdrawal" />
        }
      />
      <Button
        variant="secondary"
        block
        onClick={() => history.push(getNavLink(OVERVIEW_PAGE_URL))}
        label={<FormattedMessage id="withdrawal.add.manual.done.button.home" />}
      />
    </div>
  );
}
