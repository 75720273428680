import { ConfirmResponse } from "@lysaab/lysa-onfido/data/onfido";
import { SignupId } from "@lysaab/lysa-onfido/data/signup";
import { API, encode } from "@lysaab/ui-2";
import { ResetId } from "./dataLogin";

interface JwtResponse {
  token: string;
}

export type DocumentResponse = {
  id: string;
  signupId: SignupId;
  contentType: string;
  created: string;
} & (DocumentResponseLivePhoto | DocumentResponseOther);

type DocumentResponseLivePhoto = {
  documentType: DocumentType.LIVE_PHOTO;
  reportProperties: DocumentPropertiesLivePhoto;
};

type DocumentResponseOther = {
  documentType: Exclude<DocumentType, DocumentType.LIVE_PHOTO>;
  reportProperties: DocumentProperties;
};

interface DocumentPropertiesLivePhoto {
  documentType: DocumentType.LIVE_PHOTO;
}

interface DocumentProperties {
  documentType: Exclude<DocumentType, DocumentType.LIVE_PHOTO>;
  issuingCountry: string;
  issuingState: string;
  issuingDate: string;
  gender: string;
  nationality: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  dateOfExpiry: string;
  categorization: string;
  mrzLine1: string;
  mrzLine2: string;
  mrzLine3: string;
  documentNumbers: string[];
  address: string;
  passportNumber: string;
}

export enum DocumentType {
  PASSPORT = "PASSPORT",
  NATIONAL_IDENTITY_CARD = "NATIONAL_IDENTITY_CARD",
  NATIONAL_HEALTH_INSURANCE_CARD = "NATIONAL_HEALTH_INSURANCE_CARD",
  DRIVING_LICENCE = "DRIVING_LICENCE",
  TAX_ID = "TAX_ID",
  RESIDENCE_PERMIT = "RESIDENCE_PERMIT",
  VOTER_ID = "VOTER_ID",
  VISA = "VISA",
  LIVE_PHOTO = "LIVE_PHOTO",
  UNKNOWN = "UNKNOWN",
  UTILITY_BILL = "UTILITY_BILL",
}

export function initiate(resetId: ResetId) {
  const url = encode`/onfido/authenticationreset/initiate?authenticationResetId=${resetId}`;

  return API.post<JwtResponse>(url);
}

export function confirm(resetId: ResetId) {
  const url = encode`/onfido/authenticationreset/confirm?authenticationResetId=${resetId}`;

  return API.post<ConfirmResponse>(url);
}

export function getDocuments() {
  return API.get<DocumentResponse[]>("/onfido/documents");
}
