import { useState } from "react";
import UAParser from "ua-parser-js";

function getDeviceType() {
  const ua = new UAParser();
  return ua.getDevice();
}

export enum DeviceType {
  CONSOLE = "console",
  MOBILE = "mobile",
  TABLET = "tablet",
  SMARTTV = "smarttv",
  WEARABLE = "wearable",
  EMBEDDED = "embedded",
}

export function useDeviceType() {
  const [device] = useState<DeviceType | undefined>(
    getDeviceType().type as DeviceType
  );
  return device;
}
