import { VoidFunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { getNavLink } from "../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../overview/OverviewPage";
import { Page, PageHeader } from "../Page";

export const CHUNK_LOAD_ERROR_PAGE_URL = "/errors/chunkloaderror";

export const ChunkLoadErrorPage: VoidFunctionComponent = () => {
  return (
    <Page>
      <PageHeader>
        <h1>
          <FormattedMessage id="chunkloaderror.header" />
        </h1>
      </PageHeader>
      <p>
        <FormattedMessage id="chunkloaderror.body" />
      </p>
      <Link to={getNavLink(OVERVIEW_PAGE_URL)}>
        <FormattedMessage id="chunkloaderror.link" />
      </Link>
    </Page>
  );
};
