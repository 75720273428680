import { Story } from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { useLocation } from "react-router";
import { Route } from "../../../../components/route/Route";
import { Switch } from "../../../../components/route/Switch";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../../hooks/useSafeNavigation";
import { UpdateCrsInformation } from "./updateCrsInformation/UpdateCrsInformation";
import { ConfirmCrsInformation } from "./confirmCrsInformation/ConfirmCrsInformation";
import { PageStripped } from "../../../../pages/PageStripped";
import { CRS_PAGE_URL } from "../../../../pages/crs/CrsPage";
import { CrsContextProvider } from "../../../../pages/updateCrsStory/context/CrsContext";
import { UPDATE_CRS_URL } from "../../../../pages/updateCrsStory/UpdateCrsStory";
import { useStoryValues } from "../../../../hooks/useStoryValues";

export const BASE_ROUTES = {
  UPDATE_CRS: `${UPDATE_CRS_URL}/`,
  CONFIRM_CRS: `${UPDATE_CRS_URL}/confirm`,
};

const messages = defineMessages({
  header: {
    id: "denmark.updateCrsStory.header",
  },
  ariaProgressLabel: {
    id: "denmark.updateCrsStory.story.ariaProgressLabel",
  },
});

const InternalUpdateCrsStory = () => {
  const location = useLocation();
  const intl = useIntl();
  const safeNavigation = useSafeNavigation();
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);

  const onBack = (currentIndex: number) => {
    if (currentIndex === Object.values(ROUTES).indexOf(ROUTES.CONFIRM_CRS)) {
      safeNavigation(ROUTES.UPDATE_CRS);
    }
  };

  return (
    <PageStripped>
      <Story
        ariaLabelProgress={() =>
          intl.formatMessage(messages.ariaProgressLabel, {
            current: currentIndex + 1,
            total: storyLength,
          })
        }
        header={intl.formatMessage(messages.header)}
        progress={storyProgress}
        showBack={
          currentIndex === Object.values(ROUTES).indexOf(ROUTES.CONFIRM_CRS)
        }
        showClose={true}
        transitionKey={currentIndex.toString()}
        onExit={() => safeNavigation(getNavLink(CRS_PAGE_URL))}
        onBack={() => onBack(currentIndex)}
      >
        <Switch
          location={location}
          {...{
            order: currentIndex,
          }}
        >
          <Route path={ROUTES.UPDATE_CRS} exact>
            <UpdateCrsInformation
              next={() => safeNavigation(ROUTES.CONFIRM_CRS)}
            />
          </Route>
          <Route path={ROUTES.CONFIRM_CRS} exact>
            <ConfirmCrsInformation
              next={() => safeNavigation(getNavLink(CRS_PAGE_URL))}
            />
          </Route>
        </Switch>
      </Story>
    </PageStripped>
  );
};

export const UpdateCrsStory = () => (
  <CrsContextProvider>
    <InternalUpdateCrsStory />
  </CrsContextProvider>
);
