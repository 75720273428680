import {
  CheckmarkButton,
  NewIcon,
  SNACKBAR_TYPES,
  Snackbar,
} from "@lysaab/ui-2";
import { useCallback, useContext, useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import "./InviteButtons.scss";
import { LocalizationContext } from "../../../context/LocalizationContext";
import { LysaCountry } from "@lysaab/shared";
import { TranslatedText } from "../../../components/TranslatedText";

export const messages = defineMessages({
  shareText: {
    id: "invite.inviteLink.shareText",
  },
  shareTitle: {
    id: "invite.inviteLink.shareTitle",
  },
});

const inviteUrlPrefix = {
  [LysaCountry.DENMARK]: "https://signup.lysa.se/dk?inviteId=",
  [LysaCountry.FINLAND]: "https://signup.lysa.se/fi?inviteId=",
  [LysaCountry.GERMANY]: "https://signup.lysa.se/de?inviteId=",
  [LysaCountry.SPAIN]: "https://signup.lysa.se/es?inviteId=",
  [LysaCountry.SWEDEN]: "https://signup.lysa.se/se?inviteId=",
};

interface Props {
  inviteId: string;
}

export const InviteButtons: React.VFC<Props> = ({ inviteId }) => {
  const intl = useIntl();

  const localizationContext = useContext(LocalizationContext);
  const [isCopySuccess, setIsCopySuccess] = useState(false);
  const [isShareSuccess, setIsShareSuccess] = useState(false);
  const [isInternalError, setIsInternalError] = useState(false);

  const createInviteUrl = useCallback(() => {
    const country = localizationContext.state.country;
    if (!country) {
      setIsInternalError(true);
      return "";
    }
    setIsInternalError(false);
    return `${inviteUrlPrefix[country]}${inviteId}`;
  }, [inviteId, localizationContext.state.country]);

  const inviteUrl = useMemo(() => createInviteUrl(), [createInviteUrl]);

  const copyInvite = () => {
    const success = copyToClipboard(inviteUrl);
    if (success) {
      setIsCopySuccess(success);
      setTimeout(() => {
        setIsCopySuccess(false);
      }, 2000);
    }
  };

  const share = () => {
    try {
      navigator
        .share({
          title: intl.formatMessage(messages.shareTitle),
          text: intl.formatMessage(messages.shareText),
          url: inviteUrl,
        })
        .then(() => {
          setIsShareSuccess(true);
          setTimeout(() => setIsShareSuccess(false), 2000);
        })
        .catch((error) => {
          /* AbortError is caught even if user aborted or decided to copy through native share */
          if (error.name === "AbortError") {
            return;
          }
          setIsInternalError(true);
        });
    } catch (err) {
      setIsInternalError(true);
    }
  };

  return (
    <div className="invite-buttons-wrapper">
      {isInternalError && (
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <TranslatedText id="invite.inviteLink.error" />
        </Snackbar>
      )}
      <CheckmarkButton
        size="medium"
        isSuccessful={isCopySuccess}
        onClick={copyInvite}
      >
        <div className="invite-button-copy">
          <NewIcon.ExternalLink primaryColor="#FFF" size={16} />
          <TranslatedText id="invite.inviteLink.copy" />
        </div>
      </CheckmarkButton>
      {/* @ts-ignore: canShare is optional, but marked as non-optional in the typings */}
      {navigator.canShare && (
        <CheckmarkButton
          onClick={share}
          isSuccessful={isShareSuccess}
          variant="secondary"
        >
          <div className="invite-button-share">
            <NewIcon.ShareAndroid primaryColor="#FFF" size={16} />
            <TranslatedText id="invite.inviteLink.share" />
          </div>
        </CheckmarkButton>
      )}
    </div>
  );
};

// https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
// https://stackoverflow.com/a/45308151/88106
const copyToClipboard = (text: string) => {
  const textarea = document.createElement("textarea");

  // Move it off screen.
  textarea.style.cssText = "position: fixed; left: -99999em";

  // Set to readonly to prevent mobile devices opening a keyboard when
  // text is .select()'ed.
  textarea.setAttribute("readonly", "true");

  document.body.appendChild(textarea);

  textarea.value = text;

  const getSelected = document.getSelection();
  if (getSelected === null) {
    return false;
  }

  // Check if there is any content selected previously.
  const selected =
    getSelected.rangeCount > 0 ? getSelected.getRangeAt(0) : false;

  // iOS Safari blocks programmtic execCommand copying normally, without this hack.
  // https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios
  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    const editable = textarea.contentEditable;
    textarea.contentEditable = "true";
    const range = document.createRange();
    range.selectNodeContents(textarea);
    const sel = window.getSelection();
    if (sel) {
      sel.removeAllRanges();
      sel.addRange(range);
    }
    textarea.setSelectionRange(0, 999999);
    textarea.contentEditable = editable;
  } else {
    textarea.select();
  }

  try {
    const result = document.execCommand("copy");

    // Restore previous selection.
    if (selected) {
      document.getSelection()!.removeAllRanges();
      document.getSelection()!.addRange(selected);
    }

    return result;
  } catch (ex) {
    console.warn("Copy to clipboard failed.", ex); // eslint-disable-line no-console
    return false;
  } finally {
    document.body.removeChild(textarea);
  }
};
