import { useContext, VoidFunctionComponent } from "react";
import { Amount } from "../../../../../components/amount/Amount";
import { FeesContext } from "../../context/FeesContext";

interface Props {
  value: number;
  label: string;
}

export const FeeBodyCell: VoidFunctionComponent<Props> = ({ value, label }) => {
  const feeContext = useContext(FeesContext);

  if (feeContext.state.isPercent.value) {
    return null;
  }
  return (
    <td data-label={label}>
      <Amount amount={value} decimals={2} />
    </td>
  );
};
