import { useContext } from "react";
import { Page, PageHeader } from "../Page";
import { generatePath, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { getNavLink } from "../../hooks/useCountryUrls";
import { UserContext } from "../../context/UserContext";
import { LegalEntityType } from "../../data/dataLogin";
import "./ProfilePage.scss";
import { RouteAwareToggle } from "../../components/route/RouteAwareToggle";
import { MESSAGES_PAGE_URL } from "../messages/MessagesPage";
import { SETTINGS_PAGE_URL } from "../settings/SettingsPage";
import { DOCUMENT_PAGE_URL } from "../documents/DocumentsPage";
import { KYC_PAGE_URL } from "../kyc/KycViewPage";
import { YOUR_DATA_PAGE_URL } from "../yourData/YourDataPage";
import { TAX_INFORMATION_PAGE_URL } from "../taxInformation/TaxInformation";
import { CRS_PAGE_URL } from "../crs/CrsPage";
import { FEES_PAGE_URL } from "../fees/FeesPage";
import { ACCOUNTING_PAGE_URL } from "../../countries/sweden/pages/corporation/accounting/AccountingPage";
import { COMPANY_SETTINGS_PAGE_URL } from "../../countries/sweden/pages/corporation/companySettings/CompanySettingsPage";
import { Button } from "@lysaab/ui-2/components/buttons/Button";
import { ACCOUNT_STATEMENT_PAGE } from "../accountStatement/AccountStatementPage";
import { StatusLabel, STATUS_TYPE } from "@lysaab/ui-2";
import { useNotifications } from "../../hooks/useNotifications";
import { SHARED_ACCOUNTS_URL } from "../sharedAccountsPage/SharedAccountsPage";
import { useIsPerson } from "../../hooks/useIsPerson";
import { GridRow } from "../../components/grid/gridRow/GridRow";
import { GridCol } from "../../components/grid/gridCol/GridCol";
import { SUITABILITY_ASSESSMENT_EDIT_PAGE } from "../suitabilityAssessment/SuitabilityAssessmentStory";

export const PROFILE_PAGE_URL = "/profile";

export function ProfilePage() {
  const userContext = useContext(UserContext);
  const isPerson = useIsPerson();
  const { unreadMessages } = useNotifications();

  return (
    <Page className="profile-page">
      <PageHeader>
        <h1>
          <FormattedMessage id="profile.header" />
        </h1>
      </PageHeader>
      <GridRow className="profile-page-links">
        <RouteAwareToggle path={getNavLink(SETTINGS_PAGE_URL)}>
          <GridCol className="profile-page-link" medium={4} xsmall={12}>
            <Button
              component={Link}
              to={getNavLink(SETTINGS_PAGE_URL)}
              label={<FormattedMessage id="profile.links.newSettings" />}
              block
            />
          </GridCol>
        </RouteAwareToggle>

        <RouteAwareToggle path={getNavLink(DOCUMENT_PAGE_URL)}>
          <GridCol className="profile-page-link" medium={4} xsmall={12}>
            <Button
              component={Link}
              to={getNavLink(generatePath(DOCUMENT_PAGE_URL))}
              block
              label={<FormattedMessage id="profile.links.documents" />}
            />
          </GridCol>
        </RouteAwareToggle>
        <RouteAwareToggle path={getNavLink(KYC_PAGE_URL)}>
          <GridCol className="profile-page-link" medium={4} xsmall={12}>
            <Button
              component={Link}
              to={getNavLink(KYC_PAGE_URL)}
              block
              label={<FormattedMessage id="profile.links.kyc" />}
            />
          </GridCol>
        </RouteAwareToggle>
        <RouteAwareToggle path={getNavLink(MESSAGES_PAGE_URL)}>
          <GridCol className="profile-page-link" medium={4} xsmall={12}>
            <Button
              component={Link}
              to={getNavLink(MESSAGES_PAGE_URL)}
              block
              label={
                <>
                  <FormattedMessage id="profile.links.messages" />
                  {unreadMessages > 0 && (
                    <StatusLabel
                      type={STATUS_TYPE.ERROR}
                      icon={false}
                      className="profile-messages-indicator"
                    >
                      {unreadMessages}
                    </StatusLabel>
                  )}
                </>
              }
            />
          </GridCol>
        </RouteAwareToggle>

        {userContext.state.legalEntityType === LegalEntityType.CORPORATION && (
          <>
            <RouteAwareToggle path={getNavLink(COMPANY_SETTINGS_PAGE_URL)}>
              <GridCol className="profile-page-link" medium={4} xsmall={12}>
                <Button
                  component={Link}
                  to={getNavLink(COMPANY_SETTINGS_PAGE_URL)}
                  block
                  label={<FormattedMessage id="profile.links.corp-settings" />}
                />
              </GridCol>
            </RouteAwareToggle>
            <RouteAwareToggle path={getNavLink(ACCOUNTING_PAGE_URL)}>
              <GridCol className="profile-page-link" medium={4} xsmall={12}>
                <Button
                  component={Link}
                  to={getNavLink(ACCOUNTING_PAGE_URL)}
                  block
                  label={<FormattedMessage id="profile.links.tax" />}
                />
              </GridCol>
            </RouteAwareToggle>
          </>
        )}

        {isPerson && (
          <RouteAwareToggle path={getNavLink(TAX_INFORMATION_PAGE_URL)}>
            <GridCol className="profile-page-link" medium={4} xsmall={12}>
              <Button
                component={Link}
                to={getNavLink(TAX_INFORMATION_PAGE_URL)}
                block
                label={<FormattedMessage id="profile.links.tax-page" />}
              />
            </GridCol>
          </RouteAwareToggle>
        )}

        <RouteAwareToggle path={getNavLink(YOUR_DATA_PAGE_URL)}>
          <GridCol className="profile-page-link" medium={4} xsmall={12}>
            <Button
              component={Link}
              to={getNavLink(YOUR_DATA_PAGE_URL)}
              block
              label={<FormattedMessage id="profile.links.yourData" />}
            />
          </GridCol>
        </RouteAwareToggle>

        <RouteAwareToggle path={getNavLink(CRS_PAGE_URL)}>
          <GridCol className="profile-page-link" medium={4} xsmall={12}>
            <Button
              component={Link}
              to={getNavLink(CRS_PAGE_URL)}
              block
              label={<FormattedMessage id="profile.links.crs" />}
            />
          </GridCol>
        </RouteAwareToggle>

        <RouteAwareToggle path={getNavLink(FEES_PAGE_URL)}>
          <GridCol className="profile-page-link" medium={4} xsmall={12}>
            <Button
              component={Link}
              to={getNavLink(FEES_PAGE_URL)}
              block
              label={<FormattedMessage id="profile.links.fees" />}
            />
          </GridCol>
        </RouteAwareToggle>

        <RouteAwareToggle path={getNavLink(ACCOUNT_STATEMENT_PAGE)}>
          <GridCol className="profile-page-link" medium={4} xsmall={12}>
            <Button
              component={Link}
              to={getNavLink(ACCOUNT_STATEMENT_PAGE)}
              block
              label={<FormattedMessage id="profile.links.accountStatement" />}
            />
          </GridCol>
        </RouteAwareToggle>

        <RouteAwareToggle path={getNavLink(SHARED_ACCOUNTS_URL)}>
          <GridCol className="profile-page-link" medium={4} xsmall={12}>
            <Button
              component={Link}
              to={getNavLink(SHARED_ACCOUNTS_URL)}
              block
              label={<FormattedMessage id="profile.links.sharedAccounts" />}
            />
          </GridCol>
        </RouteAwareToggle>

        <RouteAwareToggle path={getNavLink(SUITABILITY_ASSESSMENT_EDIT_PAGE)}>
          <GridCol className="profile-page-link" medium={4} xsmall={12}>
            <Button
              component={Link}
              to={getNavLink(SUITABILITY_ASSESSMENT_EDIT_PAGE)}
              block
              label={<FormattedMessage id="profile.links.investmentProfile" />}
            />
          </GridCol>
        </RouteAwareToggle>
      </GridRow>
    </Page>
  );
}
