import { RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { useCreateKFAccount } from "../../KFAccountContext";

export const KnowsRights = () => {
  const intl = useIntl();
  const [KFAccount, setKFAccount] = useCreateKFAccount();

  return (
    <RadioGroup
      header={intl.formatMessage({
        id: "sweden.kf.intro.rights.label",
      })}
      alternatives={[
        {
          text: intl.formatMessage({
            id: "sweden.kf.intro.rights.yes",
          }),
          value: true,
        },
        {
          text: intl.formatMessage({ id: "sweden.kf.intro.rights.no" }),
          value: false,
        },
      ]}
      value={KFAccount.knowsRights}
      onChange={(value) =>
        setKFAccount({
          knowsRights: value,
        })
      }
      validators={[
        new RequiredValidator(
          intl.formatMessage({ id: "sweden.kf.intro.rights.required" })
        ),
      ]}
    />
  );
};
