import { useCallback, useContext } from "react";
import { Redirect } from "react-router";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { AddAccountKlarnaContext } from "../../AddAccountKlarnaContext";
import { ADD_EXTERNAL_KLARNA_URL } from "../../AddAccountKlarnaStory";
import { TinkAccount } from "../../../../../data/dataTink";
import { AccountLoading } from "../../../../../components/tink/accountLoading/AccountLoading";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { ADD_EXTERNAL_MANUAL_URL } from "../../../addAccountManual/AddAccountManualStory";
import { LysaCountry } from "@lysaab/shared";
import { ADD_EXTERNAL_AUTOGIRO_URL } from "../../../../../countries/sweden/pages/withdrawal/addAccountAutogiro/AddAccountAutogiroStory";
import { useCountry } from "../../../../../context/LocalizationContext";

interface Props {
  next: () => void;
}

export function AccountLoadingWrapper({ next }: Props) {
  const tinkContext = useContext(AddAccountKlarnaContext);
  const country = useCountry();
  const bank = tinkContext.state.tinkBank;
  const setState = tinkContext.setState;

  const getExternalManualUrl = useCallback(() => {
    if (country === LysaCountry.SWEDEN) {
      return getNavLink(ADD_EXTERNAL_AUTOGIRO_URL);
    } else {
      return getNavLink(ADD_EXTERNAL_MANUAL_URL);
    }
  }, [country]);

  const onComplete = useCallback(
    (accounts: TinkAccount[], accountVerificationReportId: string) => {
      setState({ tinkAccounts: accounts, accountVerificationReportId });
      setTimeout(() => {
        next();
      }, 0);
    },
    [next, setState]
  );

  if (!bank) {
    return <Redirect to={getNavLink(ADD_EXTERNAL_KLARNA_URL)} />;
  }
  return (
    <AccountLoading
      bank={bank}
      onComplete={onComplete}
      fallbackOptions={{
        locationDescriptor: {
          pathname: getExternalManualUrl(),
        },
        buttonText: (
          <TranslatedText id="sweden.deposits.monthly.story.account-loading-wrapper.fallbackButton" />
        ),
      }}
    />
  );
}
