import { defineMessages } from "react-intl";
import { FeeType, FundTransaction } from "../../../../../../data/dataCorporate";
import {
  CashTransactionType,
  FundTransactionType,
} from "../../../../../../data/dataTransactions";

export interface FundDisplay {
  transaction: FundTransaction;
}

interface MessageWithId {
  id: string;
}

export const fundTypesMessages = defineMessages<
  FundTransactionType,
  MessageWithId,
  Record<FundTransactionType, MessageWithId>
>({
  [FundTransactionType.BUY]: {
    id: "sweden.accountingPage.dataDisplay.type.fund.BUY",
  },
  [FundTransactionType.CORRECTION]: {
    id: "sweden.accountingPage.dataDisplay.type.fund.CORRECTION",
  },
  [FundTransactionType.MOVE_IN]: {
    id: "sweden.accountingPage.dataDisplay.type.fund.MOVE_IN",
  },
  [FundTransactionType.MOVE_OUT]: {
    id: "sweden.accountingPage.dataDisplay.type.fund.MOVE_OUT",
  },
  [FundTransactionType.SELL]: {
    id: "sweden.accountingPage.dataDisplay.type.fund.SELL",
  },
  [FundTransactionType.SWITCH_BUY]: {
    id: "sweden.accountingPage.dataDisplay.type.fund.SWITCH_BUY",
  },
  [FundTransactionType.SWITCH_SELL]: {
    id: "sweden.accountingPage.dataDisplay.type.fund.SWITCH_SELL",
  },
});

export const cashTypesMessages = defineMessages<
  CashTransactionType,
  MessageWithId,
  Record<CashTransactionType, MessageWithId>
>({
  [CashTransactionType.DEPOSIT]: {
    id: "sweden.accountingPage.dataDisplay.type.cash.DEPOSIT",
  },
  [CashTransactionType.FEE]: {
    id: "sweden.accountingPage.dataDisplay.type.cash.FEE",
  },

  [CashTransactionType.MOVE_IN]: {
    id: "sweden.accountingPage.dataDisplay.type.cash.MOVE_IN",
  },

  [CashTransactionType.MOVE_OUT]: {
    id: "sweden.accountingPage.dataDisplay.type.cash.MOVE_OUT",
  },

  [CashTransactionType.TAX]: {
    id: "sweden.accountingPage.dataDisplay.type.cash.TAX",
  },

  [CashTransactionType.WITHDRAWAL]: {
    id: "sweden.accountingPage.dataDisplay.type.cash.WITHDRAWAL",
  },
  [CashTransactionType.INTEREST]: {
    id: "sweden.accountingPage.dataDisplay.type.cash.INTEREST",
  },
});

export const feeTypesMessages = defineMessages<
  FeeType,
  MessageWithId,
  Record<FeeType, MessageWithId>
>({
  [FeeType.DISC]: {
    id: "sweden.accountingPage.dataDisplay.type.fee.DISC",
  },
  [FeeType.INSURANCE]: {
    id: "sweden.accountingPage.dataDisplay.type.fee.INSURANCE",
  },
});
