import { Card, RiskIndicator } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { Amount } from "../../../../components/amount/Amount";
import { TranslatedText } from "../../../../components/TranslatedText";
import {
  Transaction,
  instanceOfFundTransaction,
  instanceOfCashTransaction,
  CashTransactionType,
} from "../../../../data/dataTransactions";
import {
  ActiveAccountInformation,
  ClosedAccountInformation,
  SavingsAccountInformation,
} from "../HistoricTransactions";
import { transactionTranslations } from "../TransactionTranslations";
import { CashTransationRows } from "./cashTransactionRows/CashTransationRows";
import { FundTransationRows } from "./fundTransactionRows/FundTransationRows";

import "./TransactionCard.scss";

export const TransactionCard = ({
  transaction,
  accountInformation,
}: {
  transaction: Transaction;
  accountInformation:
    | ActiveAccountInformation
    | ClosedAccountInformation
    | SavingsAccountInformation;
}) => {
  const intl = useIntl();

  return (
    <Card className="transaction-card">
      <div className="transaction-card-header">
        <span className="transaction-card-header-type">
          {instanceOfCashTransaction(transaction) &&
          (transaction.type === CashTransactionType.MOVE_IN ||
            transaction.type === CashTransactionType.MOVE_OUT) ? (
            <TranslatedText id="historicTransactionsPage.transactionTranslations.internalTransfer" />
          ) : (
            intl.formatMessage(transactionTranslations[transaction.type])
          )}
        </span>
        {intl.formatDate(transaction.booked)}
      </div>
      <dl>
        <div className="transaction-card-row">
          <dt>
            <TranslatedText id="historicTransactionsPage.historicTransactions.transactionCard.account" />
          </dt>
          <dd className="transaction-card-row__indicator-wrapper">
            <span className="account">{accountInformation.name}</span>
            {isClosedAccountInformation(accountInformation) ? (
              `(${intl.formatDate(accountInformation.closed)})`
            ) : (
              <Amount amount={accountInformation.worth} decimals={2} />
            )}
            {isActiveAcountInformation(accountInformation) && (
              <RiskIndicator
                risk={accountInformation.risk}
                investmentType={accountInformation.investmentType}
              />
            )}
          </dd>
        </div>
        {instanceOfFundTransaction(transaction) && (
          <FundTransationRows transaction={transaction} />
        )}
        {instanceOfCashTransaction(transaction) && (
          <CashTransationRows transaction={transaction} />
        )}
      </dl>
    </Card>
  );
};

const isActiveAcountInformation = (
  accountInformation:
    | ActiveAccountInformation
    | ClosedAccountInformation
    | SavingsAccountInformation
): accountInformation is ActiveAccountInformation => {
  return accountInformation.hasOwnProperty("risk");
};

const isClosedAccountInformation = (
  accountInformation:
    | ActiveAccountInformation
    | ClosedAccountInformation
    | SavingsAccountInformation
): accountInformation is ClosedAccountInformation => {
  return accountInformation.hasOwnProperty("closed");
};
