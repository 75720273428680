import {
  FC,
  RefObject,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as d3 from "d3";
import { useIntl } from "react-intl";
import { NewIcon as Icon, Typography } from "@lysaab/ui-2";
import { GlidePathDataPoint } from "../../data/dataInvestments";
import { TranslatedText } from "../TranslatedText";
import { AllocationStagesModal } from "./components/AllocationStagesModal";

import "./GlidePathGraph.scss";

const GRAPH_HEIGHT = 225;
const GRAPH_WIDTH = 450;
const TOP_PADDING = 40;
const LEFT_PADDING = 50;

const GRAPH_RADIUS = 8;
const GRAPH_DIVIDER_WIDTH = 2;
const ELEMENT_SPACING = 4;
const ICON_SIZE = 24;
const TICK_ROTATION = -55;

interface Props {
  glidePath: GlidePathDataPoint[];
  reallocationAge: number;
  withdrawalAge: number;
}

export const GlidePathGraph: FC<Props> = ({
  glidePath,
  reallocationAge,
  withdrawalAge,
}) => {
  const [showModal, setShowModal] = useState(false);

  const scale = useRef({
    x: d3.scaleLinear().range([0, GRAPH_WIDTH]),
    y: d3.scaleLinear().domain([0, 100]).range([GRAPH_HEIGHT, 0]),
  });

  const graphData = useMemo(() => {
    scale.current.x.domain([
      d3.min(glidePath, (d) => d.age) ?? 0,
      d3.max(glidePath, (d) => d.age) ?? 0,
    ]);

    const areaGenerator = d3
      .area<GlidePathDataPoint>()
      .x((d) => scale.current.x(d.age))
      .y0(scale.current.y(0))
      .y1((d) => scale.current.y(d.takenRisk));

    const graph = areaGenerator(glidePath) ?? undefined;

    const withdrawalPosition = scale.current.x(withdrawalAge);
    const reallocationPosition = scale.current.x(reallocationAge - 1); // We want to show the line on the final year before reallocation.

    /**
     * We use hardcoded ticks
     */
    const yTicks = [100, 50, 0].map((tick) => ({
      tick,
      position: scale.current.y(tick),
    }));

    return {
      graph,
      reallocationPosition,
      withdrawalPosition,
      yTicks,
    };
  }, [reallocationAge, glidePath, withdrawalAge]);

  const [bottomPadding, setBottomPadding] = useState(0);

  if (glidePath.length < 2) {
    return null;
  }

  return (
    <div className="GlidePathGraph">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="svg"
        viewBox={`0 0 ${GRAPH_WIDTH + LEFT_PADDING} ${
          GRAPH_HEIGHT + TOP_PADDING + bottomPadding
        }`}
        fontSize={16}
        onClick={() => setShowModal(true)}
      >
        <g transform={`translate(${LEFT_PADDING}, ${TOP_PADDING})`}>
          <YTicks ticks={graphData.yTicks} />
        </g>

        <g transform={`translate(${LEFT_PADDING}, ${TOP_PADDING - 20})`}>
          <TopXTicks
            reallocationPosition={graphData.reallocationPosition}
            withdrawalPosition={graphData.withdrawalPosition}
          />
        </g>

        <g transform={`translate(${LEFT_PADDING}, ${TOP_PADDING})`}>
          <defs>
            <clipPath id="glide-path-graph-clip-path">
              {graphData.withdrawalPosition > 0 ? (
                <>
                  <rect
                    height={GRAPH_HEIGHT}
                    width={
                      graphData.withdrawalPosition - GRAPH_DIVIDER_WIDTH / 2
                    }
                    rx={GRAPH_RADIUS}
                  />
                  <rect
                    x={graphData.withdrawalPosition + GRAPH_DIVIDER_WIDTH / 2}
                    height={GRAPH_HEIGHT}
                    width={
                      GRAPH_WIDTH -
                      graphData.withdrawalPosition -
                      GRAPH_DIVIDER_WIDTH / 2
                    }
                    rx={GRAPH_RADIUS}
                  />
                </>
              ) : (
                <rect
                  height={GRAPH_HEIGHT}
                  width={GRAPH_WIDTH}
                  rx={GRAPH_RADIUS}
                />
              )}
            </clipPath>
          </defs>

          <g clipPath="url(#glide-path-graph-clip-path)">
            <rect
              className="graph-background"
              height={GRAPH_HEIGHT}
              width={GRAPH_WIDTH}
            />
            <path className="graph" d={graphData.graph} />
          </g>

          {graphData.reallocationPosition > 0 && (
            <line
              className="reallocation-line"
              x1={graphData.reallocationPosition}
              x2={graphData.reallocationPosition}
              y1={0}
              y2={GRAPH_HEIGHT}
              strokeDasharray="2"
            />
          )}
        </g>

        <g
          transform={`translate(${LEFT_PADDING}, ${
            TOP_PADDING + GRAPH_HEIGHT
          })`}
        >
          <BottomXTicks
            reallocationPosition={graphData.reallocationPosition}
            reallocationAge={reallocationAge}
            withdrawalPosition={graphData.withdrawalPosition}
            withdrawalAge={withdrawalAge}
            lastAge={glidePath[glidePath.length - 1].age}
            onMeasuredBottomPadding={setBottomPadding}
          />
        </g>
      </svg>

      <div className="footer">
        <div className="footer-content">
          <span className="dot stocks"></span>
          <Typography type="label">
            <TranslatedText id="glidePathGraph.equity-funds" />
          </Typography>
        </div>
        <div className="footer-content">
          <span className="dot bonds"></span>
          <Typography type="label">
            <TranslatedText id="glidePathGraph.fixed-income-funds" />
          </Typography>
        </div>
      </div>

      <AllocationStagesModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        reallocationYears={withdrawalAge - reallocationAge}
      />
    </div>
  );
};

//
// Top X ticks
//

interface TopXTicksProps {
  reallocationPosition: number;
  withdrawalPosition: number;
}

const TopXTicks: FC<TopXTicksProps> = ({
  reallocationPosition,
  withdrawalPosition,
}) => {
  const intl = useIntl();
  const growthOrReallocationRef = useRef<SVGTextElement>(null);
  const withdrawalRef = useRef<SVGTextElement>(null);
  const [measurements, setMeasurements] = useState({
    growthOrReallocationWidth: 0,
    withdrawalWidth: 0,
  });

  useLayoutEffect(() => {
    setMeasurements({
      growthOrReallocationWidth:
        growthOrReallocationRef.current?.getBBox().width ?? 0,
      withdrawalWidth: withdrawalRef.current?.getBBox().width ?? 0,
    });
  }, [reallocationPosition]);

  // Minimum position so to not overflow growth/reallocation label.
  const minWithdrawalPosition =
    withdrawalPosition > 0
      ? measurements.growthOrReallocationWidth + ELEMENT_SPACING
      : 0;

  // Maximum position so to not overflow icon/svg.
  const maxWithdrawalPosition =
    GRAPH_WIDTH - (measurements.withdrawalWidth + ICON_SIZE + ELEMENT_SPACING);

  return (
    <>
      {withdrawalPosition > 0 && (
        <text ref={growthOrReallocationRef} dominantBaseline="central">
          {reallocationPosition > 0
            ? intl.formatMessage({ id: "glidePathGraph.growth" })
            : intl.formatMessage({ id: "glidePathGraph.reallocation" })}
        </text>
      )}

      <text
        ref={withdrawalRef}
        dominantBaseline="central"
        x={Math.min(
          Math.max(withdrawalPosition, minWithdrawalPosition),
          maxWithdrawalPosition
        )}
      >
        {intl.formatMessage({ id: "glidePathGraph.withdrawal" })}
      </text>

      <foreignObject
        width={ICON_SIZE}
        height={ICON_SIZE}
        x={GRAPH_WIDTH - ICON_SIZE}
        y={-(ICON_SIZE / 2)}
      >
        <Icon.InformationOutline size={ICON_SIZE} />
      </foreignObject>
    </>
  );
};

//
// Bottom X ticks
//

interface BottomXTicksProps {
  reallocationPosition: number;
  reallocationAge: number;
  withdrawalPosition: number;
  withdrawalAge: number;
  lastAge: number;
  onMeasuredBottomPadding: (padding: number) => void;
}

const BottomXTicks: FC<BottomXTicksProps> = ({
  reallocationPosition,
  reallocationAge,
  withdrawalPosition,
  withdrawalAge,
  lastAge,
  onMeasuredBottomPadding,
}) => {
  const TOP_SPACING = 10;

  const intl = useIntl();
  const todayRef = useRef<SVGTextElement>(null);
  const reallocationRef = useRef<SVGTextElement>(null);
  const withdrawalRef = useRef<SVGTextElement>(null);
  const lastAgeRef = useRef<SVGTextElement>(null);

  const [{ rotate, tickHeight, rotationOffsetX }, setMeasurements] = useState({
    rotate: false,
    tickHeight: 0,
    rotationOffsetX: 0,
  });

  useLayoutEffect(() => {
    const getMeasurement = (
      ref: RefObject<SVGTextElement>,
      x: number,
      anchor: "start" | "middle" | "end"
    ) => {
      const domRect = ref.current?.getBBox();
      const width = domRect?.width ?? 0;
      const height = domRect?.height ?? 0;

      const start =
        anchor === "middle" ? x - width / 2 : anchor === "end" ? x - width : x;

      return { width, height, start };
    };

    // Measure each visible tick.
    const measurements = [
      getMeasurement(todayRef, 0, "start"),
      ...(reallocationPosition > 0
        ? [getMeasurement(reallocationRef, reallocationPosition, "middle")]
        : []),
      ...(withdrawalPosition > 0
        ? [getMeasurement(withdrawalRef, withdrawalPosition, "middle")]
        : []),
      getMeasurement(lastAgeRef, GRAPH_WIDTH, "end"),
    ];

    // Check if any tick overlaps the next one.
    const overlap = measurements.some((a, i) =>
      measurements
        .slice(i + 1)
        .some((b) => a.start + a.width + ELEMENT_SPACING >= b.start)
    );

    const tickHeight = measurements[0].height; // Every text element has the same height.

    let rotationOffsetX = 0;
    let bottomPadding = tickHeight + TOP_SPACING;
    if (overlap) {
      const maxWidth = Math.max(...measurements.map((m) => m.width));

      // Calculate X and Y offsets for the longest tick when rotated. This is based on a rotation point of top-right.
      const theta = (Math.PI / 180) * Math.abs(TICK_ROTATION);
      const y1 = maxWidth * Math.sin(theta); // Y offset from unrotated top-left to rotated top-left.
      const y2 = tickHeight * Math.cos(theta); // Y offset from rotated top-left to rotated bottom-left.
      const x1 = tickHeight * Math.sin(theta); // X offset from unrotated bottom-right to rotated bottom-right.

      rotationOffsetX = x1;
      bottomPadding = y1 + y2;
    }

    onMeasuredBottomPadding(bottomPadding);

    setMeasurements({
      rotate: overlap,
      tickHeight,
      rotationOffsetX,
    });
  }, [onMeasuredBottomPadding, reallocationPosition, withdrawalPosition]);

  // By using dominantBaseline "central" we position the vertical center of the text to the original Y position.
  // Offsetting half of the tick height gives us the correct position and sets rotation point to top.
  // Additionally textAnchor "end" sets rotation point to right; giving us top-right as origin (other solutions lacked browser support).
  const transform = (x: number): string => {
    return `translate(${x}, ${tickHeight / 2 + (rotate ? 0 : TOP_SPACING)})${
      rotate ? ` rotate(${TICK_ROTATION}, 0, -${tickHeight / 2})` : ""
    }`;
  };

  return (
    <g fontWeight={300}>
      <text
        ref={todayRef}
        dominantBaseline="central"
        textAnchor={rotate ? "end" : "start"}
        transform={transform(0)}
      >
        {intl.formatMessage({ id: "glidePathGraph.today" })}
      </text>

      {reallocationPosition > 0 && (
        <text
          ref={reallocationRef}
          dominantBaseline="central"
          textAnchor={rotate ? "end" : "middle"}
          transform={transform(reallocationPosition)}
        >
          {intl.formatMessage(
            { id: "glidePathGraph.age" },
            { age: reallocationAge }
          )}
        </text>
      )}

      {withdrawalPosition > 0 && (
        <text
          ref={withdrawalRef}
          dominantBaseline="central"
          textAnchor={rotate ? "end" : "middle"}
          transform={transform(withdrawalPosition)}
        >
          {intl.formatMessage(
            { id: "glidePathGraph.age" },
            { age: withdrawalAge }
          )}
        </text>
      )}

      <text
        ref={lastAgeRef}
        dominantBaseline="central"
        textAnchor="end"
        transform={transform(GRAPH_WIDTH - (rotate ? rotationOffsetX : 0))}
      >
        {intl.formatMessage({ id: "glidePathGraph.age" }, { age: lastAge })}
      </text>
    </g>
  );
};

//
// Left Y ticks
//

interface YTicksProps {
  ticks: { tick: number; position: number }[];
}

const YTicks: FC<YTicksProps> = ({ ticks }) => {
  const ref = useRef<SVGTextElement>(null);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    if (ref.current) {
      setHeight(ref.current.getBBox().height);
    }
  }, []);

  const yOffset = 5 + height / 2;
  const xOffset = 3;

  return (
    <g fontWeight={300}>
      {ticks.map(({ tick, position }, index) => {
        return (
          <text
            {...(index === 0 && { ref })}
            key={tick}
            x={-xOffset}
            y={
              index === 0
                ? position + yOffset
                : index === ticks.length - 1
                ? position - yOffset
                : position
            }
            dominantBaseline="central"
            textAnchor="end"
          >
            {`${tick} %`}
          </text>
        );
      })}
    </g>
  );
};
