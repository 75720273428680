import { TranslatedText } from "../../../../../../components/TranslatedText";

export function Disclaimer() {
  return (
    <p className="withdrawal-disclaimer">
      <TranslatedText
        id="withdrawalRequest.kf.disclaimer"
        description="Withdrawal request - disclaimer"
        defaultMessage="Hopefully you'll have your money withing <bold>five banking</bold> days. Large transaction might be longer due to security reasons."
      />
    </p>
  );
}
