import { Fragment, useContext, VoidFunctionComponent } from "react";
import { Card } from "@lysaab/ui-2";
import { Amount } from "../../../../components/amount/Amount";
import { GridCol } from "../../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../../components/grid/gridRow/GridRow";
import { TranslatedText } from "../../../../components/TranslatedText";
import { FormattedDate, useIntl } from "react-intl";
import { CompoundAccount } from "../../../../data/dataAccounts";
import { MonthlyPaymentWithoutBankInfo } from "../../../../data/dataMonthlyPayments";
import { DateTime } from "luxon";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import { depositMessages } from "./depositMessages";

interface Props {
  account: CompoundAccount;
  payment: MonthlyPaymentWithoutBankInfo;
}

export const DepositWithoutBankInfo: VoidFunctionComponent<Props> = ({
  account,
  payment,
}) => {
  const localizationContext = useContext(LocalizationContext);
  const intl = useIntl();

  if (!localizationContext.state.country) {
    return null;
  }

  return (
    <GridCol xsmall={12} small={6} large={4}>
      <Card>
        <h4>{account.name}</h4>
        <GridRow>
          <GridCol xsmall={12}>
            <GridRow>
              <GridCol xsmall={12}>
                <div className="static-input">
                  <span className="static-label">
                    <TranslatedText id="monthly.overview.card.next-deduction" />
                  </span>
                  <span className="static-value">
                    <FormattedDate
                      value={DateTime.fromISO(payment.nextDeduction).toJSDate()}
                    />
                  </span>
                </div>
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol xsmall={12}>
                <div className="static-input">
                  <span className="static-label">
                    {intl.formatMessage(depositMessages.amountLabel)}
                  </span>
                  <span className="static-value">
                    <Amount amount={payment.amount} />
                  </span>
                </div>
              </GridCol>
              <GridCol xsmall={12}>
                <Fragment>
                  <div className="static-input">
                    <span className="static-label">
                      {intl.formatMessage(depositMessages.preferredDayLabel)}
                    </span>
                    <span className="static-value">{payment.depositDay}</span>
                  </div>
                </Fragment>
              </GridCol>
            </GridRow>
          </GridCol>
        </GridRow>
      </Card>
    </GridCol>
  );
};
