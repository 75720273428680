import { Typography } from "@lysaab/ui-2";
import { DateTime } from "luxon";
import * as React from "react";
import { defineMessages, FormatNumberOptions, useIntl } from "react-intl";
import { getFundName } from "../../../../../../../../components/fundName/FundName";
import { LysaTable } from "../../../../../../../../components/lysaTable/LysaTable";
import { LysaTableTextCell } from "../../../../../../../../components/lysaTableTextCell/LysaTableTextCell";
import { Modal } from "../../../../../../../../components/modal/Modal";
import { TranslatedText } from "../../../../../../../../components/TranslatedText";
import { useCurrency } from "../../../../../../../../context/LocalizationContext";
import { Isin } from "../../../../../../../../data/dataFunds";
import { RedemptionPartResponse } from "../../../../../../../../data/dataTax";

import "./TaxRedemptionModal.scss";

export const tableLabelMessages = defineMessages({
  subscriptionDate: {
    id: "germany.tax.details.TaxRedemptionModal.subscriptionDate",
  },
  subscriptionPrice: {
    id: "germany.tax.details.TaxRedemptionModal.subscriptionPrice",
  },
  redemptionDate: {
    id: "germany.tax.details.TaxRedemptionModal.redemptionDate",
  },
  redemptionVolume: {
    id: "germany.tax.details.TaxRedemptionModal.redemptionVolume",
  },
  redemptionPrice: {
    id: "germany.tax.details.TaxRedemptionModal.redemptionPrice",
  },
  preTaxedVap: {
    id: "germany.tax.details.TaxRedemptionModal.preTaxedVap",
  },
  taxableGainLoss: {
    id: "germany.tax.details.TaxRedemptionModal.taxableGainLoss",
  },
});

interface ModalProps {
  redemptionPart: RedemptionPartResponse[];
  isin: Isin;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}

export const TaxRedemptionModal: React.VFC<ModalProps> = ({
  redemptionPart,
  isin,
  showModal,
  setShowModal,
}) => {
  const intl = useIntl();
  const currency = useCurrency();

  return (
    <Modal
      printEnabled
      showModal={showModal}
      onClose={() => setShowModal(false)}
      header={<TranslatedText id="germany.tax.details.realized" />}
      width={1500}
      closeOnOverlayClick
      className="tax-redemption-modal"
    >
      <Typography type="h4">
        {getFundName(isin, intl)} - {isin}
      </Typography>
      <div className="tax-redemption-modal__table-wrapper">
        <LysaTable className="tax-redemption-modal__table" fixed>
          <thead>
            <tr>
              <LysaTableTextCell
                value={intl.formatMessage(tableLabelMessages.redemptionDate)}
                header
              />
              <LysaTableTextCell
                value={intl.formatMessage(tableLabelMessages.redemptionVolume)}
                header
              />
              <LysaTableTextCell
                value={intl.formatMessage(tableLabelMessages.redemptionPrice)}
                header
              />
              <LysaTableTextCell
                value={intl.formatMessage(tableLabelMessages.subscriptionDate)}
                header
              />
              <LysaTableTextCell
                value={intl.formatMessage(tableLabelMessages.subscriptionPrice)}
                header
              />
              <LysaTableTextCell
                value={intl.formatMessage(tableLabelMessages.preTaxedVap)}
                header
              />
              <LysaTableTextCell
                value={intl.formatMessage(tableLabelMessages.taxableGainLoss)}
                header
              />
            </tr>
          </thead>
          <tbody>
            {redemptionPart.length === 0 ? (
              <tr>
                <td colSpan={7} className="text-center">
                  <TranslatedText id="germany.tax.details.noEntriesFound" />
                </td>
              </tr>
            ) : (
              <>
                {redemptionPart
                  .sort((entryA, entryB) => {
                    const diff =
                      DateTime.fromISO(entryA.redemptionDate).toMillis() -
                      DateTime.fromISO(entryB.redemptionDate).toMillis();
                    if (diff === 0) {
                      return (
                        DateTime.fromISO(entryA.subscriptionDate).toMillis() -
                        DateTime.fromISO(entryB.subscriptionDate).toMillis()
                      );
                    }
                    return diff;
                  })
                  .map((entry) => (
                    <TaxRedemptionEntry key={entry.id} redemptionPart={entry} />
                  ))}
              </>
            )}
          </tbody>
        </LysaTable>
        <div className="tax-redemption-modal__table-total-wrapper">
          <div className="tax-redemption-modal__table-total">
            <Typography type="label-large">
              <TranslatedText id="germany.tax.details.TaxRedemptionModal.totalHeade" />
            </Typography>
            <p>
              {intl.formatNumber(
                Math.trunc(
                  redemptionPart.reduce(
                    (acc, account) => acc + account.taxableGainLoss,
                    0
                  )
                ),
                {
                  style: "currency",
                  currency: currency,
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }
              )}
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

interface TaxRedemptionEntryProps {
  redemptionPart: RedemptionPartResponse;
}

const TaxRedemptionEntry: React.VFC<TaxRedemptionEntryProps> = ({
  redemptionPart,
}) => {
  const intl = useIntl();
  const currency = useCurrency();

  const currencyOptions: FormatNumberOptions = {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return (
    <tr>
      <LysaTableTextCell
        label={intl.formatMessage(tableLabelMessages.redemptionDate)}
        value={intl.formatDate(redemptionPart.redemptionDate)}
      />
      <LysaTableTextCell
        label={intl.formatMessage(tableLabelMessages.redemptionVolume)}
        value={intl.formatNumber(redemptionPart.redemptionVolume, {
          minimumSignificantDigits: 3,
        })}
      />
      <LysaTableTextCell
        label={intl.formatMessage(tableLabelMessages.redemptionPrice)}
        value={intl.formatNumber(
          redemptionPart.redemptionPrice,
          currencyOptions
        )}
      />
      <LysaTableTextCell
        label={intl.formatMessage(tableLabelMessages.subscriptionDate)}
        value={intl.formatDate(redemptionPart.subscriptionDate)}
      />
      <LysaTableTextCell
        label={intl.formatMessage(tableLabelMessages.subscriptionPrice)}
        value={intl.formatNumber(
          redemptionPart.subscriptionPrice,
          currencyOptions
        )}
      />
      <LysaTableTextCell
        label={intl.formatMessage(tableLabelMessages.preTaxedVap)}
        value={intl.formatNumber(redemptionPart.preTaxedVap, currencyOptions)}
      />
      <LysaTableTextCell
        label={intl.formatMessage(tableLabelMessages.taxableGainLoss)}
        value={intl.formatNumber(
          redemptionPart.taxableGainLoss,
          currencyOptions
        )}
      />
    </tr>
  );
};
