import { PepState } from "@lysaab/lysa-pep";
import { createContext } from "../../../context/CreateContext";

/**
 * Context that is used for the specifik purpose that if a company customer has two or more
 * verkliga huvudmän then we need to loop PEP questions for all of those and display summary and more.
 */
export interface MultiPepState {
  index: number;
  isEditing: boolean;
  pepStates?: PepState[];
}

export const [MultiPepStateContextProvider, useMultiPepContext] =
  createContext<MultiPepState>("MultiPepContext", {
    index: -1,
    isEditing: false,
  });
