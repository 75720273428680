import { Alternative, Select } from "@lysaab/ui-2";
import { useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { CompoundAccountId } from "../../data/dataAccounts";
import { useAccounts } from "../../hooks/useAccounts";
import "./AccountSelect.scss";

interface Props {
  onChange: (value?: CompoundAccountId) => void;
  value?: CompoundAccountId;
}

const messages = defineMessages({
  label: {
    id: "sharedAccountsPage.accountSelect.label",
  },
  placeholder: {
    id: "sharedAccountsPage.accountSelect.placeholder",
  },
  allAccounts: {
    id: "sharedAccountsPage.accountSelect.allAccounts",
  },
});

export const AccountSelect: React.VFC<Props> = ({ onChange, value }) => {
  const intl = useIntl();
  const { accounts } = useAccounts();

  const alternatives: Alternative<CompoundAccountId>[] = useMemo(() => {
    if (!accounts) return [];
    return [...accounts?.investmentAccounts, ...accounts?.savingsAccounts].map(
      ({ accountId, name }) => ({
        text: name,
        value: accountId,
      })
    );
  }, [accounts]);

  return (
    <div className="share-account-invitation-account-select">
      <Select
        alternatives={[
          { text: intl.formatMessage(messages.allAccounts), value: "" },
          ...(alternatives ? alternatives : []),
        ]}
        label={intl.formatMessage(messages.label)}
        placeholder={intl.formatMessage(messages.placeholder)}
        onChange={({ value }) =>
          onChange((value as CompoundAccountId) ?? undefined)
        }
        value={{
          text: "",
          value: value ?? "",
        }}
      />
    </div>
  );
};
