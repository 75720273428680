import { VoidFunctionComponent } from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  Alternative,
  Card,
  CheckboxGroup,
  RequiredValidator,
} from "@lysaab/ui-2";
import { PurposeAccount } from "../../../data/dataKyc";
import { DataDisplay } from "../../dataDisplay/DataDisplay";

export const messages = defineMessages({
  header: {
    id: "kyc.purpose.header",
  },
  required: {
    id: "kyc.purpose.required",
  },
});

interface MessageWithId {
  id: string;
}

export const personPurposeMessages = defineMessages<
  PurposeAccount,
  MessageWithId,
  Record<PurposeAccount, MessageWithId>
>({
  [PurposeAccount.FINANCIAL_SECURITY]: {
    id: "kyc.purpose.alt.FINANCIAL_SECURITY",
  },
  [PurposeAccount.PENSION]: {
    id: "kyc.purpose.alt.PENSION",
  },
  [PurposeAccount.CONSUMPTION]: {
    id: "kyc.purpose.alt.CONSUMPTION",
  },
  [PurposeAccount.GIFT]: {
    id: "kyc.purpose.alt.GIFT",
  },
  [PurposeAccount.LONGTERM_INVESTMENTS]: {
    id: "kyc.purpose.alt.LONGTERM_INVESTMENTS",
  },
});

interface Props {
  value?: PurposeAccount[];
  setValue: (purpose: PurposeAccount[]) => void;
  display?: boolean;
}

export const PurposeQuestionAccount: VoidFunctionComponent<Props> = ({
  value,
  setValue,
  display,
}) => {
  const intl = useIntl();

  const alternatives = Object.values(PurposeAccount).map((purpose) => {
    return {
      text: intl.formatMessage(personPurposeMessages[purpose]),
      value: purpose,
    } as Alternative<PurposeAccount>;
  });
  const valueAlternatives = alternatives.filter((alt) =>
    (value ?? []).includes(alt.value)
  );

  if (display) {
    return (
      <DataDisplay
        hasContainer
        type="input"
        title={intl.formatMessage(messages.header)}
        text={valueAlternatives.map((value) => value.text).join(", ")}
      />
    );
  }

  return (
    <Card>
      <CheckboxGroup
        alternatives={alternatives}
        values={valueAlternatives}
        onChange={(purposes) =>
          setValue(purposes.map((purpose) => purpose.value))
        }
        header={intl.formatMessage(messages.header)}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.required)),
        ]}
      />
    </Card>
  );
};
