import { FunctionComponent, useState, useRef } from "react";
import { Button, LysaFormRef, Form } from "@lysaab/ui-2";
import { useCreateKFAccount } from "../KFAccountContext";
import { PurposeQuestionAccountCoporation } from "../../../../../../components/kyc/account/PurposeQuestionAccountCoporation";
import { DepositIntervalQuestion } from "../../../../../../components/kyc/account/DepositIntervalQuestion";
import { WithdrawalIntervalQuestion } from "../../../../../../components/kyc/account/WithdrawalIntervalQuestion";
import { FormattedMessage, useIntl } from "react-intl";

interface Props {
  next: () => void;
}

export const LysaKyc: FunctionComponent<Props> = ({ next }) => {
  const formRef = useRef<LysaFormRef>();
  const [KFAccount, setKFAccount] = useCreateKFAccount();
  const [forceValidation, setForceValidation] = useState<boolean>(false);
  const intl = useIntl();

  return (
    <div className="create-account-corporation-kyc">
      <h1>
        <FormattedMessage id="sweden.kf.kyc.header" />
      </h1>
      <Form
        lysaFormRef={formRef}
        forceValidation={forceValidation}
        onSubmit={(event) => {
          event.preventDefault();
          if (formRef.current?.isValid) {
            next();
          } else {
            setForceValidation(true);
          }
        }}
      >
        <PurposeQuestionAccountCoporation
          value={KFAccount.purpose}
          setValue={(purpose) => {
            setKFAccount({ purpose });
          }}
        />

        <DepositIntervalQuestion
          setValue={(depositInterval) => {
            setKFAccount({ depositInterval });
          }}
          value={KFAccount.depositInterval}
        />

        <WithdrawalIntervalQuestion
          setValue={(withdrawalInterval) => {
            setKFAccount({ withdrawalInterval });
          }}
          value={KFAccount.withdrawalInterval}
        />
        <div className="bottom-nav">
          <Button
            block
            type="submit"
            label={intl.formatMessage({ id: "sweden.kf.kyc.button.next" })}
          />
        </div>
      </Form>
    </div>
  );
};
