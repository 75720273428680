import { useReducer, createContext, FunctionComponent } from "react";
import * as React from "react";
import { Alternative } from "@lysaab/ui-2";
import { InvestmentAccount } from "../../../data/dataAccounts";
import {
  ExternalAccount,
  PERIODIC_TYPE,
  WithdrawalAccount,
} from "../../../data/dataWithdrawals";
import { paymentDateAlternatives } from "./amountPeriodConfiguration/AmountPeriodConfiguration";
import { DateTime } from "luxon";

export interface WithdrawalPeriodicState {
  lysaAccount?: InvestmentAccount;
  withdrawalAccount?: WithdrawalAccount;
  externalAccount?: ExternalAccount;
  amount?: string;
  drain?: boolean;
  paymentMethod?: Alternative<PERIODIC_TYPE>;
  paymentDay: Alternative<number>;
  [PERIODIC_TYPE.FIXED_AMOUNT]?: string;
  [PERIODIC_TYPE.PERCENTAGE]?: Alternative<number>;
  [PERIODIC_TYPE.TARGET_DATE]?: Date;
}

export interface WithdrawalPeriodicContextProps {
  state: WithdrawalPeriodicState;
  setState: (newState: Partial<WithdrawalPeriodicState>) => void;
}

export const WithdrawalPeriodicContext =
  createContext<WithdrawalPeriodicContextProps>(
    {} as WithdrawalPeriodicContextProps
  );

function stateReducer(
  state: WithdrawalPeriodicState,
  newState: Partial<WithdrawalPeriodicState>
) {
  return { ...state, ...newState };
}

export const WithdrawalPeriodicContextProvider: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {
    paymentDay: paymentDateAlternatives[DateTime.now().get("day") - 1],
  });

  return (
    <WithdrawalPeriodicContext.Provider value={{ state, setState }}>
      {children}
    </WithdrawalPeriodicContext.Provider>
  );
};
