import { useEffect, useContext } from "react";
import {
  Store,
  STORE_PERFORMANCE_INTERVAL,
  STORE_PERFORMANCE_INDEXES,
  STORE_PERFORMANCE_CUSTOM_START_DATE,
  STORE_PERFORMANCE_CUSTOM_END_DATE,
  STORE_PERFORMANCE_SHOW_EVENTS,
  CURRENT_DAY,
} from "../../Store";
import { PerformanceContext } from "../../context/PerformanceContext";
import { dataPerformance } from "../../data/dataPerformance";

export function PersistSettings() {
  const {
    interval,
    indexes,
    customStartDate,
    customEndDate,
    showEvents,
  } = useContext(PerformanceContext);

  useEffect(() => {
    Store.setValue(STORE_PERFORMANCE_INTERVAL, interval);
  }, [interval]);

  useEffect(() => {
    Store.setValue(STORE_PERFORMANCE_INDEXES, indexes);
  }, [indexes]);

  useEffect(() => {
    Store.setValue(STORE_PERFORMANCE_CUSTOM_START_DATE, customStartDate);
  }, [customStartDate]);

  useEffect(() => {
    const currentDate = dataPerformance.toStringDate(new Date());
    if (
      customEndDate &&
      currentDate === dataPerformance.toStringDate(customEndDate)
    ) {
      Store.setValue(STORE_PERFORMANCE_CUSTOM_END_DATE, CURRENT_DAY);
    } else {
      Store.setValue(STORE_PERFORMANCE_CUSTOM_END_DATE, customEndDate);
    }
  }, [customEndDate]);

  useEffect(() => {
    Store.setValue(STORE_PERFORMANCE_SHOW_EVENTS, showEvents);
  }, [showEvents]);

  return null;
}
