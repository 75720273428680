import { useEffect, useState, VoidFunctionComponent } from "react";
import { Button, Snackbar, SNACKBAR_TYPES, Spinner } from "@lysaab/ui-2";
import { ConfirmationTextSection } from "./ConfirmationTextSection";
import { ConfirmationSummarySection } from "./ConfirmationSummarySection";
import { AccountType, InvestmentType } from "../../data/dataAccounts";
import { FormattedMessage } from "react-intl";
import { SavingsHorizonLength } from "../../data/dataInvestments";
import "./AccountConfirmation.scss";
import { dataDocuments, DocumentInformation } from "../../data/dataDocuments";

interface Props {
  risk: number;
  accountType: AccountType;
  accountName: string;
  investmentType: InvestmentType;
  horizonLength: SavingsHorizonLength;
  loading: boolean;
  error: boolean;
  showInvestmentType?: boolean;
  next: () => void;
}

export const AccountConfirmation: VoidFunctionComponent<Props> = ({
  risk,
  accountType,
  accountName,
  investmentType,
  horizonLength,
  loading,
  error,
  showInvestmentType,
  next,
}) => {
  const [documents, setDocuments] = useState<DocumentInformation[]>();

  useEffect(() => {
    dataDocuments.getDocumentsInformation(true).then(setDocuments);
  }, []);

  if (loading || typeof documents === "undefined") {
    return <Spinner />;
  }

  return (
    <div className="confirm-page">
      <h2>
        <FormattedMessage id="confirmation.header" />
      </h2>
      {error && (
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <FormattedMessage id="confirmation.error.text" />
        </Snackbar>
      )}
      <ConfirmationTextSection
        risk={risk}
        accountName={accountName}
        investmentType={investmentType}
        documents={documents}
      />
      <ConfirmationSummarySection
        risk={risk}
        horizonLength={horizonLength}
        accountType={accountType}
        investmentType={investmentType}
        showInvestmentType={showInvestmentType}
      />
      <Button
        block
        onClick={next}
        label={<FormattedMessage id="confirmation.button.next" />}
      />
    </div>
  );
};
