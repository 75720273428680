import { VoidFunctionComponent } from "react";
import { FundFeeDetails } from "../../../../../../../data/dataFees";
import { ExpandableTable } from "../../../components/expandableTable/ExpandableTable";
import { defineMessages, useIntl } from "react-intl";
import { CostHeaderCell } from "../../../../components/costHeaderCell/CostHeaderCell";
import { FeeHeaderCell } from "../../../../components/feeHeaderCell/FeeHeaderCell";
import { CostBodyCell } from "../../../../components/costBodyCell/CostBodyCell";
import { FeeBodyCell } from "../../../../components/feeBodyCell/FeeBodyCell";
import { LysaTableTextCell } from "../../../../../../../components/lysaTableTextCell/LysaTableTextCell";
import { getFundName } from "../../../../../../../components/fundName/FundName";

const messages = defineMessages({
  fundManagementFee: {
    id: "fees.feeTables.activeAccount.accountRow.fundFeeDetailsTable.fundManagementFee",
  },
  underlyingAssetsFee: {
    id: "fees.feeTables.activeAccount.accountRow.fundFeeDetailsTable.underlyingAssetsFee",
  },
  underlyingTransactionsFee: {
    id: "fees.feeTables.activeAccount.accountRow.fundFeeDetailsTable.underlyingTransactionsFee",
  },
  averageManagementCost: {
    id: "fees.feeTables.activeAccount.accountRow.fundFeeDetailsTable.averageManagementCost",
  },
  averageUnderlyingAssetsCost: {
    id: "fees.feeTables.activeAccount.accountRow.fundFeeDetailsTable.averageUnderlyingAssetsCost",
  },
  averageWorth: {
    id: "fees.feeTables.activeAccount.accountRow.fundFeeDetailsTable.averageWorth",
  },
  daysInvested: {
    id: "fees.feeTables.activeAccount.accountRow.fundFeeDetailsTable.daysInvested",
  },
  fundName: {
    id: "fees.feeTables.activeAccount.accountRow.fundFeeDetailsTable.fundName",
  },
  underlyingTransactionsCost: {
    id: "fees.feeTables.activeAccount.accountRow.fundFeeDetailsTable.underlyingTransactionsCost",
  },
  averageTotalCost: {
    id: "fees.feeTables.activeAccount.accountRow.fundFeeDetailsTable.averageTotalCost",
  },
});

interface Props {
  fundFeeDetails: FundFeeDetails[];
  isOpen: boolean;
}

const NBR_OF_COLS_IN_FUND_FEES = 5;

export const FundFeeDetailsTable: VoidFunctionComponent<Props> = ({
  fundFeeDetails,
  isOpen,
}) => {
  const intl = useIntl();

  return (
    <ExpandableTable isOpen={isOpen} parentCells={NBR_OF_COLS_IN_FUND_FEES}>
      <thead>
        <tr>
          <LysaTableTextCell
            value={intl.formatMessage(messages.fundName)}
            header={true}
          />
          <FeeHeaderCell
            text={intl.formatMessage(messages.fundManagementFee)}
          />
          <FeeHeaderCell
            text={intl.formatMessage(messages.underlyingAssetsFee)}
          />
          <FeeHeaderCell
            text={intl.formatMessage(messages.underlyingTransactionsFee)}
          />
          <CostHeaderCell
            text={intl.formatMessage(messages.averageManagementCost)}
          />
          <CostHeaderCell
            text={intl.formatMessage(messages.averageUnderlyingAssetsCost)}
          />
          <CostHeaderCell
            text={intl.formatMessage(messages.underlyingTransactionsCost)}
          />

          <th>{intl.formatMessage(messages.daysInvested)}</th>
          <FeeHeaderCell text={intl.formatMessage(messages.averageWorth)} />
          <CostHeaderCell
            text={intl.formatMessage(messages.averageTotalCost)}
          />
        </tr>
      </thead>
      <tbody>
        {fundFeeDetails.map((fundFeeDetail) => {
          return (
            <tr key={fundFeeDetail.isin}>
              <LysaTableTextCell
                value={getFundName(fundFeeDetail.isin, intl)}
                label={intl.formatMessage(messages.fundName)}
              />
              <FeeBodyCell
                value={fundFeeDetail.fundManagementFee}
                label={intl.formatMessage(messages.fundManagementFee)}
              />
              <FeeBodyCell
                value={fundFeeDetail.underlyingAssetsFee}
                label={intl.formatMessage(messages.underlyingAssetsFee)}
              />
              <FeeBodyCell
                value={fundFeeDetail.underlyingTransactionsFee}
                label={intl.formatMessage(messages.underlyingTransactionsFee)}
              />
              <CostBodyCell
                value={fundFeeDetail.averageManagementCost}
                label={intl.formatMessage(messages.averageManagementCost)}
              />
              <CostBodyCell
                value={fundFeeDetail.averageUnderlyingAssetsCost}
                label={intl.formatMessage(messages.averageUnderlyingAssetsCost)}
              />
              <CostBodyCell
                value={fundFeeDetail.underlyingTransactionsCost}
                label={intl.formatMessage(messages.underlyingTransactionsCost)}
              />
              <td data-label={intl.formatMessage(messages.daysInvested)}>
                {fundFeeDetail.daysInvested}
              </td>
              <CostBodyCell
                value={fundFeeDetail.averageTotalCost}
                label={intl.formatMessage(messages.averageTotalCost)}
              />
              <FeeBodyCell
                value={fundFeeDetail.averageWorth}
                label={intl.formatMessage(messages.averageWorth)}
              />
            </tr>
          );
        })}
      </tbody>
    </ExpandableTable>
  );
};
