import { Button, Card } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { useShareAccountContext } from "./ShareAccountContext";
import "./ShareAccountConfirm.scss";
import { SHARE_ACCOUNT_INVITATION_TTL_DAYS } from "../../data/dataAttorney";
import { getNavLink } from "../../hooks/useCountryUrls";
import { INVITE_PAGE_URL } from "../invite/InvitePage";
import { RouteAwareToggle } from "../../components/route/RouteAwareToggle";
import { Link } from "react-router-dom";
import { ShareAccountIntroList } from "./shareAccountIntroList/ShareAccountIntroList";
import { TranslatedText } from "../../components/TranslatedText";
import {
  ShareAccountConfirmDocs,
  shareAccountConfirmDocumentList,
} from "./ShareAccountConfirmDocs";
import { useContext, useState } from "react";
import { LocalizationContext } from "../../context/LocalizationContext";

interface Props {
  next: () => void;
}

export const ShareAccountConfirm: React.VFC<Props> = ({ next }) => {
  const { state: shareAccountState } = useShareAccountContext();
  const [document, setDocument] = useState<string>();
  const localizationContext = useContext(LocalizationContext);
  const { country } = localizationContext.state;

  if (typeof country === "undefined") {
    return null;
  }

  return (
    <div className="share-account-confirm">
      <h2>
        <FormattedMessage id="shareAccountStory.confirm.header" />
      </h2>
      <ShareAccountIntroList />
      <h3>
        <FormattedMessage id="shareAccountStory.confirm.summaryHeader" />
      </h3>
      <Card>
        <div className="list-item">
          <strong>
            <FormattedMessage id="shareAccountStory.confirm.summary.accountNameLabel" />
          </strong>
          <div className="list-item-value">
            {shareAccountState.account?.name}
          </div>
        </div>
        <div className="list-item">
          <strong>
            <FormattedMessage id="shareAccountStory.confirm.summary.expiryLabel" />
          </strong>
          <FormattedMessage
            id="shareAccountStory.confirm.summary.expiryValue"
            values={{
              days: SHARE_ACCOUNT_INVITATION_TTL_DAYS,
            }}
          />
        </div>
        {shareAccountState.account && (
          <div className="list-item">
            <strong>
              <FormattedMessage id="shareAccountStory.confirm.summary.agreementLabel" />
            </strong>
            <ShareAccountConfirmDocs
              documentVariables={{
                accountName: shareAccountState.account.name,
                accountId: shareAccountState.account.accountId,
              }}
              document={document}
              setDocument={setDocument}
            />
          </div>
        )}
      </Card>
      <p className="disclaimer">
        <TranslatedText
          id="shareAccountStory.confirm.confirmNotice"
          values={{
            break: () => <br />,
            policy: (parts) => (
              <button
                className="as-link"
                type="button"
                onClick={() =>
                  setDocument(shareAccountConfirmDocumentList[country][1])
                }
              >
                {parts}
              </button>
            ),
          }}
        />
      </p>
      <Button
        block
        onClick={next}
        label={<FormattedMessage id="shareAccountStory.confirm.nextButton" />}
      />
      <RouteAwareToggle path={getNavLink(INVITE_PAGE_URL)}>
        <div className="non-lysa-customer-section">
          <p>
            <FormattedMessage id="shareAccountStory.confirm.inviteToLysaText" />
          </p>
          <Button
            component={Link}
            variant="secondary"
            block
            to={getNavLink(INVITE_PAGE_URL)}
            label={
              <FormattedMessage id="shareAccountStory.confirm.inviteToLysaButton" />
            }
          />
        </div>
      </RouteAwareToggle>
    </div>
  );
};
