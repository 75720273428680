import { useContext, VoidFunctionComponent } from "react";
import { Button, Form, LysaFormRef } from "@lysaab/ui-2";
import { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { DepositIntervalQuestion } from "../../../components/kyc/account/DepositIntervalQuestion";
import { WithdrawalIntervalQuestion } from "../../../components/kyc/account/WithdrawalIntervalQuestion";
import { CreateAccountContext } from "../../../pages/createAccount/CreateAccountContext";
import { PurposeQuestionAccount } from "../../../components/kyc/account/PurposeQuestionAccount";
import { PurposeQuestionAccountCoporation } from "../../../components/kyc/account/PurposeQuestionAccountCoporation";
import { AccountType } from "../../../data/dataAccounts";
import { useIsPerson } from "../../../hooks/useIsPerson";

interface Props {
  next: () => void;
}

export const Kyc: VoidFunctionComponent<Props> = ({ next }) => {
  const formRef = useRef<LysaFormRef>();
  const isPerson = useIsPerson();
  const createAccountContext = useContext(CreateAccountContext);

  return (
    <div>
      <h2>
        <FormattedMessage id="create-account.kyc.header" />
      </h2>
      <p>
        {isPerson ? (
          <FormattedMessage id="create-account.kyc.intro" />
        ) : (
          <FormattedMessage id="create-account.kyc.corp.intro" />
        )}
      </p>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (formRef.current?.isInvalid) {
            return;
          }

          next();
        }}
      >
        {(createAccountContext.state.accountType === AccountType.ISK_SWE ||
          createAccountContext.state.accountType === AccountType.VP) && (
          <PurposeQuestionAccount
            value={createAccountContext.state.purpose}
            setValue={(purpose) => createAccountContext.setState({ purpose })}
          />
        )}
        {(createAccountContext.state.accountType === AccountType.VP_SWE ||
          createAccountContext.state.accountType === AccountType.DANICA_KF) && (
          <PurposeQuestionAccountCoporation
            value={createAccountContext.state.corporatePurpose}
            setValue={(purpose) =>
              createAccountContext.setState({ corporatePurpose: purpose })
            }
          />
        )}
        <DepositIntervalQuestion
          value={createAccountContext.state.depositInterval}
          setValue={(depositInterval) =>
            createAccountContext.setState({ depositInterval })
          }
        />
        <WithdrawalIntervalQuestion
          value={createAccountContext.state.withdrawalInterval}
          setValue={(withdrawalInterval) =>
            createAccountContext.setState({ withdrawalInterval })
          }
        />

        <Button
          label={<FormattedMessage id="create-account.kyc.button.next" />}
          block
          type="submit"
        />
      </Form>
    </div>
  );
};
