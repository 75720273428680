import { FunctionComponent, useRef, useState, useEffect, useMemo } from "react";
import {
  LysaFormRef,
  Form,
  Button,
  RadioGroup,
  Alternative,
  RequiredValidator,
  SwedishTinValidator,
  Card,
  TextInput,
  MinAgeTinValidator,
  SwedishTin,
} from "@lysaab/ui-2";
import { useCreateKFAccount } from "../KFAccountContext";
import "./Candidate.scss";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { defineMessages, useIntl } from "react-intl";
import { dataLife } from "../../../../../../data/dataLife";
import { MaxAgeTinValidator } from "../../../../../../components/validators/MaxAgeValidator";

export const ROUTE = "/horizon";
interface Props {
  next: () => void;
}

export const messages = defineMessages({
  required: {
    id: "sweden.kf.candidate.required",
  },
  placeholder: {
    id: "sweden.kf.candidate.placeholder",
  },
  id: {
    id: "sweden.kf.candidate.id",
  },
  incorrectId: {
    id: "sweden.kf.candidate.incorrectId",
  },
  addId: {
    id: "sweden.kf.candidate.addId",
  },
  idRequired: {
    id: "sweden.kf.candidate.id-required",
  },
  minAge: {
    id: "sweden.kf.candidate.min-age",
  },
  maxAge: {
    id: "sweden.kf.candidate.max-age",
  },
  citizenship: {
    id: "sweden.kf.candidate.citizenship",
  },
  other: {
    id: "sweden.kf.candidate.other",
  },
});

export const Candidate: FunctionComponent<Props> = ({ next }) => {
  const formRef = useRef<LysaFormRef>();
  const [KFAccount, setKFAccount] = useCreateKFAccount();
  const [isManualInput, setIsManualInput] = useState<boolean>(false);
  const [alternatives, setAlternatives] = useState<Alternative<string>[]>([]);
  const intl = useIntl();
  const otherAlternative = useMemo(
    () => ({
      text: intl.formatMessage(messages.other),
      value: "other",
    }),
    [intl]
  );

  useEffect(() => {
    dataLife
      .getInsuranceCandidates()
      .then((data) => {
        const alternatives = data.map((candidate) => ({
          text: `${candidate.name} - ${candidate.tin}`,
          value: candidate.tin,
        }));
        setAlternatives(alternatives);
      })
      .catch(() => {});
  }, []);

  let value;

  if (isManualInput) {
    value = otherAlternative;
  } else {
    value = alternatives.find(
      (alternative) => alternative.value === KFAccount.identificationNumber
    );
  }

  return (
    <div className="create-account-corporation-candidate">
      <Form
        lysaFormRef={formRef}
        onSubmit={(event) => {
          event.preventDefault();
          if (formRef.current?.isValid) {
            next();
          }
        }}
      >
        <div className="create-account-corporation-candidate-top">
          <h1>
            <TranslatedText id="sweden.kf.candidate.header" />
          </h1>
          <p className="candidate-subtitle">
            <TranslatedText
              id="sweden.kf.candidate.ingress"
              values={{
                insured: intl.formatNumber(1.01, {
                  style: "percent",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
              }}
            />
          </p>
        </div>
        <Card>
          <RadioGroup
            header=""
            onChange={(candidate) => {
              if (candidate.value === otherAlternative.value) {
                setIsManualInput(true);
                setKFAccount({
                  identificationNumber: "",
                });
              } else {
                setIsManualInput(false);
                setKFAccount({
                  identificationNumber: candidate.value,
                });
              }
            }}
            value={value}
            alternatives={[...alternatives, otherAlternative]}
            validators={[
              new RequiredValidator(intl.formatMessage(messages.required)),
            ]}
          />

          {isManualInput ? (
            <TextInput
              label={intl.formatMessage(messages.addId)}
              value={KFAccount.identificationNumber || ""}
              onChange={(event: string) =>
                setKFAccount({ identificationNumber: event })
              }
              placeholder={intl.formatMessage(messages.placeholder)}
              validators={[
                new SwedishTinValidator(
                  intl.formatMessage(messages.incorrectId)
                ),
                new MinAgeTinValidator(
                  18,
                  SwedishTin,
                  intl.formatMessage(messages.minAge)
                ),
                new MaxAgeTinValidator(
                  80,
                  SwedishTin,
                  intl.formatMessage(messages.maxAge)
                ),
                new RequiredValidator(intl.formatMessage(messages.idRequired)),
              ]}
            />
          ) : null}
        </Card>
        <div className="bottom-nav">
          <Button
            block
            type="submit"
            label={<TranslatedText id="sweden.kf.candidate.next" />}
          />
        </div>
      </Form>
    </div>
  );
};
