import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../hooks/useSafeNavigation";
import { ReviewAccountHistoryState } from "../../reviewAccount/ReviewAccountStory";
import { SuitabilityAssessmentReturnState } from "../AccessGuardRoute";
import { useAccountsContext } from "../../../context/AccountsContext";
import { useEligibilityContext } from "../../../context/EligibilityContext";
import { useReviewAccountContext } from "../../reviewAccount/ReviewAccountContext";
import {
  getEligibility,
  isValidEligibility,
} from "../../../data/dataInvestments";
import { UpdateInvestmentAccountComposition } from "../../../pageComponents/accountsAllocation/accountAdviseCard/AccountAdviceCard";
import { useSuitabilityAssessmentContext } from "../contexts/SuitabilityAssessmentContext";

export const useNavigateToReviewAccount = () => {
  const safeNavigation = useSafeNavigation();
  const history = useHistory<SuitabilityAssessmentReturnState | undefined>();
  const [accountsState] = useAccountsContext();
  const [eligibilityState] = useEligibilityContext();
  const [reviewAccountState] = useReviewAccountContext();
  const [suitabilityAssessmentState] = useSuitabilityAssessmentContext();

  const navigateToReviewAccount = useCallback(
    (pathname: string, reviewAccount: UpdateInvestmentAccountComposition) => {
      const state: SuitabilityAssessmentReturnState = {
        accountsState,
        eligibilityState,
        reviewAccountState: {
          ...reviewAccountState,
          reviewAccount,
        },
        suitabilityAssessmentState,
      };

      // Replace current history state with saved contexts so if user uses browser back it doesn't crash
      history.replace({
        pathname: history.location.pathname,
        state,
      });

      const eligibility = getEligibility(eligibilityState);
      if (!isValidEligibility(eligibility)) {
        throw new Error("Can't move to substory. Eligibility not complete");
      }

      const returnState: ReviewAccountHistoryState<SuitabilityAssessmentReturnState> =
        {
          returnUrl: history.location.pathname,
          returnState: state,
          eligibility,
        };
      safeNavigation({
        pathname: getNavLink(pathname),
        state: returnState,
      });
    },
    [
      accountsState,
      eligibilityState,
      reviewAccountState,
      suitabilityAssessmentState,
      history,
      safeNavigation,
    ]
  );

  return navigateToReviewAccount;
};
