import {
  useRef,
  useState,
  useEffect,
  VoidFunctionComponent,
  useCallback,
} from "react";
import {
  Form,
  LysaFormRef,
  Spinner,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { EventTracker } from "../../../../components/eventTracker/EventTracker";
import {
  CorporationExpenses,
  Expenses,
} from "../../../../pageComponents/economicSituation/economicSituation/corporation/Expenses";
import {
  CorporationInsolvencyRisk,
  InsolvencyRisk,
} from "../../../../pageComponents/economicSituation/economicSituation/corporation/InsolvencyRisk";
import { useHistory } from "react-router";
import { TrackerEvent } from "../../../../data/dataCustomerTracking";
import { Important } from "../../../../pageComponents/economicSituation/risk/Important";
import { Reaction } from "../../../../pageComponents/economicSituation/risk/Reaction";
import { Propensity } from "../../../../pageComponents/economicSituation/risk/Propensity";
import {
  importantCorpMessages,
  propensityCorpMessages,
  reactionCorpMessages,
} from "../../../../pageComponents/economicSituation/risk/messages";
import "./SituationCorporation.scss";
import {
  isValidEligibilityStateCorporation,
  useEligibilityContext,
} from "../../../../context/EligibilityContext";
import { useBadEconomyEventTracking } from "./hooks/useBadEconomyEventTracking";
import { useNavigateToCloseAccount } from "../../hooks/useNavigateToCloseAccount";
import {
  dataInvestments,
  isValidEligibilityCorporation,
  EligibilityRiskAnswer,
  getEligibility,
} from "../../../../data/dataInvestments";
import { CloseCustomerScenario } from "../../../../data/dataProfile";

interface Props {
  next: () => void;
}

export const SituationCorporation: VoidFunctionComponent<Props> = ({
  next,
}) => {
  const history = useHistory();
  const formRef = useRef<LysaFormRef>();
  const postBadEconomyEvent = useBadEconomyEventTracking();
  const navigateToCloseAccount = useNavigateToCloseAccount();
  const [eligibilityState, setEligibilityState] = useEligibilityContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (history.location.state) {
      setLoading(false);
      return;
    }

    if (isValidEligibilityStateCorporation(eligibilityState)) {
      setLoading(false);
      return;
    }

    dataInvestments
      .getEligibility()
      .then((response) => {
        const eligibility = getEligibility(response);
        if (!isValidEligibilityCorporation(eligibility)) {
          throw new Error(
            "SituationCorporation - LegalEntityType is wrong for this component"
          );
        }

        setEligibilityState({
          // Default becuase they wouldn't be here if they hadn't answered this before
          insolvencyRisk: CorporationInsolvencyRisk.yes,
          expenses: CorporationExpenses.more,
          ...eligibility,
        });
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.state, setEligibilityState]);

  const onSubmit = useCallback(() => {
    if (
      formRef.current?.isValid &&
      isValidEligibilityStateCorporation(eligibilityState) &&
      eligibilityState.expenses === CorporationExpenses.more &&
      eligibilityState.insolvencyRisk === CorporationInsolvencyRisk.yes
    ) {
      EventTracker.track({
        event: TrackerEvent.REVIEW_ECONOMIC_SITUATION,
        message: { isBlocked: false },
      });
      next();
    } else {
      setEligibilityState({ showWarning: true });
    }
  }, [eligibilityState, next, setEligibilityState]);

  const onCloseCustomer = useCallback(() => {
    postBadEconomyEvent();
    navigateToCloseAccount(CloseCustomerScenario.ECONOMIC_SITUATION);
  }, [navigateToCloseAccount, postBadEconomyEvent]);

  if (loading) {
    return <Spinner />;
  }

  if (!isValidEligibilityStateCorporation(eligibilityState)) {
    console.log("SituationCorporation - Not ment for person usage");
    return <Spinner />;
  }

  return (
    <Form lysaFormRef={formRef} onSubmit={onSubmit}>
      <div className="yearly-corp-situation-page">
        <h2>
          <FormattedMessage id="situation.corp.economic" />
        </h2>
        <section>
          <Expenses
            value={eligibilityState.expenses}
            setValue={(expenses) => {
              const hasBadEconomy =
                eligibilityState.insolvencyRisk !==
                  CorporationInsolvencyRisk.yes ||
                expenses !== CorporationExpenses.more;

              if (hasBadEconomy !== eligibilityState.hasBadEconomy) {
                setEligibilityState({ hasBadEconomy });
              }
              setEligibilityState({ expenses });
            }}
          />
          {eligibilityState.showWarning &&
            eligibilityState.expenses !== CorporationExpenses.more && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                <FormattedMessage id="situation.expenses.error" />
              </Snackbar>
            )}
          <InsolvencyRisk
            value={eligibilityState.insolvencyRisk}
            setValue={(insolvencyRisk) => {
              const hasBadEconomy =
                insolvencyRisk !== CorporationInsolvencyRisk.yes ||
                eligibilityState.expenses !== CorporationExpenses.more;

              if (hasBadEconomy !== eligibilityState.hasBadEconomy) {
                setEligibilityState({ hasBadEconomy });
              }
              setEligibilityState({ insolvencyRisk });
            }}
          />
          {eligibilityState.showWarning &&
            eligibilityState.insolvencyRisk !==
              CorporationInsolvencyRisk.yes && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                <FormattedMessage id="situation.insolvencyRisk.error" />
              </Snackbar>
            )}
        </section>

        <h2>
          <FormattedMessage id="situation.corp.risk" />
        </h2>
        <section>
          <Important
            value={
              eligibilityState.risk.answers[EligibilityRiskAnswer.IMPORTANCE]
            }
            setValue={(importance) =>
              setEligibilityState({
                risk: {
                  answers: {
                    ...eligibilityState.risk.answers,
                    [EligibilityRiskAnswer.IMPORTANCE]: importance,
                  },
                },
              })
            }
            messages={importantCorpMessages}
          />
          <Reaction
            value={
              eligibilityState.risk.answers[EligibilityRiskAnswer.REACTION]
            }
            setValue={(reaction) =>
              setEligibilityState({
                risk: {
                  answers: {
                    ...eligibilityState.risk.answers,
                    [EligibilityRiskAnswer.REACTION]: reaction,
                  },
                },
              })
            }
            messages={reactionCorpMessages}
          />
          <Propensity
            value={
              eligibilityState.risk.answers[EligibilityRiskAnswer.PROPENSITY]
            }
            setValue={(propensity) =>
              setEligibilityState({
                risk: {
                  answers: {
                    ...eligibilityState.risk.answers,
                    [EligibilityRiskAnswer.PROPENSITY]: propensity,
                  },
                },
              })
            }
            messages={propensityCorpMessages}
          />
        </section>

        {eligibilityState.showWarning &&
        eligibilityState.expenses !== CorporationExpenses.more &&
        eligibilityState.insolvencyRisk !== CorporationInsolvencyRisk.yes ? (
          <Button
            block
            variant="negative"
            onClick={onCloseCustomer}
            label={
              <FormattedMessage id="situation.corp.closeLysaCustomerConfirmation" />
            }
          />
        ) : (
          <Button
            block
            type="submit"
            label={<FormattedMessage id="situation.corp.button.next" />}
          />
        )}
      </div>
    </Form>
  );
};
