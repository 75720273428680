import { FunctionComponent } from "react";

interface Props {
  primaryColor: string;
}

export const PersonalSecurity: FunctionComponent<Props> = ({
  primaryColor,
}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6C9.79086 6 8 7.79086 8 10C8 12.2091 9.79086 14 12 14ZM12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12Z"
        fill={primaryColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4937 21.4377L13.0429 21.71C12.4027 22.0967 11.5973 22.0967 10.9571 21.71L10.5063 21.4377C5.83961 18.6197 3 16.905 3 8.0336V6.61034C3 5.69835 3.65123 4.91314 4.55595 4.73429L5.81114 4.48615C7.25208 4.20129 8.61019 3.60107 9.78577 2.72956C11.0979 1.75681 12.9021 1.75681 14.2142 2.72956C15.3898 3.60107 16.7479 4.20129 18.1889 4.48615L19.4441 4.73429C20.3488 4.91314 21 5.69835 21 6.61034V8.0336C21 16.905 18.1604 18.6197 13.4937 21.4377ZM5 8.0336V6.68521L6.19901 6.44818C7.92959 6.10605 9.56226 5.38491 10.9769 4.33621C11.5815 3.88793 12.4185 3.88793 13.0231 4.33621C14.4377 5.38491 16.0704 6.10605 17.801 6.44818L19 6.68521V8.0336C19 12.3307 18.2989 14.5097 17.3142 15.9227C17.1803 16.1148 17.0385 16.2975 16.8876 16.4733L16.8787 16.4645C15.941 15.5268 14.6692 15 13.3432 15H10.6569C9.33079 15 8.05902 15.5268 7.12134 16.4645L7.11244 16.4734C6.96151 16.2976 6.81966 16.1148 6.68577 15.9227C5.70113 14.5097 5 12.3307 5 8.0336ZM8.59256 17.8232C9.14915 17.2953 9.88778 17 10.6569 17H13.3432C14.1122 17 14.8509 17.2953 15.4075 17.8231C14.5841 18.4386 13.5736 19.0528 12.3091 19.8166L12 20L11.6909 19.8166C10.4264 19.0528 9.41593 18.4386 8.59256 17.8232Z"
        fill={primaryColor}
      />
    </svg>
  );
};
