import { useEffect, useState } from "react";
import { InvestmentAccountId } from "../data/dataAccounts";
import {
  CombinedAdviseAccount,
  dataInvestments,
} from "../data/dataInvestments";
import { useApi } from "@lysaab/ui-2";
import { isSuccessful } from "@lysaab/ui-2/network/useApi";

export function useAdviseAccount(
  accountId?: InvestmentAccountId
): [advise: CombinedAdviseAccount | undefined, loadAdvise: () => void] {
  const adviseAccounts = useApi(dataInvestments.getAdviseAccounts);
  const [advise, setAccountAdvice] = useState<CombinedAdviseAccount>();

  useEffect(() => {
    if (typeof accountId !== "undefined") {
      setAccountAdvice(undefined);
    }

    if (typeof accountId !== "undefined" && isSuccessful(adviseAccounts)) {
      setAccountAdvice(
        adviseAccounts.data.find(
          (adviseAccount) => adviseAccount.accountId === accountId
        )
      );
    }
  }, [accountId, adviseAccounts]);

  return [advise, adviseAccounts.loadData];
}
