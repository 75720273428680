import { LysaCountry } from "@lysaab/shared";
import {
  DanishTin,
  FinnishTin,
  SwedishTin,
  GermanTin,
  AllTinTypes,
  SpanishTin,
} from "@lysaab/ui-2";

const countryTinParserMap: Record<LysaCountry, AllTinTypes> = {
  [LysaCountry.DENMARK]: DanishTin,
  [LysaCountry.FINLAND]: FinnishTin,
  [LysaCountry.SWEDEN]: SwedishTin,
  [LysaCountry.SPAIN]: SpanishTin,
  [LysaCountry.GERMANY]: GermanTin,
};

export function normalizeTin(tin: string, country: LysaCountry) {
  const TinParser = countryTinParserMap[country];

  return new TinParser(tin).getNormalizedTin();
}
