import { FunctionComponent } from "react";
import * as React from "react";
import { GridCol } from "../../../../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../../../../components/grid/gridRow/GridRow";
import { InvestmentAccountId } from "../../../../../../data/dataAccounts";
import {
  CashTransaction,
  FeeTransaction,
  FeeType,
  FundTransaction,
} from "../../../../../../data/dataCorporate";
import {
  CashTransactionType,
  FundTransactionType,
} from "../../../../../../data/dataTransactions";
import { CashDepositWithdrawal } from "./cashTransactions/CashDepositWithdrawal";
import { CashFeeTax } from "./cashTransactions/CashFeeTax";
import { CashMove } from "./cashTransactions/CashMove";
import { FeeDiscInsurance } from "./feeTransactions/FeeDiscInsurance";
import { FundBuySell } from "./fundTransactions/FundBuySell";
import { FundCorrection } from "./fundTransactions/FundCorrection";
import { FundMove } from "./fundTransactions/FundMove";
import { FundSwitch } from "./fundTransactions/FundSwitch";
import "./TransactionDeclaration.scss";

export type DeclarationTransaction =
  | CashTransaction
  | FeeTransaction
  | FundTransaction;

export interface TransactionDeclarationProps {
  title: string;
  transactions: DeclarationTransaction[];
  missingData: React.ReactNode;
  getAccountName: (accountId: InvestmentAccountId) => string;
}

export const TransactionDeclaration: FunctionComponent<
  TransactionDeclarationProps
> = ({ title, transactions, missingData, getAccountName }) => {
  return (
    <GridRow as="ul" className="transaction-declaration">
      <h5>{title}</h5>
      {transactions.length ? (
        transactions.map((transaction, index) => {
          return (
            <GridCol
              as="li"
              xsmall={12}
              key={title + index}
              className="declaration-list"
            >
              <GridRow>
                <TransactionTypeDisplay
                  transaction={transaction}
                  getAccountName={getAccountName}
                />
              </GridRow>
            </GridCol>
          );
        })
      ) : missingData ? (
        <GridCol as="li" xsmall={12} className="info-box">
          {missingData}
        </GridCol>
      ) : null}
    </GridRow>
  );
};

function TransactionTypeDisplay({
  transaction,
  getAccountName,
}: {
  transaction: DeclarationTransaction;
  getAccountName: (accountId: InvestmentAccountId) => string;
}) {
  if (instanceOfFundTransaction(transaction)) {
    switch (transaction.type) {
      case FundTransactionType.SELL:
      case FundTransactionType.BUY: {
        return <FundBuySell transaction={transaction} />;
      }
      case FundTransactionType.MOVE_IN:
      case FundTransactionType.MOVE_OUT: {
        return <FundMove transaction={transaction} />;
      }
      case FundTransactionType.SWITCH_BUY:
      case FundTransactionType.SWITCH_SELL: {
        return <FundSwitch transaction={transaction} />;
      }
      case FundTransactionType.CORRECTION: {
        return <FundCorrection transaction={transaction} />;
      }
    }
  }

  if (instanceOfCashTransaction(transaction)) {
    switch (transaction.transactionType) {
      case CashTransactionType.DEPOSIT:
      case CashTransactionType.WITHDRAWAL: {
        return <CashDepositWithdrawal transaction={transaction} />;
      }
      case CashTransactionType.MOVE_IN:
      case CashTransactionType.MOVE_OUT: {
        return (
          <CashMove transaction={transaction} getAccountName={getAccountName} />
        );
      }
      case CashTransactionType.FEE:
      case CashTransactionType.TAX: {
        return (
          <CashFeeTax
            transaction={transaction}
            getAccountName={getAccountName}
          />
        );
      }
      case CashTransactionType.INTEREST: {
        return null;
      }
    }
  }

  switch (transaction.feeType) {
    case FeeType.DISC:
    case FeeType.INSURANCE: {
      return <FeeDiscInsurance transaction={transaction} />;
    }
  }
}

function instanceOfCashTransaction(
  transaction: DeclarationTransaction
): transaction is CashTransaction {
  return transaction.hasOwnProperty("transactionId");
}

function instanceOfFundTransaction(
  transaction: DeclarationTransaction
): transaction is FundTransaction {
  return transaction.hasOwnProperty("orderId");
}
