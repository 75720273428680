import { FC, ReactNode, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Slider } from "@lysaab/ui-2";
import { getAssetsSteps, getMonthlySavingsSteps } from "./useSteps";
import { CustomStepsSlider } from "./CustomStepsSlider";
import { useLocalizationContext } from "../../context/LocalizationContext";

const SliderLabel: FC<{
  description: ReactNode;
  value: ReactNode;
}> = ({ description, value }) => {
  return (
    <h4 className="slider-header">
      <strong>{description}</strong>
      <span>{value}</span>
    </h4>
  );
};

interface Props {
  amount: number;
  onAmountChange: (value: number) => void;
  monthly: number;
  onMonthlyChange: (value: number) => void;
  horizonYears: number;
  onHorizonYearsChange: (value: number) => void;
  expectedYield: number;
  onExpectedYieldChange: (value: number) => void;
}

export const FeesInformationEditor: FC<Props> = ({
  amount,
  onAmountChange,
  monthly,
  onMonthlyChange,
  horizonYears,
  onHorizonYearsChange,
  expectedYield,
  onExpectedYieldChange,
}) => {
  const intl = useIntl();
  const localizationContext = useLocalizationContext();

  const amountSteps = useMemo(
    // Invested amount cannot be 0 when querying fees from the API,
    // so we slice the first step (which is 0).
    // Should this be handled within getAssetsSteps() instead?
    () => getAssetsSteps(localizationContext.state.currency).slice(1),
    [localizationContext.state.currency]
  );
  const monthlySteps = useMemo(
    () => getMonthlySavingsSteps(localizationContext.state.currency),
    [localizationContext.state.currency]
  );

  return (
    <>
      <CustomStepsSlider
        value={amount}
        onChange={(value) => onAmountChange(value)}
        steps={amountSteps}
        label={
          <SliderLabel
            description={
              <FormattedMessage id="feesInformation.card.total.slider.header" />
            }
            value={
              <FormattedMessage
                id="feesInformation.card.total.slider.value"
                values={{
                  amount: intl.formatNumber(amount, {
                    style: "currency",
                    currency: localizationContext.state.currency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }),
                }}
              />
            }
          />
        }
      />
      <CustomStepsSlider
        value={monthly}
        onChange={(value) => onMonthlyChange(value)}
        steps={monthlySteps}
        label={
          <SliderLabel
            description={
              <FormattedMessage id="feesInformation.card.monthlyInvestment.slider.header" />
            }
            value={
              <FormattedMessage
                id="feesInformation.card.monthlyInvestment.slider.value"
                values={{
                  monthly: intl.formatNumber(monthly, {
                    style: "currency",
                    currency: localizationContext.state.currency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }),
                }}
              />
            }
          />
        }
      />
      <Slider
        min={1}
        max={50}
        step={1}
        value={horizonYears}
        onChange={(value) => onHorizonYearsChange(value)}
        label={
          <SliderLabel
            description={
              <FormattedMessage id="feesInformation.card.horizon.slider.header" />
            }
            value={
              <FormattedMessage
                id="feesInformation.card.horizon.slider.value"
                values={{ horizonYears }}
              />
            }
          />
        }
      />
      <Slider
        min={0}
        max={15}
        step={0.5}
        value={expectedYield}
        onChange={(value) => onExpectedYieldChange(value)}
        label={
          <SliderLabel
            description={
              <FormattedMessage id="feesInformation.card.expectedYield.slider.header" />
            }
            value={
              <FormattedMessage
                id="feesInformation.card.expectedYield.slider.value"
                values={{
                  expectedYield: intl.formatNumber(expectedYield / 100, {
                    style: "percent",
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  }),
                }}
              />
            }
          />
        }
      />
    </>
  );
};
