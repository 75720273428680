import { VoidFunctionComponent } from "react";
import { useIntl, defineMessages } from "react-intl";
import { Alternative, Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { SavingsAccountWithdrawalInterval } from "../../../data/dataKyc";
import { DataDisplay } from "../../dataDisplay/DataDisplay";

export const messages = defineMessages({
  header: {
    id: "kyc.savingsAccountWithdrawalinterval.header.savings",
  },
  required: {
    id: "kyc.savingsAccountWithdrawalinterval.required",
  },
});

interface MessageWithId {
  id: string;
}

const alternativeMessages = defineMessages<
  SavingsAccountWithdrawalInterval,
  MessageWithId,
  Record<SavingsAccountWithdrawalInterval, MessageWithId>
>({
  [SavingsAccountWithdrawalInterval.MORE_THAN_FIVE_TIMES_MONTHLY]: {
    id: "kyc.savingsAccountWithdrawalinterval.alt.MORE_THAN_FIVE_TIMES_MONTHLY",
  },
  [SavingsAccountWithdrawalInterval.ONE_TO_FIVE_TIMES_MONTHLY]: {
    id: "kyc.savingsAccountWithdrawalinterval.alt.ONE_TO_FIVE_TIMES_MONTHLY",
  },
  [SavingsAccountWithdrawalInterval.LESS_THAN_ONE_TIME_MONTHLY]: {
    id: "kyc.savingsAccountWithdrawalinterval.alt.LESS_THAN_ONE_TIME_MONTHLY",
  },
});

interface Props {
  value?: SavingsAccountWithdrawalInterval;
  setValue: (depositInterval: SavingsAccountWithdrawalInterval) => void;
  display?: boolean;
}

export const SavingsAccountWithdrawalIntervalQuestion: VoidFunctionComponent<
  Props
> = ({ value, setValue, display }) => {
  const intl = useIntl();

  const alternatives = Object.entries(alternativeMessages).map(
    ([withdrawalInterval, message]) => {
      return {
        text: intl.formatMessage(message),
        value: withdrawalInterval,
      } as Alternative<SavingsAccountWithdrawalInterval>;
    }
  );
  const valueAlternative = alternatives.find((alt) => alt.value === value);

  if (display) {
    return (
      <DataDisplay
        hasContainer
        type="input"
        title={intl.formatMessage(messages.header)}
        text={valueAlternative?.text}
      />
    );
  }

  return (
    <Card>
      <RadioGroup
        alternatives={alternatives}
        value={valueAlternative}
        header={intl.formatMessage(messages.header)}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.required)),
        ]}
        onChange={(withdrawalInterval) => setValue(withdrawalInterval.value)}
      />
    </Card>
  );
};
