import { useEffect, VoidFunctionComponent } from "react";
import { useHistory } from "react-router";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { RiskWarning } from "../../../../../../pageComponents/riskWarning/RiskWarning";
import { OVERVIEW_PAGE_URL } from "../../../../../../pages/overview/OverviewPage";
import { useCreateKFAccount } from "../KFAccountContext";

interface Props {
  next: () => void;
}

export const RiskWarningWrapper: VoidFunctionComponent<Props> = ({ next }) => {
  const [KFAccount, setKFAccount] = useCreateKFAccount();
  const history = useHistory();

  useEffect(() => {
    if (typeof KFAccount.allocationSelectedRisk === "undefined") {
      history.push(getNavLink(OVERVIEW_PAGE_URL));
    }
  }, [history, KFAccount.allocationSelectedRisk]);

  const internalNext = () => {
    setKFAccount({
      allocationForcedRisk: undefined,
      takenRisk: KFAccount.allocationSelectedRisk || 0,
    });
    next();
  };

  const internalNextLysa = () => {
    // Need to store it in state to handle back/forward navigation
    setKFAccount({
      allocationForcedRisk: KFAccount.advicedRisk || 0,
      takenRisk: KFAccount.advicedRisk || 0,
    });
    next();
  };

  return (
    <RiskWarning
      risk={KFAccount.allocationSelectedRisk || 0}
      advisedRisk={KFAccount.advicedRisk || 0}
      confirmSelectedRisk={internalNext}
      takeAdvice={internalNextLysa}
    />
  );
};
