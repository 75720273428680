import cx from "classnames";
import { Bank } from "../../data/dataBanks";
import { GenericBankIcon } from "../bankIcon/banks/genericBankIcon/GenericBankIcon";

import "./BankLogo.scss";

interface Props {
  bank: Bank;
  className?: string;
  size?: number;
}

export function BankLogo({ bank, className, size = 40 }: Props) {
  if (bank.key === "UNKNOWN") {
    return <GenericBankIcon size={size} className={className} />;
  }

  return (
    <div
      className={cx("bank-logo", bank.key, className)}
      style={{ height: `${size}px`, width: `${size}px` }}
    />
  );
}
