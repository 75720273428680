import { Card, Button, Snackbar, SNACKBAR_TYPES, Spinner } from "@lysaab/ui-2";
import {
  Fragment,
  useContext,
  useEffect,
  useState,
  VoidFunctionComponent,
} from "react";
import { FormattedDate } from "react-intl";
import { useHistory } from "react-router";
import { TranslatedText } from "../../../components/TranslatedText";
import { CrsResponse, dataCrs } from "../../../data/dataCrs";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { UPDATE_CRS_URL } from "../../updateCrsStory/UpdateCrsStory";
import { LocalizationContext } from "../../../context/LocalizationContext";
import { getCountryFromCountryCode } from "../../../utils/getCountryFromCountryCode";

import "./CurrentCrsInformation.scss";
import { useIsReadOnly } from "../../../hooks/useIsReadOnly";
import { dataLogin } from "../../../data/dataLogin";
import { LysaCountry } from "@lysaab/shared";

export const CurrentCrsInformation: VoidFunctionComponent = () => {
  const language = useContext(LocalizationContext).state.language;
  const [serverCrs, setServerCrs] = useState<CrsResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();
  const isReadOnly = useIsReadOnly();
  const [username, setUsername] = useState("");
  const localizationContext = useContext(LocalizationContext);

  useEffect(() => {
    setIsLoading(true);
    const crsPromise = dataCrs
      .getLatestCrs()
      .then(setServerCrs)
      .catch(() => setError(true));

    const usernamePromise = dataLogin
      .getUsername()
      .then(setUsername)
      .catch(() => setError(true));

    Promise.all([crsPromise, usernamePromise]).finally(() =>
      setIsLoading(false)
    );
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  // Would be nice to not look at LysaCountry to determine if this should be
  // shown or not
  // I did think about comparing tin and username and only show if they are
  // different. But 1. It felt weird too loop over serverCrs.countries to find
  // a tin. And 2. I still wanted to show the username for old german customers
  // that had their tin as their username
  // Best, I think, would be if we could ask BE if the current customer uses
  // username+password to login
  const shouldShowUsername =
    username && localizationContext.state.country === LysaCountry.GERMANY;

  return (
    <div className="current-crs-information">
      <h2>
        <TranslatedText id="crs.currentCrsInformation.header" />
      </h2>
      <Card>
        {error && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="crs.currentCrsInformation.error" />
          </Snackbar>
        )}
        <dl>
          <div className="list-row">
            <dt>
              <TranslatedText id="crs.currentCrsInformation.firstName" />
            </dt>
            <dd>{serverCrs?.firstName}</dd>
          </div>
          <div className="list-row">
            <dt>
              <TranslatedText id="crs.currentCrsInformation.lastName" />
            </dt>
            <dd>{serverCrs?.lastName}</dd>
          </div>
          <div className="divider"></div>
          {shouldShowUsername && (
            <div className="list-row">
              <dt>
                <TranslatedText id="crs.currentCrsInformation.username" />
              </dt>
              <dd>{username}</dd>
            </div>
          )}
          {serverCrs?.countries.map((country) => {
            return (
              <Fragment key={country.country}>
                <div className="list-row">
                  <dt>
                    <TranslatedText id="crs.currentCrsInformation.taxResidence" />
                  </dt>
                  <dd>
                    {getCountryFromCountryCode({
                      country: country.country,
                      language,
                    })}
                  </dd>
                </div>
                <div className="list-row">
                  <dt>
                    <TranslatedText
                      id="crs.currentCrsInformation.tin"
                      values={{ country: country.country }}
                    />
                  </dt>
                  <dd>{country.tin}</dd>
                </div>
              </Fragment>
            );
          })}
          <div className="list-row">
            <dt>
              <TranslatedText id="crs.currentCrsInformation.countryOfResidence" />
            </dt>
            <dd>
              {serverCrs &&
                getCountryFromCountryCode({
                  country: serverCrs.residentCountry,
                  language,
                })}
            </dd>
          </div>
          <div className="list-row">
            <dt>
              <TranslatedText id="crs.currentCrsInformation.street" />
            </dt>
            <dd>{serverCrs?.street}</dd>
          </div>
          <div className="list-row">
            <dt>
              <TranslatedText id="crs.currentCrsInformation.postalCode" />
            </dt>
            <dd>{serverCrs?.zipCode}</dd>
          </div>
          <div className="list-row">
            <dt>
              <TranslatedText id="crs.currentCrsInformation.city" />
            </dt>
            <dd>{serverCrs?.city}</dd>
          </div>
          <div className="list-row">
            <dt>
              <TranslatedText id="crs.currentCrsInformation.lastUpdated" />
            </dt>
            <dd>
              {serverCrs?.saved && <FormattedDate value={serverCrs.saved} />}
            </dd>
          </div>
        </dl>
        <Button
          onClick={() => history.push(getNavLink(UPDATE_CRS_URL))}
          block
          disabled={isReadOnly}
          label={<TranslatedText id="crs.currentCrsInformation.updateButton" />}
        />
      </Card>
    </div>
  );
};
