import { API, encode } from "@lysaab/ui-2";
import { InvestmentAccountId } from "./dataAccounts";

export interface DepositAccount {
  accountNumber: string;
  accountId: InvestmentAccountId;
  bankCode?: string;
}

export const dataDepositAccount = {
  getDepositAccount(accountId: InvestmentAccountId) {
    return API.get<DepositAccount>(
      encode`/deposit-account?accountId=${accountId}`
    );
  },
};
