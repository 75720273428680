import { FunctionComponent, ReactNode } from "react";
import "./StoryDisclaimer.scss";

interface Props {
  children: ReactNode;
}

export const StoryDisclaimer: FunctionComponent<Props> = ({ children }) => (
  <p className="story-disclaimer">{children}</p>
);
