import {
  LysaFormRef,
  Form,
  Card,
  RequiredValidator,
  Button,
  OtpInput,
  Spinner,
  Snackbar,
  SNACKBAR_TYPES,
  OtpValidator,
} from "@lysaab/ui-2";
import { useRef, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { TranslatedText } from "../../../../components/TranslatedText";
import {
  dataLogin,
  IbanValidationResult,
  InitVerificationResult,
  VerificationResult,
  VerifyOtpResponse,
} from "../../../../data/dataLogin";

interface Props {
  next: () => void;
  goBack: () => void;
  result: InitVerificationResult;
}

const messages = defineMessages({
  label: { id: "withdrawal.add.manual.otp-verification.code.label" },
  required: { id: "withdrawal.add.manual.otp-verification.code.required" },
  otpError: { id: "withdrawal.add.manual.otp-verification.code.otp-error" },
});

export default function OtpVerification({ next, result, goBack }: Props) {
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [totpCode, setTotpCode] = useState("");
  const [serverResponse, setServerResponse] = useState<
    VerificationResult | IbanValidationResult
  >();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <h2>
        <TranslatedText id="withdrawal.add.manual.otp-verification.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            return;
          }
          setIsLoading(true);
          let promise: Promise<VerifyOtpResponse<IbanValidationResult>>;
          if (result === InitVerificationResult.EMAIL_CODE) {
            promise = dataLogin.verifyEmailCode<IbanValidationResult>(totpCode);
          } else {
            promise = dataLogin.verifyTotpCode<IbanValidationResult>(totpCode);
          }
          promise.then((response) => {
            if (response.result === VerificationResult.SUCCESS) {
              next();
              return;
            }
            if (response.explanation) {
              setServerResponse(response.explanation);
            } else {
              setServerResponse(response.result);
            }
            setIsLoading(false);
          });
        }}
      >
        <Card>
          <OtpInput
            label={intl.formatMessage(messages.label)}
            value={totpCode}
            onChange={(value) => setTotpCode(value)}
            validators={[
              new RequiredValidator(intl.formatMessage(messages.required)),
              new OtpValidator(intl.formatMessage(messages.otpError)),
            ]}
          />
        </Card>
        {serverResponse === VerificationResult.ERROR && (
          <Snackbar type={SNACKBAR_TYPES.ERROR}>
            <TranslatedText id="withdrawal.add.manual.otp.failed.error" />
          </Snackbar>
        )}
        {serverResponse === VerificationResult.FAILED && (
          <Snackbar type={SNACKBAR_TYPES.ERROR}>
            <TranslatedText id="withdrawal.add.manual.otp.failed.failed" />
          </Snackbar>
        )}
        {serverResponse === VerificationResult.RATE_LIMIT && (
          <Snackbar type={SNACKBAR_TYPES.ERROR}>
            <TranslatedText id="withdrawal.add.manual.otp.failed.rate-limit" />
          </Snackbar>
        )}
        {serverResponse === IbanValidationResult.INCORRECT_COUNTRY && (
          <Snackbar type={SNACKBAR_TYPES.ERROR}>
            <TranslatedText id="withdrawal.add.manual.otp.failed.incorrect-country" />
          </Snackbar>
        )}
        {serverResponse === IbanValidationResult.INCORRECT_LENGTH && (
          <Snackbar type={SNACKBAR_TYPES.ERROR}>
            <TranslatedText id="withdrawal.add.manual.otp.failed.incorrect-length" />
          </Snackbar>
        )}
        {serverResponse === IbanValidationResult.INVALID && (
          <Snackbar type={SNACKBAR_TYPES.ERROR}>
            <TranslatedText id="withdrawal.add.manual.otp.failed.invalid" />
          </Snackbar>
        )}
        {serverResponse === IbanValidationResult.UNSUPPORTED && (
          <Snackbar type={SNACKBAR_TYPES.ERROR}>
            <TranslatedText id="withdrawal.add.manual.otp.failed.unsupported" />
          </Snackbar>
        )}
        <Button
          type="submit"
          block
          label={<TranslatedText id="withdrawal.add.manual.otp.next" />}
        />

        {serverResponse && showGoBack(serverResponse) && (
          <Button
            variant="secondary"
            onClick={goBack}
            block
            label={<TranslatedText id="withdrawal.add.manual.otp.go-back" />}
          />
        )}
      </Form>
    </div>
  );
}

function showGoBack(
  response: VerificationResult | IbanValidationResult
): boolean {
  return (
    response === VerificationResult.ERROR ||
    response === VerificationResult.FAILED ||
    response === VerificationResult.RATE_LIMIT ||
    response === IbanValidationResult.INCORRECT_COUNTRY ||
    response === IbanValidationResult.INCORRECT_LENGTH ||
    response === IbanValidationResult.INVALID ||
    response === IbanValidationResult.UNSUPPORTED
  );
}
