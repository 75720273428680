import { useEffect, useRef, VoidFunctionComponent } from "react";

import { useHistory } from "react-router";
import { FormattedMessage } from "react-intl";
import { useTransfer } from "../TransferContext";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { TRANSFER_PENSIONS_URL } from "../TransferPensionsStory";
import { Button, Form, LysaFormRef, Typography } from "@lysaab/ui-2";
import { PAIQuestion } from "../../../../../pageComponents/sustainability/PAIQuestion";
import { isSustainabilityImportantSpecific } from "../../../../../data/dataInvestments";
import { SFDRQuestion } from "../../../../../pageComponents/sustainability/SFDRQuestion";
import { TaxonomyQuestion } from "../../../../../pageComponents/sustainability/TaxonomyQuestion";

interface Props {
  next(): void;
}

export const SustainabilityImportantQuestions: VoidFunctionComponent<Props> = ({
  next,
}) => {
  const formRef = useRef<LysaFormRef>();
  const [transfer, setTransfer] = useTransfer();
  const history = useHistory();

  /**
   * Safe guard in case we loose context. On refresh etc. Send user back to start of story.
   */
  useEffect(() => {
    if (typeof transfer?.moves[0]?.institute === "undefined") {
      history.replace(getNavLink(TRANSFER_PENSIONS_URL));
    }
  }, [history, transfer?.moves]);

  if (!isSustainabilityImportantSpecific(transfer)) {
    return null;
  }

  /**
   * Setting sfdr, taxonomy and pai to context cause an issue in case
   * the user move back and change the sustainability question to "NOT_IMPORTANT".
   * If so, sfdr, taxonomy and pai should not exist. But we never reset them.
   * This cause backend to break on /init if we pass them along.
   * Quick fix is to filter them out before /init. Real fix is to keep context in sync with possible scenarios.
   */

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={() => {
        if (formRef.current?.isValid) {
          next();
        }
      }}
    >
      <Typography type="h2">
        <FormattedMessage id="sweden.transfer-pension.sustainability-important-questions.title" />
      </Typography>
      <SFDRQuestion
        sfdr={transfer.sfdr}
        setSfdr={(sfdr) => setTransfer({ sfdr: sfdr.value })}
      />
      <TaxonomyQuestion
        taxonomy={transfer.taxonomy}
        setTaxonomy={(taxonomy) => setTransfer({ taxonomy: taxonomy.value })}
      />
      <PAIQuestion
        pai={transfer.pai}
        setPai={(pai) => setTransfer({ pai: pai.value })}
      />
      <Button
        block
        type="submit"
        label={
          <FormattedMessage id="sweden.transfer-pension.sustainability-important-questions.button" />
        }
      />
    </Form>
  );
};
