import { encode } from "@lysaab/ui-2";
import { API } from "@lysaab/ui-2/network/API";
import { SavingsAccountId } from "./dataAccounts";

export interface ExternalAccruedInterestResponse {
  accruedInterest: number;
  accountId: SavingsAccountId;
  fee: number;
}

export const dataSavingsAccountInterest = {
  getMultipleAccruedInterest: (accountIds: SavingsAccountId[]) => {
    const params = new URLSearchParams();

    accountIds.forEach((accountId) => {
      params.append("accountIds", encodeURIComponent(accountId));
    });
    return API.get<ExternalAccruedInterestResponse[]>(
      `/savings-account-interest/interest/accrued?${params.toString()}`
    );
  },
  getAccruedInterest: (accountId: SavingsAccountId) => {
    return API.get<ExternalAccruedInterestResponse>(
      encode`/savings-account-interest/interest/accrued/${accountId}`
    );
  },
};
