import { LysaCountry } from "@lysaab/shared";
import { NewIcon, Spinner } from "@lysaab/ui-2";
import { useContext, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { TranslatedText } from "../../../../components/TranslatedText";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import { dataInvite } from "../../../../data/dataInvite";
import "./InviteInformation.scss";
import { InviteQrCode } from "../../../../pages/invite/inviteQrCode/InviteQrCode";
import { InviteButtons } from "../../../../pages/invite/inviteButtons/InviteButtons";

export const messages = defineMessages({
  disclaimer: {
    id: "denmark.invite.inviteInformation.disclaimer",
  },
});

const MAXIMUM_DISCOUNT_AMOUNT = {
  [LysaCountry.DENMARK]: 200000,
  [LysaCountry.FINLAND]: 20000,
  [LysaCountry.GERMANY]: 20000,
  [LysaCountry.SWEDEN]: 200000,
  [LysaCountry.SPAIN]: 20000,
};

const DISCOUNT_PERCENTAGE = 0.2;

export const InviteInformation = () => {
  const localizationContext = useContext(LocalizationContext);
  const intl = useIntl();
  const [inviteId, setInviteId] = useState("");
  const [rebateMonths, setRebateMonths] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    dataInvite
      .getId()
      .then(({ inviteId }) => {
        setInviteId(inviteId);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    dataInvite.getRebateMonths().then(setRebateMonths);
  }, []);

  if (isLoading) return <Spinner />;

  return (
    <div className="invite-information">
      <div className="invite-information-code-header">
        <NewIcon.InviteFriend size={80} />
        <InviteQrCode inviteId={inviteId} error={isError} />
        <NewIcon.Recieve size={80} />
      </div>
      <TranslatedText
        id="denmark.invite.inviteInformation.ingress"
        values={{
          discountPercentage: intl.formatNumber(DISCOUNT_PERCENTAGE, {
            style: "percent",
          }),
          paragraph: (parts) => <p>{parts}</p>,
          rebateMonths,
        }}
      />
      <ul>
        <li>
          <TranslatedText id="denmark.invite.inviteInformation.ingressListFirst" />
        </li>
        <li>
          <TranslatedText id="denmark.invite.inviteInformation.ingressListSecond" />
        </li>
        <li>
          <TranslatedText id="denmark.invite.inviteInformation.ingressListThird" />
        </li>
      </ul>

      <InviteButtons inviteId={inviteId} />

      <h3 className="invite-information-header">
        <TranslatedText id="denmark.invite.inviteInformation.philosophy.header" />
      </h3>

      <TranslatedText
        id="denmark.invite.inviteInformation.philosophy.text"
        values={{
          paragraph: (parts) => <p>{parts}</p>,
        }}
      />
      <p className="invite-information-disclaimer">
        {intl.formatMessage(messages.disclaimer, {
          minAmount: intl.formatNumber(
            MAXIMUM_DISCOUNT_AMOUNT[
              localizationContext.state.country || LysaCountry.SWEDEN
            ],
            {
              style: "currency",
              currency: localizationContext.state.currency,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }
          ),
          discountPercentage: intl.formatNumber(DISCOUNT_PERCENTAGE, {
            style: "percent",
          }),
          rebateMonths,
        })}
      </p>
    </div>
  );
};
