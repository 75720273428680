import * as React from "react";
import cx from "classnames";
import { InternalTransfer } from "../../data/dataTransactions";
import { dataPerformance } from "../../data/dataPerformance";
import { Amount } from "../../components/amount/Amount";

import { InvestmentAccount } from "../../data/dataAccounts";

import "./InternalTransferEntry.scss";
import { Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../components/TranslatedText";

interface Props {
  account: InvestmentAccount | undefined;
  internalTransfer: InternalTransfer;
}

export const InternalTransferEntry: React.FC<Props> = ({
  account,
  internalTransfer,
}) => {
  const isDeposit = internalTransfer.toAccountId === account?.accountId;
  const classes = cx(
    "internal-transfer-entry",
    `${
      isDeposit
        ? "internal-transfer-entry-deposit"
        : "internal-transfer-entry-withdrawal"
    }`
  );

  return (
    <div className={classes}>
      <div>
        <Typography type="body" variant="secondary" component="span">
          {dataPerformance.toStringDate(
            dataPerformance.toJsDate(internalTransfer.booked)
          )}
        </Typography>

        <div className="internal-transfer-entry-type">
          <span className="internal-transfer-entry-indicator" />{" "}
          {isDeposit ? (
            <Typography type="body" component="span">
              <TranslatedText id="accountPage.transactions.internal-deposit" />
            </Typography>
          ) : (
            <Typography type="body" component="span">
              <TranslatedText id="accountPage.transactions.internal-withdrawal" />
            </Typography>
          )}
        </div>
      </div>

      <div className="text-right">
        <Typography type="body" variant="secondary" component="span">
          <TranslatedText id="accountPage.transactions.amount" />
        </Typography>

        <div className="internal-transfer-entry-type">
          {isDeposit ? (
            <Amount amount={internalTransfer.amount} decimals={2} />
          ) : (
            <Amount amount={internalTransfer.amount * -1} decimals={2} />
          )}
        </div>
      </div>
    </div>
  );
};
