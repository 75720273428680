import { NewIcon, Typography } from "@lysaab/ui-2";
import { ReactElement, VoidFunctionComponent } from "react";
import * as React from "react";
import cx from "classnames";

import "./ListItem.scss";

interface TrailingIconProps {
  icon: keyof typeof NewIcon;
}
interface TrailingStringProps {
  text: string;
}

export const ListItem: VoidFunctionComponent<{
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  primaryText: ReactElement | string;
  trailing?: TrailingIconProps | TrailingStringProps;
  secondaryText?: ReactElement | string;
  className?: string;
  selected?: boolean;
}> = ({
  onClick,
  primaryText,
  secondaryText,
  trailing,
  className,
  selected,
}) => {
  return (
    <li
      className={cx("link-item", className, {
        "link-item--selected": selected,
      })}
    >
      <button type="button" className="link-item__button" onClick={onClick}>
        <span className="link-item__col">
          <Typography type="label-large">{primaryText}</Typography>
          <Typography type="body" component="span" variant="secondary">
            {secondaryText}
          </Typography>
        </span>
        {typeof trailing !== "undefined" && (
          <TrailingComponent trailing={trailing} />
        )}
      </button>
    </li>
  );
};

const TrailingComponent: React.VoidFunctionComponent<{
  trailing: TrailingIconProps | TrailingStringProps;
}> = ({ trailing }) => {
  if ("icon" in trailing) {
    const IconComponent = NewIcon[trailing.icon as keyof typeof NewIcon];
    return (
      <IconComponent className="link-item__trailing-icon link-item__trailing" />
    );
  } else if ("text" in trailing) {
    return <span className="link-item__trailing">{trailing.text}</span>;
  }
  return null;
};
