import { API } from "@lysaab/ui-2/network/API";
import { cache } from "@lysaab/ui-2/network/Cache";
import { encode } from "@lysaab/ui-2/network/Encode";
import { CompoundAccountId } from "./dataAccounts";

export interface MonthlyInternalTransferRequestBody {
  fromAccountId: CompoundAccountId;
  toAccountId: CompoundAccountId;
  transferDay: number;
  amount: number;
}

export interface MonthlyInternalTransferResponse {
  monthlyInternalTransfers: MonthlyInternalTransfer[];
}

export interface MonthlyInternalTransfer {
  id: string;
  fromAccountId: CompoundAccountId;
  toAccountId: CompoundAccountId;
  transferDay: number;
  amount: number;
  nextTransferDate: string;
}

export const dataPeriodicInternalTransfer = {
  addMonthlyInternalTransfer: (request: MonthlyInternalTransferRequestBody) => {
    return API.post(
      "/periodic-internal-transfer/monthly-internal-transfer",
      request
    ).then(() => {
      cache.delete("/periodic-internal-transfer/monthly-internal-transfer");
    });
  },
  getMonthlyInternalTransfers: (accountId: CompoundAccountId) => {
    return API.get<MonthlyInternalTransferResponse>(
      encode`/periodic-internal-transfer/monthly-internal-transfer?accountId=${accountId}`
    ).then((response) => {
      return response.monthlyInternalTransfers;
    });
  },
  updateMonthlyInternalTransfer: (
    id: string,
    request: MonthlyInternalTransferRequestBody
  ) => {
    return API.put(
      encode`/periodic-internal-transfer/monthly-internal-transfer/${id}`,
      request
    ).then(() => {
      cache.delete("/periodic-internal-transfer/monthly-internal-transfer");
    });
  },
  deleteMonthlyInternalTransfer: (id: any) => {
    return API.delete(
      encode`/periodic-internal-transfer/monthly-internal-transfer/${id}`
    ).then(() => {
      cache.delete("/periodic-internal-transfer/monthly-internal-transfer");
    });
  },
};
