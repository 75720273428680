import { RefObject, VoidFunctionComponent } from "react";
import { Button, RequiredValidator, HiddenInput } from "@lysaab/ui-2";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { CardPaneListItem } from "../../../../components/cardPane/CardPaneListItem";
import { SavingsHorizonLength } from "../../../../data/dataInvestments";
import { useAccountName } from "../../../../hooks/useAccountName";
import { UpdateInvestmentAccountComposition } from "../AccountAdviceCard";
import { AccountUpdateAction } from "../../../../pages/reviewAccount/ReviewAccountContext";

const messages = defineMessages({
  formErrorRequired: {
    id: "summary.accountCardButtons.ForceSelection.error.required",
  },
});

type Props = {
  containerRef: RefObject<HTMLDivElement>;
  account: UpdateInvestmentAccountComposition;
  updateAccount: (accountAction: AccountUpdateAction) => void;
};

export const ForceSelection: VoidFunctionComponent<Props> = ({
  containerRef,
  account,
  updateAccount,
}) => {
  const intl = useIntl();
  const accountName = useAccountName(account.accountId, true);

  return (
    <CardPaneListItem>
      {account.newAdvice.savingsHorizon === SavingsHorizonLength.SHORT &&
        account.risk > 50 && (
          <p>
            <FormattedMessage id="summary.accountCard.lysa.allocation.warning" />
          </p>
        )}

      <Button
        block
        onClick={() => updateAccount(AccountUpdateAction.ADVICE)}
        label={
          <FormattedMessage id="summary.accountCard.button.ForceSelection.short.horizon.lysa.advice.updated" />
        }
      />

      <div className="button-middle-text">
        <FormattedMessage id="summary.accountCard.short.horizon.or" />
        <span> *</span>
      </div>

      <Button
        block
        onClick={() => updateAccount(AccountUpdateAction.ADVICE)}
        label={
          <FormattedMessage id="summary.accountCard.button.ForceSelection.short.horizon.update" />
        }
      />

      <HiddenInput
        label={accountName}
        scrollToRef={containerRef}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.formErrorRequired)),
        ]}
      />
    </CardPaneListItem>
  );
};
