import { LysaCountry } from "@lysaab/shared";
import { Button, Snackbar, SNACKBAR_TYPES, useFlash } from "@lysaab/ui-2";
import { useContext } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { QRCodeCanvas } from "qrcode.react";
import { LocalizationContext } from "../../../context/LocalizationContext";
import {
  ShareAccountInvitation,
  ShareAccountInvitationId,
  ShareAccountSentInvitation,
} from "../../../data/dataAttorney";
import { GridCol } from "../../grid/gridCol/GridCol";
import { GridRow } from "../../grid/gridRow/GridRow";
import { ListItemAccountName } from "../listItems/ListItemAccountName";
import { ListItemCancel } from "../listItems/ListItemCancel";
import { ListItemExpiry } from "../listItems/ListItemExpiry";

const invitationUrlMap: Record<
  LysaCountry,
  (baseUrl: string, id: ShareAccountInvitationId) => string
> = {
  [LysaCountry.DENMARK]: (baseUrl, id) =>
    `${baseUrl}/dk/share-account-invitation/${id}`,
  [LysaCountry.FINLAND]: (baseUrl, id) =>
    `${baseUrl}/fi/share-account-invitation/${id}`,
  [LysaCountry.GERMANY]: (baseUrl, id) =>
    `${baseUrl}/de/share-account-invitation/${id}`,
  [LysaCountry.SWEDEN]: (baseUrl, id) =>
    `${baseUrl}/se/share-account-invitation/${id}`,
  [LysaCountry.SPAIN]: (baseUrl, id) =>
    `${baseUrl}/es/share-account-invitation/${id}`,
};

const messages = defineMessages({
  shareTitle: {
    id: "shareAccountStory.inviteCard.shareTitle",
  },
  shareText: {
    id: "shareAccountStory.inviteCard.shareText",
  },
  copySuccessText: {
    id: "shareAccountStory.inviteCard.copySuccessText",
  },
  shareFailText: {
    id: "shareAccountStory.inviteCard.shareFailText",
  },
});

interface Props {
  invitation: ShareAccountInvitation | ShareAccountSentInvitation;
  onCancel: () => void;
}

export const CreatedState: React.VFC<Props> = ({ invitation, onCancel }) => {
  return (
    <div>
      <ShareSection invitation={invitation} />
      <hr />
      <div>
        {invitation.accountName && (
          <ListItemAccountName accountName={invitation.accountName} />
        )}
        {invitation.expiresAt && (
          <ListItemExpiry expiryDate={invitation.expiresAt} />
        )}
        <ListItemCancel onCancel={onCancel} />
      </div>
      <div className="info-snackbar">
        <Snackbar type={SNACKBAR_TYPES.INFO}>
          <FormattedMessage id="shareAccountInviteCard.invitationFlowDescription" />
        </Snackbar>
      </div>
    </div>
  );
};

interface ShareSectionProps {
  invitation: ShareAccountInvitation | ShareAccountSentInvitation;
}

export const ShareSection: React.VFC<ShareSectionProps> = ({ invitation }) => {
  const localizationContext = useContext(LocalizationContext);
  const country = localizationContext.state.country;
  const intl = useIntl();
  const pushFlash = useFlash();

  if (!country) {
    return null;
  }

  const invitationUrl = invitationUrlMap[country](
    window.location.origin,
    invitation.invitationId
  );

  const share = () => {
    try {
      navigator
        .share({
          title: intl.formatMessage(messages.shareTitle),
          text: intl.formatMessage(messages.shareText),
          url: invitationUrl,
        })
        .catch((error) => {
          /* AbortError is caught even if user aborted or decided to copy through native share */
          if (error.name === "AbortError") {
            return;
          }
          pushFlash({
            text: intl.formatMessage(messages.shareFailText),
            type: SNACKBAR_TYPES.INFO,
          });
        });
    } catch (err) {
      pushFlash({
        text: intl.formatMessage(messages.shareFailText),
        type: SNACKBAR_TYPES.INFO,
      });
    }
  };

  const copy = () => {
    navigator.clipboard.writeText(invitationUrl).then(() => {
      pushFlash({
        text: intl.formatMessage(messages.copySuccessText),
        type: SNACKBAR_TYPES.INFO,
      });
    });
  };

  return (
    <div>
      <div className="qr-code">
        <QRCodeCanvas includeMargin={true} value={invitationUrl} size={200} />
      </div>
      <GridRow flexEvenly>
        {/* @ts-ignore: canShare is optional, but marked as non-optional in the typings */}
        {navigator.canShare && (
          <GridCol xsmall={6}>
            <Button
              size="medium"
              block
              onClick={share}
              label={
                <FormattedMessage id="shareAccountInviteCard.shareButton" />
              }
            />
          </GridCol>
        )}
        <GridCol xsmall={6}>
          <Button
            size="medium"
            block
            onClick={copy}
            data-link={invitationUrl}
            label={<FormattedMessage id="shareAccountInviteCard.copyButton" />}
          />
        </GridCol>
      </GridRow>
    </div>
  );
};
