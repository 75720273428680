import { cache, Spinner } from "@lysaab/ui-2";
import { useState, useEffect, useCallback } from "react";
import { useRouteMatch } from "react-router";
import {
  CompoundAccountId,
  AllAccountResponse,
  dataAccounts,
} from "../../data/dataAccounts";
import { BASE_URL_ACCOUNT_SHARE, dataAttorney } from "../../data/dataAttorney";
import { dataLogin } from "../../data/dataLogin";
import { getNavLink } from "../../hooks/useCountryUrls";
import { SHARED_ACCOUNT_PAGE_URL } from "./sharedAccountPage/SharedAccountPage";

interface Match {
  accountId: CompoundAccountId;
}

function useRefreshAccounts(accountId: CompoundAccountId) {
  const [accounts, setAccounts] = useState<AllAccountResponse>();
  const [isError, setIsError] = useState(false);

  const getAccounts = useCallback(
    (id: CompoundAccountId) =>
      new Promise<AllAccountResponse>(async (resolve) => {
        let accounts = await dataAccounts.getAllAccounts();
        const accountFound = [
          ...accounts.sharedInvestmentAccounts,
          ...accounts.sharedSavingsAccounts,
        ].some((account) => account.accountId === id);
        if (!accountFound) {
          cache.delete(BASE_URL_ACCOUNT_SHARE + "/received");
          const shares = await dataAttorney.getAccountSharesWithMe();
          const isActiveShare = shares.some((share) => share.accountId === id);
          if (isActiveShare) {
            await dataLogin.refresh();
            dataAccounts.clearGetAccounts();
            resolve(await dataAccounts.getAllAccounts());
          }
        }
        resolve(accounts);
      }),
    []
  );

  useEffect(() => {
    getAccounts(accountId)
      .then(setAccounts)
      .catch(() => setIsError(true));
  }, [accountId, getAccounts]);

  return { accounts, isError };
}

export const CheckForMissingAccountWrapper: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const accountId = useRouteMatch<Match>({
    path: getNavLink(SHARED_ACCOUNT_PAGE_URL),
  })?.params.accountId!;

  const { accounts } = useRefreshAccounts(accountId);

  if (typeof accounts === "undefined") {
    return <Spinner />;
  }

  return <>{children}</>;
};
