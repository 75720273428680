import { Button, Typography } from "@lysaab/ui-2";
import { VoidFunctionComponent } from "react";
import { useIntl } from "react-intl";
import { generatePath, Link } from "react-router-dom";
import { CloseAccountReasonsList } from "../../../../../components/closeAccountReasonsList/CloseAccountReasonsList";
import {
  CompoundAccount,
  isSavingsAccount,
} from "../../../../../data/dataAccounts";
import {
  CloseableAccount,
  NotCloseableAccount,
} from "../../../../../hooks/useInvestmentAccountCloseable";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { BASE_ROUTES as CLOSE_INVESTMENT_ACCOUNT_ROUTES } from "../../../../closeAccountStory/CloseAccountStory";
import { BASE_ROUTES as CLOSE_SAVINGS_ACCOUNT_ROUTES } from "../../../../../countries/sweden/pages/closeSavingsAccountStory/CloseSavingsAccountStory";

import "./CloseAccountRow.scss";

interface Props {
  account: CompoundAccount;
  disabledState: CloseableAccount | NotCloseableAccount;
}

export const CloseAccountRow: VoidFunctionComponent<Props> = ({
  account,
  disabledState,
}) => {
  const intl = useIntl();

  if (!disabledState.isCloseable) {
    return (
      <>
        <div className="close-account-row">
          <Typography component="span" className="close-account-row__name">
            {account.name}
          </Typography>
          <Button
            variant="secondary"
            size="small"
            disabled
            inline
            label={intl.formatMessage({
              id: "endInvestment.closeAccount.close",
            })}
          />
        </div>
        {disabledState.reasons.length > 0 && (
          <CloseAccountReasonsList reasons={disabledState.reasons} />
        )}
      </>
    );
  }

  return (
    <div className="close-account-row">
      <Typography component="span" className="close-account-row__name">
        {account.name}
      </Typography>
      <Button
        component={Link}
        to={
          isSavingsAccount(account)
            ? getNavLink(
                generatePath(
                  CLOSE_SAVINGS_ACCOUNT_ROUTES.CLOSE_SAVINGS_ACCOUNT_CONFIRMATION,
                  {
                    accountId: account.accountId,
                  }
                )
              )
            : getNavLink(
                generatePath(
                  CLOSE_INVESTMENT_ACCOUNT_ROUTES.CLOSE_ACCOUNT_CONFIRMATION,
                  {
                    accountId: account.accountId,
                  }
                )
              )
        }
        inline
        variant="secondary"
        size="small"
        label={intl.formatMessage({
          id: "endInvestment.closeAccount.close",
        })}
      />
    </div>
  );
};
