import { FunctionComponent } from "react";
import { NewIcon, SelectableCard, Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../../components/TranslatedText";

import "./NotMovablePensionsCard.scss";

interface Props {
  count: number;
  onClick: () => void;
}

export const NotMovablePensionsCard: FunctionComponent<Props> = ({
  count,
  onClick,
}) => {
  /**
   * Only show card if not movable pensions exists
   */
  if (count === 0) {
    return null;
  }
  return (
    <SelectableCard
      className="transfer-pension-not-movable-pensions-card"
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        onClick();
      }}
    >
      <header className="header">
        <section className="start">
          <div
            className="counter-circle"
            role="status"
            aria-label={`Counter: ${count}`}
          >
            <span className="counter-value">{count}</span>
          </div>
          <Typography type="label" className="label">
            <TranslatedText
              id={"sweden.transfer-pension.not-movable-pension-card.header"}
            />
          </Typography>
        </section>
        <section className="end">
          <NewIcon.ChevronRight />
        </section>
      </header>
    </SelectableCard>
  );
};
