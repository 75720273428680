import { useContext, useEffect } from "react";
import { LayoutContext } from "../context/LayoutContext";

export function HideNav() {
  const layoutContext = useContext(LayoutContext);
  const { setState } = layoutContext;

  useEffect(() => {
    setState({
      hideNav: true,
    });
    return () => {
      setState({
        hideNav: false,
      });
    };
  }, [setState]);

  return null;
}
