import {
  BaseValidator,
  ValidationResponse,
  VALIDATION_STATE,
} from "@lysaab/ui-2";

const USERNAME_LENGTH = 13;

export class GermanUsernameValidator extends BaseValidator {
  protected name = "GermanUsernameValidator";

  getId() {
    return this.getBaseId();
  }

  validate(value: string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (
        typeof value === "undefined" ||
        value.toString().length === 0 ||
        value.toString().length === USERNAME_LENGTH
      ) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
          hideError: true,
        });
      }
    });
  }
}
