import { defineMessages } from "react-intl";

export const messages = defineMessages({
  residenceCountryLabel: {
    id: "crsFormFields.residenceCountry.header",
  },
  residenceCountryPlaceholder: {
    id: "crsFormFields.residenceCountry.placeholder",
  },
  residenceCountryRequired: {
    id: "crsFormFields.residenceCountry.required",
  },
  firstNameLabel: {
    id: "crsFormFields.firstName.label",
  },
  firstNameRequired: {
    id: "crsFormFields.firstName.required",
  },
  firstNameLength: {
    id: "crsFormFields.firstName.length",
  },
  lastNameLabel: {
    id: "crsFormFields.lastName.label",
  },
  lastNameRequired: {
    id: "crsFormFields.lastName.required",
  },
  lastNameLength: {
    id: "crsFormFields.lastName.length",
  },
  streetLabel: {
    id: "crsFormFields.street.label",
  },
  streetRequired: {
    id: "crsFormFields.street.required",
  },
  streetLength: {
    id: "crsFormFields.street.length",
  },
  postCodeLabel: {
    id: "crsFormFields.postalCode.label",
  },
  postCodeRequired: {
    id: "crsFormFields.postalCode.required",
  },
  cityLabel: {
    id: "crsFormFields.city.label",
  },
  cityRequired: {
    id: "crsFormFields.city.required",
  },
  cityLength: {
    id: "crsFormFields.city.length",
  },
});
