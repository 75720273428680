import { useContext, VoidFunctionComponent } from "react";
import { FeesContext } from "../../context/FeesContext";

interface Props {
  text: string;
}

export const FeeHeaderCell: VoidFunctionComponent<Props> = ({ text }) => {
  const feeContext = useContext(FeesContext);

  if (feeContext.state.isPercent.value) {
    return null;
  }
  return <th>{text}</th>;
};
