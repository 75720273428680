import { PepContext, PepState } from "@lysaab/lysa-pep";
import { useContext, FunctionComponent, ReactNode, useEffect } from "react";
import * as React from "react";
import { RouteProps, useHistory } from "react-router-dom";
import { Route } from "../../components/route/Route";
import { getNavLink } from "../../hooks/useCountryUrls";
import {
  EligibilityState,
  useEligibilityContext,
} from "../../context/EligibilityContext";
import { useMultiPepContext, MultiPepState } from "./contexts/MultiPepContext";
import {
  useYearlyReviewContext,
  YearlyReviewState,
} from "./contexts/YearlyReviewContext";
import { BASE_ROUTES } from "./YearlyReviewStory";
import {
  AccountsState,
  useAccountsContext,
} from "../../context/AccountsContext";
import { useReviewAccountContext } from "../reviewAccount/ReviewAccountContext";
import { ReviewAccountReturnState } from "../reviewAccount/ReviewAccountStory";

interface Props {
  children: ReactNode;
}

export type YearlyReviewReturnState = ReviewAccountReturnState<{
  pepState: PepState;
  multiPepState: MultiPepState;
  eligibilityState: EligibilityState;
  yearlyReviewState: YearlyReviewState;
  accountsState: AccountsState;
}>;

const AccessGuard: FunctionComponent<Props> = ({ children }) => {
  const history = useHistory<YearlyReviewReturnState | undefined>();
  const pepContext = useContext(PepContext);
  const [, setMultiPepState] = useMultiPepContext();
  const [, setEligibilityState] = useEligibilityContext();
  const [yearlyReviewState, setYearlyReviewState] = useYearlyReviewContext();
  const [, setAccountsState] = useAccountsContext();
  const [, setReviewAccountState] = useReviewAccountContext();
  const returnState = history.location.state;

  /**
   * Check if history state has content and if email is missing then replace the
   * current contexts with history state context data if it exits. If data is missing the
   * jump to first page
   */
  useEffect(() => {
    if (returnState && !yearlyReviewState.email) {
      if (returnState.pepState) {
        pepContext.setState(returnState.pepState);
      }
      if (returnState.multiPepState) {
        setMultiPepState(returnState.multiPepState);
      }
      if (returnState.eligibilityState) {
        setEligibilityState(returnState.eligibilityState);
      }
      if (returnState.yearlyReviewState) {
        setYearlyReviewState(returnState.yearlyReviewState);
      }
      if (returnState.accountsState) {
        setAccountsState(returnState.accountsState);
      }
      if (returnState.reviewAccountState) {
        setReviewAccountState(returnState.reviewAccountState);
      }
    } else if (!yearlyReviewState.email) {
      history.replace(getNavLink(BASE_ROUTES.EMAIL));
    }
  }, [
    history,
    pepContext,
    returnState,
    setAccountsState,
    setEligibilityState,
    setMultiPepState,
    setReviewAccountState,
    setYearlyReviewState,
    yearlyReviewState.email,
  ]);

  return <React.Fragment>{children}</React.Fragment>;
};

export const AccessGuardRoute: FunctionComponent<
  React.PropsWithChildren<RouteProps>
> = ({ children, ...props }) => (
  <Route {...props}>
    <AccessGuard>{children}</AccessGuard>
  </Route>
);
