import { useCallback, useContext, useEffect, useState } from "react";
import * as React from "react";
import { Card, CardList } from "@lysaab/ui-2";
import { Page, PageHeader } from "../Page";
import { ActiveMessages } from "./ActiveMessages/ActiveMessages";
import { ClosedMessages } from "./ClosedMessages/ClosedMessages";
import { dataMessages, MessagesStatus } from "../../data/dataMessages";
import {
  MessagesContext,
  MessagesContextProvider,
} from "../../context/MessagesContext";

import "./MessagesPage.scss";
import { WriteMessage } from "./WriteMessage/WriteMessage";
import { FormattedMessage } from "react-intl";

export const MESSAGES_PAGE_URL = "/messages";

const InternalMessagesPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const messagesContext = useContext(MessagesContext);

  const setMessagesState = messagesContext.setState;

  const reloadMessages = useCallback(() => {
    setLoading(true);

    dataMessages
      .getMessages()
      .then((data) => {
        setMessagesState({ messages: data });
      })
      .catch(() => {
        setMessagesState({ messages: [] });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setMessagesState]);

  useEffect(() => {
    reloadMessages();
  }, [reloadMessages]);

  const nbrOfActiveThreads = messagesContext.state.messages.filter(
    (thread) => thread.status !== MessagesStatus.CLOSED
  ).length;
  const nbrOfClosedThreads =
    messagesContext.state.messages.length - nbrOfActiveThreads;

  return (
    <Page className="messages-page">
      <PageHeader>
        <h1>
          <FormattedMessage id="messages.page.header" />
        </h1>
      </PageHeader>
      <div className="messages-page-grid">
        <div>
          <h2>
            <FormattedMessage id="messages.page.createMessage.header" />
          </h2>
          <Card>
            <WriteMessage />
          </Card>
        </div>
        <div>
          <h2>
            <FormattedMessage id="messages.page.activeMessages.header" />
          </h2>

          {loading || nbrOfActiveThreads > 0 ? (
            <CardList>
              <ActiveMessages
                isLoading={loading}
                reloadMessages={reloadMessages}
                setIsLoading={() => {
                  setLoading(true);
                }}
              />
            </CardList>
          ) : (
            <div>
              <FormattedMessage id="messages.page.activeMessages.noMessages" />
            </div>
          )}
          <h2>
            <FormattedMessage id="messages.page.closedMessages.header" />
          </h2>
          {loading || nbrOfClosedThreads > 0 ? (
            <CardList>
              <ClosedMessages isLoading={loading} />
            </CardList>
          ) : (
            <div>
              <FormattedMessage id="messages.page.closedMessages.noMessages" />
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};

export const MessagesPage = () => (
  <MessagesContextProvider>
    <InternalMessagesPage />
  </MessagesContextProvider>
);
