import { defineMessages } from "react-intl";
import { messages as baseMessages } from "../../../../components/crsFormFields/CrsFormFieldsMessages";

const additionalMessages = defineMessages({
  birthCountryLabel: {
    id: "denmark.crsFormFields.birthCountry.label",
  },
  birthCountryRequired: {
    id: "denmark.crsFormFields.birthCountry.required",
  },
  birthCountryPlaceholder: {
    id: "denmark.crsFormFields.birthCountry.placeholder",
  },
  birthCityLabel: {
    id: "denmark.crsFormFields.birthCity.label",
  },
  birthCityRequired: {
    id: "denmark.crsFormFields.birthCity.required",
  },
  birthCityLength: {
    id: "denmark.crsFormFields.birthCity.length",
  },
});

export const messages = { ...baseMessages, ...additionalMessages };
