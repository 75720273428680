import { LysaCountry } from "@lysaab/shared";
import { countries, Language, WorldCountry } from "@lysaab/ui-2";

interface Parameters {
  country?: WorldCountry | LysaCountry;
  language: Language;
}

export const getCountryFromCountryCode = ({
  country,
  language,
}: Parameters) => {
  return countries[language].find((ctry) => {
    return country && ctry.value === country.toString();
  })?.label;
};
