import { defineMessages } from "react-intl";

export const messages = defineMessages({
  transfersButton: {
    id: "menu.button.transfers",
  },
  overviewButton: {
    id: "menu.button.overview",
  },
  profileButton: {
    id: "menu.button.profile",
  },
  messagesButton: {
    id: "menu.button.messages",
  },
  contactButton: {
    id: "menu.button.contact",
  },
  helpButton: {
    id: "menu.button.help",
  },
  closeButton: {
    id: "menu.button.close",
  },
  logoutButton: {
    id: "menu.button.logout",
  },
  inviteButton: {
    id: "menu.button.invite",
  },
  switchButton: {
    id: "menu.button.switch",
  },
  currentUser: {
    id: "menu.button.current-user",
  },
});
