import * as React from "react";

import { ActionImpl, ActionId } from "kbar";

import "./ResultItem.scss";

export const ResultItem = React.forwardRef(
  (
    {
      action,
      active,
      currentRootActionId,
    }: {
      action: ActionImpl;
      active: boolean;
      currentRootActionId: ActionId;
    },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const ancestors = React.useMemo(() => {
      if (!currentRootActionId) return action.ancestors;
      const index = action.ancestors.findIndex(
        (ancestor) => ancestor.id === currentRootActionId
      );
      // +1 removes the currentRootAction; e.g.
      // if we are on the "Set theme" parent action,
      // the UI should not display "Set theme… > Dark"
      // but rather just "Dark"
      return action.ancestors.slice(index + 1);
    }, [action.ancestors, currentRootActionId]);

    return (
      <div
        ref={ref}
        className={"cmd-k-result-item" + (active ? " active-item" : "")}
      >
        <div className="icon-ancestors-name-action">
          {action.icon && action.icon}
          <div className="ancestors-name">
            <div>
              {ancestors.length > 0 &&
                ancestors.map((ancestor) => (
                  <React.Fragment key={ancestor.id}>
                    <span className="ancestor-name">{ancestor.name}</span>
                    <span className="ancestor-rsaquo">&rsaquo;</span>
                  </React.Fragment>
                ))}
              <span>{action.name}</span>
            </div>
            {action.subtitle && (
              <span className="action-subtitle">{action.subtitle}</span>
            )}
          </div>
        </div>
        {action.shortcut?.length && (
          <div aria-hidden className="shortcut-keys">
            {action.shortcut.map((sc) => (
              <kbd key={sc}>{sc}</kbd>
            ))}
          </div>
        )}
      </div>
    );
  }
);
