import {
  Button,
  Card,
  EmailInput,
  EmailValidator,
  RequiredValidator,
  Form,
  BirthdayInput,
  LysaFormRef,
} from "@lysaab/ui-2";
import { DateTime } from "luxon";
import { useContext, useRef, useState, VoidFunctionComponent } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { LocalizationContext } from "../../../context/LocalizationContext";
import { dataLogin } from "../../../data/dataLogin";

interface Props {
  next: () => void;
}

const messages = defineMessages({
  emailLabel: {
    id: "username-reminder.intro.email.label",
  },
  emailPlaceholder: {
    id: "username-reminder.intro.email.placeholder",
  },
  emailRequired: {
    id: "username-reminder.intro.email.required",
  },
  emailInvalid: {
    id: "username-reminder.intro.email.invalid",
  },
  emailSuggestion: {
    id: "username-reminder.intro.email.suggestion",
  },
  dateOfBirthLabel: {
    id: "username-reminder.intro.date-of-birth.label",
  },
  dateOfBirthRequired: {
    id: "username-reminder.intro.date-of-birth.required",
  },
});

const eighteenYearsAgo = DateTime.local()
  .minus({ years: 18 })
  .toFormat("yyyy-LL-dd");

export const Intro: VoidFunctionComponent<Props> = ({ next }) => {
  const intl = useIntl();
  const [email, setEmail] = useState("");
  const [birthday, setBirthday] = useState("");
  const formRef = useRef<LysaFormRef>();
  const localizationContext = useContext(LocalizationContext);

  return (
    <div>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid || !localizationContext.state.country) {
            return;
          }

          dataLogin
            .usernameReminder(
              email,
              birthday,
              localizationContext.state.country,
              localizationContext.state.language
            )
            .then(() => {
              next();
            });
          /**
           * Leave any errors to the error boundary
           */
        }}
      >
        <h2>
          <FormattedMessage id="username-reminder.intro.header" />
        </h2>
        <Card>
          <BirthdayInput
            label={intl.formatMessage(messages.dateOfBirthLabel)}
            onChange={setBirthday}
            value={birthday}
            max={eighteenYearsAgo}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.dateOfBirthRequired)
              ),
            ]}
          />
          <EmailInput
            label={intl.formatMessage(messages.emailLabel)}
            value={email}
            onChange={setEmail}
            placeholder={intl.formatMessage(messages.emailPlaceholder)}
            suggestionMessage={intl.formatMessage(messages.emailSuggestion, {
              // This replacement is made inside <EmailInput>, so pass it along
              suggestion: "{suggestion}",
            })}
            validators={[
              new RequiredValidator(intl.formatMessage(messages.emailRequired)),
              new EmailValidator(intl.formatMessage(messages.emailInvalid)),
            ]}
          />
        </Card>
        <Button
          block
          type="submit"
          label={<FormattedMessage id="username-reminder.intro.button" />}
        />
      </Form>
    </div>
  );
};
