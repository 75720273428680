import { useContext, useEffect } from "react";
import { useNotificationsContext } from "../context/NotificationsContext";
import { UserContext } from "../context/UserContext";
import { dataMessages } from "../data/dataMessages";

export function useNotifications() {
  const { state } = useNotificationsContext();
  return state;
}

export function useInitNotifications() {
  const { setState: setNotificationsContext } = useNotificationsContext();
  const { state: user } = useContext(UserContext);

  useEffect(() => {
    if (user.tin) {
      dataMessages.getMessages().then((messages) => {
        const newUnreadMessagesCount =
          dataMessages.getUnreadMessagesCount(messages);
        setNotificationsContext({
          unreadMessages: newUnreadMessagesCount,
        });
      });
    }
    return () => {
      setNotificationsContext({
        unreadMessages: 0,
      });
    };
  }, [setNotificationsContext, user]);
}
