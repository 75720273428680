import {
  Form,
  LysaFormRef,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { useContext, useRef } from "react";
import { LysaAccountSelectionCard } from "../../../components/lysaAccountSelectionCard/LysaAccountSelectionCard";
import { TranslatedText } from "../../../components/TranslatedText";
import {
  isKF,
  MergedInternalTransferAccount,
} from "../../../data/dataTransfer";
import { useMergedInternalTransferAccounts } from "../hooks/useMergedInternalTransferAccounts";
import { InternalTransferContext } from "../InternalTransferContext";

interface Props {
  next: (account: MergedInternalTransferAccount) => void;
}

export function InternalTransferToAccount({ next }: Props) {
  const internalTransferContext = useContext(InternalTransferContext);
  const selectedAccount = internalTransferContext.state.toAccount;
  const formRef = useRef<LysaFormRef>();
  const mergedInternalTransferAccounts = useMergedInternalTransferAccounts();

  if (mergedInternalTransferAccounts.status === "LOADING") {
    return (
      <div className="internal-transfer-to-account">
        <Spinner />
      </div>
    );
  }

  if (
    mergedInternalTransferAccounts.status === "ERROR" ||
    mergedInternalTransferAccounts.data.length === 1
  ) {
    return (
      <div className="internal-transfer-to-account">
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <TranslatedText id="internal-transfer.story.to-account.error" />
        </Snackbar>
      </div>
    );
  }

  const accountAlternatives = mergedInternalTransferAccounts.data.filter(
    (account) =>
      account.accountId !== internalTransferContext.state.fromAccount?.accountId
  );

  return (
    <div className="internal-transfer-to-account">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (
            formRef.current?.isInvalid ||
            typeof internalTransferContext.state.fromAccount === "undefined"
          ) {
            return;
          }
          next(internalTransferContext.state.fromAccount);
        }}
      >
        <LysaAccountSelectionCard
          accounts={accountAlternatives}
          selectedAccount={selectedAccount}
          onChange={(toAccount) => {
            internalTransferContext.setState({ toAccount });
          }}
          legend={
            <h2>
              <TranslatedText id="internal-transfer.story.to-account.header" />
            </h2>
          }
        />
        {selectedAccount && isKF(selectedAccount) && (
          <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
            <TranslatedText id="internal-transfer.story.to-account.warning" />
          </Snackbar>
        )}

        <Button
          type="submit"
          block
          label={
            <TranslatedText id="internal-transfer.story.to-account.button" />
          }
        />
      </Form>
    </div>
  );
}
