import { encode } from "@lysaab/ui-2";
import { API } from "@lysaab/ui-2/network/API";
import { ExternalSavingsAccountId, SavingsAccountId } from "./dataAccounts";

interface ExternalPendingFundTransferResponse {
  amount: number;
  sendingAccount: ExternalSavingsAccountId;
  receivingAccount: ExternalSavingsAccountId;
}

export interface ExternalRebalancingTransferResponse {
  accountId: SavingsAccountId;
  amount: number;
  createdAt: string;
}

export const dataSavingsAccountDistribution = {
  getPendingTransactions: (accountId: SavingsAccountId) => {
    return API.get<ExternalPendingFundTransferResponse[]>(
      encode`/savings-account-distribution/transactions/pending/${accountId}`
    );
  },
  getOngoingBalancing: (accountId: SavingsAccountId) => {
    return API.get<ExternalRebalancingTransferResponse | null>(
      encode`/savings-account-distribution/account-balancing/ongoing/${accountId}`
    );
  },
};
