import { useContext, useEffect, VoidFunctionComponent } from "react";
import { useHistory } from "react-router";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { RiskWarning } from "../../../pageComponents/riskWarning/RiskWarning";
import { CreateAccountContext } from "../../../pages/createAccount/CreateAccountContext";
import { OVERVIEW_PAGE_URL } from "../../../pages/overview/OverviewPage";

interface Props {
  next: () => void;
}

export const RiskWarningWrapper: VoidFunctionComponent<Props> = ({ next }) => {
  const createAccountContext = useContext(CreateAccountContext);
  const history = useHistory();

  useEffect(() => {
    if (
      typeof createAccountContext.state.allocationSelectedRisk === "undefined"
    ) {
      history.push(getNavLink(OVERVIEW_PAGE_URL));
    }
  }, [history, createAccountContext.state.allocationSelectedRisk]);

  const internalNext = () => {
    createAccountContext.setState({
      allocationForcedRisk: undefined,
      takenRisk: createAccountContext.state.allocationSelectedRisk || 0,
    });
    next();
  };

  const internalNextLysa = () => {
    // Need to store it in state to handle back/forward navigation
    createAccountContext.setState({
      allocationForcedRisk: createAccountContext.state.advise?.advisedRisk || 0,
      takenRisk: createAccountContext.state.advise?.advisedRisk || 0,
    });
    next();
  };

  return (
    <RiskWarning
      risk={createAccountContext.state.allocationSelectedRisk || 0}
      advisedRisk={createAccountContext.state.advise?.advisedRisk || 0}
      confirmSelectedRisk={internalNext}
      takeAdvice={internalNextLysa}
    />
  );
};
