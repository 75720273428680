// From: https://stackoverflow.com/questions/105034/how-do-i-create-a-guid-uuid
function uuidv4DevFallback() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
    (
      +c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
    ).toString(16)
  );
}

/**
 * This function returns a random UUID.
 *
 * It relies on crypto.randomUUID which is available in all modern browsers, but,
 * because this is only available over https/localhost, we have a fallback in
 * place for the cases where we want to test the app on another device on the
 * local network in dev.
 */
export function getRandomUUID() {
  const shouldFallback =
    process.env.NODE_ENV === "development" && !crypto.randomUUID;
  return shouldFallback ? uuidv4DevFallback() : crypto.randomUUID();
}
