import { LysaCountry } from "@lysaab/shared";
import { useContext } from "react";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { LocalizationContext } from "../../context/LocalizationContext";

interface Message {
  id: string;
}

const currency = defineMessages<
  LysaCountry,
  Message,
  Record<LysaCountry, Message>
>({
  [LysaCountry.SWEDEN]: {
    id: "currency.se",
  },
  [LysaCountry.DENMARK]: {
    id: "currency.dk",
  },
  [LysaCountry.FINLAND]: {
    id: "currency.fi",
  },
  [LysaCountry.GERMANY]: {
    id: "currency.de",
  },
  [LysaCountry.SPAIN]: {
    id: "currency.es",
  },
});

export const CurrencyString: React.VFC = () => {
  const localizationContext = useContext(LocalizationContext);
  const intl = useIntl();

  if (localizationContext.state.country === undefined) {
    throw new Error("Country is undefined");
  }

  return (
    <span>
      {intl.formatMessage(currency[localizationContext.state.country])}
    </span>
  );
};
