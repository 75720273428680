import { useCallback, useEffect, useRef } from "react";
import * as React from "react";
import { CircleButton } from "@lysaab/ui-2";
import ReactModal from "react-modal";
import { useReactToPrint } from "react-to-print";
import { useInView } from "react-intersection-observer";
import cx from "classnames";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "./bodyScrollLock";
import "./Modal.scss";

interface Props {
  showModal: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  header?: string | React.ReactNode;
  printEnabled?: boolean;
  width?: number;
  className?: string;
  hideHeader?: boolean;
  closeOnOverlayClick?: boolean;
}

export const Modal: React.FunctionComponent<Props> = ({
  showModal,
  onClose,
  children,
  header,
  printEnabled = false,
  width = 700,
  className,
  hideHeader = false,
  closeOnOverlayClick = false,
}) => {
  const [topInViewRef, topInView] = useInView();
  const topRef = useRef<HTMLDivElement>(null);
  const printRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: "body { margin: 20px; }",
  });

  const afterCloseHandler = useCallback(
    () => enableBodyScroll(document.body),
    []
  );

  const afterOpenHandler = useCallback(() => {
    disableBodyScroll(document.body, {
      reserveScrollBarGap: true,
    });
  }, []);

  const scrollTop = () => {
    topRef?.current?.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

  return (
    <ReactModal
      className={className}
      overlayClassName="modal"
      closeTimeoutMS={250}
      isOpen={showModal}
      style={{
        overlay: {
          backgroundColor: "rgba(242, 242, 242, 0.75)",
        },
        content: {
          padding: 0,
          position: "initial",
          width,
          border: 0,
          borderRadius: 10,
          margin: 10,
        },
      }}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={closeOnOverlayClick}
      onAfterClose={afterCloseHandler}
      onAfterOpen={afterOpenHandler}
    >
      {!hideHeader && (
        <header className="modal-header">
          <h3 className="modal-header-title">{header}</h3>
          <div className="modal-header-actions">
            {printEnabled && (
              <CircleButton
                className="modal-action-button"
                onClick={handlePrint}
                icon="Print"
              />
            )}
            {onClose && (
              <CircleButton
                className="modal-action-button"
                ariaLabel="Increment"
                onClick={onClose}
                icon="Cancel"
              />
            )}
          </div>
        </header>
      )}
      <div className="modal-body" ref={printRef}>
        <div ref={topRef}></div>
        <div ref={topInViewRef}></div>
        <div>{children}</div>
      </div>
      <div
        className={cx(
          { "--visible": !topInView },
          "modal-body-scroll-top-button"
        )}
      >
        <CircleButton
          className="modal-action-button"
          onClick={() => scrollTop()}
          icon="Up"
        />
      </div>
    </ReactModal>
  );
};
