import {
  useContext,
  useEffect,
  useRef,
  useState,
  VoidFunctionComponent,
} from "react";
import {
  Button,
  LysaFormRef,
  Form,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Card,
} from "@lysaab/ui-2";
import { CrsResponse, dataCrs } from "../../../../../data/dataCrs";
import { TranslatedText } from "../../../../../components/TranslatedText";

import "./UpdateCrsInformation.scss";
import { CrsContext } from "../../../../../pages/updateCrsStory/context/CrsContext";
import { CrsFormFields } from "../../../components/crsFormFields/CrsFormFields";
import { useCountry } from "../../../../../context/LocalizationContext";

interface Props {
  next: () => void;
}

export const UpdateCrsInformation: VoidFunctionComponent<Props> = ({
  next,
}) => {
  const crsContext = useContext(CrsContext);
  const [serverCrs, setServerCrs] = useState<CrsResponse>();
  const [hasCrsChanged, setHasCrsChanged] = useState(true);
  const [showNoChangeWarn, setShowNoChangeWarn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { crsInformation } = crsContext.state;
  const country = useCountry();
  const currentCountry = useRef(country);

  const formRef = useRef<LysaFormRef>();

  useEffect(() => {
    setIsLoading(true);
    dataCrs
      .getLatestCrs()
      .then((response) => {
        if (!currentCountry.current) {
          return;
        }
        const temp = JSON.parse(JSON.stringify(response)) as CrsResponse;
        const hasSigningCountryAsTaxCountry = temp.countries.some(
          (country) => country.country === currentCountry.current
        );
        if (!hasSigningCountryAsTaxCountry) {
          temp.countries = [
            {
              country: currentCountry.current,
              tin: "",
            },
            ...temp.countries,
          ];
        }
        setServerCrs(temp);

        if (typeof crsInformation === "undefined") {
          crsContext.setState({ crsInformation: temp });
        }
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
    /* The useEffect should only run on first render, but eslint wants crsContext as dependecy which will trigger useEffect on every input change */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (serverCrs && typeof crsInformation !== "undefined") {
      setHasCrsChanged(hasCrsBeenUpdated(crsInformation, serverCrs));
      if (hasCrsChanged) {
        setShowNoChangeWarn(false);
      }
    }
  }, [crsInformation, hasCrsChanged, serverCrs]);

  if (isLoading) {
    return <Spinner />;
  }

  if (
    typeof crsInformation === "undefined" ||
    typeof crsContext.state.crsInformation === "undefined"
  ) {
    return null;
  }
  return (
    <div className="update-crs-information">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!hasCrsChanged) {
            setShowNoChangeWarn(true);
            return;
          }
          if (formRef.current?.isValid) {
            next();
          }
        }}
      >
        <h2>
          <TranslatedText id="denmark.updateCrsStory.updateCrsInformation.header" />
        </h2>

        <Card>
          {error && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="denmark.updateCrsStory.updateCrsInformation.error" />
            </Snackbar>
          )}
          {showNoChangeWarn && (
            <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
              <TranslatedText id="denmark.updateCrsStory.updateCrsInformation.crsNoChange" />
            </Snackbar>
          )}
          <CrsFormFields
            data={crsContext.state.crsInformation}
            setData={crsContext.setState}
          />
        </Card>
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="denmark.updateCrsStory.updateCrsInformation.button.next" />
          }
        />
      </Form>
    </div>
  );
};

export const hasCrsBeenUpdated = (
  updatedCrs: CrsResponse,
  savedCrs: CrsResponse
) => {
  return JSON.stringify(updatedCrs) !== JSON.stringify(savedCrs);
};
