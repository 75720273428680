import {
  ExpandableSneakPeakCard,
  Slider,
  Snackbar,
  SNACKBAR_TYPES,
  STORY_ANIMATION_DURATION,
  useDebounceValue,
} from "@lysaab/ui-2";
import { useContext, useEffect, useRef, useState } from "react";
import * as React from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { DEFAULT_VALUES } from "./DefaultValues";
import { useHistory } from "react-router-dom";
import { LocalizationContext } from "../../context/LocalizationContext";
import { dataFees, FeesEstimatedSignedIn } from "../../data/dataFees";
import { Amount } from "../../components/amount/Amount";
import { FormattedPercentage } from "../../components/formattedPercentage/FormattedPercentage";
import { AccountType, InvestmentType } from "../../data/dataAccounts";
import { InvestmentInput } from "./InvestmentInput";
import { MonthlyInvestmentInput } from "./MonthlyInvestmentInput";

import "./EditAllocationFees.scss";
import { simulateFutureWorthAndFee } from "./feeHelpers";
import { useAccounts } from "../../hooks/useAccounts";
import { LysaCountry } from "@lysaab/shared";
import { KfTaxInformation } from "./KfTaxInformation";

const messages = defineMessages({
  showMoreButton: {
    id: "editallocation.fees.showmore",
  },
  showLessButton: {
    id: "editallocation.fees.showless",
  },
});

const DEBOUNCE_TIMER = 500;

const INITIAL_FEES = {
  future: {
    discretionary: 0,
    fundManagement: 0,
    fundAssets: 0,
    transactionFees: 0,
    total: 0,
  },
  cost: {
    discretionary: 0,
    fundManagement: 0,
    fundAssets: 0,
    transactionFees: 0,
    total: 0,
  },
};

interface Props {
  risk: number;
  investmentType: InvestmentType;
  accountType: AccountType;
  allocationInvestment: number;
  setAllocationInvestment: (allocationInvestment: number) => void;
  monthlyInvestment: number;
  setMonthlyInvestment: (value: number) => void;
  horizonYears: number;
  setHorizonYears: (allocationInvestment: number) => void;
  insured?: string;
}

export const EditAllocationFees: React.VFC<Props> = ({
  risk,
  investmentType,
  allocationInvestment,
  setAllocationInvestment,
  monthlyInvestment,
  setMonthlyInvestment,
  horizonYears,
  setHorizonYears,
  accountType,
  insured,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const debouncedRisk = useDebounceValue(risk, DEBOUNCE_TIMER);
  const localizationContext = useContext(LocalizationContext);
  const containerDiv = useRef<HTMLDivElement>(null);
  const [estimatedFees, setEstimatedFees] =
    useState<FeesEstimatedSignedIn>(INITIAL_FEES);
  const [expectedYield, setExpectedYield] = useState(7);
  const { accounts } = useAccounts();

  useEffect(() => {
    const search = new URLSearchParams(history.location.search);
    if (search.has("fees")) {
      setTimeout(
        () => containerDiv.current?.scrollIntoView({ behavior: "smooth" }),
        STORY_ANIMATION_DURATION
      );
    }
  }, [history.location.search]);

  useEffect(() => {
    if (!localizationContext.state.country) {
      return;
    }

    if (
      allocationInvestment <
        DEFAULT_VALUES[localizationContext.state.currency].minInvestment ||
      allocationInvestment >
        DEFAULT_VALUES[localizationContext.state.currency].maxInvestment
    ) {
      setEstimatedFees(INITIAL_FEES);
      return;
    }

    dataFees
      .getEstimatedFeesSignedIn({
        amount: parseInt(allocationInvestment.toString(), 10),
        risk: debouncedRisk,
        investmentType: investmentType,
        country: localizationContext.state.country,
        accountType: accountType,
        insured: insured,
      })
      .then((fees) => {
        setEstimatedFees(fees);
      });
  }, [
    debouncedRisk,
    investmentType,
    localizationContext.state.currency,
    localizationContext.state.country,
    allocationInvestment,
    accountType,
    insured,
  ]);

  if (!accounts || !localizationContext.state.country) {
    return null;
  }

  // We don't care about fees.future because we make our own calculations.
  // So we use fees.cost, which is in percentage, like 0.12%
  // fees.rebase is expressed in basis points, so we divide it by 100 to get it
  // in percentage instead
  const fees = estimatedFees || INITIAL_FEES;

  const fundManagementPercentage = fees.cost.fundManagement;
  const fundsPercentage = fees.cost.fundAssets;
  const transactionsPercentage = fees.cost.transactionFees;
  const insurancePercentage = fees.cost.insurancePremium ?? 0;
  const insuranceRiskPercentage = fees.cost.insuranceRiskPremium ?? 0;
  const customerAum =
    accounts?.investmentAccounts.reduce(
      (totalWorth, account) => totalWorth + account.worth,
      0
    ) +
    accounts?.savingsAccounts.reduce(
      (totalWorth, account) => totalWorth + account.totalBalance,
      0
    );

  const simulated = simulateFutureWorthAndFee({
    initialInvestment: allocationInvestment,
    monthlyInvestment: monthlyInvestment,
    years: horizonYears,
    expectedYearlyYield: expectedYield,
    yearlyFundFeePercentage: fundsPercentage,
    yearlyTransactionFeePercentage: transactionsPercentage,
    yearlyLysaFundManagementFeePercentage: fundManagementPercentage,
    yearlyInsuranceFeePercentage: insurancePercentage,
    yearlyInsuranceRiskFeePercentage: insuranceRiskPercentage,
    country: localizationContext.state.country,
    customerAum,
    discountExpiry: fees.rebateExpiry ? new Date(fees.rebateExpiry) : undefined,
  });
  const lysaMinPercentage = fundManagementPercentage + simulated.minLysaFee;
  const lysaMaxPercentage = fundManagementPercentage + simulated.maxLysaFee;
  const minTotalPercentage =
    lysaMinPercentage +
    fundsPercentage +
    transactionsPercentage +
    insurancePercentage +
    insuranceRiskPercentage;
  const maxTotalPercentage =
    lysaMaxPercentage +
    fundsPercentage +
    transactionsPercentage +
    insurancePercentage +
    insuranceRiskPercentage;
  const fundsCost = simulated.totalFees.fundFee;
  const transactionsCost = simulated.totalFees.transactionFee;
  const insuranceCost = simulated.totalFees.insuranceFee;
  const insuranceRiskCost = simulated.totalFees.insuranceRiskFee;
  const lysaCost =
    simulated.totalFees.discretionaryFee +
    simulated.totalFees.lysaFundManagementFee;

  return (
    <div
      className="edit-allocation-fees-page card-with-top-margin"
      ref={containerDiv}
    >
      <ExpandableSneakPeakCard
        buttonOpenText={intl.formatMessage(messages.showMoreButton)}
        buttonCloseText={intl.formatMessage(messages.showLessButton)}
        startOpen={/\bfees\b/.test(history.location.search)}
      >
        <>
          <div className="header">
            <h3>
              <FormattedMessage id="editallocation.fees.header" />
            </h3>
          </div>
          {fees && fees.rebate && (
            <Snackbar type={SNACKBAR_TYPES.SUCCESS}>
              <span>
                <FormattedMessage
                  id="editallocation.fees.discount"
                  values={{
                    discount: intl.formatNumber(fees.rebate / 100, {
                      style: "percent",
                    }),
                    endDate: fees.rebateExpiry
                      ? intl.formatDate(fees.rebateExpiry)
                      : "-",
                  }}
                />
              </span>
            </Snackbar>
          )}
          <div className="controls">
            <h4>
              <FormattedMessage id="editallocation.text.example-initial-investment" />
            </h4>
            <InvestmentInput
              allocationInvestment={allocationInvestment}
              setAllocationInvestment={setAllocationInvestment}
            />
            <h4>
              <FormattedMessage id="editallocation.text.example-monthly-investment" />
            </h4>
            <MonthlyInvestmentInput
              monthly={monthlyInvestment}
              setMonthly={setMonthlyInvestment}
            />
            <Slider
              min={1}
              max={50}
              step={1}
              value={horizonYears}
              onChange={(value) => setHorizonYears(value)}
              label={
                <h4 className="horizon-slider-title">
                  <strong>
                    <FormattedMessage id="editallocation.text.horizon" />
                  </strong>
                  <i>
                    <FormattedMessage
                      id="editallocation.text.horizon.years"
                      values={{ horizon: horizonYears }}
                    />
                  </i>
                </h4>
              }
            />
            <Slider
              min={0}
              max={10}
              step={0.1}
              value={expectedYield}
              onChange={(value) => setExpectedYield(value)}
              label={
                <h4 className="expected-yield-slider-title">
                  <strong>
                    <FormattedMessage id="editallocation.text.expected" />
                  </strong>
                  <i>
                    <FormattedPercentage value={expectedYield} decimals={1} />
                  </i>
                </h4>
              }
            />
          </div>
          <div className="summary">
            <div className="header">
              <h4>
                <FormattedMessage
                  id="editallocation.fees.summary.header"
                  values={{ horizon: horizonYears }}
                />
              </h4>
            </div>
            <dl>
              <dt>
                <FormattedMessage id="editallocation.text.lysa" />
                {": "}
                {lysaMinPercentage === lysaMaxPercentage ? (
                  <FormattedPercentage value={lysaMinPercentage} decimals={3} />
                ) : (
                  <>
                    <FormattedPercentage
                      value={lysaMinPercentage}
                      decimals={3}
                    />{" "}
                    -{" "}
                    <FormattedPercentage
                      value={lysaMaxPercentage}
                      decimals={3}
                    />
                  </>
                )}
              </dt>
              <dd>
                <Amount amount={lysaCost} />
              </dd>
              <dt>
                <FormattedMessage id="editallocation.fees.funds" />
                {": "}
                <FormattedPercentage value={fundsPercentage} decimals={3} />
              </dt>
              <dd>
                <Amount amount={fundsCost} />
              </dd>
              <dt>
                <FormattedMessage id="editallocation.fees.transactions" />
                {": "}
                <FormattedPercentage
                  value={transactionsPercentage}
                  decimals={3}
                />
              </dt>
              <dd>
                <Amount amount={transactionsCost} />
              </dd>
              {(accountType === AccountType.DANICA_KF ||
                accountType === AccountType.KF_SWE) && (
                <React.Fragment>
                  <dt>
                    <FormattedMessage id="editallocation.fees.insurance" />
                    {": "}
                    <FormattedPercentage
                      value={insurancePercentage}
                      decimals={3}
                    />
                  </dt>
                  <dd>
                    <Amount amount={insuranceCost} />
                  </dd>
                </React.Fragment>
              )}
              {accountType === AccountType.KF_SWE && (
                <React.Fragment>
                  <dt>
                    <FormattedMessage id="editallocation.fees.insurance-risk" />
                    {": "}
                    <FormattedPercentage
                      value={insuranceRiskPercentage}
                      decimals={3}
                    />
                  </dt>
                  <dd>
                    <div className="cost-wrapper">
                      {insuranceRiskCost < 1 && (
                        <React.Fragment>&lt;</React.Fragment>
                      )}
                      <Amount amount={insuranceRiskCost} />
                    </div>
                  </dd>
                </React.Fragment>
              )}
              <dt className="total">
                <FormattedMessage id="editallocation.fees.total" />
                {": "}
                {minTotalPercentage === maxTotalPercentage ? (
                  <FormattedPercentage
                    value={minTotalPercentage}
                    decimals={3}
                  />
                ) : (
                  <>
                    <FormattedPercentage
                      value={minTotalPercentage}
                      decimals={3}
                    />
                    {" - "}
                    <FormattedPercentage
                      value={maxTotalPercentage}
                      decimals={3}
                    />
                  </>
                )}
              </dt>
              <dd className="total">
                <Amount amount={simulated.fee} />
              </dd>
            </dl>
          </div>
          {(accountType === AccountType.DANICA_KF ||
            accountType === AccountType.KF_SWE) && (
            <KfTaxInformation
              oneTimeInvestment={allocationInvestment}
              monthlyInvestment={monthlyInvestment}
              accountType={accountType}
            />
          )}
          <div className="fee-disclaimer">
            <p>
              <FormattedMessage id="editallocation.fees.disclaimer" />
            </p>
            <p>
              <LysaFeeDescription
                userCountry={localizationContext.state.country}
              />
            </p>
          </div>
        </>
      </ExpandableSneakPeakCard>
    </div>
  );
};

const FEE_DESCRIPTION_ELEVIO_ARTICLE_ID = "334";

const LysaFeeDescription = ({ userCountry }: { userCountry?: LysaCountry }) => {
  // TODO: Remove when the Elevio article is translated to all languages
  if (userCountry !== LysaCountry.SWEDEN) {
    return null;
  }

  return (
    <FormattedMessage
      id="editallocation.fees.description"
      values={{
        link: (parts) => (
          <span data-elevio-article={FEE_DESCRIPTION_ELEVIO_ARTICLE_ID}>
            {parts}
          </span>
        ),
      }}
    />
  );
};
