import { FunctionComponent, useId, useMemo, useRef, useState } from "react";

import {
  InputErrorList,
  RequiredValidator,
  Typography,
  useForm,
  useValidation,
} from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { Move } from "../../TransferContext";
import { MoveCard } from "../moveCard/MoveCard";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { ScrapedPensionData } from "../../../../../../data/dataLifePensionMove";

import "./ActionablePensions.scss";

interface Props {
  movable: ScrapedPensionData[] | undefined;
  unknown: ScrapedPensionData[] | undefined;
  manual: Move[] | undefined;
  checked: { [id: string]: boolean };
  setChecked: React.Dispatch<React.SetStateAction<{ [id: string]: boolean }>>;
}

export const ActionablePensions: FunctionComponent<Props> = ({
  movable,
  unknown,
  manual,
  checked,
  setChecked,
}) => {
  const intl = useIntl();
  const values = useMemo(
    () => Object.values(checked).filter(Boolean),
    [checked]
  );
  const id = useId();

  const [inputName] = useState("insurance_summary_group_" + id);
  const containerRef = useRef<HTMLFieldSetElement>(null);

  const [validity, errorMessages, , resetValidation] = useValidation<boolean[]>(
    values,
    [
      new RequiredValidator(
        intl.formatMessage({
          id: "sweden.transfer-pension.insurance-information.summary.noCompaniesSelected.warning",
        })
      ),
    ],
    false
  );

  useForm(
    inputName,
    validity,
    values,
    resetValidation,
    containerRef,
    errorMessages
  );

  return (
    <fieldset
      ref={containerRef}
      className="actionable-pensions-movable-insurance-fieldset"
    >
      {movable && (
        <section className="move-cards">
          <Typography type="h3" className="move-cards-header">
            <TranslatedText id="sweden.transfer-pension.insurance-information.summary.movable.header" />
          </Typography>
          {movable.map((movableMove) => {
            return (
              <MoveCard
                key={movableMove.id}
                insuranceCompany={movableMove.insuranceCompany}
                insuranceNumber={movableMove.insuranceNumber}
                insuranceHolderName={movableMove.insuranceHolderName}
                checked={checked[movableMove.id]}
                currentWorth={movableMove.currentWorth}
                information={movableMove.information}
                moveAccountType={movableMove.pensionType}
                handleChange={() => {
                  setChecked((oldChecked) => {
                    return {
                      ...oldChecked,
                      [movableMove.id]: !oldChecked[movableMove.id],
                    };
                  });
                }}
              />
            );
          })}
        </section>
      )}
      {unknown && (
        <section className="move-cards">
          <div>
            <Typography type="h3" className="move-cards-header">
              <TranslatedText id="sweden.transfer-pension.insurance-information.summary.unknown.header" />
            </Typography>
            <Typography type="body" className="move-cards-ingress">
              <TranslatedText id="sweden.transfer-pension.insurance-information.summary.unknown.ingress" />
            </Typography>
          </div>
          {unknown.map((unknownMove) => {
            return (
              <MoveCard
                key={unknownMove.id}
                insuranceCompany={unknownMove.insuranceCompany}
                insuranceNumber={unknownMove.insuranceNumber}
                insuranceHolderName={unknownMove.insuranceHolderName}
                checked={checked[unknownMove.id]}
                currentWorth={unknownMove.currentWorth}
                information={unknownMove.information}
                moveAccountType={unknownMove.pensionType}
                list="UNKNOWN"
                handleChange={() => {
                  setChecked((oldChecked) => {
                    return {
                      ...oldChecked,
                      [unknownMove.id]: !oldChecked[unknownMove.id],
                    };
                  });
                }}
              />
            );
          })}
        </section>
      )}
      {manual && manual.length > 0 && (
        <section className="move-cards">
          <Typography type="h3" className="move-cards-header">
            <TranslatedText id="sweden.transfer-pension.insurance-information.summary.manual.header" />
          </Typography>
          {manual.map((manualMove) => {
            return (
              <MoveCard
                key={manualMove.id}
                insuranceCompany={manualMove.institute}
                insuranceNumber={manualMove.insuranceNumber}
                insuranceHolderName={manualMove.employer}
                checked={manualMove.id ? checked[manualMove.id] : false}
                currentWorth={manualMove.currentWorth}
                moveAccountType={manualMove.type}
                handleChange={() => {
                  setChecked((oldChecked) => {
                    return {
                      ...oldChecked,
                      [manualMove.id as string]:
                        !oldChecked[manualMove.id as string],
                    };
                  });
                }}
              />
            );
          })}
        </section>
      )}
      {errorMessages.length > 0 && (
        <div className="error-container">
          <InputErrorList errorMessages={errorMessages} />
        </div>
      )}
    </fieldset>
  );
};
