import { Button, Card, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { useContext } from "react";
import * as React from "react";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../../../../../components/TranslatedText";
import { KF_WITHDRAWAL_STATUS } from "../../../../../../../data/dataDanica";
import { getNavLink } from "../../../../../../../hooks/useCountryUrls";
import { KfWithdrawalContext } from "../KfWithdrawalContext";
import { ROUTES } from "../WithdrawalKfRequestPage";

export const Expired: React.FunctionComponent = () => {
  const { state: kfWithdrawalState } = useContext(KfWithdrawalContext);

  if (
    kfWithdrawalState.signingStatus?.status !== KF_WITHDRAWAL_STATUS.EXPIRED
  ) {
    return null;
  }

  return (
    <Card>
      <h4>
        <TranslatedText
          id="withdrawalRequest.kf.signing.expired.header"
          description="withdrawal request kf - status expired header"
          defaultMessage="Expired"
        />
      </h4>
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <div>
          <TranslatedText
            id="withdrawalRequest.kf.signing.expired.paragraph"
            description="withdrawal request kf - status expired paragraph"
            defaultMessage="This withdrawal has expired. You will need to start a new withdrawal"
          />
        </div>
      </Snackbar>
      <Button
        component={Link}
        to={getNavLink(ROUTES.FROM)}
        block
        label={
          <TranslatedText id="withdrawalRequest.kf.signing.expired.button" />
        }
      />
    </Card>
  );
};
