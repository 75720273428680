import * as React from "react";
import "./FlagIcon.scss";

interface Props {
  code: string;
  /** Reusing prop name and value from react-flag-icon-css */
  size?: "2x";
}

export const FlagIcon: React.VFC<Props> = ({ code, size }) => {
  return (
    <span className={"fi fi-" + code + (size === "2x" ? " x2" : "")}></span>
  );
};
