import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import {
  CREATE_SAVINGS_ACCOUNT_URL,
  SAVINGS_ACCOUNT_BASE_ROUTES,
} from "../../savingsAccount/CreateSavingsAccountStory";
import { RouteAwareToggle } from "../../../../../../components/route/RouteAwareToggle";
import { InvestmentAccountCard } from "./components/investmentAccountCard/InvestmentAccountCard";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { SavingsAccountIntroCard } from "../../components/savingsAccountIntroCard/SavingsAccountIntroCard";
import { Button } from "@lysaab/ui-2";
import { Link } from "react-router-dom";
import { ElevioLink } from "../../../../../../components/elevio/ElevioLink";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
} from "../../../../../../data/dataCustomerTracking";
import { MovePensionCard } from "./components/movePensionCard/MovePensionCard";
import { TRANSFER_PENSIONS_URL } from "../../../transferPensions/TransferPensionsStory";

import "./Intro.scss";

export const Intro: React.FC = () => {
  return (
    <div className="create-account-intro">
      <InvestmentAccountCard />
      <RouteAwareToggle path={getNavLink(CREATE_SAVINGS_ACCOUNT_URL)}>
        <SavingsAccountIntroCard
          navigationButton={
            <Button
              component={Link}
              to={getNavLink(SAVINGS_ACCOUNT_BASE_ROUTES.INFO)}
              onClick={() =>
                dataCustomerTrackingService.postEvent({
                  eventName: "clickedOpenSavingsAccountButton",
                  domain: FeatureDomain.SAVINGS_ACCOUNT,
                  subDomain: SubDomain.CREATE_ACCOUNT,
                  payload: {
                    buttonOption: "accountTypeSelection",
                  },
                })
              }
              block
              label={
                <TranslatedText id="sweden.createAccountIntroStory.intro.createSavingsButton" />
              }
            />
          }
        />
      </RouteAwareToggle>
      <RouteAwareToggle path={getNavLink(TRANSFER_PENSIONS_URL)}>
        <MovePensionCard />
      </RouteAwareToggle>
      <RouteAwareToggle path={getNavLink(CREATE_SAVINGS_ACCOUNT_URL)}>
        <p>
          <TranslatedText
            id="sweden.createAccountIntroStory.intro.disclaimer"
            values={{
              link: (text) => <ElevioLink articleId="157" linkText={text} />,
            }}
          />
        </p>
      </RouteAwareToggle>
    </div>
  );
};
