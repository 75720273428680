import { API } from "@lysaab/ui-2";

export enum Bank {
  SKANDIABANKEN = "SKANDIABANKEN",
  SEB = "SEB",
  NORDEA = "NORDEA",
  HANDELSBANKEN = "HANDELSBANKEN",
  SWEDBANK = "SWEDBANK",
  LANSFORSAKRINGAR = "LANSFORSAKRINGAR",
  DANSKE_BANK = "DANSKE_BANK",
  ICA = "ICA",
  OP = "OP",
  SPANKKI = "S-PANKKI",
  SAASTOPANKKI = "SAASTOPANKKI",
  AKTIA = "AKTIA",
  POP_PANKKI = "POP_PANKKI",
  OMA_SAASTOPANKKI = "OMA_SAASTOPANKKI",
  ALANDSBANKEN = "ALANDSBANKEN",
  N26 = "N26",
  JYSKE = "JYSKE",
  SYDBANK = "SYDBANK",
}

export type BankCode = string & { readonly isBankCode: true };
export type AccountJWT = string & { readonly isAccountJWT: true };

export interface AvailableBank {
  displayName: string;
  name: Bank;
  enabled: boolean;
  tagName: string;
  bankCode: BankCode;
  monthlySavingsEnabled: boolean;
  minAllowed: number;
  maxAllowed: number;
  allowWithdrawalAccounts: boolean;
}

export enum KlarnaState {
  WAITING_TO_START = "WAITING_TO_START",
  WAITING_FOR_USER = "WAITING_FOR_USER",
  WAITING_FOR_ACCOUNTS = "WAITING_FOR_ACCOUNTS",
  ERROR = "ERROR",
  COMPLETE = "COMPLETE",
}

enum AccountType {
  DEFAULT = "DEFAULT",
  SAVING = "SAVING",
  CREDITCARD = "CREDITCARD",
  DEPOT = "DEPOT",
  UNKNOWN = "UNKNOWN",
}

export interface KlarnaAccount {
  id: string;
  name: string;
  balance: number;
  iban: string;
  accountHolder: string;
  accountType: AccountType;
  accountJWT: AccountJWT;
  bankAccount: string;
}

export interface KlarnaAccountRequest {
  state: KlarnaState;
  clientToken?: string;
  error?: string;
  accounts?: KlarnaAccount[];
}

export enum AccountTypes {
  DEFAULT = "DEFAULT",
  SAVING = "SAVING",
  CREDITCARD = "CREDITCARD",
  DEPOT = "DEPOT",
  UNKNOWN = "UNKNOWN",
}

export const dataKlarna = {
  getAllBanks: () => {
    return API.get<AvailableBank[]>("/klarna/banks");
  },
  initAccountInformationSession: (
    bankCode: BankCode,
    accountTypes?: AccountTypes[]
  ) => {
    return API.post(`/klarna/accounts/ais`, { bankCode, accountTypes });
  },
  pollAccountInformationSession: () => {
    return API.get<KlarnaAccountRequest>("/klarna/accounts", true);
  },
};
