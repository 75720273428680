import { useReducer } from "react";
import * as React from "react";
import { Interval, IndexId } from "../data/dataPerformance";
import {
  Store,
  STORE_PERFORMANCE_INTERVAL,
  STORE_PERFORMANCE_INDEXES,
  STORE_PERFORMANCE_CUSTOM_START_DATE,
  STORE_PERFORMANCE_CUSTOM_END_DATE,
  STORE_PERFORMANCE_SHOW_EVENTS,
  CURRENT_DAY,
} from "../Store";
import { DateTime } from "luxon";

export const EARLIEST_START_DATE = Date.parse("2016-11-10T23:00:00.000Z");

export interface PerformanceState {
  interval: Interval;
  customStartDate: Date | null;
  customEndDate: Date | null;
  indexes: IndexId[];
  showOverlay: boolean;
  showEvents: boolean;
}

export interface PerformanceContextProps extends PerformanceState {
  setState: (newState: Partial<PerformanceState>) => void;
}

export const PerformanceContext = React.createContext<PerformanceContextProps>(
  {} as PerformanceContextProps
);

export const withPerformance =
  <P extends object>(
    Component: React.ComponentType<React.PropsWithChildren<P>>
  ): React.FC<
    React.PropsWithChildren<Omit<P, keyof PerformanceContextProps>>
  > =>
  (props) =>
    (
      <PerformanceContext.Consumer>
        {(contextProps) => <Component {...(props as P)} {...contextProps} />}
      </PerformanceContext.Consumer>
    );

function stateReducer(
  state: PerformanceState,
  newState: Partial<PerformanceState>
) {
  return { ...state, ...newState };
}

export const PerformanceContextProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  let start = null;
  let end = null;
  const rawStartDate = Store.getValue(
    STORE_PERFORMANCE_CUSTOM_START_DATE
  ) as string;
  const rawEndDate = Store.getValue(
    STORE_PERFORMANCE_CUSTOM_END_DATE
  ) as string;

  if (rawEndDate === CURRENT_DAY) {
    end = new Date();
  } else if (
    !isNaN(Date.parse(rawEndDate)) &&
    Date.parse(rawEndDate) <= Date.now()
  ) {
    end = new Date(rawEndDate);
  } else {
    end = new Date();
  }

  if (
    !isNaN(Date.parse(rawStartDate)) &&
    Date.parse(rawStartDate) >= EARLIEST_START_DATE
  ) {
    start = new Date(rawStartDate);
  } else {
    let dt = DateTime.fromJSDate(end);
    dt = dt.startOf("day");
    dt = dt.minus({ months: 3 });
    start = dt.toJSDate();
  }

  const [state, setState] = useReducer(stateReducer, {
    indexes: Store.getValue(STORE_PERFORMANCE_INDEXES),
    interval: Store.getValue(STORE_PERFORMANCE_INTERVAL),
    customStartDate: start,
    customEndDate: end,
    showOverlay: false,
    showEvents: Store.getValue(STORE_PERFORMANCE_SHOW_EVENTS),
  });

  return (
    <PerformanceContext.Provider value={{ ...state, setState }}>
      {children}
    </PerformanceContext.Provider>
  );
};
