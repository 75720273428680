import { FunctionComponent } from "react";
import { FormattedNumber } from "react-intl";

interface Props {
  value: number;
  decimals?: number;
}

export const FormattedPercentage: FunctionComponent<Props> = ({
  value,
  decimals = 0,
}) => {
  return (
    <FormattedNumber
      value={value / 100}
      // eslint-disable-next-line react/style-prop-object
      style="percent"
      minimumFractionDigits={decimals}
      maximumFractionDigits={decimals}
    />
  );
};
