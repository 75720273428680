import { useContext } from "react";
import { useIntl } from "react-intl";
import { LocalizationContext } from "../context/LocalizationContext";

export const useCurrencySymbol = () => {
  const { currency } = useContext(LocalizationContext).state;
  const intl = useIntl();

  let symbol: string | undefined;
  intl
    .formatNumberToParts(0, {
      style: "currency",
      currency,
    })
    .forEach(({ type, value }) => {
      if (type === "currency") {
        symbol = value;
      }
    });

  if (typeof symbol === "undefined") {
    throw new Error("No currency for locale");
  }

  return symbol;
};
