import { useContext, useRef, VoidFunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { GridCol } from "../../../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../../../components/grid/gridRow/GridRow";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { Page, PageHeader } from "../../../../../pages/Page";
import { USER_MANAGEMENT_URL } from "../userManagement/UserManagementStory";
import { AddAccount } from "./addAccount/AddAccount";
import { FeeAccount } from "./feeAccount/FeeAccount";
import { PaidFees } from "./paidFees/PaidFees";
import { Principals } from "./principals/Principals";
import { UserContext } from "../../../../../context/UserContext";
import { CurrentCrsInformation } from "./currentCrsInformation/CurrentCrsInformation";
import "./CompanySettingsPage.scss";
import { Button } from "@lysaab/ui-2";
import { InvoiceAddress } from "./invoiceAddress/InvoiceAddress";

export const COMPANY_SETTINGS_PAGE_URL = "/company-settings";

export const CompanySettingsPage: VoidFunctionComponent = () => {
  const userContext = useContext(UserContext);
  const reloadRef = useRef<{ reloadConsents?: () => void }>({});

  return (
    <Page className="company-settings-page">
      <PageHeader>
        <h1>
          <FormattedMessage id="sweden.companySettings.title" />
        </h1>
      </PageHeader>
      <GridRow>
        <GridCol xsmall={12} large={6}>
          <GridRow>
            <GridCol xsmall={12}>
              <FeeAccount reloadRef={reloadRef} />
            </GridCol>

            {!userContext.state.readOnly && (
              <GridCol xsmall={12}>
                <AddAccount reloadRef={reloadRef} />
              </GridCol>
            )}
          </GridRow>
        </GridCol>

        <GridCol xsmall={12} large={6}>
          <GridRow>
            <GridCol xsmall={12}>
              <CurrentCrsInformation />
            </GridCol>
            <GridCol xsmall={12}>
              <Principals />
            </GridCol>

            <GridCol xsmall={12}>
              <Button
                component={Link}
                to={getNavLink(USER_MANAGEMENT_URL)}
                block
                className="user-management-link"
                label={
                  <FormattedMessage id="sweden.companySettings.userMangement.title" />
                }
              />
            </GridCol>
            <GridCol xsmall={12}>
              <InvoiceAddress />
            </GridCol>
          </GridRow>
        </GridCol>
      </GridRow>

      <GridRow>
        <GridCol xsmall={12}>
          <PaidFees />
        </GridCol>
      </GridRow>
    </Page>
  );
};
