import { FormattedMessage, FormattedRelativeTime } from "react-intl";

interface Props {
  expiryDate: string;
}

export const ListItemExpiry: React.VFC<Props> = ({ expiryDate }) => {
  return (
    <div className="list-item">
      <strong>
        <FormattedMessage id="shareAccountInviteCard.expiryLabel" />
      </strong>
      <div>
        <FormattedRelativeTime
          value={new Date(expiryDate).getTime() / 1000 - Date.now() / 1000}
          numeric="auto"
          updateIntervalInSeconds={1}
        />
      </div>
    </div>
  );
};
