import { Fragment } from "react";
import { Amount } from "../../../../../components/amount/Amount";
import { TranslatedText } from "../../../../../components/TranslatedText";
import {
  CashTransaction,
  CashTransactionType,
} from "../../../../../data/dataTransactions";
import { DepositTransactionRows } from "./components/DepositTransactionRow";
import { FeeTransactionRows } from "./components/FeeTransactionRow";
import { InterestTransactionRow } from "./components/InterestTransactionRow";
import { WithdrawalTransactionRows } from "./components/WithdrawalTransactionRow";

export const CashTransationRows = ({
  transaction,
}: {
  transaction: CashTransaction;
}) => {
  return (
    <Fragment>
      <div className="transaction-card-row ">
        <dt>
          <TranslatedText id="historicTransactionsPage.historicTransactions.transactionCard.amount.CashTransationRows" />
        </dt>
        <dd>
          <Amount
            amount={
              transaction.type === CashTransactionType.MOVE_OUT
                ? -1 * transaction.amount
                : transaction.amount
            }
            decimals={2}
          />
        </dd>
      </div>

      {/* TaxTransactions, MoveInTransactions and MoveOutTransactions only show Account and amount */}
      <FeeTransactionRows transaction={transaction} />
      <DepositTransactionRows transaction={transaction} />
      <WithdrawalTransactionRows transaction={transaction} />
      <InterestTransactionRow transaction={transaction} />
    </Fragment>
  );
};
