import { LysaCountry } from "@lysaab/shared";
import { useCallback, useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../context/LocalizationContext";
import {
  BankType,
  CustomerKycCorporation,
  CustomerKycPerson,
  dataKyc,
  DepositType,
  DepositTypeCorporation,
  DepositTypePerson,
  Employment,
  isCountryBank,
  isDeposit,
  isEmployment,
  isMoneyOrigin,
  KycQuestion,
  MoneyOrigin,
  QuestionAnswer,
  SaveCustomerKycRequest,
} from "../data/dataKyc";
import { LegalEntityType } from "../data/dataLogin";

export const useCustomerKyc = <T extends LegalEntityType>(
  legalEntityType: T
) => {
  const localizationContext = useContext(LocalizationContext);
  const [customerKyc, setCustomerKycInternal] =
    useState<CustomerKycInternal<T>>();

  useEffect(() => {
    const country = localizationContext.state.country;

    if (!country || !legalEntityType) {
      return;
    }

    dataKyc.getKyc().then((response) => {
      setCustomerKycInternal(
        mapCustomerKycToInternal(
          legalEntityType,
          country,
          response.customerKyc.questionAnswers
        )
      );
    });
  }, [localizationContext, legalEntityType]);

  const setCustomerKyc = useCallback(
    (questionAnswers: Partial<CustomerKycInternal>) => {
      if (customerKyc) {
        setCustomerKycInternal({
          ...(customerKyc || {}),
          ...questionAnswers,
        });
      }
    },
    [customerKyc]
  );

  return [customerKyc, setCustomerKyc] as [
    CustomerKycInternal<T>,
    (q: Partial<CustomerKycInternal<T>>) => void
  ];
};

interface CustomerKycInternalPerson {
  [KycQuestion.DEPOSIT_YEARLY_VALUE]: DepositTypePerson | undefined;
  [KycQuestion.EMPLOYMENT]: Employment | undefined;
  [KycQuestion.MONEY_ORIGIN]: MoneyOrigin<LegalEntityType.PERSON>[];
  [KycQuestion.MONEY_BANK_ORIGIN]: BankType[];
}

export function isCustomerKycInternalPerson(
  kyc: CustomerKycInternal
): kyc is CustomerKycInternalPerson;
export function isCustomerKycInternalPerson(
  kyc: Partial<CustomerKycInternal>
): kyc is Partial<CustomerKycInternalPerson>;
export function isCustomerKycInternalPerson(
  kyc: Partial<CustomerKycInternal>
): kyc is Partial<CustomerKycInternalPerson> {
  return (
    typeof (kyc as CustomerKycInternalPerson)[KycQuestion.EMPLOYMENT] ===
    "undefined"
  );
}

interface CustomerKycInternalCorporation {
  [KycQuestion.DEPOSIT_YEARLY_VALUE]: DepositTypeCorporation | undefined;
  [KycQuestion.MONEY_ORIGIN]: MoneyOrigin<LegalEntityType.CORPORATION>[];
  [KycQuestion.MONEY_BANK_ORIGIN]: BankType[];
}

export function isCustomerKycInternalCorporation(
  kyc: CustomerKycInternal
): kyc is CustomerKycInternalCorporation;
export function isCustomerKycInternalCorporation(
  kyc: Partial<CustomerKycInternal>
): kyc is Partial<CustomerKycInternalCorporation>;
export function isCustomerKycInternalCorporation(
  kyc: Partial<CustomerKycInternal>
): kyc is Partial<CustomerKycInternalCorporation> {
  return (
    typeof (kyc as CustomerKycInternalPerson)[KycQuestion.EMPLOYMENT] ===
    "undefined"
  );
}

export type CustomerKycInternal<T = unknown> = T extends LegalEntityType.PERSON
  ? CustomerKycInternalPerson
  : T extends LegalEntityType.CORPORATION
  ? CustomerKycInternalCorporation
  : CustomerKycInternalPerson | CustomerKycInternalCorporation;

const initialQuestionAnswersPerson: CustomerKycInternalPerson = {
  [KycQuestion.DEPOSIT_YEARLY_VALUE]: undefined,
  [KycQuestion.EMPLOYMENT]: undefined,
  [KycQuestion.MONEY_ORIGIN]: [],
  [KycQuestion.MONEY_BANK_ORIGIN]: [],
};

const initialQuestionAnswersCorporation: CustomerKycInternalCorporation = {
  [KycQuestion.DEPOSIT_YEARLY_VALUE]: undefined,
  [KycQuestion.MONEY_ORIGIN]: [],
  [KycQuestion.MONEY_BANK_ORIGIN]: [],
};

function mapCustomerKycToInternal<T extends LegalEntityType>(
  legalEntityType: T,
  country: LysaCountry,
  questionAnswers: QuestionAnswer[]
): CustomerKycInternal<T> {
  let customerKyc;
  switch (legalEntityType) {
    case LegalEntityType.PERSON: {
      customerKyc = mapCustomerKycPersonToInternal(questionAnswers, country);
      break;
    }
    case LegalEntityType.CORPORATION: {
      customerKyc = mapCustomerKycCorporationToInternal(
        questionAnswers,
        country
      );
      break;
    }
    default: {
      throw new Error("mapCustomerKycToInternal - invalid LegalEntityType");
    }
  }
  return customerKyc as CustomerKycInternal<T>;
}

function mapCustomerKycPersonToInternal(
  questionAnswers: QuestionAnswer[],
  country: LysaCountry
): CustomerKycInternalPerson {
  return questionAnswers.reduce((acc, qa) => {
    switch (qa.question) {
      case KycQuestion.DEPOSIT_YEARLY_VALUE: {
        if (qa.answers.length === 1) {
          const answers = qa.answers
            .map((answer) => answer.toString())
            .filter((answer): answer is DepositType<LegalEntityType.PERSON> =>
              isDeposit(LegalEntityType.PERSON, country, answer)
            );

          acc[KycQuestion.DEPOSIT_YEARLY_VALUE] = answers[0];
        }
        return acc;
      }

      case KycQuestion.EMPLOYMENT: {
        if (qa.answers.length === 1) {
          const answers = qa.answers
            .map((answers) => answers.toString())
            .filter((answer): answer is Employment => isEmployment(answer));

          acc[KycQuestion.EMPLOYMENT] = answers[0];
        }
        return acc;
      }

      case KycQuestion.MONEY_ORIGIN: {
        const answers = qa.answers
          .map((answers) => answers.toString())
          .filter((answer): answer is MoneyOrigin<LegalEntityType.PERSON> =>
            isMoneyOrigin(LegalEntityType.PERSON, answer)
          );

        acc[KycQuestion.MONEY_ORIGIN] = answers;
        return acc;
      }

      case KycQuestion.MONEY_BANK_ORIGIN: {
        const answers = qa.answers
          .map((answers) => answers.toString())
          .filter((answer): answer is BankType =>
            isCountryBank(country, answer)
          );

        acc[KycQuestion.MONEY_BANK_ORIGIN] = answers;
        return acc;
      }

      default: {
        return acc;
      }
    }
  }, initialQuestionAnswersPerson);
}

function mapCustomerKycCorporationToInternal(
  questionAnswers: QuestionAnswer[],
  country: LysaCountry
): CustomerKycInternalCorporation {
  return questionAnswers.reduce((acc, qa) => {
    switch (qa.question) {
      case KycQuestion.DEPOSIT_YEARLY_VALUE: {
        if (qa.answers.length === 1) {
          const answers = qa.answers
            .map((answer) => answer.toString())
            .filter(
              (answer): answer is DepositType<LegalEntityType.CORPORATION> =>
                isDeposit(LegalEntityType.CORPORATION, country, answer)
            );

          acc[KycQuestion.DEPOSIT_YEARLY_VALUE] = answers[0];
        }
        return acc;
      }

      case KycQuestion.MONEY_ORIGIN: {
        const answers = qa.answers
          .map((answers) => answers.toString())
          .filter(
            (answer): answer is MoneyOrigin<LegalEntityType.CORPORATION> =>
              isMoneyOrigin(LegalEntityType.CORPORATION, answer)
          );

        acc[KycQuestion.MONEY_ORIGIN] = answers;
        return acc;
      }

      case KycQuestion.MONEY_BANK_ORIGIN: {
        const answers = qa.answers
          .map((answers) => answers.toString())
          .filter((answer): answer is BankType =>
            isCountryBank(country, answer)
          );

        acc[KycQuestion.MONEY_BANK_ORIGIN] = answers;
        return acc;
      }

      default: {
        return acc;
      }
    }
  }, initialQuestionAnswersCorporation);
}

export function mapCustomerKycToExternal<T extends LegalEntityType>(
  legalEntityType: T,
  customerKyc: CustomerKycInternal<T>
): SaveCustomerKycRequest<T> {
  if (legalEntityType === LegalEntityType.PERSON) {
    return mapCustomerKycPersonToExternal(
      customerKyc as CustomerKycInternal<LegalEntityType.PERSON>
    ) as SaveCustomerKycRequest<T>;
  }

  return mapCustomerKycCorporationToExternal(
    customerKyc as CustomerKycInternal<LegalEntityType.CORPORATION>
  ) as SaveCustomerKycRequest<T>;
}

function mapCustomerKycPersonToExternal(
  customerKyc: CustomerKycInternalPerson
): CustomerKycPerson {
  const DEPOSIT_YEARLY_VALUE = customerKyc[KycQuestion.DEPOSIT_YEARLY_VALUE];
  const EMPLOYMENT = customerKyc[KycQuestion.EMPLOYMENT];

  if (typeof DEPOSIT_YEARLY_VALUE === "undefined") {
    throw new Error(
      "mapCustomerKycPersonToExternal -DEPOSIT_YEARLY_VALUE is undefined"
    );
  }

  if (typeof EMPLOYMENT === "undefined") {
    throw new Error("mapCustomerKycPersonToExternal -EMPLOYMENT is undefined");
  }

  return {
    legalEntityType: LegalEntityType.PERSON,
    customerKyc: {
      version: "3",
      questionAnswers: [
        {
          question: KycQuestion.DEPOSIT_YEARLY_VALUE,
          answers: [DEPOSIT_YEARLY_VALUE],
        },
        {
          question: KycQuestion.EMPLOYMENT,
          answers: [EMPLOYMENT],
        },
        {
          question: KycQuestion.MONEY_ORIGIN,
          answers: customerKyc[KycQuestion.MONEY_ORIGIN],
        },
        {
          question: KycQuestion.MONEY_BANK_ORIGIN,
          answers: customerKyc[KycQuestion.MONEY_BANK_ORIGIN],
        },
      ],
    },
  };
}

function mapCustomerKycCorporationToExternal(
  customerKyc: CustomerKycInternalCorporation
): CustomerKycCorporation {
  const DEPOSIT_YEARLY_VALUE = customerKyc[KycQuestion.DEPOSIT_YEARLY_VALUE];

  if (typeof DEPOSIT_YEARLY_VALUE === "undefined") {
    throw new Error(
      "mapCustomerKycCorporationToExternal - DEPOSIT_YEARLY_VALUE is undefined"
    );
  }

  return {
    legalEntityType: LegalEntityType.CORPORATION,
    customerKyc: {
      version: "3",
      questionAnswers: [
        {
          question: KycQuestion.DEPOSIT_YEARLY_VALUE,
          answers: [DEPOSIT_YEARLY_VALUE],
        },
        {
          question: KycQuestion.MONEY_ORIGIN,
          answers: customerKyc[KycQuestion.MONEY_ORIGIN],
        },
        {
          question: KycQuestion.MONEY_BANK_ORIGIN,
          answers: customerKyc[KycQuestion.MONEY_BANK_ORIGIN],
        },
      ],
    },
  };
}
