import { FunctionComponent, useEffect } from "react";

import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { Docs } from "../components/docs/Docs";
import { useTransfer } from "../TransferContext";
import { Button, Typography } from "@lysaab/ui-2";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { TRANSFER_PENSIONS_URL } from "../TransferPensionsStory";
import { TranslatedText } from "../../../../../components/TranslatedText";

import "./AdvancedInformation.scss";

export const AdvancedInformation: FunctionComponent = () => {
  const intl = useIntl();
  const [transfer] = useTransfer();
  const history = useHistory();

  /**
   * Safe guard in case we loose context. On refresh etc. Send user back to start of story.
   */
  useEffect(() => {
    if (typeof transfer?.moves[0]?.institute === "undefined") {
      history.replace(getNavLink(TRANSFER_PENSIONS_URL));
    }
  }, [history, transfer?.moves]);

  return (
    <article className="transfer-pension-story-advanced-information">
      <Typography type="h2">
        <TranslatedText
          id={"sweden.transfer-pension.advanced-information.header"}
        />
      </Typography>
      <Typography type="body">
        <TranslatedText
          id={"sweden.transfer-pension.advanced-information.ingress"}
        />
      </Typography>
      <Docs informationType="ADVANCED_INFORMATION" />
      <Button
        block
        variant="primary"
        type="button"
        className="button"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.advanced-information.back",
        })}
        onClick={history.goBack}
      />
    </article>
  );
};
