import { useContext, useEffect } from "react";
import * as React from "react";
import { LayoutContext } from "../context/LayoutContext";
import { PageProps } from "./Page";
import cx from "classnames";
import "./Page.scss";

export const PageStripped: React.FC<PageProps> = ({ children, className }) => {
  const layoutContext = useContext(LayoutContext);
  const { setState } = layoutContext;

  useEffect(() => {
    setState({
      pageStripped: true,
    });
    return () => {
      setState({
        pageStripped: false,
      });
    };
  }, [setState]);

  return (
    <main className={cx("page page-stripped", className)}>
      <article>{children}</article>
    </main>
  );
};
