import { Spinner } from "@lysaab/ui-2";
import { useCallback, useEffect, useState, VoidFunctionComponent } from "react";
import { InvestmentAccount, CompoundAccount } from "../../../data/dataAccounts";
import {
  CashTransaction,
  CashTransactionType,
  dataTransactions,
  instanceOfCashTransaction,
  Transaction,
} from "../../../data/dataTransactions";
import { dataUser } from "../../../data/dataUser";
import { FeesExample } from "./feesExample/FeesExample";
import { FeesData } from "./feesData/FeesData";

interface Props {
  account: InvestmentAccount | undefined;
  accounts: CompoundAccount[];
  showAction?: boolean;
}

export const Fees: VoidFunctionComponent<Props> = ({
  account,
  accounts,
  showAction = true,
}) => {
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState<Transaction[]>();

  const load = useCallback(
    (accounts: CompoundAccount[], account: InvestmentAccount) => {
      const filter = { ...dataTransactions.getDefaultFilter() };
      filter.start = dataUser.getCreated(accounts);
      dataTransactions
        .getTransactions(filter)
        .then((data) =>
          data.filter(
            (item): item is CashTransaction =>
              instanceOfCashTransaction(item) &&
              item.accountId === account.accountId &&
              (item.type === CashTransactionType.DEPOSIT ||
                item.type === CashTransactionType.WITHDRAWAL)
          )
        )
        .then(setTransactions)
        .catch(() => {})
        .finally(() => setLoading(false));
    },
    []
  );

  useEffect(() => {
    if (!account) {
      return;
    }
    load(accounts, account);
  }, [account, accounts, load]);

  if (loading) {
    return <Spinner />;
  }

  if (transactions?.length === 0) {
    return <FeesExample account={account} />;
  }

  return <FeesData account={account} showAction={showAction} />;
};
