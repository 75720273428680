import "core-js";
import "intersection-observer";
/**
 * Intl prolyfills. These can be loaded async, but it won't work without alot of work.
 * We would have to block rendering until everything is loaded for browsers which need it
 * to prevent errors while the polyfills are loaded. We would also have to handle potential
 * network errors while loading.
 *
 * As of Chrome 87 almost all these polyfills are loaded for all browsers which also makes this
 * unnecessary
 */
import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-pluralrules/locale-data/sv";
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/en-DK";
import "@formatjs/intl-numberformat/locale-data/en-FI";
import "@formatjs/intl-numberformat/locale-data/en-SE";
import "@formatjs/intl-numberformat/locale-data/sv";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import AppProviders from "./AppProviders";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

Sentry.init({
  dsn: "https://ca962e2cad3a40e88dbef433cca43d1d@o339122.ingest.sentry.io/5494417",
  integrations: [
    new CaptureConsoleIntegration({
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: ["error"],
    }),
  ],
  maxValueLength: 15000,
  enabled: process.env.NODE_ENV === "production",
});

const root = createRoot(document.getElementById("root")!);
root.render(<AppProviders />);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready
    .then((registration) => {
      registration.unregister();
    })
    .catch((error) => {
      console.error(error.message);
    });
}
