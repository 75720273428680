import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { Page, PageHeader } from "../../Page";
import { GridRow } from "../../../components/grid/gridRow/GridRow";
import { GridCol } from "../../../components/grid/gridCol/GridCol";
import { NewIcon, Spinner } from "@lysaab/ui-2";
import { TranslatedText } from "../../../components/TranslatedText";
import { OverviewCard } from "./components/overviewCard/OverviewCard";
import { FeesCard } from "./components/feesCard/FeesCard";
import { InstitutionAllocationCard } from "./components/institutionAllocationCard/InstitutionAllocationCard";
import { SavingsAccountId } from "../../../data/dataAccounts";
import { LoadingContextProvider } from "../../../context/LoadingContext";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { useSharedSavingsAccount } from "../../../hooks/useSharedSavingsAccount";
import { FollowedAccount } from "../FollowedAccount";
import { AccountShareWithMe, dataAttorney } from "../../../data/dataAttorney";
import { MonthlyCard } from "./components/monthlyCard/MonthlyCard";
import { TransactionsCard } from "./components/transactionsCard/TransactionsCard";

import "./SavingsAccountPage.scss";
import "./SharedSavingsAccountPage.scss";
import { CheckForMissingAccountWrapper } from "../CheckForMissingAccountWrapper";
import { InterestCardWrapper } from "./components/interestCardWrapper/InterestCardWrapper";

export const SHARED_SAVINGS_ACCOUNT_PAGE_URL =
  "/shared-savings-account/:accountId";

const InnerSharedSavingsAccountPage = () => {
  const match = useRouteMatch<{ accountId: SavingsAccountId }>({
    path: getNavLink(SHARED_SAVINGS_ACCOUNT_PAGE_URL),
  });
  const { account } = useSharedSavingsAccount(match?.params.accountId);
  const [accountShare, setAccountShare] = useState<AccountShareWithMe>();

  useEffect(() => {
    if (typeof account === "undefined") {
      return;
    }

    dataAttorney
      .getAccountShareWithMeForAccount(account.accountId)
      .then((shares) => shares.length > 0 && setAccountShare(shares[0]));
  }, [account]);

  if (
    typeof account === "undefined" ||
    typeof match?.params.accountId === "undefined"
  ) {
    return <Spinner />;
  }

  return (
    <Page className="savings-account-page shared-savings-account-page">
      <PageHeader>
        <h1 className="savings-account-page__header shared-savings-account-page__header">
          <span className="savings-account-page__header-text">
            {account.name}
          </span>
          <span className="savings-account-page__header-type">
            <TranslatedText id="sharedSavingsAccountPage.header.accountType" />
          </span>
        </h1>
        <div className="shared-savings-account-page__subheader">
          <NewIcon.Accounts className="icon" size={20} />
          <span>
            <TranslatedText
              id="sharedSavingsAccountPage.sharedBy"
              values={{ name: accountShare?.ownerName }}
            />
          </span>
        </div>
      </PageHeader>
      <div className="savings-account-page__overview-wrapper">
        <OverviewCard accountId={account.accountId} />
      </div>

      <div className="shared-savings-account-page__grid">
        <GridRow>
          <GridCol
            xsmall={12}
            medium={4}
            className="savings-account-page__section-wrapper"
          >
            <section className="savings-account-page__section">
              <h2>
                <TranslatedText id="sharedSavingsAccountPage.interestCard.header" />
              </h2>
              <InterestCardWrapper accountId={account.accountId} />
            </section>
          </GridCol>
          <GridCol
            xsmall={12}
            medium={4}
            className="savings-account-page__section-wrapper"
          >
            <section className="savings-account-page__section">
              <h2>
                <TranslatedText id="sharedSavingsAccountPage.institutionAllocationCard.institution" />
              </h2>
              <InstitutionAllocationCard account={account} />
            </section>
          </GridCol>
          <GridCol
            xsmall={12}
            medium={4}
            className="savings-account-page__section-wrapper"
          >
            <section className="savings-account-page__section">
              <h2>
                <TranslatedText id="sharedSavingsAccountPage.transactionsCard.header" />
              </h2>
              <TransactionsCard accountId={account.accountId} />
            </section>
          </GridCol>
          <GridCol
            xsmall={12}
            medium={4}
            className="savings-account-page__section-wrapper"
          >
            <section className="savings-account-page__section">
              <h2>
                <TranslatedText id="sharedSavingsAccountPage.monthlyCard.header" />
              </h2>
              <MonthlyCard accountId={account.accountId} showAction={false} />
            </section>
          </GridCol>
          <GridCol
            xsmall={12}
            medium={4}
            className="savings-account-page__section-wrapper"
          >
            <section className="savings-account-page__section">
              <h2>
                <TranslatedText id="sharedSavingsAccountPage.feesCard.header" />
              </h2>
              <FeesCard accountId={account.accountId} showAction={false} />
            </section>
          </GridCol>
          <GridCol
            xsmall={12}
            medium={4}
            className="savings-account-page__section-wrapper"
          >
            <section className="savings-account-page__section">
              <h2>
                <TranslatedText id="sharedSavingsAccountPage.followedAccount.header" />
              </h2>
              <FollowedAccount accountShare={accountShare} />
            </section>
          </GridCol>
        </GridRow>
      </div>
    </Page>
  );
};

export const SharedSavingsAccountPage = () => {
  return (
    <CheckForMissingAccountWrapper>
      <LoadingContextProvider>
        <InnerSharedSavingsAccountPage />
      </LoadingContextProvider>
    </CheckForMissingAccountWrapper>
  );
};
