import { DocModalLink, NewIcon } from "@lysaab/ui-2";
import { FunctionComponent, useContext } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { LocalizationContext } from "../../context/LocalizationContext";
import { docLinks } from "./DocLinks";
import "./Footer.scss";

const FooterDocModalButton: FunctionComponent<
  React.PropsWithChildren<React.ButtonHTMLAttributes<HTMLButtonElement>>
> = (props) => {
  return (
    <button type="button" className="footer-legal-link-button" {...props} />
  );
};

export function Footer() {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);

  if (!localizationContext.state.country) {
    return null;
  }

  return (
    <footer className="page-footer">
      <div className="footer-legal">
        <ul>
          {docLinks[localizationContext.state.country].map((link) => (
            <li key={link.doc}>
              <DocModalLink
                document={link.doc}
                modalAnnouncement=""
                component={FooterDocModalButton}
              >
                {intl.formatMessage(link.name)}
              </DocModalLink>
            </li>
          ))}
        </ul>

        <div className="footer-social">
          <a
            href="https://www.linkedin.com/company/lysa-ab"
            target="_blank"
            rel="noopener noreferrer"
          >
            <NewIcon.LinkedIn />
          </a>
          <a
            href="https://twitter.com/lysa_ab"
            target="_blank"
            rel="noopener noreferrer"
          >
            <NewIcon.Twitter />
          </a>
          <a
            href="https://www.instagram.com/lysa.se/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <NewIcon.Instagram />
          </a>
          <a
            href="https://www.facebook.com/followlysa"
            target="_blank"
            rel="noopener noreferrer"
          >
            <NewIcon.Facebook />
          </a>
        </div>
      </div>
      <hr />
      <div className="footer-disclaimer">
        <FormattedMessage
          id="footer.address"
          values={{ year: intl.formatDate(new Date(), { year: "numeric" }) }}
        />
        <br />
        <FormattedMessage id="footer.disclaimer" />
      </div>
    </footer>
  );
}
