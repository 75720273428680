import {
  FunctionComponent,
  useRef,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import * as React from "react";
import {
  Form,
  LysaFormRef,
  Card,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Button,
} from "@lysaab/ui-2";
import { FormattedMessage, useIntl } from "react-intl";
import { MonthlyEarningsSlider } from "../../../../pageComponents/economicSituation/economicSituation/person/MonthlyEarningsSlider";
import { MonthlyPaymentsSlider } from "../../../../pageComponents/economicSituation/economicSituation/person/MonthlyPaymentsSlider";
import { LiquidAssetsSlider } from "../../../../pageComponents/economicSituation/economicSituation/person/LiquidAssentsSlider";
import { OtherAssetsSlider } from "../../../../pageComponents/economicSituation/economicSituation/person/OtherAssetsSlider";
import { DebtsSlider } from "../../../../pageComponents/economicSituation/economicSituation/person/DebtsSlider";
import SuitabilityAssessmentUtils from "../../../suitabilityAssessment/SuitabilityAssessmentUtils";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import {
  ContactEmailAddresses,
  ContactPhoneNumbers,
} from "../../../../components/Contact";
import { EventTracker } from "../../../../components/eventTracker/EventTracker";
import { useHistory } from "react-router";
import { TrackerEvent } from "../../../../data/dataCustomerTracking";
import { LysaCountry } from "@lysaab/shared";
import { Reaction } from "../../../../pageComponents/economicSituation/risk/Reaction";
import { Propensity } from "../../../../pageComponents/economicSituation/risk/Propensity";
import { Important } from "../../../../pageComponents/economicSituation/risk/Important";
import {
  importantMessages,
  propensityMessages,
  reactionMessages,
} from "../../../../pageComponents/economicSituation/risk/messages";
import "./SituationPerson.scss";
import {
  isValidEligibilityStatePerson,
  useEligibilityContext,
} from "../../../../context/EligibilityContext";
import { useBadEconomyEventTracking } from "./hooks/useBadEconomyEventTracking";
import { useNavigateToCloseAccount } from "../../hooks/useNavigateToCloseAccount";
import {
  isValidEligibilityPerson,
  EligibilityRiskAnswer,
  dataInvestments,
  getEligibility,
} from "../../../../data/dataInvestments";
import { CloseCustomerScenario } from "../../../../data/dataProfile";

interface Props {
  next: () => void;
}

export const SituationPerson: FunctionComponent<Props> = ({ next }) => {
  const history = useHistory();
  const formRef = useRef<LysaFormRef>();
  const postBadEconomyEvent = useBadEconomyEventTracking();
  const navigateToCloseAccount = useNavigateToCloseAccount();
  const [eligibilityState, setEligibilityState] = useEligibilityContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (history.location.state) {
      setLoading(false);
      return;
    }

    if (isValidEligibilityStatePerson(eligibilityState)) {
      setLoading(false);
      return;
    }

    dataInvestments
      .getEligibility()
      .then((response) => {
        const eligibility = getEligibility(response);
        if (!isValidEligibilityPerson(eligibility)) {
          throw new Error(
            "SituationPerson - LegalEntityType is wrong for this component"
          );
        }

        setEligibilityState({
          showWarning: false,
          hasBadEconomy: false,
          ...eligibility,
        });
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.state, setEligibilityState]);

  const onSubmit = useCallback(() => {
    if (
      formRef.current?.isValid &&
      isValidEligibilityStatePerson(eligibilityState) &&
      SuitabilityAssessmentUtils.isValid(
        eligibilityState.financial?.liquidAssets,
        eligibilityState.financial?.monthlyPayments,
        eligibilityState.financial?.otherAssets,
        eligibilityState.financial?.debts,
        eligibilityState.financial?.monthlyEarnings
      )
    ) {
      EventTracker.track({
        event: TrackerEvent.REVIEW_ECONOMIC_SITUATION,
        message: {
          isBlocked: false,
        },
      });
      next();
    } else {
      setEligibilityState({ showWarning: true });
    }
  }, [eligibilityState, next, setEligibilityState]);

  const onCloseCustomer = useCallback(() => {
    postBadEconomyEvent();
    navigateToCloseAccount(CloseCustomerScenario.ECONOMIC_SITUATION);
  }, [navigateToCloseAccount, postBadEconomyEvent]);

  if (loading) {
    return <Spinner />;
  }

  if (!isValidEligibilityStatePerson(eligibilityState)) {
    console.log("SituationPerson - Not ment for company usage");
    return <Spinner />;
  }

  return (
    <Form lysaFormRef={formRef} onSubmit={onSubmit}>
      <div className="yearly-situation-page">
        <h2>
          <FormattedMessage id="situation.economic" />
        </h2>
        <p>
          <FormattedMessage id="situation.economic.description" />
        </p>

        <section>
          <Card>
            <MonthlyEarningsSlider
              value={eligibilityState.financial.monthlyEarnings}
              setValue={(monthlyEarnings) =>
                setEligibilityState({
                  financial: {
                    ...eligibilityState.financial,
                    monthlyEarnings,
                  },
                })
              }
            />
            <MonthlyPaymentsSlider
              value={eligibilityState.financial.monthlyPayments}
              setValue={(monthlyPayments) =>
                setEligibilityState({
                  financial: {
                    ...eligibilityState.financial,
                    monthlyPayments,
                  },
                })
              }
            />
            <LiquidAssetsSlider
              value={eligibilityState.financial.liquidAssets}
              setValue={(liquidAssets) =>
                setEligibilityState({
                  financial: {
                    ...eligibilityState.financial,
                    liquidAssets,
                  },
                })
              }
            />
            <OtherAssetsSlider
              value={eligibilityState.financial.otherAssets}
              setValue={(otherAssets) =>
                setEligibilityState({
                  financial: {
                    ...eligibilityState.financial,
                    otherAssets,
                  },
                })
              }
            />
            <DebtsSlider
              value={eligibilityState.financial.debts}
              setValue={(debts) =>
                setEligibilityState({
                  financial: {
                    ...eligibilityState.financial,
                    debts,
                  },
                })
              }
            />
          </Card>
        </section>
        {eligibilityState.showWarning && <InsufficientMeansError />}
        <h2>
          <FormattedMessage id="situation.risk" />
        </h2>
        <section>
          <Important
            value={eligibilityState.risk.answers["1"]}
            setValue={(importance) =>
              setEligibilityState({
                risk: {
                  answers: {
                    ...eligibilityState.risk.answers,
                    [EligibilityRiskAnswer.IMPORTANCE]: importance,
                  },
                },
              })
            }
            messages={importantMessages}
          />
          <Reaction
            value={eligibilityState.risk.answers["2"]}
            setValue={(reaction) =>
              setEligibilityState({
                risk: {
                  answers: {
                    ...eligibilityState.risk.answers,
                    [EligibilityRiskAnswer.REACTION]: reaction,
                  },
                },
              })
            }
            messages={reactionMessages}
          />
          <Propensity
            value={eligibilityState.risk.answers["3"]}
            setValue={(propensity) =>
              setEligibilityState({
                risk: {
                  answers: {
                    ...eligibilityState.risk.answers,
                    [EligibilityRiskAnswer.PROPENSITY]: propensity,
                  },
                },
              })
            }
            messages={propensityMessages}
          />
        </section>
        {eligibilityState.hasBadEconomy && eligibilityState.showWarning ? (
          <Button
            block
            variant="negative"
            onClick={onCloseCustomer}
            label={
              <FormattedMessage id="situation.closeLysaCustomerConfirmation" />
            }
          />
        ) : (
          <Button
            block
            type="submit"
            label={<FormattedMessage id="situation.button.next" />}
          />
        )}
      </div>
    </Form>
  );
};

const InsufficientMeansError: React.FC = () => {
  const intl = useIntl();
  const [eligibilityState, setEligibilityState] = useEligibilityContext();
  const localizationContext = useContext(LocalizationContext);

  if (!isValidEligibilityStatePerson(eligibilityState)) {
    return null;
  }

  if (
    SuitabilityAssessmentUtils.hasInsufficientBuffert(
      eligibilityState.financial.liquidAssets,
      eligibilityState.financial.monthlyPayments
    )
  ) {
    if (eligibilityState.hasBadEconomy !== true) {
      setEligibilityState({ hasBadEconomy: true });
    }

    return (
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <div className="error-wrapper">
          <p>
            <FormattedMessage id="situation.insufficient.buffer.text" />
          </p>
          <p>
            <FormattedMessage
              id="situation.insufficient.buffer.contact"
              values={{
                phone: () => {
                  const phoneNumber = intl.formatMessage(
                    ContactPhoneNumbers[
                      localizationContext.state.country || LysaCountry.SWEDEN
                    ]
                  );
                  return <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>;
                },
                email: () => {
                  const emailAddress = intl.formatMessage(
                    ContactEmailAddresses[
                      localizationContext.state.country || LysaCountry.SWEDEN
                    ]
                  );
                  return <a href={`email:${emailAddress}`}>{emailAddress}</a>;
                },
              }}
            />
          </p>
          <p>
            <FormattedMessage id="situation.insufficient.buffer.recheck" />
          </p>
          <span data-elevio-article="109">
            <span className="loner link">
              <FormattedMessage id="situation.insufficient.buffer.elevio" />
            </span>
          </span>
        </div>
      </Snackbar>
    );
  }

  if (
    SuitabilityAssessmentUtils.hasInsufficientMeans(
      eligibilityState.financial.liquidAssets,
      eligibilityState.financial.otherAssets,
      eligibilityState.financial.debts,
      eligibilityState.financial.monthlyEarnings
    )
  ) {
    if (eligibilityState.hasBadEconomy !== true) {
      setEligibilityState({ hasBadEconomy: true });
    }

    return (
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <div className="error-wrapper">
          <p>
            <FormattedMessage id="situation.insufficient.means.text" />
          </p>
          <p>
            <FormattedMessage
              id="situation.insufficient.means.contact"
              values={{
                phone: () => {
                  const phoneNumber = intl.formatMessage(
                    ContactPhoneNumbers[
                      localizationContext.state.country || LysaCountry.SWEDEN
                    ]
                  );
                  return <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>;
                },
                email: () => {
                  const emailAddress = intl.formatMessage(
                    ContactEmailAddresses[
                      localizationContext.state.country || LysaCountry.SWEDEN
                    ]
                  );
                  return <a href={`email:${emailAddress}`}>{emailAddress}</a>;
                },
              }}
            />
          </p>
          <p>
            <FormattedMessage id="situation.insufficient.means.recheck" />
          </p>
          <span data-elevio-article="109">
            <span className="loner link">
              <FormattedMessage id="situation.insufficient.means.elevio" />
            </span>
          </span>
        </div>
      </Snackbar>
    );
  }

  if (
    SuitabilityAssessmentUtils.isRunningOutOfMeans(
      eligibilityState.financial.liquidAssets,
      eligibilityState.financial.monthlyEarnings,
      eligibilityState.financial.monthlyPayments
    )
  ) {
    if (eligibilityState.hasBadEconomy !== true) {
      setEligibilityState({ hasBadEconomy: true });
    }

    return (
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <div className="error-wrapper">
          <p>
            <FormattedMessage id="situation.running.out.text" />
          </p>
          <p>
            <FormattedMessage
              id="situation.running.out.contact"
              values={{
                phone: () => {
                  const phoneNumber = intl.formatMessage(
                    ContactPhoneNumbers[
                      localizationContext.state.country || LysaCountry.SWEDEN
                    ]
                  );
                  return <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>;
                },
                email: () => {
                  const emailAddress = intl.formatMessage(
                    ContactEmailAddresses[
                      localizationContext.state.country || LysaCountry.SWEDEN
                    ]
                  );
                  return <a href={`email:${emailAddress}`}>{emailAddress}</a>;
                },
              }}
            />
          </p>
          <p>
            <FormattedMessage id="situation.running.out.recheck" />
          </p>
          <span data-elevio-article="109">
            <span className="loner link">
              <FormattedMessage id="situation.running.out.elevio" />
            </span>
          </span>
        </div>
      </Snackbar>
    );
  }

  if (eligibilityState.hasBadEconomy !== false) {
    setEligibilityState({ hasBadEconomy: false });
  }
  return null;
};
