import { AsyncScriptLoader } from "./AsyncScriptLoader";

const KLARNA_SCRIPT_URI = `https://x.klarnacdn.net/xs2a/app-launcher/v0/xs2a-app-launcher.js`;

export interface KlarnaError {
  category: string;
  message?: string;
  reason?: Error;
}

export interface KlarnaConfiguration {
  autoClose: boolean;
  hideTransitionOnFlowEnd: boolean;
  onLoad: () => void;
  onReady: () => void;
  onAbort: () => void;
  onError: (error: KlarnaError) => void;
  onFinished: () => void;
  onClose: () => void;
}

declare global {
  interface Window {
    XS2A: {
      options: KlarnaConfiguration;
      startFlow: (
        clientToken: string,
        config?: Partial<KlarnaConfiguration>
      ) => void;
    };
  }
}

export class KlarnaIntegrationClient {
  private scriptPromise: Promise<undefined>;

  constructor() {
    this.scriptPromise = AsyncScriptLoader.load(KLARNA_SCRIPT_URI);
    this.scriptPromise.then(() => {
      this.resetConfig();
    });
  }

  private resetConfig() {
    this.scriptPromise.then(() => {
      window.XS2A.options = {
        autoClose: true,
        hideTransitionOnFlowEnd: true,
        onLoad: () => {},
        onReady: () => {},
        onAbort: () => {},
        onError: () => {},
        onFinished: () => {},
        onClose: () => {},
      };
    });
  }

  public start(
    clientToken: string,
    onAbort: () => void,
    onError: (error: KlarnaError) => void
  ) {
    this.scriptPromise.then(() => {
      window.XS2A.startFlow(clientToken, { onAbort, onError });
    });
  }

  static preLoad() {
    /**
     * A story that knows that it will use the Klarna API should call this in the story
     * boostrap. This will speed up the flow start later
     */
    AsyncScriptLoader.load(KLARNA_SCRIPT_URI);
  }
}
