import * as React from "react";
import { Error } from "./Error";
import "./Retry.scss";
import {
  AnimatePresence,
  AnimationControls,
  motion,
  TargetAndTransition,
  VariantLabels,
} from "framer-motion";
import { Spinner } from "@lysaab/ui-2";

export enum Status {
  PENDING = "is-pending",
  ERROR = "has-error",
  SUCCESS = "",
}

interface Props {
  retry?: () => void;
  text?: React.ReactNode | string;
  buttonText?: React.ReactNode | string;
  children: React.ReactNode | string;
  status: Status;
  childrenAnimationControls?:
    | boolean
    | AnimationControls
    | TargetAndTransition
    | VariantLabels
    | undefined;
}

export const Retry: React.FC<Props> = ({
  status,
  retry,
  text,
  buttonText,
  children,
  childrenAnimationControls = { opacity: status === Status.SUCCESS ? 1 : 0 },
}) => {
  return (
    <div className="retry-wrapper">
      <AnimatePresence>
        {status === Status.PENDING && (
          <motion.div
            transition={{ duration: 0.3 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key={1}
            className="retry-pending"
          >
            <Spinner />
          </motion.div>
        )}
        {status === Status.ERROR && (
          <motion.div
            transition={{ duration: 0.3 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="retry-error"
            key={2}
          >
            <Error retry={retry} text={text} buttonText={buttonText} />
          </motion.div>
        )}
      </AnimatePresence>
      <motion.div
        initial={false}
        animate={childrenAnimationControls}
        className="retry"
      >
        {children}
      </motion.div>
    </div>
  );
};
