import { VoidFunctionComponent } from "react";
import { Page, PageHeader } from "../../pages/Page";
import { CloseAccount } from "./closeAccount/CloseAccount";
import { TranslatedText } from "../../components/TranslatedText";
import { GridCol } from "../../components/grid/gridCol/GridCol";
import { GridRow } from "../../components/grid/gridRow/GridRow";
import { CloseLysaCustomerAccount } from "./closeLysaCustomerAccount/CloseLysaCustomerAccount";

export const END_INVESTMENT_URL = "/end-investment";

export const EndInvestment: VoidFunctionComponent = () => {
  return (
    <Page>
      <PageHeader>
        <h1>
          <TranslatedText id="endInvestment.header" />
        </h1>
      </PageHeader>
      <GridRow>
        <GridCol xsmall={12} medium={6}>
          <CloseAccount />
        </GridCol>
        <GridCol xsmall={12} medium={6}>
          <CloseLysaCustomerAccount />
        </GridCol>
      </GridRow>
    </Page>
  );
};
