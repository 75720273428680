import {
  Button,
  CardList,
  CardListItem,
  encode,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import { useEffect, useState } from "react";
import { FormattedDate } from "react-intl";
import { ClosedAccount, dataProfile } from "../../data/dataProfile";
import { TranslatedText } from "../TranslatedText";

import "./ClosedAccountsData.scss";

export const ClosedAccountsData = () => {
  const [closedAccounts, setClosedAccounts] = useState<ClosedAccount[]>();
  const [error, setError] = useState(false);

  useEffect(() => {
    dataProfile
      .getClosedAccounts()
      .then((data) => {
        setClosedAccounts(data);
      })
      .catch(() => {
        setError(true);
      });
  }, []);

  if (!closedAccounts || closedAccounts.length === 0) return null;
  return (
    <div className="closed-accounts-data">
      <h2>
        <TranslatedText id="yourData.closedAccounts.header" />
      </h2>
      <CardList>
        {error && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="yourData.closedAccounts.error" />
          </Snackbar>
        )}
        {closedAccounts.map((account) => {
          return (
            <CardListItem key={account.accountId}>
              <h3>{account.name}</h3>
              <div className="closed-accounts-data__row">
                <TranslatedText id="yourData.closedAccounts.closed" />
                <span>
                  <FormattedDate value={account.closed} />
                </span>
              </div>
              <div className="closed-accounts-data__row">
                <TranslatedText id="yourData.closedAccounts.transactions" />
                <Button
                  component="a"
                  variant="secondary"
                  size="small"
                  inline
                  href={
                    process.env.REACT_APP_API +
                    encode`/gdpr-export/closed-account/transactions/${account.accountId}`
                  }
                  download
                  label={
                    <TranslatedText id="yourData.closedAccounts.download" />
                  }
                />
              </div>
            </CardListItem>
          );
        })}
      </CardList>
    </div>
  );
};
