import { FunctionComponent } from "react";
import * as React from "react";
import { AnimatePresence, motion } from "framer-motion";

interface Props {
  isOpen: boolean;
  id?: string;
  className?: string;
}

export const AnimateHeight: FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ children, isOpen, id, className }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ height: 0, overflow: "hidden" }}
          animate={{ height: "auto" }}
          exit={{ height: 0 }}
          transition={{ duration: 0.5 }}
          id={id}
          className={className}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
