import * as React from "react";
import { AccountName } from "../accountName/AccountName";
import { Amount } from "../amount/Amount";
import { CompoundAccount, isSavingsAccount } from "../../data/dataAccounts";
import { dataPerformance } from "../../data/dataPerformance";
import "./InternalTransferItem.scss";
import { TranslatedText } from "../TranslatedText";

interface Props {
  fromAccount: CompoundAccount;
  toAccount: CompoundAccount;
  amount?: string;
  date?: Date;
}

export const TransferItem: React.FunctionComponent<Props> = ({
  fromAccount,
  toAccount,
  amount = "0",
  date = new Date(),
}) => {
  return (
    <div className="internal-transfer-item">
      <AccountName
        account={fromAccount}
        showRisk
        digits={2}
        showSavingsAccountIcon={isSavingsAccount(fromAccount)}
      />
      <div className="amount-tag">
        <div>
          <Amount amount={parseFloat(amount || "0")} decimals={2} />
        </div>
      </div>
      <div className="arrow-wrapper">
        <span>
          <TranslatedText
            id="internal.withdrawal-item.from"
            description="Withdrawal item - from"
            defaultMessage="From"
          />
        </span>
        <div className="transaction-arrow">
          <div className="arrow-start" />
          <div className="arrow-body" />
          <svg
            className="arrow-top"
            height="20px"
            width="20px"
            viewBox="0 0 20 20"
          >
            <path d="M10 5 L20 10 L10 15 Z" />
          </svg>
        </div>
        <span>
          <TranslatedText
            id="internal.withdrawal-item.to"
            description="Withdrawal item - to"
            defaultMessage="To"
          />
        </span>
      </div>
      <div className="internal-transfer-item-to">
        <div>{dataPerformance.toStringDate(date)}</div>
        <div>
          <AccountName
            account={toAccount}
            showRisk
            digits={2}
            showSavingsAccountIcon={isSavingsAccount(toAccount)}
          />
        </div>
      </div>
    </div>
  );
};
