import { Button } from "@lysaab/ui-2";
import { FunctionComponent } from "react";

import "./ReadMoreButton.scss";

interface Props {
  text: React.ReactNode[];
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const ReadMoreButton: FunctionComponent<Props> = ({ text, onClick }) => {
  return (
    <Button
      inline
      variant="outlined"
      size="small"
      label={text}
      className="move-card-read-more-button"
      onClick={onClick}
    />
  );
};
