import * as React from "react";
import { Amount } from "../../components/amount/Amount";
import { dataBanks } from "../../data/dataBanks";
import { BankLogo } from "../../components/bankLogo/BankLogo";
import { FormattedMessage } from "react-intl";
import "./MonthlyEntry.scss";
import {
  isMonthlyPaymentWithBankInfo,
  MonthlyPayment,
} from "../../data/dataMonthlyPayments";

interface Props {
  monthly: MonthlyPayment;
}

export const MonthlyEntry: React.VoidFunctionComponent<Props> = ({
  monthly,
}) => {
  const bank = isMonthlyPaymentWithBankInfo(monthly)
    ? dataBanks.getBank(monthly.bank)
    : undefined;

  return (
    <div className="monthly-entry">
      <div className="flex">
        {bank && (
          <>
            <strong>{bank.short}</strong>
            <BankLogo bank={bank} />
          </>
        )}
      </div>
      <dl>
        {isMonthlyPaymentWithBankInfo(monthly) && monthly.bank !== "MOBILEPAY" && (
          <React.Fragment>
            <dt>
              <FormattedMessage
                id="accountPage.monthly.entry.external"
                description="Account page monthly entry - external account"
                defaultMessage="External account"
              />
              :
            </dt>
            <dd>{monthly.externalBankAccount}</dd>
          </React.Fragment>
        )}

        <dt>
          <FormattedMessage
            id="accountPage.monthly.entry.amount"
            description="Account page monthly entry - amount"
            defaultMessage="Amount"
          />
          :
        </dt>
        <dd>
          <Amount amount={monthly.amount} />{" "}
          <span className="monthly-ext">
            /{" "}
            <FormattedMessage
              id="accountPage.monthly.entry.period"
              description="Account page monthly entry - period"
              defaultMessage="month"
            />
          </span>
        </dd>
        <dt>
          <FormattedMessage
            id="accountPage.monthly.entry.nextDeduction"
            description="Account page monthly entry - next deduction"
            defaultMessage="Next deduction"
          />
          :
        </dt>
        <dd>{monthly.nextDeduction}</dd>
      </dl>
    </div>
  );
};
