import { VoidFunctionComponent } from "react";
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from "react-intl";
import { Amount } from "../../../../../../../components/amount/Amount";
import { getFundName } from "../../../../../../../components/fundName/FundName";
import { GridCol } from "../../../../../../../components/grid/gridCol/GridCol";
import { FundTransaction } from "../../../../../../../data/dataCorporate";
import { DataDisplay } from "../../../../../../../components/dataDisplay/DataDisplay";
import { fundTypesMessages } from "../TransactionUtils";

export const FundCorrection: VoidFunctionComponent<{
  transaction: FundTransaction;
}> = ({ transaction }) => {
  const intl = useIntl();
  return (
    <>
      <GridCol xsmall={2} className="text-container">
        <DataDisplay
          type="list-item"
          title={
            <FormattedMessage id="sweden.accountingPage.dataDisplay.fundCorrection.title.date" />
          }
          text={<FormattedDate value={transaction.booked} />}
        />
      </GridCol>

      <GridCol xsmall={5} className="text-container">
        <DataDisplay
          type="list-item"
          title={
            <FormattedMessage id="sweden.accountingPage.dataDisplay.fundCorrection.title.holding" />
          }
          text={`${getFundName(transaction.fund, intl)} (${transaction.fund})`}
        />
      </GridCol>

      <GridCol xsmall={3} className="text-container text-right">
        <DataDisplay
          type="list-item"
          title={
            <FormattedMessage id="sweden.accountingPage.dataDisplay.fundCorrection.title.worth" />
          }
          text={<Amount amount={transaction.worth} decimals={2} />}
        />
      </GridCol>

      <GridCol xsmall={2} className="text-container text-right">
        <DataDisplay
          type="list-item"
          title={
            <FormattedMessage id="sweden.accountingPage.dataDisplay.fundCorrection.title.type" />
          }
          text={intl.formatMessage(fundTypesMessages[transaction.type])}
        />
      </GridCol>

      <GridCol xsmall={8} className="text-container" />

      <GridCol xsmall={2} className="text-container text-right">
        <DataDisplay
          type="list-item"
          title={
            <FormattedMessage id="sweden.accountingPage.dataDisplay.fundCorrection.title.nav" />
          }
          text={
            <FormattedNumber
              value={transaction.price}
              minimumFractionDigits={4}
              maximumFractionDigits={4}
            />
          }
        />
      </GridCol>

      <GridCol xsmall={2} className="text-container text-right">
        <DataDisplay
          type="list-item"
          title={
            <FormattedMessage id="sweden.accountingPage.dataDisplay.fundCorrection.title.shares" />
          }
          text={
            <FormattedNumber
              value={transaction.volume}
              minimumFractionDigits={4}
              maximumFractionDigits={4}
            />
          }
        />
      </GridCol>
    </>
  );
};
