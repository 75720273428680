import * as React from "react";
import { Button } from "@lysaab/ui-2";
import { RouteAwareToggle } from "../../../../../components/route/RouteAwareToggle";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { Link } from "react-router-dom";
import { GridRow } from "../../../../../components/grid/gridRow/GridRow";
import { GridCol } from "../../../../../components/grid/gridCol/GridCol";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { DEPOSITS_OVERVIEW_URL } from "../../../../deposits/overview/Recommendation";
import { SavingsAccountId } from "../../../../../data/dataAccounts";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
} from "../../../../../data/dataCustomerTracking";
import { INTERNAL_TRANSFER_PAGE_URL } from "../../../../internalTransfer/InternalTransferPage";
import { useAccounts } from "../../../../../hooks/useAccounts";
import { WITHDRAWALS_REQUEST_PAGE } from "../../../../withdrawal/request/WithdrawalStory";
import "./AccountActions.scss";

interface Props {
  accountId: SavingsAccountId;
}

export const AccountActions: React.VFC<Props> = ({ accountId }) => {
  const { accounts } = useAccounts();

  if (typeof accounts === "undefined") {
    return null;
  }
  return (
    <GridRow className="account-actions">
      <RouteAwareToggle path={getNavLink(DEPOSITS_OVERVIEW_URL)}>
        <GridCol xsmall={12} medium={4} className="account-actions__button">
          <Button
            component={Link}
            to={{
              pathname: getNavLink(DEPOSITS_OVERVIEW_URL),
              state: { accountId },
            }}
            onClick={() =>
              dataCustomerTrackingService.postEvent({
                domain: FeatureDomain.SAVINGS_ACCOUNT,
                eventName: "clickedAccountDepositButton",
                subDomain: SubDomain.ACCOUNT_DETAILS,
              })
            }
            block
            icon="Deposit"
            label={
              <TranslatedText id="savingsAccountPage.accountActions.deposits" />
            }
          />
        </GridCol>
      </RouteAwareToggle>
      {[...accounts.investmentAccounts, ...accounts.savingsAccounts].length >
      1 ? (
        <RouteAwareToggle path={getNavLink(INTERNAL_TRANSFER_PAGE_URL)}>
          <GridCol xsmall={12} medium={4} className="account-actions__button">
            <Button
              component={Link}
              block
              to={{
                pathname: getNavLink(INTERNAL_TRANSFER_PAGE_URL),
                state: { accountId },
              }}
              variant="secondary"
              icon="Transfer"
              label={
                <TranslatedText id="savingsAccountPage.accountActions.internalTransfer" />
              }
            />
          </GridCol>
        </RouteAwareToggle>
      ) : null}
      <RouteAwareToggle path={getNavLink(WITHDRAWALS_REQUEST_PAGE)}>
        <GridCol xsmall={12} medium={4} className="account-actions__button">
          <Button
            component={Link}
            to={{
              pathname: getNavLink(WITHDRAWALS_REQUEST_PAGE),
              state: { accountId },
            }}
            onClick={() =>
              dataCustomerTrackingService.postEvent({
                domain: FeatureDomain.SAVINGS_ACCOUNT,
                eventName: "clickedAccountWithdrawalButton",
                subDomain: SubDomain.ACCOUNT_DETAILS,
              })
            }
            variant="secondary"
            block
            icon="Withdraw"
            label={
              <TranslatedText id="savingsAccountPage.accountActions.withdrawals" />
            }
          />
        </GridCol>
      </RouteAwareToggle>
    </GridRow>
  );
};
