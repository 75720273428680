import { API } from "@lysaab/ui-2/network/API";
export interface TrackRequest {
  seq: number;
  uri: string;
  event?: TrackerEvent;
  message?: string;
}

export enum TrackerEvent {
  DEPOSIT = "DEPOSIT",
  WITHDRAWAL = "WITHDRAWAL",
  DELETE_WITHDRAWAL = "DELETE_WITHDRAWAL",
  USER_AGENT = "USER_AGENT",
  CLIENT_INFO = "CLIENT_INFO",
  SWITCH_ENTITY = "SWITCH_ENTITY",
  CHANGE_ALLOCATION = "CHANGE_ALLOCATION",
  INTERNAL_TRANSFER = "INTERNAL_TRANSFER",
  PAUSE_MONTHLY = "PAUSE_MONTHLY",
  RESET_MONTHLY = "RESET_MONTHLY",
  ADD_MONTHLY = "ADD_MONTHLY",
  ADD_MONTHLY_ACCOUNT_CHOICE = "ADD_MONTHLY_ACCOUNT_CHOICE",
  ADD_MONTHLY_EXTERNAL_ACCOUNT = "ADD_MONTHLY_EXTERNAL_ACCOUNT",
  ADD_MONTHLY_KLARNA_BANK = "ADD_MONTHLY_KLARNA_BANK",
  ADD_MONTHLY_KLARNA_ACCOUNT = "ADD_MONTHLY_KLARNA_ACCOUNT",
  ADD_MONTHLY_TINK_BANK = "ADD_MONTHLY_TINK_BANK",
  UPDATE_MONTHLY = "UPDATE_MONTHLY",
  DELETE_MONTHLY = "DELETE_MONTHLY",
  BANK_SELECTION = "BANK_SELECTION",
  ACCOUNT_NAME_CHANGE = "ACCOUNT_NAME_CHANGE",
  ERROR = "ERROR",
  RETRY = "RETRY",
  INITIATE_SWISH = "INITIATE_SWISH",
  DEPOSIT_KLARNA = "DEPOSIT_KLARNA",
  LOADED_ACCOUNTS = "LOADED_ACCOUNTS",
  PAGE = "PAGE",
  YEARLY_REVIEW_DIALOG = "YEARLY_REVIEW_DIALOG",
  YEARLY_REVIEW_EMAIL = "YEARLY_REVIEW_EMAIL",
  YEARLY_REVIEW_VERIFY_EMAIL = "YEARLY_REVIEW_VERIFY_EMAIL",
  YEARLY_REVIEW_FATCA = "YEARLY_REVIEW_FATCA",
  YEARLY_REVIEW_CRS = "YEARLY_REVIEW_CRS",
  YEARLY_REVIEW_CUSTOMER_KYC = "YEARLY_REVIEW_CUSTOMER_KYC",
  YEARLY_REVIEW_ACCOUNTS_KYC = "YEARLY_REVIEW_ACCOUNTS_KYC",
  YEARLY_REVIEW_PEP_EDIT = "YEARLY_REVIEW_PEP_EDIT",
  YEARLY_REVIEW_PEP_SAVE = "YEARLY_REVIEW_PEP_SAVE",
  REVIEW_ECONOMIC_SITUATION = "REVIEW_ECONOMIC_SITUATION",
  REVIEW_ACCOUNT_RISK = "REVIEW_ACCOUNT_RISK",
  YEARLY_REVIEW_DONE = "YEARLY_REVIEW_DONE",
  NETS_FINALIZING = "NETS_FINALIZING",
  NETS_RESTART = "NETS_RESTART",
  NETS_NO_POPUP = "NETS_NO_POPUP",
  NETS_POPUP_CREATED = "NETS_POPUP_CREATED",
  NETS_POPUP_OPENED = "NETS_POPUP_OPENED",
  NETS_PRE_SELECT = "NETS_PRE_SELECT",
  NETS_SELECT_PROVIDER = "NETS_SELECT_PROVIDER",
  NETS_ABORT = "NETS_ABORT",
  NETS_SHOWING_EIDENT = "NETS_SHOWING_EIDENT",
  YEARLY_REVIEW_CITIZENSHIP_SAVE = "YEARLY_REVIEW_CITIZENSHIP_SAVE",
  ADD_MONTHLY_TINK_ACCOUNT = "ADD_MONTHLY_TINK_ACCOUNT",
}

export enum FeatureDomain {
  SAVINGS_ACCOUNT = "Savings Account",
  INVESTMENT_ACCOUNT = "Investment Account",
  TRANSFERS = "Transfers",
  GENERAL = "General",
}

export enum SubDomain {
  OVERVIEW = "Overview",
  CREATE_ACCOUNT = "Create Account",
  ACCOUNT_DETAILS = "Account Details",
  TRANSFERS = "Transfers",
  INTERNAL_TRANSFERS = "Internal Transfers",
  DEPOSIT = "Deposit",
  WITHDRAWAL = "Withdrawal",
  FEATURE_TOGGLE = "Feature Toggle",
}
export interface RequestData {
  domain: FeatureDomain;
  subDomain: SubDomain;
  eventName: string;
  payload?: { [k: string]: string };
}

export const dataCustomerTrackingService = {
  sendTracking: (queue: TrackRequest[]) => {
    return API.post("/cts", queue);
  },

  postEvent: (requestData: RequestData) => {
    return API.post("/cts/feature-event", requestData).catch(() =>
      console.log("Failed to send events")
    );
  },
};
