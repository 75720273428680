import { matchPath, useLocation } from "react-router";
import { getNavLink } from "./useCountryUrls";

export function useStoryValues<T extends Record<string, string>>(
  baseRoutes: T
): [number, T, number, number] {
  const location = useLocation();

  const routes = Object.entries(baseRoutes).reduce(
    (ROUTES, [key, path]) => ({ ...ROUTES, [key]: getNavLink(path) }),
    {} as T
  );

  const currentIndex = Object.values(routes).findIndex((path) => {
    return (
      matchPath(location.pathname, {
        path,
        exact: true,
      }) !== null
    );
  });

  const progress =
    (100 / Object.values(baseRoutes).length) * (currentIndex + 1);

  const length = Object.values(baseRoutes).length;

  return [currentIndex, routes, progress, length];
}
