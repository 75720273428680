import React, {
  useContext,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { KfMigrationContext } from "./KfMigrationContext";
import {
  BankIDStatus,
  FailedHintCode,
  OrderRef,
  PendingHintCode,
  useBankId,
  useDisablePollInBackground,
} from "@lysaab/ui-2";
import { dataDanica } from "../../../../../../data/dataDanica";
import { parse, stringify } from "query-string";
import { Location } from "history";
import { IntlShape, useIntl } from "react-intl";
import {
  signFailedMessages,
  signingMessages,
  signPendingMessages,
} from "./BankIdMessages";

interface Params {
  signingId: string;
  userId: string;
}

interface SearchParams {
  orderRef?: string;
  at?: string;
  signingId?: string;
  id?: string;
  t?: string;
  userId?: string;
}

interface Props {
  navigateToSigning: (signingId: string) => void;
}

export const Sign: React.FunctionComponent<Props> = ({ navigateToSigning }) => {
  const intl = useIntl();
  const { params } = useRouteMatch<Params>();
  const { signingId, userId } = params;
  const { setState: setKfWithdrawalState } = useContext(KfMigrationContext);
  const history = useHistory();
  const location = useLocation();
  const { orderRef, autoStartToken } = getParamsFromUrl(location);
  const latestQrCode = useRef<string | undefined>(undefined);

  const onComplete = useCallback(
    (_response: any, reset: any) => {
      setKfWithdrawalState({
        signResponse: undefined,
      });
      navigateToSigning(signingId);
      reset();
    },
    [navigateToSigning, setKfWithdrawalState, signingId]
  );

  const qrCodePollFn = useCallback(
    () =>
      latestQrCode.current
        ? Promise.resolve({ code: latestQrCode.current })
        : Promise.resolve(undefined),
    []
  );

  const pollFnRaw = useMemo(() => {
    return signingId && orderRef
      ? () => {
          return dataDanica
            .kfMigrationPoll(signingId, orderRef)
            .then((response) => {
              console.log("poll response", response);
              // Futur includes QR code in the polling response, so we save it
              // to a ref and let the qrCodePollFn read from there to be able
              // to reuse useBankId without changes
              latestQrCode.current = response.qrCode;
              return response;
            });
        }
      : undefined;
  }, [signingId, orderRef]);

  const pollFn = useDisablePollInBackground(pollFnRaw);

  const {
    pollStatus,
    initiate,
    qrCode,
    latestResponse,
    setOpenOnOtherDevice,
    reset,
  } = useBankId({
    onComplete,
    initPollFn: dataDanica.kfMigrationSign,
    pollFn,
    qrCodePollFn,
  });

  const init = useCallback(
    (signingId: any, userId: any) => {
      if (pollStatus === "IDLE" || pollStatus === "FAILED") {
        initiate(signingId, userId).then((response) => {
          if (response) {
            history.push(
              getUrlWithParams(
                location,
                response.orderRef,
                response.autoStartToken
              )
            );
          }
        });
      }
    },
    [history, initiate, location, pollStatus]
  );

  useEffect(() => {
    if (signingId && userId && !orderRef && pollStatus === "IDLE") {
      init(signingId, userId);
    }
  }, [orderRef, pollStatus, signingId, init, userId]);

  console.log("qrCode: ", qrCode);

  return (
    <BankIDStatus
      qrCode={qrCode}
      setOpenOnOtherDevice={setOpenOnOtherDevice}
      getMessages={getMessages(intl)}
      retry={() => {
        init(signingId, userId);
      }}
      getPendingMessages={getPendingMessages(intl)}
      getFailedMessages={getFailedMessages(intl)}
      reset={() => {
        history.goBack();
        reset();
      }}
      response={latestResponse}
      autoStartToken={autoStartToken}
    />
  );
};

function getMessages(intl: IntlShape) {
  return () => {
    return {
      qrInfo1: intl.formatMessage(signingMessages.qrInfo1),
      qrInfo2: intl.formatMessage(signingMessages.qrInfo2),
      qrInfo3: intl.formatMessage(signingMessages.qrInfo3),
      buttonOpen: intl.formatMessage(signingMessages.buttonOpen),
      buttonErrorHeader: intl.formatMessage(signingMessages.buttonErrorHeader),
      buttonRetry: intl.formatMessage(signingMessages.buttonRetry),
      buttonClose: intl.formatMessage(signingMessages.buttonClose),
      buttonOtherDevice: intl.formatMessage(signingMessages.buttonOtherDevice),
    };
  };
}

function getPendingMessages(intl: IntlShape) {
  return (hintCode: PendingHintCode) =>
    intl.formatMessage(signPendingMessages[hintCode]);
}

function getFailedMessages(intl: IntlShape) {
  return (hintCode: FailedHintCode) =>
    intl.formatMessage(signFailedMessages[hintCode]);
}

function getUrlWithParams(
  location: Location,
  orderRef: OrderRef | undefined,
  autoStartToken: string
) {
  const search = parse(location.search) as SearchParams;
  search.orderRef = orderRef;
  search.at = autoStartToken;

  return {
    pathname: location.pathname,
    search: stringify(search as Record<string, any>, { skipEmptyString: true }),
  };
}

function getParamsFromUrl(location: Location) {
  const search = parse(location.search) as SearchParams;
  return {
    orderRef: search.orderRef,
    autoStartToken: search.at,
  };
}
