import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { FunctionComponent, PropsWithChildren } from "react";

// You might want to read the documentation on important defaults:
// https://tanstack.com/query/latest/docs/framework/react/guides/important-defaults
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: 0,
    },
  },
});

export const ReactQueryProvider: FunctionComponent<
  PropsWithChildren<unknown>
> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};
