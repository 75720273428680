import { FunctionComponent } from "react";
import * as React from "react";
import cx from "classnames";
import "./StaticInput.scss";

interface Props {
  label: string;
  /** Used if children doesn't exist */
  value?: string;
  /** Prioritized before value */
  children?: React.ReactNode;
  sideBySide?: boolean;
}

export const StaticInput: FunctionComponent<Props> = ({
  label,
  value,
  children,
  sideBySide,
}) => {
  return (
    <div
      className={cx("static-input", {
        "static-input--side-by-side": sideBySide,
      })}
    >
      <span className="static-label">{label}</span>
      <span className="static-value">{children || value || "-"}</span>
    </div>
  );
};
