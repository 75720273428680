import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  VoidFunctionComponent,
} from "react";
import {
  Spinner,
  Card,
  LysaFormRef,
  Form,
  RadioGroup,
  RequiredValidator,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import {
  ExternalAccount,
  dataWithdrawals,
  getBankText,
} from "../../../../data/dataWithdrawals";
import {
  WithdrawalPeriodicContext,
  WithdrawalPeriodicState,
} from "../WithdrawalPeriodicContext";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import { AddKlarnaAccountLocationState } from "../../addAccountKlarna/AddAccountKlarnaStory";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { WITHDRAWALS_PERIODIC_STORY_URL } from "../WithdrawalPeriodicStory";
import { useSafeNavigation } from "../../../../hooks/useSafeNavigation";

const messages = defineMessages({
  selectAccount: {
    id: "withdrawalPeriodic.externalAccounts.select.header",
  },
  selectAccountErrorRequired: {
    id: "withdrawalPeriodic.externalAccounts.select.error.required",
  },
});

interface Props {
  addWithdrawalAccountUrl?: string;
  next: () => void;
}

export const ExternalAccounts: VoidFunctionComponent<Props> = ({
  addWithdrawalAccountUrl,
  next,
}) => {
  const intl = useIntl();
  const formRef = useRef<LysaFormRef>();
  const location = useLocation<{ returnState: WithdrawalPeriodicState }>();
  const safeNavigation = useSafeNavigation();
  const withdrawalPeriodicContext = useContext(WithdrawalPeriodicContext);

  const [loading, setLoading] = useState(true);
  const [externalAccounts, setExternalAccounts] = useState<ExternalAccount[]>();

  const onSubmit = useCallback(() => {
    if (
      !formRef.current?.isValid ||
      !withdrawalPeriodicContext.state.externalAccount
    ) {
      return;
    }
    next();
  }, [next, withdrawalPeriodicContext.state.externalAccount]);

  useEffect(() => {
    dataWithdrawals
      .getExternalAccounts()
      .then(setExternalAccounts)
      .catch(() => {})
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!withdrawalPeriodicContext.state.lysaAccount) {
      if (location.state) {
        withdrawalPeriodicContext.setState(location.state.returnState);
      } else {
        safeNavigation(getNavLink(WITHDRAWALS_PERIODIC_STORY_URL));
      }
    }
  }, [location.state, safeNavigation, withdrawalPeriodicContext]);

  const alternatives =
    externalAccounts?.map((externalAccount) => {
      return {
        text: getBankText(externalAccount),
        value: externalAccount.externalBankAccount,
      };
    }) ?? [];

  if (loading) {
    return <Spinner />;
  }

  return (
    <Form lysaFormRef={formRef} onSubmit={onSubmit}>
      <h2>
        <FormattedMessage id="withdrawalPeriodic.externalAccounts.header" />
      </h2>
      <Card>
        {alternatives.length === 0 ? (
          <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
            <FormattedMessage id="withdrawalPeriodic.externalAccounts.no.accounts" />
          </Snackbar>
        ) : (
          <RadioGroup
            header={intl.formatMessage(messages.selectAccount)}
            onChange={(alternative) => {
              const externalAccount = (externalAccounts || []).find(
                (externalAccount) =>
                  externalAccount.externalBankAccount === alternative.value
              );
              if (externalAccount) {
                withdrawalPeriodicContext.setState({ externalAccount });
              }
            }}
            value={alternatives.find(
              (alternative) =>
                alternative.value ===
                  withdrawalPeriodicContext.state.externalAccount
                    ?.externalBankAccount ?? ""
            )}
            alternatives={alternatives}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.selectAccountErrorRequired)
              ),
            ]}
          />
        )}
        {addWithdrawalAccountUrl && (
          <Link<AddKlarnaAccountLocationState>
            to={{
              pathname: addWithdrawalAccountUrl,
              state: {
                returnUrl: location.pathname,
                returnState: withdrawalPeriodicContext.state,
              },
            }}
          >
            <b>
              <FormattedMessage id="withdrawalPeriodic.externalAccounts.add" />
            </b>
          </Link>
        )}
      </Card>
      <Button
        block
        type="submit"
        label={
          <FormattedMessage id="withdrawalPeriodic.externalAccounts.next" />
        }
      />
    </Form>
  );
};
