import { GridCol } from "../../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../../components/grid/gridRow/GridRow";
import { TranslatedText } from "../../../../components/TranslatedText";
import { CitizenshipInformation } from "../../../../pages/crs/citizenshipInformation/CitizenshipInformation";
import { Page, PageHeader } from "../../../../pages/Page";
import { CurrentCrsInformation } from "./currentCrsInformation/CurrentCrsInformation";

export const CrsPage = () => (
  <Page>
    <PageHeader>
      <h1>
        <TranslatedText id="sweden.crs.header" />
      </h1>
    </PageHeader>
    <GridRow>
      <GridCol xsmall={12} large={6}>
        <CurrentCrsInformation />
      </GridCol>
      <GridCol xsmall={12} large={6}>
        <CitizenshipInformation />
      </GridCol>
    </GridRow>
  </Page>
);
