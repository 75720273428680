import { CardList } from "@lysaab/ui-2";
import { FunctionComponent, useEffect, useState } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { InsuredResponse, TaxResponse, dataLife } from "../../data/dataLife";
import {
  AccountType,
  CompoundAccount,
  isInvestmentAccount,
} from "../../data/dataAccounts";
import { Amount } from "../../components/amount/Amount";
import "./InsuranceInformation.scss";

interface Props {
  account: CompoundAccount | undefined;
}

export const InsuranceInformation: FunctionComponent<Props> = ({ account }) => {
  const [insured, setInsured] = useState<InsuredResponse>();
  const [tax, setTax] = useState<TaxResponse>();

  useEffect(() => {
    setInsured(undefined);
    setTax(undefined);

    if (
      !account ||
      !(isInvestmentAccount(account) && account.type === AccountType.KF_SWE)
    ) {
      return;
    }

    dataLife.getInsured(account.accountId).then(setInsured);
    dataLife.getTax(account.accountId).then(setTax);
  }, [account]);

  if (
    !insured ||
    !tax ||
    !account ||
    !(isInvestmentAccount(account) && account.type === AccountType.KF_SWE)
  ) {
    return null;
  }

  return (
    <section className="account-page-insurance-info">
      <h2>
        <FormattedMessage id="accountPage.insurance-info.header" />
      </h2>
      <CardList>
        <div className="account-page-padder">
          <div className="account-page-card-body">
            <strong className="account-page-card-subheader">
              <FormattedMessage id="accountPage.insurance-info.tax.header" />{" "}
              <FormattedDate value={new Date()} year="numeric" />
            </strong>
            <dl>
              <dt>
                <FormattedMessage id="accountPage.insurance-info.tax.paid" />
              </dt>
              <dd>
                <Amount amount={Number(tax.paid)} decimals={0} />
              </dd>
              <dt>
                <FormattedMessage id="accountPage.insurance-info.tax.accumulated" />
              </dt>
              <dd>
                <Amount amount={Number(tax.accumulated)} decimals={0} />
              </dd>
            </dl>
            <strong className="account-page-card-subheader">
              <FormattedMessage id="accountPage.insurance-info.insured.header" />
            </strong>
            <dl>
              <dt>{insured.name}</dt>
              <dd>{insured.tin}</dd>
            </dl>
          </div>
        </div>
      </CardList>
    </section>
  );
};
