import { FunctionComponent } from "react";

import { useIntl } from "react-intl";
import { Ingress } from "../components/ingress/Ingress";
import { Button, NewIcon, Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../components/TranslatedText";

import "./GroupIntroFindPensions.scss";

interface Props {
  next: () => void;
}

export const GroupIntroFindPensions: FunctionComponent<Props> = ({ next }) => {
  const intl = useIntl();

  return (
    <section className="transfer-pension-group-intro-find">
      <header>
        <NewIcon.Search
          size={96}
          primaryColor="var(--lysa-icon-secondary)"
          className="icon"
        />
        <Typography type="display-small">
          <TranslatedText id="sweden.transfer-pension.group-intro.find.header" />
        </Typography>
        <Ingress>
          <TranslatedText id="sweden.transfer-pension.group-intro.find.ingress" />
        </Ingress>
      </header>
      <Button
        type="button"
        variant="primary"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.group-intro.find.button",
        })}
        block
        onClick={next}
      />
    </section>
  );
};
