import { useState, useCallback } from "react";
import * as React from "react";
import { Icon } from "@lysaab/ui-2";
import { OtpStep } from "./OtpStep";
import { FormattedMessage } from "react-intl";
import { PasswordStep } from "./PasswordStep";
import { LoginFooter } from "./LoginFooter";
import "./LoginUsernamePasswordPage.scss";

export const LOGIN_USERNAME_PASSWORD_PAGE_URL_OLD = "/login-tin-password";
export const LOGIN_USERNAME_PASSWORD_PAGE_URL = "/login";

export enum OtpType {
  EMAIL = "EMAIL",
  TOTP = "TOTP",
}

interface Props {
  allowUsernameReminder?: boolean;
}

export const LoginUsernamePasswordPage: React.VFC<Props> = ({
  allowUsernameReminder = false,
}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState<string>("");
  const [otpType, setOtpType] = useState<OtpType>();

  const reset = useCallback(() => {
    setOtp("");
    setUsername("");
    setPassword("");
    setOtpType(undefined);
  }, []);

  return (
    <div className="login-username-password-page">
      <header className="login-username-password-page-header">
        <Icon.Logo />
      </header>
      <div className="center-wrapper">
        <h1>
          <FormattedMessage id="login-username-password.header" />
        </h1>
        {otpType ? (
          <OtpStep
            otp={otp}
            setOtp={setOtp}
            onSubmit={() => {}}
            otpType={otpType}
            username={username}
            onReset={reset}
          />
        ) : (
          <PasswordStep
            username={username}
            setUsername={setUsername}
            password={password}
            setPassword={setPassword}
            setOtpType={setOtpType}
          />
        )}
        <LoginFooter allowUsernameReminder={allowUsernameReminder} />
      </div>
    </div>
  );
};
