import { useEffect, useState } from "react";
import { dataInvestments, GlidePathDataPoint } from "../data/dataInvestments";

const MAXIMUM_REBALANCE_YEARS = 10;

export enum AllocationStage {
  GROWTH = "GROWTH",
  REBALANCE = "REBALANCE",
}

interface PensionAllocation {
  glidePath: GlidePathDataPoint[];
  allocationStage: AllocationStage;
  reallocationAge: number;
  withdrawalAge: number;
  currentTakenRisk: number;
  finalTakenRisk: number;
}

type State =
  | {
      status: "pending";
      isFetching: boolean;
      allocation?: undefined;
    }
  | {
      status: "error";
      isFetching: boolean;
      allocation?: undefined;
    }
  | {
      status: "success";
      isFetching: boolean;
      allocation: PensionAllocation;
    };

interface Options {
  age: number | undefined;
  withdrawalAge: number | undefined;
  withdrawalMonths: number | undefined;
  takenRiskDeviation: number | undefined;
}

export function usePensionAllocation({
  age,
  withdrawalAge,
  withdrawalMonths,
  takenRiskDeviation,
}: Options) {
  const [state, setState] = useState<State>({
    status: "pending",
    isFetching: false,
  });

  useEffect(() => {
    if (
      age === undefined ||
      withdrawalAge === undefined ||
      withdrawalMonths === undefined ||
      takenRiskDeviation === undefined
    ) {
      setState({ status: "pending", isFetching: false });
      return;
    }

    // In order to calculate allocation stage and years of reallocation before withdrawal
    // we need a minimum set of datapoints including the last year before reallocation starts.
    const glidePathStartAge = Math.min(
      age,
      withdrawalAge - (MAXIMUM_REBALANCE_YEARS + 1)
    );

    setState((prev) => ({ ...prev, isFetching: true }));
    dataInvestments
      .getPensionGlidePath(
        glidePathStartAge,
        withdrawalAge,
        withdrawalMonths,
        takenRiskDeviation
      )
      .then(({ glidePath }) => {
        const reallocationAge = getReallocationAge(glidePath);
        const currentTakenRisk = getCurrentTakenRisk(age, glidePath);
        const finalTakenRisk = getFinalTakenRisk(glidePath);

        if (
          reallocationAge === undefined ||
          currentTakenRisk === undefined ||
          finalTakenRisk === undefined
        ) {
          // We should never reach this unless we're not getting the minimum required set of datapoints.
          setState({ status: "error", isFetching: false });
          return;
        }

        setState({
          status: "success",
          isFetching: false,
          allocation: {
            glidePath: getFilteredGlidePath(age, glidePath),
            allocationStage: getAllocationStage(age, glidePath),
            reallocationAge,
            withdrawalAge,
            currentTakenRisk,
            finalTakenRisk,
          },
        });
      })
      .catch(() => {
        setState({ status: "error", isFetching: false });
      });
  }, [age, withdrawalAge, withdrawalMonths, takenRiskDeviation]);

  return state;
}

/**
 * For smoother lines when rendering the glide path as a graph, we only need
 * first, rebalance start, rebalance end, and last data points (if applicable).
 *
 * @param age Age of the user.
 * @param glidePath Data points for taken risk per age.
 * @returns Filtered data points.
 */
function getFilteredGlidePath(
  age: number,
  glidePath: GlidePathDataPoint[]
): GlidePathDataPoint[] {
  const filteredGlidePath: GlidePathDataPoint[] = [];

  for (var i = 0; i < glidePath.length; i++) {
    // Add first data point.
    if (glidePath[i].age === age) {
      filteredGlidePath.push(glidePath[i]);
      continue;
    }

    // Add rebalance start data point if applicable.
    if (
      filteredGlidePath.length === 1 &&
      glidePath[i - 1].takenRisk === glidePath[i].takenRisk &&
      glidePath[i + 1] &&
      glidePath[i + 1].takenRisk < glidePath[i].takenRisk
    ) {
      filteredGlidePath.push(glidePath[i]);
      continue;
    }

    // Add rebalance end data point if applicable.
    if (
      filteredGlidePath.length > 0 &&
      glidePath[i - 1].takenRisk > glidePath[i].takenRisk &&
      glidePath[i + 1].takenRisk === glidePath[i].takenRisk
    ) {
      filteredGlidePath.push(glidePath[i]);
      continue;
    }

    // Add last data point if applicable.
    if (filteredGlidePath.length > 0 && i === glidePath.length - 1) {
      filteredGlidePath.push(glidePath[i]);
      break;
    }
  }

  return filteredGlidePath;
}

/**
 * Calculate which allocation stage the user is in: growth or rebalance.
 *
 * @param age Age of the user.
 * @param glidePath Data points for taken risk per age (MUST include a datapoint with user's maximum taken risk pre-rebalance).
 * @return Allocation stage for the user.
 */
function getAllocationStage(
  age: number,
  glidePath: GlidePathDataPoint[]
): AllocationStage {
  const currentTakenRisk = getCurrentTakenRisk(age, glidePath);

  const maxTakenRisk = Math.max(
    ...glidePath.map((dataPoint) => dataPoint.takenRisk)
  );

  if (currentTakenRisk && currentTakenRisk < maxTakenRisk) {
    return AllocationStage.REBALANCE;
  } else {
    return AllocationStage.GROWTH;
  }
}

/**
 * Get age of user when reallocation starts.
 *
 * @param glidePath Data points for taken risk per age (MUST include a datapoint with user's maximum taken risk pre-rebalance).
 * @returns Age of user when reallocation starts.
 */
function getReallocationAge(
  glidePath: GlidePathDataPoint[]
): number | undefined {
  const maxTakenRisk = Math.max(
    ...glidePath.map((dataPoint) => dataPoint.takenRisk)
  );

  return glidePath.find((dataPoint) => dataPoint.takenRisk < maxTakenRisk)?.age;
}

/**
 * Get current taken risk for the user.
 *
 * @param age Age of the user.
 * @param glidePath Data points for taken risk per age.
 * @returns Current taken risk.
 */
function getCurrentTakenRisk(
  age: number,
  glidePath: GlidePathDataPoint[]
): number | undefined {
  return glidePath.find((dataPoint) => dataPoint.age === age)?.takenRisk;
}

/**
 * Get taken risk for the final year of withdrawal.
 *
 * @param glidePath Data points for taken risk per age.
 * @returns Taken risk for the final year of withdrawal.
 */
function getFinalTakenRisk(
  glidePath: GlidePathDataPoint[]
): number | undefined {
  return glidePath[glidePath.length - 1]?.takenRisk;
}
