import { Button, CardList, CardListItem, encode } from "@lysaab/ui-2";
import { useAccounts } from "../../hooks/useAccounts";
import { TranslatedText } from "../TranslatedText";

import "./ActiveAccountsData.scss";

export const ActiveAccountsData = () => {
  const { accounts } = useAccounts();

  if (typeof accounts === "undefined") {
    return null;
  }

  return (
    <div className="active-accounts-data">
      <h2>
        <TranslatedText id="activeAccountsData.activeAccountsHeader" />
      </h2>
      <CardList>
        {[...accounts.investmentAccounts, ...accounts.savingsAccounts].map(
          (account) => {
            return (
              <CardListItem key={account.accountId}>
                <h3>{account.name}</h3>
                <div className="active-accounts-data__row">
                  <TranslatedText id="activeAccountsData.historic" />
                  <Button
                    component="a"
                    variant="secondary"
                    size="small"
                    inline
                    href={
                      process.env.REACT_APP_API +
                      encode`/gdpr-export/performance/${account.accountId}`
                    }
                    download
                    label={<TranslatedText id="activeAccountsData.download" />}
                  />
                </div>
                <div className="active-accounts-data__row">
                  <TranslatedText id="activeAccountsData.transactions" />
                  <Button
                    component="a"
                    variant="secondary"
                    size="small"
                    inline
                    href={
                      process.env.REACT_APP_API +
                      encode`/gdpr-export/transactions/${account.accountId}`
                    }
                    download
                    label={<TranslatedText id="activeAccountsData.download" />}
                  />
                </div>
              </CardListItem>
            );
          }
        )}
      </CardList>
    </div>
  );
};
