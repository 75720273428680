import { useCallback, useEffect, useState } from "react";
import {
  SaveAccountRequest,
  AccountKycRequestAccount,
  AccountKycRequestAccountCorporation,
  dataKyc,
  DepositInterval,
  isDepositInterval,
  isPurpose,
  isWithdrawalInterval,
  KycQuestion,
  PurposeAccount,
  PurposeAccountCorporation,
  QuestionAnswer,
  WithdrawalInterval,
  AccountKyc,
  AccountKycType,
  SavingsAccountWithdrawalInterval,
  SavingsAccountDepositInterval,
  SavingsAccountPurpose,
  AccountKycRequestSavingsAccount,
  isSavingsAccountDepositInterval,
  isSavingsAccountWithdrawalInterval,
} from "../data/dataKyc";
import {
  InvestmentAccount,
  AccountType,
  dataAccounts,
  CompoundAccount,
  isInvestmentAccount,
  SavingsAccount,
  CompoundAccountId,
} from "../data/dataAccounts";

export const useAccountKyc = () => {
  const [accountsKyc, setAccountsKycInternal] =
    useState<AccountKycInternal[]>();

  useEffect(() => {
    Promise.all([dataAccounts.getAllAccounts(), dataKyc.getKyc()]).then(
      ([accounts, responseAccountsKyc]) => {
        setAccountsKycInternal(
          mapAccountKycToInternal(
            [...accounts.investmentAccounts, ...accounts.savingsAccounts],
            responseAccountsKyc.accountsKyc
          )
        );
      }
    );
  }, []);

  const setAccountsKyc = useCallback(
    (accountKyc: AccountKycInternal) => {
      if (!accountsKyc) {
        return;
      }
      const accountKycIndex = accountsKyc.findIndex(
        (akyc) => accountKyc.accountId === akyc.accountId
      );
      if (accountKycIndex > -1) {
        accountsKyc[accountKycIndex] = {
          ...accountsKyc[accountKycIndex],
          ...accountKyc,
        } as AccountKycInternal;
        setAccountsKycInternal([...accountsKyc]);
      }
    },
    [accountsKyc]
  );

  return [accountsKyc, setAccountsKyc] as [
    AccountKycInternal[],
    (q: Partial<AccountKycInternal>) => void
  ];
};

interface AccountInternal {
  accountName: string;
  accountId: CompoundAccountId;
  accountKycType: AccountKycType;
}

export interface AccountKycAccountInteral extends AccountInternal {
  [KycQuestion.PURPOSE]: PurposeAccount[];
  [KycQuestion.DEPOSIT_INTERVAL]: DepositInterval | undefined;
  [KycQuestion.WITHDRAWAL_INTERVAL]: WithdrawalInterval | undefined;
}

export interface AccountKycSavingsAccountInternal extends AccountInternal {
  [KycQuestion.PURPOSE]: SavingsAccountPurpose[];
  [KycQuestion.DEPOSIT_INTERVAL]: SavingsAccountDepositInterval | undefined;
  [KycQuestion.WITHDRAWAL_INTERVAL]:
    | SavingsAccountWithdrawalInterval
    | undefined;
}

export interface AccountKycAccountCorporationInternal extends AccountInternal {
  [KycQuestion.PURPOSE]: PurposeAccountCorporation[];
  [KycQuestion.DEPOSIT_INTERVAL]: DepositInterval | undefined;
  [KycQuestion.WITHDRAWAL_INTERVAL]: WithdrawalInterval | undefined;
}

export type AccountKycInternal =
  | AccountKycAccountInteral
  | AccountKycAccountCorporationInternal
  | AccountKycSavingsAccountInternal;

const initialAccountKycAccount: Partial<AccountKycAccountInteral> = {
  [KycQuestion.PURPOSE]: [],
  [KycQuestion.DEPOSIT_INTERVAL]: undefined,
  [KycQuestion.WITHDRAWAL_INTERVAL]: undefined,
};

const initialAccountKycSavingsAccount: Partial<AccountKycSavingsAccountInternal> =
  {
    [KycQuestion.PURPOSE]: [],
    [KycQuestion.DEPOSIT_INTERVAL]: undefined,
    [KycQuestion.WITHDRAWAL_INTERVAL]: undefined,
  };

const initialAccountKycAccountCorporation: Partial<AccountKycAccountCorporationInternal> =
  {
    [KycQuestion.PURPOSE]: [],
    [KycQuestion.DEPOSIT_INTERVAL]: undefined,
    [KycQuestion.WITHDRAWAL_INTERVAL]: undefined,
  };

function mapAccountKycToInternal(
  accounts: CompoundAccount[],
  accountsKyc: AccountKyc[]
): AccountKycInternal[] {
  const accountsKycInternal: AccountKycInternal[] = accountsKyc.map(
    (accountKyc): AccountKycInternal => {
      const account = accounts.find(
        (account) => account.accountId === accountKyc.kycId
      );

      if (!account) {
        throw new Error("mapAccountKycToInternal - Missing account");
      }

      const typeMapping: Record<
        AccountKycType,
        (account: CompoundAccount) => AccountKycInternal | undefined
      > = {
        [AccountKycType.ACCOUNT]: (account: CompoundAccount) => {
          if (!isInvestmentAccount(account)) {
            return;
          }
          return mapAccountKycAccountToInternal(
            account,
            accountKyc.questionAnswers
          );
        },
        [AccountKycType.ACCOUNT_CORPORATION]: (account: CompoundAccount) => {
          if (!isInvestmentAccount(account)) {
            return;
          }
          return mapAccountKycAccountCorporationToInternal(
            account,
            accountKyc.questionAnswers
          );
        },
        [AccountKycType.SAVINGS_ACCOUNT]: (account: CompoundAccount) => {
          if (isInvestmentAccount(account)) {
            return;
          }
          return mapSavingsAccountKycAccountToInternal(
            account,
            accountKyc.questionAnswers
          );
        },
      };

      const kycType = getAccountKycType(account);

      if (typeof kycType === "undefined") {
        throw new Error(
          "mapAccountKycToInternal - trying to map KYC for account without KYC"
        );
      }

      const mappedAccountToKycAnswers = typeMapping[kycType](account);

      if (typeof mappedAccountToKycAnswers === "undefined") {
        throw new Error(
          "mapAccountKycToInternal - wrong mapping account to kyc-question"
        );
      }

      return mappedAccountToKycAnswers;
    }
  );
  return accountsKycInternal;
}

function mapAccountKycAccountToInternal(
  account: InvestmentAccount,
  questionAnswers: QuestionAnswer[]
): AccountKycAccountInteral {
  const initial = {
    ...initialAccountKycAccount,
    accountName: account.name,
    accountId: account.accountId,
    accountKycType: getAccountKycType(account),
  } as AccountKycAccountInteral;

  return questionAnswers.reduce((acc, qa) => {
    switch (qa.question) {
      case KycQuestion.PURPOSE: {
        const answers = qa.answers
          .map((answers) => answers.toString())
          .filter((answer): answer is PurposeAccount =>
            isPurpose(AccountKycType.ACCOUNT, answer)
          );

        acc[KycQuestion.PURPOSE] = answers;
        return acc;
      }

      case KycQuestion.DEPOSIT_INTERVAL: {
        if (qa.answers.length === 1) {
          const answers = qa.answers
            .map((answers) => answers.toString())
            .filter((answer): answer is DepositInterval =>
              isDepositInterval(answer)
            );

          acc[KycQuestion.DEPOSIT_INTERVAL] = answers[0];
        }
        return acc;
      }

      case KycQuestion.WITHDRAWAL_INTERVAL: {
        if (qa.answers.length === 1) {
          const answers = qa.answers
            .map((answers) => answers.toString())
            .filter((answer): answer is WithdrawalInterval =>
              isWithdrawalInterval(answer)
            );

          acc[KycQuestion.WITHDRAWAL_INTERVAL] = answers[0];
        }
        return acc;
      }

      default: {
        return acc;
      }
    }
  }, initial);
}

function mapSavingsAccountKycAccountToInternal(
  account: SavingsAccount,
  questionAnswers: QuestionAnswer[]
): AccountKycSavingsAccountInternal {
  const initial = {
    ...initialAccountKycSavingsAccount,
    accountName: account.name,
    accountId: account.accountId,
    accountKycType: getAccountKycType(account),
  } as AccountKycSavingsAccountInternal;

  return questionAnswers.reduce((acc, qa) => {
    switch (qa.question) {
      case KycQuestion.PURPOSE: {
        const answers = qa.answers
          .map((answers) => answers.toString())
          .filter((answer): answer is SavingsAccountPurpose =>
            isPurpose(AccountKycType.SAVINGS_ACCOUNT, answer)
          );

        acc[KycQuestion.PURPOSE] = answers;
        return acc;
      }

      case KycQuestion.DEPOSIT_INTERVAL: {
        if (qa.answers.length === 1) {
          const answers = qa.answers
            .map((answers) => answers.toString())
            .filter((answer): answer is SavingsAccountDepositInterval =>
              isSavingsAccountDepositInterval(answer)
            );

          acc[KycQuestion.DEPOSIT_INTERVAL] = answers[0];
        }
        return acc;
      }

      case KycQuestion.WITHDRAWAL_INTERVAL: {
        if (qa.answers.length === 1) {
          const answers = qa.answers
            .map((answers) => answers.toString())
            .filter((answer): answer is SavingsAccountWithdrawalInterval =>
              isSavingsAccountWithdrawalInterval(answer)
            );

          acc[KycQuestion.WITHDRAWAL_INTERVAL] = answers[0];
        }
        return acc;
      }

      default: {
        return acc;
      }
    }
  }, initial);
}

function mapAccountKycAccountCorporationToInternal(
  account: InvestmentAccount,
  questionAnswers: QuestionAnswer[]
): AccountKycAccountCorporationInternal {
  const initial = {
    ...initialAccountKycAccountCorporation,
    accountName: account.name,
    accountId: account.accountId,
    accountKycType: getAccountKycType(account),
  } as AccountKycAccountCorporationInternal;

  return questionAnswers.reduce((acc, qa) => {
    switch (qa.question) {
      case KycQuestion.PURPOSE: {
        const answers = qa.answers
          .map((answers) => answers.toString())
          .filter((answer): answer is PurposeAccountCorporation =>
            isPurpose(AccountKycType.ACCOUNT_CORPORATION, answer)
          );

        acc[KycQuestion.PURPOSE] = answers;
        return acc;
      }

      case KycQuestion.DEPOSIT_INTERVAL: {
        if (qa.answers.length === 1) {
          const answers = qa.answers
            .map((answers) => answers.toString())
            .filter((answer): answer is DepositInterval =>
              isDepositInterval(answer)
            );

          acc[KycQuestion.DEPOSIT_INTERVAL] = answers[0];
        }
        return acc;
      }

      case KycQuestion.WITHDRAWAL_INTERVAL: {
        if (qa.answers.length === 1) {
          const answers = qa.answers
            .map((answers) => answers.toString())
            .filter((answer): answer is WithdrawalInterval =>
              isWithdrawalInterval(answer)
            );

          acc[KycQuestion.WITHDRAWAL_INTERVAL] = answers[0];
        }
        return acc;
      }

      default: {
        return acc;
      }
    }
  }, initial);
}

export function mapAccountKycToExternal(
  accountKyc: AccountKycInternal
): SaveAccountRequest {
  const typeMapping: Record<AccountKycType, () => SaveAccountRequest> = {
    [AccountKycType.ACCOUNT]: () =>
      mapAccountKycAccountToExternal(accountKyc as AccountKycAccountInteral),
    [AccountKycType.SAVINGS_ACCOUNT]: () =>
      mapSavingsAccountKycToExternal(
        accountKyc as AccountKycSavingsAccountInternal
      ),
    [AccountKycType.ACCOUNT_CORPORATION]: () =>
      mapAccountKycAccountCorporationToExternal(
        accountKyc as AccountKycAccountCorporationInternal
      ),
  };
  return typeMapping[accountKyc.accountKycType]();
}

function mapAccountKycAccountToExternal(
  accountKyc: AccountKycAccountInteral
): AccountKycRequestAccount {
  const DEPOSIT_INTERVAL = accountKyc[KycQuestion.DEPOSIT_INTERVAL];
  const WITHDRAWAL_INTERVAL = accountKyc[KycQuestion.WITHDRAWAL_INTERVAL];

  if (typeof DEPOSIT_INTERVAL === "undefined") {
    throw new Error("mapAccountKycToExternal - DEPOSIT_INTERVAL is undefined");
  }

  if (typeof WITHDRAWAL_INTERVAL === "undefined") {
    throw new Error(
      "mapAccountKycToExternal - WITHDRAWAL_INTERVAL is undefined"
    );
  }

  return {
    accountKycType: accountKyc.accountKycType,
    accountKyc: {
      version: "3",
      questionAnswers: [
        {
          question: KycQuestion.PURPOSE,
          answers: accountKyc[KycQuestion.PURPOSE],
        },
        {
          question: KycQuestion.DEPOSIT_INTERVAL,
          answers: [DEPOSIT_INTERVAL],
        },
        {
          question: KycQuestion.WITHDRAWAL_INTERVAL,
          answers: [WITHDRAWAL_INTERVAL],
        },
      ],
    },
  } as AccountKycRequestAccount;
}

function mapSavingsAccountKycToExternal(
  accountKyc: AccountKycSavingsAccountInternal
): AccountKycRequestSavingsAccount {
  const DEPOSIT_INTERVAL = accountKyc[KycQuestion.DEPOSIT_INTERVAL];
  const WITHDRAWAL_INTERVAL = accountKyc[KycQuestion.WITHDRAWAL_INTERVAL];

  if (typeof DEPOSIT_INTERVAL === "undefined") {
    throw new Error(
      "mapAccountKycSavingsAccountToExternal - DEPOSIT_INTERVAL is undefined"
    );
  }

  if (typeof WITHDRAWAL_INTERVAL === "undefined") {
    throw new Error(
      "mapAccountKycSavingsAccountToExternal - WITHDRAWAL_INTERVAL is undefined"
    );
  }

  return {
    accountKycType: accountKyc.accountKycType,
    accountKyc: {
      version: "3",
      questionAnswers: [
        {
          question: KycQuestion.PURPOSE,
          answers: accountKyc[KycQuestion.PURPOSE],
        },
        {
          question: KycQuestion.DEPOSIT_INTERVAL,
          answers: [DEPOSIT_INTERVAL],
        },
        {
          question: KycQuestion.WITHDRAWAL_INTERVAL,
          answers: [WITHDRAWAL_INTERVAL],
        },
      ],
    },
  } as AccountKycRequestSavingsAccount;
}

function mapAccountKycAccountCorporationToExternal(
  accountKyc: AccountKycAccountCorporationInternal
): AccountKycRequestAccountCorporation {
  const DEPOSIT_INTERVAL = accountKyc[KycQuestion.DEPOSIT_INTERVAL];
  const WITHDRAWAL_INTERVAL = accountKyc[KycQuestion.WITHDRAWAL_INTERVAL];

  if (typeof DEPOSIT_INTERVAL === "undefined") {
    throw new Error(
      "mapAccountKycAccountCorporationToExternal - DEPOSIT_INTERVAL is undefined"
    );
  }

  if (typeof WITHDRAWAL_INTERVAL === "undefined") {
    throw new Error(
      "mapAccountKycAccountCorporationToExternal - WITHDRAWAL_INTERVAL is undefined"
    );
  }

  return {
    accountKycType: AccountKycType.ACCOUNT_CORPORATION,
    accountKyc: {
      version: "3",
      questionAnswers: [
        {
          question: KycQuestion.PURPOSE,
          answers: accountKyc[KycQuestion.PURPOSE],
        },
        {
          question: KycQuestion.DEPOSIT_INTERVAL,
          answers: [DEPOSIT_INTERVAL],
        },
        {
          question: KycQuestion.WITHDRAWAL_INTERVAL,
          answers: [WITHDRAWAL_INTERVAL],
        },
      ],
    },
  };
}

function getAccountKycType(
  account: CompoundAccount
): AccountKycType | undefined {
  if (!isInvestmentAccount(account)) {
    return AccountKycType.SAVINGS_ACCOUNT;
  }
  /**
   * TODO: Insurances should not have KYC. But this is a pretty large change
   */
  switch (account.type) {
    case AccountType.ISK_SWE:
    case AccountType.VP:
      return AccountKycType.ACCOUNT;
    case AccountType.VP_SWE:
    case AccountType.KF_SWE:
    case AccountType.DANICA_KF:
    case AccountType.TJP_SWE:
      return AccountKycType.ACCOUNT_CORPORATION;
    case AccountType.LYSA_TJP:
    case AccountType.LYSA_PPF:
      return undefined;
  }
}
