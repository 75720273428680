import { defineMessages } from "react-intl";
import {
  RiskImportance,
  RiskPropensity,
  RiskReaction,
} from "../../../data/dataInvestments";

interface MessageWithId {
  id: string;
}

export const messagesRiskImportance = defineMessages<
  RiskImportance,
  MessageWithId,
  Record<RiskImportance, MessageWithId>
>({
  [RiskImportance.MAXIMIZE]: {
    id: "risk.important.alt.maximize",
  },
  [RiskImportance.MINIMIZE]: {
    id: "risk.important.alt.minimize",
  },
  [RiskImportance.BOTH]: {
    id: "risk.important.alt.both",
  },
});

export const messagesRiskReaction = defineMessages<
  RiskReaction,
  MessageWithId,
  Record<RiskReaction, MessageWithId>
>({
  [RiskReaction.SELL]: {
    id: "risk.reaction.alt.sell",
  },
  [RiskReaction.KEEP]: {
    id: "risk.reaction.alt.keep",
  },
  [RiskReaction.BUY]: {
    id: "risk.reaction.alt.buy",
  },
});

export const messagesRiskPropensity = defineMessages<
  RiskPropensity,
  MessageWithId,
  Record<RiskPropensity, MessageWithId>
>({
  [RiskPropensity.BAD]: {
    id: "risk.riskpropensity.alt.bad",
  },
  [RiskPropensity.PRETTY_BAD]: {
    id: "risk.riskpropensity.alt.prettybad",
  },
  [RiskPropensity.GOOD]: {
    id: "risk.riskpropensity.alt.good",
  },
  [RiskPropensity.PRETTY_GOOD]: {
    id: "risk.riskpropensity.alt.prettygood",
  },
  [RiskPropensity.TOO_RISKY]: {
    id: "risk.riskpropensity.alt.toorisky",
  },
});

export const propensityMessages = defineMessages({
  header: {
    id: "risk.riskpropensity.header",
  },
  required: {
    id: "risk.riskpropensity.required",
  },
});

export const importantMessages = defineMessages({
  header: {
    id: "risk.important.header",
  },
  required: {
    id: "risk.important.required",
  },
});

export const reactionMessages = defineMessages({
  header: {
    id: "risk.reaction.header",
  },
  required: {
    id: "risk.reaction.required",
  },
});

export const propensityCorpMessages = defineMessages({
  header: {
    id: "risk.riskpropensity.corp.header",
  },
  required: {
    id: "risk.riskpropensity.corp.required",
  },
});

export const importantCorpMessages = defineMessages({
  header: {
    id: "risk.important.corp.header",
  },
  required: {
    id: "risk.important.corp.required",
  },
});

export const reactionCorpMessages = defineMessages({
  header: {
    id: "risk.reaction.corp.header",
  },
  required: {
    id: "risk.reaction.corp.required",
  },
});
