import { API } from "@lysaab/ui-2/network/API";
import { ContractNoteId, FundTransactionType } from "./dataTransactions";
import { encode } from "@lysaab/ui-2";
import { InvestmentAccountId } from "./dataAccounts";
import { Isin } from "./dataFunds";

export enum CONTRACT_NOTE_TYPE {
  BUY = "buy",
  SELL = "sell",
  SWITCH = "switch",
}

export interface ContractNoteResponse {
  contractNoteId: ContractNoteId;
  identificationNumber: string;
  customerName: string;
  orderDate: string;
  tradeDate: string;
  accountName: string;
  accountId: InvestmentAccountId;
  amount: number;
  type: CONTRACT_NOTE_TYPE;
  trades: ContractNoteTrade[];
}

export interface ContractNoteTrade {
  isin: Isin;
  price: number;
  type: FundTransactionType;
  volume: number;
}

export const dataInvoice = {
  getInvoice: (invoiceId: ContractNoteId) => {
    return API.get<ContractNoteResponse>(
      encode`/fundmarket/notes/${invoiceId}`
    );
  },
};
