import {
  LysaFormRef,
  Form,
  Card,
  Button,
  MoneyInput,
  RequiredValidator,
  MinValidator,
  MaxValidator,
  Alternative,
  RadioGroup,
  SNACKBAR_TYPES,
  Snackbar,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import { Fragment, useEffect, useRef, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router";
import { TranslatedText } from "../../../../components/TranslatedText";
import { getNavLink } from "../../../../hooks/useCountryUrls";

import { useIsReadOnly } from "../../../../hooks/useIsReadOnly";
import HorizontalDivider from "../../../../components/horizontalDivider/HorizontalDivider";
import { WithdrawalHelp } from "../../request/components/withdrawalHelp/WithdrawalHelp";
import { useWithdrawalIskToSavingsContext } from "../WithdrawalIskToSavingsContext";
import {
  AddTransferAmountArguments,
  AddTransferDrainArguments,
  TransferType,
  dataTransfer,
} from "../../../../data/dataTransfer";
import { WITHDRAWAL_ISK_TO_SAVINGS_PAGE } from "../WithdrawalIskToSavingsStory";
import { useCurrency } from "../../../../context/LocalizationContext";
import {
  FeatureDomain,
  SubDomain,
  dataCustomerTrackingService,
} from "../../../../data/dataCustomerTracking";
import { TransferItem } from "../../../../components/internalTransferItem/InternalTransferItem";

import "./Amount.scss";
import { DisclaimerIskToSavings } from "../components/disclaimer/DisclaimerIskToSavings";

interface Props {
  next: (transferType: TransferType) => void;
}

const messages = defineMessages({
  amountLabel: {
    id: "withdrawal.isk-to-savings-story.amount.label",
  },
  amountRequired: {
    id: "withdrawal.isk-to-savings-story.amount.required",
  },
  amountMin: {
    id: "withdrawal.isk-to-savings-story.amount.min",
  },
  amountMax: {
    id: "withdrawal.isk-to-savings-story.amount.max",
  },
  alternativeDrain: {
    id: "withdrawal.isk-to-savings-story.amount.type.drain",
  },
  alternativeAmount: {
    id: "withdrawal.isk-to-savings-story.amount.type.amount",
  },
  alternativeHeader: {
    id: "withdrawal.isk-to-savings-story.amount.type.header",
  },
  alternativeRequired: {
    id: "withdrawal.isk-to-savings-story.amount.type.required",
  },
  reasonLabel: {
    id: "withdrawal.isk-to-savings-story.amount.reason.label",
  },
  reasonPlaceholder: {
    id: "withdrawal.isk-to-savings-story.amount.reason.placeholder",
  },
});

const MIN_PERCENTAGE = 0.8;

export function Amount({ next }: Props) {
  const formRef = useRef<LysaFormRef>();
  const currency = useCurrency();
  const {
    state: internalWithdrawalContext,
    setState: setInternalWithdrawalContext,
  } = useWithdrawalIskToSavingsContext();
  const intl = useIntl();
  const [selectedAlternative, setSelectedAlternative] =
    useState<Alternative<TransferType>>();
  const history = useHistory();
  const isReadOnly = useIsReadOnly();
  const [loading, setLoading] = useState(false);

  const alternatives: Alternative<TransferType>[] = [
    {
      text: intl.formatMessage(messages.alternativeAmount),
      value: TransferType.AMOUNT,
    },
    {
      text: intl.formatMessage(messages.alternativeDrain),
      value: TransferType.DRAIN,
    },
  ];

  const selectedLysaAccount = internalWithdrawalContext.selectedLysaAccount;
  const selectedSavingsAccount =
    internalWithdrawalContext.selectedSavingsAccount;

  useEffect(() => {
    if (!selectedLysaAccount || !selectedSavingsAccount) {
      history.push(getNavLink(WITHDRAWAL_ISK_TO_SAVINGS_PAGE));
    }
  }, [history, selectedLysaAccount, selectedSavingsAccount]);

  if (loading) {
    return <Spinner />;
  }

  if (!selectedLysaAccount || !selectedSavingsAccount) {
    return null;
  }

  return (
    <div className="withdrawal-isk-to-savings__amount">
      <Typography type="h2">
        <TranslatedText id="withdrawal.isk-to-savings-story.amount.header" />
      </Typography>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (
            !formRef.current?.isValid ||
            isReadOnly ||
            !selectedLysaAccount ||
            !selectedSavingsAccount ||
            !selectedAlternative
          ) {
            return;
          }
          setLoading(true);

          const amount = internalWithdrawalContext.amount;
          let transferParameters:
            | AddTransferDrainArguments
            | AddTransferAmountArguments;
          if (
            selectedAlternative.value === TransferType.AMOUNT &&
            typeof amount !== "undefined"
          ) {
            transferParameters = {
              toAccountId: selectedSavingsAccount.accountId,
              fromAccountId: selectedLysaAccount.accountId,
              requestId: new Date().getTime(),
              transferType: TransferType.AMOUNT,
              amount: amount,
            };
          } else {
            transferParameters = {
              toAccountId: selectedSavingsAccount.accountId,
              fromAccountId: selectedLysaAccount.accountId,
              requestId: new Date().getTime(),
              transferType: TransferType.DRAIN,
            } as AddTransferDrainArguments;
          }

          dataTransfer
            .addTransfer(transferParameters)
            .then(() => {
              dataCustomerTrackingService.postEvent({
                eventName: "clickedConfirmWithdrawalIskToSavings",
                domain: FeatureDomain.TRANSFERS,
                subDomain: SubDomain.WITHDRAWAL,
                payload: {
                  amount:
                    selectedAlternative.value === TransferType.DRAIN
                      ? selectedLysaAccount.worth.toString()
                      : amount
                      ? amount.toString()
                      : "",
                  is_drain:
                    selectedAlternative.value === TransferType.DRAIN
                      ? "True"
                      : "False",
                },
              });
              next(selectedAlternative.value);
            })
            .catch((error) => {
              setLoading(false);
              throw error;
            });
        }}
      >
        <Card>
          <RadioGroup
            alternatives={alternatives}
            value={selectedAlternative}
            header={intl.formatMessage(messages.alternativeHeader)}
            onChange={(alt) => setSelectedAlternative(alt)}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.alternativeRequired)
              ),
            ]}
          />
          {selectedAlternative?.value === TransferType.AMOUNT && (
            <Fragment>
              <Snackbar type={SNACKBAR_TYPES.INFO}>
                <div>
                  <TranslatedText
                    id="withdrawal.amount-amount.amount-snackbar"
                    values={{
                      minPercentage: intl.formatNumber(MIN_PERCENTAGE, {
                        style: "percent",
                      }),
                      maxTransfer: intl.formatNumber(
                        selectedLysaAccount.maxWithdrawal,
                        {
                          style: "currency",
                          currency: currency,
                        }
                      ),
                    }}
                  />
                </div>
              </Snackbar>
              <MoneyInput
                decimalScale={0}
                currency={currency}
                value={internalWithdrawalContext.amount?.toString()}
                onChange={(value) =>
                  setInternalWithdrawalContext({ amount: Number(value) })
                }
                label={intl.formatMessage(messages.amountLabel)}
                validators={[
                  new RequiredValidator(
                    intl.formatMessage(messages.amountRequired)
                  ),
                  new MinValidator(
                    1,
                    intl.formatMessage(messages.amountMin, {
                      amount: intl.formatNumber(1, {
                        style: "currency",
                        currency: currency,
                      }),
                    })
                  ),
                  new MaxValidator(
                    selectedLysaAccount.maxWithdrawal,
                    intl.formatMessage(messages.amountMax, {
                      amount: intl.formatNumber(
                        selectedLysaAccount.maxWithdrawal,
                        {
                          style: "currency",
                          currency: currency,
                        }
                      ),
                    })
                  ),
                ]}
              />
            </Fragment>
          )}
        </Card>

        <TransferItem
          toAccount={selectedSavingsAccount}
          fromAccount={selectedLysaAccount}
          amount={
            selectedAlternative?.value === TransferType.DRAIN
              ? selectedLysaAccount.worth.toString()
              : internalWithdrawalContext.amount?.toString()
          }
        />

        <Button
          type="submit"
          block
          label={
            <TranslatedText id="withdrawal.isk-to-savings-story.amount.button" />
          }
        />
      </Form>
      <HorizontalDivider />
      <WithdrawalHelp />
      <DisclaimerIskToSavings />
    </div>
  );
}
