import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Spinner, Button } from "@lysaab/ui-2";
import { defineMessages, FormattedMessage } from "react-intl";
import { Page, PageHeader } from "./Page";
import { useFeatureContext } from "../context/FeatureContext";
import { MESSAGES_PAGE_URL } from "./messages/MessagesPage";
import { getNavLink } from "../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "./overview/OverviewPage";
import NotFoundImage from "../assets/404.png";
import "./NotFoundPage.scss";

export const NOT_FOUND_URL = "/404";

const messages = defineMessages({
  header: {
    id: "not-found.header",
  },
  body: {
    id: "not-found.body",
  },
  homeButton: {
    id: "not-found.homeButton",
  },
  contactButton: {
    id: "not-found.contactButton",
  },
});

export function NotFoundPage() {
  const [features] = useFeatureContext();
  const location = useLocation<{}>();

  if (
    Object.values(features).some((feature) => {
      return typeof feature === "undefined";
    })
  ) {
    return <Spinner />;
  }
  return (
    <Page className="not-found-page">
      <img src={NotFoundImage} alt="404" />
      <PageHeader>
        <h1>
          <FormattedMessage {...messages.header} />
        </h1>
      </PageHeader>
      <p>
        <FormattedMessage {...messages.body} />
      </p>
      <Button
        variant="secondary"
        component={Link}
        block
        to={{
          pathname: getNavLink(OVERVIEW_PAGE_URL),
          state: { prevPath: location.pathname },
        }}
        label={<FormattedMessage {...messages.homeButton} />}
      />
      <Button
        variant="secondary"
        component={Link}
        block
        to={{
          pathname: getNavLink(MESSAGES_PAGE_URL),
          state: { prevPath: location.pathname },
        }}
        label={<FormattedMessage {...messages.contactButton} />}
      />
    </Page>
  );
}
