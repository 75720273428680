import { FunctionComponent, useContext, useState } from "react";

import { useIntl } from "react-intl";
import { MAX_AGE } from "../TransferContext";
import { getUserAge } from "../utils/userAge";
import { Ingress } from "../components/ingress/Ingress";
import { Button, NewIcon, Typography } from "@lysaab/ui-2";
import { UserContext, useUser } from "../../../../../context/UserContext";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { Modal } from "../../../../../components/modal/Modal";

import "./Intro.scss";
import { LegalEntityType } from "../../../../../data/dataLogin";

interface Props {
  next: () => void;
  exit: () => void;
}

export const Intro: FunctionComponent<Props> = ({ next, exit }) => {
  const intl = useIntl();
  const user = useUser();
  const userContext = useContext(UserContext);
  const age = getUserAge(user.tin);
  const [readMore, setReadMore] = useState(false);

  if (age && age > MAX_AGE) {
    return (
      <article className="transfer-pension-intro">
        <header>
          <NewIcon.AlertOutline
            size={96}
            primaryColor="var(--lysa-icon-secondary)"
          />
          <Typography type="display-small">
            <TranslatedText id={"sweden.transfer-pension.intro.block.header"} />
          </Typography>
          <Ingress>
            <TranslatedText
              id={"sweden.transfer-pension.intro.block.reason.age"}
              values={{
                max: (text) => {
                  return <span>{MAX_AGE}</span>;
                },
              }}
            />
          </Ingress>
        </header>
        <Button
          style={{ marginTop: "6rem" }}
          block
          variant="primary"
          type="button"
          label={intl.formatMessage({
            id: "sweden.transfer-pension.intro.exit.button",
          })}
          onClick={exit}
        />
      </article>
    );
  }

  if (userContext.state.legalEntityType === LegalEntityType.CORPORATION) {
    return (
      <article className="transfer-pension-intro">
        <header>
          <NewIcon.AlertOutline
            size={96}
            primaryColor="var(--lysa-icon-secondary)"
          />
          <Typography type="display-small">
            <TranslatedText id={"sweden.transfer-pension.intro.block.header"} />
          </Typography>
          <Ingress>
            <TranslatedText
              id={"sweden.transfer-pension.intro.block.reason.corporation"}
            />
          </Ingress>
        </header>
        <Button
          style={{ marginTop: "6rem" }}
          block
          variant="primary"
          type="button"
          label={intl.formatMessage({
            id: "sweden.transfer-pension.intro.exit.button",
          })}
          onClick={exit}
        />
      </article>
    );
  }

  return (
    <article className="transfer-pension-intro">
      <header>
        <NewIcon.Move size={96} primaryColor="var(--lysa-icon-secondary)" />
        <Typography type="display-small">
          <TranslatedText
            id={"sweden.transfer-pension.intro.header"}
            values={{
              first: (text) => {
                return (
                  <span>
                    {text}
                    <br />
                  </span>
                );
              },
              second: (text) => {
                return <span>{text}</span>;
              },
            }}
          />
        </Typography>
        <Ingress>
          <TranslatedText id={"sweden.transfer-pension.intro.ingress"} />
        </Ingress>
        <section className="time-estimate">
          <NewIcon.Clock size={16} primaryColor="var(--lysa-text-secondary)" />
          <TranslatedText id={"sweden.transfer-pension.intro.time-estimate"} />
        </section>
        <hr className="divider" />
      </header>
      <ul>
        <li className="list-item">
          <span className="icon-circle">
            <NewIcon.Rocket />
          </span>
          <span className="list-item-text">
            <Typography type="label">
              <TranslatedText
                id={"sweden.transfer-pension.intro.list-item-1.header"}
              />
            </Typography>
            <Typography type="body" className="list-item-body">
              <TranslatedText
                id={"sweden.transfer-pension.intro.list-item-1"}
                values={{
                  link: (text) => {
                    return (
                      <button
                        className="read-more-button"
                        onClick={() => setReadMore(true)}
                      >
                        Läs mer
                      </button>
                    );
                  },
                }}
              />
            </Typography>
          </span>
        </li>
        <li className="list-item">
          <span className="icon-circle">
            <NewIcon.Calendar />
          </span>
          <span className="list-item-text">
            <Typography type="label">
              <TranslatedText
                id={"sweden.transfer-pension.intro.list-item-2.header"}
              />
            </Typography>
            <Typography type="body" className="list-item-body">
              <TranslatedText
                id={"sweden.transfer-pension.intro.list-item-2"}
              />
            </Typography>
          </span>
        </li>
        <li className="list-item">
          <span className="icon-circle">
            <NewIcon.Eye />
          </span>
          <span className="list-item-text">
            <Typography type="label">
              <TranslatedText
                id={"sweden.transfer-pension.intro.list-item-3.header"}
              />
            </Typography>
            <Typography type="body" className="list-item-body">
              <TranslatedText
                id={"sweden.transfer-pension.intro.list-item-3"}
              />
            </Typography>
          </span>
        </li>
      </ul>
      {readMore && (
        <Modal
          header={intl.formatMessage({
            id: "sweden.transfer-pension.intro.read-more.fees.heading",
          })}
          showModal={readMore}
          onClose={() => {
            setReadMore(false);
          }}
          closeOnOverlayClick
        >
          <Typography type="body">
            <TranslatedText id="sweden.transfer-pension.intro.read-more.fees.body.one" />
          </Typography>
          <Typography type="body">
            <TranslatedText id="sweden.transfer-pension.intro.read-more.fees.body.two" />
          </Typography>
          <Typography type="body">
            <TranslatedText id="sweden.transfer-pension.intro.read-more.fees.body.three" />
          </Typography>
          <Typography type="body-small">
            <TranslatedText id="sweden.transfer-pension.intro.read-more.fees.footnote" />
          </Typography>
        </Modal>
      )}
      <Button
        className="next"
        block
        variant="primary"
        onClick={next}
        label={intl.formatMessage({
          id: "sweden.transfer-pension.intro.button",
        })}
      />
    </article>
  );
};
