import "./Valentines.scss";

export const Valentines: React.VFC = () => {
  const heartCount = 20;

  return (
    <div className="valentines">
      {[...Array(heartCount)].map((_, index) => (
        <div className="heart-wrapper" key={index}>
          <div className="heart"></div>
        </div>
      ))}
    </div>
  );
};
