import { useLoadLegalEntityExperiments } from "@lysaab/shared";

type ValueOf<T> = T[keyof T];

// Example: { WELCOME_MODAL: "app-welcome-modal.enabled" }
export const experimentKeys = {
  PENSION_MOVE: "pension-move.enabled",
  MIN_DEPOSIT: "min-deposit-amount",
  TINK_ENABLED: "tink.enabled",
  KF_MIGRATE_ENABLED: "kf-move.enabled",
} as const;

/*
Example:
{
  [experimentKeys.WELCOME_MODAL]: {
    defaultValue: "false"
  },
}
*/
export const experimentConfig = {
  [experimentKeys.PENSION_MOVE]: {
    defaultValue: "false",
  },
  [experimentKeys.MIN_DEPOSIT]: {
    defaultValue: "1000",
  },
  [experimentKeys.TINK_ENABLED]: {
    defaultValue: "false",
  },
  [experimentKeys.KF_MIGRATE_ENABLED]: {
    defaultValue: "false",
  },
} as const;

// These are automatically loaded on login
// If you have an experiment that does not use legalEntityId as the subjecId,
// you'll need to implement loading yourself
// Example: [experimentKeys.WELCOME_MODAL]
const legalEntityIdExperiments: Array<ValueOf<typeof experimentKeys>> = [
  experimentKeys.PENSION_MOVE,
  experimentKeys.MIN_DEPOSIT,
  experimentKeys.TINK_ENABLED,
  experimentKeys.KF_MIGRATE_ENABLED,
];

export function useLoadSignInExperiments() {
  return useLoadLegalEntityExperiments(legalEntityIdExperiments);
}
