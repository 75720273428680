import { VoidFunctionComponent } from "react";
import { Alternative, Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { useIntl, defineMessages, MessageDescriptor } from "react-intl";
import { SavingsHorizonLength } from "../../../data/dataInvestments";
import { useUser } from "../../../context/UserContext";
import { LegalEntityType } from "../../../data/dataLogin";

const messages = defineMessages({
  header: {
    id: "horizon.length.header",
  },
  headerCorp: {
    id: "horizon.corp.length.header",
  },
});

const savingsHorizonLengthQuestion: Record<LegalEntityType, MessageDescriptor> =
  {
    [LegalEntityType.PERSON]: messages.header,
    [LegalEntityType.CORPORATION]: messages.headerCorp,
  };

export const savingsHorizonLengthMessages =
  defineMessages<SavingsHorizonLength>({
    [SavingsHorizonLength.VERY_LONG]: {
      id: "horizon.length.alt.verylong",
    },
    [SavingsHorizonLength.LONG]: {
      id: "horizon.length.alt.long",
    },
    [SavingsHorizonLength.KINDA_LONG]: {
      id: "horizon.length.alt.kindalong",
    },
    [SavingsHorizonLength.MIDDLE]: {
      id: "horizon.length.alt.middle",
    },
    [SavingsHorizonLength.SHORT]: {
      id: "horizon.length.alt.short",
    },
  });

interface Props {
  savingsHorizonLength?:
    | SavingsHorizonLength
    | Alternative<SavingsHorizonLength>;
  setSavingsHorizonLength: (
    horizonLength: Alternative<SavingsHorizonLength>
  ) => void;
}

export const SavingsHorizonLengthInput: VoidFunctionComponent<Props> = ({
  savingsHorizonLength,
  setSavingsHorizonLength,
}) => {
  const intl = useIntl();
  const legalEntityType = useUser().legalEntityType;

  const alternatives = Object.values(SavingsHorizonLength).map((key) => ({
    text: intl.formatMessage(savingsHorizonLengthMessages[key]),
    value: key,
  }));

  const value =
    typeof savingsHorizonLength === "string"
      ? {
          text: intl.formatMessage(
            savingsHorizonLengthMessages[savingsHorizonLength]
          ),
          value: savingsHorizonLength,
        }
      : savingsHorizonLength;

  if (typeof legalEntityType === "undefined") {
    return null;
  }

  return (
    <Card>
      <RadioGroup
        alternatives={alternatives}
        header={intl.formatMessage(
          savingsHorizonLengthQuestion[legalEntityType]
        )}
        onChange={setSavingsHorizonLength}
        value={value}
        validators={[
          new RequiredValidator(
            intl.formatMessage({ id: "horizon.length.required" })
          ),
        ]}
        data-test-id="horizon-length"
      />
    </Card>
  );
};
