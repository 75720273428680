import { useEffect, useMemo, useState, VoidFunctionComponent } from "react";
import {
  AllDocumentsAccounts,
  DocumentInformation,
  DocumentsCustomer,
} from "../../data/dataDocuments";
import { DocumentLink } from "./DocumentLink";
import { getSignedDocuments } from "./getSignedDocuments";
import { DOCUMENT_GROUP } from "./DocumentsPage";

interface Props {
  group: DOCUMENT_GROUP;
  documents: string[];
  accounts?: AllDocumentsAccounts;
  customer?: DocumentsCustomer;
  documentsInformation: DocumentInformation[];
}

export const DocumentLinks: VoidFunctionComponent<Props> = ({
  group,
  documents,
  accounts = [],
  customer,
  documentsInformation,
}) => {
  const [docs, setDocs] = useState<DocumentInformation[]>();

  const signedDocuments = useMemo(() => {
    if (typeof docs === "undefined") {
      return;
    }

    return getSignedDocuments(docs, accounts, customer);
  }, [accounts, customer, docs]);

  useEffect(() => {
    const docs = documents.reduce((info: DocumentInformation[], doc) => {
      const matchingDocument = documentsInformation.find(
        ({ fileName }) => fileName.replace(".md", "") === doc
      );
      if (matchingDocument) {
        return [...info, matchingDocument];
      }
      console.error(`Couldn't find information for document ${doc}`);
      return info;
    }, []);
    setDocs(docs);
  }, [documents, documentsInformation]);

  if (!docs || !signedDocuments) {
    return null;
  }

  return (
    <>
      {signedDocuments.map((doc) => (
        <DocumentLink
          group={group}
          document={doc.fileName}
          key={doc.fileName}
        />
      ))}
    </>
  );
};
