import { Fragment, VoidFunctionComponent } from "react";
import { useDocumentInformation } from "../../../../../../hooks/useDocumentInformation";

interface Props {
  documentName: string;
}

export const DocName: VoidFunctionComponent<Props> = ({ documentName }) => {
  const documentInformation = useDocumentInformation(
    documentName.replace(".md", "")
  );
  return <Fragment>{documentInformation?.title}</Fragment>;
};
