import { API, cache } from "@lysaab/ui-2";
import { InvestmentAccountId } from "./dataAccounts";
import { LegalEntityId } from "./dataUser";

export type AgreementId = string & { readonly isAgreementId: true };

export enum AgreementStatus {
  PENDING = "Pending",
  ACCEPTED = "Accepted",
  REJECTED = "Rejected",
  EXPIRED = "Expired",
  CANCELED = "Canceled",
}

export interface CreateAgreementRequest {
  phone: string;
  targetPaymentDay: number;
  userRedirect: string;
  cancelRedirect?: string;
}

export interface CreateAgreementResponse {
  agreementId: AgreementId;
  signingLink: string;
}

export interface GetAgreementResponse {
  agreementId: AgreementId;
  mobilepayAgreementId: string;
  entity: LegalEntityId;
  phone: string;
  targetPaymentDay: number;
  requestedAt: string;
  lastUpdatedAt: string;
  status: AgreementStatus;
}
export interface CreateMonthlyDepositRequest {
  accountId: InvestmentAccountId;
  amount: number;
}

export const dataMobilepay = {
  createAgreement: (data: CreateAgreementRequest) => {
    return API.post<CreateAgreementResponse>(`/mobilepay/agreement`, data).then(
      (resp) => {
        cache.delete("/mobilepay");
        cache.delete("/monthly-payments");
        return resp;
      }
    );
  },
  getActiveAgreement: (ignoreCache = false) => {
    return API.get<GetAgreementResponse[]>(
      `/mobilepay/agreement/active`,
      ignoreCache
    );
  },
  getAllAgreements: () => {
    return API.get<GetAgreementResponse[]>("/mobilepay/agreements");
  },
  createMonthlyDeposit: (data: CreateMonthlyDepositRequest) => {
    return API.post(`/mobilepay/monthly-deposits`, data).then((resp) => {
      cache.delete("/mobilepay");
      cache.delete("/monthly-payments");
      return resp;
    });
  },
  pollActiveAgreement: () => {
    return new Promise<GetAgreementResponse[]>((resolve, reject) => {
      const poll = () => {
        setTimeout(() => {
          dataMobilepay
            .getActiveAgreement(true)
            .then((resp) => {
              if (
                resp.length === 1 &&
                resp[0].status.toLowerCase() ===
                  AgreementStatus.PENDING.toLowerCase()
              ) {
                poll();
              } else {
                resolve(resp);
              }
            })
            .catch(reject);
        }, 3000);
      };
      poll();
    });
  },
  cancelAgreement: () => {
    return API.delete("/mobilepay/agreement").then(() => {
      cache.delete("/mobilepay");
      cache.delete("/monthly-payments");
    });
  },
};
