import { FunctionComponent, ReactElement } from "react";

import { NewIcon, SelectableCard, Typography } from "@lysaab/ui-2";

import "./DocumentCard.scss";

/**
 * A SelectableCard component that links to associated documents.
 */

interface Props {
  header: ReactElement;
  body: ReactElement;
  onClick: () => void;
}

export const DocumentCard: FunctionComponent<Props> = ({
  header,
  body,
  onClick,
}) => {
  return (
    <SelectableCard
      className="transfer-pension-document-card"
      onClick={(event) => {
        /**
         * Required because the card is inside a form with validation, and we don't want the card to trigger the form's validation.
         */
        event.preventDefault();
        event.stopPropagation();
        onClick();
      }}
    >
      <header className="header">
        <div className="left">
          <NewIcon.Document />
          <Typography type="label">{header}</Typography>
        </div>
        <NewIcon.ChevronRight />
      </header>
      <Typography className="body" type="body">
        {body}
      </Typography>
    </SelectableCard>
  );
};
