import { RadioGroupCard, RequiredValidator } from "@lysaab/ui-2";
import { useEffect, useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  AccountType,
  InvestmentAccount,
  isInvestmentAccount,
  SavingsAccount,
} from "../../data/dataAccounts";
import { LysaAccountSelectionCardAccount } from "./LysaAccountSelectionCardAccount";
import { LysaAccountSelectionCardSavingsAccount } from "./LysaAccountSelectionCardSavingsAccount";
import { AccountTypeLogo } from "../accountType/AccountTypeLogo";
import { useAccountFilterParams } from "../../hooks/useAccountFilterParams";

interface Props<T> {
  accounts: T[];
  selectedAccount?: T;
  onChange: (account: T) => void;
  legend: JSX.Element | string;
  legendInCard?: boolean;
}

const messages = defineMessages({
  required: {
    id: "lysaAccountSelectionCard.validation.required",
  },
});

export const LysaAccountSelectionCard = <
  T extends InvestmentAccount | SavingsAccount
>({
  accounts,
  selectedAccount,
  legend,
  onChange,
  legendInCard = false,
}: Props<T>) => {
  const intl = useIntl();
  const { hasSavingsAccountFilter, hasInvestmentAccountFilter } =
    useAccountFilterParams();

  const alternatives = useMemo(
    () =>
      accounts
        .filter(
          (account) =>
            !isInvestmentAccount(account) ||
            (account.type !== AccountType.LYSA_PPF &&
              account.type !== AccountType.LYSA_TJP)
        )
        .filter((account) => {
          if (hasSavingsAccountFilter) {
            return !isInvestmentAccount(account);
          }
          if (hasInvestmentAccountFilter) {
            return isInvestmentAccount(account);
          }
          return true;
        })
        .map((account) => ({
          header: account.name,
          description: isInvestmentAccount(account) ? (
            <LysaAccountSelectionCardAccount account={account} />
          ) : (
            <LysaAccountSelectionCardSavingsAccount account={account} />
          ),
          value: account,
          logo: <AccountTypeLogo account={account} />,
        })),
    [accounts, hasInvestmentAccountFilter, hasSavingsAccountFilter]
  );

  useEffect(() => {
    if (alternatives.length === 1 && typeof selectedAccount === "undefined") {
      onChange(alternatives[0].value);
    }
  }, [alternatives, onChange, selectedAccount]);

  return (
    <RadioGroupCard
      legend={legend}
      legendInCard={legendInCard}
      alternatives={alternatives}
      onChange={(alternative) => {
        alternative && onChange(alternative);
      }}
      value={selectedAccount}
      validators={[
        new RequiredValidator(intl.formatMessage(messages.required)),
      ]}
    />
  );
};
