import {
  ShareAccountInvitation,
  ShareAccountSentInvitation,
} from "../../../data/dataAttorney";
import { TranslatedText } from "../../TranslatedText";

interface Props {
  invitation: ShareAccountInvitation | ShareAccountSentInvitation;
  inviteeName?: string;
}

export const AcceptedOwnerState: React.VFC<Props> = ({
  invitation,
  inviteeName,
}) => {
  return (
    <div className="accepted-owner-state">
      <h3 className="emoji">🎉</h3>
      <p className="text">
        {inviteeName ? (
          <TranslatedText
            id="shareAccountStory.inviteCard.shareSuccessfulHeader"
            values={{
              accountName: invitation.accountName,
              inviteeName,
            }}
          />
        ) : (
          <TranslatedText
            id="shareAccountStory.inviteCard.shareSuccessfulHeaderWithoutName"
            values={{
              accountName: invitation.accountName,
            }}
          />
        )}
      </p>
    </div>
  );
};
