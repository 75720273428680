import { FunctionComponent } from "react";
import * as React from "react";

import "./PendingDepositTable.scss";

export const PendingDepositTable: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  return <table className="pending-deposit-lysa-table">{children}</table>;
};
