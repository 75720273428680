import { VoidFunctionComponent } from "react";
import * as React from "react";
import cx from "classnames";
import "./DataDisplay.scss";

interface Props {
  title?: React.ReactElement | string;
  text?: React.ReactElement | string;
  className?: string;
  type: "side-by-side" | "list-item" | "input";
  hasContainer?: boolean;
}

export const DataDisplay: VoidFunctionComponent<Props> = ({
  title = null,
  text = null,
  className = "",
  type,
  hasContainer,
}) => {
  if (hasContainer) {
    return (
      <div className="data-display-container">
        <span className={cx("data-display title", className, type)}>
          {title}
        </span>
        <span className={cx("data-display text", className, type)}>{text}</span>
      </div>
    );
  }

  return (
    <>
      <span className={cx("data-display title", className, type)}>{title}</span>
      <span className={cx("data-display text", className, type)}>{text}</span>
    </>
  );
};
