import { useEffect, useRef, useState } from "react";
import {
  Card,
  Form,
  LysaFormRef,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  ServerError,
  Typography,
} from "@lysaab/ui-2";
import { useIntl } from "react-intl";

import "./Confirm.scss";
import { useMonthlyInternalTransferContext } from "../MonthlyInternalTransferContext";
import { useLocalizationContext } from "../../../context/LocalizationContext";
import { TranslatedText } from "../../../components/TranslatedText";
import { useIsReadOnly } from "../../../hooks/useIsReadOnly";
import { useAccounts } from "../../../hooks/useAccounts";
import {
  MonthlyInternalTransferRequestBody,
  dataPeriodicInternalTransfer,
} from "../../../data/dataPeriodicInternalTransfer";
import { useHistory } from "react-router-dom";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { BASE_ROUTES } from "../MonthlyInternalTransferStory";
import { AccountTypeLogo } from "../../../components/accountType/AccountTypeLogo";

interface Props {
  next: () => void;
}

export const Confirm = ({ next }: Props) => {
  const monthlyInternalTransferContext = useMonthlyInternalTransferContext();
  const localizationContext = useLocalizationContext();
  const intl = useIntl();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<LysaFormRef>();
  const isReadOnly = useIsReadOnly();
  const { accounts } = useAccounts();
  const history = useHistory();

  useEffect(() => {
    if (
      typeof monthlyInternalTransferContext.state.fromAccountId === "undefined"
    ) {
      return history.replace(getNavLink(BASE_ROUTES.FROM_ACCOUNT));
    }
  }, [history, monthlyInternalTransferContext.state.fromAccountId]);

  const confirmMonthlyInternalTransfer = () => {
    if (isLoading) {
      return;
    }

    if (
      typeof monthlyInternalTransferContext.state.fromAccountId !==
        "undefined" &&
      typeof monthlyInternalTransferContext.state.toAccountId !== "undefined" &&
      typeof monthlyInternalTransferContext.state.amount !== "undefined" &&
      typeof monthlyInternalTransferContext.state.transferDay !== "undefined"
    ) {
      const requestParams: MonthlyInternalTransferRequestBody = {
        fromAccountId: monthlyInternalTransferContext.state.fromAccountId,
        toAccountId: monthlyInternalTransferContext.state.toAccountId,
        transferDay: monthlyInternalTransferContext.state.transferDay,
        amount: monthlyInternalTransferContext.state.amount,
      };
      setIsLoading(true);
      let promise: Promise<void>;
      if (typeof monthlyInternalTransferContext.state.id !== "undefined") {
        promise = dataPeriodicInternalTransfer.updateMonthlyInternalTransfer(
          monthlyInternalTransferContext.state.id,
          requestParams
        );
      } else {
        promise =
          dataPeriodicInternalTransfer.addMonthlyInternalTransfer(
            requestParams
          );
      }
      promise
        .then(() => {
          next();
        })
        .catch((error: ServerError<unknown>) => {
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  if (isLoading || !accounts) {
    return <Spinner />;
  }

  const mergedAccounts = [
    ...accounts?.investmentAccounts,
    ...accounts?.savingsAccounts,
  ];

  const fromAccount = mergedAccounts?.find(
    (account) =>
      account.accountId === monthlyInternalTransferContext.state.fromAccountId
  );
  const toAccount = mergedAccounts?.find(
    (account) =>
      account.accountId === monthlyInternalTransferContext.state.toAccountId
  );
  return (
    <div className="monthly-internal-story-confirm">
      <h2>
        <TranslatedText id="monthly-internal-transfer.story.confirm.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid || isReadOnly) {
            return;
          }
          confirmMonthlyInternalTransfer();
        }}
      >
        <Card>
          {isError && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="deposits.monthly.story.confirm.error" />
            </Snackbar>
          )}
          <dl>
            <div className="monthly-internal-story-confirm__entry">
              <dt>
                <Typography type="body" component="span">
                  <TranslatedText id="monthly-internal-transfer.story.confirm.from-account" />
                </Typography>
              </dt>
              <dd>
                <AccountTypeLogo account={fromAccount!} size={24} />
                <Typography type="body" component="span">
                  {fromAccount?.name}
                </Typography>
              </dd>
            </div>
            <div className="monthly-internal-story-confirm__entry">
              <dt>
                <Typography type="body" component="span">
                  <TranslatedText id="monthly-internal-transfer.story.confirm.to-account" />
                </Typography>
              </dt>
              <dd>
                <AccountTypeLogo account={toAccount!} size={24} />
                <Typography type="body" component="span">
                  {toAccount?.name}
                </Typography>
              </dd>
            </div>
            <div className="monthly-internal-story-confirm__entry">
              <dt>
                <Typography type="body" component="span">
                  <TranslatedText id="monthly-internal-transfer.story.confirm.amount" />
                </Typography>
              </dt>
              <dd>
                <Typography type="body" component="span">
                  {`${intl.formatNumber(
                    Number(monthlyInternalTransferContext.state.amount),
                    {
                      style: "currency",
                      currency: localizationContext.state.currency,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    }
                  )} / ${intl.formatMessage({
                    id: "monthly-internal-transfer.story.confirm.per-month",
                  })}`}
                </Typography>
              </dd>
            </div>
            <div className="monthly-internal-story-confirm__entry">
              <dt>
                <Typography type="body" component="span">
                  <TranslatedText id="monthly-internal-transfer.story.confirm.transfer-day" />
                </Typography>
              </dt>
              <dd>
                <Typography type="body" component="span">
                  {monthlyInternalTransferContext.state.transferDay}
                </Typography>
              </dd>
            </div>
          </dl>
        </Card>

        <Button
          block
          type="submit"
          label={
            <TranslatedText id="monthly-internal-transfer.story.confirm.button" />
          }
        />
      </Form>
    </div>
  );
};
