import { Page, PageHeader } from "../../../../pages/Page";
import { TranslatedText } from "../../../../components/TranslatedText";
import { OverviewAgreement } from "./overviewAgreement/OverviewAgreement";

export const MonthlyAgreements = () => {
  return (
    <Page>
      <PageHeader>
        <h1>
          <TranslatedText id="denmark.monthlyAgreements.header" />
        </h1>
      </PageHeader>
      <OverviewAgreement />
    </Page>
  );
};
