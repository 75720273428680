import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { Page, PageHeader } from "../../Page";
import { GridRow } from "../../../components/grid/gridRow/GridRow";
import { GridCol } from "../../../components/grid/gridCol/GridCol";
import { Snackbar, SNACKBAR_TYPES, Spinner, Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../../components/TranslatedText";
import { OverviewCard } from "./components/overviewCard/OverviewCard";
import { AccountActions } from "./components/accountActions/AccountActions";
import { FeesCard } from "./components/feesCard/FeesCard";
import { InstitutionAllocationCard } from "./components/institutionAllocationCard/InstitutionAllocationCard";
import { SavingsAccountId } from "../../../data/dataAccounts";
import { useSavingsAccount } from "../../../hooks/useSavingsAccount";
import { LoadingContextProvider } from "../../../context/LoadingContext";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { ChangeNameCard } from "./components/changeNameCard/ChangeNameCard";
import { DocsCard } from "./components/docsCard/DocsCard";
import { MonthlyCard } from "./components/monthlyCard/MonthlyCard";
import { TransactionsCard } from "./components/transactionsCard/TransactionsCard";
import { ShareAccountCard } from "../ShareAccountCard";
import { RouteAwareToggle } from "../../../components/route/RouteAwareToggle";
import { SHARE_ACCOUNT_URL } from "../../shareAccountStory/ShareAccountStory";
import { InterestCardWrapper } from "./components/interestCardWrapper/InterestCardWrapper";
import { CLOSE_SAVINGS_ACCOUNT_URL } from "../../../countries/sweden/pages/closeSavingsAccountStory/CloseSavingsAccountStory";
import { CloseSavingsAccount } from "./components/closeSavingsAccount/CloseSavingsAccount";

import { useHasOngoingPartnerBalancing } from "../../../hooks/useHasOngoingPartnerTransfer";
import { ElevioLink } from "../../../components/elevio/ElevioLink";
import { PendingInternalTransfers } from "../PendingInternalTransfers";
import "./SavingsAccountPage.scss";
import {
  PendingInternalTransfer,
  dataTransfer,
} from "../../../data/dataTransfer";
import { useAccounts } from "../../../hooks/useAccounts";
import { MonthlyInternalTransfers } from "../MonthlyInternalTransfers";
import { useIsReadOnly } from "../../../hooks/useIsReadOnly";

export const SAVINGS_ACCOUNT_PAGE_URL = "/savings-account/:accountId";

const InnerSavingsAccountPage = () => {
  const match = useRouteMatch<{ accountId: SavingsAccountId }>({
    path: getNavLink(SAVINGS_ACCOUNT_PAGE_URL),
  });
  const { account, getAccount } = useSavingsAccount(match?.params.accountId);
  const hasOngoingPartnerBalancing = useHasOngoingPartnerBalancing(
    match?.params.accountId
  );
  const [pendingInternalTransfers, setPendingInternalTransfers] = useState<
    PendingInternalTransfer[]
  >([]);
  const { accounts } = useAccounts();
  const ownedAccounts = accounts
    ? [...accounts.investmentAccounts, ...accounts.savingsAccounts]
    : [];
  const isReadonly = useIsReadOnly();

  useEffect(() => {
    dataTransfer.getPendingInternalTransfers().then((data) => {
      const filteredPendingInternalTransfers = data.filter(
        (pendingInternalTransfer) => {
          return (
            pendingInternalTransfer.toAccountId === account?.accountId ||
            pendingInternalTransfer.fromAccountId === account?.accountId
          );
        }
      );
      setPendingInternalTransfers(filteredPendingInternalTransfers);
    });
  }, [account?.accountId]);

  if (
    typeof account === "undefined" ||
    typeof match?.params.accountId === "undefined"
  ) {
    return <Spinner />;
  }

  const showOngoingPartnerBalance =
    hasOngoingPartnerBalancing !== null &&
    typeof hasOngoingPartnerBalancing !== "undefined";

  return (
    <Page className="savings-account-page">
      <PageHeader>
        <h1 className="savings-account-page__header">
          <span className="savings-account-page__header-text">
            {account.name}
          </span>
          <span className="savings-account-page__header-type">
            <TranslatedText id="savingsAccountPage.header.accountType" />
          </span>
        </h1>
      </PageHeader>
      {showOngoingPartnerBalance && (
        <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
          <Typography component="span">
            <TranslatedText
              id="savingsAccountPage.snackbar.rebalancing"
              values={{
                link: (text) => <ElevioLink articleId="341" linkText={text} />,
              }}
            />
          </Typography>
        </Snackbar>
      )}
      <div className="savings-account-page__overview-wrapper">
        <OverviewCard accountId={account.accountId} />
      </div>

      <div className="savings-account-page__section-wrapper">
        <AccountActions accountId={account.accountId} />
      </div>
      <GridRow>
        <GridCol
          xsmall={12}
          medium={4}
          className="savings-account-page__section-wrapper"
        >
          <section className="savings-account-page__section">
            <Typography type="h2">
              <TranslatedText id="savingsAccountPage.monthlyCard.header" />
            </Typography>
            <MonthlyCard accountId={account.accountId} />
          </section>
        </GridCol>
        <GridCol
          xsmall={12}
          medium={4}
          className="savings-account-page__section-wrapper"
        >
          <section className="savings-account-page__section">
            <MonthlyInternalTransfers
              key={account.accountId}
              account={account}
            />
          </section>
        </GridCol>
        <GridCol
          xsmall={12}
          medium={4}
          className="savings-account-page__section-wrapper"
        >
          <section className="savings-account-page__section">
            <Typography type="h2">
              <TranslatedText id="savingsAccountPage.transactionsCard.header" />
            </Typography>
            <TransactionsCard accountId={account.accountId} />
          </section>
        </GridCol>
        {pendingInternalTransfers.length > 0 && (
          <GridCol
            xsmall={12}
            medium={4}
            className="savings-account-page__section-wrapper"
          >
            <section className="savings-account-page__section">
              <Typography type="h2">
                <TranslatedText id="savingsAccountPage.pending-internal-transfers.header" />
              </Typography>
              <PendingInternalTransfers
                account={account}
                accounts={ownedAccounts}
                showHeader={false}
              />
            </section>
          </GridCol>
        )}
        <GridCol
          xsmall={12}
          medium={4}
          className="savings-account-page__section-wrapper"
        >
          <section className="savings-account-page__section">
            <Typography type="h2">
              <TranslatedText id="savingsAccountPage.interestCard.header" />
            </Typography>
            <InterestCardWrapper accountId={account.accountId} />
          </section>
        </GridCol>
        <GridCol
          xsmall={12}
          medium={4}
          className="savings-account-page__section-wrapper"
        >
          <section className="savings-account-page__section">
            <Typography type="h2">
              <TranslatedText id="savingsAccountPage.institutionAllocationCard.institution" />
            </Typography>
            <InstitutionAllocationCard account={account} />
          </section>
        </GridCol>

        <GridCol
          xsmall={12}
          medium={4}
          className="savings-account-page__section-wrapper"
        >
          <section className="savings-account-page__section">
            <Typography type="h2">
              <TranslatedText id="savingsAccountPage.feesCard.header" />
            </Typography>
            <FeesCard accountId={account.accountId} />
          </section>
        </GridCol>

        <RouteAwareToggle path={getNavLink(SHARE_ACCOUNT_URL)}>
          <GridCol
            xsmall={12}
            medium={4}
            className="savings-account-page__section-wrapper"
          >
            <section className="savings-account-page__section">
              <Typography type="h2">
                <TranslatedText id="savingsAccountPage.sharedAccountCard.header" />
              </Typography>
              <ShareAccountCard account={account} />
            </section>
          </GridCol>
        </RouteAwareToggle>
        <GridCol
          xsmall={12}
          medium={4}
          className="savings-account-page__section-wrapper"
        >
          <section className="savings-account-page__section">
            <Typography type="h2">
              <TranslatedText id="savingsAccountPage.docsCard.header" />
            </Typography>
            <DocsCard accountId={account.accountId} />
          </section>
        </GridCol>
        <GridCol
          xsmall={12}
          medium={4}
          className="savings-account-page__section-wrapper"
        >
          <section className="savings-account-page__section">
            <Typography type="h2">
              <TranslatedText id="savingsAccountPage.changeNameCard.header" />
            </Typography>
            <ChangeNameCard account={account} updateAccount={getAccount} />
          </section>
        </GridCol>
        {!isReadonly && (
          <RouteAwareToggle path={getNavLink(CLOSE_SAVINGS_ACCOUNT_URL)}>
            <GridCol
              xsmall={12}
              medium={4}
              className="savings-account-page__section-wrapper"
            >
              <section className="savings-account-page__section">
                <Typography type="h2">
                  <TranslatedText id="savingsAccountPage.closeSavingsAccount.header" />
                </Typography>
                <CloseSavingsAccount accountId={account.accountId} />
              </section>
            </GridCol>
          </RouteAwareToggle>
        )}
      </GridRow>
    </Page>
  );
};

export const SavingsAccountPage = () => {
  return (
    <LoadingContextProvider>
      <InnerSavingsAccountPage />
    </LoadingContextProvider>
  );
};
