import {
  useContext,
  useEffect,
  useRef,
  useState,
  VoidFunctionComponent,
} from "react";
import {
  Button,
  LysaFormRef,
  Form,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import "./UpdateCrsInformation.scss";
import { CrsSwedishResponse, dataCrs } from "../../../../../data/dataCrs";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { CrsContext } from "../context/CrsContext";
import { CrsFormFields } from "../../../components/crsFormFields/CrsFormFields";

interface Props {
  next: () => void;
}

export const UpdateCrsInformation: VoidFunctionComponent<Props> = ({
  next,
}) => {
  const crsContext = useContext(CrsContext);
  const setCrsContext = crsContext.setState;
  const [serverCrs, setServerCrs] = useState<CrsSwedishResponse>();
  const [hasCrsChanged, setHasCrsChanged] = useState(true);
  const [showNoChangeWarn, setShowNoChangeWarn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { crsInformation } = crsContext.state;

  const formRef = useRef<LysaFormRef>();

  useEffect(() => {
    if (crsInformation !== undefined) {
      return;
    }

    setIsLoading(true);
    dataCrs
      .getLatestCrsSwedish()
      .then((response) => {
        setServerCrs(response);

        if (typeof crsInformation === "undefined") {
          setCrsContext({
            crsInformation: response,
          });
        }
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [crsInformation, setCrsContext]);

  useEffect(() => {
    if (serverCrs && typeof crsInformation !== "undefined") {
      setHasCrsChanged(hasCrsBeenUpdated(crsInformation, serverCrs));
      if (hasCrsChanged) {
        setShowNoChangeWarn(false);
      }
    }
  }, [crsInformation, hasCrsChanged, serverCrs]);

  if (isLoading) {
    return <Spinner />;
  }

  if (typeof crsInformation === "undefined") {
    return null;
  }
  return (
    <div className="update-crs-information">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!hasCrsChanged) {
            setShowNoChangeWarn(true);
            return;
          }
          if (formRef.current?.isValid) {
            next();
          }
        }}
      >
        <h2>
          <TranslatedText id="sweden.updateCrsStory.updateCrsInformation.header" />
        </h2>

        {error && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="sweden.updateCrsStory.updateCrsInformation.error" />
          </Snackbar>
        )}
        <CrsFormFields data={crsInformation} setData={crsContext.setState} />
        {showNoChangeWarn && (
          <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
            <TranslatedText id="sweden.updateCrsStory.updateCrsInformation.crsNoChange" />
          </Snackbar>
        )}
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="sweden.updateCrsStory.updateCrsInformation.button.next" />
          }
        />
      </Form>
    </div>
  );
};

export const hasCrsBeenUpdated = (
  updatedCrs: CrsSwedishResponse,
  savedCrs: CrsSwedishResponse
) => {
  return JSON.stringify(updatedCrs) !== JSON.stringify(savedCrs);
};
