import { AllocationBarIntl, Card, Icon, RiskIndicator } from "@lysaab/ui-2";
import { FunctionComponent } from "react";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { defineMessages, useIntl } from "react-intl";
import { InvestmentType } from "../../../../../../data/dataAccounts";
import { savingsHorizonLengthMessages } from "../../../../../../pageComponents/horizon/questions/SavingsHorizonLengthInput";
import { SavingsHorizonLength } from "../../../../../../data/dataInvestments";
import "./SummaryInfo.scss";

interface MessageWithId {
  id: string;
}

const investmentTypeMessages = defineMessages<
  InvestmentType,
  MessageWithId,
  Record<InvestmentType, MessageWithId>
>({
  [InvestmentType.BROAD]: {
    id: "sweden.kf.summary.broad",
  },
  [InvestmentType.SUSTAINABLE]: {
    id: "sweden.kf.summary.sustainable",
  },
});

interface Props {
  investmentType: InvestmentType;
  takenRisk: number;
  savingsHorizon: SavingsHorizonLength;
  insured: string;
}

export const SummaryInfo: FunctionComponent<Props> = ({
  investmentType,
  takenRisk,
  savingsHorizon,
  insured,
}) => {
  const intl = useIntl();

  return (
    <Card className="create-account-kf-summary-info">
      <h4>
        <Icon.Summary />
        <TranslatedText id="sweden.kf.summary.card-header" />
      </h4>

      <dl>
        <dt>
          <TranslatedText id="sweden.kf.summary.account-type-label" />
        </dt>
        <dd>
          <TranslatedText id="sweden.kf.summary.account-type-value" />
        </dd>
        <dt>
          <TranslatedText id="sweden.kf.summary.investment-type-label" />
        </dt>
        <dd className="create-account-corporation-lysa-summary-risk-indicator">
          {intl.formatMessage(investmentTypeMessages[investmentType])}
          <RiskIndicator risk={takenRisk} investmentType={investmentType} />
        </dd>
        <dt>
          <TranslatedText id="sweden.kf.summary.horizon-label" />
        </dt>
        <dd>
          {intl.formatMessage(savingsHorizonLengthMessages[savingsHorizon])}
        </dd>
        <dt>
          <TranslatedText id="sweden.kf.summary.risk-label" />
        </dt>
        <dd className="risk-indicator-container">
          <AllocationBarIntl
            messages={{
              stocks: (
                <TranslatedText
                  id="sweden.kf.summary.stocks-label"
                  values={{
                    stocks: intl.formatNumber(takenRisk / 100, {
                      style: "percent",
                    }),
                  }}
                />
              ),
              bonds: (
                <TranslatedText
                  id="sweden.kf.summary.bonds-label"
                  values={{
                    bonds: intl.formatNumber(1 - takenRisk / 100, {
                      style: "percent",
                    }),
                  }}
                />
              ),
            }}
            risk={takenRisk}
          />
        </dd>
      </dl>
      <dl>
        <dt>
          <TranslatedText id="sweden.kf.summary.provider-label" />
        </dt>
        <dd>
          <TranslatedText id="sweden.kf.summary.provider-value" />
        </dd>
        <dt>
          <TranslatedText id="sweden.kf.summary.fee-label" />
        </dt>
        <dd>
          <TranslatedText id="sweden.kf.summary.fee-value" />
        </dd>
        <dt>
          <TranslatedText id="sweden.kf.summary.insured" />
        </dt>
        <dd>{insured}</dd>
      </dl>
    </Card>
  );
};
