import {
  CountrySelect,
  Alternative,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  TextInput,
  MaxLengthValidator,
  RadioGroup,
  Card,
  WorldCountry,
} from "@lysaab/ui-2";
import { Fragment, useCallback, useContext, useState } from "react";
import { useIntl } from "react-intl";
import { TaxResidenceList } from "../../../../components/taxResidenceList/TaxResidenceList";
import { TranslatedText } from "../../../../components/TranslatedText";
import {
  DisallowedCountry,
  UsaValidator,
} from "../../../../components/validators/UsaValidator";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import {
  CrsSwedishResponse,
  instanceOfCrsSwedishResponseWithAddress,
} from "../../../../data/dataCrs";
import { getCountryFromCountryCode } from "../../../../utils/getCountryFromCountryCode";
import "./CrsFormFields.scss";
import { messages } from "./CrsFormFieldsMessages";

interface Props {
  data: CrsSwedishResponse;
  setData: ({ crsInformation }: { crsInformation: CrsSwedishResponse }) => void;
}

export const CrsFormFields = ({ data, setData }: Props) => {
  const intl = useIntl();
  const { language, country } = useContext(LocalizationContext).state;
  const [showTaxResidenceList, setShowTaxResidenceList] = useState<Boolean>(
    data.countries.length > 0
  );
  const [taxResidenceAlternatives] = useState<Alternative<boolean>[]>([
    {
      text: intl.formatMessage(messages.taxableInOtherCountryNo),
      value: false,
    },
    {
      text: intl.formatMessage(messages.taxableInOtherCountryYes),
      value: true,
    },
  ]);

  const setResidenceCountry = useCallback(
    (value: WorldCountry) => {
      if (
        value === WorldCountry.SWEDEN &&
        instanceOfCrsSwedishResponseWithAddress(data)
      ) {
        setData({
          crsInformation: {
            residentCountry: value,
            countries: [...data.countries],
          },
        });
      } else {
        setData({
          crsInformation: {
            ...data,
            residentCountry: value,
          },
        });
      }
    },
    [data, setData]
  );

  const showAddressInformation =
    data.residentCountry &&
    data.residentCountry !== WorldCountry.SWEDEN &&
    instanceOfCrsSwedishResponseWithAddress(data);

  return (
    <div className="crs-form-fields">
      <Card>
        <div className="lysa-ui-input-group">
          <RadioGroup
            header={intl.formatMessage(messages.taxableInOtherCountryLabel, {
              country: getCountryFromCountryCode({ country, language }),
            })}
            alternatives={taxResidenceAlternatives}
            value={taxResidenceAlternatives.find(
              ({ value }) => value === showTaxResidenceList
            )}
            onChange={({ value }) => {
              setShowTaxResidenceList(value);
              if (value) {
                setData({
                  crsInformation: {
                    ...data,
                    residentCountry: WorldCountry.SWEDEN,
                  },
                });
              } else {
                setData({ crsInformation: { countries: [] } });
              }
            }}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.taxableInOtherCountryRequired)
              ),
            ]}
          />
        </div>
        {showTaxResidenceList && (
          <TaxResidenceList
            omitCountries={[WorldCountry.SWEDEN]}
            disablePrimaryTaxResidence={false}
            data={
              data.countries.length > 0
                ? data.countries
                : [
                    {
                      tin: "",
                      country: undefined,
                    },
                  ]
            }
            setData={(countries) =>
              setData({ crsInformation: { ...data, countries } })
            }
          />
        )}
      </Card>
      {showTaxResidenceList && (
        <Card>
          <CountrySelect
            label={intl.formatMessage(messages.residenceCountryLabel)}
            placeholder={intl.formatMessage(
              messages.residenceCountryPlaceholder
            )}
            value={data.residentCountry}
            onChange={({ value }) => setResidenceCountry(value)}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.residenceCountryRequired)
              ),
              new UsaValidator(" "),
            ]}
            language={language}
          />
          {showAddressInformation && (
            <Fragment>
              {data.residentCountry ===
                DisallowedCountry.UNITED_STATES_OF_AMERICA && (
                <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                  <TranslatedText id="sweden.crsFormFields.residentCountry.usa" />
                </Snackbar>
              )}
              <TextInput
                label={intl.formatMessage(messages.streetLabel)}
                value={data.street || ""}
                onChange={(street) =>
                  setData({
                    crsInformation: {
                      ...data,
                      street,
                    },
                  })
                }
                validators={[
                  new RequiredValidator(
                    intl.formatMessage(messages.streetRequired)
                  ),
                  new MaxLengthValidator(
                    300,
                    intl.formatMessage(messages.streetLength)
                  ),
                ]}
              />
              <TextInput
                label={intl.formatMessage(messages.postCodeLabel)}
                value={data.zipCode}
                onChange={(zipCode) => {
                  setData({
                    crsInformation: {
                      ...data,
                      zipCode,
                    },
                  });
                }}
                validators={[
                  new RequiredValidator(
                    intl.formatMessage(messages.postCodeRequired)
                  ),
                ]}
              />
              <TextInput
                label={intl.formatMessage(messages.cityLabel)}
                value={data.city || ""}
                onChange={(city) => {
                  setData({
                    crsInformation: {
                      ...data,
                      city,
                    },
                  });
                }}
                validators={[
                  new RequiredValidator(
                    intl.formatMessage(messages.cityRequired)
                  ),
                  new MaxLengthValidator(
                    300,
                    intl.formatMessage(messages.cityLength)
                  ),
                ]}
              />
            </Fragment>
          )}
        </Card>
      )}
    </div>
  );
};
