import { API } from "@lysaab/ui-2/network/API";
import { dataPerformance } from "./dataPerformance";
import { InvestmentAccountId, SavingsAccountId } from "./dataAccounts";
import { encode } from "@lysaab/ui-2";
import { Isin } from "./dataFunds";
import { Bank } from "./dataKlarna";
import { DateTime } from "luxon";

export enum TransactionType {
  WITHDRAWAL = "WITHDRAWAL",
  DEPOSIT = "DEPOSIT",
  BUY = "BUY",
  SELL = "SELL",
  FEE = "FEE",
  SWITCH_BUY = "SWITCH_BUY",
  SWITCH_SELL = "SWITCH_SELL",
  MOVE_IN = "MOVE_IN",
  MOVE_OUT = "MOVE_OUT",
  TAX = "TAX",
  CORRECTION = "CORRECTION",
  INTEREST = "INTEREST",
}

export enum FundTransactionType {
  BUY = "BUY",
  SELL = "SELL",
  SWITCH_BUY = "SWITCH_BUY",
  SWITCH_SELL = "SWITCH_SELL",
  CORRECTION = "CORRECTION",
  MOVE_IN = "MOVE_IN",
  MOVE_OUT = "MOVE_OUT",
}

export enum CashTransactionType {
  DEPOSIT = "DEPOSIT",
  WITHDRAWAL = "WITHDRAWAL",
  FEE = "FEE",
  TAX = "TAX",
  MOVE_IN = "MOVE_IN",
  MOVE_OUT = "MOVE_OUT",
  INTEREST = "INTEREST",
}

export type ContractNoteId = string & { readonly isContractNoteId: true };

interface TransactionResponse {
  accountId: InvestmentAccountId | SavingsAccountId;
  booked: string;
  amount: number;
}

type CashTransactionWithdrawal = {
  type: CashTransactionType.WITHDRAWAL;
  externalBankAccount: string;
  bank: Bank;
} & TransactionResponse;

type CashTransactionMove = {
  type: CashTransactionType.MOVE_IN | CashTransactionType.MOVE_OUT;
} & TransactionResponse;

type CashTransactionOther = {
  type: Exclude<
    CashTransactionType,
    | CashTransactionType.WITHDRAWAL
    | CashTransactionType.MOVE_IN
    | CashTransactionType.MOVE_OUT
  >;
  depositChannel: string;
} & TransactionResponse;

export type CashTransaction =
  | CashTransactionWithdrawal
  | CashTransactionMove
  | CashTransactionOther;

type FundTransactionAll = {
  orderId: string;
  isin: Isin;
  volume: number;
  price: number;
};

export type FundTransactionMove = {
  type: FundTransactionType.MOVE_IN | FundTransactionType.MOVE_OUT;
} & TransactionResponse &
  FundTransactionAll;

type FundTransactionOther = {
  type: Exclude<
    FundTransactionType,
    FundTransactionType.MOVE_IN | FundTransactionType.MOVE_OUT
  >;
  contractNoteId: ContractNoteId;
} & TransactionResponse &
  FundTransactionAll;

export type FundTransaction = FundTransactionMove | FundTransactionOther;
export type Transaction = FundTransaction | CashTransaction;

/* Since MOVE_IN and MOVE_OUT is both on FUND_TRANSACTION_TYPE and CASH_TRANSACTION_TYPE we also check isin-property */
export function instanceOfFundTransaction(
  object: Transaction
): object is FundTransaction {
  return object.type in FundTransactionType && object.hasOwnProperty("isin");
}

export function instanceOfCashTransaction(
  object: Transaction
): object is CashTransaction {
  return object.type in CashTransactionType && !object.hasOwnProperty("isin");
}

export function instanceOfInternalTransfer(
  object: Transaction | InternalTransfer
): object is InternalTransfer {
  return (
    object.hasOwnProperty("fromAccountId") &&
    object.hasOwnProperty("toAccountId")
  );
}
export interface Filter {
  start: Date;
  end: Date;
  accountIds: (InvestmentAccountId | SavingsAccountId)[];
  types: TransactionType[];
}

export interface InternalTransfer {
  booked: string;
  fromAccountId: InvestmentAccountId;
  fromAccountClosed: boolean;
  nameOfClosedFromAccount?: string;
  toAccountId: InvestmentAccountId;
  toAccountClosed: boolean;
  nameOfClosedToAccount?: string;
  amount: number;
}

export const dataTransactions = {
  getTransactions: (filter: Filter) => {
    const params = dataTransactions.filterToQueryParameters(filter);
    return API.get<Transaction[]>(`/transactions?${params.toString()}`);
  },

  filterToQueryParameters: (filter: Filter) => {
    const params = new URLSearchParams();
    filter.accountIds.forEach((accountId) => {
      params.append("accountIds", encodeURIComponent(accountId));
    });
    filter.types.forEach((type) => {
      params.append("types", encodeURIComponent(type));
    });
    params.append(
      "from",
      encodeURIComponent(DateTime.fromJSDate(filter.start).toISODate())
    );
    params.append(
      "to",
      encodeURIComponent(DateTime.fromJSDate(filter.end).toISODate())
    );
    return params;
  },

  getDefaultFilter: () => ({
    start: new Date(),
    end: new Date(),
    accountIds: [],
    types: [],
  }),

  getInternalTransfers: (start: Date, end: Date) => {
    return API.get<InternalTransfer[]>(
      encode`/transfer/transactions?start=${dataPerformance.toStringDate(
        start
      )}&end=${dataPerformance.toStringDate(end)}`
    );
  },
};
