import { useCallback, useEffect, useRef } from "react";
import { createContext } from "../../context/CreateContext";
import { isValidAccountQuestionsUpdated } from "../../data/dataInvestments";
import { UpdateInvestmentAccountComposition } from "../../pageComponents/accountsAllocation/accountAdviseCard/AccountAdviceCard";

export enum AccountUpdateAction {
  KEEP = "KEEP",
  ADVICE = "UPDATE",
  CUSTOM = "CUSTOM",
}

export function isAccountUpdateAction(
  value: string
): value is AccountUpdateAction {
  return Object.values(AccountUpdateAction).includes(
    value as AccountUpdateAction
  );
}

/**
 * Primary context for ReviewAccountStory
 * Also used in YearlyReview, SuitabilityAssessmentStory and AccountSituationStory in the last page (Account(s)Allocation)
 * Used to keep track of the current selected action for each investment account (AccountUpdateAction)
 */
export interface ReviewAccountState {
  // Active account that is reviewed in ReviewAccountStory
  reviewAccount?: UpdateInvestmentAccountComposition;
  // All accounts that can be reviewed
  accounts: UpdateInvestmentAccountComposition[];
}

export const [ReviewAccountContextProvider, useReviewAccountContext] =
  createContext<ReviewAccountState>("ReviewAccountContext", {
    accounts: [],
  });

/**
 * Used to update accounts. Only accounts where risk, investmentType or InvestmentAccountQuestions are update and the action/risk is then set to default.
 * If account is not updated we don't want to bother the customer to redo the selection
 */
export function useUpdateReviewAccounts() {
  const [state, setState] = useReviewAccountContext();
  const accountsRef = useRef(state.accounts);

  useEffect(() => {
    accountsRef.current = state.accounts;
  }, [state.accounts]);

  const updateAccounts = useCallback(
    (updatedAccounts: UpdateInvestmentAccountComposition[]) => {
      let accounts: UpdateInvestmentAccountComposition[] = JSON.parse(
        JSON.stringify(accountsRef.current)
      );

      updatedAccounts.forEach((account) => {
        const index = accounts.findIndex(
          (a) => account.accountId === a.accountId
        );

        if (index > -1) {
          const isAdvisedInvestmentTypeUpdated =
            account.newAdvice.investmentType !==
            accounts[index].newAdvice.investmentType;
          const isAdvicedRiskUpdated =
            account.newAdvice.advice !== accounts[index].newAdvice.advice;

          if (
            isAdvisedInvestmentTypeUpdated ||
            isAdvicedRiskUpdated ||
            isValidAccountQuestionsUpdated(
              account.newAdvice,
              accounts[index].newAdvice
            )
          ) {
            accounts[index] = account;
          }
        } else {
          accounts = [...accounts, account];
        }
      });

      setState({ accounts });
    },
    [setState]
  );

  return updateAccounts;
}
