import { Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import {
  ShareAccountInvitation,
  ShareAccountInvitationStatus,
  ShareAccountSentInvitation,
} from "../../../data/dataAttorney";
import { ListItemAccountName } from "../listItems/ListItemAccountName";

interface Props {
  invitation: ShareAccountInvitation | ShareAccountSentInvitation;
}

export const DeclinedState: React.VFC<Props> = ({ invitation }) => {
  return (
    <div>
      {invitation.accountName && (
        <ListItemAccountName accountName={invitation.accountName} />
      )}
      <hr />
      {invitation.status === ShareAccountInvitationStatus.DECLINED_OWNER && (
        <Snackbar type={SNACKBAR_TYPES.ERROR}>
          <FormattedMessage id="shareAccountStory.inviteCard.inviteCanceledText" />
        </Snackbar>
      )}
      {invitation.status === ShareAccountInvitationStatus.DECLINED_INVITEE && (
        <Snackbar type={SNACKBAR_TYPES.ERROR}>
          <FormattedMessage id="shareAccountStory.inviteCard.inviteCanceledByInviteeText" />
        </Snackbar>
      )}
    </div>
  );
};
