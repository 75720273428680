import { Story } from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { useLocation, useHistory, Switch, Route } from "react-router";
import { Contact } from "../../components/contact/Contact";
import { getNavLink } from "../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../hooks/useSafeNavigation";
import { useStoryValues } from "../../hooks/useStoryValues";
import { LOGIN_USERNAME_PASSWORD_PAGE_URL } from "../login/LoginUsernamePasswordPage";
import { PageStripped } from "../PageStripped";
import { Done } from "./Done/Done";
import { Intro } from "./Intro/Intro";

export const USERNAME_REMINDER_URL = "/username-reminder";

export const BASE_ROUTES = {
  INTRO: `${USERNAME_REMINDER_URL}/`,
  DONE: `${USERNAME_REMINDER_URL}/done`,
};

const messages = defineMessages({
  header: {
    id: "username-reminder.story.header",
  },
  ariaProgressLabel: {
    id: "username-reminder.aria-progress-label",
  },
});

export const UsernameReminder = () => {
  const intl = useIntl();
  const location = useLocation();
  const safeNavigation = useSafeNavigation();
  const history = useHistory();
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);

  return (
    <PageStripped>
      <div className="username-reminder-story">
        <Story
          ariaLabelProgress={() =>
            intl.formatMessage(messages.ariaProgressLabel, {
              current: currentIndex + 1,
              total: storyLength,
            })
          }
          header={intl.formatMessage(messages.header)}
          onExit={() =>
            history.push(getNavLink(LOGIN_USERNAME_PASSWORD_PAGE_URL))
          }
          showBack={false}
          showClose={true}
          onBack={() => history.goBack()}
          transitionKey={currentIndex.toString()}
          progress={storyProgress}
        >
          <Switch {...{ order: currentIndex }} location={location}>
            <Route path={ROUTES.INTRO} exact>
              <Intro
                next={() => {
                  safeNavigation(ROUTES.DONE);
                }}
              />
              <Contact />
            </Route>

            <Route path={ROUTES.DONE}>
              <Done />
              <Contact />
            </Route>
          </Switch>
        </Story>
      </div>
    </PageStripped>
  );
};
