import { Button, Card } from "@lysaab/ui-2";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { getNavLink } from "../../../../../../../hooks/useCountryUrls";
import { WITHDRAWAL_PAGE_URL } from "../../../../../../../pages/withdrawal/overview/WithdrawalPage";
import "./Done.scss";

export const Done: React.VFC = () => {
  return (
    <div className="add-autogiro-account-done">
      <h1>
        <FormattedMessage id="sweden.deposits.add.autogiro.done.header" />
      </h1>
      <Card>
        <p className="done-text">
          <FormattedMessage id="sweden.deposits.add.autogiro.done.text" />
        </p>
      </Card>
      <Button
        block
        component={Link}
        to={getNavLink(WITHDRAWAL_PAGE_URL)}
        label={<FormattedMessage id="sweden.deposits.add.autogiro.done.back" />}
      />
    </div>
  );
};
