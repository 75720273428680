import { Button, Typography, LysaLink } from "@lysaab/ui-2";
import { useContext, useEffect, useState, VoidFunctionComponent } from "react";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { MESSAGES_PAGE_URL } from "../../../../../../pages/messages/MessagesPage";
import { Link } from "react-router-dom";
import { SavingsInterestCard } from "../../../../components/savingsInterestCardContent/SavingsInterestCard";
import "./Info.scss";
import { FeesCard } from "./feesCard/FeesCard";
import { ContactPhoneNumbers } from "../../../../../../components/Contact";
import { useIntl } from "react-intl";
import { LysaCountry } from "@lysaab/shared";
import { LocalizationContext } from "../../../../../../context/LocalizationContext";
import {
  dataSavingsAccount,
  EffectiveInterestRateResponse,
} from "../../../../../../data/dataSavingsAccount";
import { InfoModal } from "./components/infoModal/InfoModal";
import { Video } from "./components/video/Video";
import sparkontoPoster from "./sparkonto-video-poster.jpg";

interface Props {
  next: () => void;
}

export const Info: VoidFunctionComponent<Props> = ({ next }) => {
  const localizationContext = useContext(LocalizationContext);
  const intl = useIntl();
  const [interestRate, setInterestRate] =
    useState<EffectiveInterestRateResponse>();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dataSavingsAccount
      .getInterestRate()
      .then(setInterestRate)
      .catch(() => setIsError(true))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="create-savings-info">
      <div>
        <Video
          src="https://r2.lysa.se/sparkonto_auto_3.mp4"
          poster={sparkontoPoster}
        />
      </div>
      <Typography type="body" className="create-savings-info__ingress">
        <TranslatedText id="sweden.createSavingsAccount.info.ingress" />
      </Typography>
      <InfoModal />
      <SavingsInterestCard
        interestRate={interestRate}
        isError={isError}
        isLoading={isLoading}
        showElevioLink={false}
      />
      <FeesCard
        interestRate={interestRate}
        isError={isError}
        isLoading={isLoading}
      />
      {!isError && (
        <Button
          onClick={() => next()}
          block
          label={
            <TranslatedText id="sweden.createAccount.info.createSavingsButton" />
          }
        />
      )}
      <Typography type="body-small">
        <TranslatedText
          id="sweden.createSavingsAccount.info.disclaimer"
          values={{
            link: (parts) => (
              <LysaLink component={Link} to={getNavLink(MESSAGES_PAGE_URL)}>
                {parts}
              </LysaLink>
            ),
            phone: () => {
              const phoneNumber = intl.formatMessage(
                ContactPhoneNumbers[
                  localizationContext.state.country || LysaCountry.SWEDEN
                ]
              );
              return (
                <LysaLink href={`tel:${phoneNumber}`}>{phoneNumber}</LysaLink>
              );
            },
          }}
        />
      </Typography>
    </div>
  );
};
