import { Button, Card, StatusLabel, STATUS_TYPE } from "@lysaab/ui-2";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { generatePath, Link, useLocation } from "react-router-dom";
import {
  AllShareAccountInvitation,
  isShareAccountReceivedInvitation,
  isShareAccountSentInvitation,
  ShareAccountInvitationStatus,
  ShareAccountReceivedInvitation,
  ShareAccountSentInvitation,
} from "../../data/dataAttorney";
import { dataPerformance } from "../../data/dataPerformance";
import { getNavLink } from "../../hooks/useCountryUrls";
import "./InvitationCard.scss";

interface Props {
  invitation: AllShareAccountInvitation;
  viewMoreUrl?: string;
}

export const InvitationCard: React.VFC<Props> = ({
  invitation,
  viewMoreUrl,
}) => {
  const location = useLocation();

  return (
    <Card className="share-account-invitation-card">
      <div className="card-header">
        <div className="text">
          <strong>
            {invitation.accountName ? (
              invitation.accountName
            ) : (
              <FormattedMessage id="shareAccountInvitations.invitationCard.unknownAccount" />
            )}
          </strong>
          <span className="date">
            {dataPerformance.toStringDate(
              dataPerformance.toJsDate(invitation.created)
            )}
          </span>
        </div>

        <Status status={invitation.status} />
      </div>
      {isShareAccountSentInvitation(invitation) && (
        <SentInformation invitation={invitation} />
      )}
      {isShareAccountReceivedInvitation(invitation) && (
        <ReceivedInformation invitation={invitation} />
      )}
      {viewMoreUrl && (
        <Button
          block
          component={Link}
          to={{
            pathname: getNavLink(
              generatePath(viewMoreUrl, {
                invitationId: invitation.invitationId,
              })
            ),
            state: {
              returnUrl: location.pathname + location.search,
            },
          }}
          size="small"
          className="more-button"
          label={
            <FormattedMessage id="shareAccountInvitations.invitationCard.showMoreButtonText" />
          }
        />
      )}
    </Card>
  );
};

interface SentInformationProps {
  invitation: ShareAccountSentInvitation;
}

const SentInformation: React.VFC<SentInformationProps> = ({ invitation }) => {
  return (
    <>
      <div>{invitation.inviteeName}</div>
    </>
  );
};

interface ReceivedInformationProps {
  invitation: ShareAccountReceivedInvitation;
}

const ReceivedInformation: React.VFC<ReceivedInformationProps> = ({
  invitation,
}) => {
  return (
    <>
      <div>{invitation.ownerName}</div>
    </>
  );
};

const statusMessages = defineMessages({
  created: {
    id: "shareAccountInvitationStatus.created",
  },
  acceptedInvitee: {
    id: "shareAccountInvitationStatus.accepted-invitee",
  },
  acceptedOwner: {
    id: "shareAccountInvitationStatus.accepted-owner",
  },
  timedOut: {
    id: "shareAccountInvitationStatus.timed-out",
  },
  declinedOwner: {
    id: "shareAccountInvitationStatus.declined-owner",
  },
  declinedInvitee: {
    id: "shareAccountInvitationStatus.declined-invitee",
  },
});

const StatusTranslation: Record<ShareAccountInvitationStatus, { id: string }> =
  {
    [ShareAccountInvitationStatus.CREATED]: statusMessages.created,
    [ShareAccountInvitationStatus.ACCEPTED_INVITEE]:
      statusMessages.acceptedInvitee,
    [ShareAccountInvitationStatus.ACCEPTED_OWNER]: statusMessages.acceptedOwner,
    [ShareAccountInvitationStatus.TIMED_OUT]: statusMessages.timedOut,
    [ShareAccountInvitationStatus.DECLINED_OWNER]: statusMessages.declinedOwner,
    [ShareAccountInvitationStatus.DECLINED_INVITEE]:
      statusMessages.declinedInvitee,
  };

const StatusType: Record<ShareAccountInvitationStatus, STATUS_TYPE> = {
  [ShareAccountInvitationStatus.CREATED]: STATUS_TYPE.INFO,
  [ShareAccountInvitationStatus.ACCEPTED_INVITEE]: STATUS_TYPE.INFO,
  [ShareAccountInvitationStatus.ACCEPTED_OWNER]: STATUS_TYPE.SUCCESS,
  [ShareAccountInvitationStatus.TIMED_OUT]: STATUS_TYPE.ERROR,
  [ShareAccountInvitationStatus.DECLINED_OWNER]: STATUS_TYPE.ERROR,
  [ShareAccountInvitationStatus.DECLINED_INVITEE]: STATUS_TYPE.ERROR,
};

const Status = ({ status }: { status: ShareAccountInvitationStatus }) => {
  const intl = useIntl();
  return (
    <StatusLabel
      className="status-label"
      type={StatusType[status]}
      icon={false}
    >
      {intl.formatMessage(StatusTranslation[status])}
    </StatusLabel>
  );
};
