import {
  ValidationResponse,
  VALIDATION_STATE,
} from "@lysaab/ui-2/hooks/useValidation";
import { BaseValidator } from "@lysaab/ui-2/validators/BaseValidator";
import { dataAccounts, normalizeAccountName } from "../../data/dataAccounts";

export class AccountNameAlreadyExistsValidator extends BaseValidator {
  name = "AccountNameAlreadyExistsValidator";
  private existingName: string;

  constructor(existingName: string, error: string) {
    super(error);
    this.existingName = existingName;
  }

  getId() {
    return this.getBaseId() + this.existingName;
  }

  validate(value: string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve, reject) => {
      const normalizedAccountName = normalizeAccountName(value);
      if (normalizedAccountName === this.existingName) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
        return;
      }
      dataAccounts
        .getAllAccounts()
        .then((data) => {
          const accounts = [
            ...data.investmentAccounts,
            ...data.savingsAccounts,
          ];
          const account = accounts.find(
            (item) => item.name === normalizedAccountName
          );
          if (account) {
            resolve({
              status: VALIDATION_STATE.FAILED,
              message: this.error,
              hideError: true,
            });
          } else {
            resolve({ status: VALIDATION_STATE.SUCCESS });
          }
        })
        .catch((error) => reject(error));
    });
  }
}
