import { LysaCountry } from "@lysaab/shared";
import {
  Card,
  Form,
  LysaFormRef,
  MaxValidator,
  MinValidator,
  MoneyInput,
  Button,
  RequiredValidator,
  Select,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { useEffect, useRef, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { useLocalizationContext } from "../../../../../context/LocalizationContext";
import { ElevioLink } from "../../../../../components/elevio/ElevioLink";
import {
  dataDirectDebit,
  MonthlyDepositAccountRestrictionResponse,
} from "../../../../../data/dataDirectDebit";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { useMonthlyContext } from "../MonthlyContext";
import { BASE_ROUTES } from "../MonthlyStory";

interface Props {
  next: () => void;
}

const messages = defineMessages({
  amountLabel: {
    id: "deposits.monthly.story.amount.label",
  },
  amountRequired: {
    id: "deposits.monthly.story.amount.required",
  },
  amountMin: {
    id: "deposits.monthly.story.amount.min",
  },
  amountMax: {
    id: "deposits.monthly.story.amount.max",
  },
  dayLabel: {
    id: "deposits.monthly.story.day.label",
  },
  dayPlaceholder: {
    id: "deposits.monthly.story.day.placeHolder",
  },
  dayRequired: {
    id: "deposits.monthly.story.day.required",
  },
});

const MIN_AMOUNT = 20;
const daysOfMonth = Array.from(Array(31).keys()).map((day) => day + 1);

export const MonthlyAmount = ({ next }: Props) => {
  const formRef = useRef<LysaFormRef>();
  const { currency, country } = useLocalizationContext().state;
  const [currentRestrictions, setCurrentRestrictions] =
    useState<MonthlyDepositAccountRestrictionResponse>();
  const monthlyContext = useMonthlyContext();
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const history = useHistory();
  const moneyInputValidators = [
    new RequiredValidator(intl.formatMessage(messages.amountRequired)),
    new MinValidator(
      MIN_AMOUNT,
      intl.formatMessage(messages.amountMin, {
        minAmount: intl.formatNumber(MIN_AMOUNT, {
          style: "currency",
          currency,
        }),
      })
    ),
  ];
  const elevioArticleId: Record<LysaCountry, number> = {
    [LysaCountry.SWEDEN]: 149,
    [LysaCountry.FINLAND]: 149,
    [LysaCountry.DENMARK]: 149,
    [LysaCountry.GERMANY]: 290,
    [LysaCountry.SPAIN]: 149,
  };

  useEffect(() => {
    if (typeof monthlyContext.state.externalAccount === "undefined") {
      return history.replace(getNavLink(BASE_ROUTES.INTRO));
    }
    dataDirectDebit
      .getAccountRestriction(monthlyContext.state.externalAccount)
      .then(setCurrentRestrictions)
      .catch(() => {
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  }, [history, monthlyContext.state.externalAccount]);

  if (isLoading || !currentRestrictions || !country) {
    return <Spinner />;
  }

  return (
    <div className="monthly-amount">
      <h2>
        <TranslatedText id="deposits.monthly.story.amount.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            return;
          }
          next();
        }}
      >
        <Card>
          {isError && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="deposits.monthly.story.amount.error" />
            </Snackbar>
          )}
          {currentRestrictions.limited && (
            <p>
              <TranslatedText
                id="deposits.monthly.story.amount.unconfirmedInfo"
                values={{
                  help: (parts) => (
                    <ElevioLink
                      linkText={parts}
                      articleId={elevioArticleId[country]}
                    />
                  ),
                }}
              />
            </p>
          )}
          <MoneyInput
            currency={currency}
            value={monthlyContext.state.amount}
            onChange={(amount) => monthlyContext.setState({ amount })}
            label={intl.formatMessage(messages.amountLabel)}
            validators={
              currentRestrictions.limited
                ? [
                    ...moneyInputValidators,
                    new MaxValidator(
                      currentRestrictions.amountLimit,
                      intl.formatMessage(messages.amountMax, {
                        maxAmount: intl.formatNumber(
                          currentRestrictions.amountLimit,
                          {
                            style: "currency",
                            currency,
                          }
                        ),
                      })
                    ),
                  ]
                : moneyInputValidators
            }
          />
          <Select
            alternatives={daysOfMonth.map((day) => {
              return {
                text: day.toString(),
                value: day,
              };
            })}
            label={intl.formatMessage(messages.dayLabel)}
            placeholder={intl.formatMessage(messages.dayPlaceholder)}
            onChange={(day) =>
              monthlyContext.setState({ withdrawalDay: day.value })
            }
            value={{
              text: monthlyContext.state.withdrawalDay.toString(),
              value: monthlyContext.state.withdrawalDay,
            }}
            validators={[
              new RequiredValidator(intl.formatMessage(messages.dayRequired)),
            ]}
          />
          <p>
            <TranslatedText
              id="deposits.monthly.story.day.info"
              values={{
                nextDate: intl.formatDate(
                  currentRestrictions?.nextPossibleTargetDate
                ),
              }}
            />
          </p>
        </Card>
        <Button
          type="submit"
          block
          label={<TranslatedText id="deposits.monthly.story.amount.next" />}
        />
      </Form>
    </div>
  );
};
