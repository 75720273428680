import { Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import {
  ShareAccountInvitation,
  ShareAccountSentInvitation,
} from "../../../data/dataAttorney";
import { ListItemAccountName } from "../listItems/ListItemAccountName";
import { ListItemCancel } from "../listItems/ListItemCancel";
import { ListItemExpiry } from "../listItems/ListItemExpiry";

interface Props {
  invitation: ShareAccountInvitation | ShareAccountSentInvitation;
  onCancel: () => void;
}

export const ReadState: React.VFC<Props> = ({ invitation, onCancel }) => {
  return (
    <div>
      <Snackbar type={SNACKBAR_TYPES.INFO}>
        <FormattedMessage id="shareAccountStory.inviteCard.inviteOpenedText" />
      </Snackbar>
      <hr />
      {invitation.accountName && (
        <ListItemAccountName accountName={invitation.accountName} />
      )}
      {invitation.expiresAt && (
        <ListItemExpiry expiryDate={invitation.expiresAt} />
      )}
      <ListItemCancel onCancel={onCancel} />
    </div>
  );
};
