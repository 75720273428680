import { FunctionComponent } from "react";
import { GenericBankIcon } from "../../../../../../components/bankIcon/banks/genericBankIcon/GenericBankIcon";
import { CreateAccountTypeLogo } from "../../../../../../components/accountType/AccountTypeLogo";

import "./TransferTypeIcon.scss";

type PersonTransferType = "SAVINGS_ACCOUNT" | "EXTERNAL";

type CorporationTransferType = "KF" | "VP";

export type TransferTypeIcons = PersonTransferType | CorporationTransferType;

interface Props {
  type: TransferTypeIcons;
}

export const TransferTypeIcon: FunctionComponent<Props> = ({ type }) => {
  if (type === "SAVINGS_ACCOUNT") {
    return <CreateAccountTypeLogo color="#7842F5" text="SPAR" size={40} />;
  } else {
    if (type === "KF") {
      return <GenericBankIcon size={40} />;
    }
    if (type === "VP") {
      return <GenericBankIcon size={40} />;
    }
    if (type === "EXTERNAL") {
      return <GenericBankIcon size={40} />;
    }
    return null;
  }
};
