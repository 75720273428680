import { useEffect, useState, VoidFunctionComponent } from "react";

import { Spinner } from "@lysaab/ui-2";
import { useHistory } from "react-router";
import {
  dataInvestments,
  getPensionAccountQuestions,
  GetPensionSuitabilityAssessmentRequest,
  GetSuitabilityAssessmentResponse,
} from "../../../../../data/dataInvestments";
import { getUserAge } from "../utils/userAge";
import { useTransfer } from "../TransferContext";
import { useUser } from "../../../../../context/UserContext";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { TRANSFER_PENSIONS_URL } from "../TransferPensionsStory";
import { useLanguage } from "../../../../../context/LocalizationContext";
import { ConfirmEsgUpdate } from "../../../../../pageComponents/confirmEsgUpdate/ConfirmEsgUpdate";

interface Props {
  next: () => void;
}

export const ConfirmEsgUpdateWrapper: VoidFunctionComponent<Props> = ({
  next,
}) => {
  const [advise, setAdvise] = useState<GetSuitabilityAssessmentResponse>();
  const language = useLanguage();
  const [transfer] = useTransfer();
  const user = useUser();
  const age = getUserAge(user.tin);
  const history = useHistory();

  /**
   * Safe guard in case we loose context. On refresh etc. Send user back to start of story.
   */
  useEffect(() => {
    if (typeof transfer?.moves[0]?.institute === "undefined") {
      history.replace(getNavLink(TRANSFER_PENSIONS_URL));
    }
  }, [history, transfer?.moves]);

  useEffect(() => {
    const data: Partial<GetPensionSuitabilityAssessmentRequest> = {
      language: language,
      age: age,
      ...getPensionAccountQuestions(transfer),
    };

    dataInvestments.getNewPensionAccountSuitability(data).then((advise) => {
      if (!advise.esgResult.esgBestMatch) {
        return next();
      }
      setAdvise(advise);
    });
  }, [transfer, language, next, age]);

  if (!advise) {
    return <Spinner />;
  }

  return (
    <ConfirmEsgUpdate
      esg={advise.esgResult.esgAnswers}
      updatedEsg={advise.esgResult.esgBestMatch!}
      next={next}
    />
  );
};
