import {
  dataUnauthenticatedTrackingService as uts,
  TrackerEvent,
  TrackRequest,
} from "../../data/dataUnauthenticatedTracking";
import type { EventTrack as NetsEventTrack } from "@lysaab/lysa-nets";

const DEBOUNCE_INTERVAL = 500;

interface DefaultTrack {
  event: TrackerEvent.USER_AGENT;
  message?: string | { [key: string]: string | number };
}

type TrackProps = NetsEventTrack | DefaultTrack;

type TrackWithMessage = {
  message: string | { [key: string]: string | number };
};

function isTrackWithMessage(track: any): track is TrackWithMessage {
  return (track as TrackWithMessage).message !== undefined;
}

class EventTrackerImpl {
  private seq = 0;
  private trackQueue: TrackRequest[] = [];
  private debounceInterval: NodeJS.Timeout | undefined;
  public logId: string | undefined;

  public setLogId = (logId: string) => {
    this.logId = logId;
  };

  public track = (track: TrackProps) => {
    const message = isTrackWithMessage(track) ? track.message : undefined;

    if (this.seq === 0) {
      this.sendUserAgent();
    }
    this.seq++;
    let messageString: string | undefined;
    if (message && typeof message !== "string") {
      try {
        messageString = JSON.stringify(message);
      } catch (e) {
        messageString = "Unknown error";
      }
    } else {
      messageString = message;
    }
    this.trackQueue.push({
      seq: this.seq,
      uri: window.location.pathname,
      event: track.event,
      message: messageString,
    });
    if (this.debounceInterval) {
      clearInterval(this.debounceInterval);
    }
    this.debounceInterval = setTimeout(this.sendTracking, DEBOUNCE_INTERVAL);
  };

  private sendTracking = () => {
    if (!this.logId) {
      return;
    }

    const queue = [...this.trackQueue];
    this.trackQueue = [];
    uts
      .sendTracking(this.logId, queue)
      .catch(() => console.log("Failed to send events"));
  };

  public sendUserAgent = () => {
    this.trackQueue.push({
      seq: 0,
      uri: window.location.pathname,
      event: TrackerEvent.USER_AGENT,
      message: navigator.userAgent,
    });
  };

  public reset = () => {
    this.seq = 0;
  };
}

export const UtsTracker = new EventTrackerImpl();
