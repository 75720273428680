import { VoidFunctionComponent } from "react";

interface Props {
  size?: number;
}

export const MonthlyDepositIcon: VoidFunctionComponent<Props> = ({
  size = 40,
}) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_7274_12038)">
        <circle cx="20" cy="20" r="20" fill="#F1F1F1" />
        <path
          d="M17 11C17 10.4477 16.5523 10 16 10C15.4477 10 15 10.4477 15 11V12H14C12.3431 12 11 13.3431 11 15V27C11 28.6569 12.3431 30 14 30H18.8027C18.4513 29.3926 18.2034 28.7179 18.083 28H14C13.4477 28 13 27.5523 13 27V19H23.1C23.25 18.2611 23.5629 17.5814 23.9996 17H13V15C13 14.4477 13.4477 14 14 14H26C26.5523 14 27 14.4477 27 15V15.1C27.3231 15.0344 27.6575 15 28 15C28.3425 15 28.6769 15.0344 29 15.1V15C29 13.3431 27.6569 12 26 12H25V11C25 10.4477 24.5523 10 24 10C23.4477 10 23 10.4477 23 11V12H17V11Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M28 27C28 29.2091 26.2091 31 24 31C21.7909 31 20 29.2091 20 27C20 24.7909 21.7909 23 24 23C26.2091 23 28 24.7909 28 27ZM26 27C26 28.1046 25.1046 29 24 29C22.8954 29 22 28.1046 22 27C22 25.8954 22.8954 25 24 25C25.1046 25 26 25.8954 26 27Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M28 23C29.6569 23 31 21.6569 31 20C31 18.3431 29.6569 17 28 17C26.3431 17 25 18.3431 25 20C25 21.6569 26.3431 23 28 23ZM28 21C28.5523 21 29 20.5523 29 20C29 19.4477 28.5523 19 28 19C27.4477 19 27 19.4477 27 20C27 20.5523 27.4477 21 28 21Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_7274_12038">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
