import { FunctionComponent, useEffect, useState } from "react";
import { Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { Consent, dataAutogiro } from "../../../../../data/dataAutogiro";
import { ExternalAccount } from "../../../../../data/dataWithdrawals";
import { MissingExternalAccount } from "../../../../../components/missingExternalAccount/MissingExternalAccount";

interface Props {
  showMissingAccounts?: boolean;
  externalAccounts?: ExternalAccount[];
}

export const WaitingAccounts: FunctionComponent<Props> = ({
  showMissingAccounts = false,
  externalAccounts = [],
}) => {
  const [waitingAccounts, setWaitingAccounts] = useState<Consent[]>([]);

  useEffect(() => {
    dataAutogiro.getConsents().then((response) => {
      setWaitingAccounts([...response].filter((account) => !account.approved));
    });
  }, []);

  if (waitingAccounts.length === 0) {
    return showMissingAccounts ? (
      <MissingExternalAccount externalAccounts={externalAccounts} />
    ) : null;
  }

  return (
    <Snackbar type={SNACKBAR_TYPES.INFO} icon>
      <TranslatedText
        id="withdrawals.accounts.waiting.text"
        values={{
          accounts: waitingAccounts.length,
          accountNumbers: waitingAccounts
            .map((a) => a.externalBankAccount)
            .join(", "),
        }}
      />
    </Snackbar>
  );
};
