import {
  useReducer,
  createContext,
  FunctionComponent,
  useContext,
} from "react";
import { CompoundAccount } from "../../data/dataAccounts";
import { ShareAccountInvitation } from "../../data/dataAttorney";

export interface ShareAccountState {
  account?: CompoundAccount;
  invitation?: ShareAccountInvitation;
}

export interface ShareAccountContextProps {
  state: ShareAccountState;
  setState: (newState: Partial<ShareAccountState>) => void;
}

const ShareAccountContext = createContext<ShareAccountContextProps | undefined>(
  undefined
);

function stateReducer(
  state: ShareAccountState,
  newState: Partial<ShareAccountState>
) {
  return { ...state, ...newState };
}

export const ShareAccountContextProvider: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {});

  return (
    <ShareAccountContext.Provider value={{ state, setState }}>
      {children}
    </ShareAccountContext.Provider>
  );
};

export function useShareAccountContext() {
  const shareAccountContext = useContext(ShareAccountContext);

  if (typeof shareAccountContext === "undefined") {
    throw new Error(
      "useShareAccountContext must be used within a ShareAccountContext"
    );
  }

  return shareAccountContext;
}
