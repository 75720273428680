import { DateTime } from "luxon";
import * as React from "react";
import { matchPath, useLocation } from "react-router";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../OverviewPage";
import { Valentines } from "./Valentines";
import { Fireworks } from "fireworks-js/dist/react";
import "./HolidayAnimations.scss";

const now = DateTime.now();
const isNewYear =
  (now.day >= 30 && now.month === 12) || (now.day <= 2 && now.month === 1);
const isXmas = now.month === 12 || now.month === 1;
const isValentines = now.month === 2 && now.day === 14;

export const HolidayAnimations = () => {
  const location = useLocation();

  const isOnOverview = matchPath(location.pathname, {
    path: getNavLink(OVERVIEW_PAGE_URL),
    exact: true,
  });
  const showNewYear = isOnOverview && isNewYear;
  const showXmas = isOnOverview && !showNewYear && isXmas;
  const showValentines = isOnOverview && isValentines;

  const fireworksStyles: React.CSSProperties = {
    top: 0,
    left: 0,
    width: "100%",
    height: "125%",
    position: "absolute",
  };

  return (
    <>
      {showNewYear && (
        <Fireworks
          options={{
            delay: {
              min: 80,
              max: 100,
            },
            rocketsPoint: 50,
            opacity: 0.75,
            speed: 0.5,
            acceleration: 1.02,
            friction: 0.93,
            gravity: 1.5,
            particles: 90,
            trace: 10,
            explosion: 6,
            autoresize: true,
          }}
          style={fireworksStyles}
        />
      )}
      {showXmas && (
        <div className="snowflakes" aria-hidden="true">
          <div className="snowflake">❅</div>
          <div className="snowflake">❆</div>
          <div className="snowflake">❅</div>
          <div className="snowflake">❆</div>
          <div className="snowflake">❅</div>
          <div className="snowflake">❆</div>
          <div className="snowflake">❅</div>
          <div className="snowflake">❆</div>
          <div className="snowflake">❅</div>
          <div className="snowflake">❆</div>
          <div className="snowflake">❅</div>
          <div className="snowflake">❆</div>
        </div>
      )}
      {showValentines && <Valentines />}
    </>
  );
};
