import { Card, Button, Snackbar, SNACKBAR_TYPES, Spinner } from "@lysaab/ui-2";
import {
  Fragment,
  useContext,
  useEffect,
  VoidFunctionComponent,
  useState,
} from "react";
import { useHistory } from "react-router";
import { TranslatedText } from "../../../components/TranslatedText";
import { CrsContext } from "../context/CrsContext";
import { dataCrs, mapCrsResponseToRequest } from "../../../data/dataCrs";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { NOT_FOUND_URL } from "../../NotFoundPage";
import { getCountryFromCountryCode } from "../../../utils/getCountryFromCountryCode";
import {
  LocalizationContext,
  useCountry,
} from "../../../context/LocalizationContext";

import "./ConfirmCrsInformation.scss";

interface Props {
  next: () => void;
}

export const ConfirmCrsInformation: VoidFunctionComponent<Props> = ({
  next,
}) => {
  const crsInformation = useContext(CrsContext).state.crsInformation;
  const language = useContext(LocalizationContext).state.language;
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const country = useCountry();

  useEffect(() => {
    if (typeof crsInformation === "undefined") {
      return history.replace(getNavLink(NOT_FOUND_URL));
    }
  }, [crsInformation, history]);

  const postCrsInformation = () => {
    if (crsInformation) {
      setIsLoading(true);
      dataCrs
        .postUpdateCrs(mapCrsResponseToRequest(crsInformation, country))
        .then(() => {
          next();
        })
        .catch(() => {
          setIsLoading(false);
          setError(true);
        });
    }
  };

  if (isLoading || typeof crsInformation === "undefined") return <Spinner />;

  return (
    <div className="confirm-crs-information">
      <h1>
        <TranslatedText id="updateCrsStory.confirmCrsInformation.header" />
      </h1>
      <Card>
        {error && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="updateCrsStory.confirmCrsInformation.error" />
          </Snackbar>
        )}
        <p>
          <TranslatedText id="updateCrsStory.confirmCrsInformation.ingress" />
        </p>
        <dl>
          {crsInformation.countries.map((country) => {
            return (
              <Fragment key={country.country}>
                <div className="list-row">
                  <dt>
                    <TranslatedText id="updateCrsStory.confirmCrsInformation.taxResidence" />
                  </dt>
                  <dd>
                    {getCountryFromCountryCode({
                      country: country.country,
                      language,
                    }) + " "}
                    ({country.country})
                  </dd>
                </div>
                {/* Since we add a fake home country row in the list, we don't want to show an empty tin row */}
                {country.tin && (
                  <div className="list-row">
                    <dt>
                      <TranslatedText
                        id="updateCrsStory.confirmCrsInformation.tin"
                        values={{ country: country.country }}
                      />
                    </dt>
                    <dd>{country.tin}</dd>
                  </div>
                )}
              </Fragment>
            );
          })}
          <div className="list-row">
            <dt>
              <TranslatedText id="updateCrsStory.confirmCrsInformation.countryOfResidence" />
            </dt>
            <dd>
              {getCountryFromCountryCode({
                country: crsInformation.residentCountry,
                language,
              }) + " "}
              ({crsInformation?.residentCountry})
            </dd>
          </div>
          <div className="list-row">
            <dt>
              <TranslatedText id="updateCrsStory.confirmCrsInformation.street" />
            </dt>
            <dd>{crsInformation.street}</dd>
          </div>
          <div className="list-row">
            <dt>
              <TranslatedText id="updateCrsStory.confirmCrsInformation.postalCode" />
            </dt>
            <dd>{crsInformation.zipCode}</dd>
          </div>
          <div className="list-row">
            <dt>
              <TranslatedText id="updateCrsStory.confirmCrsInformation.city" />
            </dt>
            <dd>{crsInformation.city}</dd>
          </div>
        </dl>
        <p>
          <TranslatedText id="updateCrsStory.confirmCrsInformation.endText" />
        </p>
      </Card>
      <Button
        onClick={() => {
          postCrsInformation();
        }}
        block
        label={
          <TranslatedText id="updateCrsStory.updateCrsInformation.button.submit" />
        }
      />
    </div>
  );
};
