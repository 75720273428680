import { useCallback, useContext, useEffect } from "react";
import { EventTracker } from "../../../../../../../components/eventTracker/EventTracker";
import { BankSelection } from "../../../../../../../components/klarna/bankSelection/BankSelection";
import * as H from "history";
import { TrackerEvent } from "../../../../../../../data/dataCustomerTracking";
import { AvailableBank } from "../../../../../../../data/dataKlarna";
import { getNavLink } from "../../../../../../../hooks/useCountryUrls";
import { AutogiroDepositContext } from "../AutogiroDepositContext";
import { AUTOGIRO_DEPOSIT_PAGE_URL, BASE_ROUTES } from "../AutogiroDepositPage";
import {
  AddDepositAutogiroAccountLocationState,
  ADD_DEPOSIT_AUTOGIRO_URL,
} from "../../addAccount/AddDepositAccountAutogiroStory";
import { TranslatedText } from "../../../../../../../components/TranslatedText";
import { useIsPerson } from "../../../../../../../hooks/useIsPerson";
import { useHistory } from "react-router";
import { OVERVIEW_PAGE_URL } from "../../../../../../../pages/overview/OverviewPage";

interface Props {
  next: () => void;
}

export function BankSelectionWrapper({ next }: Props) {
  const autogiroDepositContext = useContext(AutogiroDepositContext);
  const setState = autogiroDepositContext.setState;
  const isPerson = useIsPerson();
  const history = useHistory();

  useEffect(() => {
    if (typeof autogiroDepositContext.state.lysaAccount === "undefined") {
      history.replace(getNavLink(AUTOGIRO_DEPOSIT_PAGE_URL));
    }
  }, [autogiroDepositContext.state.lysaAccount, history]);

  useEffect(() => {
    if (!isPerson) {
      console.error("Company reached Autogiro-deposit Klarna flow");
      history.replace(OVERVIEW_PAGE_URL);
    }
  }, [history, isPerson]);

  const onComplete = useCallback(
    (bank: AvailableBank) => {
      setState({ bank });
      EventTracker.track({
        event: TrackerEvent.ADD_MONTHLY_KLARNA_BANK,
        message: bank.displayName,
      });
      setTimeout(() => {
        next();
      }, 0);
    },
    [next, setState]
  );

  const missingBankLocation: H.LocationDescriptor<AddDepositAutogiroAccountLocationState> =
    {
      pathname: getNavLink(ADD_DEPOSIT_AUTOGIRO_URL),
      state: {
        returnUrl: getNavLink(BASE_ROUTES.EXTERNAL_ACCOUNT_SELECTION),
        returnState: autogiroDepositContext.state,
      },
    };

  return (
    <BankSelection
      onComplete={onComplete}
      intro={
        <TranslatedText id="sweden.deposits.autogiro.story.bank-selection.intro" />
      }
      missingBank={missingBankLocation}
      missingBankHeader={
        <TranslatedText id="klarna.bank-selection.missing-bank.header" />
      }
      missingBankDescription={
        <TranslatedText id="klarna.bank-selection.missing-bank.description" />
      }
    />
  );
}
