import { defineMessages } from "react-intl";

export const addAccountMessages = defineMessages({
  snackbarSuccess: {
    id: "sweden.companySettings.addAccount.snackbar.success",
  },
  snackbarError: {
    id: "sweden.companySettings.addAccount.snackbar.error",
  },
  accountInputLabel: {
    id: "sweden.companySettings.addAccount.accountInput.label",
  },
  accountInputErrorNumber: {
    id: "sweden.companySettings.addAccount.accountInput.error.number",
  },
  accountInputErrorRequired: {
    id: "sweden.companySettings.addAccount.accountInput.error.required",
  },
  termsTitle: {
    id: "sweden.companySettings.addAccount.terms.checkbox.title",
  },
  termsErrorRequired: {
    id: "sweden.companySettings.addAccount.terms.checkbox.error.required",
  },
  termsAnnouncement: {
    id: "sweden.companySettings.addAccount.terms.announcement",
  },
});

export const feeAccountMessages = defineMessages({
  selectLabel: {
    id: "sweden.companySettings.feeAccount.select.label",
  },
  selectPlaceholder: {
    id: "sweden.companySettings.feeAccount.select.placeholder",
  },
  snackbarSuccess: {
    id: "sweden.companySettings.feeAccount.snackbar.success",
  },
  snackbarError: {
    id: "sweden.companySettings.feeAccount.snackbar.error",
  },
});

export const paidFeesMessages = defineMessages({
  tableDate: {
    id: "sweden.companySettings.paidFees.table.date",
  },
  tableAccount: {
    id: "sweden.companySettings.paidFees.table.account",
  },
  tableFeeIncludingVat: {
    id: "sweden.companySettings.paidFees.table.feeIncludingVat",
  },
  tableFeeExcludingVat: {
    id: "sweden.companySettings.paidFees.table.feeExcludingVat",
  },
  tableFetchReceipt: {
    id: "sweden.companySettings.principals.info.receipt",
  },
});
