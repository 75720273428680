import { Button, SNACKBAR_TYPES, useFlash } from "@lysaab/ui-2";
import * as React from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

const messages = defineMessages({
  success: {
    id: "copyButton.successMessage",
  },
});

interface Props {
  text: string;
}

export const CopyButton: React.VFC<Props> = ({ text }) => {
  const pushFlash = useFlash();
  const intl = useIntl();

  return (
    <Button
      variant="primary"
      size="small"
      onClick={() => {
        navigator.clipboard.writeText(text).then(() => {
          pushFlash({
            text: intl.formatMessage(messages.success),
            type: SNACKBAR_TYPES.INFO,
          });
        });
      }}
      label={<FormattedMessage id="copyButton.copytext" />}
    />
  );
};
