import { defineMessages } from "react-intl";
import { FailedHintCode, PendingHintCode } from "@lysaab/ui-2";

export const signingMessages = defineMessages({
  qrInfo1: {
    id: "withdrawalRequest.kf.bankid.qrInfo1",
  },
  qrInfo2: {
    id: "withdrawalRequest.kf.bankid.qrInfo2",
  },
  qrInfo3: {
    id: "withdrawalRequest.kf.bankid.qrInfo3",
  },
  buttonOpen: {
    id: "withdrawalRequest.kf.bankid.buttonOpen",
  },
  buttonErrorHeader: {
    id: "withdrawalRequest.kf.bankid.buttonErrorHeader",
  },
  buttonRetry: {
    id: "withdrawalRequest.kf.bankid.buttonRetry",
  },
  buttonClose: {
    id: "withdrawalRequest.kf.bankid.buttonClose",
  },
  buttonOtherDevice: {
    id: "withdrawalRequest.kf.bankid.buttonOtherDevice",
  },
});

export const signPendingMessages = defineMessages({
  [PendingHintCode.NOCLIENT]: {
    id: "withdrawalRequest.kf.bankid.hint.pending.noclient",
  },
  [PendingHintCode.OUTSTANDINGTRANSACTION]: {
    id: "withdrawalRequest.kf.bankid.hint.pending.outstandingtransaction",
  },
  [PendingHintCode.STARTED]: {
    id: "withdrawalRequest.kf.bankid.hint.pending.started",
  },
  [PendingHintCode.USERSIGN]: {
    id: "withdrawalRequest.kf.bankid.hint.pending.usersign",
  },
});

export const signFailedMessages = defineMessages({
  [FailedHintCode.CANCELLED]: {
    id: "withdrawalRequest.kf.bankid.hint.failed.cancelled",
  },
  [FailedHintCode.CERTIFICATEERR]: {
    id: "withdrawalRequest.kf.bankid.hint.failed.certificateerr",
  },
  [FailedHintCode.EXPIREDTRANSACTION]: {
    id: "withdrawalRequest.kf.bankid.hint.failed.expiredtransaction",
  },
  [FailedHintCode.STARTFAILED]: {
    id: "withdrawalRequest.kf.bankid.hint.failed.startfailed",
  },
  [FailedHintCode.USERCANCEL]: {
    id: "withdrawalRequest.kf.bankid.hint.failed.usercancel",
  },
  [FailedHintCode.UNKNOWN]: {
    id: "withdrawalRequest.kf.bankid.hint.failed.unknown",
  },
});
