import { PepContext } from "@lysaab/lysa-pep";
import {
  Fragment,
  useContext,
  FunctionComponent,
  ReactNode,
  useEffect,
} from "react";
import { Route, RouteProps, useHistory } from "react-router-dom";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../../overview/OverviewPage";

interface Props {
  children: ReactNode;
}

const DirectAccessGuard: FunctionComponent<Props> = ({ children }) => {
  const pepContext = useContext(PepContext);
  const history = useHistory();
  const search = new URLSearchParams(history.location.search);

  let returnPath = getNavLink(OVERVIEW_PAGE_URL);
  if (search.has("returnUrl")) {
    returnPath = search.get("returnUrl") ?? returnPath;
  }

  useEffect(() => {
    if (pepContext.state.locked) {
      history.replace(returnPath);
    }
  }, [history, pepContext.state.locked, returnPath]);

  return <Fragment>{children}</Fragment>;
};

export const RouteGuard: FunctionComponent<Props & RouteProps> = ({
  children,
  ...props
}) => (
  <Route {...props}>
    <DirectAccessGuard>{children}</DirectAccessGuard>
  </Route>
);
