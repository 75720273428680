import { useContext, useCallback, VoidFunctionComponent } from "react";
import { Spinner } from "@lysaab/ui-2";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import { EditAccountRiskConfirm } from "../../../../pageComponents/editAccountRiskConfirm/EditAccountRiskConfirm";
import { UserContext } from "../../../../context/UserContext";
import { useAccountName } from "../../../../hooks/useAccountName";
import {
  AccountUpdateAction,
  useReviewAccountContext,
} from "../../ReviewAccountContext";
import { UpdateInvestmentAccountComposition } from "../../../../pageComponents/accountsAllocation/accountAdviseCard/AccountAdviceCard";

interface Props {
  next: (reviewAccount: UpdateInvestmentAccountComposition) => void;
}

export const ConfirmationWrapper: VoidFunctionComponent<Props> = ({ next }) => {
  const localizationContext = useContext(LocalizationContext);
  const [reviewAccountState] = useReviewAccountContext();
  const userContext = useContext(UserContext);
  const accountName = useAccountName(
    reviewAccountState.reviewAccount?.accountId,
    true
  );
  const action = reviewAccountState.reviewAccount?.action;

  const risk =
    action === AccountUpdateAction.KEEP
      ? reviewAccountState.reviewAccount?.newAdvice.takenRisk
      : action === AccountUpdateAction.ADVICE
      ? reviewAccountState.reviewAccount?.newAdvice.advice
      : reviewAccountState.reviewAccount?.risk;

  const onNext = useCallback(() => {
    if (!reviewAccountState.reviewAccount) {
      throw new Error("ConfirmationWrapper - missing review account");
    }

    if (!action) {
      throw new Error("ConfirmationWrapper - missing value action");
    }

    if (typeof risk === "undefined") {
      throw new Error("ConfirmationWrapper - missing value risk");
    }

    next({
      ...reviewAccountState.reviewAccount,
      risk,
      action,
    });
  }, [reviewAccountState.reviewAccount, action, risk, next]);

  if (typeof risk === "undefined") {
    return <Spinner />;
  }

  if (!reviewAccountState.reviewAccount || !userContext.state.name) {
    return <Spinner />;
  }

  return (
    <EditAccountRiskConfirm
      values={{
        accountId: reviewAccountState.reviewAccount.accountId,
        advisedRisk:
          reviewAccountState.reviewAccount.newAdvice.advice.toString(),
        takenRisk: risk.toString(),
        investmentType:
          reviewAccountState.reviewAccount.newAdvice.investmentType,
        language: localizationContext.state.language,
        accountName,
        name: userContext.state.name,
      }}
      loading={false}
      hasError={false}
      next={onNext}
    />
  );
};
