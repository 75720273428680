import { VoidFunctionComponent } from "react";
import cx from "classnames";
import { Typography } from "@lysaab/ui-2";
import { Amount } from "../../../../../../../../components/amount/Amount";

import "./SavingsSummaryNumber.scss";

interface Props {
  label: string;
  amount: number | undefined;
  showSign?: boolean;
  large?: boolean;
  decimals?: number;
}

export const SavingsSummaryNumber: VoidFunctionComponent<Props> = ({
  label,
  amount,
  showSign,
  large = false,
  decimals,
}) => (
  <div className="savings-summary-number">
    <div className="savings-summary-number__label">
      <Typography type="label">{label}</Typography>
    </div>
    <div
      className={cx("savings-summary-number__amount", {
        "savings-summary-number__amount--large": large,
        "savings-summary-number__amount--loading":
          typeof amount === "undefined",
      })}
    >
      {typeof amount === "undefined" ? (
        "-"
      ) : (
        <Amount
          amount={amount}
          showSign={showSign}
          styled={false}
          decimals={decimals}
        />
      )}
    </div>
  </div>
);
