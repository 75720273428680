import { Card, ServerError, SNACKBAR_TYPES, useFlash } from "@lysaab/ui-2";
import { useEffect, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import {
  dataAttorney,
  isShareAccountSentInvitation,
  ShareAccountInvitation,
  ShareAccountInvitationId,
  ShareAccountInvitationStatus,
  ShareAccountSentInvitation,
} from "../../data/dataAttorney";
import "./ShareAccountInviteCard.scss";
import { AnimatePresence, motion } from "framer-motion";
import { TranslatedText } from "../TranslatedText";
import { CreatedState } from "./states/CreatedState";
import { AcceptedInviteeState } from "./states/AcceptedInviteeState";
import { AcceptedOwnerState } from "./states/AcceptedOwnerState";
import { DeclinedState } from "./states/DeclinedState";
import { TimedOutState } from "./states/TimedOutState";
import { ReadState } from "./states/ReadState";
import { ShareAccountInviteCardDocs } from "./ShareAccountInviteCardDocs";

const messages = defineMessages({
  shareAlreadyExistFailText: {
    id: "shareAccountStory.inviteCard.shareAlreadyExistFailText",
  },
});

interface Props {
  invitation: ShareAccountInvitation | ShareAccountSentInvitation;
  refreshInvitation: (
    id: ShareAccountInvitationId
  ) => Promise<ShareAccountInvitation | ShareAccountSentInvitation>;
}

export const ShareAccountInviteCard: React.VFC<Props> = ({
  invitation,
  refreshInvitation,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const pushFlash = useFlash();
  const intl = useIntl();
  const [inviteeName, setInviteeName] = useState<string>();
  const isOpened = Boolean(invitation.inviteeAssigned);
  const isDeclined =
    invitation.status === ShareAccountInvitationStatus.DECLINED_INVITEE ||
    invitation.status === ShareAccountInvitationStatus.DECLINED_OWNER;
  const { status } = invitation;

  useEffect(() => {
    if ("inviteeName" in invitation) {
      setInviteeName(invitation.inviteeName);
    }
  }, [invitation]);

  const cancel = async () => {
    setIsLoading(true);
    try {
      await dataAttorney.declineInvitation(invitation.invitationId);
      await refreshInvitation(invitation.invitationId);
    } finally {
      setIsLoading(false);
    }
  };

  const accept = async () => {
    setIsLoading(true);
    try {
      await dataAttorney.acceptInvitationResponse(invitation.invitationId);
      await refreshInvitation(invitation.invitationId);
    } catch (error) {
      if (error instanceof ServerError && error.status === 409) {
        pushFlash({
          text: intl.formatMessage(messages.shareAlreadyExistFailText),
          type: SNACKBAR_TYPES.ERROR,
        });
        return;
      }
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <motion.div
      layout="size"
      initial={false}
      className="share-account-invite-card"
    >
      <motion.div layout="position">
        <Header invitation={invitation} />
      </motion.div>
      <InformationText invitation={invitation} />
      <Card showLoading={isLoading}>
        <AnimatePresence>
          {status === ShareAccountInvitationStatus.CREATED && !isOpened && (
            <CreatedState invitation={invitation} onCancel={cancel} />
          )}
          {status === ShareAccountInvitationStatus.CREATED && isOpened && (
            <ReadState invitation={invitation} onCancel={cancel} />
          )}
          {status === ShareAccountInvitationStatus.ACCEPTED_INVITEE && (
            <AcceptedInviteeState
              invitation={invitation}
              onCancel={cancel}
              onAccept={accept}
            />
          )}
          {status === ShareAccountInvitationStatus.ACCEPTED_OWNER && (
            <AcceptedOwnerState
              invitation={invitation}
              inviteeName={inviteeName}
            />
          )}
          {status === ShareAccountInvitationStatus.TIMED_OUT && (
            <TimedOutState invitation={invitation} />
          )}
          {isDeclined && <DeclinedState invitation={invitation} />}
        </AnimatePresence>
      </Card>
    </motion.div>
  );
};

interface InvitationProps {
  invitation: ShareAccountInvitation | ShareAccountSentInvitation;
}

export const InformationText: React.VFC<InvitationProps> = ({ invitation }) => {
  const isInSharingState =
    invitation.status === ShareAccountInvitationStatus.CREATED &&
    invitation.inviteeAssigned !== true;

  return (
    <>
      <motion.div layout="size">
        {isShareAccountSentInvitation(invitation) &&
          invitation.status ===
            ShareAccountInvitationStatus.ACCEPTED_INVITEE && (
            <div>
              <p>
                <TranslatedText
                  id="shareAccountInviteCard.information.acceptInformationParagraph"
                  values={{
                    accountName: invitation.accountName,
                  }}
                />
              </p>
              <p>
                <ShareAccountInviteCardDocs
                  documentVariables={{
                    accountName: invitation.accountName,
                    accountId: invitation.accountId,
                    inviteeName: invitation.inviteeName,
                  }}
                />
              </p>
            </div>
          )}
        {isInSharingState && (
          <div>
            <p>
              <FormattedMessage id="shareAccountInviteCard.information.shareInformationParagraph" />
            </p>
          </div>
        )}
      </motion.div>
      {invitation.status !== ShareAccountInvitationStatus.ACCEPTED_OWNER && (
        <motion.div layout="size">
          <h3 className="sub-header">
            <TranslatedText
              id="shareAccountInviteCard.information.summaryHeader"
              values={{
                accountName: invitation.accountName,
              }}
            />
          </h3>
        </motion.div>
      )}
    </>
  );
};

interface InvitationProps {
  invitation: ShareAccountInvitation | ShareAccountSentInvitation;
}

export const Header: React.VFC<InvitationProps> = ({ invitation }) => {
  if (invitation.status === ShareAccountInvitationStatus.ACCEPTED_INVITEE) {
    return (
      <h2>
        <FormattedMessage id="shareAccountInviteCard.header.review" />
      </h2>
    );
  }
  if (invitation.status === ShareAccountInvitationStatus.ACCEPTED_OWNER) {
    return (
      <h2>
        <FormattedMessage id="shareAccountInviteCard.header.done" />
      </h2>
    );
  }

  return (
    <h2>
      <FormattedMessage id="shareAccountInviteCard.header.share" />
    </h2>
  );
};
