import { useContext, VoidFunctionComponent } from "react";
import { Form, LysaFormRef, Button } from "@lysaab/ui-2";
import { useRef } from "react";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { CreateSavingsAccountContext } from "../CreateSavingsAccountContext";
import { SavingsAccountPurposeQuestionAccount } from "../../../../../../components/kyc/account/SavingsAccountPurposeQuestionAccount";
import { SavingsAccountDepositIntervalQuestion } from "../../../../../../components/kyc/account/SavingsAccountDepositIntervalQuestion";
import { SavingsAccountWithdrawalIntervalQuestion } from "../../../../../../components/kyc/account/SavingsAccountWithdrawalIntervalQuestion";

interface Props {
  next: () => void;
}

export const Kyc: VoidFunctionComponent<Props> = ({ next }) => {
  const formRef = useRef<LysaFormRef>();
  const createSavingsAccountContext = useContext(CreateSavingsAccountContext);

  return (
    <div>
      <h2>
        <TranslatedText id="sweden.createAccount.createSavingsAccount.kyc.header" />
      </h2>
      <p>
        <TranslatedText id="sweden.createAccount.createSavingsAccount.kyc.intro" />
      </p>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (formRef.current?.isInvalid) {
            return;
          }
          next();
        }}
      >
        <SavingsAccountPurposeQuestionAccount
          value={createSavingsAccountContext.state.purpose}
          setValue={(purpose) =>
            createSavingsAccountContext.setState({ purpose })
          }
        />
        <SavingsAccountDepositIntervalQuestion
          value={createSavingsAccountContext.state.depositInterval}
          setValue={(depositInterval) =>
            createSavingsAccountContext.setState({ depositInterval })
          }
        />
        <SavingsAccountWithdrawalIntervalQuestion
          value={createSavingsAccountContext.state.withdrawalInterval}
          setValue={(withdrawalInterval) =>
            createSavingsAccountContext.setState({ withdrawalInterval })
          }
        />

        <Button
          block
          type="submit"
          label={
            <TranslatedText id="sweden.createAccount.createSavingsAccount.kyc.button.next" />
          }
        />
      </Form>
    </div>
  );
};
