import { FunctionComponent } from "react";

import {
  Button,
  NewIcon,
  SelectableCard,
  Snackbar,
  SNACKBAR_TYPES,
  Typography,
} from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../components/TranslatedText";

import "./PensionSettings.scss";
import { useTransfer } from "../TransferContext";
import { FormattedPercentage } from "../../../../../components/formattedPercentage/FormattedPercentage";
import { useIntl } from "react-intl";
import { SustainabilityImportance } from "../../../../../data/dataInvestments";

interface Props {
  toTakenRiskDeviation: () => void;
  toSustainability: () => void;
  toWithdrawalAge: () => void;
  toWithdrawalPeriod: () => void;
  toRepayment: () => void;
  toAdvice: () => void;
}

export const PensionSettings: FunctionComponent<Props> = ({
  toTakenRiskDeviation,
  toSustainability,
  toWithdrawalAge,
  toWithdrawalPeriod,
  toRepayment,
  toAdvice,
}) => {
  const [transfer, setTransfer] = useTransfer();
  const intl = useIntl();

  const getMessage = (sustainability: SustainabilityImportance) => {
    return sustainability === SustainabilityImportance.NOT_IMPORTANT
      ? intl.formatMessage({
          id: "sweden.transfer-pension.pension-settings.sustainability.BROAD",
        })
      : intl.formatMessage({
          id: "sweden.transfer-pension.pension-settings.sustainability.SUSTAINABLE",
        });
  };

  const getIcon = (sustainability: SustainabilityImportance) => {
    return sustainability === SustainabilityImportance.NOT_IMPORTANT ? (
      <NewIcon.Global size={16} />
    ) : (
      <NewIcon.Sustainable size={16} />
    );
  };

  /**
   * This part safe giard in case user started changing sustainability but never finished.
   */

  if (
    transfer.sustainability === "IMPORTANT" &&
    transfer.sustainabilityPreference === undefined
  ) {
    setTransfer({ sustainability: SustainabilityImportance.NOT_IMPORTANT });
  }

  return (
    <article className="transfer-pension-story-pension-settings">
      <Typography type="h3" className="heading">
        <TranslatedText id="sweden.transfer-pension.pension-settings.header" />
      </Typography>
      <SelectableCard
        type="button"
        className="selectable-card"
        onClick={toTakenRiskDeviation}
      >
        <header className="header">
          <section className="start">
            <Typography type="label" className="label">
              <TranslatedText
                id={
                  "sweden.transfer-pension.pension-settings.taken-risk-deviation.label"
                }
              />
            </Typography>
          </section>
          <section className="end">
            <FormattedPercentage
              value={transfer.takenRiskDeviation}
              decimals={1}
            />
            <NewIcon.ChevronRight />
          </section>
        </header>
      </SelectableCard>
      <SelectableCard
        type="button"
        className="selectable-card"
        onClick={toSustainability}
      >
        <header className="header">
          <section className="start">
            <Typography type="label" className="label">
              <TranslatedText
                id={
                  "sweden.transfer-pension.pension-settings.investment-type.label"
                }
              />
            </Typography>
          </section>
          <section className="end">
            {transfer.sustainability && getMessage(transfer.sustainability)}
            {transfer.sustainability && getIcon(transfer.sustainability)}
            <NewIcon.ChevronRight />
          </section>
        </header>
      </SelectableCard>
      <SelectableCard
        type="button"
        className="selectable-card"
        onClick={toWithdrawalAge}
      >
        <header className="header">
          <section className="start">
            <Typography type="label" className="label">
              <TranslatedText
                id={
                  "sweden.transfer-pension.pension-settings.withdrawalAge.label"
                }
              />
            </Typography>
          </section>
          <section className="end">
            {`${transfer.withdrawalAge} ${intl.formatMessage({
              id: "sweden.transfer-pension.pension-settings.withdrawalAge.unit",
            })}`}
            <NewIcon.ChevronRight />
          </section>
        </header>
      </SelectableCard>
      <SelectableCard
        type="button"
        className="selectable-card"
        onClick={toWithdrawalPeriod}
      >
        <header className="header">
          <section className="start">
            <Typography type="label" className="label">
              <TranslatedText
                id={
                  "sweden.transfer-pension.pension-settings.withdrawalPeriod.label"
                }
              />
            </Typography>
          </section>
          <section className="end">
            {`${transfer.withdrawalMonths / 12} ${intl.formatMessage({
              id: "sweden.transfer-pension.pension-settings.withdrawalPeriod.unit",
            })}`}
            <NewIcon.ChevronRight />
          </section>
        </header>
      </SelectableCard>
      <SelectableCard
        type="button"
        className="selectable-card"
        onClick={toRepayment}
      >
        <header className="header">
          <section className="start">
            <Typography type="label" className="label">
              <TranslatedText
                id={"sweden.transfer-pension.pension-settings.repayment.label"}
              />
            </Typography>
          </section>
          <section className="end">
            {`${
              transfer.repayment
                ? intl.formatMessage({
                    id: "sweden.transfer-pension.pension-settings.repayment.yes",
                  })
                : intl.formatMessage({
                    id: "sweden.transfer-pension.pension-settings.repayment.no",
                  })
            }`}
            <NewIcon.ChevronRight />
          </section>
        </header>
      </SelectableCard>
      <section className="snackbar">
        <Snackbar type={SNACKBAR_TYPES.INFO} icon textAlign="left">
          <TranslatedText id="sweden.transfer-pension.pension-settings.snackbar" />
        </Snackbar>
      </section>
      <section className="actions">
        <Button
          block
          type="button"
          label={intl.formatMessage({
            id: "sweden.transfer-pension.pension-settings.back",
          })}
          onClick={toAdvice}
        />
      </section>
    </article>
  );
};
