import "./NewCustomerGreeting.scss";
import { FormattedMessage } from "react-intl";
import { TranslatedText } from "../../components/TranslatedText";
import { Link } from "react-router-dom";
import { getNavLink } from "../../hooks/useCountryUrls";
import { Button } from "@lysaab/ui-2";
import { MONTHLY_DEPOSITS_URL } from "../deposits/monthly/create/MonthlyStory";
import { RouteAwareToggle } from "../../components/route/RouteAwareToggle";
import { DEPOSITS_OVERVIEW_URL } from "../deposits/overview/Recommendation";
import { MONTHLY_OVERVIEW_PAGE } from "../deposits/monthly/overview/MonthlyOverview";

export function NewCustomerGreeting() {
  return (
    <div className="new-customer-greeting">
      <h1>
        <FormattedMessage id="newCustomer.header" />
      </h1>
      <TranslatedText
        id="newCustomer.text"
        values={{
          p: (parts) => <p>{parts}</p>,
        }}
      />
      <p>
        <FormattedMessage id="newCustomer.regards" />
        <br />
        <FormattedMessage id="newCustomer.lysa" />
      </p>
      <RouteAwareToggle path={getNavLink(DEPOSITS_OVERVIEW_URL)}>
        <Button
          component={Link}
          to={getNavLink(DEPOSITS_OVERVIEW_URL)}
          label={<FormattedMessage id="newCustomer.deposits-button-text" />}
          icon="Deposit"
        />
      </RouteAwareToggle>
      <RouteAwareToggle path={getNavLink(MONTHLY_OVERVIEW_PAGE)}>
        <Button
          component={Link}
          to={getNavLink(MONTHLY_DEPOSITS_URL)}
          label={
            <FormattedMessage id="newCustomer.monthly-deposits-button-text" />
          }
          icon="Deposit"
        />
      </RouteAwareToggle>
    </div>
  );
}
