import { FunctionComponent, useEffect, useState } from "react";
import { AccountType, InvestmentAccount } from "../../data/dataAccounts";
import { SNACKBAR_TYPES, Snackbar } from "@lysaab/ui-2";
import { dataDanica } from "../../data/dataDanica";
import "./KfMoveInformation.scss";
import { useIntl } from "react-intl";
import { TranslatedText } from "../TranslatedText";

interface Props {
  account: InvestmentAccount | undefined;
}

export const KfMoveInformation: FunctionComponent<Props> = ({ account }) => {
  const [moveDate, setMoveDate] = useState<string>();
  const intl = useIntl();

  useEffect(() => {
    setMoveDate(undefined);

    if (!account || account.type !== AccountType.KF_SWE) {
      return;
    }

    dataDanica.getIsMovedFromFutur(account.accountId).then((isMoved) => {
      setMoveDate(isMoved.completed);
    });
  }, [account]);

  if (!account || account.type !== AccountType.KF_SWE || !moveDate) {
    return null;
  }

  return (
    <div className="kf-move-graph-info">
      <Snackbar type={SNACKBAR_TYPES.INFO}>
        <TranslatedText
          id="performance.graph.kfMoveInformation"
          values={{ date: intl.formatDate(moveDate, { dateStyle: "full" }) }}
        />
      </Snackbar>
    </div>
  );
};
