import { useCallback, useContext } from "react";
import { Redirect } from "react-router";
import { AccountLoading } from "../../../../../../../components/klarna/accountLoading/AccountLoading";
import { TranslatedText } from "../../../../../../../components/TranslatedText";
import {
  AccountTypes,
  KlarnaAccount,
} from "../../../../../../../data/dataKlarna";
import { getNavLink } from "../../../../../../../hooks/useCountryUrls";
import { ADD_DEPOSIT_AUTOGIRO_URL } from "../../addAccount/AddDepositAccountAutogiroStory";
import { AutogiroDepositContext } from "../AutogiroDepositContext";
import { BASE_ROUTES } from "../AutogiroDepositPage";

interface Props {
  next: () => void;
}

export function AccountLoadingWrapper({ next }: Props) {
  const autogiroContext = useContext(AutogiroDepositContext);
  const bank = autogiroContext.state.bank;
  const setState = autogiroContext.setState;

  const onComplete = useCallback(
    (accounts: KlarnaAccount[]) => {
      setState({ klarnaAccounts: accounts });
      setTimeout(() => {
        next();
      }, 0);
    },
    [next, setState]
  );

  if (!bank) {
    return <Redirect to={getNavLink(BASE_ROUTES.ACCOUNT_SELECTION)} />;
  }
  return (
    <AccountLoading
      bank={bank}
      onComplete={onComplete}
      accountTypes={[AccountTypes.DEFAULT]}
      fallbackOptions={{
        locationDescriptor: {
          pathname: getNavLink(ADD_DEPOSIT_AUTOGIRO_URL),
          state: {
            returnUrl: getNavLink(BASE_ROUTES.EXTERNAL_ACCOUNT_SELECTION),
            returnState: autogiroContext.state,
          },
        },
        buttonText: (
          <TranslatedText id="sweden.deposits.autogiro.story.account-loading-wrapper.fallbackButton" />
        ),
      }}
    />
  );
}
