import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { EventTracker } from "../../../components/eventTracker/EventTracker";
import { TrackerEvent } from "../../../data/dataCustomerTracking";
import { Eligibility, isValidEligibility } from "../../../data/dataInvestments";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../hooks/useSafeNavigation";
import { UpdateInvestmentAccountComposition } from "../../../pageComponents/accountsAllocation/accountAdviseCard/AccountAdviceCard";
import { OVERVIEW_PAGE_URL } from "../../overview/OverviewPage";
import {
  AccountUpdateAction,
  useReviewAccountContext,
} from "../ReviewAccountContext";
import { ReviewAccountHistoryState } from "../ReviewAccountStory";

export function useReviewHistory() {
  const safeNavigation = useSafeNavigation();
  const history = useHistory<ReviewAccountHistoryState | undefined>();
  const [, setReviewAccountState] = useReviewAccountContext();
  const [savedLocationState, setSavedLocationState] = useState<
    ReviewAccountHistoryState | undefined
  >();
  const [eligibility, setEligibility] = useState<Eligibility | undefined>();
  useEffect(() => {
    // If savedLocationState and eligibility is set then abort
    if (
      typeof savedLocationState !== "undefined" &&
      typeof eligibility !== "undefined"
    ) {
      return;
    }

    // Preventing using browser back to navigate into story again
    if (
      typeof savedLocationState === "undefined" &&
      history.location.state?.preventDirectAccess === true
    ) {
      safeNavigation({
        pathname: history.location.state.returnUrl,
        state: history.location.state.returnState,
      });
      return;
    }

    const currentHistoryState = history.location.state;

    if (currentHistoryState?.returnState) {
      setEligibility(currentHistoryState.eligibility);
      setReviewAccountState(currentHistoryState.returnState.reviewAccountState);
      setSavedLocationState({
        ...currentHistoryState,
        preventDirectAccess: true,
      });
      history.replace({
        pathname: history.location.pathname,
        state: {
          ...currentHistoryState,
          // set preventDirectAccess to prevent browser navigation
          preventDirectAccess: true,
        },
      });
    } else {
      safeNavigation(
        getNavLink(currentHistoryState?.returnUrl ?? OVERVIEW_PAGE_URL)
      );
    }
  }, [
    history,
    savedLocationState,
    safeNavigation,
    setReviewAccountState,
    eligibility,
  ]);

  // Pass reviewAccount to function to update returnState context with it
  const navigateToOrigin = useCallback(
    (reviewAccount?: UpdateInvestmentAccountComposition) => {
      if (!savedLocationState || !savedLocationState.returnState) {
        return;
      }

      const accounts =
        savedLocationState.returnState.reviewAccountState.accounts;

      if (typeof reviewAccount !== "undefined") {
        if (!reviewAccount.action) {
          throw new Error("Can't update account when action is not defined");
        }

        let risk: number;

        switch (reviewAccount.action) {
          case AccountUpdateAction.KEEP: {
            risk = reviewAccount.newAdvice.takenRisk;
            break;
          }
          case AccountUpdateAction.ADVICE: {
            risk = reviewAccount.newAdvice.advice;
            break;
          }
          case AccountUpdateAction.CUSTOM: {
            risk = reviewAccount.risk;
            break;
          }
          default: {
            throw new Error("Risk failed to set");
          }
        }

        const index = accounts.findIndex(
          (account) => account.accountId === reviewAccount.accountId
        );

        if (index < 0) {
          throw new Error(
            "useReviewHistory - Can't find updated account in reviewAccountState accounts"
          );
        }

        accounts[index] = reviewAccount;
        EventTracker.track({
          event: TrackerEvent.REVIEW_ACCOUNT_RISK,
          message: {
            action: reviewAccount.action,
            accountId: reviewAccount.accountId,
            risk,
          },
        });
      }

      /**
       * replace history, if user uses browser navigation then the current
       * state (with updated reviewAccount) is used when navigated back to origin story
       */
      history.replace({
        pathname: history.location.pathname,
        state: {
          ...savedLocationState,
          returnState: {
            ...savedLocationState.returnState,
            reviewAccountState: {
              ...savedLocationState.returnState.reviewAccountState,
              accounts,
            },
          },
        },
      });

      safeNavigation({
        pathname: savedLocationState.returnUrl,
        hash: reviewAccount?.accountId,
        state: {
          ...savedLocationState.returnState,
          reviewAccountState: {
            ...savedLocationState.returnState.reviewAccountState,
            accounts,
          },
        },
      });
    },
    [savedLocationState, history, safeNavigation]
  );

  const navigateInStory = (pathname: string, search?: string) => {
    if (!savedLocationState) {
      return;
    }

    history.push({
      pathname,
      search,
      state: {
        ...savedLocationState,
        preventDirectAccess: true,
      },
    });
  };

  return {
    isLoading:
      typeof savedLocationState === "undefined" &&
      isValidEligibility(eligibility),
    eligibility,
    navigateToOrigin,
    navigateInStory,
  };
}
