import { Button, Card, Spinner, Typography } from "@lysaab/ui-2";
import { useEffect, useState } from "react";
import * as React from "react";
import { defineMessages, FormatNumberOptions, useIntl } from "react-intl";
import { getFundName } from "../../../../../components/fundName/FundName";
import HorizontalDivider from "../../../../../components/horizontalDivider/HorizontalDivider";
import { LysaTable } from "../../../../../components/lysaTable/LysaTable";
import { LysaTableTextCell } from "../../../../../components/lysaTableTextCell/LysaTableTextCell";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { useCurrency } from "../../../../../context/LocalizationContext";
import { dataFunds, Fund, FundType, Isin } from "../../../../../data/dataFunds";
import {
  dataLegalEntity,
  LegalEntityResponse,
} from "../../../../../data/dataLegalEntity";
import { DeTaxResponse } from "../../../../../data/dataTax";
import { SubVap } from "./components/subVapRedemption/SubVap";
import { TaxRedemption } from "./components/taxRedemption/TaxRedemption";
import "./TaxDetails.scss";

interface Props {
  year?: number;
  information?: DeTaxResponse;
  loading?: boolean;
}

export const messages = defineMessages({
  isin: {
    id: "germany.tax.details.isin",
  },
  fundName: {
    id: "germany.tax.details.fundName",
  },
  netProfit: {
    id: "germany.tax.details.netProfit",
  },
  details: {
    id: "germany.tax.details.details",
  },
  fundType: {
    id: "germany.tax.details.fundType",
  },
  vap: {
    id: "germany.tax.details.vap",
  },
  kapInvReferenceHeader: {
    id: "germany.tax.details.kapInvReferenceHeader",
  },
  kapInvReferenceStocks: {
    id: "germany.tax.details.kapInvReferenceStocks",
  },
  kapInvReferenceBonds: {
    id: "germany.tax.details.kapInvReferenceBonds",
  },
  kapInvReferenceStocksVap: {
    id: "germany.tax.details.kapInvReferenceStocksVap",
  },
  kapInvReferenceBondsVap: {
    id: "germany.tax.details.kapInvReferenceBondsVap",
  },
});

export const fundTypeMessage = defineMessages<FundType>({
  [FundType.STOCKS]: {
    id: "germany.tax.details.stocks",
  },
  [FundType.BONDS]: {
    id: "germany.tax.details.bonds",
  },
});

export const TaxDetails: React.VFC<Props> = ({
  year,
  information,
  loading,
}) => {
  const [legalEntity, setLegalEntity] = useState<LegalEntityResponse>();
  const [holdings, setHoldings] = useState<Fund[]>();
  const intl = useIntl();
  const currency = useCurrency();

  useEffect(() => {
    Promise.all([
      dataLegalEntity.getLegalEntity(),
      dataFunds.getHoldings(),
    ]).then(([legalEntity, holdings]) => {
      setLegalEntity(legalEntity);
      setHoldings(holdings);
    });
  }, []);

  if (typeof year === "undefined") {
    return (
      <>
        <Typography type="h2">
          <TranslatedText id="germany.tax.details.details-header" />
        </Typography>
        <Card>
          <TranslatedText id="germany.tax.details.placeholder" />
        </Card>
      </>
    );
  }

  if (
    typeof information === "undefined" ||
    typeof legalEntity === "undefined" ||
    loading
  ) {
    return (
      <>
        <Typography type="h2">
          <TranslatedText id="germany.tax.details.details-header" />
        </Typography>
        <Card>
          <Spinner />
        </Card>
      </>
    );
  }

  const getHoldingInfo = (isin: Isin) => {
    return holdings?.find((holding) => {
      return holding.fundShareClasses.some((fund) => fund.isin === isin);
    });
  };

  /* Sort the same way as the fund-classes is ordered in the KAP-INV form */
  const sortedTaxInformationByFundType = [
    ...information.taxInformationByIsins,
  ].sort((info) => {
    const holdingInfo = getHoldingInfo(info.isin);
    if (typeof holdingInfo === "undefined") return 1;

    return holdingInfo.fundType === FundType.STOCKS ? -1 : 1;
  });

  const currencyOptions: FormatNumberOptions = {
    style: "currency",
    currency: currency,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  };

  return (
    <div className="germany-tax-details">
      <Typography type="h2">
        <TranslatedText id="germany.tax.details.details-header" /> - {year}
      </Typography>
      <Card>
        <div className="germany-tax-details__header">
          <div className="germany-tax-details__header-details">
            <dl>
              <Typography type="label" component="dt">
                <TranslatedText id="germany.tax.details.name" />
              </Typography>
              <dd>{legalEntity.name}</dd>
              <Typography type="label" component="dt">
                <TranslatedText id="germany.tax.details.tin" />
              </Typography>
              <dd>{legalEntity.tin}</dd>
            </dl>
          </div>
          <Button
            onClick={window.print}
            icon="Print"
            label={<TranslatedText id="germany.tax.details.print-button" />}
            variant="secondary"
            className="germany-tax-details__header-button germany-tax-details__hide-in-print"
          />
        </div>
        <Typography type="h3">
          <TranslatedText id="germany.tax.details.profitSummaryHeader" />
        </Typography>
        <Typography type="label-large" component="p">
          <TranslatedText id="germany.tax.details.profitSummaryBody" />
        </Typography>
        <div className="germany-tax-details__table-wrapper">
          {sortedTaxInformationByFundType.map((info, index) => {
            const holdingInfo = getHoldingInfo(info.isin);
            if (typeof holdingInfo === "undefined") return null;

            const isStocks = holdingInfo.fundType === FundType.STOCKS;
            const isLastItem =
              index === information.taxInformationByIsins.length - 1;
            return (
              <React.Fragment key={info.isin + "profit"}>
                <LysaTable
                  key={info.isin + "profit"}
                  className="germany-tax-details__table"
                  fixed
                >
                  <thead>
                    <tr>
                      <LysaTableTextCell
                        value={intl.formatMessage(messages.fundName)}
                        header
                      />
                      <LysaTableTextCell
                        value={intl.formatMessage(messages.fundType)}
                        header
                      />
                      <LysaTableTextCell
                        className="germany-tax-details__table--text-right"
                        value={intl.formatMessage(
                          messages.kapInvReferenceHeader
                        )}
                        header
                      />
                      <LysaTableTextCell
                        className="germany-tax-details__table--text-right germany-tax-details__table--space-left"
                        value={intl.formatMessage(messages.netProfit)}
                        header
                      />
                      <LysaTableTextCell
                        className="germany-tax-details__hide-in-print"
                        value={intl.formatMessage(messages.details)}
                        header
                      />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <LysaTableTextCell
                        label={intl.formatMessage(messages.fundName)}
                        value={getFundName(info.isin, intl)}
                      />
                      <LysaTableTextCell
                        label={intl.formatMessage(messages.fundType)}
                        value={intl.formatMessage(
                          fundTypeMessage[holdingInfo.fundType]
                        )}
                      />
                      <LysaTableTextCell
                        className="germany-tax-details__table--text-right"
                        label={intl.formatMessage(
                          messages.kapInvReferenceHeader
                        )}
                        value={
                          isStocks
                            ? intl.formatMessage(messages.kapInvReferenceStocks)
                            : intl.formatMessage(messages.kapInvReferenceBonds)
                        }
                      />
                      <LysaTableTextCell
                        className="germany-tax-details__table--text-right germany-tax-details__table--space-left"
                        label={intl.formatMessage(messages.netProfit)}
                        value={intl.formatNumber(
                          truncateNumber(info.netProfit),
                          currencyOptions
                        )}
                      />
                      <LysaTableTextCell
                        className="germany-tax-details__hide-in-print"
                        label={intl.formatMessage(messages.details)}
                      >
                        <TaxRedemption
                          redemptionPart={info.redemptionParts}
                          isin={info.isin}
                        />
                      </LysaTableTextCell>
                    </tr>
                  </tbody>
                </LysaTable>
                {!isLastItem && (
                  <HorizontalDivider
                    className="germany-tax-details__divider"
                    key={info.isin + "profit-divider"}
                  />
                )}
              </React.Fragment>
            );
          })}
        </div>
      </Card>
      <Card>
        <div className="germany-tax-details__print-break-before">
          <Typography type="h3">
            <TranslatedText id="germany.tax.details.vapSummaryHeader" />
          </Typography>
          <div className="germany-tax-details__table-wrapper">
            {sortedTaxInformationByFundType.map((info, index) => {
              const holdingInfo = getHoldingInfo(info.isin);
              if (typeof holdingInfo === "undefined") return null;

              const isStocks = holdingInfo.fundType === FundType.STOCKS;
              const isLastItem =
                index === information.taxInformationByIsins.length - 1;
              return (
                <React.Fragment key={info.isin + "vap"}>
                  <LysaTable className="germany-tax-details__table" fixed>
                    <thead>
                      <tr>
                        <LysaTableTextCell
                          value={intl.formatMessage(messages.fundName)}
                          header
                        />
                        <LysaTableTextCell
                          value={intl.formatMessage(messages.fundType)}
                          header
                        />
                        <LysaTableTextCell
                          className="germany-tax-details__table--text-right"
                          value={intl.formatMessage(
                            messages.kapInvReferenceHeader
                          )}
                          header
                        />
                        <LysaTableTextCell
                          className="germany-tax-details__table--text-right"
                          value={intl.formatMessage(messages.vap)}
                          header
                        />
                        <LysaTableTextCell
                          className="germany-tax-details__hide-in-print"
                          value={intl.formatMessage(messages.details)}
                          header
                        />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <LysaTableTextCell
                          label={intl.formatMessage(messages.fundName)}
                          value={getFundName(info.isin, intl)}
                        />
                        <LysaTableTextCell
                          label={intl.formatMessage(messages.fundType)}
                          value={intl.formatMessage(
                            fundTypeMessage[holdingInfo.fundType]
                          )}
                        />
                        <LysaTableTextCell
                          className="germany-tax-details__table--text-right"
                          label={intl.formatMessage(
                            messages.kapInvReferenceHeader
                          )}
                          value={
                            isStocks
                              ? intl.formatMessage(
                                  messages.kapInvReferenceStocksVap
                                )
                              : intl.formatMessage(
                                  messages.kapInvReferenceBondsVap
                                )
                          }
                        />
                        <LysaTableTextCell
                          className="germany-tax-details__table--text-right"
                          label={intl.formatMessage(messages.vap)}
                          value={intl.formatNumber(
                            truncateNumber(info.vap),
                            currencyOptions
                          )}
                        />
                        <LysaTableTextCell
                          className="germany-tax-details__hide-in-print"
                          label={intl.formatMessage(messages.details)}
                        >
                          <SubVap
                            subVapPart={info.subVapParts}
                            isin={info.isin}
                          />
                        </LysaTableTextCell>
                      </tr>
                    </tbody>
                  </LysaTable>
                  {!isLastItem && (
                    <HorizontalDivider
                      className="germany-tax-details__divider"
                      key={info.isin + "vap-divider"}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </Card>
    </div>
  );
};

/* To remove minus sign when truncatedNum is -0 */
const truncateNumber = (num: number) => {
  const truncatedNum = Math.trunc(num);
  return truncatedNum === 0 ? 0 : truncatedNum;
};
