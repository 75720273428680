import { Card, Spinner } from "@lysaab/ui-2";
import { useContext } from "react";
import * as React from "react";
import { TranslatedText } from "../../../../../../../components/TranslatedText";
import { KF_WITHDRAWAL_STATUS } from "../../../../../../../data/dataDanica";
import { KfWithdrawalContext } from "../KfWithdrawalContext";

export const PendingDanica: React.FunctionComponent = () => {
  const { state: kfWithdrawalState } = useContext(KfWithdrawalContext);
  const status = kfWithdrawalState.signingStatus?.status;

  if (
    status &&
    kfWithdrawalState.signingStatus?.status !==
      KF_WITHDRAWAL_STATUS.PENDING_DANICA
  ) {
    return null;
  }

  return (
    <Card>
      <h4>
        <TranslatedText
          id="withdrawalRequest.kf.signing.pending.header"
          description="withdrawal request kf - status pending header"
          defaultMessage="Validating..."
        />
      </h4>
      <p>
        <TranslatedText id="withdrawalRequest.kf.signing.pending.text" />
      </p>
      <Spinner />
    </Card>
  );
};
