import { ReactNode } from "react";
import * as React from "react";
import "./Disclaimer.scss";

interface Props {
  children: string | ReactNode;
}

export const Disclaimer: React.FC<Props> = ({ children }) => {
  return <div className="account-page-disclaimer">{children}</div>;
};
