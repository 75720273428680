import { CardList, CardListItem } from "@lysaab/ui-2";
import NumberFormat from "react-number-format";
import { LysaRight } from "../../../data/dataLogin";
import { TranslatedText } from "../../../components/TranslatedText";
import { EventTracker } from "../../../components/eventTracker/EventTracker";
import { generatePath, Link } from "react-router-dom";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { SWITCH_USER_PAGE_URL } from "./SwitchUser";
import { TrackerEvent } from "../../../data/dataCustomerTracking";

import "./SwitchUserCard.scss";

interface Props {
  entity: LysaRight;
}

export function SwitchUserCard({ entity }: Props) {
  const handleClick = () => {
    EventTracker.track({
      event: TrackerEvent.SWITCH_ENTITY,
      message: entity.name,
    });
  };

  const content = (
    <CardList>
      <CardListItem>
        <div className="button-wrapper button-list-item">
          <div className="left-wrapper">
            <div className="button-text-wrapper">
              <span className="list-name">{entity.name}</span>
              <NumberFormat
                className="list-id"
                displayType="text"
                format={
                  entity.tin.length === 10 ? "######-####" : "########-####"
                }
                value={entity.tin}
              />
            </div>
          </div>
          {!entity.active ? (
            <span>
              <TranslatedText id="switchUser.login.login" />
            </span>
          ) : (
            <span>
              <TranslatedText id="switchUser.login.loggedIn" />
            </span>
          )}
        </div>
      </CardListItem>
    </CardList>
  );

  if (entity.active) {
    return <div className="user-card">{content}</div>;
  }

  return (
    <Link
      className="user-card"
      to={getNavLink(
        generatePath(SWITCH_USER_PAGE_URL, {
          id: entity.accessRightsId,
        })
      )}
      onClick={handleClick}
    >
      {content}
    </Link>
  );
}
