import { VoidFunctionComponent } from "react";
import { AccountType } from "../../../../../../data/dataAccounts";
import { Horizon } from "../../../../../../pageComponents/horizon/Horizon";
import { useCreateKFAccount } from "../KFAccountContext";

interface Props {
  next: () => void;
}

export const HorizonWrapper: VoidFunctionComponent<Props> = ({ next }) => {
  const [KFAccount, setKFAccount] = useCreateKFAccount();

  return (
    <Horizon
      horizonLength={KFAccount.savingsHorizon}
      setHorizonLength={(savingsHorizon) =>
        setKFAccount({ savingsHorizon: savingsHorizon?.value })
      }
      horizonProbability={KFAccount.needEarlier}
      setHorizonProbability={(needEarlier) =>
        setKFAccount({ needEarlier: needEarlier?.value })
      }
      next={next}
      accountType={AccountType.KF_SWE}
    />
  );
};
