import { preloadableImport } from "../../components/safeLazyImport/PreloadableImport";
import { SafeLazyImport } from "../../components/safeLazyImport/SafeLazyImport";

export const LOGIN_RESET_PAGE_URL = "/login-reset";

export const LoginResetStoryLazy = preloadableImport(() =>
  import("./LoginResetStoryLazy").then((module) => ({
    default: module.LoginResetStoryLazy,
  }))
);

export const LoginResetStory: React.VFC = () => {
  return (
    <SafeLazyImport>
      <LoginResetStoryLazy />
    </SafeLazyImport>
  );
};
