import { useState, useCallback, useEffect, FunctionComponent } from "react";
import { CardList, Snackbar, SNACKBAR_TYPES, Typography } from "@lysaab/ui-2";
import {
  AccountType,
  InvestmentAccount,
  dataAccounts,
  isPensionAccountType,
} from "../../../../data/dataAccounts";
import { Retry, Status } from "../../../../components/retry/Retry";
import {
  dataFees,
  FeeEstimation,
  FeesEstimatedSignedIn,
} from "../../../../data/dataFees";
import { useInView } from "react-intersection-observer";
import { Disclaimer } from "../../Disclaimer";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { FormattedPercentage } from "../../../../components/formattedPercentage/FormattedPercentage";
import { useCountry } from "../../../../context/LocalizationContext";
import "./FeesExample.scss";
import { useAccountAllocation } from "../../../../hooks/useAccountAllocation";
import { CombinedAdviseAccount } from "../../../../data/dataInvestments";
import { InsuredResponse, dataLife } from "../../../../data/dataLife";

interface Props {
  account: InvestmentAccount | undefined;
}

function getFutureFees(
  costData: FeeEstimation | undefined,
  showInsuranceFees: boolean,
  showPensionFees: boolean
) {
  return (
    <dl>
      <dt>
        <FormattedMessage id="accountPage.fees.example.list.future.lysa" /> -{" "}
        {costData ? (
          <FormattedPercentage
            value={costData.discretionary + costData.fundManagement}
            decimals={3}
          />
        ) : (
          "-"
        )}
      </dt>
      {showPensionFees && costData?.insurancePremium ? (
        <>
          <dt>
            <FormattedMessage id="accountPage.fees.example.list.future.pension" />{" "}
            -{" "}
            {costData ? (
              <FormattedPercentage
                value={costData.insurancePremium}
                decimals={3}
              />
            ) : (
              "-"
            )}
          </dt>
        </>
      ) : null}
      <dt>
        <FormattedMessage id="accountPage.fees.example.list.future.funds" /> -{" "}
        {costData ? (
          <FormattedPercentage value={costData.fundAssets} decimals={3} />
        ) : (
          "-"
        )}
      </dt>
      <dt>
        <FormattedMessage id="accountPage.fees.example.list.future.transactions" />{" "}
        -{" "}
        {costData ? (
          <FormattedPercentage value={costData.transactionFees} decimals={3} />
        ) : (
          "-"
        )}
      </dt>
      {showInsuranceFees && costData && costData.insurancePremium ? (
        <>
          <dt>
            <FormattedMessage id="accountPage.fees.example.list.future.insurance" />{" "}
            -{" "}
            {costData ? (
              <FormattedPercentage
                value={costData.insurancePremium}
                decimals={3}
              />
            ) : (
              "-"
            )}
          </dt>
        </>
      ) : null}

      {showInsuranceFees && costData && costData.insuranceRiskPremium ? (
        <>
          <dt>
            <FormattedMessage id="accountPage.fees.example.list.future.insurance-risk" />{" "}
            -{" "}
            {costData ? (
              <FormattedPercentage
                value={costData.insuranceRiskPremium}
                decimals={3}
              />
            ) : (
              "-"
            )}
          </dt>
        </>
      ) : null}
    </dl>
  );
}

export const FeesExample: FunctionComponent<Props> = ({ account }) => {
  const intl = useIntl();
  const country = useCountry();
  const [fees, setFees] = useState<FeesEstimatedSignedIn>();
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  const accountAllocation = useAccountAllocation(account?.accountId);
  const [insured, setInsured] = useState<InsuredResponse>();

  const load = useCallback(
    (
      account: InvestmentAccount,
      accountAllocation: CombinedAdviseAccount,
      insured: InsuredResponse | undefined
    ) => {
      if (!country) {
        return;
      }
      dataFees
        .getEstimatedFeesSignedIn({
          amount: 100,
          risk: accountAllocation.takenRisk,
          investmentType: accountAllocation.investmentType,
          country: country,
          accountType: account.type,
          insured: insured ? insured.tin : undefined,
        })
        .then(setFees)
        .then(() => setStatus(Status.SUCCESS))
        .catch(() => setStatus(Status.ERROR));
    },
    [country]
  );

  const retry = useCallback(() => {
    if (!account || !accountAllocation) {
      return;
    }
    if (account.type === AccountType.KF_SWE && typeof insured === "undefined") {
      return;
    }
    setStatus(Status.PENDING);
    setTimeout(() => {
      load(account, accountAllocation, insured);
    }, 600);
  }, [account, accountAllocation, insured, load]);

  useEffect(() => {
    setInsured(undefined);
    if (!account || account.type !== AccountType.KF_SWE) {
      return;
    }

    dataLife.getInsured(account.accountId).then(setInsured);
  }, [account]);

  useEffect(() => {
    if (!inView) {
      return;
    }
    if (!account || !accountAllocation) {
      return;
    }

    if (account.type === AccountType.KF_SWE && typeof insured === "undefined") {
      return;
    }

    load(account, accountAllocation, insured);
  }, [inView, load, account, accountAllocation, insured]);

  const showInsuranceFees =
    dataAccounts.isInsuranceAccount(account) &&
    fees?.cost.insurancePremium !== undefined;
  const showPensionFees =
    isPensionAccountType(account?.type) &&
    fees?.cost.insurancePremium !== undefined;

  return (
    <section className="account-page-fees-example" ref={ref}>
      <Typography type="h2">
        <FormattedMessage id="accountPage.fees.example.header" />
      </Typography>
      <CardList>
        <Retry retry={retry} status={status}>
          <div className="account-page-padder">
            <div className="account-page-card-body">
              {fees && fees.rebate && (
                <Snackbar type={SNACKBAR_TYPES.SUCCESS}>
                  <span>
                    <FormattedMessage
                      id="accountPage.fees.example.discount"
                      values={{
                        discount: intl.formatNumber(fees.rebate / 100, {
                          style: "percent",
                        }),
                        endDate: fees.rebateExpiry
                          ? intl.formatDate(fees.rebateExpiry)
                          : "-",
                      }}
                    />
                  </span>
                </Snackbar>
              )}
              <strong className="account-page-card-subheader">
                <FormattedMessage id="accountPage.fees.example.estimation" />{" "}
                <FormattedDate value={new Date()} year="numeric" /> -{" "}
                {fees?.cost.total ? (
                  <FormattedPercentage value={fees.cost.total} decimals={3} />
                ) : (
                  "-"
                )}
              </strong>
              {getFutureFees(fees?.cost, showInsuranceFees, showPensionFees)}
            </div>
          </div>
        </Retry>
      </CardList>
      <Disclaimer>
        <FormattedMessage id="accountPage.fees.example.disclaimer" />
      </Disclaimer>
    </section>
  );
};
