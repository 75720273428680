import { Form, LysaFormRef, Button, Spinner } from "@lysaab/ui-2";
import { useContext, useEffect, useRef } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useRouteMatch } from "react-router";
import { EventTracker } from "../../../../../../components/eventTracker/EventTracker";
import { LysaAccountSelectionCard } from "../../../../../../components/lysaAccountSelectionCard/LysaAccountSelectionCard";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { InvestmentAccountId } from "../../../../../../data/dataAccounts";
import { TrackerEvent } from "../../../../../../data/dataCustomerTracking";
import { useAccounts } from "../../../../../../hooks/useAccounts";
import { MonthlyContext } from "../MonthlyContext";

interface Props {
  next: () => void;
}

interface Match {
  accountId?: InvestmentAccountId;
}

const messages = defineMessages({
  lysaAccountLabel: {
    id: "sweden.deposits.monthly.story.lysa-account-selection.lysa.label",
  },
  lysaAccountRequired: {
    id: "sweden.deposits.monthly.story.lysa-account-selection.lysa.required",
  },
});

export function LysaAccountSelection({ next }: Props) {
  const { accounts } = useAccounts();
  const formRef = useRef<LysaFormRef>();
  const monthlyContext = useContext(MonthlyContext);
  const selectedLysaAccount = monthlyContext.state.selectedLysaAccount;
  const intl = useIntl();
  const match = useRouteMatch<Match>();
  const loaded = useRef(false);

  useEffect(() => {
    if (loaded.current || typeof accounts === "undefined") {
      return;
    }
    loaded.current = true;

    if (match.params.accountId) {
      const account = [
        ...accounts.investmentAccounts,
        ...accounts.savingsAccounts,
      ].find((acc) => acc.accountId === match.params.accountId);
      if (account) {
        monthlyContext.setState({ selectedLysaAccount: account });
        EventTracker.track({
          event: TrackerEvent.ADD_MONTHLY_ACCOUNT_CHOICE,
          message: account.name,
        });
        next();
      }
    }
  }, [accounts, match.params.accountId, monthlyContext, next]);

  if (!accounts) {
    return <Spinner />;
  }

  return (
    <div>
      <h2>
        <TranslatedText id="sweden.deposits.monthly.story.lysa-account-selection.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            return;
          }
          EventTracker.track({
            event: TrackerEvent.ADD_MONTHLY_ACCOUNT_CHOICE,
            message: monthlyContext.state.selectedLysaAccount?.name,
          });
          next();
        }}
      >
        <LysaAccountSelectionCard
          accounts={[
            ...accounts.investmentAccounts,
            ...accounts.savingsAccounts,
          ]}
          selectedAccount={selectedLysaAccount}
          onChange={(account) =>
            monthlyContext.setState({
              selectedLysaAccount: account,
            })
          }
          legend={intl.formatMessage(messages.lysaAccountLabel)}
        />
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="sweden.deposits.monthly.story.lysa-account-selection.button" />
          }
        />
      </Form>
    </div>
  );
}
