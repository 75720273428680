import {
  Card,
  Form,
  LysaFormRef,
  MinValidator,
  MoneyInput,
  Button,
  RequiredValidator,
  Select,
  Spinner,
} from "@lysaab/ui-2";
import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { useMonthlyInternalTransferContext } from "../MonthlyInternalTransferContext";
import { useLocalizationContext } from "../../../context/LocalizationContext";
import { TranslatedText } from "../../../components/TranslatedText";
import { useHistory } from "react-router-dom";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { BASE_ROUTES } from "../MonthlyInternalTransferStory";

interface Props {
  next: () => void;
}

const MIN_AMOUNT = 0.01;
const daysOfMonth = Array.from(Array(31).keys()).map((day) => day + 1);

export const Amount = ({ next }: Props) => {
  const history = useHistory();
  const formRef = useRef<LysaFormRef>();
  const { currency, country } = useLocalizationContext().state;
  const monthlyInternalTransferContext = useMonthlyInternalTransferContext();
  const intl = useIntl();
  const moneyInputValidators = [
    new RequiredValidator(
      intl.formatMessage({
        id: "monthly-internal-transfer.story.amount-and-day-selection.amount.required",
      })
    ),
    new MinValidator(
      MIN_AMOUNT,
      intl.formatMessage(
        {
          id: "monthly-internal-transfer.story.amount-and-day-selection.amount.min",
        },
        {
          minAmount: intl.formatNumber(MIN_AMOUNT, {
            style: "currency",
            currency,
          }),
        }
      )
    ),
  ];

  useEffect(() => {
    if (
      typeof monthlyInternalTransferContext.state.fromAccountId === "undefined"
    ) {
      return history.replace(getNavLink(BASE_ROUTES.FROM_ACCOUNT));
    }
  }, [history, monthlyInternalTransferContext.state.fromAccountId]);

  if (!country) {
    return <Spinner />;
  }

  return (
    <div className="monthly-amount">
      <h2>
        <TranslatedText id="monthly-internal-transfer.story.amount-and-day-selection.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            return;
          }
          next();
        }}
      >
        <Card>
          <MoneyInput
            currency={currency}
            value={monthlyInternalTransferContext.state.amount?.toString()}
            onChange={(amount) =>
              monthlyInternalTransferContext.setState({
                amount: Number(amount),
              })
            }
            label={intl.formatMessage({
              id: "monthly-internal-transfer.story.amount-and-day-selection.amount.label",
            })}
            validators={moneyInputValidators}
          />
          <Select
            alternatives={daysOfMonth.map((day) => {
              return {
                text: day.toString(),
                value: day,
              };
            })}
            label={intl.formatMessage({
              id: "monthly-internal-transfer.story.amount-and-day-selection.day.label",
            })}
            placeholder={intl.formatMessage({
              id: "monthly-internal-transfer.story.amount-and-day-selection.day.placeholder",
            })}
            onChange={(day) =>
              monthlyInternalTransferContext.setState({
                transferDay: Number(day.value),
              })
            }
            value={{
              text:
                monthlyInternalTransferContext.state.transferDay?.toString() ||
                "29",
              value: monthlyInternalTransferContext.state.transferDay || 29,
            }}
            validators={[
              new RequiredValidator(
                intl.formatMessage({
                  id: "monthly-internal-transfer.story.amount-and-day-selection.day.required",
                })
              ),
            ]}
          />
          <p>
            <TranslatedText id="monthly-internal-transfer.story.amount-and-day-selection.day.info" />
          </p>
        </Card>
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="monthly-internal-transfer.story.amount-and-day-selection.button" />
          }
        />
      </Form>
    </div>
  );
};
