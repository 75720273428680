import { Spinner, Button, Icon, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { DateTime } from "luxon";
import { useContext, useEffect, useState, VoidFunctionComponent } from "react";
import {
  FormattedMessage,
  FormattedDate,
  defineMessages,
  useIntl,
} from "react-intl";
import { AccountType } from "../../../../../components/accountType/AccountType";
import { GridCol } from "../../../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../../../components/grid/gridRow/GridRow";
import { UserContext } from "../../../../../context/UserContext";
import {
  InvestmentAccount,
  InvestmentAccountId,
  AccountType as AccountTypeEnum,
} from "../../../../../data/dataAccounts";
import {
  DeclarationDocumentation,
  dataCorporate,
} from "../../../../../data/dataCorporate";
import { ClosedAccount } from "../../../../../data/dataProfile";
import { DataDisplay } from "../../../../../components/dataDisplay/DataDisplay";
import { HoldingDeclaration } from "./holdingDeclaration/HoldingDeclaration";
import {
  DeclarationTransaction,
  TransactionDeclaration,
} from "./transactionDeclaration/TransactionDeclaration";
import { useAccountParams } from "./UseAccountParams";
import { isClosedAccount } from "../../../../../pages/historicTransactions/historicTransactions/HistoricTransactions";
import { dataDanica } from "../../../../../data/dataDanica";
import { Link } from "react-router-dom";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { MOVE_ACCOUNTING_PAGE_URL } from "../moveAccounting/MoveAccounting";

const messages = defineMessages({
  companyName: {
    id: "sweden.accountingPage.accountingDeclaration.company.name",
  },
  companyOrgNumber: {
    id: "sweden.accountingPage.accountingDeclaration.company.orgNumber",
  },
  companyAccount: {
    id: "sweden.accountingPage.accountingDeclaration.company.account",
  },
  companyTimePeriod: {
    id: "sweden.accountingPage.accountingDeclaration.company.timePeriod",
  },
  holdingsStart: {
    id: "sweden.accountingPage.accountingDeclaration.holdings.start",
  },
  holdingsEnd: {
    id: "sweden.accountingPage.accountingDeclaration.holdings.end",
  },
  declarationTransactions: {
    id: "sweden.accountingPage.accountingDeclaration.declaration.transactions",
  },
});

type Props = {
  account?: InvestmentAccount | ClosedAccount;
  getAccountName: (accountId: InvestmentAccountId) => string;
};

export const AccountingInformation: VoidFunctionComponent<Props> = ({
  account,
  getAccountName,
}) => {
  const intl = useIntl();
  const userContext = useContext(UserContext);
  const [declaration, setDeclaration] = useState<DeclarationDocumentation>();
  const [transactions, setTransactions] = useState<DeclarationTransaction[]>(
    []
  );

  const [fromDate, toDate, accountId] = useAccountParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [showMoveBanner, setShowMoveBanner] = useState(false);

  useEffect(() => {
    if (declaration) {
      setTransactions(
        [
          ...declaration.cashTransactions,
          ...declaration.feeTransactions.map((feeTransaction) => ({
            ...feeTransaction,
            booked: DateTime.fromISO(feeTransaction.booked)
              .set({ hour: 23, minute: 59, second: 59 })
              .toISO(),
          })),
          ...declaration.fundTransactions,
        ].sort((a, b) =>
          DateTime.fromISO(b.booked).diff(DateTime.fromISO(a.booked)).toMillis()
        )
      );
    } else {
      setTransactions([]);
    }
  }, [declaration]);

  useEffect(() => {
    if (fromDate && toDate && accountId) {
      setLoading(true);
      dataCorporate
        .getAccountDeclarationDocumentation(
          accountId,
          DateTime.fromJSDate(fromDate).toISODate(),
          DateTime.fromJSDate(toDate).toISODate()
        )
        .then(setDeclaration)
        .finally(() => setLoading(false));
    }
  }, [fromDate, toDate, accountId]);

  useEffect(() => {
    setShowMoveBanner(false);

    if (
      !account ||
      !isClosedAccount(account) ||
      account.type !== AccountTypeEnum.DANICA_KF
    ) {
      return;
    }

    dataDanica.getKfMoveAmount(account.accountId).then((moveAmount) => {
      if (moveAmount.amount > 0) {
        setShowMoveBanner(true);
      }
    });
  }, [account]);

  if (loading || !accountId || !account) {
    return (
      <div className="spinner-container">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <GridRow>
        <GridCol xsmall={12}>
          <ul className="company-details">
            <GridRow className="company-title">
              <GridCol>
                <h3>
                  <FormattedMessage
                    id="sweden.accountingPage.account.title"
                    values={{
                      accountType: (
                        <>
                          {" - "}
                          <AccountType account={account} long capitalized />
                        </>
                      ),
                    }}
                  />
                </h3>
              </GridCol>

              <GridCol className="no-print print-button">
                <Button
                  size="small"
                  onClick={() => window.print()}
                  icon="Print"
                  label={
                    <FormattedMessage id="sweden.accountingPage.account.print" />
                  }
                />
              </GridCol>

              <GridCol className="only-print">
                <Icon.Logo color="#000" size={60} />
              </GridCol>
            </GridRow>
            <li>
              <DataDisplay
                type="side-by-side"
                title={intl.formatMessage(messages.companyName)}
                text={userContext.state.name}
              />
            </li>

            <li>
              <DataDisplay
                type="side-by-side"
                title={intl.formatMessage(messages.companyOrgNumber)}
                text={userContext.state.tin}
              />
            </li>

            <li>
              <DataDisplay
                type="side-by-side"
                title={intl.formatMessage(messages.companyAccount)}
                text={account?.name}
              />
            </li>

            <li>
              <DataDisplay
                type="side-by-side"
                title={intl.formatMessage(messages.companyTimePeriod)}
                text={
                  <>
                    <FormattedDate value={fromDate} />
                    {" - "}
                    <FormattedDate value={toDate} />
                  </>
                }
              />
            </li>
          </ul>

          {showMoveBanner && (
            <Snackbar type={SNACKBAR_TYPES.INFO}>
              <FormattedMessage
                id="sweden.accountingPage.accountingDeclaration.move-banner"
                values={{
                  Link: (link) => (
                    <Link
                      to={
                        getNavLink(MOVE_ACCOUNTING_PAGE_URL) +
                        "?accountId=" +
                        account.accountId
                      }
                    >
                      {link}
                    </Link>
                  ),
                }}
              />
            </Snackbar>
          )}

          {account.type === "VP_SWE" && (
            <p className="account-info no-print">
              <FormattedMessage
                id="sweden.accountingPage.account.declaration.info"
                values={{
                  link1: (
                    <a
                      href="https://www.skatteverket.se/foretagorganisationer/sjalvservice/svarpavanligafragor/dek[…]nintaktpafondandelarvadardet.5.133ff59513d6f9ee2eb1d7c.html"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage id="sweden.accountingPage.account.declaration.info.link1" />
                    </a>
                  ),
                  link2: (
                    <a
                      href="https://www4.skatteverket.se/rattsligvagledning/edition/2021.17/2814.html"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage id="sweden.accountingPage.account.declaration.info.link2" />
                    </a>
                  ),
                }}
              />
            </p>
          )}
        </GridCol>
      </GridRow>
      <GridRow>
        {declaration ? (
          <GridCol xsmall={12} className="transactions">
            {declaration.start.length ||
            declaration?.end.length ||
            transactions.length ? (
              <>
                <HoldingDeclaration
                  title={intl.formatMessage(messages.holdingsStart)}
                  holdings={declaration.start}
                  missingData={
                    <Snackbar type={SNACKBAR_TYPES.INFO}>
                      <FormattedMessage id="sweden.accountingPage.accountingDeclaration.info.no.holdings.start" />
                    </Snackbar>
                  }
                />

                <HoldingDeclaration
                  title={intl.formatMessage(messages.holdingsEnd)}
                  holdings={declaration.end}
                  missingData={
                    <Snackbar type={SNACKBAR_TYPES.INFO}>
                      <FormattedMessage id="sweden.accountingPage.accountingDeclaration.info.no.holdings.end" />
                    </Snackbar>
                  }
                />

                <TransactionDeclaration
                  title={intl.formatMessage(messages.declarationTransactions)}
                  transactions={transactions}
                  getAccountName={getAccountName}
                  missingData={
                    <Snackbar type={SNACKBAR_TYPES.INFO}>
                      <FormattedMessage id="sweden.accountingPage.accountingDeclaration.info.no.transactions" />
                    </Snackbar>
                  }
                />
              </>
            ) : (
              <Snackbar type={SNACKBAR_TYPES.INFO}>
                <FormattedMessage id="sweden.accountingPage.accountingDeclaration.info.no.data" />
              </Snackbar>
            )}
          </GridCol>
        ) : null}
      </GridRow>
    </>
  );
};
