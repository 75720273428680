import {
  Card,
  Form,
  LysaFormRef,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
  WorldCountry,
} from "@lysaab/ui-2";
import { useEffect, useRef, useState, VoidFunctionComponent } from "react";
import { CitizenshipFormFields } from "../../../../components/citizenshipFormFields/CitizenshipFormFields";
import { EventTracker } from "../../../../components/eventTracker/EventTracker";
import { TranslatedText } from "../../../../components/TranslatedText";
import { TrackerEvent } from "../../../../data/dataCustomerTracking";
import { dataLegalEntity } from "../../../../data/dataLegalEntity";

export interface Props {
  next: () => void;
}

export const Citizenship: VoidFunctionComponent<Props> = ({ next }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [citizenships, setCitizenships] =
    useState<(WorldCountry | undefined)[]>();
  const formRef = useRef<LysaFormRef>();

  const fetchCitizenships = () => {
    setIsLoading(true);
    dataLegalEntity
      .getProfileSettings()
      .then(({ details }) => {
        setCitizenships(details.citizenshipsIso);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => setIsLoading(false));
  };

  const postCitizenships = () => {
    setIsLoading(true);
    const filteredCitizenships = citizenships?.filter(isWorldCountry);

    if (filteredCitizenships) {
      dataLegalEntity
        .updateCitizenships(filteredCitizenships)
        .then(() => {
          EventTracker.track({
            event: TrackerEvent.YEARLY_REVIEW_CITIZENSHIP_SAVE,
            message: { citizenships: filteredCitizenships },
          });
          next();
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    fetchCitizenships();
  }, []);

  return (
    <div className="citizenship">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (formRef.current?.isValid) {
            postCitizenships();
          }
        }}
      >
        <h2>
          <TranslatedText id="yearly.citizenship.header" />
        </h2>
        <Card showLoading={isLoading}>
          {error && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="yearly.citizenship.error" />
            </Snackbar>
          )}
          <CitizenshipFormFields
            citizenships={citizenships}
            setCitizenships={(value) => setCitizenships(value)}
          />
        </Card>

        {typeof citizenships !== "undefined" && (
          <Button
            type="submit"
            block
            label={<TranslatedText id="yearly.citizenship.button.next" />}
          />
        )}
      </Form>
    </div>
  );
};

const isWorldCountry = (
  worldCountry: WorldCountry | undefined
): worldCountry is WorldCountry => {
  return typeof worldCountry !== "undefined";
};
