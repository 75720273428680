import {
  Form,
  LysaFormRef,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { useContext, useEffect, useRef, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { TranslatedText } from "../../../../../../../components/TranslatedText";
import { UserContext } from "../../../../../../../context/UserContext";
import {
  dataAccounts,
  CompoundAccount,
} from "../../../../../../../data/dataAccounts";
import { getNavLink } from "../../../../../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../../../../../hooks/useSafeNavigation";
import { AutogiroDepositContext } from "../AutogiroDepositContext";
import {
  FallbackTransferState,
  DEPOSITS_OVERVIEW_URL,
} from "../../../../../../../pages/deposits/overview/Recommendation";
import { LysaAccountSelectionCard } from "../../../../../../../components/lysaAccountSelectionCard/LysaAccountSelectionCard";

interface Props {
  next: () => void;
}

const messages = defineMessages({
  lysaAccountLabel: {
    id: "sweden.deposits.autogiro.story.account-selection.lysa-account.label",
  },
  lysaAccountRequired: {
    id: "sweden.deposits.autogiro.story.account-selection.lysa-account.required",
  },
});

export function LysaAccountSelection({ next }: Props) {
  const [accounts, setAccounts] = useState<CompoundAccount[]>();
  const [error, setError] = useState(false);
  const autogiroDepositContext = useContext(AutogiroDepositContext);
  const userContext = useContext(UserContext);
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();
  const safeNavigation = useSafeNavigation();
  const location = useLocation<FallbackTransferState>();

  useEffect(() => {
    Promise.all([dataAccounts.getAllAccounts()])
      .then(([accounts]) => {
        setAccounts([
          ...accounts.investmentAccounts,
          ...accounts.savingsAccounts,
        ]);
      })
      .catch(() => {
        setError(true);
      });
  }, []);

  const selectedAccount = autogiroDepositContext.state.lysaAccount;
  const setContext = autogiroDepositContext.setState;

  useEffect(() => {
    if (!selectedAccount && location.state && accounts) {
      setContext({
        lysaAccount: accounts.find(
          (account) => account.accountId === location.state.accountId
        ),
        amount: location.state.amount,
      });
    }
  }, [selectedAccount, location, accounts, setContext]);

  if (error) {
    return (
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <TranslatedText
          id={"sweden.deposits.autogiro.story.account-selection.error"}
        />
      </Snackbar>
    );
  }

  if (!accounts) {
    return <Spinner />;
  }

  return (
    <div className="autogiro-deposit-account-selection">
      <h2>
        <TranslatedText id="sweden.deposits.autogiro.story.account-selection.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (userContext.state.readOnly) {
            safeNavigation(getNavLink(DEPOSITS_OVERVIEW_URL));
            return;
          }
          if (
            formRef.current?.isInvalid ||
            !autogiroDepositContext.state.lysaAccount
          ) {
            return;
          }
          next();
        }}
      >
        <LysaAccountSelectionCard
          accounts={accounts}
          selectedAccount={autogiroDepositContext.state.lysaAccount}
          onChange={(account) =>
            autogiroDepositContext.setState({
              lysaAccount: account,
            })
          }
          legend={intl.formatMessage(messages.lysaAccountLabel)}
        />
        {userContext.state.readOnly && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="sweden.deposits.autogiro.story.account-selection.read-only" />
          </Snackbar>
        )}
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="sweden.deposits.autogiro.story.account-selection.button" />
          }
        />
      </Form>
    </div>
  );
}
