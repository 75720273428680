import { useContext } from "react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { getLinkPrefix, getNavLink } from "../../hooks/useCountryUrls";
import { LOGIN_RESET_PAGE_URL } from "../loginReset/LoginResetStory";
import { Link } from "react-router-dom";
import { COUNTRY_SELECT_PAGE_URL } from "../countrySelect/CountrySelectPage";
import { FlagIcon } from "../../components/flagIcon/FlagIcon";
import { LocalizationContext } from "../../context/LocalizationContext";
import { USERNAME_REMINDER_URL } from "../usernameReminder/UsernameReminder";
import { Spinner } from "@lysaab/ui-2";
import "./LoginFooter.scss";

interface Props {
  allowUsernameReminder: boolean;
}

export const LoginFooter: React.VFC<Props> = ({ allowUsernameReminder }) => {
  const localizationContext = useContext(LocalizationContext);
  const country = localizationContext.state.country;

  if (!country) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div className="login-username-password-footer">
      <p>
        <FormattedMessage
          id="login-username-password-footer.signup"
          values={{
            link: (txt) => (
              <a href={process.env.REACT_APP_SIGNUP_SITE + getLinkPrefix()}>
                {txt}
              </a>
            ),
          }}
        />
      </p>
      {allowUsernameReminder && (
        <p>
          <FormattedMessage
            id="login-username-password-footer.username-reminder"
            values={{
              link: (txt) => (
                <a href={getNavLink(USERNAME_REMINDER_URL)}>{txt}</a>
              ),
            }}
          />
        </p>
      )}
      <p>
        <FormattedMessage
          id="login-username-password-footer.reset"
          values={{
            link: (txt) => <a href={getNavLink(LOGIN_RESET_PAGE_URL)}>{txt}</a>,
          }}
        />
      </p>
      <p>
        <FlagIcon code={country.toLocaleLowerCase()} />
        <FormattedMessage
          id="login-username-password-footer.country-select"
          values={{
            link: (txt) => <Link to={COUNTRY_SELECT_PAGE_URL}>{txt}</Link>,
          }}
        />
      </p>
    </div>
  );
};
