import {
  accountNumberInfo,
  Alternative,
  Card,
  Form,
  LysaFormRef,
  Button,
  Select,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  useFlash,
} from "@lysaab/ui-2";
import {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  VoidFunctionComponent,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { StaticInput } from "../../../../../../components/staticInput/StaticInput";
import { UserContext } from "../../../../../../context/UserContext";
import {
  Consent,
  dataAutogiro,
  getConsentBankText,
} from "../../../../../../data/dataAutogiro";
import { banks } from "../../../../../../data/dataBanks";
import {
  CompanyInformation,
  dataCorporate,
} from "../../../../../../data/dataCorporate";
import { feeAccountMessages } from "../Messages";

interface Props {
  reloadRef: MutableRefObject<{ reloadConsents?: () => void }>;
}

export const FeeAccount: VoidFunctionComponent<Props> = ({ reloadRef }) => {
  const pushFlash = useFlash();
  const intl = useIntl();
  const formRef = useRef<LysaFormRef>();
  const userContext = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [consents, setConsents] = useState<Consent[]>();
  const [companyInformation, setCompanyInformation] =
    useState<CompanyInformation>();
  const [removedConsent, setRemovedConsent] = useState<Consent>();
  const [selectedAccount, setSelectedAccount] =
    useState<Alternative<Consent>>();

  const onSubmit = useCallback(() => {
    if (
      !formRef.current?.isValid ||
      !selectedAccount ||
      !selectedAccount.value.approved ||
      (removedConsent?.bank === selectedAccount?.value.bank &&
        removedConsent?.externalBankAccount ===
          selectedAccount?.value.externalBankAccount)
    ) {
      return;
    }
    if (
      selectedAccount.value.externalBankAccount ===
      companyInformation?.feeExternalBankAccount
    ) {
      pushFlash({
        text: intl.formatMessage(feeAccountMessages.snackbarSuccess),
        type: SNACKBAR_TYPES.SUCCESS,
      });
      return;
    }

    setLoading(true);
    dataCorporate
      .saveFeeAccount(selectedAccount.value.externalBankAccount.toString())
      .then(() => dataCorporate.getCompanyData())
      .then(setCompanyInformation)
      .then(() => {
        setLoading(false);
        pushFlash({
          text: intl.formatMessage(feeAccountMessages.snackbarSuccess),
          type: SNACKBAR_TYPES.SUCCESS,
        });
      })
      .catch(() => {
        setLoading(false);
        pushFlash({
          text: intl.formatMessage(feeAccountMessages.snackbarError),
          type: SNACKBAR_TYPES.ERROR,
        });
      });
  }, [
    selectedAccount,
    removedConsent,
    companyInformation?.feeExternalBankAccount,
    pushFlash,
    intl,
  ]);

  const loadData = useCallback(() => {
    Promise.all([dataAutogiro.getConsents(), dataCorporate.getCompanyData()])
      .then(([consents, companyInformation]) => {
        setConsents(consents);
        setCompanyInformation(companyInformation);

        const consent = consents.find(
          (consent) =>
            consent.externalBankAccount ===
            companyInformation.feeExternalBankAccount
        );
        if (consent) {
          setSelectedAccount({
            text: getConsentBankText(consent),
            value: consent,
          });
        } else {
          const account = accountNumberInfo(
            companyInformation.feeExternalBankAccount
          );
          const failedConsent = {
            bank: (account
              ? account.bank_name
              : companyInformation.feeExternalBankAccount) as keyof typeof banks,
            externalBankAccount: companyInformation.feeExternalBankAccount,
            approved: false,
            created: "",
          };
          setConsents([failedConsent, ...consents]);
          setRemovedConsent(failedConsent);
          setSelectedAccount({
            text: getConsentBankText(failedConsent),
            value: failedConsent,
          });
        }
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    loadData();
    reloadRef.current.reloadConsents = loadData;
  }, [loadData, reloadRef]);

  if (loading) {
    return (
      <div className="company-settings--fee-account">
        <Spinner />
      </div>
    );
  }

  const isSelectedAccountMandateRemoved =
    removedConsent?.bank === selectedAccount?.value.bank &&
    removedConsent?.externalBankAccount ===
      selectedAccount?.value.externalBankAccount;
  return (
    <div className="company-settings--fee-account">
      <h2>
        <FormattedMessage id="sweden.companySettings.feeAccount.title" />
      </h2>
      <Card>
        <Form lysaFormRef={formRef} onSubmit={onSubmit}>
          {userContext.state.readOnly ? (
            <StaticInput
              label={intl.formatMessage(feeAccountMessages.selectLabel)}
              value={selectedAccount?.text}
            />
          ) : (
            <Select
              label={intl.formatMessage(feeAccountMessages.selectLabel)}
              placeholder={intl.formatMessage(
                feeAccountMessages.selectPlaceholder
              )}
              alternatives={(consents || []).map((consent) => {
                return {
                  text: getConsentBankText(consent),
                  value: consent.externalBankAccount,
                };
              })}
              value={{
                text: selectedAccount?.text ?? "",
                value: selectedAccount?.value.externalBankAccount ?? "",
              }}
              onChange={(alternative) => {
                const consent = (consents || []).find(
                  (consent) => consent.externalBankAccount === alternative.value
                );
                if (consent) {
                  setSelectedAccount({ ...alternative, value: consent });
                }
              }}
            />
          )}
          {selectedAccount &&
            !selectedAccount.value.approved &&
            !isSelectedAccountMandateRemoved && (
              <Snackbar type={SNACKBAR_TYPES.WARNING}>
                <FormattedMessage id="sweden.companySettings.feeAccount.account.not.approved" />
              </Snackbar>
            )}

          {isSelectedAccountMandateRemoved && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <FormattedMessage id="sweden.companySettings.feeAccount.account.consent.removed" />
            </Snackbar>
          )}

          {userContext.state.readOnly === false && (
            <Button
              block
              type="submit"
              label={
                <FormattedMessage id="sweden.companySettings.feeAccount.button.submit" />
              }
            />
          )}
        </Form>
      </Card>
    </div>
  );
};
