import { generatePath, useParams } from "react-router";
import {
  InvestmentAccountId,
  InvestmentType,
  SavingsAccountId,
} from "../../data/dataAccounts";
import { TranslatedText } from "../../components/TranslatedText";
import { Page, PageHeader } from "../Page";
import { DocumentsMenu } from "./DocumentsMenu";
import { LysaDoc } from "@lysaab/ui-2";
import { useState } from "react";
import { useEffect } from "react";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import {
  dataDocuments,
  Documents,
  isActiveAccountDocument,
} from "../../data/dataDocuments";
import { LysaLink, Spinner } from "@lysaab/ui-2";
import { customerDocuments } from "./DocumentLists";
import { useContext } from "react";
import { LocalizationContext } from "../../context/LocalizationContext";
import "./DocumentsPage.scss";
import { useQuery } from "../../hooks/useQuery";
import {
  AccountShare,
  AccountShareId,
  dataAttorney,
} from "../../data/dataAttorney";
import { UserContext } from "../../context/UserContext";
import { getNavLink } from "../../hooks/useCountryUrls";

export const DOCUMENT_PAGE_URL = "/document/:group?/:document?";

export type DOCUMENT_GROUP =
  | "customer"
  | InvestmentAccountId
  | SavingsAccountId
  | AccountShareId;

export enum DocumentGroupType {
  Share = "share",
}

const messages = defineMessages<
  InvestmentType,
  { id: string },
  Record<InvestmentType, { id: string }>
>({
  [InvestmentType.BROAD]: {
    id: "documents.investment-type.broad",
  },
  [InvestmentType.SUSTAINABLE]: {
    id: "documents.investment-type.sustainable",
  },
});

export const generateDocumentLink = (
  group: DOCUMENT_GROUP,
  document: string,
  type?: DocumentGroupType
) => {
  const path = generatePath(DOCUMENT_PAGE_URL, {
    group,
    document: encodeURIComponent(document.replace(".md", "")),
  });
  const search = new URLSearchParams();
  if (type) {
    search.set("documentGroupType", type);
  }
  return getNavLink(`${path}?${search.toString()}`);
};

interface Params {
  group?: DOCUMENT_GROUP | InvestmentAccountId;
  document?: string;
}

interface QueryParams {
  documentGroupType?: DocumentGroupType;
}

export const DocumentsPage = () => {
  const params = useParams<Params>();
  const query = useQuery<QueryParams>();
  const {
    state: { name },
  } = useContext(UserContext);
  const [docData, setDocData] = useState<Documents>();
  const [accountShares, setAccountShares] = useState<AccountShare[]>();
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);

  const { documentGroupType } = query;

  useEffect(() => {
    dataDocuments.getDocuments().then(setDocData);
    Promise.all([
      dataAttorney.getAccountSharesByMe(),
      dataAttorney.getAccountSharesWithMe(),
    ]).then(([sharedByMe, sharedWithMe]) => {
      setAccountShares([...sharedByMe, ...sharedWithMe]);
    });
  }, []);

  if (!docData || !localizationContext.state.country || !accountShares) {
    return <Spinner />;
  }

  return (
    <Page>
      <PageHeader>
        <h1>
          <TranslatedText id="documents.header" />
        </h1>
      </PageHeader>
      <div className="document-mobile-link">
        <LysaLink<"a">
          href="#menu-wrapper"
          onClick={(event) => {
            event.preventDefault();
            if (event.button === 0) {
              document.getElementById("menu-wrapper")?.scrollIntoView();
            }
          }}
          component="a"
        >
          <TranslatedText id="documents.mobile.menu.link" />
        </LysaLink>
      </div>
      <div className="documents-page">
        <div id="menu-wrapper" className="menu-wrapper">
          <DocumentsMenu docData={docData} />
        </div>
        <div className="document-wrapper">
          {params.group && params.document ? (
            <LysaDoc
              path={`${decodeURIComponent(params.document)}.md`}
              variableValues={getVariables(
                params.group,
                docData,
                accountShares,
                intl,
                name,
                documentGroupType
              )}
            />
          ) : (
            <LysaDoc
              path={`${
                customerDocuments[docData.customer.entityType][
                  localizationContext.state.country
                ]
              }.md`}
              variableValues={getVariables(
                "customer",
                docData,
                accountShares,
                intl,
                name,
                documentGroupType
              )}
            />
          )}
        </div>
      </div>
    </Page>
  );
};

function getVariables(
  group: DOCUMENT_GROUP,
  docData: Documents,
  accountShares: AccountShare[],
  intl: IntlShape,
  name: undefined | string,
  documentGroupType?: DocumentGroupType
): Record<string, string> {
  if (group === "customer") {
    return {
      SIGNING_DATE: docData.customer.date,
      COMPANY_NAME: docData.customer.name,
      COMPANY_ID: docData.customer.identificationNumber,
      CUSTOMER_NAME: docData.customer.name,
      CUSTOMER_ID: docData.customer.identificationNumber,
    };
  }

  if (documentGroupType === DocumentGroupType.Share) {
    const accountShare = accountShares.find(
      (share) => share.accountShareId === group
    );

    if (!accountShare) {
      return {};
    }

    return {
      SIGNING_DATE: intl.formatDate(accountShare.created),
      ACCOUNT_NAME: accountShare.accountName,
      ACCOUNT_ID: accountShare.accountId,
      OWNER_NAME:
        "ownerName" in accountShare ? accountShare.ownerName : name ?? "",
      INVITEE_NAME:
        "inviteeName" in accountShare ? accountShare.inviteeName : name ?? "",
    };
  }

  const savingsAccount = docData.savingsAccounts.find(
    (account) => account.accountId === group
  );

  if (savingsAccount) {
    return {
      SIGNING_DATE: savingsAccount.accountSigning,
      ACCOUNT_NAME: savingsAccount.accountName,
      ACCOUNT_ID: savingsAccount.accountId,
      CUSTOMER_NAME: savingsAccount.name,
      CUSTOMER_ID: savingsAccount.identificationNumber,
    };
  }

  const account = docData.accounts.find(
    (account) => account.accountId === group
  );

  if (!account || !isActiveAccountDocument(account)) {
    return {};
  }

  return {
    SIGNING_DATE: account.accountSigning,
    COMPANY_NAME: account.name,
    COMPANY_ID: account.identificationNumber,
    CUSTOMER_NAME: account.name,
    CUSTOMER_ID: account.identificationNumber,
    ACCOUNT_NAME: account.accountName,
    ACCOUNT_ID: account.accountId,
    STOCKS: intl.formatNumber(account.stocks / 100, { style: "percent" }),
    BONDS: intl.formatNumber(account.bonds / 100, { style: "percent" }),
    INVESTMENT_TYPE: intl.formatMessage(messages[account.investmentType]),
  };
}
