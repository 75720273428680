import { FunctionComponent, useEffect, useState } from "react";
import {
  GetKfWithdrawalStatus,
  KF_WITHDRAWAL_STATUS,
  dataDanica,
} from "../../../../data/dataDanica";
import { SNACKBAR_TYPES, Snackbar } from "@lysaab/ui-2";
import "./KfMigrationInfo.scss";
import { AccountType, InvestmentAccount } from "../../../../data/dataAccounts";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { ROUTES as MIGRATION_ROUTES } from "../../../../countries/sweden/pages/corporation/migration/request/MigrationKfRequestPage";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";

interface Props {
  account?: InvestmentAccount;
}

export const KfMigrationStatus: FunctionComponent<Props> = ({ account }) => {
  const [migrationStatus, setMigrationStatus] =
    useState<GetKfWithdrawalStatus>();
  const [signingId, setSigningId] = useState<string>();

  useEffect(() => {
    setMigrationStatus(undefined);
    setSigningId(undefined);
    if (!account || account.type !== AccountType.DANICA_KF) {
      return;
    }
    dataDanica.getWithdrawalKFAccounts().then((withdrawalAccounts) => {
      const acc = withdrawalAccounts.find(
        (widthdrawalAccount) =>
          widthdrawalAccount.accountId === account?.accountId
      );
      if (!acc || !acc.pendingMove) {
        return;
      }
      setSigningId(acc.pendingMove);
      dataDanica.getKfMigrationStatus(acc.pendingMove).then(setMigrationStatus);
    });
  }, [account]);

  if (!migrationStatus || !account || !signingId) {
    return null;
  }

  if (
    migrationStatus.status === KF_WITHDRAWAL_STATUS.PENDING_DANICA ||
    migrationStatus.status ===
      KF_WITHDRAWAL_STATUS.PENDING_DANICA_MANUAL_VERIFICATION ||
    migrationStatus.status === KF_WITHDRAWAL_STATUS.PENDING_SIGNING
  ) {
    return (
      <div className="kf-active-migration-status">
        <Snackbar type={SNACKBAR_TYPES.INFO}>
          <div>
            Du har en pågående flytt av din kapitalförsäkring till Lysa Life.{" "}
            <Link
              to={getNavLink(
                generatePath(MIGRATION_ROUTES.SIGNERS, {
                  signingId: signingId,
                })
              )}
            >
              Klicka här för att se status på flytten.
            </Link>
          </div>
        </Snackbar>
      </div>
    );
  }

  if (migrationStatus.status === KF_WITHDRAWAL_STATUS.WITHDRAWAL_ONGOING) {
    return (
      <div className="kf-active-migration-status">
        <Snackbar type={SNACKBAR_TYPES.INFO}>
          <div>
            Du har begärt en flytt av din kapitalförsäkring till Lysa Life. Allt
            är klart och vi kommer automatiskt flytta din försäkring i slutet av
            året.
          </div>
        </Snackbar>
      </div>
    );
  }

  return null;
};
