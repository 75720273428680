import {
  Alternative,
  Card,
  Select,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { Fragment, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { TranslatedText } from "../../../components/TranslatedText";
import { dataLogin } from "../../../data/dataLogin";
import { AccessRightsId } from "../../../data/dataUser";

const messages = defineMessages({
  selectPrimaryPlaceholder: {
    id: "settings.changePrimaryLogin.selectPrimaryPlaceholder",
  },
  selectPrimaryLabel: {
    id: "settings.changePrimaryLogin.selectPrimaryLabel",
  },
});

export const ChangePrimaryLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rightsAlternatives, setRightsAlternatives] = useState<
    Alternative<AccessRightsId>[]
  >([]);
  const [selectedAlternative, setSelectedAlternative] =
    useState<Alternative<AccessRightsId>>();
  const intl = useIntl();

  useEffect(() => {
    dataLogin
      .getRights()
      .then((response) => {
        const currentPrimaryAccount = response.find(
          (accountRight) => accountRight.primary
        );
        if (currentPrimaryAccount) {
          setSelectedAlternative({
            text: currentPrimaryAccount.name,
            value: currentPrimaryAccount.accessRightsId,
          });
        }
        setRightsAlternatives(
          response.map((accountRight) => {
            return {
              text: accountRight.name,
              value: accountRight.accessRightsId,
            };
          })
        );
      })
      .catch(() => setError(true))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) return <Spinner />;
  /* Don't show if customer doesn't have access to any other account */
  if (rightsAlternatives.length <= 1 && !error) return null;
  return (
    <Fragment>
      <h2>
        <TranslatedText id="settings.changePrimaryLogin.header" />
      </h2>
      <Card>
        {error ? (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="settings.changePrimaryLogin.error" />
          </Snackbar>
        ) : (
          <Select
            placeholder={intl.formatMessage(messages.selectPrimaryPlaceholder)}
            alternatives={rightsAlternatives}
            value={selectedAlternative}
            onChange={(value) => {
              setIsLoading(true);
              dataLogin
                .changePrimaryLogin(value.value)
                .then((response) => {
                  const primary = response.find(
                    (accountRight) => accountRight.primary
                  );
                  if (primary) {
                    setSelectedAlternative({
                      text: primary.name,
                      value: primary.accessRightsId,
                    });
                  }
                })
                .catch(() => setError(true))
                .finally(() => setIsLoading(false));
            }}
            label={intl.formatMessage(messages.selectPrimaryLabel)}
          />
        )}
      </Card>
    </Fragment>
  );
};
