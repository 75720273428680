import { SafeLazyImport } from "../../components/safeLazyImport/SafeLazyImport";
import { preloadableImport } from "../../components/safeLazyImport/PreloadableImport";

export const ACCOUNT_STATEMENT_PAGE = "/account-statement";

const LazyAccountStatementPage = preloadableImport(() =>
  import("./LazyAccountStatementPage").then((module) => ({
    default: module.LazyAccountStatementPage,
  }))
);

export const AccountStatementPage = () => {
  return (
    <SafeLazyImport>
      <LazyAccountStatementPage />
    </SafeLazyImport>
  );
};
