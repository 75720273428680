import { VoidFunctionComponent } from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  Alternative,
  Card,
  CheckboxGroup,
  RequiredValidator,
} from "@lysaab/ui-2";
import { PurposeAccountCorporation } from "../../../data/dataKyc";
import { DataDisplay } from "../../dataDisplay/DataDisplay";
import { useIsPerson } from "../../../hooks/useIsPerson";

export const messages = defineMessages({
  header: {
    id: "kyc.purpose.corp.header",
  },
  required: {
    id: "kyc.purpose.corp.required",
  },
});

interface MessageWithId {
  id: string;
}

export const corporationPurposeMessages = defineMessages<
  PurposeAccountCorporation,
  MessageWithId,
  Record<PurposeAccountCorporation, MessageWithId>
>({
  [PurposeAccountCorporation.LONGTERM_INVESTMENTS]: {
    id: "kyc.purpose.corp.alt.LONGTERM_INVESTMENTS",
  },
  [PurposeAccountCorporation.SURPLUS_LIQUIDITY]: {
    id: "kyc.purpose.corp.alt.SURPLUS_LIQUIDITY",
  },
});

interface Props {
  value?: PurposeAccountCorporation[];
  setValue: (purpose: PurposeAccountCorporation[]) => void;
  display?: boolean;
}

export const PurposeQuestionAccountCoporation: VoidFunctionComponent<Props> = ({
  value,
  setValue,
  display,
}) => {
  const intl = useIntl();
  const isPerson = useIsPerson();

  if (isPerson) {
    return null;
  }

  const alternatives = Object.values(PurposeAccountCorporation).map(
    (purpose) => {
      return {
        text: intl.formatMessage(corporationPurposeMessages[purpose]),
        value: purpose,
      } as Alternative<PurposeAccountCorporation>;
    }
  );
  const valueAlternatives = alternatives.filter((alt) =>
    (value ?? []).includes(alt.value)
  );

  if (display) {
    return (
      <DataDisplay
        hasContainer
        type="input"
        title={intl.formatMessage(messages.header)}
        text={valueAlternatives.map((value) => value.text).join(", ")}
      />
    );
  }

  return (
    <Card>
      <CheckboxGroup
        alternatives={alternatives}
        values={valueAlternatives}
        onChange={(purposes) =>
          setValue(purposes.map((purpose) => purpose.value))
        }
        header={intl.formatMessage(messages.header)}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.required)),
        ]}
      />
    </Card>
  );
};
