import { Checkbox, RequiredValidator } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { useCreateKFAccount } from "../../KFAccountContext";

export const UnderstoodRights = () => {
  const intl = useIntl();
  const [KFAccount, setKFAccount] = useCreateKFAccount();

  return (
    <Checkbox
      alternative={{
        text: intl.formatMessage({
          id: "sweden.kf.intro.rights-info.label",
        }),
        value: true,
      }}
      checked={!!KFAccount.understoodRights}
      onChange={(value) =>
        setKFAccount({
          understoodRights: !KFAccount.understoodRights,
        })
      }
      validators={[
        new RequiredValidator(
          intl.formatMessage({
            id: "sweden.kf.intro.rights-info.required",
          })
        ),
      ]}
    />
  );
};
