import { BANK_ICON_CLASS } from "../../BankIcon";
import Icon from "./lansforsakringar.svg";

export function Lansforsakringar() {
  return (
    <div className={BANK_ICON_CLASS}>
      <img src={Icon} alt="Länsförsäkringar icon" />
    </div>
  );
}
