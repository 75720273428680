import React, { useState } from "react";
import { defineMessages, MessageDescriptor, useIntl } from "react-intl";
import {
  Button,
  CardList,
  InvestmentType,
  RiskIndicator,
  Typography,
} from "@lysaab/ui-2";
import { PensionAdviseAccount } from "../../../../data/dataInvestments";
import { GlidePathGraph } from "../../../../components/glidePathGraph/GlidePathGraph";
import {
  AllocationStage,
  usePensionAllocation,
} from "../../../../hooks/usePensionAllocation";
import { TranslatedText } from "../../../../components/TranslatedText";
import { Modal } from "../../../../components/modal/Modal";
import { CardBottom } from "../../CardBottom";
import { Link } from "react-router-dom";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { MESSAGES_PAGE_URL } from "../../../messages/MessagesPage";
import { InvestmentAccount } from "../../../../data/dataAccounts";
import { HideIfReadOnly } from "../../../../components/hideIfReadOnly/HideIfReadOnly";

import "./PensionAccountFocus.scss";

interface Props {
  account: InvestmentAccount | undefined;
  adviseAccount: PensionAdviseAccount;
}

export const PensionAccountFocus: React.FC<Props> = ({
  account,
  adviseAccount,
}) => {
  const intl = useIntl();
  const [showDeclarationModal, setShowDeclarationModal] = useState(false);
  const { allocation } = usePensionAllocation({
    age: adviseAccount.age,
    withdrawalAge: adviseAccount.withdrawalAge,
    withdrawalMonths: adviseAccount.withdrawalMonths,
    takenRiskDeviation: adviseAccount.takenRiskDeviation,
  });

  if (!account || !allocation) {
    return null;
  }

  return (
    <section className="PensionAccountFocus account-page-pension-account-focus">
      <div className="PensionAccountFocus__header">
        <Typography type="h2">
          <TranslatedText id="account.pension-account-focus.header" />
        </Typography>
        <div>
          <Button
            variant="secondary"
            size="small"
            label={intl.formatMessage({
              id: "account.pension-account-focus.show-declaration",
            })}
            onClick={() => setShowDeclarationModal(true)}
          />
        </div>
      </div>
      <CardList>
        <div className="account-page-card-body">
          <Typography type="label">
            <TranslatedText id="account.pension-account-focus.allocation.header" />
          </Typography>

          {adviseAccount.takenRiskDeviation !== 0 && (
            <Typography>
              <TranslatedText
                id="account.pension-account-focus.allocation.takenRiskDeviation"
                values={{
                  deviation: intl.formatNumber(
                    adviseAccount.takenRiskDeviation / 100,
                    { style: "percent" }
                  ),
                }}
              />
            </Typography>
          )}

          <Typography>
            {allocation.allocationStage === AllocationStage.GROWTH ? (
              <TranslatedText
                id="account.pension-account-focus.allocation.growth-stage"
                values={{
                  currentTakenRisk: intl.formatNumber(
                    allocation.currentTakenRisk / 100,
                    { style: "percent" }
                  ),
                  finalTakenRisk: intl.formatNumber(
                    allocation.finalTakenRisk / 100,
                    { style: "percent" }
                  ),
                  years: allocation.withdrawalAge - allocation.reallocationAge,
                }}
              />
            ) : (
              <TranslatedText
                id="account.pension-account-focus.allocation.rebalance-stage"
                values={{
                  currentTakenRisk: intl.formatNumber(
                    allocation.currentTakenRisk / 100,
                    { style: "percent" }
                  ),
                  finalTakenRisk: intl.formatNumber(
                    allocation.finalTakenRisk / 100,
                    { style: "percent" }
                  ),
                }}
              />
            )}
          </Typography>

          <GlidePathGraph
            glidePath={allocation.glidePath}
            reallocationAge={allocation.reallocationAge}
            withdrawalAge={allocation.withdrawalAge}
          />

          <div className="PensionAccountFocus__info-card">
            <dl>
              <Typography component="dt">
                <TranslatedText id="account.pension-account-focus.withdrawalAge.title" />
              </Typography>
              <Typography component="dd">
                <TranslatedText
                  id="account.pension-account-focus.withdrawalAge.value"
                  values={{ age: adviseAccount.withdrawalAge }}
                />
              </Typography>
            </dl>
            <dl>
              <Typography component="dt">
                <TranslatedText id="account.pension-account-focus.withdrawalDuration.title" />
              </Typography>
              <Typography component="dd">
                <TranslatedText
                  id="account.pension-account-focus.withdrawalDuration.value"
                  values={{
                    years: adviseAccount.withdrawalMonths / 12, // Converted to years
                  }}
                />
              </Typography>
            </dl>
            <dl>
              <Typography component="dt">
                <TranslatedText id="account.pension-account-focus.repaymentCover.title" />
              </Typography>
              <Typography component="dd">
                <TranslatedText id="account.pension-account-focus.repaymentCover.value" />
              </Typography>
            </dl>
          </div>

          <dl>
            <Typography component="dt">
              <TranslatedText id="account.pension-account-focus.type" />
            </Typography>
            <Typography component="dd" data-test-id="account-investment-type">
              {intl.formatMessage(
                investmentTypeMessages[adviseAccount.investmentType]
              )}
              <RiskIndicator
                size={20}
                risk={adviseAccount.takenRisk}
                investmentType={adviseAccount.investmentType}
              />
            </Typography>
          </dl>
        </div>
        <HideIfReadOnly>
          {!account.isSharedAccount && (
            <CardBottom>
              <Link to={getNavLink(MESSAGES_PAGE_URL)}>
                <TranslatedText id="account.pension-account-focus.update" />
              </Link>
            </CardBottom>
          )}
        </HideIfReadOnly>
      </CardList>

      <Modal
        header={intl.formatMessage({
          id: "account.pension-account-focus.declaration-modal.header",
        })}
        showModal={showDeclarationModal}
        onClose={() => setShowDeclarationModal(false)}
        closeOnOverlayClick
      >
        <Typography>{adviseAccount.declaration}</Typography>
      </Modal>
    </section>
  );
};

const investmentTypeMessages = defineMessages<
  InvestmentType,
  MessageDescriptor,
  Record<InvestmentType, MessageDescriptor>
>({
  [InvestmentType.BROAD]: {
    id: "account.pension-account-focus.type.BROAD",
  },
  [InvestmentType.SUSTAINABLE]: {
    id: "account.pension-account-focus.type.SUSTAINABLE",
  },
});
