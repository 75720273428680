import { useCallback, useRef, VoidFunctionComponent } from "react";
import { Button, Form, LysaFormRef, Spinner } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { isSustainabilityImportantSpecific } from "../../../data/dataInvestments";
import { PAIQuestion } from "../../../pageComponents/sustainability/PAIQuestion";
import { SFDRQuestion } from "../../../pageComponents/sustainability/SFDRQuestion";
import { TaxonomyQuestion } from "../../../pageComponents/sustainability/TaxonomyQuestion";
import { useAccountContext } from "../context/AccountContext";
import { AccountPreferences } from "../../../context/AccountsContext";

interface Props {
  next: () => void;
}

export const SustainabilityQuestions: VoidFunctionComponent<Props> = ({
  next,
}) => {
  const formRef = useRef<LysaFormRef>();
  const [accountState, setAccountState] = useAccountContext();

  const onSubmit = useCallback(() => {
    if (formRef.current?.isValid) {
      next();
    }
  }, [next]);

  if (
    !accountState.account ||
    !isSustainabilityImportantSpecific(accountState.account)
  ) {
    return <Spinner />;
  }

  return (
    <Form lysaFormRef={formRef} onSubmit={onSubmit}>
      <h2>
        <FormattedMessage id="accountSituation.sustainabilityQuestions.title" />
      </h2>

      <SFDRQuestion
        sfdr={accountState.account.sfdr}
        setSfdr={(sfdr) =>
          setAccountState({
            account: {
              ...accountState.account,
              sfdr: sfdr.value,
            } as AccountPreferences,
          })
        }
      />
      <TaxonomyQuestion
        taxonomy={accountState.account?.taxonomy}
        setTaxonomy={(taxonomy) =>
          setAccountState({
            account: {
              ...accountState.account,
              taxonomy: taxonomy.value,
            } as AccountPreferences,
          })
        }
      />
      <PAIQuestion
        pai={accountState.account.pai}
        setPai={(pai) =>
          setAccountState({
            account: {
              ...accountState.account,
              pai: pai.value,
            } as AccountPreferences,
          })
        }
      />
      <Button
        block
        type="submit"
        label={
          <FormattedMessage id="accountSituation.sustainabilityQuestions.button.next" />
        }
      />
    </Form>
  );
};
