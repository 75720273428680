import { useRef, VoidFunctionComponent } from "react";
import { Button, Form, LysaFormRef } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { SustainabilityQuestion } from "../../../../../../../pageComponents/sustainability/SustainabilityQuestion";
import { useCreateKFAccount } from "../../KFAccountContext";

interface Props {
  next(): void;
}

export const Sustainability: VoidFunctionComponent<Props> = ({ next }) => {
  const formRef = useRef<LysaFormRef>();
  const [KFAccount, setKFAccount] = useCreateKFAccount();

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={() => {
        if (formRef.current?.isValid) {
          next();
        }
      }}
    >
      <SustainabilityQuestion
        sustainability={KFAccount.sustainability}
        setSustainability={(sustainability) => setKFAccount({ sustainability })}
      />
      <Button
        block
        type="submit"
        label={
          <FormattedMessage id="sweden.createAccount.sustainability.next" />
        }
      />
    </Form>
  );
};
