import { VoidFunctionComponent } from "react";
import { Button } from "@lysaab/ui-2";
import { Link } from "react-router-dom";
import { OVERVIEW_PAGE_URL } from "../../../overview/OverviewPage";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { FormattedMessage } from "react-intl";

export const Done: VoidFunctionComponent = () => {
  return (
    <>
      <h2>
        <FormattedMessage id="yearly.done.title" />
      </h2>

      <p>
        <FormattedMessage id="yearly.done.description" />
      </p>

      <Button
        block
        component={Link}
        to={getNavLink(OVERVIEW_PAGE_URL)}
        label={<FormattedMessage id="yearly.done.button.navigate" />}
      />
    </>
  );
};
