import { FunctionComponent } from "react";
import * as React from "react";
import cx from "classnames";

import "./LysaTable.scss";

interface Props {
  className?: string;
  zebraStriped?: boolean;
  small?: boolean;
  fixed?: boolean;
}
export const LysaTable: FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  className,
  zebraStriped = true,
  small,
  fixed = false,
}) => {
  return (
    <table
      className={cx("lysa-table", className, {
        "lysa-table--zebra": zebraStriped,
        "lysa-table--small": small,
        "lysa-table--fixed": fixed,
      })}
    >
      {children}
    </table>
  );
};
