import {
  LysaFormRef,
  Spinner,
  Form,
  Button,
  Typography,
  RadioGroupCard,
  RequiredValidator,
} from "@lysaab/ui-2";
import { Fragment, useEffect, useRef } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router";
import { TranslatedText } from "../../../../components/TranslatedText";
import { useAccounts } from "../../../../hooks/useAccounts";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { AccountTypeLogo } from "../../../../components/accountType/AccountTypeLogo";
import { Amount } from "../../../../components/amount/Amount";
import HorizontalDivider from "../../../../components/horizontalDivider/HorizontalDivider";
import { useWithdrawalIskToSavingsContext } from "../WithdrawalIskToSavingsContext";
import { WITHDRAWAL_ISK_TO_SAVINGS_PAGE } from "../WithdrawalIskToSavingsStory";
import { WithdrawalHelp } from "../../request/components/withdrawalHelp/WithdrawalHelp";
import { DisclaimerIskToSavings } from "../components/disclaimer/DisclaimerIskToSavings";

interface Props {
  next: () => void;
}

const messages = defineMessages({
  lysaSavingsAccountLabel: {
    id: "withdrawal.isk-to-savings-story.savings-account-selection.savingsAccount.label",
  },
  required: {
    id: "withdrawal.isk-to-savings-story.savings-account-selection.no-option-selected",
  },
});

export function SelectSavingsAccount({ next }: Props) {
  const intl = useIntl();
  const { accounts } = useAccounts();
  const formRef = useRef<LysaFormRef>();
  const history = useHistory();
  const {
    state: internalWithdrawalContext,
    setState: setInternalWithdrawalContext,
  } = useWithdrawalIskToSavingsContext();

  const selectedLysaAccount = internalWithdrawalContext.selectedLysaAccount;
  const selectedSavingsAccount =
    internalWithdrawalContext.selectedSavingsAccount;

  useEffect(() => {
    if (selectedLysaAccount) {
      return;
    } else {
      history.push(getNavLink(WITHDRAWAL_ISK_TO_SAVINGS_PAGE));
    }
  }, [history, selectedLysaAccount]);

  if (!accounts) {
    return <Spinner />;
  }

  return (
    <div className="withdrawal-story-internal-request">
      <Typography type="h2">
        <TranslatedText id="withdrawal.isk-to-savings-story.savings-account-selection.header" />
      </Typography>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (formRef.current?.isInvalid) {
            return;
          }
          next();
        }}
      >
        <div className="witdrawal-story-internal-request__savings-account-option">
          <Fragment>
            <RadioGroupCard
              legend={intl.formatMessage(messages.lysaSavingsAccountLabel)}
              alternatives={accounts.savingsAccounts.map((savingsAccount) => {
                return {
                  header: savingsAccount.name,
                  description: (
                    <Typography type="label" component="span">
                      <Amount
                        amount={savingsAccount.totalBalance}
                        decimals={0}
                      />
                    </Typography>
                  ),
                  value: {
                    text: savingsAccount.name,
                    value: savingsAccount.accountId,
                  },
                  logo: <AccountTypeLogo account={savingsAccount} />,
                };
              })}
              onChange={(alt) => {
                const selectedSavingsAccountTemp =
                  accounts.savingsAccounts.find(
                    (savingsAccount) => savingsAccount.accountId === alt.value
                  );
                if (selectedSavingsAccountTemp) {
                  setInternalWithdrawalContext({
                    selectedSavingsAccount: selectedSavingsAccountTemp,
                  });
                }
              }}
              value={
                selectedSavingsAccount && {
                  text: selectedSavingsAccount.name,
                  value: selectedSavingsAccount.accountId,
                }
              }
              validators={[
                new RequiredValidator(intl.formatMessage(messages.required)),
              ]}
            />
          </Fragment>
        </div>

        <Button
          type="submit"
          block
          label={
            <TranslatedText id="withdrawal.isk-to-savings-story.savings-account-selection.button" />
          }
        />
      </Form>
      <HorizontalDivider />
      <WithdrawalHelp />
      <DisclaimerIskToSavings />
    </div>
  );
}
