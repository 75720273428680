import { Fragment, useContext } from "react";
import { Page, PageHeader } from "../Page";
import { Card, Spinner, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { Marketing } from "./marketing/Marketing";
import { dataLegalEntity } from "../../data/dataLegalEntity";
import { UserContext } from "../../context/UserContext";
import { ReadOnlyMarketing } from "./readOnlyMarketing/ReadOnlyMarketing";
import { GridRow } from "../../components/grid/gridRow/GridRow";
import { GridCol } from "../../components/grid/gridCol/GridCol";
import { TranslatedText } from "../../components/TranslatedText";
import { ProfileLinks } from "./profileLinks/ProfileLinks";
import { ChangePrimaryLogin } from "./changePrimaryLogin/ChangePrimaryLogin";
import { LanguageSelection } from "./languageSelection/LanguageSelection";
import { useQuery } from "@tanstack/react-query";

export const SETTINGS_PAGE_URL = "/settings";

export const SettingsPage = () => {
  const userContext = useContext(UserContext);
  const user = userContext.state;

  const {
    data: settings,
    isError,
    isPending,
  } = useQuery({
    queryKey: ["profileSettings"],
    queryFn: dataLegalEntity.getProfileSettings,
    staleTime: 1000 * 60 * 5,
  });

  if (isPending) return <Spinner />;
  return (
    <Page>
      <PageHeader>
        <h1>
          <TranslatedText id="settings.header" />
        </h1>
      </PageHeader>
      <GridRow>
        <GridCol xsmall={12} medium={6}>
          {isError && (
            <Fragment>
              <h2>
                <TranslatedText id="settings.marketing.header" />
              </h2>
              <Card>
                <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                  <TranslatedText id="settings.error" />
                </Snackbar>
              </Card>
            </Fragment>
          )}
          {settings && (
            <Fragment>
              {user.readOnly ? (
                <ReadOnlyMarketing
                  commercialEmail={settings.contactDetails.commercialEmail}
                  notificationEmail={settings.contactDetails.notificationEmail}
                />
              ) : (
                <Marketing
                  marketingSettings={settings.contactDetails}
                  error={isError}
                />
              )}
            </Fragment>
          )}
        </GridCol>
        <GridCol xsmall={12} medium={6}>
          <ProfileLinks />
          {user.multipleRights && <ChangePrimaryLogin />}
        </GridCol>
        <GridCol xsmall={12} medium={6}>
          <LanguageSelection />
        </GridCol>
      </GridRow>
    </Page>
  );
};
