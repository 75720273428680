import {
  useCallback,
  useContext,
  useEffect,
  useState,
  VoidFunctionComponent,
} from "react";
import { FlashContext, SNACKBAR_TYPES, Spinner } from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { useCreateKFAccount } from "../KFAccountContext";
import { useLocalizationContext } from "../../../../../../context/LocalizationContext";
import {
  InvestmentAccountQuestions,
  AdviceResponse,
  dataInvestments,
  getAccountQuestions,
  GetSuitabilityAssessmentRequest,
  isValidGetSuitabilityAssessmentRequest,
} from "../../../../../../data/dataInvestments";
import {
  Advise,
  AdviceState as AdviseState,
} from "../../../../../../pageComponents/advise/Advise";
import { AccountType } from "../../../../../../data/dataAccounts";

const messages = defineMessages({
  retry: {
    id: "advice.wrapper.retry",
  },
  assessmentErrorFlash: {
    id: "advice.wrapper.assessment.error.flash",
  },
});

interface Props {
  next: () => void;
  navigateToEditAllocation: () => void;
  navigateToFees: () => void;
}

export const AdviseWrapper: VoidFunctionComponent<Props> = ({
  next,
  navigateToEditAllocation,
  navigateToFees,
}) => {
  const intl = useIntl();
  const [KFAccount, setKFAccount] = useCreateKFAccount();
  const localization = useLocalizationContext();
  const pushFlash = useContext(FlashContext).pushFlash;
  const [loadingState, setLoadingState] = useState(AdviseState.INITIAL);
  const [advise, setAdvise] = useState<AdviceResponse>();
  const [retryMsg, setRetryMsg] = useState<string>();

  useEffect(() => {
    if (loadingState !== AdviseState.INITIAL) {
      return;
    }

    const data: Partial<GetSuitabilityAssessmentRequest> = {
      language: localization.state.language,
      ...getAccountQuestions(KFAccount),
    };

    if (!isValidGetSuitabilityAssessmentRequest(data)) {
      throw new Error("AdviceWrapper - Missing data object");
    }

    setLoadingState(AdviseState.LOADING);
    dataInvestments
      .getNewAccountSuitability(data)
      .then((advise) => {
        setKFAccount({
          advicedRisk: advise.advisedRisk,
          investmentType: advise.investmentType,
          declaration: advise.declaration,
        });
        setAdvise(advise);
        setLoadingState(AdviseState.DONE);
      })
      .catch((error) => {
        setLoadingState(AdviseState.ERROR);
        //     // We don't get a status when the network is down
        if (!error.status || error.status >= 500) {
          setRetryMsg(intl.formatMessage(messages.retry));
        } else {
          pushFlash({
            type: SNACKBAR_TYPES.ERROR,
            text: intl.formatMessage(messages.assessmentErrorFlash),
          });
          return;
        }
      });
  }, [
    KFAccount,
    intl,
    loadingState,
    localization.state.language,
    pushFlash,
    setKFAccount,
  ]);

  const onRetry = useCallback(() => {
    setRetryMsg(undefined);
    setLoadingState(AdviseState.INITIAL);
  }, []);

  const onNext = useCallback(() => {
    setKFAccount({
      takenRisk: KFAccount.advicedRisk,
    });
    next();
  }, [KFAccount.advicedRisk, next, setKFAccount]);

  if (!KFAccount.advicedRisk || !KFAccount.investmentType) {
    return <Spinner />;
  }

  let accountQuestions: Partial<InvestmentAccountQuestions> | undefined;

  try {
    accountQuestions = getAccountQuestions(KFAccount);
  } catch {}

  if (!accountQuestions) {
    return null;
  }

  return (
    <Advise
      advise={advise}
      retryMsg={retryMsg}
      investmentType={KFAccount.investmentType}
      retry={onRetry}
      next={onNext}
      navigateToEditAllocation={navigateToEditAllocation}
      navigateToFees={navigateToFees}
      accountQuestions={accountQuestions}
      accountType={AccountType.KF_SWE}
    />
  );
};
