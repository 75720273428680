import { Button } from "@lysaab/ui-2";
import { useState } from "react";
import * as React from "react";
import { TranslatedText } from "../../../../../../../components/TranslatedText";
import { Isin } from "../../../../../../../data/dataFunds";
import { SubVapPartResponse } from "../../../../../../../data/dataTax";
import { SubVapModal } from "./components/SubVapModal";

export const SubVap: React.VFC<{
  subVapPart: SubVapPartResponse[];
  isin: Isin;
}> = ({ subVapPart, isin }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <Button
        size="small"
        inline
        onClick={() => {
          setShowModal(true);
        }}
        label={<TranslatedText id="germany.tax.details.subVap.link" />}
        variant="outlined"
        icon="Search"
      />
      <SubVapModal
        isin={isin}
        showModal={showModal}
        setShowModal={setShowModal}
        subVapPart={subVapPart}
      />
    </div>
  );
};
