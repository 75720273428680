import { ReactNode } from "react";
import * as React from "react";
import cx from "classnames";

import "./TransparentButton.scss";

export const TransparentButton = ({
  onClick,
  children,
  className,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
  className?: string;
}) => {
  return (
    <button
      type="button"
      className={cx(className, "transparent-button-component")}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
