import { Alternative, API, encode, ServerError } from "@lysaab/ui-2";
import {
  ValidationResponse,
  VALIDATION_STATE,
} from "@lysaab/ui-2/hooks/useValidation";
import { BaseValidator } from "@lysaab/ui-2/validators/BaseValidator";
import { ExternalAccountNumber } from "../../../../../../data/dataDirectDebit";

const BAD_REQUEST_CODE = 400;

export class UnconfirmedExternalAccountValidator extends BaseValidator {
  protected name = "UnconfirmedExternalAccountValidator";
  private notAllowedErrorMessage;

  constructor(ibanErrorMessage: string, notAllowedErrorMessage: string) {
    super(ibanErrorMessage);
    this.notAllowedErrorMessage = notAllowedErrorMessage;
  }

  getId() {
    return this.getBaseId() + this.notAllowedErrorMessage;
  }

  validate(
    externalAccount: Alternative<ExternalAccountNumber> | string
  ): Promise<ValidationResponse> {
    /**
     * The requiredValidator and minLengthValidator should
     * stops from proceeding here
     */
    if (
      typeof externalAccount === "undefined" ||
      (typeof externalAccount === "string" && externalAccount.length < 5)
    ) {
      return Promise.resolve<ValidationResponse>({
        status: VALIDATION_STATE.SUCCESS,
        hideError: true,
      });
    }
    return new Promise<ValidationResponse>((resolve, reject) => {
      API.get<{ valid: boolean }>(
        encode`/direct-debit/monthly-deposit/accounts/valid?externalAccountNumber=${
          typeof externalAccount === "string"
            ? externalAccount
            : externalAccount.value
        }`,
        true
      )
        .then((response) => {
          if (response.valid) {
            resolve({ status: VALIDATION_STATE.SUCCESS });
          } else {
            resolve({
              status: VALIDATION_STATE.FAILED,
              message: this.notAllowedErrorMessage,
            });
          }
        })
        .catch((error: ServerError<unknown>) => {
          if (error.status === BAD_REQUEST_CODE) {
            resolve({
              status: VALIDATION_STATE.FAILED,
              message: this.error,
            });
          } else {
            reject(error);
          }
        });
    });
  }
}
