import { Button, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import {
  isShareAccountSentInvitation,
  ShareAccountInvitation,
  ShareAccountSentInvitation,
} from "../../../data/dataAttorney";
import { ListItemAccountName } from "../listItems/ListItemAccountName";
import { ListItemCancel } from "../listItems/ListItemCancel";
import { ListItemExpiry } from "../listItems/ListItemExpiry";
import "./AcceptedOwnerState.scss";

interface Props {
  invitation: ShareAccountInvitation | ShareAccountSentInvitation;
  onCancel: () => void;
  onAccept: () => void;
}

export const AcceptedInviteeState: React.VFC<Props> = ({
  invitation,
  onCancel,
  onAccept,
}) => {
  if (!isShareAccountSentInvitation(invitation)) {
    return null;
  }

  return (
    <div>
      <Snackbar type={SNACKBAR_TYPES.INFO}>
        <FormattedMessage
          id="shareAccountStory.inviteCard.inviteeAcceptedText"
          values={{
            inviteeName: invitation.inviteeName,
          }}
        />
      </Snackbar>
      <hr />
      <div>
        <ListItemAccountName accountName={invitation.accountName} />
        <div className="list-item">
          <strong>
            <FormattedMessage id="shareAccountInviteCard.inviteeNameLabel" />
          </strong>
          <div>{invitation.inviteeName}</div>
        </div>
        <div className="list-item">
          <strong>
            <FormattedMessage id="shareAccountInviteCard.inviteeDateOfBirthLabel" />
          </strong>
          <div>{invitation.inviteeDoB}</div>
        </div>
        {invitation.expiresAt && (
          <ListItemExpiry expiryDate={invitation.expiresAt} />
        )}
        <ListItemCancel onCancel={onCancel} />
        <hr />
        <Button
          block
          onClick={onAccept}
          label={<FormattedMessage id="shareAccountInviteCard.acceptButton" />}
        />
      </div>
    </div>
  );
};
