import { FunctionComponent } from "react";
import * as React from "react";
import {
  NewIcon,
  RadioGroupCard,
  RequiredValidator,
  Typography,
} from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { SustainabilityImportance } from "../../data/dataInvestments";
import { ExpandableContent } from "../../components/expandableContent/ExpandableContent";
import { useIsPerson } from "../../hooks/useIsPerson";

interface MessageWithId {
  id: string;
}

const messages = defineMessages({
  header: { id: "esg.sustainability.header" },
  headerCorp: { id: "esg.sustainability.corp.header" },
  required: { id: "esg.sustainability.required" },
});

/** Title **/
export const sustainabilityAltMessages = defineMessages<
  SustainabilityImportance,
  MessageWithId,
  Record<SustainabilityImportance, MessageWithId>
>({
  [SustainabilityImportance.NOT_IMPORTANT]: {
    id: "esg.sustainability.alt.NOT_IMPORTANT.header",
  },
  [SustainabilityImportance.IMPORTANT]: {
    id: "esg.sustainability.alt.IMPORTANT.header",
  },
});

export const sustainabilityAltCorpMessages = defineMessages<
  SustainabilityImportance,
  MessageWithId,
  Record<SustainabilityImportance, MessageWithId>
>({
  [SustainabilityImportance.NOT_IMPORTANT]: {
    id: "esg.sustainability.alt.NOT_IMPORTANT.header",
  },
  [SustainabilityImportance.IMPORTANT]: {
    id: "esg.sustainability.alt.IMPORTANT.header",
  },
});

/** Description **/
const alternativeDescriptionMessages = defineMessages<
  SustainabilityImportance,
  MessageWithId,
  Record<SustainabilityImportance, MessageWithId>
>({
  [SustainabilityImportance.NOT_IMPORTANT]: {
    id: "esg.sustainability.alt.NOT_IMPORTANT.description",
  },
  [SustainabilityImportance.IMPORTANT]: {
    id: "esg.sustainability.alt.IMPORTANT.description",
  },
});

const alternativeDescriptionCorpMessages = defineMessages<
  SustainabilityImportance,
  MessageWithId,
  Record<SustainabilityImportance, MessageWithId>
>({
  [SustainabilityImportance.NOT_IMPORTANT]: {
    id: "esg.sustainability.corp.alt.NOT_IMPORTANT.description",
  },
  [SustainabilityImportance.IMPORTANT]: {
    id: "esg.sustainability.corp.alt.IMPORTANT.description",
  },
});

/** Expanded description **/
const alternativeExpandDescriptionNotImportantMessage = defineMessages({
  item1: {
    id: "esg.sustainability.alt.NOT_IMPORTANT.expandDescription.item1",
  },
});

const alternativeExpandDescriptionImportantMessage = defineMessages({
  item1: {
    id: "esg.sustainability.alt.IMPORTANT.expandDescription.item1",
  },
  item2: {
    id: "esg.sustainability.alt.IMPORTANT.expandDescription.item2",
  },
});

const alternativeExpandDescriptionMessages = {
  [SustainabilityImportance.NOT_IMPORTANT]:
    alternativeExpandDescriptionNotImportantMessage,
  [SustainabilityImportance.IMPORTANT]:
    alternativeExpandDescriptionImportantMessage,
};

const alternativeExpandDescriptionCorpNotImportantMessage = defineMessages({
  item1: {
    id: "esg.sustainability.corp.alt.NOT_IMPORTANT.expandDescription.item1",
  },
});

const alternativeExpandDescriptionCorpImportantMessage = defineMessages({
  item1: {
    id: "esg.sustainability.corp.alt.IMPORTANT.expandDescription.item1",
  },
  item2: {
    id: "esg.sustainability.corp.alt.IMPORTANT.expandDescription.item2",
  },
});

const alternativeExpandDescriptionCorpMessages = {
  [SustainabilityImportance.NOT_IMPORTANT]:
    alternativeExpandDescriptionCorpNotImportantMessage,
  [SustainabilityImportance.IMPORTANT]:
    alternativeExpandDescriptionCorpImportantMessage,
};

/** Icon **/
const alternativeIcon: Record<SustainabilityImportance, React.ReactElement> = {
  [SustainabilityImportance.NOT_IMPORTANT]: <NewIcon.Earth />,
  [SustainabilityImportance.IMPORTANT]: <NewIcon.Sustainable />,
};

interface Props {
  sustainability?: SustainabilityImportance;
  setSustainability: (sustainability: SustainabilityImportance) => void;
}

export const SustainabilityQuestion: FunctionComponent<Props> = ({
  sustainability,
  setSustainability,
}) => {
  const intl = useIntl();
  const isPerson = useIsPerson();

  const alternatives = Object.values(SustainabilityImportance).map((key) => {
    return {
      header: intl.formatMessage(
        (isPerson ? sustainabilityAltMessages : sustainabilityAltCorpMessages)[
          key
        ]
      ),
      description: (
        <Typography type="body-small" component="span">
          {intl.formatMessage(
            (isPerson
              ? alternativeDescriptionMessages
              : alternativeDescriptionCorpMessages)[key]
          )}
        </Typography>
      ),
      expandableContent: (
        <ExpandableContent>
          <ul>
            {Object.values(
              (isPerson
                ? alternativeExpandDescriptionMessages
                : alternativeExpandDescriptionCorpMessages)[key]
            ).map((item, index) => {
              return (
                <li key={`selection-expandable-content-${index}`}>
                  {intl.formatMessage(item)}
                </li>
              );
            })}
          </ul>
        </ExpandableContent>
      ),
      logo: alternativeIcon[key],
      value: key,
    };
  });

  return (
    <RadioGroupCard
      legend={
        <h2>
          {isPerson
            ? intl.formatMessage(messages.header)
            : intl.formatMessage(messages.headerCorp)}
        </h2>
      }
      alternatives={alternatives}
      value={sustainability}
      onChange={setSustainability}
      validators={[
        new RequiredValidator(intl.formatMessage(messages.required)),
      ]}
    />
  );
};
