import { Checkbox, RequiredValidator } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { useCreateKFAccount } from "../../KFAccountContext";

export const UnderstoodFee = () => {
  const intl = useIntl();
  const [KFAccount, setKFAccount] = useCreateKFAccount();

  return (
    <Checkbox
      alternative={{
        text: intl.formatMessage({
          id: "sweden.kf.intro.fee-info.label",
        }),
        value: true,
      }}
      checked={!!KFAccount.understoodFee}
      onChange={(value) =>
        setKFAccount({
          understoodFee: !KFAccount.understoodFee,
        })
      }
      validators={[
        new RequiredValidator(
          intl.formatMessage({
            id: "sweden.kf.intro.fee-info.required",
          })
        ),
      ]}
    />
  );
};
