import { FunctionComponent, useEffect, useState } from "react";

import {
  Button,
  ServerError,
  Snackbar,
  SNACKBAR_TYPES,
  Typography,
} from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import {
  dataLifePensionMove,
  ScrapedPensionData,
} from "../../../../../data/dataLifePensionMove";
import { MoveCard } from "../components/moveCard/MoveCard";
import { TranslatedText } from "../../../../../components/TranslatedText";

import "./NotMovable.scss";

export const NotMovable: FunctionComponent = () => {
  const [notMovable, setNotMovable] = useState<Array<ScrapedPensionData>>();
  const [noPensionFound, setNoPensionFound] = useState<Array<string>>();
  const [apiError, setApiError] = useState<Error | null>(null);
  const intl = useIntl();
  const history = useHistory();

  useEffect(() => {
    dataLifePensionMove
      .getPensionData()
      .then((pensionData) => {
        const notMovablePensionData = pensionData.movability.NOT_MOVABLE;
        const noPensionFoundPensionData = pensionData.noPensionsFound
          ? Object.keys(pensionData.noPensionsFound)
          : [];
        setNotMovable(notMovablePensionData);
        setNoPensionFound(noPensionFoundPensionData);
      })
      .catch((error) => {
        if (error instanceof ServerError) {
          setApiError(error);
        } else {
          // Unknown error, rethrow
          throw error;
        }
      });
  }, []);

  return (
    <article className="transfer-pension-not-movable">
      <Typography type="h2" className="header">
        <TranslatedText id="sweden.transfer-pension.not-movable.header" />
      </Typography>
      <Typography type="body">
        <TranslatedText id="sweden.transfer-pension.not-movable.body" />
      </Typography>
      {apiError && (
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon textAlign="left">
          <TranslatedText
            id="sweden.transfer-pension.not-movable.error"
            values={{ br: <br /> }}
          />
        </Snackbar>
      )}
      {notMovable && (
        <section className="move-cards">
          {notMovable.map((notMovableMove) => {
            return (
              <MoveCard
                key={notMovableMove.id}
                moveAccountType={notMovableMove.pensionType}
                insuranceCompany={notMovableMove.insuranceCompany}
                insuranceNumber={notMovableMove.insuranceNumber}
                insuranceHolderName={notMovableMove?.insuranceHolderName}
                currentWorth={notMovableMove.currentWorth}
                information={notMovableMove.information}
              />
            );
          })}
        </section>
      )}
      {noPensionFound && noPensionFound.length > 0 && (
        <>
          <Typography type="h3">
            <TranslatedText id="sweden.transfer-pension.not-movable.no-pension-found.header" />
          </Typography>
          <section className="move-cards">
            {noPensionFound.map((insuranceCompany) => {
              return (
                <MoveCard
                  key={insuranceCompany}
                  insuranceCompany={insuranceCompany}
                />
              );
            })}
          </section>
        </>
      )}
      <Button
        block
        className="button"
        variant="primary"
        type="button"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.not-movable.back-button",
        })}
        onClick={history.goBack}
      />
    </article>
  );
};
