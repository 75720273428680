import { useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { getFundName } from "../../../../components/fundName/FundName";
import { LysaTable } from "../../../../components/lysaTable/LysaTable";
import { LysaTableTextCell } from "../../../../components/lysaTableTextCell/LysaTableTextCell";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import { FiTaxHolding } from "../../../../data/dataTax";

const messages = defineMessages({
  isin: {
    id: "finland.tax.holdings.isin",
  },
  shares: {
    id: "finland.tax.holdings.shares",
  },
  comp: {
    id: "finland.tax.holdings.comp",
  },
  compClarification: {
    id: "finland.tax.holdings.compClarification",
  },
  noEntries: {
    id: "finland.tax.holdings.noEntries",
  },
  worth: {
    id: "finland.tax.holdings.worth",
  },
  navPrice: {
    id: "finland.tax.holdings.navPrice",
  },
  fundName: {
    id: "finland.tax.holdings.fundName",
  },
});

interface Props {
  holdings?: FiTaxHolding[];
}

export const HoldingsTable: React.VFC<Props> = ({ holdings }) => {
  const localizationContext = useContext(LocalizationContext);
  const intl = useIntl();

  if (typeof holdings === "undefined") {
    return null;
  }

  return (
    <LysaTable>
      <thead>
        <tr>
          <LysaTableTextCell
            className="text-left"
            value={intl.formatMessage(messages.fundName)}
            header
          />
          <LysaTableTextCell
            className="text-left"
            value={intl.formatMessage(messages.isin)}
            header
          />
          <LysaTableTextCell
            className="text-right"
            value={intl.formatMessage(messages.shares)}
            header
          />
          <LysaTableTextCell
            className="text-right"
            value={intl.formatMessage(messages.navPrice)}
            header
          />
          <LysaTableTextCell header>
            <div className="double-table-row text-right">
              <span>{intl.formatMessage(messages.comp)}</span>
              <span>{intl.formatMessage(messages.compClarification)}</span>
            </div>
          </LysaTableTextCell>
          <LysaTableTextCell
            className="text-right"
            value={intl.formatMessage(messages.worth)}
            header
          />
        </tr>
      </thead>
      <tbody>
        {holdings.map((holding) => (
          <tr>
            <LysaTableTextCell
              className="text-left"
              label={intl.formatMessage(messages.fundName)}
              value={getFundName(holding.isin, intl)}
            />
            <LysaTableTextCell
              className="text-left"
              label={intl.formatMessage(messages.isin)}
              value={holding.isin}
            />
            <LysaTableTextCell
              className="text-right"
              label={intl.formatMessage(messages.shares)}
              value={intl.formatNumber(holding.shares)}
            />
            <LysaTableTextCell
              className="text-right"
              label={intl.formatMessage(messages.navPrice)}
              value={intl.formatNumber(holding.navPrice, {
                currency: localizationContext.state.currency,
                style: "currency",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            />
            <LysaTableTextCell
              className="text-right"
              label={intl.formatMessage(messages.comp)}
              value={intl.formatNumber(holding.worth70pct, {
                currency: localizationContext.state.currency,
                style: "currency",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            />
            <LysaTableTextCell
              className="text-right"
              label={intl.formatMessage(messages.worth)}
              value={intl.formatNumber(holding.worth, {
                currency: localizationContext.state.currency,
                style: "currency",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            />
          </tr>
        ))}

        {holdings.length === 0 && (
          <tr>
            <td colSpan={6} className="text-center">
              {intl.formatMessage(messages.noEntries)}
            </td>
          </tr>
        )}
      </tbody>
    </LysaTable>
  );
};
