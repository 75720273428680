import { useCallback, useEffect, useMemo, useState } from "react";
import { Datepicker, MaxDateValidator, MinDateValidator } from "@lysaab/ui-2";
import { DateTime } from "luxon";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { GridCol } from "../../../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../../../components/grid/gridRow/GridRow";
import { TranslatedText } from "../../../../../components/TranslatedText";
import {
  InvestmentAccount,
  InvestmentAccountId,
  dataAccounts,
} from "../../../../../data/dataAccounts";
import { ClosedAccount, dataProfile } from "../../../../../data/dataProfile";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { Page, PageHeader } from "../../../../../pages/Page";
import { AccountList } from "./accountList/AccountList";
import { useAccountParams } from "./UseAccountParams";
import { AccountingInformation } from "./AccountingInformation";
import "./AccountingPage.scss";

const messages = defineMessages({
  activeAccounts: {
    id: "sweden.accountingPage.accounts.active",
  },
  closedAccounts: {
    id: "sweden.accountingPage.accounts.closed",
  },
  fromDate: {
    id: "sweden.accountingPage.date.from",
  },
  fromDateError: {
    id: "sweden.accountingPage.date.from.error.min",
  },
  toDate: {
    id: "sweden.accountingPage.date.to",
  },
  toDateErrorMin: {
    id: "sweden.accountingPage.date.to.error.min",
  },
  toDateErrorMax: {
    id: "sweden.accountingPage.date.to.error.max",
  },
});

export const ACCOUNTING_PAGE_URL = "/accounting";

export const AccountingPage = () => {
  const intl = useIntl();
  const history = useHistory();

  const [accounts, setaccounts] = useState<InvestmentAccount[]>([]);
  const [closedAccounts, setClosedAccounts] = useState<ClosedAccount[]>([]);

  const [fromDate, toDate, accountId] = useAccountParams();
  const allAccounts = useMemo(
    () => [...accounts, ...closedAccounts],
    [accounts, closedAccounts]
  );
  const account = allAccounts.find(
    (account) => account.accountId === accountId
  );

  const getAccountName = useCallback(
    (accountId: InvestmentAccountId) => {
      const _allAccounts = ([] as (InvestmentAccount | ClosedAccount)[])
        .concat(accounts)
        .concat(closedAccounts);
      const account = _allAccounts.find(
        (account) => account.accountId === accountId
      );
      if (account) {
        return account.name;
      }
      return accountId;
    },
    [accounts, closedAccounts]
  );

  useEffect(() => {
    dataAccounts.getAccounts().then(setaccounts);
    dataProfile.getClosedAccounts().then(setClosedAccounts);
  }, []);

  useEffect(() => {
    if (!accountId && allAccounts.length) {
      history.replace({
        pathname: getNavLink(ACCOUNTING_PAGE_URL),
        search: stringifyParams(fromDate, toDate, allAccounts[0].accountId),
      });
    }
  }, [
    accountId,
    accounts,
    allAccounts,
    closedAccounts,
    fromDate,
    history,
    toDate,
  ]);

  const oldestDate =
    accounts && accounts.length
      ? DateTime.fromISO(
          accounts
            .map((account) => account.created)
            .sort((a, b) =>
              DateTime.fromISO(a).diff(DateTime.fromISO(b)).toMillis()
            )[0]
        )
          .set({ month: 1, day: 1 })
          .toJSDate()
      : undefined;

  return (
    <Page className="accounting-page">
      <PageHeader className="no-print">
        <h1>
          <TranslatedText id="sweden.accountingPage.header" />
        </h1>
      </PageHeader>

      <GridRow>
        <GridCol xsmall={12} large={3} noPrint>
          <GridRow>
            <GridCol xsmall={12} large={8}>
              <Datepicker
                label={intl.formatMessage(messages.fromDate)}
                selected={fromDate}
                onChange={(date: Date | null) => {
                  /* date is null when input is empty */
                  if (date === null) {
                    return;
                  }
                  history.replace({
                    pathname: getNavLink(ACCOUNTING_PAGE_URL),
                    search: stringifyParams(date, toDate, accountId),
                  });
                }}
                validators={[
                  new MaxDateValidator(
                    toDate,
                    intl.formatMessage(messages.fromDateError)
                  ),
                ]}
                minDate={oldestDate}
                maxDate={toDate}
              />
            </GridCol>

            <GridCol xsmall={12} large={8} print={12}>
              <Datepicker
                label={intl.formatMessage(messages.toDate)}
                selected={toDate}
                onChange={(date: Date | null) => {
                  /* date is null when input is empty */
                  if (date === null) {
                    return;
                  }
                  history.replace({
                    pathname: getNavLink(ACCOUNTING_PAGE_URL),
                    search: stringifyParams(fromDate, date, accountId),
                  });
                }}
                validators={[
                  new MinDateValidator(
                    fromDate,
                    intl.formatMessage(messages.toDateErrorMin)
                  ),
                  new MaxDateValidator(
                    DateTime.now().minus({ days: 1 }).toJSDate(),
                    intl.formatMessage(messages.toDateErrorMax)
                  ),
                ]}
                minDate={fromDate}
                maxDate={DateTime.now().minus({ days: 1 }).toJSDate()}
              />
            </GridCol>

            <GridCol xsmall={12} noPrint>
              <AccountList
                title={intl.formatMessage(messages.activeAccounts)}
                accounts={accounts}
                fromDate={fromDate}
                toDate={toDate}
              />

              <AccountList
                title={intl.formatMessage(messages.closedAccounts)}
                accounts={closedAccounts}
                fromDate={fromDate}
                toDate={toDate}
              />
            </GridCol>
          </GridRow>
        </GridCol>

        <GridCol xsmall={12} large={9} print={12} className="company">
          <AccountingInformation
            account={account}
            getAccountName={getAccountName}
          />
        </GridCol>
      </GridRow>
    </Page>
  );
};

export const stringifyParams = (
  fromDate: Date,
  toDate: Date,
  accountId: string
) => {
  const params = new URLSearchParams();

  params.set("fromDate", DateTime.fromJSDate(fromDate).toISODate());
  params.set("toDate", DateTime.fromJSDate(toDate).toISODate());
  if (accountId) {
    params.set("accountId", accountId);
  }
  return params.toString();
};
