import { VoidFunctionComponent, useEffect, useState } from "react";

import { useIntl } from "react-intl";
import {
  DocumentInformation,
  dataDocuments,
  getLatestDocuments,
} from "../../../../../../data/dataDocuments";
import { DocModal, DocModalLink } from "@lysaab/ui-2";
import { useUser } from "../../../../../../context/UserContext";

import "./InsurelyDoc.scss";

const documentList = [["legal/se/en/LysaLife-Insurelyvillkor-out.md"]];

const getLatestVersionCommon = (information: DocumentInformation[]) =>
  getLatestDocuments(information, ...documentList);

export const InsurelyDoc: VoidFunctionComponent = () => {
  const intl = useIntl();
  const user = useUser();
  const [showDoc, setShowDoc] = useState<string>();
  const [documentList, setDocumentList] = useState<DocumentInformation[]>();

  useEffect(() => {
    // Returns a array with 125 items. Each item is an object with 5 properties, keys are: country, created, fileName, land, title, type
    dataDocuments.getDocumentsInformation(true).then(setDocumentList);
  }, []);

  if (!documentList) {
    return null;
  }

  const latestVersions = getLatestVersionCommon(documentList);

  const latestVersionsWithMetaData = latestVersions.map((document) => {
    const match = documentList.find((item) => item.fileName === document);
    return (
      match || {
        fileName: document,
        title: document.replace(".md", ""),
      }
    );
  });

  const variableValues: Record<string, string> = {
    SIGNING_DATE: intl.formatDate(new Date()),
    CUSTOMER_NAME: user.name ?? "",
    CUSTOMER_ID: user.tin ?? "",
  };

  return (
    <section className="transfer-pension-docs">
      <DocModal
        onRequestClose={() => setShowDoc(undefined)}
        active={!!showDoc}
        modalAnnouncement={intl.formatMessage({
          id: "sweden.transfer-pension.insurelyDoc.modal-announcement",
        })}
        selectPlaceholder={intl.formatMessage({
          id: "sweden.transfer-pension.insurelyDoc.select-placeholder",
        })}
        docList={latestVersions}
        variableValues={variableValues}
        header={intl.formatMessage({
          id: "sweden.transfer-pension.insurelyDoc.header",
        })}
        docName={showDoc}
      />
      <div className="link-container">
        {latestVersionsWithMetaData.map((document) => (
          <DocModalLink
            key={document.fileName}
            className="link"
            document={document?.fileName}
            modalAnnouncement={document?.title}
            variableValues={variableValues}
          >
            {document?.title}
          </DocModalLink>
        ))}
      </div>
    </section>
  );
};
