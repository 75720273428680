import { NewIcon as Icon } from "@lysaab/ui-2";
import { AvailableBank } from "../../../data/dataKlarna";
import { BankIcon } from "../../bankIcon/BankIcon";
import "./BankListItem.scss";

interface Props {
  bank: AvailableBank;
  onComplete: (bank: AvailableBank) => void;
}

export const BankListItem: React.VFC<Props> = ({ bank, onComplete }) => {
  return (
    <button
      type="button"
      className="transparent-button card-list-item"
      onClick={() => onComplete(bank)}
    >
      <BankIcon bank={bank.name} />
      <div className="card-list-item__label">
        {bank.displayName}
        <Icon.ChevronRight />
      </div>
    </button>
  );
};
