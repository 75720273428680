import { Button, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { useContext } from "react";
import * as React from "react";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { WithdrawalItem } from "../../../../../../components/withdrawalItem/WithdrawalItem";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";

import { KfWithdrawalContext, WithdrawalType } from "./KfWithdrawalContext";
import { PartialWithdrawalItem } from "./SigningStates/PartialWithdrawalItem";
import { WITHDRAWAL_PAGE_URL } from "../../../../../../pages/withdrawal/overview/WithdrawalPage";

export const Ongoing: React.FunctionComponent = () => {
  const { state: kfWithdrawalState } = useContext(KfWithdrawalContext);
  const { account, from, to, signingStatus } = kfWithdrawalState;

  if (!signingStatus) {
    return null;
  }

  return (
    <div className="withdrawal-request-page-done">
      <h1>
        <TranslatedText
          id="withdrawalRequest.kf.signing.ongoing.header"
          defaultMessage="Ongoing withdrawal"
          description="Withdrawal request kf - ongoing header"
        />
      </h1>
      <Snackbar type={SNACKBAR_TYPES.SUCCESS} icon>
        <div>
          {kfWithdrawalState.withdrawalType?.value === WithdrawalType.DRAIN ? (
            <TranslatedText
              id="withdrawalRequest.kf.signing.ongoing.emptying"
              defaultMessage="We have received your withdrawal. We'll sell the required fund shares and terminate yout account. You will have your money within a copule of days."
              description="Withdrawal request kf - ongoing emptying"
            />
          ) : (
            <TranslatedText
              id="withdrawalRequest.kf.signing.ongoing.withdrawal"
              defaultMessage="We have received your withdrawal. We'll sell the required fund shares. You will have your money within a copule of days."
              description="Withdrawal request kf - ongoing withdrawal"
            />
          )}
        </div>
      </Snackbar>

      {account && from && to ? (
        <WithdrawalItem
          moneyOnAccount={account.worth}
          name={account.name}
          externalBank={to.bank}
          externalBankAccount={to.externalBankAccount}
          withdrawalAmount={
            kfWithdrawalState.withdrawalType?.value === WithdrawalType.DRAIN
              ? account.worth.toString()
              : kfWithdrawalState.amount
          }
        />
      ) : (
        <PartialWithdrawalItem
          accountName={signingStatus.withdrawal.accountName}
          initiatorName={signingStatus.withdrawal.initiatorName}
          accountWorth={signingStatus.withdrawal.accountWorth}
          externalBankAccount={signingStatus.withdrawal.externalBankAccount}
          amount={`${signingStatus.withdrawal.amount}`}
          bank={signingStatus.withdrawal.bank}
          date={new Date(signingStatus.withdrawal.requested)}
        />
      )}
      <Button
        variant="secondary"
        component={Link}
        to={getNavLink(WITHDRAWAL_PAGE_URL)}
        label={<TranslatedText id="withdrawalRequest.kf.ongoing.back" />}
        block
      />
    </div>
  );
};
