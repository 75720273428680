import { useVirtualAccountAlternative } from "../../../../components/deposits/depositCard/items/useVirtualAccountAlternative";
import { Recommendation } from "../../../../pages/deposits/overview/Recommendation";

export function DepositsOverviewWrapper() {
  const virtualAccountAlt = useVirtualAccountAlternative();

  return (
    <div className="deposits-overview-wrapper-spain">
      <Recommendation alternatives={[virtualAccountAlt]} />
    </div>
  );
}
