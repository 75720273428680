import { Button, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { useEffect, useState } from "react";
import { generatePath, Link } from "react-router-dom";
import { TranslatedText } from "../../components/TranslatedText";
import {
  dataAttorney,
  ShareAccountInvitationStatus,
  ShareAccountSentInvitation,
} from "../../data/dataAttorney";
import { getNavLink } from "../../hooks/useCountryUrls";
import {
  ShareAccountInvitationsView,
  SHARE_ACCOUNT_INVITATIONS_VIEW_URL,
} from "../shareAccountInvitations/ShareAccountInvitationsPage";
import { SHARE_ACCOUNT_SENT_INVITATION_URL } from "../shareAccountSentInvitation/ShareAccountSentInvitationPage";

export const WaitingSharedAccountInvitations: React.FC = () => {
  const [invitations, setInvitations] = useState<ShareAccountSentInvitation[]>(
    []
  );

  useEffect(() => {
    dataAttorney
      .getSentInvitations()
      .then((response) => {
        const waitingInvitations = response.filter(
          (invitation) =>
            invitation.status === ShareAccountInvitationStatus.ACCEPTED_INVITEE
        );
        setInvitations(waitingInvitations);
      })
      .catch();
  }, []);

  if (invitations.length === 0) {
    return null;
  }

  return (
    <div className="compressed-snackbar">
      <Snackbar type={SNACKBAR_TYPES.INFO}>
        {invitations.length === 1 ? (
          <>
            <p>
              <TranslatedText
                id="waitingSharedAccountInvitation.singleInvite.text"
                values={{
                  inviteeName: invitations[0].inviteeName,
                  accountName: invitations[0].accountName,
                }}
              />
            </p>
            <Button
              component={Link}
              to={{
                pathname: getNavLink(
                  generatePath(SHARE_ACCOUNT_SENT_INVITATION_URL, {
                    invitationId: invitations[0].invitationId,
                  })
                ),
              }}
              size="small"
              label={
                <TranslatedText id="waitingSharedAccountInvitation.singleInvite.button" />
              }
            />
          </>
        ) : (
          <>
            <p>
              <TranslatedText id="waitingSharedAccountInvitation.multipleInvites.text" />
            </p>
            <Button
              component={Link}
              to={getNavLink(
                SHARE_ACCOUNT_INVITATIONS_VIEW_URL(
                  ShareAccountInvitationsView.SENT
                )
              )}
              size="small"
              label={
                <TranslatedText id="waitingSharedAccountInvitation.multipleInvites.button" />
              }
            />
          </>
        )}
      </Snackbar>
    </div>
  );
};
