import {
  Form,
  LysaFormRef,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
  Typography,
} from "@lysaab/ui-2";
import React, {
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { generatePath, Redirect, useLocation } from "react-router";
import {
  InvestmentAccount,
  dataAccounts,
  InvestmentAccountId,
} from "../../../../../../data/dataAccounts";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { useQuery } from "../../../../../../hooks/useQuery";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { Retry, Status } from "../../../../../../components/retry/Retry";
import { KfMigrationContext } from "./KfMigrationContext";
import {
  dataDanica,
  WithdrawalKFAccount,
} from "../../../../../../data/dataDanica";
import { UserContext } from "../../../../../../context/UserContext";
import { LOGIN_SWEDEN_PAGE_URL } from "../../../LoginPage";
import { LysaAccountSelectionCard } from "../../../../../../components/lysaAccountSelectionCard/LysaAccountSelectionCard";
import {
  InvestmentAdviseAccount,
  dataInvestments,
} from "../../../../../../data/dataInvestments";
import { MIN_RECOMMENDED_ALLOCATION } from "../../kf/KFAccountContext";
import { CREATE_ACCOUNT_CORPORATION_PAGE_URL } from "../../kf/CreateAccountCorporationPage";
import { Link } from "react-router-dom";

export interface QueryParams {
  accountId?: InvestmentAccountId;
}

function getError(
  selectedWithdrawalAccount?: WithdrawalKFAccount,
  account?: InvestmentAccount
) {
  if (!selectedWithdrawalAccount || typeof account === "undefined") {
    return null;
  }

  if (account.worth === 0) {
    return (
      <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
        <div>
          <TranslatedText id="withdrawalRequest.from.means" />
        </div>
      </Snackbar>
    );
  }

  if (selectedWithdrawalAccount.pendingWithdrawal) {
    return (
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <div>
          <TranslatedText id="withdrawalRequest.from.withdrawal" />
        </div>
      </Snackbar>
    );
  }

  if (selectedWithdrawalAccount.blockedByRecentWithdrawal) {
    return (
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <div>
          <TranslatedText id="withdrawalRequest.from.error.recent-withdrawal" />
        </div>
      </Snackbar>
    );
  }

  if (selectedWithdrawalAccount.pendingMove) {
    return (
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <div>Denna kapitalförsäkringen är redan på väg att flyttas.</div>
      </Snackbar>
    );
  }

  return null;
}

interface Props {
  next: () => void;
}

export const From: React.FunctionComponent<Props> = ({ next }) => {
  const kfWithdrawalContext = useContext(KfMigrationContext);
  const setKfWithdrawalState = kfWithdrawalContext.setState;
  const query = useQuery<QueryParams>();
  const formRef = React.useRef<LysaFormRef>();
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [accounts, setAccounts] = useState<InvestmentAccount[]>([]);
  const [withdrawalKFAccounts, setWithdrawalKFAccounts] = useState<
    WithdrawalKFAccount[]
  >([]);
  const userContext = useContext(UserContext);
  const user = userContext.state;
  const location = useLocation<WithdrawalKFAccount>();
  const [advices, setAdvices] = useState<InvestmentAdviseAccount[]>();

  const selectedAccount = accounts.find(
    (acc) => acc.accountId === kfWithdrawalContext.state.from?.accountId
  );
  const selectedAccountAdvice = advices?.find(
    (acc) => acc.accountId === kfWithdrawalContext.state.from?.accountId
  );

  /**
   * Don't show accounts with no money. Decision 26 sep 2024, Pelle, Alex, Theo, Mats.
   */
  const availableAccounts = useMemo(
    () =>
      accounts.filter(
        ({ accountId, worth }) =>
          withdrawalKFAccounts.some(
            (account) => account.accountId === accountId
          ) && worth > 0
      ),
    [accounts, withdrawalKFAccounts]
  );

  const load = useCallback(() => {
    Promise.all([
      dataAccounts.getAccounts(),
      dataDanica.getWithdrawalKFAccounts(),
      dataInvestments.getAdviseAccounts(),
    ])
      .then((data) => {
        const [requestAccounts, requestWithdrawalKFAccounts, advices] = data;
        if (requestWithdrawalKFAccounts.length === 1) {
          setKfWithdrawalState({
            from: requestWithdrawalKFAccounts[0],
          });
        } else if (query.accountId) {
          const selectedAccount = requestWithdrawalKFAccounts.find(
            (item) => item.accountId === query.accountId
          );

          if (selectedAccount) {
            setKfWithdrawalState({
              from: selectedAccount,
            });
          }
        }
        setAccounts(requestAccounts);
        setWithdrawalKFAccounts(requestWithdrawalKFAccounts);
        setStatus(Status.SUCCESS);
        if (advices) {
          setAdvices(advices as InvestmentAdviseAccount[]);
        }
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, [setKfWithdrawalState, query.accountId]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(load, 500);
  }, [load]);

  useEffect(load, [load]);

  useEffect(() => {
    if (
      typeof location.state === "undefined" ||
      typeof withdrawalKFAccounts === "undefined"
    ) {
      return;
    }
    setKfWithdrawalState({
      from: withdrawalKFAccounts.find(
        (account) => account.accountId === location.state.accountId
      ),
    });
  }, [location.state, setKfWithdrawalState, withdrawalKFAccounts]);

  const goToNext = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!formRef.current?.isValid) {
        return;
      }

      const hasError = !!getError(
        kfWithdrawalContext.state.from,
        selectedAccount
      );

      if (hasError) {
        return;
      }

      kfWithdrawalContext.setState({
        account: selectedAccount,
      });

      next();
    },
    [kfWithdrawalContext, next, selectedAccount]
  );

  if (!user.name) {
    return <Redirect to={generatePath(getNavLink(LOGIN_SWEDEN_PAGE_URL))} />;
  }

  if (!accounts.length) {
    return <div className="withdrawal-request-page-from" />;
  }

  const error = getError(kfWithdrawalContext.state.from, selectedAccount);

  return (
    <div className="withdrawal-request-page-from">
      <Typography type="h1">Välj kapitalförsäkring som ska flyttas</Typography>

      <Retry status={status} retry={retry}>
        <Form lysaFormRef={formRef} onSubmit={goToNext}>
          <LysaAccountSelectionCard
            accounts={availableAccounts}
            selectedAccount={availableAccounts?.find(
              (account) =>
                account.accountId === kfWithdrawalContext.state.from?.accountId
            )}
            onChange={(lysaAccount) => {
              const withdrawalAccount = withdrawalKFAccounts.find(
                (withdrawalAccount) =>
                  withdrawalAccount.accountId === lysaAccount.accountId
              );
              if (withdrawalAccount) {
                kfWithdrawalContext.setState({
                  from: withdrawalAccount,
                });
              }
            }}
            legend={"Välj försäkring du vill flytta"}
          />
          {error}
          {error === null &&
            selectedAccountAdvice &&
            selectedAccountAdvice?.takenRisk < MIN_RECOMMENDED_ALLOCATION && (
              <Snackbar icon={true} type={SNACKBAR_TYPES.WARNING}>
                <div>
                  <strong>
                    Avrådan på grund av Lysas roll som försäkringsförmedlare:
                  </strong>{" "}
                  eftersom kapitalförsäkring är en schablonbeskattad sparform
                  bör spararen ha en investering som över tid kan förväntas
                  leverera en avkastning som innebär att beskattningsformens
                  fördel tillgodogörs. Ditt investeringsförslag understiger ett
                  aktieinnehav om{" "}
                  <strong>{MIN_RECOMMENDED_ALLOCATION} %</strong>. En
                  värdepappersdepå kan därför vara mer fördelaktig än en
                  kapitalförsäkring.
                  <br />
                  <br />
                  <b>
                    <Link to={getNavLink(CREATE_ACCOUNT_CORPORATION_PAGE_URL)}>
                      Öppna värdepappersdepå
                    </Link>
                  </b>
                </div>
              </Snackbar>
            )}
          <Button
            block
            type="submit"
            label={<TranslatedText id="withdrawalRequest.kf.from.next" />}
          />
        </Form>
      </Retry>
    </div>
  );
};
