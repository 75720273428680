import { useEffect, useState } from "react";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { SavingsAccountId } from "../../../../../data/dataAccounts";
import {
  CashTransaction,
  CashTransactionType,
  dataTransactions,
  Filter,
  instanceOfCashTransaction,
} from "../../../../../data/dataTransactions";
import { useAccounts } from "../../../../../hooks/useAccounts";
import { AccountPageCard } from "../accountPageCard/AccountPageCard";
import cx from "classnames";
import { Amount } from "../../../../../components/amount/Amount";
import { FormattedDate } from "react-intl";
import { useLoadingContext } from "../../../../../context/LoadingContext";
import { Spinner, Typography } from "@lysaab/ui-2";

import "./TransactionsCard.scss";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { Link } from "react-router-dom";
import {
  HistoricTransactionsPageLocationState,
  HISTORIC_TRANSACTIONS_PAGE_URL,
} from "../../../../historicTransactions/HistoricTransactionsPage";
import { DateTime } from "luxon";

const MAX_TRANSACTIONS_SHOWN = 4;

export const TransactionsCard = ({
  accountId,
}: {
  accountId: SavingsAccountId;
}) => {
  const { accounts } = useAccounts();
  const [accountName, setAccountName] = useState<string>();
  const [transactions, setTransactions] = useState<CashTransaction[]>([]);
  const { isLoading, setIsLoading } = useLoadingContext();

  useEffect(() => {
    if (typeof accounts === "undefined") {
      return;
    }
    const currentAccount = [
      ...accounts.savingsAccounts,
      ...accounts.sharedSavingsAccounts,
    ].find((account) => account.accountId === accountId);

    if (typeof currentAccount === "undefined") {
      return;
    }

    setAccountName(currentAccount.name);

    const filter: Filter = {
      end: new Date(),
      start: DateTime.fromISO(currentAccount.created).toJSDate(),
      accountIds: [accountId],
      types: [],
    };

    dataTransactions
      .getTransactions(filter)
      .then((data) => {
        const items = data
          .filter(
            (item): item is CashTransaction =>
              instanceOfCashTransaction(item) &&
              (item.type === CashTransactionType.DEPOSIT ||
                item.type === CashTransactionType.WITHDRAWAL ||
                item.type === CashTransactionType.INTEREST ||
                item.type === CashTransactionType.FEE ||
                item.type === CashTransactionType.MOVE_OUT ||
                item.type === CashTransactionType.MOVE_IN)
          )
          .sort((a, b) => {
            const firstDate = DateTime.fromISO(a.booked);
            const secondDate = DateTime.fromISO(b.booked);

            if (firstDate.year === secondDate.year) {
              if (
                a.type === CashTransactionType.FEE &&
                b.type === CashTransactionType.INTEREST
              ) {
                return -1;
              }
              if (
                a.type === CashTransactionType.INTEREST &&
                b.type === CashTransactionType.FEE
              ) {
                return 1;
              }
            }

            return secondDate.toMillis() - firstDate.toMillis();
          });
        setTransactions(items);
      })
      .finally(() => setIsLoading(false));
  }, [accountId, accounts, setIsLoading]);

  if (isLoading || typeof accountName === "undefined") {
    return <Spinner />;
  }

  const hasAdditionalTransactions =
    transactions.length > MAX_TRANSACTIONS_SHOWN;
  return (
    <AccountPageCard
      className="transactions-card"
      bottomLink={
        hasAdditionalTransactions && (
          <Link<HistoricTransactionsPageLocationState>
            to={{
              pathname: getNavLink(HISTORIC_TRANSACTIONS_PAGE_URL),
              state: { accountId },
            }}
          >
            <TranslatedText id="savingsAccountPage.transactionsCard.showMore" />
          </Link>
        )
      }
    >
      {hasAdditionalTransactions ? (
        <Typography type="label" component="p">
          <TranslatedText
            id="savingsAccountPage.transactionsCard.ingressRecentTransactions"
            values={{ name: accountName }}
          />
        </Typography>
      ) : (
        <Typography type="label" component="p">
          <TranslatedText
            id="savingsAccountPage.transactionsCard.ingressAllTransactions"
            values={{ name: accountName }}
          />
        </Typography>
      )}

      {transactions.length > 0 ? (
        <div className="transaction-card__list">
          <ul>
            {transactions
              .slice(0, MAX_TRANSACTIONS_SHOWN)
              .map(({ type, booked, amount }) => {
                const isDeposit = type === CashTransactionType.DEPOSIT;
                const isWithdrawal = type === CashTransactionType.WITHDRAWAL;
                const isInternalTransfer =
                  type === CashTransactionType.MOVE_IN ||
                  type === CashTransactionType.MOVE_OUT;
                const isInterest = type === CashTransactionType.INTEREST;
                const isFee = type === CashTransactionType.FEE;

                return (
                  <li key={booked + type + amount}>
                    <div className="transaction-card__entry">
                      <div className="transaction-card__entry-left">
                        <Typography
                          type="body"
                          variant="secondary"
                          component="span"
                        >
                          <FormattedDate value={booked} />
                        </Typography>
                        <div>
                          <span
                            className={cx("transaction-card__entry-indicator", {
                              "transaction-card__deposit-indicator": isDeposit,
                              "transaction-card__withdrawal-indicator":
                                isWithdrawal,
                              "transaction-card__internal-transfer-indicator":
                                isInternalTransfer,
                              "transaction-card__interest-indicator":
                                isInterest,
                              "transaction-card__fee-indicator": isFee,
                            })}
                          />
                          {isDeposit && (
                            <TranslatedText id="savingsAccountPage.transactionsCard.deposit" />
                          )}
                          {isWithdrawal && (
                            <TranslatedText id="savingsAccountPage.transactionsCard.withdrawal" />
                          )}
                          {isInterest && (
                            <TranslatedText id="savingsAccountPage.transactionsCard.interest" />
                          )}
                          {isFee && (
                            <TranslatedText id="savingsAccountPage.transactionsCard.fee" />
                          )}
                          {isInternalTransfer && (
                            <TranslatedText id="savingsAccountPage.transactionsCard.internalTransfer" />
                          )}
                        </div>
                      </div>
                      <div className="transaction-card__entry-right">
                        <Typography
                          type="body"
                          variant="secondary"
                          component="span"
                        >
                          <TranslatedText id="savingsAccountPage.transactionsCard.amount" />
                        </Typography>
                        <Amount
                          amount={
                            type === CashTransactionType.MOVE_OUT ||
                            isWithdrawal
                              ? amount * -1
                              : amount
                          }
                          decimals={2}
                        />
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
          {transactions.length > MAX_TRANSACTIONS_SHOWN && (
            <div className="transaction-card__list-fade" />
          )}
        </div>
      ) : (
        <Typography type="body" component="span">
          <TranslatedText id="savingsAccountPage.transactionsCard.noTransactions" />
        </Typography>
      )}
    </AccountPageCard>
  );
};
