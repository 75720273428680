import { useContext } from "react";
import { UserContext } from "../../../context/UserContext";
import { LegalEntityType } from "../../../data/dataLogin";
import { Redirect, useLocation } from "react-router";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { CREATE_ACCOUNT_URL } from "../../../pages/createAccount/CreateAccountStory";
import { CREATE_ACCOUNT_CORPORATION_PAGE_URL } from "./corporation/kf/CreateAccountCorporationPage";

export const CREATE_ACCOUNT_SELECTION_PAGE_URL = "/create-account-selection";

export function CreateAccountSelection() {
  const location = useLocation<{}>();
  const userContext = useContext(UserContext);
  const user = userContext.state;

  if (user.legalEntityType === LegalEntityType.CORPORATION) {
    return (
      <Redirect
        to={{
          pathname: getNavLink(CREATE_ACCOUNT_CORPORATION_PAGE_URL),
          state: { ...location.state },
        }}
      />
    );
  } else {
    <Redirect
      to={{
        pathname: getNavLink(CREATE_ACCOUNT_URL),
      }}
    />;
  }

  return null;
}
