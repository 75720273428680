import { Spinner } from "@lysaab/ui-2";
import { VoidFunctionComponent, useCallback } from "react";
import { ConfirmEsgUpdate } from "../../../../pageComponents/confirmEsgUpdate/ConfirmEsgUpdate";
import { useReviewAccountContext } from "../../ReviewAccountContext";

interface Props {
  next: () => void;
}

export const ConfirmEsgUpdateWrapper: VoidFunctionComponent<Props> = ({
  next,
}) => {
  const [reviewAccountState, setReviewAccountState] = useReviewAccountContext();

  const onNext = useCallback(() => {
    if (typeof reviewAccountState.reviewAccount === "undefined") {
      throw new Error("onNext - Missing reviewAccount in reviewAccountState");
    }

    setReviewAccountState({
      reviewAccount: {
        ...reviewAccountState.reviewAccount,
        hasReviewedUnmatchedEsgPreferences: true,
      },
    });

    next();
  }, [next, reviewAccountState, setReviewAccountState]);

  if (
    !reviewAccountState.reviewAccount ||
    !reviewAccountState.reviewAccount.newAdvice.esgResult.esgBestMatch
  ) {
    return <Spinner />;
  }

  return (
    <ConfirmEsgUpdate
      esg={reviewAccountState.reviewAccount.newAdvice.esgResult.esgAnswers}
      updatedEsg={
        reviewAccountState.reviewAccount.newAdvice.esgResult.esgBestMatch
      }
      next={onNext}
    />
  );
};
