import {
  Button,
  Card,
  EmailInput,
  EmailValidator,
  Form,
  LysaFormRef,
  RequiredValidator,
  SNACKBAR_TYPES,
  Snackbar,
  Spinner,
  cache,
} from "@lysaab/ui-2";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useRouteMatch } from "react-router";
import {
  CreationStatusResponse,
  dataLife,
  InitiateSigningRequest,
  InsuranceCreationState,
  InsuranceCreationToken,
} from "../../../../../../data/dataLife";
import { AgreementSigner } from "./components/AgreementSigner";
import { SummaryInfo } from "../components/SummaryInfo";
import "./CreationStatusOverview.scss";
import { Docs } from "../docs/Docs";
import {
  dataDocuments,
  DocumentInformation,
} from "../../../../../../data/dataDocuments";
import { queryClient } from "../../../../../../ReactQueryProvider";

const TIMER = 3000;

interface Props {
  sign: (
    creationToken: InsuranceCreationToken,
    data: InitiateSigningRequest
  ) => void;
  next: () => void;
}

interface Params {
  creationToken: InsuranceCreationToken;
}

export const CreationStatusOverview: FunctionComponent<Props> = ({
  next,
  sign,
}) => {
  const timer = useRef<number>();
  const { params } = useRouteMatch<Params>();
  const { creationToken } = params;
  const [creationStatus, setCreationStatus] =
    useState<CreationStatusResponse>();
  const [copySuccess, setCopySuccess] = useState<boolean | undefined>();
  const formRef = useRef<LysaFormRef>();
  const [email, setEmail] = useState<string>("");
  const [emailSuccess, setEmailSuccess] = useState<boolean | undefined>();
  const [documentsInformation, setDocumentsInformation] =
    useState<DocumentInformation[]>();

  useEffect(() => {
    dataDocuments.getDocumentsInformation(true).then((information) => {
      setDocumentsInformation(information);
    });
  }, []);

  const pollStatus = useCallback(
    (creationToken: InsuranceCreationToken) => {
      dataLife
        .pollCreationStatus(creationToken)
        .then((data) => {
          if (
            data.state !== InsuranceCreationState.DONE &&
            data.state !== InsuranceCreationState.FAILED
          ) {
            clearTimeout(timer.current);
            timer.current = window.setTimeout(() => {
              pollStatus(creationToken);
            }, TIMER);
          } else if (data.state === InsuranceCreationState.DONE) {
            cache.clear();
            queryClient.invalidateQueries();
            next();
          }

          /**
           * TODO: Should we store status in the context?
           */

          setCreationStatus(data);
        })
        .catch(() => {
          // Just retry
          timer.current = window.setTimeout(() => {
            pollStatus(creationToken);
          }, TIMER);
        });
    },
    [next]
  );

  useEffect(() => {
    pollStatus(creationToken);

    return () => {
      clearTimeout(timer.current);
    };
  }, [pollStatus, creationToken]);

  const copyLink = useCallback(() => {
    if (copyToClipboard(window.location.href)) {
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(undefined);
      }, 2000);
    } else {
      setCopySuccess(false);
      setTimeout(() => {
        setCopySuccess(undefined);
      }, 5000);
    }
  }, []);

  if (
    !creationStatus ||
    creationStatus.state !== InsuranceCreationState.SIGNING ||
    !documentsInformation
  ) {
    return <Spinner />;
  }

  return (
    <div className="creation-status-overview">
      <h1>Signering</h1>
      <p>
        Genom att signera bekräftar jag att de uppgifter som jag har lämnat i
        ansökan är korrekta och fullständiga. Jag bekräftar att jag har tagit
        del av informationen i nedanstående dokument och att jag accepterar
        avtalsvillkoren enligt nedan och att jag förbinder mig att följa dessa.
        Jag godtar att informationen om produkten endast lämnas på denna
        webbplats.
      </p>
      <Docs
        name={creationStatus.summary.policyHolderName}
        investmentType={creationStatus.summary.investmentType}
        risk={creationStatus.summary.takenRisk}
        documentsInformation={documentsInformation}
      />
      <SummaryInfo
        investmentType={creationStatus.summary.investmentType}
        takenRisk={creationStatus.summary.takenRisk}
        savingsHorizon={creationStatus.summary.savingsHorizon}
        insured={creationStatus.summary.insured}
      />
      <Card>
        <h4>Signera avtal med Mobilt BankID</h4>
        <p className="signing-group-description">
          En av grupperna nedan måste signera för att skapa kontot
        </p>

        <div className="signers-groups">
          {creationStatus.signingGroups?.map((group, i) => (
            <div className="signing-group" key={i}>
              <div className="signing-group-header">
                <h5>Signeringsgrupp {i + 1}</h5>
                <p>{group.signingText}</p>
              </div>
              <div className="signing-group-content">
                <ul className="signer-names">
                  {group.signees.map((signee) => (
                    <AgreementSigner
                      key={signee.id}
                      signer={signee}
                      onClick={() => {
                        sign(creationToken, { id: signee.id });
                      }}
                    />
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </Card>

      {creationStatus &&
        creationStatus.state === InsuranceCreationState.SIGNING && (
          <div className="futur-summary-additional-contact">
            <Card>
              <h4>Signaturer och identifiering</h4>
              <div className="relative">
                {copySuccess === false ? (
                  <Snackbar type={SNACKBAR_TYPES.ERROR}>
                    <b>Ajdå!</b> Länken blev inte kopierad
                  </Snackbar>
                ) : null}
                {copySuccess === true ? (
                  <Snackbar type={SNACKBAR_TYPES.SUCCESS}>
                    Länken kopierades
                  </Snackbar>
                ) : null}
                <p>
                  För att öppna en kapitalförsäkring måste vi ha signaturer av
                  företagets firmatecknare och verkliga huvudmän. Om det krävs
                  fler firmatecknare utöver dig själv, eller om du endast
                  administrerar kontot, kan du själv kopiera länken till den här
                  sidan och skicka till de firmatecknare och verkliga huvudmän
                  som ska signera. Du kan också skriva in mailadressen här nedan
                  så förmedlar vi länken.
                </p>
              </div>

              <Button size="small" onClick={copyLink} label="Kopiera länk" />

              <Form
                lysaFormRef={formRef}
                onSubmit={(event) => {
                  event.preventDefault();

                  if (formRef.current?.isValid) {
                    dataLife
                      .shareSigningByEmail(creationToken, email)
                      .then(() => {
                        setEmailSuccess(true);
                        setTimeout(() => {
                          setEmailSuccess(undefined);
                        }, 2000);
                      })
                      .catch(() => {
                        setEmailSuccess(false);
                        setTimeout(() => {
                          setEmailSuccess(undefined);
                        }, 5000);
                      });
                  }
                }}
              >
                <div className="relative">
                  {emailSuccess === false ? (
                    <Snackbar type={SNACKBAR_TYPES.ERROR}>
                      <b>Ajdå!</b> Vi kunde inte skicka meddelandet.
                    </Snackbar>
                  ) : null}
                  {emailSuccess === true ? (
                    <Snackbar type={SNACKBAR_TYPES.SUCCESS}>
                      Länken skickades
                    </Snackbar>
                  ) : null}
                </div>
                <div className="email-wrapper">
                  <EmailInput
                    value={email}
                    label="Email"
                    placeholder=""
                    onChange={(event) => setEmail(event)}
                    validators={[
                      new EmailValidator("Ogiltig email"),
                      new RequiredValidator("Email måste fyllas i"),
                    ]}
                    forceValidation={false}
                    suggestionMessage={
                      "Det ser ut som att du kanske skrev in en felaktig " +
                      "emailadress. Menade du {suggestion}?"
                    }
                  />

                  <Button size="small" type="submit" label="Maila" />
                </div>
              </Form>
            </Card>
          </div>
        )}
    </div>
  );
};

function copyToClipboard(str: string) {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  const selection = document.getSelection();
  if (!selection) {
    return false;
  }

  const selected = selection.rangeCount > 0 ? selection.getRangeAt(0) : false;
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  if (selected) {
    selection.removeAllRanges();
    selection.addRange(selected);
  }

  return true;
}
