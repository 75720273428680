import { useLocation } from "react-router";

export function useAccountFilterParams() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const filterParam = searchParams.get("filter");
  const hasSavingsAccountFilter = filterParam === "savings";
  const hasInvestmentAccountFilter = filterParam === "investment";
  return {
    hasSavingsAccountFilter,
    hasInvestmentAccountFilter,
    hasAccountFilter: hasSavingsAccountFilter || hasInvestmentAccountFilter,
    accountFilterParams: searchParams.toString(),
  };
}
