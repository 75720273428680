import { API } from "@lysaab/ui-2";

interface CustomerExportResponse {
  status: "PENDING" | "COMPLETE";
}

export const dataGdprExport = {
  getCustomerData: () => {
    return API.get<CustomerExportResponse>(
      `/gdpr-export/customer/request`,
      true
    );
  },
};
