import { defineMessages } from "react-intl";
import { TransactionType } from "../../../data/dataTransactions";

interface MessageWithId {
  id: string;
}

export const transactionTranslations = defineMessages<
  TransactionType,
  MessageWithId,
  Record<TransactionType, MessageWithId>
>({
  [TransactionType.WITHDRAWAL]: {
    id: "historicTransactionsPage.transactionTranslations.withdrawal",
  },
  [TransactionType.DEPOSIT]: {
    id: "historicTransactionsPage.transactionTranslations.deposit",
  },
  [TransactionType.BUY]: {
    id: "historicTransactionsPage.transactionTranslations.fundBuy",
  },
  [TransactionType.SELL]: {
    id: "historicTransactionsPage.transactionTranslations.fundSell",
  },
  [TransactionType.FEE]: {
    id: "historicTransactionsPage.transactionTranslations.fee",
  },
  [TransactionType.CORRECTION]: {
    id: "historicTransactionsPage.transactionTranslations.correction",
  },
  [TransactionType.TAX]: {
    id: "historicTransactionsPage.transactionTranslations.tax",
  },
  [TransactionType.MOVE_IN]: {
    id: "historicTransactionsPage.transactionTranslations.moveIn",
  },
  [TransactionType.MOVE_OUT]: {
    id: "historicTransactionsPage.transactionTranslations.moveOut",
  },
  [TransactionType.SWITCH_BUY]: {
    id: "historicTransactionsPage.transactionTranslations.switchBuy",
  },
  [TransactionType.SWITCH_SELL]: {
    id: "historicTransactionsPage.transactionTranslations.switchSell",
  },
  [TransactionType.INTEREST]: {
    id: "historicTransactionsPage.transactionTranslations.interest",
  },
});
