import {
  useContext,
  useEffect,
  useRef,
  useState,
  VoidFunctionComponent,
} from "react";
import {
  Button,
  LysaFormRef,
  Form,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Card,
} from "@lysaab/ui-2";
import {
  CrsResponse,
  dataCrs,
  mapCrsResponseToRequest,
} from "../../../data/dataCrs";
import { TranslatedText } from "../../../components/TranslatedText";
import { CrsContext } from "../../updateCrsStory/context/CrsContext";
import { CrsFormFields } from "../../../components/crsFormFields/CrsFormFields";
import { hasCrsBeenUpdated } from "../../updateCrsStory/updateCrsInformation/UpdateCrsInformation";

import "./UpdateCrs.scss";
import { useCountry } from "../../../context/LocalizationContext";

export interface UpdateCrsProps {
  next: () => void;
}

export const UpdateCrs: VoidFunctionComponent<UpdateCrsProps> = ({ next }) => {
  const crsContext = useContext(CrsContext);
  const [serverCrs, setServerCrs] = useState<CrsResponse>();
  const [hasCrsChanged, setHasCrsChanged] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { crsInformation } = crsContext.state;
  const country = useCountry();
  const currentCountry = useRef(country);

  const formRef = useRef<LysaFormRef>();

  useEffect(() => {
    setIsLoading(true);
    dataCrs
      .getLatestCrs()
      .then((resp) => {
        if (!currentCountry.current) {
          return;
        }
        const temp = JSON.parse(JSON.stringify(resp)) as CrsResponse;
        const hasSigningCountryAsTaxCountry = temp.countries.some(
          (country) => country.country === currentCountry.current
        );
        if (!hasSigningCountryAsTaxCountry) {
          temp.countries = [
            {
              country: currentCountry.current,
              tin: "",
            },
            ...temp.countries,
          ];
        }

        setServerCrs(temp);
        if (typeof crsInformation === "undefined") {
          crsContext.setState({ crsInformation: temp });
        }
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
    /* The useEffect should only run on first render, but eslint wants crsContext as dependecy which will trigger useEffect on every input change */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (serverCrs && typeof crsInformation !== "undefined") {
      setHasCrsChanged(hasCrsBeenUpdated(crsInformation, serverCrs));
    }
  }, [crsInformation, hasCrsChanged, serverCrs]);

  if (isLoading) {
    return <Spinner />;
  }

  const postCrsInformation = (crsInformation: CrsResponse) => {
    setIsLoading(true);
    dataCrs
      .postUpdateCrs(mapCrsResponseToRequest(crsInformation, country))
      .then(() => {
        next();
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (
    typeof crsInformation === "undefined" ||
    typeof crsContext.state.crsInformation === "undefined"
  ) {
    return null;
  }
  return (
    <div className="update-crs">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (formRef.current?.isValid && crsInformation) {
            postCrsInformation(crsInformation);
          }
        }}
      >
        <h2>
          <TranslatedText id="create-account.story.updateCrs.header" />
        </h2>

        <Card>
          {error && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="create-account.story.updateCrs.error" />
            </Snackbar>
          )}
          <CrsFormFields
            data={crsContext.state.crsInformation}
            setData={crsContext.setState}
          />
        </Card>
        <p>
          <TranslatedText id="create-account.story.updateCrs.nextConfirmText" />
        </p>
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="create-account.story.updateCrs.button.next" />
          }
        />
      </Form>
    </div>
  );
};
