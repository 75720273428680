import { useCallback, useRef, useState, VoidFunctionComponent } from "react";
import { Button, Form, FormErrors, LysaFormRef, Spinner } from "@lysaab/ui-2";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { dataKyc } from "../../../../data/dataKyc";
import { stateToRequest } from "@lysaab/lysa-pep";
import { defineMessages } from "react-intl";
import { dataYearlyReview } from "../../../../data/dataYearlyReview";
import { EventTracker } from "../../../../components/eventTracker/EventTracker";
import { useYearlyReviewStatus } from "../../../../hooks/useYearlyReviewState";
import {
  getEligibility,
  getEligibilityRisk,
  dataInvestments,
  getAccountQuestions,
  isValidRecalculateAdvicesWithRiskRequest,
  isValidUpdateInvestmentProfilesRequest,
} from "../../../../data/dataInvestments";
import {
  AccountAdviceCard,
  UpdateInvestmentAccountComposition,
} from "../../../../pageComponents/accountsAllocation/accountAdviseCard/AccountAdviceCard";
import { TrackerEvent } from "../../../../data/dataCustomerTracking";
import { InvestmentAccountId } from "../../../../data/dataAccounts";
import { useEligibilityContext } from "../../../../context/EligibilityContext";
import { useMultiPepContext } from "../../contexts/MultiPepContext";
import { useAccountsContext } from "../../../../context/AccountsContext";
import {
  AccountUpdateAction,
  useReviewAccountContext,
  useUpdateReviewAccounts,
} from "../../../reviewAccount/ReviewAccountContext";
import { useYearlyReviewContext } from "../../contexts/YearlyReviewContext";
import { useFeatureContext } from "../../../../context/FeatureContext";

const messages = defineMessages({
  errorTitle: {
    id: "yearly.summary.error.title",
  },
});

interface Props {
  next: () => void;
  navigateReviewRiskWarning: (
    reviewAccount: UpdateInvestmentAccountComposition
  ) => void;
  navigateReview: (reviewAccount: UpdateInvestmentAccountComposition) => void;
}

export const AccountsAllocation: VoidFunctionComponent<Props> = ({
  next,
  navigateReviewRiskWarning,
  navigateReview,
}) => {
  const intl = useIntl();
  const [, setFeatureState] = useFeatureContext();
  const [multiPepState] = useMultiPepContext();
  const [eligibilityState] = useEligibilityContext();
  const [accountsState] = useAccountsContext();
  const [reviewAccountState] = useReviewAccountContext();
  const [yearlyReviewState, setYearlyReviewState] = useYearlyReviewContext();
  const updateReviewAccounts = useUpdateReviewAccounts();
  const { deadline } = useYearlyReviewStatus();

  const [loading, setLoading] = useState<boolean>(true);
  const formRef = useRef<LysaFormRef>();

  useEffect(() => {
    if (accountsState.preferenceAccounts.length === 0) {
      return;
    }

    const data = {
      risk: getEligibilityRisk(eligibilityState.risk),
      investmentProfiles: accountsState.preferenceAccounts.map((account) => ({
        accountId: account.accountId as InvestmentAccountId,
        ...getAccountQuestions(account),
      })),
    };

    if (!isValidRecalculateAdvicesWithRiskRequest(data)) {
      throw new Error("AccountsAllocation - data missmatches request");
    }

    Promise.all([
      dataInvestments.getAdviseAccounts(),
      dataInvestments.recalculateAdvicesWithRisk(data),
    ])
      .then(([adviseAccounts, recalculatedAdvices]) =>
        recalculatedAdvices.reduce<UpdateInvestmentAccountComposition[]>(
          (mergedAccounts, recalculatedAdvice) => {
            const adviseAccount = adviseAccounts.find(
              (adviceAccount) =>
                adviceAccount.accountId === recalculatedAdvice.accountId
            );
            if (adviseAccount) {
              return [
                ...mergedAccounts,
                {
                  accountId: recalculatedAdvice.accountId,
                  risk: recalculatedAdvice.takenRisk,
                  newAdvice: recalculatedAdvice,
                  oldAdvice: adviseAccount,
                },
              ];
            }
            return mergedAccounts;
          },
          []
        )
      )
      .then(updateReviewAccounts)
      .finally(() => setLoading(false));
  }, [
    accountsState.preferenceAccounts,
    eligibilityState,
    updateReviewAccounts,
  ]);

  const onSubmit = useCallback(() => {
    if (!reviewAccountState.accounts) {
      return;
    }

    const investmentRequest = {
      eligibility: getEligibility(eligibilityState),
      investmentProfiles: reviewAccountState.accounts.map((account) => ({
        accountId: account.accountId,
        takenRisk: account.risk,
        ...getAccountQuestions(account.newAdvice),
      })),
    };

    if (
      formRef.current?.isValid &&
      multiPepState.pepStates &&
      deadline &&
      isValidUpdateInvestmentProfilesRequest(investmentRequest)
    ) {
      setLoading(true);
      Promise.all([
        // Save pep
        ...multiPepState.pepStates.map((pepState) => {
          if (pepState.locked) {
            return Promise.resolve();
          }
          return dataKyc.updatePep(stateToRequest(pepState));
        }),
        // Save eligibility, account questions and risk
        dataInvestments.updateInvestmentProfiles(investmentRequest),
      ])
        .then(() => {
          EventTracker.track({
            event: TrackerEvent.YEARLY_REVIEW_PEP_SAVE,
            message: multiPepState.pepStates?.map((pepState) => ({
              tin: pepState.tin,
              locked: pepState.locked,
            })),
          });

          // Check if yearly review is all done and request new time
          return dataYearlyReview.postYearlyReviewDate().then(() => {
            setLoading(false);
            EventTracker.track({ event: TrackerEvent.YEARLY_REVIEW_DONE });
            setFeatureState({ yearlyReviewLockRoutes: false });
            next();
          });
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setYearlyReviewState({ forceErrorsAccountsAllocation: true });
    }
  }, [
    reviewAccountState.accounts,
    eligibilityState,
    multiPepState.pepStates,
    deadline,
    setFeatureState,
    next,
    setYearlyReviewState,
  ]);

  const handleAccountAction = useCallback(
    (
      action: AccountUpdateAction,
      account: UpdateInvestmentAccountComposition
    ) => {
      const reviewAccount: UpdateInvestmentAccountComposition = JSON.parse(
        JSON.stringify({ ...account, action })
      );

      if (action === AccountUpdateAction.KEEP) {
        navigateReviewRiskWarning(reviewAccount);
      } else {
        navigateReview(reviewAccount);
      }
    },
    [navigateReview, navigateReviewRiskWarning]
  );

  if (loading || !reviewAccountState.accounts) {
    return <Spinner />;
  }

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={onSubmit}
      forceValidation={yearlyReviewState.forceErrorsAccountsAllocation}
    >
      <h2>
        <FormattedMessage id="yearly.summary.header" />
      </h2>
      <p>
        <FormattedMessage id="yearly.summary.description" />
      </p>

      <FormErrors
        title={intl.formatMessage(messages.errorTitle)}
        lysaFormRef={formRef}
      />

      {reviewAccountState.accounts.map((account) => (
        <AccountAdviceCard
          key={account.accountId}
          account={account}
          onAccountAction={(action) => handleAccountAction(action, account)}
        />
      ))}

      <Button
        block
        type="submit"
        label={<FormattedMessage id="yearly.summary.button.next" />}
      />
    </Form>
  );
};
