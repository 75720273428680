import { useCallback, useEffect, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import {
  AccountType,
  dataAccounts,
  InvestmentAccountId,
  isPensionAccountType,
} from "../data/dataAccounts";
import { dataTransfer } from "../data/dataTransfer";
import { useIsReadOnly } from "./useIsReadOnly";
import { usePendingDeposits } from "./usePendingDeposits";

export interface CloseableAccount {
  isCloseable: true;
}

export interface NotCloseableAccount {
  isCloseable: false;
  reasons: Array<string>;
}

type CloseableAccounts = Record<
  InvestmentAccountId,
  CloseableAccount | NotCloseableAccount
>;

export function useInvestmentAccountCloseable() {
  const loadPendingDeposits = usePendingDeposits();
  const [accountsCloseable, setAccountsCloseable] =
    useState<CloseableAccounts>();
  const [status, setStatus] = useState<"SUCCESS" | "LOADING" | "ERROR">(
    "LOADING"
  );
  const isReadonly = useIsReadOnly();
  const intl = useIntl();

  useEffect(() => {
    if (typeof loadPendingDeposits === "undefined") {
      return;
    }
    Promise.all([
      dataAccounts.getAllAccounts(),
      loadPendingDeposits(),
      dataTransfer.getPendingInternalTransfers(),
    ])
      .then(([accounts, pendingDeposits, pendingInternalTransfers]) => {
        const accountAggregator: Record<AccountType, number> = {
          [AccountType.DANICA_KF]: 0,
          [AccountType.ISK_SWE]: 0,
          [AccountType.KF_SWE]: 0,
          [AccountType.LYSA_PPF]: 0,
          [AccountType.LYSA_TJP]: 0,
          [AccountType.TJP_SWE]: 0,
          [AccountType.VP]: 0,
          [AccountType.VP_SWE]: 0,
        };
        accounts.investmentAccounts.forEach((account) => {
          if (accountAggregator.hasOwnProperty(account.type)) {
            accountAggregator[account.type]++;
          }
        });

        const accountsCloseableState: CloseableAccounts = {};
        accounts.investmentAccounts.forEach((account) => {
          const hasPendingDeposit = pendingDeposits.some(
            (pendingDeposit) => pendingDeposit.accountId === account.accountId
          );
          const hasIncomingInternalTransfer = pendingInternalTransfers.some(
            (pendingInternalTransfer) =>
              pendingInternalTransfer.toAccountId === account.accountId
          );
          const isLastCorporateVPAccount =
            accountAggregator.VP_SWE === 1 &&
            account.type === AccountType.VP_SWE;
          const isLastIsk =
            accountAggregator.ISK_SWE === 1 &&
            account.type === AccountType.ISK_SWE;
          const isMoneyOnAccount = account.worth > 0;
          const isFuturKapitalForsakring =
            account.type === AccountType.DANICA_KF;
          const isPension = isPensionAccountType(account.type);
          const isLastInvestmentAccount =
            accounts.investmentAccounts.length === 1;

          const disabledState = getInvestmentAccountCloseDisabledState(
            isMoneyOnAccount,
            isLastInvestmentAccount,
            hasPendingDeposit,
            isFuturKapitalForsakring,
            isLastCorporateVPAccount,
            isReadonly,
            hasIncomingInternalTransfer,
            isPension,
            isLastIsk,
            intl
          );
          accountsCloseableState[account.accountId] = disabledState;
        });
        setAccountsCloseable({ ...accountsCloseableState });
        setStatus("SUCCESS");
      })
      .catch(() => {
        setStatus("ERROR");
      });
  }, [intl, isReadonly, loadPendingDeposits]);

  const isAccountCloseable = useCallback(
    (accountId: InvestmentAccountId) => {
      const closeableState = accountsCloseable?.[accountId];
      if (typeof closeableState === "undefined") {
        throw new Error("isAccountCloseable - no closeable state for account");
      }
      return closeableState;
    },
    [accountsCloseable]
  );

  if (status === "ERROR") {
    return "ERROR";
  } else if (status === "LOADING") {
    return "LOADING";
  }

  return isAccountCloseable;
}

function getInvestmentAccountCloseDisabledState(
  isMoneyOnAccount: boolean,
  isLastInvestmentAccount: boolean,
  hasPendingDeposit: boolean,
  isFuturKapitalForsakring: boolean,
  isLastCorporateVPAccount: boolean,
  isReadonly: boolean,
  hasIncomingInternalTransfer: boolean,
  isPension: boolean,
  isLastIsk: boolean,
  intl: IntlShape
): CloseableAccount | NotCloseableAccount {
  const isCloseDisabledForInvestmentAccount =
    isMoneyOnAccount ||
    isLastInvestmentAccount ||
    hasPendingDeposit ||
    isFuturKapitalForsakring ||
    isLastCorporateVPAccount ||
    isReadonly ||
    hasIncomingInternalTransfer ||
    isPension ||
    isLastIsk;

  if (!isCloseDisabledForInvestmentAccount) {
    return { isCloseable: true };
  }

  let reasons: string[] = [];

  if (isMoneyOnAccount) {
    reasons.push(
      intl.formatMessage({
        id: "closeInvestmentAccount.reasons.isMoneyOnAccount",
      })
    );
  }

  if (hasPendingDeposit) {
    reasons.push(
      intl.formatMessage({
        id: "closeInvestmentAccount.reasons.isPendingDeposit",
      })
    );
  }
  if (hasIncomingInternalTransfer) {
    reasons.push(
      intl.formatMessage({
        id: "closeInvestmentAccount.reasons.hasIncomingInternalTransfer",
      })
    );
  }
  if (isLastInvestmentAccount || isLastIsk) {
    reasons = [
      intl.formatMessage({
        id: "closeInvestmentAccount.reasons.isOnlyOneAccount",
      }),
    ];
  } else if (isFuturKapitalForsakring) {
    reasons = [
      intl.formatMessage({
        id: "closeInvestmentAccount.reasons.isKapitalForsakring",
      }),
    ];
  } else if (isLastCorporateVPAccount) {
    reasons = [
      intl.formatMessage({
        id: "closeInvestmentAccount.reasons.isLastVPAccount",
      }),
    ];
  } else if (isPension) {
    reasons = [
      intl.formatMessage({
        id: "closeInvestmentAccount.reasons.isPension",
      }),
    ];
  }

  return {
    isCloseable: false,
    reasons: reasons,
  };
}
