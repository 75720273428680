import { useEffect, useState } from "react";
import { Page, PageHeader } from "../../../../../pages/Page";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { GridRow } from "../../../../../components/grid/gridRow/GridRow";
import { GridCol } from "../../../../../components/grid/gridCol/GridCol";
import { ClosedAccount, dataProfile } from "../../../../../data/dataProfile";
import { AccountType } from "../../../../../data/dataAccounts";
import { dataDanica } from "../../../../../data/dataDanica";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Icon, Spinner } from "@lysaab/ui-2";
import { DataDisplay } from "../../../../../components/dataDisplay/DataDisplay";
import { useUser } from "../../../../../context/UserContext";
import { AccountList } from "./AccountList";
import { AccountInformation } from "./AccountInformation";
import "./AccountingPage.scss";
import { useLocation } from "react-router";

interface ClosedAccountWithAmount extends ClosedAccount {
  amount: number;
}

export const MOVE_ACCOUNTING_PAGE_URL = "/move-accounting";

export const MoveAccounting = () => {
  const [closedAccounts, setClosedAccounts] = useState<
    ClosedAccountWithAmount[]
  >([]);
  const intl = useIntl();
  const user = useUser();
  const location = useLocation();
  const account = getAccounts(location, closedAccounts);

  useEffect(() => {
    dataProfile.getClosedAccounts().then((accounts) => {
      const closedKFs = accounts.filter(
        (account) => account.type === AccountType.DANICA_KF
      );
      const promises = Promise.all(
        closedKFs.map((account) => {
          return dataDanica
            .getKfMoveAmount(account.accountId)
            .then((move): ClosedAccountWithAmount | undefined => {
              if (move.amount === 0) {
                return undefined;
              }
              return { ...account, amount: move.amount };
            });
        })
      );
      promises.then((accounts) => {
        setClosedAccounts(
          accounts.filter(
            (account): account is ClosedAccountWithAmount =>
              account !== undefined
          )
        );
      });
    });
  }, []);

  if (!account) {
    return <Spinner />;
  }

  return (
    <Page className="accounting-page">
      <PageHeader className="no-print">
        <h1>
          <TranslatedText id="sweden.move-accounting.header" />
        </h1>
      </PageHeader>

      <GridRow>
        <GridCol xsmall={12} large={3} noPrint>
          <AccountList
            title={intl.formatMessage({
              id: "sweden.accounting-page.move.accounts.header",
            })}
            accounts={closedAccounts}
          />
        </GridCol>
        <GridCol xsmall={12} large={9} className="company">
          <ul className="company-details">
            <GridRow className="company-title">
              <GridCol>
                <h3>
                  <FormattedMessage id="sweden.accounting-page.move.accounts.title" />
                </h3>
              </GridCol>

              <GridCol className="no-print print-button">
                <Button
                  size="small"
                  onClick={() => window.print()}
                  icon="Print"
                  label={
                    <FormattedMessage id="sweden.accountingPage.account.print" />
                  }
                />
              </GridCol>

              <GridCol className="only-print">
                <Icon.Logo color="#000" size={60} />
              </GridCol>
            </GridRow>
            <li>
              <DataDisplay
                type="side-by-side"
                title={intl.formatMessage({
                  id: "sweden.accounting-page.move.name",
                })}
                text={user.name}
              />
            </li>
            <li>
              <DataDisplay
                type="side-by-side"
                title={intl.formatMessage({
                  id: "sweden.accounting-page.move.orgNumber",
                })}
                text={user.tin}
              />
            </li>
            <li>
              <DataDisplay
                type="side-by-side"
                title={intl.formatMessage({
                  id: "sweden.accounting-page.move.account",
                })}
                text={account?.name}
              />
            </li>
          </ul>
          <AccountInformation account={account} />
        </GridCol>
      </GridRow>
    </Page>
  );
};

function getAccounts(
  location: ReturnType<typeof useLocation>,
  accounts: ClosedAccountWithAmount[] | undefined
) {
  if (!accounts || !accounts.length) {
    return;
  }

  const searchParams = new URLSearchParams(location.search);

  return (
    accounts.find(
      (account) => account.accountId === searchParams.get("accountId")
    ) ?? accounts[0]
  );
}
