import { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Form,
  LysaFormRef,
  MaxLengthValidator,
  NewIcon,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  TextInput,
  Typography,
} from "@lysaab/ui-2";
import { useHistory } from "react-router-dom";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../../../../../pages/overview/OverviewPage";
import { DEPOSITS_OVERVIEW_URL } from "../../../../../../pages/deposits/overview/Recommendation";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
} from "../../../../../../data/dataCustomerTracking";
import { defineMessages, useIntl } from "react-intl";
import { MONTHLY_DEPOSITS_URL } from "../../../../../../pages/deposits/monthly/create/MonthlyStory";
import {
  dataAccounts,
  normalizeAccountName,
  SavingsAccount,
} from "../../../../../../data/dataAccounts";
import { useAccounts } from "../../../../../../hooks/useAccounts";
import { DateTime } from "luxon";
import * as H from "history";
import { AccountNameInvalidCharsValidator } from "../../../../../../components/validators/AccountNameInvalidCharsValidator";

import "./Done.scss";
import { AccountNameAlreadyExistsValidator } from "../../../../../../components/validators/AccountNameAlreadyExistsValidator";

const messages = defineMessages({
  inputLabel: {
    id: "sweden.createSavingsAccount.createSavingsAccount.done.inputLabel",
  },
  invalid: {
    id: "sweden.createSavingsAccount.createSavingsAccount.done.invalidValidatorMessage",
  },
  nameAlreadyExists: {
    id: "sweden.createSavingsAccount.createSavingsAccount.done.nameAlreadyExistsValidatorMessage",
  },
  long: {
    id: "sweden.createSavingsAccount.createSavingsAccount.done.longValidatorMessage",
  },
  empty: {
    id: "sweden.createSavingsAccount.createSavingsAccount.done.emptyValidatorMessage",
  },
});

export function Done() {
  const intl = useIntl();
  const [nameSuggestion, setNameSuggestion] = useState("");
  const { accounts } = useAccounts();
  const [isError, setIsError] = useState(false);
  const [latestCreatedSavingsAccount, setLatestCreatedSavingsAccount] =
    useState<SavingsAccount>();
  const formRef = useRef<LysaFormRef>();
  const originalAccountName = useRef<string>("");
  const history = useHistory();

  useEffect(() => {
    if (typeof accounts === "undefined") {
      return;
    }
    const sortedSavingsAccountDesc = accounts.savingsAccounts.sort(
      (first, second) =>
        DateTime.fromISO(second.created).toMillis() -
        DateTime.fromISO(first.created).toMillis()
    );
    setLatestCreatedSavingsAccount(sortedSavingsAccountDesc[0]);
    setNameSuggestion(sortedSavingsAccountDesc[0].name);
    originalAccountName.current = sortedSavingsAccountDesc[0].name;
  }, [accounts]);

  const changeAccountNameAndNavigate = (
    latestCreatedSavingsAccount: SavingsAccount,
    locationDescriptor: H.LocationDescriptor<unknown>
  ) => {
    if (typeof accounts === "undefined") {
      return;
    }
    if (normalizeAccountName(nameSuggestion) === originalAccountName.current) {
      return history.push(locationDescriptor);
    } else {
      dataAccounts
        .updateAccountName(
          latestCreatedSavingsAccount.accountId,
          nameSuggestion
        )
        .then(() => history.push(locationDescriptor))
        .catch(() => setIsError(true));
    }
  };

  if (typeof latestCreatedSavingsAccount === "undefined") {
    return <Spinner />;
  }

  return (
    <div className="open-account-done">
      {isError && (
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <TranslatedText id="sweden.createAccount.createSavingsAccount.done.error" />
        </Snackbar>
      )}
      <div className="open-account-done__icon-wrapper">
        <NewIcon.Check size={100} />
      </div>

      <Typography component="h2" type="h1">
        <TranslatedText id="sweden.createAccount.createSavingsAccount.done.newHeader" />
      </Typography>
      <Typography>
        <TranslatedText id="sweden.createAccount.createSavingsAccount.done.ingress" />
      </Typography>
      <Form lysaFormRef={formRef}>
        <Card>
          <TextInput
            label={intl.formatMessage(messages.inputLabel)}
            value={nameSuggestion}
            onChange={(value) => {
              setIsError(false);
              setNameSuggestion(value);
            }}
            validators={[
              new RequiredValidator(intl.formatMessage(messages.empty)),
              new AccountNameInvalidCharsValidator(
                intl.formatMessage(messages.invalid, {
                  invalidChars:
                    AccountNameInvalidCharsValidator.FORMATTED_INVALID_CHARS,
                })
              ),
              new MaxLengthValidator(50, intl.formatMessage(messages.long)),
              new AccountNameAlreadyExistsValidator(
                originalAccountName.current,
                intl.formatMessage(messages.nameAlreadyExists)
              ),
            ]}
          />
        </Card>
        <Card>
          <Typography type="h3">
            <TranslatedText id="sweden.createAccount.createSavingsAccount.done.tipsHeader" />
          </Typography>
          <ul className="open-account-done__list">
            <Typography
              component="li"
              type="body"
              className="open-account-done__list-item"
            >
              <NewIcon.Check />
              <TranslatedText id="sweden.createSavingsAccount.done.firstTips" />
            </Typography>
            <Typography
              component="li"
              type="body"
              className="open-account-done__list-item"
            >
              <NewIcon.Check />
              <TranslatedText id="sweden.createSavingsAccount.done.secondTips" />
            </Typography>
            <Typography
              component="li"
              type="body"
              className="open-account-done__list-item"
            >
              <NewIcon.Check />
              <TranslatedText id="sweden.createSavingsAccount.done.thirdTips" />
            </Typography>
          </ul>
          <Button
            block
            onClick={() => {
              if (formRef.current?.isInvalid) {
                formRef.current?.setForceErrors(true);
                return;
              }
              dataCustomerTrackingService.postEvent({
                domain: FeatureDomain.SAVINGS_ACCOUNT,
                eventName: "clickedDoneMonthlyPaymentButton",
                subDomain: SubDomain.CREATE_ACCOUNT,
              });
              changeAccountNameAndNavigate(
                latestCreatedSavingsAccount,
                getNavLink(MONTHLY_DEPOSITS_URL)
              );
            }}
            label={
              <TranslatedText id="sweden.createAccount.createSavingsAccount.done.link.monthlyDeposit" />
            }
          />
          <Button
            onClick={() => {
              if (formRef.current?.isInvalid) {
                formRef.current?.setForceErrors(true);
                return;
              }
              dataCustomerTrackingService.postEvent({
                domain: FeatureDomain.SAVINGS_ACCOUNT,
                eventName: "clickedDoneDepositButton",
                subDomain: SubDomain.CREATE_ACCOUNT,
              });
              changeAccountNameAndNavigate(latestCreatedSavingsAccount, {
                pathname: getNavLink(DEPOSITS_OVERVIEW_URL),
                state: {
                  accountId: latestCreatedSavingsAccount.accountId,
                },
              });
            }}
            block
            variant="secondary"
            label={
              <TranslatedText id="sweden.createAccount.createSavingsAccount.done.link.deposit" />
            }
          />
        </Card>
        <Button
          onClick={() => {
            if (formRef.current?.isInvalid) {
              formRef.current?.setForceErrors(true);
              return;
            }
            dataCustomerTrackingService.postEvent({
              domain: FeatureDomain.SAVINGS_ACCOUNT,
              eventName: "clickedDoneOverviewButton",
              subDomain: SubDomain.CREATE_ACCOUNT,
            });
            changeAccountNameAndNavigate(
              latestCreatedSavingsAccount,
              getNavLink(OVERVIEW_PAGE_URL)
            );
          }}
          block
          variant="secondary"
          label={
            <TranslatedText id="sweden.createAccount.createSavingsAccount.done.link.overview" />
          }
        />
      </Form>
    </div>
  );
}
