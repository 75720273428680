import { useCallback, useContext } from "react";
import * as H from "history";
import { TranslatedText } from "../../../../components/TranslatedText";
import { TinkAvailableBank } from "../../../../data/dataTink";
import { BankSelection } from "../../../../components/tink/BankSelection";
import { AddAccountKlarnaContext } from "../AddAccountKlarnaContext";

interface Props {
  next: () => void;
  missingBank?: H.LocationDescriptor<H.LocationState>;
}

export function TinkBankSelectionWrapper({ next, missingBank }: Props) {
  const klarnaContext = useContext(AddAccountKlarnaContext);
  const setState = klarnaContext.setState;

  const onComplete = useCallback(
    (bank: TinkAvailableBank) => {
      setState({ tinkBank: bank });
      setTimeout(() => {
        next();
      }, 0);
    },
    [next, setState]
  );

  return (
    <BankSelection
      onComplete={onComplete}
      missingBank={missingBank}
      missingBankHeader={
        <TranslatedText id="klarna.bank-selection.missing-bank.header" />
      }
      missingBankDescription={
        <TranslatedText id="klarna.bank-selection.missing-bank.description" />
      }
    />
  );
}
