import { FunctionComponent, useEffect, useState } from "react";

import { useIntl } from "react-intl";
import { useLocation } from "react-router";
import { useTransfer } from "../TransferContext";
import {
  dataLifePensionMove,
  PensionMoveSigningOptions,
  PensionMoveStatus,
} from "../../../../../data/dataLifePensionMove";
import { Ingress } from "../components/ingress/Ingress";
import { MoveSteps } from "../components/moveSteps/MoveSteps";
import { AccountType } from "../../../../../data/dataAccounts";
import { Button, Spinner, Success, Typography } from "@lysaab/ui-2";
import { MoveTimeInfo } from "../components/moveTimeInfo/MoveTimeInfo";
import { TranslatedText } from "../../../../../components/TranslatedText";

import "./Done.scss";

interface Props {
  next: () => void;
}

export const Done: FunctionComponent<Props> = ({ next }) => {
  const intl = useIntl();
  const [transfer, setTransfer] = useTransfer();
  const location = useLocation();
  const { pathname } = location;
  const pathnameParts = pathname.split("/");
  const caseId = pathnameParts[pathnameParts.length - 1];
  const [state, setState] = useState<"loading" | "completed">("loading");

  useEffect(() => {
    if (caseId) {
      setState("loading");
      dataLifePensionMove
        .getMove(caseId)
        .then((transfer) => {
          setTransfer(transfer);
        })
        .catch(() => {
          /**
           *  We just swallow the error. The component will render "Transfer started!" anyway.
           *  Is probably a better default than showing an error message.
           */
        })
        .finally(() => {
          setState("completed");
        });
    }
  }, [caseId, setTransfer]);

  const accountTypesInTransfer = transfer.moves.reduce((prev, move) => {
    if (move.type) {
      prev.push(move.type);
    }
    return prev;
  }, [] as AccountType[]);

  const pensionMoveStatusesInTransfer = transfer.moves.reduce((prev, move) => {
    if (move.state) {
      prev.push(move.state);
    }
    return prev;
  }, [] as PensionMoveStatus[]);

  const hasManualSigning = transfer.moves.some(
    (move) => move.signing === PensionMoveSigningOptions.MANUAL
  );

  return (
    <article className="transfer-pension-done">
      <section className="success">
        <Success />
      </section>
      {state === "loading" ? (
        <Spinner />
      ) : (
        <Heading hasManualSigning={hasManualSigning} />
      )}
      <hr className="divider" />
      <MoveSteps
        accountTypes={accountTypesInTransfer}
        pensionMoveStatuses={pensionMoveStatusesInTransfer}
      />
      <MoveTimeInfo />
      <Button
        className="cta"
        block
        variant="primary"
        type="button"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.done.button",
        })}
        onClick={next}
      />
    </article>
  );
};

const Heading = ({ hasManualSigning }: { hasManualSigning: boolean }) => {
  return hasManualSigning ? (
    <>
      <Typography type="h2" className="header">
        <TranslatedText id="sweden.transfer-pension.done.manual.header" />
      </Typography>
      <Ingress>
        <TranslatedText id="sweden.transfer-pension.done.manual.ingress" />
      </Ingress>
    </>
  ) : (
    <>
      <Typography type="h2" className="header">
        <TranslatedText id="sweden.transfer-pension.done.header" />
      </Typography>
      <Ingress>
        <TranslatedText id="sweden.transfer-pension.done.ingress" />
      </Ingress>
    </>
  );
};
