import { LysaCountry } from "@lysaab/shared";
import { DocModal } from "@lysaab/ui-2";
import { useContext, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { TranslatedText } from "../../components/TranslatedText";
import { LocalizationContext } from "../../context/LocalizationContext";
import { UserContext } from "../../context/UserContext";
import { CompoundAccountId } from "../../data/dataAccounts";

interface DocumentVariables {
  accountId: CompoundAccountId;
  accountName: string;
  ownerName: string;
}

interface Props {
  documentVariables: DocumentVariables;
}

const messages = defineMessages({
  header: {
    id: "shareAccountInvitation.docs.header",
  },
  placeholder: {
    id: "shareAccountInvitation.docs.placeholder",
  },
  modalAnnouncement: {
    id: "shareAccountInvitation.docs.modalAnnouncement",
  },
});

export const documentList: Record<LysaCountry, string[]> = {
  [LysaCountry.DENMARK]: [
    "legal/all/en/shared-account.md",
    "legal/all/en/privacy-note.md",
  ],
  [LysaCountry.FINLAND]: [
    "legal/all/en/shared-account.md",
    "legal/all/en/privacy-note.md",
  ],
  [LysaCountry.GERMANY]: [
    "legal/all/en/shared-account.md",
    "legal/all/en/privacy-note.md",
  ],
  [LysaCountry.SPAIN]: [],
  [LysaCountry.SWEDEN]: [
    "legal/se/sv/shared-account.md",
    "legal/se/sv/personuppgiftspolicy.md",
  ],
};

export const ShareAccountInvitationDocs: React.FC<Props> = ({
  documentVariables,
}) => {
  const [document, setDocument] = useState<string>();
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const {
    state: { name },
  } = useContext(UserContext);
  const { country } = localizationContext.state;

  if (typeof country === "undefined" || typeof name === "undefined") {
    return null;
  }

  const variables = {
    SIGNING_DATE: intl.formatDate(new Date()),
    ACCOUNT_NAME: documentVariables.accountName,
    ACCOUNT_ID: documentVariables.accountId || "",
    OWNER_NAME: documentVariables.ownerName,
    INVITEE_NAME: name,
  };

  return (
    <>
      <DocModal
        header={intl.formatMessage(messages.header)}
        active={!!document}
        onRequestClose={() => setDocument(undefined)}
        variableValues={variables}
        docName={document}
        selectPlaceholder={intl.formatMessage(messages.placeholder)}
        docList={documentList[country]}
        modalAnnouncement={intl.formatMessage(messages.modalAnnouncement)}
      />
      <p>
        <button
          className="as-link"
          type="button"
          onClick={() => {
            setDocument(documentList[country][0]);
          }}
        >
          <FormattedMessage id="shareAccountInvitation.docs.documentLink" />
        </button>
      </p>

      <ul>
        <li>
          <FormattedMessage id="shareAccountInvitation.docs.acceptDisclaimer1" />
        </li>
        <li>
          <TranslatedText
            id="shareAccountInvitation.docs.acceptDisclaimer2"
            values={{
              policy: (parts) => (
                <button
                  className="as-link"
                  type="button"
                  onClick={() => {
                    setDocument(documentList[country][1]);
                  }}
                >
                  {parts}
                </button>
              ),
            }}
          />
        </li>
      </ul>
    </>
  );
};
