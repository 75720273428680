import {
  ValidationResponse,
  VALIDATION_STATE,
  BaseValidator,
} from "@lysaab/ui-2";

export class UniqueValidator<T> extends BaseValidator {
  protected name = "UniqueValidator";

  constructor(private listOfItems: T[], error: string) {
    super(error);
  }

  getId() {
    return this.getBaseId();
  }

  validate(value: T): Promise<ValidationResponse> {
    return new Promise((resolve) => {
      if (this.listOfItems.includes(value)) {
        resolve({ status: VALIDATION_STATE.FAILED, message: this.error });
      } else {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      }
    });
  }
}
