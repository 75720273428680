import {
  Card,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import { useEffect, useState, VoidFunctionComponent } from "react";
import {
  InvestmentAccount,
  SavingsAccount,
  isSavingsAccount,
  dataAccounts,
  isPensionAccountType,
} from "../../../data/dataAccounts";
import { TranslatedText } from "../../../components/TranslatedText";
import { useIsReadOnly } from "../../../hooks/useIsReadOnly";
import { CloseAccountRow } from "./components/closeAccountRow/CloseAccountRow";
import { useInvestmentAccountCloseable } from "../../../hooks/useInvestmentAccountCloseable";
import { useSavingsAccountCloseable } from "../../../hooks/useSavingsAccountCloseable";

import "./CloseAccount.scss";

export const CloseAccount: VoidFunctionComponent = () => {
  const isReadonly = useIsReadOnly();
  const [accounts, setAccounts] = useState<
    (InvestmentAccount | SavingsAccount)[]
  >([]);
  const getInvestmentAccountCloseable = useInvestmentAccountCloseable();
  const getSavingsAccountCloseable = useSavingsAccountCloseable();

  useEffect(() => {
    dataAccounts.getAllAccounts().then((accounts) => {
      setAccounts([
        ...accounts.investmentAccounts.filter(
          (account) => !isPensionAccountType(account.type)
        ),
        ...accounts.savingsAccounts,
      ]);
    });
  }, []);

  const isError =
    getInvestmentAccountCloseable === "ERROR" ||
    getSavingsAccountCloseable === "ERROR";

  if (isError) {
    return (
      <section>
        <Typography type="h2">
          <TranslatedText id="endInvestment.closeAccount.header" />
        </Typography>
        <Card className="close-account-error">
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="endInvestment.closeAccount.error.noAccounts" />
          </Snackbar>
        </Card>
      </section>
    );
  }

  if (isReadonly) {
    return (
      <section>
        <Typography type="h2">
          <TranslatedText id="endInvestment.closeAccount.header" />
        </Typography>
        <Card className="close-account-error">
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="endInvestment.closeAccount.error.readOnly" />
          </Snackbar>
        </Card>
      </section>
    );
  }

  const isLoading =
    getInvestmentAccountCloseable === "LOADING" ||
    getSavingsAccountCloseable === "LOADING";

  return (
    <section>
      <Typography type="h2">
        <TranslatedText id="endInvestment.closeAccount.header" />
      </Typography>
      <Card className="close-account">
        {isLoading ? (
          <Spinner />
        ) : (
          accounts.map((account) => {
            const accountDisabledState = isSavingsAccount(account)
              ? getSavingsAccountCloseable(account.accountId)
              : getInvestmentAccountCloseable(account.accountId);

            if (!accountDisabledState) {
              throw new Error("CloseAccount - No disabled state for account");
            }

            return (
              <div
                className="close-account__row-wrapper"
                key={account.accountId}
              >
                <CloseAccountRow
                  account={account}
                  disabledState={accountDisabledState}
                />
              </div>
            );
          })
        )}
      </Card>
    </section>
  );
};
