import { API } from "@lysaab/ui-2";
import { AccountPosition } from "./dataAccounts";

export type Isin = string & { readonly isIsin: true };

export enum FundType {
  STOCKS = "STOCKS",
  BONDS = "BONDS",
}

export interface Fund {
  depotId: string;
  fundShareClasses: Instrument[];
  largePositions: LargePosition[];
  remainingPositions: SmallPosition[];
  remainingPositionsPercent: number;
  fundType: FundType;
  isin?: Isin;
}

export interface PositionWithFund extends AccountPosition {
  fund?: Fund;
  name?: string;
}

export interface BasePosition {
  instruments: Instrument[];
}

export interface Instrument {
  isin: Isin;
  name: string;
}

export interface LargePosition extends BasePosition {
  percent: number;
}

export type SmallPosition = BasePosition;

export const dataFunds = {
  getHoldings: () => {
    return API.get<Fund[]>("/funds/data/summary");
  },
};
