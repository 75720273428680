import {
  Fragment,
  useContext,
  FunctionComponent,
  useRef,
  useState,
  useEffect,
} from "react";
import { IskTransferContext, TypeOfTransfer } from "./IskTransferContext";
import {
  Card,
  Button,
  Form,
  Checkbox,
  RequiredValidator,
  LysaFormRef,
  Spinner,
  Snackbar,
  SNACKBAR_TYPES,
  Alternative,
  ServerError,
} from "@lysaab/ui-2";
import { ISK_TRANSFER_URL } from "./IskTransferStory";
import { useHistory } from "react-router-dom";
import { dataIsk } from "../../../../data/dataIsk";
import {
  FormattedNumber,
  FormattedMessage,
  defineMessages,
  useIntl,
} from "react-intl";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import { getNavLink } from "../../../../hooks/useCountryUrls";

interface Props {
  next: (transferId: number) => void;
}

const messages = defineMessages({
  terms: {
    id: "sweden.isk.move.confirm.terms.text",
    defaultMessage:
      "I confirm the above transfer assignment to the specified investment savings account with Lysa and hereby authorize Lysa to carry out the assignment for transfer.",
  },
  termsRequired: {
    id: "sweden.isk.move.confirm.terms.required",
    defaultMessage: "You have to accept",
  },
});

export const IskConfirm: FunctionComponent<Props> = ({ next }) => {
  const iskTransferContext = useContext(IskTransferContext);
  const formRef = useRef<LysaFormRef>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<number>();
  const history = useHistory();
  const [institutions, setInstitutions] = useState<Alternative<string>[]>([]);
  const localizationContext = useContext(LocalizationContext);
  const intl = useIntl();

  useEffect(() => {
    if (!iskTransferContext.state.type) {
      history.push(getNavLink(ISK_TRANSFER_URL));
    }
  }, [history, iskTransferContext.state.type]);

  useEffect(() => {
    dataIsk.getInstitutions().then((resp) => setInstitutions(resp));
  }, []);

  const save = () => {
    if (
      !iskTransferContext.state.account ||
      !iskTransferContext.state.accountNumber ||
      !iskTransferContext.state.institution ||
      !iskTransferContext.state.type
    ) {
      return;
    }
    setIsLoading(true);
    dataIsk
      .saveTransfer({
        accountId: iskTransferContext.state.account.value,
        accountNumber: iskTransferContext.state.accountNumber,
        amount:
          iskTransferContext.state.type.value === TypeOfTransfer.PARTIAL
            ? Number(iskTransferContext.state.amount || "0")
            : undefined,
        fullTransfer:
          iskTransferContext.state.type.value === TypeOfTransfer.FULL,
        institute: iskTransferContext.state.institution.value,
        closeAccount: iskTransferContext.state.closeAccount,
      })
      .then((resp) => {
        iskTransferContext.setState({ transferId: resp.transferId });
        setTimeout(() => next(resp.transferId), 0);
      })
      .catch((error: ServerError<any>) => {
        setIsLoading(false);
        setError(error.status);
      });
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <h2>
        <FormattedMessage
          id="sweden.isk.move.confirm.header"
          defaultMessage="Confirm ISK transfer"
        />
      </h2>
      <Card>
        <table className="confirm-table">
          <tbody>
            <tr>
              <td>
                <b>
                  <FormattedMessage
                    id="sweden.isk.move.confirm.institution"
                    defaultMessage="Instituation:"
                  />
                </b>
              </td>
              <td>
                {
                  institutions.find(
                    (inst) =>
                      inst.value === iskTransferContext.state.institution?.value
                  )?.text
                }
              </td>
            </tr>
            <tr>
              <td>
                <b>
                  <FormattedMessage
                    id="sweden.isk.move.confirm.accountno"
                    defaultMessage="Account number:"
                  />
                </b>
              </td>
              <td>{iskTransferContext.state.accountNumber}</td>
            </tr>
            <tr>
              <td>
                <b>
                  <FormattedMessage
                    id="sweden.isk.move.confirm.lysa-account"
                    defaultMessage="Lysa account:"
                  />
                </b>
              </td>
              <td>{iskTransferContext.state.account?.text}</td>
            </tr>
            {iskTransferContext.state.type?.value === TypeOfTransfer.FULL && (
              <Fragment>
                <tr>
                  <td>
                    <b>
                      <FormattedMessage
                        id="sweden.isk.move.confirm.full.task"
                        defaultMessage="Task:"
                      />
                    </b>
                  </td>
                  <td>
                    <FormattedMessage
                      id="sweden.isk.move.confirm.full.description"
                      defaultMessage="Sell all assets and move the liquidity to Lysa"
                    />
                  </td>
                </tr>
              </Fragment>
            )}
            {iskTransferContext.state.type?.value ===
              TypeOfTransfer.PARTIAL && (
              <Fragment>
                <tr>
                  <td>
                    <b>
                      <FormattedMessage
                        id="sweden.isk.move.confirm.partial.task"
                        defaultMessage="Task:"
                      />
                    </b>
                  </td>

                  <td>
                    {" "}
                    <FormattedMessage
                      id="sweden.isk.move.confirm.partial.description"
                      defaultMessage="Move an amount"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <FormattedMessage
                        id="sweden.isk.move.confirm.partial.amount"
                        defaultMessage="Amount:"
                      />
                    </b>
                  </td>
                  <td>
                    <FormattedNumber
                      currency={localizationContext.state.currency}
                      value={parseInt(
                        iskTransferContext.state.amount || "0",
                        10
                      )}
                      // eslint-disable-next-line react/style-prop-object
                      style="currency"
                    />
                  </td>
                </tr>
              </Fragment>
            )}
            {iskTransferContext.state.type?.value === TypeOfTransfer.FULL && (
              <tr>
                <td>
                  <b>
                    <FormattedMessage
                      id="sweden.isk.move.confirm.close.label"
                      defaultMessage="Close account:"
                    />
                  </b>
                </td>
                <td>
                  {iskTransferContext.state.closeAccount ? (
                    <FormattedMessage
                      id="sweden.isk.move.confirm.close.yes"
                      defaultMessage="Yes"
                    />
                  ) : (
                    <FormattedMessage
                      id="sweden.isk.move.confirm.close.no"
                      defaultMessage="No"
                    />
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Card>
      <Form
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            return;
          }

          save();
        }}
        lysaFormRef={formRef}
      >
        <Checkbox
          alternative={{
            text: intl.formatMessage(messages.termsRequired),
            value: true,
          }}
          validators={[
            new RequiredValidator(intl.formatMessage(messages.termsRequired)),
          ]}
          checked={!!iskTransferContext.state.acceptedTerms?.value}
          onChange={(term) => {
            iskTransferContext.setState({
              acceptedTerms: {
                ...term,
                value: !iskTransferContext.state.acceptedTerms?.value,
              },
            });
          }}
        />
        {error && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <div>
              <h3>
                <FormattedMessage
                  id="sweden.isk.move.confirm.error.header"
                  defaultMessage="Something went wrong"
                />
              </h3>
              {error === 409 ? (
                <p>
                  <FormattedMessage
                    id="sweden.isk.move.confirm.error.duplicate"
                    defaultMessage="You already have an active transfer from account {account}"
                    values={{
                      account: iskTransferContext.state.accountNumber,
                    }}
                  />
                </p>
              ) : (
                <p>
                  <FormattedMessage
                    id="sweden.isk.move.confirm.error.description"
                    defaultMessage="Try again later"
                  />
                </p>
              )}
            </div>
          </Snackbar>
        )}
        <Button
          block
          type="submit"
          label={<FormattedMessage id="sweden.isk.move.confirm.button.sign" />}
        />
      </Form>
    </div>
  );
};
