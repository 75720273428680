import { VoidFunctionComponent } from "react";
import {
  AllocationBarIntl,
  Card,
  Icon,
  InvestmentType,
  RiskIndicator,
} from "@lysaab/ui-2";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { FormattedPercentage } from "../../components/formattedPercentage/FormattedPercentage";
import { AccountType } from "../../data/dataAccounts";
import { SavingsHorizonLength } from "../../data/dataInvestments";

import "./ConfirmationSummarySection.scss";

interface MessageWithId {
  id: string;
}

const messages = defineMessages({
  allocationBarStocks: {
    id: "confirmation.card.bar.stocks",
  },
  allocationBarBonds: {
    id: "confirmation.card.bar.bonds",
  },
});

const accountTypeMessages = defineMessages<
  AccountType,
  MessageWithId,
  Record<AccountType, MessageWithId>
>({
  [AccountType.VP]: {
    id: "confirmation.card.account.VP",
  },
  [AccountType.ISK_SWE]: {
    id: "confirmation.card.account.ISK_SWE",
  },
  [AccountType.VP_SWE]: {
    id: "confirmation.card.account.VP_SWE",
  },
  [AccountType.TJP_SWE]: {
    id: "confirmation.card.account.TJP_SWE",
  },
  [AccountType.DANICA_KF]: {
    id: "confirmation.card.account.DANICA_KF",
  },
  [AccountType.KF_SWE]: {
    id: "confirmation.card.account.KF_SWE",
  },
  [AccountType.LYSA_TJP]: {
    id: "confirmation.card.account.LYSA_TJP",
  },
  [AccountType.LYSA_PPF]: {
    id: "confirmation.card.account.LYSA_PPF",
  },
});

const horizonLengtMessages = defineMessages<
  SavingsHorizonLength,
  MessageWithId,
  Record<SavingsHorizonLength, MessageWithId>
>({
  [SavingsHorizonLength.SHORT]: {
    id: "confirmation.card.horizon.SHORT",
  },
  [SavingsHorizonLength.MIDDLE]: {
    id: "confirmation.card.horizon.MIDDLE",
  },
  [SavingsHorizonLength.KINDA_LONG]: {
    id: "confirmation.card.horizon.KINDA_LONG",
  },
  [SavingsHorizonLength.LONG]: {
    id: "confirmation.card.horizon.LONG",
  },
  [SavingsHorizonLength.VERY_LONG]: {
    id: "confirmation.card.horizon.VERY_LONG",
  },
});

const investmentTypeMessages = defineMessages<
  InvestmentType,
  MessageWithId,
  Record<InvestmentType, MessageWithId>
>({
  [InvestmentType.BROAD]: {
    id: "confirmation.summary.card.investment.BROAD",
  },
  [InvestmentType.SUSTAINABLE]: {
    id: "confirmation.summary.card.investment.SUSTAINABLE",
  },
});

interface Props {
  risk: number;
  horizonLength: SavingsHorizonLength;
  accountType: AccountType;
  investmentType: InvestmentType;
  showInvestmentType?: boolean;
}

export const ConfirmationSummarySection: VoidFunctionComponent<Props> = ({
  risk,
  horizonLength,
  accountType,
  investmentType,
  showInvestmentType,
}) => {
  const intl = useIntl();

  const allocationBarLabels = {
    stocks: (
      <>
        <span className="allocation-bar-value">
          <FormattedPercentage value={risk} />
        </span>
        <span className="allocation-bar-name">
          {intl.formatMessage(messages.allocationBarStocks)}
        </span>
      </>
    ),
    bonds: (
      <>
        <span className="allocation-bar-name">
          {intl.formatMessage(messages.allocationBarBonds)}
        </span>
        <span className="allocation-bar-value">
          <FormattedPercentage value={100 - risk} />
        </span>
      </>
    ),
  };

  return (
    <section className="confirmation-summary">
      <Card>
        <h4>
          <Icon.Chart />
          <FormattedMessage id="confirmation.card.header" />
        </h4>

        <dl>
          <dt>
            <FormattedMessage id="confirmation.card.account.type" />
          </dt>
          <dd>{intl.formatMessage(accountTypeMessages[accountType])}</dd>
          <dt>
            <FormattedMessage id="confirmation.card.horizon" />
          </dt>
          <dd>{intl.formatMessage(horizonLengtMessages[horizonLength])}</dd>
          {showInvestmentType && (
            <>
              <dt>
                <FormattedMessage id="confirmation.card.investment.type" />
              </dt>
              <dd className="confirmation-summary__risk-indicator">
                {intl.formatMessage(investmentTypeMessages[investmentType])}
                <RiskIndicator risk={risk} investmentType={investmentType} />
              </dd>
            </>
          )}
        </dl>

        <div>
          <FormattedMessage id="confirmation.card.bar" />
        </div>

        <AllocationBarIntl risk={risk} messages={allocationBarLabels} />
      </Card>
    </section>
  );
};
