import { defineMessages, useIntl } from "react-intl";
import { GridCol } from "../../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../../components/grid/gridRow/GridRow";
import { TranslatedText } from "../../../../components/TranslatedText";
import { Page, PageHeader } from "../../../../pages/Page";
import "./TaxInformationPage.scss";

const messages = defineMessages({
  taxRates: {
    id: "spain.taxinformation.links.tax-rates",
  },
  taxAgencyWebsite: {
    id: "spain.taxinformation.links.tax-agency",
  },
  contactDetails: {
    id: "spain.taxinformation.links.contact-details",
  },
});

export const TaxInformationPage = () => {
  const intl = useIntl();

  return (
    <Page>
      <PageHeader>
        <h1>
          <TranslatedText id="spain.taxinformation.header" />
        </h1>
      </PageHeader>
      <div className="spain-tax-information">
        <GridRow>
          <GridCol xsmall={12}>
            <h2>
              <TranslatedText id="spain.taxinformation.how-will-i-be-taxed.header" />
            </h2>
            <TranslatedText
              id="spain.taxinformation.how-will-i-be-taxed.text"
              values={{
                paragraph: (parts) => <p>{parts}</p>,
                taxRatesLink: (parts) => (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={intl.formatMessage(messages.taxRates)}
                  >
                    {parts}
                  </a>
                ),
                agencyLink: (parts) => (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={intl.formatMessage(messages.taxAgencyWebsite)}
                  >
                    {parts}
                  </a>
                ),
              }}
            />
            <h2>
              <TranslatedText id="spain.taxinformation.how-lysa-report-tax.header" />
            </h2>
            <TranslatedText
              id="spain.taxinformation.how-lysa-report-tax.text"
              values={{
                paragraph: (parts) => <p>{parts}</p>,
              }}
            />
            <div className="disclaimer">
              <p>
                <TranslatedText
                  id="spain.taxinformation.disclaimer"
                  values={{
                    link: (parts) => (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={intl.formatMessage(messages.contactDetails)}
                      >
                        {parts}
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          </GridCol>
        </GridRow>
      </div>
    </Page>
  );
};
