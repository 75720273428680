import { FunctionComponent, useEffect, useState } from "react";
import {
  AccountType,
  InvestmentAccountId,
} from "../../../../data/dataAccounts";
import { WithdrawalKFAccount, dataDanica } from "../../../../data/dataDanica";
import { useIsPerson } from "../../../../hooks/useIsPerson";
import { SNACKBAR_TYPES, Snackbar } from "@lysaab/ui-2";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { MIGRATION_REQUEST_KF_PAGE_URL } from "../../../../countries/sweden/pages/corporation/migration/request/MigrationKfRequestPage";
import { Link } from "react-router-dom";
import { useAccounts } from "../../../../hooks/useAccounts";
import "./KfMigrationInfo.scss";

interface Props {
  accountId?: InvestmentAccountId;
}

export const KfMigrationInfo: FunctionComponent<Props> = ({ accountId }) => {
  const isPerson = useIsPerson();
  const [withdrawalAccounts, setWithdrawalAccounts] =
    useState<WithdrawalKFAccount[]>();
  const accounts = useAccounts();

  useEffect(() => {
    if (isPerson) {
      return;
    }
    dataDanica.getWithdrawalKFAccounts().then(setWithdrawalAccounts);
  }, [isPerson]);

  if (isPerson || !withdrawalAccounts || !accounts.accounts) {
    return null;
  }

  if (accountId) {
    const withdrawalaccount = withdrawalAccounts.find(
      (account) => account.accountId === accountId
    );
    const account = accounts.accounts.investmentAccounts.find(
      (account) => account.accountId === accountId
    );

    if (
      !withdrawalaccount ||
      withdrawalaccount.pendingMove ||
      withdrawalaccount.pendingWithdrawal ||
      !account ||
      account.worth === 0
    ) {
      return null;
    }

    return <KfSnackbar />;
  }

  const moveableAccounts = accounts.accounts.investmentAccounts.filter(
    (account) => {
      if (account.type !== AccountType.DANICA_KF) {
        return false;
      }

      const withdrawalAccount = withdrawalAccounts.find(
        (withdrawalAccount) => withdrawalAccount.accountId === account.accountId
      );

      if (
        !withdrawalAccount ||
        withdrawalAccount.pendingMove ||
        withdrawalAccount.pendingWithdrawal ||
        account.worth === 0
      ) {
        return false;
      }

      return true;
    }
  );

  if (moveableAccounts.length === 0) {
    return null;
  }

  return <KfSnackbar />;
};

function KfSnackbar() {
  return (
    <div className="kf-migration-info">
      <Snackbar type={SNACKBAR_TYPES.INFO}>
        <div className="kf-migration-info-text">
          Du har väl inte missat att du måste flytta din kapitalförsäkring till
          Lysa Life? Flytta din försäkring senast <strong>15/12 2024</strong> så
          ger vi dig rabatt på försäkringsavgiften (0,2 %) under hela 2025. Om
          inte kommer din/dina kapitalförsäkring/ar avvecklas.{" "}
          <Link to={getNavLink(MIGRATION_REQUEST_KF_PAGE_URL)}>Flytta här</Link>
        </div>
      </Snackbar>
    </div>
  );
}
