import { API, cache, encode } from "@lysaab/ui-2";
import { CompoundAccountId, InvestmentAccountId } from "./dataAccounts";
import { banks } from "./dataBanks";

export interface Autogiro {
  id: string;
  accountId: string;
  amount: number;
  nextDeduction: string;
  externalBankAccount: string;
  created: string;
  bank: keyof typeof banks;
}

export enum PaymentStatus {
  WAITING_CONSENT = "WAITING_CONSENT",
  WAITNG_DISPATCH = "WAITNG_DISPATCH",
  SENT = "SENT",
}

export function isPaymentStatus(value: unknown): value is PaymentStatus {
  return (
    typeof value === "string" &&
    Object.values(PaymentStatus).some((v) => value === v)
  );
}

interface AutogiroCorporateFeeDeductionResponse {
  transactionId: string;
  amount: number;
  created: string;
  paymentStatus: PaymentStatus;
  monthlyPayment: false;
}

export interface AutogiroPaymentResponse {
  transactionId: string;
  accountId: InvestmentAccountId;
  amount: number;
  created?: string;
  markedOngoing?: string;
  deductionDate?: string;
  monthlyPayment: boolean;
  paymentStatus: PaymentStatus;
}

interface PeriodicDepositResponse {
  accountId: InvestmentAccountId;
  amount: number;
  nextDeduction: string;
  externalBankAccount: string;
  isSkipped: boolean;
}

interface PeriodicDepositRequest {
  amount: number;
  externalBankAccount: string;
  accountId: CompoundAccountId;
  depositDay: number;
}

export interface Consent {
  externalBankAccount: string;
  bank: keyof typeof banks;
  approved: boolean;
  created: string;
}

export enum CommentCode {
  REMOVED_BY_PAYER_OR_HIS_BANK = "REMOVED_BY_PAYER_OR_HIS_BANK",
  ACCOUNT_TYPE_NOT_VALID_FOR_AUTOGIRO = "ACCOUNT_TYPE_NOT_VALID_FOR_AUTOGIRO",
  INVALID_ACCOUNT_DETAILS = "INVALID_ACCOUNT_DETAILS",
  REMOVED_DUE_TO_MISSING_ACCOUNT_REPLY = "REMOVED_DUE_TO_MISSING_ACCOUNT_REPLY",
  INVALID_ACCOUNT_NUMBER = "INVALID_ACCOUNT_NUMBER",
  REMOVED_CONSENT = "REMOVED_CONSENT",
  CUSTOMER_CLOSED = "CUSTOMER_CLOSED",
  INTERNAL = "INTERNAL",
}
export interface HistoricConsent {
  externalBankAccount: string;
  bank: keyof typeof banks;
  created: string;
  deleted: string;
  commentCode: CommentCode;
}
export interface SinglePayment {
  externalBankAccount: string;
  amount: number;
}

export const dataAutogiro = {
  getAutogiros: () => {
    return API.get<Autogiro[]>("/autogiro");
  },

  getPendingAutogiroPayments: () => {
    return API.get<
      (AutogiroPaymentResponse | AutogiroCorporateFeeDeductionResponse)[]
    >("/autogiro/payments/pending");
  },

  getConsents: () => {
    return API.get<Consent[]>("/autogiro/consents");
  },

  getHistoricConsents: () => {
    return API.get<HistoricConsent[]>("/autogiro/consents/historic");
  },

  addAutogiro: (externalBankAccount: string) => {
    return API.post<Consent[]>("/autogiro/consent", {
      externalBankAccount,
    }).then((response) => {
      cache.delete("/autogiro");
      cache.delete("/withdrawal/external-accounts");
      return response;
    });
  },

  addPayment: (accountId: CompoundAccountId, payment: SinglePayment) => {
    return API.post<AutogiroPaymentResponse>(
      encode`/autogiro/${accountId}/payment`,
      payment
    ).then((response) => {
      cache.delete("/autogiro");
      cache.delete("/monthly-payments");
      return response;
    });
  },

  removePayment: (id: string) => {
    return API.delete<boolean>(encode`/autogiro/payments/pending/${id}`).then(
      (response) => {
        cache.delete("/autogiro/payments/pending");
        return response;
      }
    );
  },

  createNewPeriodicDeposit: (
    periodicDepositRequest: PeriodicDepositRequest
  ) => {
    return API.post<PeriodicDepositResponse[]>(
      encode`/autogiro`,
      periodicDepositRequest
    ).then((response) => {
      cache.delete("/autogiro");
      cache.delete("/monthly-payments");
      return response;
    });
  },
};

export function getConsentBankText(consent: Consent) {
  if (consent.bank === "UNKNOWN" || !((consent.bank as string) in banks)) {
    return consent.externalBankAccount;
  }
  return `${consent.externalBankAccount} - ${banks[consent.bank].long}`;
}

export const isAutogiroCorporateFeeDeductionResponse = (
  pendingPayment:
    | AutogiroPaymentResponse
    | AutogiroCorporateFeeDeductionResponse
): pendingPayment is AutogiroCorporateFeeDeductionResponse => {
  return (
    typeof (pendingPayment as AutogiroPaymentResponse).accountId === "undefined"
  );
};
