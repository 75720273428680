import { Button, Snackbar, SNACKBAR_TYPES, Spinner } from "@lysaab/ui-2";
import { LayoutGroup, motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ShareAccountInviteCard } from "../../components/shareAccountInviteCard/ShareAccountInviteCard";
import {
  dataAttorney,
  isShareAccountInvitationInFinalState,
  ShareAccountInvitationId,
} from "../../data/dataAttorney";
import { getNavLink } from "../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../hooks/useSafeNavigation";
import { OVERVIEW_PAGE_URL } from "../overview/OverviewPage";
import {
  ShareAccountInvitationsView,
  SHARE_ACCOUNT_INVITATIONS_VIEW_URL,
} from "../shareAccountInvitations/ShareAccountInvitationsPage";
import { useShareAccountContext } from "./ShareAccountContext";

export const ShareAccountDone: React.VFC = () => {
  const { state: shareAccountState, setState: setShareAccountState } =
    useShareAccountContext();
  const [error, setError] = useState(false);
  const timer = useRef<NodeJS.Timeout>();

  const getInvitation = useCallback(
    (invitationId: ShareAccountInvitationId) => {
      return dataAttorney.getSentInvitation(invitationId).then((invitation) => {
        setShareAccountState({ invitation });
        return invitation;
      });
    },
    [setShareAccountState]
  );

  const poll = useCallback(
    (invitationId: ShareAccountInvitationId) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        getInvitation(invitationId).then((invitation) => {
          if (!isShareAccountInvitationInFinalState(invitation)) {
            poll(invitationId);
          }
        });
      }, 3000);
    },
    [getInvitation]
  );

  useEffect(() => {
    if (typeof shareAccountState.account === "undefined") {
      setError(true);
      return;
    }
    if (typeof shareAccountState.invitation !== "undefined") {
      poll(shareAccountState.invitation.invitationId);
      return;
    }

    dataAttorney
      .createInvitation(shareAccountState.account.accountId)
      .then((invitation) => {
        setShareAccountState({ invitation });
        poll(invitation.invitationId);
      })
      .catch(() => {
        setError(true);
      });
  }, [
    poll,
    setShareAccountState,
    shareAccountState.account,
    shareAccountState.invitation,
  ]);

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  if (error) {
    return (
      <div>
        <h2>
          <FormattedMessage id="shareAccountStory.done.header" />
        </h2>
        <Snackbar type={SNACKBAR_TYPES.ERROR}>
          <FormattedMessage id="shareAccountStory.done.error" />
        </Snackbar>
        <Buttons />
      </div>
    );
  }

  if (typeof shareAccountState.invitation === "undefined") {
    return <Spinner />;
  }

  return (
    <div>
      <LayoutGroup id="asdf">
        <ShareAccountInviteCard
          invitation={shareAccountState.invitation}
          refreshInvitation={getInvitation}
        />
        <motion.div layout>
          <Buttons />
        </motion.div>
      </LayoutGroup>
    </div>
  );
};

const Buttons: React.VFC = () => {
  const safeNavigation = useSafeNavigation();
  return (
    <>
      <Button
        variant="primary"
        block
        onClick={() => safeNavigation(getNavLink(OVERVIEW_PAGE_URL))}
        label={<FormattedMessage id="shareAccountStory.done.overviewButton" />}
      />
      <Button
        variant="secondary"
        block
        onClick={() =>
          safeNavigation(
            getNavLink(
              SHARE_ACCOUNT_INVITATIONS_VIEW_URL(
                ShareAccountInvitationsView.SENT
              )
            )
          )
        }
        label={<FormattedMessage id="shareAccountStory.done.viewAllButton" />}
      />
    </>
  );
};
