import { TranslatedText } from "../../components/TranslatedText";

export const NewCustomerGreetingAccount = () => (
  <TranslatedText
    id="newCustomerAccount.text"
    values={{
      p: (parts) => <p>{parts}</p>,
    }}
  />
);
