import { Card, Snackbar, SNACKBAR_TYPES, Spinner } from "@lysaab/ui-2";
import { useEffect, useState, VoidFunctionComponent } from "react";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { CrsSwedishResponse, dataCrs } from "../../../../../../data/dataCrs";
import {
  dataLegalEntity,
  EntityDetailsResponse,
} from "../../../../../../data/dataLegalEntity";
import { EntityDetailsInformation } from "./EntityDetailsInformation";
import "./CurrentCrsInformation.scss";

export const CurrentCrsInformation: VoidFunctionComponent = () => {
  const [serverCrs, setServerCrs] = useState<CrsSwedishResponse>();
  const [entityDetails, setEntityDetails] = useState<EntityDetailsResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const profileRequest = dataLegalEntity
      .getProfileSettings()
      .then((response) => {
        setEntityDetails(response);
      });

    const crsRequest = dataCrs
      .getLatestCrsSwedish()
      .then((response) => {
        setServerCrs(response);
      })
      .catch(() => setError(true));

    Promise.all([profileRequest, crsRequest]).finally(() =>
      setIsLoading(false)
    );
  }, []);

  if (isLoading) return <Spinner />;

  return (
    <div className="current-crs-corp-information">
      <h2>
        <TranslatedText id="sweden.crs.corp.currentCrsInformation.header" />
      </h2>
      <Card>
        {error && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="sweden.crs.corp.currentCrsInformation.error" />
          </Snackbar>
        )}

        {serverCrs !== undefined && (
          <dl>
            {entityDetails && (
              <EntityDetailsInformation
                entityDetails={entityDetails.details}
                entityType={entityDetails.entityType}
              />
            )}
          </dl>
        )}
      </Card>
    </div>
  );
};
