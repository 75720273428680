import { Language } from "@lysaab/shared";
import {
  API,
  BankIdInitResponse,
  BankIdStandardResponses,
  OrderRef,
  encode,
} from "@lysaab/ui-2";
import {
  AccountType,
  InvestmentAccountId,
  InvestmentType,
} from "./dataAccounts";
import {
  InvestmentAccountQuestions,
  SavingsHorizonLength,
} from "./dataInvestments";
import {
  DepositInterval,
  KycQuestion,
  PurposeAccountCorporation,
  WithdrawalInterval,
} from "./dataKyc";

export type InsuranceCreationToken = string & {
  __insuranceCreationToken: never;
};

export enum Precondition {
  OK = "OK",
  PENDING_INSURANCE_APPLICATION = "PENDING_INSURANCE_APPLICATION",
  NOT_SUPPORTED_CORPORATION_TYPE = "NOT_SUPPORTED_CORPORATION_TYPE",
}

interface SupportedResponse {
  precondition: Precondition.OK;
}

interface PendingInsuranceResponse {
  precondition: Precondition.PENDING_INSURANCE_APPLICATION;
  creationToken: InsuranceCreationToken;
}

interface NotSupportedResponse {
  precondition: Precondition.NOT_SUPPORTED_CORPORATION_TYPE;
}

export type GetPreconditionsResponse =
  | SupportedResponse
  | PendingInsuranceResponse
  | NotSupportedResponse;

export interface InsuranceCandicateResponse {
  name: string;
  tin: string;
}

interface Insured {
  identificationNumber: string;
}

export type CreateAccountRequest = InvestmentAccountQuestions & {
  creationToken: InsuranceCreationToken;
  takenRisk: number;
  lysaKyc: {
    version: "3";
    questionAnswers: [
      {
        question: KycQuestion.PURPOSE;
        answers: PurposeAccountCorporation[];
      },
      {
        question: KycQuestion.DEPOSIT_INTERVAL;
        answers: [DepositInterval];
      },
      {
        question: KycQuestion.WITHDRAWAL_INTERVAL;
        answers: WithdrawalInterval[];
      }
    ];
  };
  investmentType: InvestmentType;
  insured: Insured;
  language: Language;
  accountName: string;
};

export interface InitiateSigningRequest {
  id: number;
}

export enum InsuranceCreationState {
  STARTED = "STARTED",
  SIGNING = "SIGNING",
  QUEUED = "QUEUED",
  WAITING_CREATION = "WAITING_CREATION",
  DONE = "DONE",
  FAILED = "FAILED",
}

export enum InsuranceSigningRole {
  /* VD */
  CEO = "CEO",

  /* VICE VD */
  VICE_PRESIDENT = "VICE_PRESIDENT",

  /* EXTERN VICE VD */
  EXTERNAL_VICE_PRESIDENT = "EXTERNAL_VICE_PRESIDENT",

  /* EXTERN VD */
  EXTERNAL_CEO = "EXTERNAL_CEO",

  /* ORDFÖRANDE */
  BOARD_CHAIRMAN = "BOARD_CHAIRMAN",

  /* STYRELSELEDAMOT */
  BOARD_MEMBER = "BOARD_MEMBER",

  /* VICE ORDFÖRANDE */
  VICE_CHAIRMAN = "VICE_CHAIRMAN",

  /* BOLAGSMAN */
  PARTNER = "PARTNER",

  /* STYRELSESUPPLEANT */
  ALTERNATE = "ALTERNATE",

  /* INNEHAVARE */
  PROPRIETOR = "PROPRIETOR",

  /* KOMMANDITDELÄGARE */
  LIMITED_PARTNER = "LIMITED_PARTNER",

  /* EXTERN FIRMATECKNARE */
  EXTERNAL_SIGNATORY = "EXTERNAL_SIGNATORY",

  /* KOMPLEMENTÄR */
  GENERAL_PARTNER = "GENERAL_PARTNER",

  /* HUVUDANSVARIG REVISOR */
  KEY_AUDIT_PARTNER = "KEY_AUDIT_PARTNER",

  /* REVISOR */
  AUDITOR = "AUDITOR",

  /* REVISORSSUPPLEANT */
  DEPUTY_AUDITOR = "DEPUTY_AUDITOR",

  /* AKTUARIE */
  ACTUARY = "ACTUARY",

  // Above should be same as CompanyPosition. Below we add specific roles for insurance signing.

  INSURED = "INSURED",

  /* Okänd */
  UNKNOWN = "UNKNOWN",
}

export enum SignaturesRequired {
  /**
   * Everyone in the group must sign
   */
  ALL = "ALL",
  /**
   * A majority must sign, the chairman breaks ties
   */
  MAJORITY = "MAJORITY",
  /**
   * Only one in the group must sign
   */
  ONE = "ONE",
  /**/
  TWO = "TWO",
  /**/
  THREE = "THREE",
  /**/
  FOUR = "FOUR",
  /**/
  FIVE = "FIVE",
}

export interface SigneeParticipant {
  id: number;
  name: string;
  roles: InsuranceSigningRole[];
  confirmed: boolean;
}

interface SigningGroupResponse {
  signingText: string;
  signaturesRequired: SignaturesRequired;
  signees: SigneeParticipant[];
}

interface SigningSummary {
  accountType: AccountType;
  takenRisk: number;
  investmentType: InvestmentType;
  insured: string;
  savingsHorizon: SavingsHorizonLength;
  policyHolderName: string;
}

export interface CreationStatusResponse {
  token: InsuranceCreationToken;
  state: InsuranceCreationState;
  signingGroups: SigningGroupResponse[];
  summary: SigningSummary;
}

export interface InsuredResponse {
  tin: string;
  birthday: string;
  name: string;
}

export interface TaxResponse {
  paid: string;
  accumulated: string;
}

export interface InvoiceResponse {
  address: string;
  zipCode: string;
  city: string;
}

export type InvoiceRequest = InvoiceResponse;

export const dataLife = {
  getPreconditions: () => {
    return API.get<GetPreconditionsResponse>(
      "/life/kf/check-preconditions",
      true
    );
  },
  getInsuranceCandidates: () => {
    return API.get<InsuranceCandicateResponse[]>(
      "/life/kf/insurance-candidates"
    );
  },
  createAccount: (data: CreateAccountRequest) => {
    return API.post<CreateAccountRequest>(
      "/life/kf/start-account-creation",
      data
    );
  },
  pollCreationStatus: (creationToken: InsuranceCreationToken) => {
    return API.get<CreationStatusResponse>(
      `/life/signing/poll/${creationToken}`,
      true
    );
  },
  initiateSigning: (
    creationToken: InsuranceCreationToken,
    data: InitiateSigningRequest
  ) => {
    return API.post<BankIdInitResponse>(
      `/life/signing/sign/signees?creationToken=${creationToken}`,
      data
    );
  },
  pollSigning: async (
    creationToken: InsuranceCreationToken,
    orderRef: OrderRef
  ) => {
    const response = await API.get<BankIdStandardResponses>(
      `/life/signing/sign/signees/${orderRef}?creationToken=${creationToken}`,
      true
    );

    return { ...response, creationToken };
  },
  pollQrCode: (orderRef?: OrderRef) => {
    if (!orderRef) {
      return Promise.resolve(undefined);
    }

    return API.get<{ code: string }>(
      encode`/life/signing/sign/signees/qr/${orderRef}`,
      true
    );
  },
  getInsured: (accountId: InvestmentAccountId) => {
    return API.get<InsuredResponse>(`/life/${accountId}/insured`);
  },
  getTax: (accountId: InvestmentAccountId) => {
    return API.get<TaxResponse>(`/life/${accountId}/tax`);
  },
  setInvoiceAddress: (data: InvoiceRequest) => {
    return API.post<InvoiceResponse>(`/life/invoice`, data);
  },
  getInvoiceAddress: () => {
    return API.get<InvoiceResponse>(`/life/invoice`);
  },

  shareSigningByEmail: (creationToken: string, email: string) => {
    return API.post(`/life/signing/${creationToken}/invite`, {
      email,
    });
  },
};
