import {
  Button,
  Card,
  Form,
  LysaFormRef,
  Snackbar,
  SNACKBAR_TYPES,
  useFlash,
  WorldCountry,
} from "@lysaab/ui-2";
import { useEffect, useRef, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { CitizenshipFormFields } from "../../../components/citizenshipFormFields/CitizenshipFormFields";
import { TranslatedText } from "../../../components/TranslatedText";
import { dataLegalEntity } from "../../../data/dataLegalEntity";
import { useIsReadOnly } from "../../../hooks/useIsReadOnly";

import "./CitizenshipInformation.scss";

const messages = defineMessages({
  citizenshipInformationSaved: {
    id: "crs.citizenshipInformation.citizenshipInformationSaved",
  },
});

export const CitizenshipInformation: React.VFC = () => {
  const formRef = useRef<LysaFormRef>();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [citizenships, setCitizenships] =
    useState<(WorldCountry | undefined)[]>();
  const pushFlash = useFlash();
  const intl = useIntl();
  const isReadOnly = useIsReadOnly();

  const fetchCitizenships = () => {
    setIsLoading(true);
    dataLegalEntity
      .getProfileSettings()
      .then(({ details }) => {
        setCitizenships(details.citizenshipsIso);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => setIsLoading(false));
  };

  const postCitizenships = () => {
    setIsLoading(true);
    const filteredCitizenships = citizenships?.filter(isWorldCountry);

    if (filteredCitizenships) {
      dataLegalEntity
        .updateCitizenships(filteredCitizenships)
        .then(() => {
          pushFlash({
            text: intl.formatMessage(messages.citizenshipInformationSaved),
            type: SNACKBAR_TYPES.SUCCESS,
          });
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    fetchCitizenships();
  }, []);

  return (
    <div className="citizenship-information">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (formRef.current?.isValid) {
            postCitizenships();
          }
        }}
      >
        <h2>
          <TranslatedText id="crs.citizenshipInformation.header" />
        </h2>
        <Card showLoading={isLoading}>
          {error && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="crs.citizenshipInformation.error" />
            </Snackbar>
          )}
          <CitizenshipFormFields
            citizenships={citizenships}
            setCitizenships={(c) => setCitizenships(c)}
          />
          {typeof citizenships !== "undefined" && (
            <Button
              type="submit"
              block
              disabled={isReadOnly}
              label={
                <TranslatedText id="crs.citizenshipInformation.saveButton" />
              }
            />
          )}
        </Card>
      </Form>
    </div>
  );
};

const isWorldCountry = (
  worldCountry: WorldCountry | undefined
): worldCountry is WorldCountry => {
  return typeof worldCountry !== "undefined";
};
