import { Button, Card, Typography } from "@lysaab/ui-2";
import { Link, useLocation } from "react-router-dom";
import { TransferTypeIcon } from "./components/TransferTypeIcon";
import { WITHDRAWAL_ISK_TO_SAVINGS_PAGE } from "../../../internalRequest/WithdrawalIskToSavingsStory";
import { WITHDRAWALS_REQUEST_PAGE } from "../../../request/WithdrawalStory";
import { WITHDRAWAL_REQUEST_KF_PAGE_URL } from "../../../../../countries/sweden/pages/withdrawal/kf/request/WithdrawalKfRequestPage";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { useEffect, useState } from "react";
import { dataDanica } from "../../../../../data/dataDanica";
import { SAVINGS_ACCOUNT_BASE_ROUTES } from "../../../../../countries/sweden/pages/createAccount/savingsAccount/CreateSavingsAccountStory";

import "./WithdrawalSelectTypeCard.scss";
import {
  FeatureDomain,
  SubDomain,
  dataCustomerTrackingService,
} from "../../../../../data/dataCustomerTracking";

export const ExternalWithdrawalSelectTypeCard = () => {
  const location = useLocation();
  return (
    <Card>
      <div className="header-row">
        <TransferTypeIcon type="EXTERNAL" />
        <Typography type="h3">
          <TranslatedText id="withdrawals.overview.selectType.external.header" />
        </Typography>
      </div>
      <Typography type="body">
        <TranslatedText id="withdrawals.overview.selectType.external.body" />
      </Typography>

      <Button
        block
        component={Link}
        to={{
          pathname: getNavLink(WITHDRAWALS_REQUEST_PAGE),
          state: location.state,
        }}
        label={
          <TranslatedText id="withdrawals.overview.selectType.external.next" />
        }
        onClick={() => {
          dataCustomerTrackingService.postEvent({
            eventName: "clickedWithdrawalToExternalAccount",
            domain: FeatureDomain.TRANSFERS,
            subDomain: SubDomain.WITHDRAWAL,
          });
        }}
      />
    </Card>
  );
};

export const InternalWithdrawalSelectTypeCard = () => {
  const location = useLocation();
  return (
    <Card>
      <div className="header-row">
        <TransferTypeIcon type="SAVINGS_ACCOUNT" />
        <Typography type="h3">
          <TranslatedText id="withdrawals.overview.selectType.internal.header" />
        </Typography>
      </div>
      <Typography type="body">
        <TranslatedText id="withdrawals.overview.selectType.internal.body" />
      </Typography>

      <Button
        block
        component={Link}
        to={{
          pathname: getNavLink(WITHDRAWAL_ISK_TO_SAVINGS_PAGE),
          state: location.state,
        }}
        label={
          <TranslatedText id="withdrawals.overview.selectType.internal.next" />
        }
        onClick={() => {
          dataCustomerTrackingService.postEvent({
            eventName: "clickedWithdrawalToSavingsAccount",
            domain: FeatureDomain.TRANSFERS,
            subDomain: SubDomain.WITHDRAWAL,
          });
        }}
      />
    </Card>
  );
};

export const CreateSavingsAccountWithdrawalSelectTypeCard = () => {
  return (
    <Card>
      <div className="header-row">
        <TransferTypeIcon type="SAVINGS_ACCOUNT" />
        <Typography type="h3">
          <TranslatedText id="withdrawals.overview.selectType.noSavingsAccount.header" />
        </Typography>
      </div>
      <Typography type="body">
        <TranslatedText id="withdrawals.overview.selectType.noSavingsAccount.body" />
      </Typography>

      <Button
        block
        component={Link}
        to={getNavLink(SAVINGS_ACCOUNT_BASE_ROUTES.INFO)}
        label={
          <TranslatedText id="withdrawals.overview.selectType.noSavingsAccount.next" />
        }
        onClick={() => {
          dataCustomerTrackingService.postEvent({
            eventName: "clickedCreateSavingsAccount",
            domain: FeatureDomain.TRANSFERS,
            subDomain: SubDomain.WITHDRAWAL,
          });
        }}
      />
    </Card>
  );
};

export const KFWithdrawalSelectTypeCard = () => {
  const [isKfWithdrawalOpen, setIsKfWithdrawalOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    dataDanica.getIsWithdrawalsClosed().then((res) => {
      if (!res.closed) {
        setIsKfWithdrawalOpen(!res.closed);
      }
    });
  }, []);

  return (
    <Card>
      <div className="header-row">
        <TransferTypeIcon type="KF" />
        <Typography type="h3">
          <TranslatedText id="withdrawals.overview.selectType.kf.header" />
        </Typography>
      </div>
      <Typography type="body">
        <TranslatedText id="withdrawals.overview.selectType.kf.body" />
      </Typography>

      {isKfWithdrawalOpen && (
        <Button
          block
          component={Link}
          to={{
            pathname: getNavLink(WITHDRAWAL_REQUEST_KF_PAGE_URL),
            state: location.state,
          }}
          label={
            <TranslatedText id="withdrawals.overview.selectType.kf.next" />
          }
        />
      )}
    </Card>
  );
};

export const VPWithdrawalSelectTypeCard = () => {
  const location = useLocation();
  return (
    <Card>
      <div className="header-row">
        <TransferTypeIcon type="KF" />
        <Typography type="h3">
          <TranslatedText id="withdrawals.overview.selectType.vp.header" />
        </Typography>
      </div>
      <Typography type="body">
        <TranslatedText id="withdrawals.overview.selectType.vp.body" />
      </Typography>

      <Button
        block
        component={Link}
        to={{
          pathname: getNavLink(WITHDRAWALS_REQUEST_PAGE),
          state: location.state,
        }}
        label={<TranslatedText id="withdrawals.overview.selectType.vp.next" />}
      />
    </Card>
  );
};
