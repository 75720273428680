import {
  Tin,
  BaseValidator,
  ValidationResponse,
  VALIDATION_STATE,
} from "@lysaab/ui-2";
import { DateTime } from "luxon";

function stringToDate(date: string | undefined) {
  if (date === undefined) {
    return undefined;
  }

  return DateTime.fromFormat(date, "yyyy-LL-dd").toJSDate();
}

export interface TinConstructor {
  new (tin: string): Tin;
}

export class MaxAgeTinValidator extends BaseValidator {
  protected name = "MaxAgeTinValidator";

  private maxAge: number;
  // private TinParser: typeof DanishTin | typeof SwedishTin  | typeof GermanTin | typeof FinnishTin ;
  private TinParser: TinConstructor;

  //constructor(maxDate: Date, tinParser: typeof DanishTin | typeof SwedishTin | typeof GermanTin | typeof FinnishTin, error: string) {
  constructor(maxAge: number, tinParser: TinConstructor, error: string) {
    super(error);
    this.maxAge = maxAge;
    this.TinParser = tinParser;
  }

  getId() {
    // Note that this validator will not rerun automatically if the TinConstructor changes
    return this.getBaseId() + this.maxAge;
  }

  validate(value: string): Promise<ValidationResponse> {
    if (value.length === 0) {
      return Promise.resolve({
        status: VALIDATION_STATE.SUCCESS,
        hideError: true,
      });
    }

    return new Promise<ValidationResponse>((resolve) => {
      const TinParser = this.TinParser;
      const tin = new TinParser(value);

      if (!tin.isValid()) {
        return resolve({
          status: VALIDATION_STATE.SUCCESS,
          message: this.error,
          hideError: true,
        });
      }

      const maxAgeDate = DateTime.local()
        .minus({ years: this.maxAge })
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toJSDate();

      const birthDate = stringToDate(tin.getBirthDate());

      if (!birthDate) {
        return resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
          hideError: false,
        });
      }

      if (birthDate >= maxAgeDate) {
        return resolve({ status: VALIDATION_STATE.SUCCESS });
      }

      resolve({
        status: VALIDATION_STATE.FAILED,
        message: this.error,
        hideError: false,
      });
    });
  }
}
