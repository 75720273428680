import {
  Form,
  LysaFormRef,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { TranslatedText } from "../../../../components/TranslatedText";
import { LysaAccountSelectionCard } from "../../../../components/lysaAccountSelectionCard/LysaAccountSelectionCard";
import {
  AccountType,
  InvestmentAccountId,
  dataAccounts,
} from "../../../../data/dataAccounts";
import {
  MergedInternalTransferAccount,
  dataTransfer,
  TransferableGeneral,
  TransferableInvestment,
  MergedInternalTransferInvestmentAccount,
  isMergedInternalTransferInvestmentAccount,
  isDanicaKF,
} from "../../../../data/dataTransfer";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../../overview/OverviewPage";
import { useWithdrawalIskToSavingsContext } from "../WithdrawalIskToSavingsContext";
import HorizontalDivider from "../../../../components/horizontalDivider/HorizontalDivider";
import { WithdrawalHelp } from "../../request/components/withdrawalHelp/WithdrawalHelp";

import { useIsReadOnly } from "../../../../hooks/useIsReadOnly";
import { AllAccountResponse } from "../../../../data/dataAccounts";
import { WITHDRAWAL_PAGE_URL } from "../../overview/WithdrawalPage";
import { DisclaimerIskToSavings } from "../components/disclaimer/DisclaimerIskToSavings";

interface Props {
  next: () => void;
}

interface WithAccountId {
  accountId?: InvestmentAccountId;
}

export function SelectInvestmentAccount({ next }: Props) {
  const [allAccounts, setAccounts] = useState<AllAccountResponse>();
  const [
    mergedInternalTransferInvestmentAccounts,
    setMergedInternalTransferInvestmentAccounts,
  ] = useState<MergedInternalTransferInvestmentAccount[]>([]);
  const {
    state: internalWithdrawalContext,
    setState: setInternalWithdrawalContext,
  } = useWithdrawalIskToSavingsContext();
  const selectedAccount = internalWithdrawalContext.selectedLysaAccount;
  const formRef = useRef<LysaFormRef>();
  const isReadOnly = useIsReadOnly();
  const location = useLocation<WithAccountId | undefined>();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      dataAccounts.getAllAccounts(),
      dataTransfer.getInternalTransferAccounts(),
    ])
      .then(([accounts, internalTransferAccounts]) => {
        const investmentAccounts = [...accounts.investmentAccounts];
        const mergedAccounts = investmentAccounts
          .filter(
            (account) =>
              account.type !== AccountType.LYSA_PPF &&
              account.type !== AccountType.LYSA_TJP
          )
          .map((account) => {
            const internalTransferAccount = internalTransferAccounts.find(
              (internalTransferAccount) =>
                internalTransferAccount.accountId === account.accountId
            );
            return {
              ...account,
              ...internalTransferAccount,
            } as MergedInternalTransferAccount;
          })
          .filter(
            (account): account is MergedInternalTransferInvestmentAccount =>
              isMergedInternalTransferInvestmentAccount(account)
          );
        setMergedInternalTransferInvestmentAccounts(mergedAccounts);
        setAccounts(accounts);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (
      typeof location.state?.accountId === "undefined" ||
      typeof mergedInternalTransferInvestmentAccounts === "undefined"
    ) {
      return;
    }
    setInternalWithdrawalContext({
      selectedLysaAccount: mergedInternalTransferInvestmentAccounts.find(
        (account) => account.accountId === location.state?.accountId
      ),
    });
  }, [
    setInternalWithdrawalContext,
    location.state,
    mergedInternalTransferInvestmentAccounts,
  ]);

  useEffect(() => {
    if (typeof allAccounts === "undefined") {
      return;
    }
    const hasSavingsAccount = allAccounts.savingsAccounts.length > 0;
    if (!hasSavingsAccount) {
      history.replace(getNavLink(WITHDRAWAL_PAGE_URL));
    }
  }, [allAccounts, history]);

  if (loading) {
    return (
      <div className="internal-transfer-from-account">
        <Spinner />
      </div>
    );
  }

  if (isError) {
    return (
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <Typography type="label">
          <TranslatedText id="withdrawal.isk-to-savings-story.lysa-account-selection.error.loading-accounts.label" />
        </Typography>
        <Typography type="body">
          <TranslatedText id="withdrawal.isk-to-savings-story.lysa-account-selection.error.loading-accounts.body" />
        </Typography>
      </Snackbar>
    );
  }

  const investmentAccounts = allAccounts
    ? [...allAccounts.investmentAccounts]
    : [];

  return (
    <div>
      <Typography type="h2">
        <TranslatedText id="withdrawal.isk-to-savings-story.lysa-account-selection.header" />
      </Typography>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (
            formRef.current?.isInvalid ||
            selectedAccount?.transferable !== TransferableGeneral.OK ||
            isDanicaKF(selectedAccount) ||
            isReadOnly
          ) {
            return;
          }
          next();
        }}
      >
        <LysaAccountSelectionCard
          accounts={investmentAccounts}
          selectedAccount={investmentAccounts.find(
            ({ accountId }) => accountId === selectedAccount?.accountId
          )}
          onChange={({ accountId }) => {
            setInternalWithdrawalContext({
              selectedLysaAccount:
                mergedInternalTransferInvestmentAccounts.find(
                  (account) => account.accountId === accountId
                ),
            });
          }}
          legend={
            <TranslatedText id="withdrawal.isk-to-savings-story.lysa-account-selection.account-selection.label" />
          }
        />

        {isDanicaKF(selectedAccount) ? (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="withdrawal.isk-to-savings-story.lysa-account-selection.error.kf" />
          </Snackbar>
        ) : (
          <>
            {selectedAccount?.transferable ===
              TransferableGeneral.LACKS_FUNDS && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                {selectedAccount.worth > 0 &&
                selectedAccount.uninvestedMoney === selectedAccount.worth ? (
                  <TranslatedText id="withdrawal.isk-to-savings-story.lysa-account-selection.error.lacks-funds-with-cash" />
                ) : (
                  <TranslatedText id="withdrawal.isk-to-savings-story.lysa-account-selection.error.lacks-funds" />
                )}
              </Snackbar>
            )}
            {selectedAccount?.transferable ===
              TransferableInvestment.PENDING_ORDER && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                <TranslatedText id="withdrawal.isk-to-savings-story.lysa-account-selection.error.pending-order" />
              </Snackbar>
            )}
            {selectedAccount?.transferable ===
              TransferableGeneral.PENDING_WITHDRAWAL && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                <TranslatedText id="withdrawal.isk-to-savings-story.lysa-account-selection.error.pending-withdrawal" />
              </Snackbar>
            )}
            {selectedAccount?.transferable ===
              TransferableGeneral.PENDING_INTERNAL_TRANSFER && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                <TranslatedText id="withdrawal.isk-to-savings-story.lysa-account-selection.error.pending-internal-transfer" />
              </Snackbar>
            )}
          </>
        )}

        {isReadOnly ? (
          <>
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="withdrawal.isk-to-savings-story.lysa-account-selection.error.read-only" />
            </Snackbar>
            <Button
              component={Link}
              to={getNavLink(OVERVIEW_PAGE_URL)}
              block
              label={
                <TranslatedText id="withdrawal.isk-to-savings-story.lysa-account-selection.back" />
              }
            />
          </>
        ) : (
          <Button
            type="submit"
            block
            label={
              <TranslatedText id="withdrawal.isk-to-savings-story.lysa-account-selection.button" />
            }
          />
        )}
      </Form>
      <HorizontalDivider />
      <WithdrawalHelp />
      <DisclaimerIskToSavings />
    </div>
  );
}
