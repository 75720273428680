import { useContext, useEffect, useMemo, useState } from "react";
import ReactModal from "react-modal";
import { Button } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { matchPath, useHistory } from "react-router";
import { getNavLink } from "../../hooks/useCountryUrls";
import { YEARLY_REVIEW_URL } from "../../pages/yearlyReview/YearlyReviewStory";
import "./YearlyReviewInfo.scss";
import { useYearlyReviewStatus } from "../../hooks/useYearlyReviewState";
import { useFeatureContext } from "../../context/FeatureContext";
import { LOGOUT_PAGE_URL } from "../../pages/logout/LogoutPage";
import { OVERVIEW_PAGE_URL } from "../../pages/overview/OverviewPage";
import { Store, YEARLY_REVIEW_FORCE_CLOSE } from "../../Store";
import { MESSAGES_PAGE_URL } from "../../pages/messages/MessagesPage";
import { UserContext } from "../../context/UserContext";
import { DateTime } from "luxon";
import { YearlyReviewStatus } from "../../data/dataYearlyReview";
import { EventTracker } from "../eventTracker/EventTracker";
import { TrackerEvent } from "../../data/dataCustomerTracking";
import { TranslatedText } from "../TranslatedText";

ReactModal.setAppElement("#root");

export function YearlyReviewInfo() {
  const history = useHistory();
  const [, setFeatureState] = useFeatureContext();
  const userContext = useContext(UserContext);
  const { status, hash } = useYearlyReviewStatus();
  const [forceCloseBy, setForceCloseBy] = useState<string[]>([]);
  const isYearlyReviewPath = matchPath(history.location.pathname, {
    path: getNavLink(YEARLY_REVIEW_URL),
    exact: false,
  });

  const isOverviewPath = useMemo(() => {
    return !!matchPath(history.location.pathname, {
      path: getNavLink(OVERVIEW_PAGE_URL),
      exact: true,
    });
  }, [history.location.pathname]);

  const showDialog = useMemo(() => {
    if (
      typeof status === "undefined" ||
      typeof userContext.state.tin === "undefined"
    ) {
      return false;
    }

    switch (status) {
      case YearlyReviewStatus.NOT_NEEDED: {
        return false;
      }
      case YearlyReviewStatus.CAN_START: {
        if (
          isYearlyReviewPath ||
          forceCloseBy.includes(userContext.state.tin) ||
          (hash && getHasForceClosedBefore(hash))
        ) {
          return false;
        }
        if (isOverviewPath) {
          return true;
        }
        return false;
      }
      case YearlyReviewStatus.FORCE: {
        if (isOverviewPath) {
          return true;
        }
        return false;
      }
    }
  }, [
    forceCloseBy,
    hash,
    isOverviewPath,
    isYearlyReviewPath,
    userContext.state.tin,
    status,
  ]);

  useEffect(() => {
    setFeatureState({
      yearlyReviewLockRoutes: status === YearlyReviewStatus.FORCE,
    });
  }, [setFeatureState, status]);

  if (!hash) {
    return null;
  }

  return (
    <ReactModal
      overlayClassName="yearly-review-info-modal"
      isOpen={showDialog}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(242, 242, 242, 0.9)",
          display: "flex",
          zIndex: 9999,
          justifyContent: "center",
          alignItems: "center",
        },
        content: {
          position: "initial",
          backgroundColor: "#fcfcfc",
        },
      }}
    >
      <div className="yearly-review-info">
        <h1>
          <FormattedMessage id="yearlyReviewInfo.header" />
        </h1>
        <p>
          {YearlyReviewStatus.CAN_START === status && (
            <TranslatedText id="yearlyReviewInfo.description.CAN_START" />
          )}
          {YearlyReviewStatus.FORCE === status && (
            <TranslatedText id="yearlyReviewInfo.description.FORCE" />
          )}
        </p>
        <Button
          block
          onClick={() => {
            EventTracker.track({
              event: TrackerEvent.YEARLY_REVIEW_DIALOG,
              message: {
                action: `Start with status: ${status}`,
              },
            });
            history.push(getNavLink(YEARLY_REVIEW_URL));
          }}
          label={
            <FormattedMessage id="yearlyReviewInfo.button.startYearlyReview" />
          }
        />
        {YearlyReviewStatus.CAN_START === status && (
          <Button
            variant="secondary"
            block
            onClick={() => {
              setForceCloseBy((state) => {
                return [...state, hash];
              });
              const value = Store.getValue(YEARLY_REVIEW_FORCE_CLOSE) ?? [];
              Store.setValue(YEARLY_REVIEW_FORCE_CLOSE, [
                ...value,
                [hash, new Date().toISOString()],
              ]);
              EventTracker.track({
                event: TrackerEvent.YEARLY_REVIEW_DIALOG,
                message: {
                  action: `Paused notification, status: ${status}`,
                },
              });
            }}
            label={<FormattedMessage id="yearlyReviewInfo.button.later" />}
          />
        )}
        {YearlyReviewStatus.FORCE === status && (
          <>
            <Button
              variant="secondary"
              block
              onClick={() => {
                EventTracker.track({
                  event: TrackerEvent.YEARLY_REVIEW_DIALOG,
                  message: {
                    action: `Go to messages, status: ${status}`,
                  },
                });
                history.push(getNavLink(MESSAGES_PAGE_URL));
              }}
              label={<FormattedMessage id="yearlyReviewInfo.button.messages" />}
            />
            <Button
              variant="negative"
              block
              onClick={() => {
                EventTracker.track({
                  event: TrackerEvent.YEARLY_REVIEW_DIALOG,
                  message: {
                    action: `Sign out, status: ${status}`,
                  },
                });
                history.push(getNavLink(LOGOUT_PAGE_URL));
              }}
              label={<FormattedMessage id="yearlyReviewInfo.button.signout" />}
            />
          </>
        )}
      </div>
    </ReactModal>
  );
}

function getHasForceClosedBefore(currentHash: string): boolean {
  const value = Store.getValue(YEARLY_REVIEW_FORCE_CLOSE);
  const lastDenied = value?.find(
    ([savedHash]) => savedHash === currentHash
  )?.[1];

  if (typeof lastDenied === "string" && isWithinTimeLimit(lastDenied)) {
    return true;
  }

  if (typeof lastDenied === "string") {
    Store.setValue(
      YEARLY_REVIEW_FORCE_CLOSE,
      value?.filter(([savedHash]) => savedHash !== currentHash) ?? null
    );
  }
  return false;
}

function isWithinTimeLimit(date: string): boolean {
  const lastDenied = DateTime.fromISO(date);
  const isToday = lastDenied.toISODate() === DateTime.now().toISODate();
  const isWithinOneHour = lastDenied.plus({ hours: 1 }) > DateTime.now();
  return isToday && isWithinOneHour;
}
