import { Card, Button, Snackbar, SNACKBAR_TYPES, Spinner } from "@lysaab/ui-2";
import { useContext, useEffect, useState } from "react";
import * as React from "react";
import { useIntl } from "react-intl";
import { TranslatedText } from "../../../../components/TranslatedText";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import {
  dataLegalEntity,
  LegalEntityResponse,
} from "../../../../data/dataLegalEntity";
import { FiTaxHolding, RedemptionPart } from "../../../../data/dataTax";
import { HoldingsTable } from "./HoldingsTable";
import { RedemptionsTable } from "./RedemptionsTable";
import "./TaxDetails.scss";

interface Props {
  holdings?: FiTaxHolding[];
  redemptions?: RedemptionPart[];
  fees?: number;
  year?: string;
  loading?: boolean;
}

export const TaxDetails: React.VFC<Props> = ({
  holdings,
  redemptions,
  fees,
  year,
  loading,
}) => {
  const [legalEntity, setLegalEntity] = useState<LegalEntityResponse>();
  const localizationContext = useContext(LocalizationContext);
  const intl = useIntl();

  useEffect(() => {
    dataLegalEntity.getLegalEntity().then(setLegalEntity);
  }, []);

  if (typeof year === "undefined") {
    return (
      <>
        <h2>
          <span>
            <TranslatedText id="finland.tax.details.details-header" />
          </span>
        </h2>
        <Card>
          <TranslatedText id="finland.tax.details.placeholder" />
        </Card>
      </>
    );
  }

  if (
    typeof holdings === "undefined" ||
    typeof redemptions === "undefined" ||
    typeof fees === "undefined" ||
    typeof legalEntity === "undefined" ||
    loading
  ) {
    return (
      <>
        <h2>
          <span>
            <TranslatedText id="finland.tax.details.details-header" /> - {year}
          </span>
        </h2>
        <Card>
          <Spinner />
        </Card>
      </>
    );
  }

  return (
    <div className="finland-tax-details">
      <h2 className="title">
        <span>
          <TranslatedText id="finland.tax.details.details-header" /> - {year}
        </span>
      </h2>
      <Card>
        <div className="tax-header">
          <div className="tax-header-left">
            <dl>
              <dt>
                <TranslatedText id="finland.tax.details.name" />
              </dt>
              <dd>{legalEntity.name}</dd>
              <dt>
                <TranslatedText id="finland.tax.details.tin" />
              </dt>
              <dd>{legalEntity.tin}</dd>
            </dl>
            <hr />
            <dl>
              <dt>
                <TranslatedText id="finland.tax.details.fees" />
              </dt>
              <dd>
                {intl.formatNumber(fees, {
                  currency: localizationContext.state.currency,
                  style: "currency",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </dd>
            </dl>
          </div>
          <div className="tax-print-button">
            <Button
              size="small"
              onClick={window.print}
              icon="Print"
              label={<TranslatedText id="finland.tax.details.print-button" />}
            />
          </div>
        </div>
        <div className="tax-reporting-information">
          <Snackbar type={SNACKBAR_TYPES.INFO} icon={true}>
            <TranslatedText id="finland.tax.details.information" />
          </Snackbar>
        </div>
        <h4>
          <TranslatedText id="finland.tax.details.holdings" />
        </h4>
        <HoldingsTable holdings={holdings} />

        <h4>
          <TranslatedText id="finland.tax.details.redemptions" />
        </h4>
        <RedemptionsTable redemptionParts={redemptions} />

        <div className="tax-print-button">
          <Button
            size="small"
            onClick={window.print}
            icon="Print"
            label={<TranslatedText id="finland.tax.details.print-button" />}
          />
        </div>
      </Card>
    </div>
  );
};
