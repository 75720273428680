import { useEffect, useRef, useContext } from "react";
import { Redirect } from "react-router-dom";
import {
  Currency,
  Language,
  LocalizationContext,
} from "../../context/LocalizationContext";
import {
  OverviewPage,
  OVERVIEW_PAGE_URL,
} from "../../pages/overview/OverviewPage";
import { ProfilePage, PROFILE_PAGE_URL } from "../../pages/profile/ProfilePage";
import { NotFoundPage } from "../../pages/NotFoundPage";
import { getNavLink } from "../../hooks/useCountryUrls";
import { AccountPage, ACCOUNT_PAGE_URL } from "../../pages/account/AccountPage";
import {
  PublicRoute,
  PrivateRoute,
  NonPrivateRoute,
} from "../../components/router/Routes";
import { LoginPage, LOGIN_SWEDEN_PAGE_URL } from "./pages/LoginPage";
import { StorePage, STORE_PAGE_URL } from "../../pages/StorePage";
import { OfflinePage, OFFLINE_URL } from "../../pages/OfflinePage";
import { LOGOUT_PAGE_URL, LogoutPage } from "../../pages/logout/LogoutPage";
import {
  TransfersMenuPage,
  TRANSFERS_MENU_PAGE_URL,
} from "../../pages/transfersMenu/TransfersMenuPage";
import {
  CreateAccountSelection,
  CREATE_ACCOUNT_SELECTION_PAGE_URL,
} from "./pages/CreateAccountSelection";
import { useWrongCountryRedirect } from "../../hooks/useWrongCountryRedirect";
import {
  IskTransferStory,
  ISK_TRANSFER_URL,
} from "./pages/iskTransfer/IskTransferStory";
import {
  UpdateAccountPage,
  UPDATE_ACCOUNT_PAGE_URL,
} from "../../pages/updateAccount/UpdateAccountPage";
import {
  WithdrawalKfRequestPage,
  WITHDRAWAL_REQUEST_KF_PAGE_URL,
} from "./pages/withdrawal/kf/request/WithdrawalKfRequestPage";
import { Switch } from "../../components/route/Switch";
import {
  AddAccountKlarnaStory,
  ADD_EXTERNAL_KLARNA_URL,
} from "../../pages/withdrawal/addAccountKlarna/AddAccountKlarnaStory";
import {
  WithdrawalStory,
  WITHDRAWALS_REQUEST_PAGE,
} from "../../pages/withdrawal/request/WithdrawalStory";
import {
  MessagesPage,
  MESSAGES_PAGE_URL,
} from "../../pages/messages/MessagesPage";
import { InvitePage, INVITE_PAGE_URL } from "../../pages/invite/InvitePage";
import {
  AddAccountAutogiroStory,
  ADD_EXTERNAL_AUTOGIRO_URL,
} from "./pages/withdrawal/addAccountAutogiro/AddAccountAutogiroStory";
import {
  SwitchUserSelectionPage,
  SWITCH_USER_SELECTION_PAGE_URL,
} from "./switchUser/SwitchUserSelection";
import { SwitchUserPage, SWITCH_USER_PAGE_URL } from "./switchUser/SwitchUser";
import { GridPage, GRID_PAGE_URL } from "../../pages/grid/GridPage";
import { Tax } from "./pages/taxInformation/TaxInformation";
import {
  AutogiroDepositPage,
  AUTOGIRO_DEPOSIT_PAGE_URL,
} from "./pages/deposits/Autogiro/deposit/AutogiroDepositPage";

import { UserContext } from "../../context/UserContext";
import { LegalEntityType } from "../../data/dataLogin";
import { FeesPage, FEES_PAGE_URL } from "../../pages/fees/FeesPage";
import {
  CREATE_ACCOUNT_URL,
  CreateAccountStory,
} from "./pages/createAccount/CreateAccountStory";
import {
  DocumentsPage,
  DOCUMENT_PAGE_URL,
} from "../../pages/documents/DocumentsPage";
import { DepositsOverviewWrapper } from "./pages/deposits/DepositsOverviewWrapper";
import {
  SwishDepositPage,
  SWISH_DEPOSIT_PAGE_URL,
} from "./pages/deposits/swish/SwishDepositPage";
import {
  BankgiroDepositStory,
  BANKGIRO_DEPOSIT_PAGE_URL,
} from "./pages/deposits/bankgiro/BankgiroDepositStory";
import {
  HistoricTransactionsPage,
  HISTORIC_TRANSACTIONS_PAGE_URL,
} from "../../pages/historicTransactions/HistoricTransactionsPage";
import { InvoicePage, INVOICE_PAGE_URL } from "../../pages/invoice/InvoicePage";
import { usePreferredCountry } from "../../hooks/usePreferredCountry";
import { TAX_INFORMATION_PAGE_URL } from "../../pages/taxInformation/TaxInformation";
import {
  InternalTransferPage,
  INTERNAL_TRANSFER_PAGE_URL,
} from "../../pages/internalTransfer/InternalTransferPage";
import {
  YearlyReviewStory,
  YEARLY_REVIEW_URL,
} from "../../pages/yearlyReview/YearlyReviewStory";
import {
  MONTHLY_OVERVIEW_PAGE,
  MonthlyOverview,
} from "../../pages/deposits/monthly/overview/MonthlyOverview";
import { MonthlyStory } from "./pages/deposits/monthly/MonthlyStory";
import { MONTHLY_DEPOSITS_URL } from "../../pages/deposits/monthly/create/MonthlyStory";
import {
  UserManagementStory,
  USER_MANAGEMENT_URL,
} from "./pages/corporation/userManagement/UserManagementStory";
import {
  AccountingPage,
  ACCOUNTING_PAGE_URL,
} from "./pages/corporation/accounting/AccountingPage";
import {
  CloseLysaCustomerAccountStory,
  CLOSE_LYSA_CUSTOMER_URL,
} from "../../pages/closeLysaCustomerAccountStory/CloseLysaCustomerStory";
import {
  SettingsPage,
  SETTINGS_PAGE_URL,
} from "../../pages/settings/SettingsPage";
import {
  EndInvestment,
  END_INVESTMENT_URL,
} from "../../pages/endInvestment/EndInvestment";
import {
  YourDataPage,
  YOUR_DATA_PAGE_URL,
} from "../../pages/yourData/YourDataPage";
import {
  CloseAccountStory,
  CLOSE_ACCOUNT_URL,
} from "../../pages/closeAccountStory/CloseAccountStory";
import {
  ChangeEmailPage,
  CHANGE_EMAIL_PAGE_URL,
} from "../../pages/changeEmail/ChangeEmailPage";
import {
  CompanySettingsPage,
  COMPANY_SETTINGS_PAGE_URL,
} from "./pages/corporation/companySettings/CompanySettingsPage";
import {
  WithdrawalPeriodicStory,
  WITHDRAWALS_PERIODIC_STORY_URL,
} from "../../pages/withdrawal/withdrawalPeriodic/WithdrawalPeriodicStory";
import {
  AccountStatementPage,
  ACCOUNT_STATEMENT_PAGE,
} from "../../pages/accountStatement/AccountStatementPage";
import { KycViewPage, KYC_PAGE_URL } from "../../pages/kyc/KycViewPage";
import {
  AccountKycUpdate,
  KYC_ACCOUNT_UPDATE_PAGE_URL,
} from "../../pages/kyc/accountsKyc/accountKycUpdate/AccountKycUpdate";
import {
  KYC_UPDATE_PEP_STORY,
  PepUpdateStory,
} from "../../pages/kyc/pep/pepUpdate/PepUpdateStory";
import {
  SUITABILITY_ASSESSMENT_EDIT_PAGE,
  SuitabilityAssessmentStory,
} from "../../pages/suitabilityAssessment/SuitabilityAssessmentStory";
import {
  CreateSavingsAccountStory,
  CREATE_SAVINGS_ACCOUNT_URL,
} from "./pages/createAccount/savingsAccount/CreateSavingsAccountStory";
import {
  SavingsAccountPage,
  SAVINGS_ACCOUNT_PAGE_URL,
} from "../../pages/account/savingsAccountPage/SavingsAccountPage";
import {
  CHUNK_LOAD_ERROR_PAGE_URL,
  ChunkLoadErrorPage,
} from "../../pages/chunkLoadError/ChunkLoadErrorPage";

import { DEPOSITS_OVERVIEW_URL } from "../../pages/deposits/overview/Recommendation";
import { CrsPage } from "./pages/crs/CrsPage";
import { UPDATE_CRS_URL } from "../../pages/updateCrsStory/UpdateCrsStory";
import { CRS_PAGE_URL } from "../../pages/crs/CrsPage";
import { UpdateCrsStory } from "./pages/updateCrsStory/UpdateCrsStory";
import { Crs } from "./pages/yearlyReview/crs/Crs";
import {
  CreateAccountIntroStory,
  CREATE_ACCOUNT_INTRO_URL,
} from "./pages/createAccount/createAccountIntroStory/CreateAccountIntroStory";
import {
  AddDepositAccountAutogiroStory,
  ADD_DEPOSIT_AUTOGIRO_URL,
} from "./pages/deposits/Autogiro/addAccount/AddDepositAccountAutogiroStory";
import {
  AccountSituationStory,
  ACCOUNT_SITUATION_EDIT_PAGE,
} from "../../pages/accountSituation/AccountSituationStory";
import {
  ReviewAccountStory,
  REVIEW_ACCOUNT_URL,
} from "../../pages/reviewAccount/ReviewAccountStory";
import {
  MonthlyAgreements,
  MONTHLY_CONSENTS_PAGE_URL,
} from "./pages/monthlyAgreements/MonthlyAgreements";
import {
  ShareAccountStory,
  SHARE_ACCOUNT_URL,
} from "../../pages/shareAccountStory/ShareAccountStory";
import {
  ShareAccountInvitationPage,
  SHARE_ACCOUNT_INVITATION_URL,
} from "../../pages/shareAccountInvitation/ShareAccountInvitationPage";
import {
  ShareAccountInvitationsPage,
  SHARE_ACCOUNT_INVITATIONS_URL,
} from "../../pages/shareAccountInvitations/ShareAccountInvitationsPage";
import {
  ShareAccountSentInvitationPage,
  SHARE_ACCOUNT_SENT_INVITATION_URL,
} from "../../pages/shareAccountSentInvitation/ShareAccountSentInvitationPage";
import { LysaCountry } from "@lysaab/shared";
import {
  SharedAccountsPage,
  SHARED_ACCOUNTS_URL,
} from "../../pages/sharedAccountsPage/SharedAccountsPage";
import {
  SharedAccountPage,
  SHARED_ACCOUNT_PAGE_URL,
} from "../../pages/account/sharedAccountPage/SharedAccountPage";
import {
  SharedSavingsAccountPage,
  SHARED_SAVINGS_ACCOUNT_PAGE_URL,
} from "../../pages/account/savingsAccountPage/SharedSavingsAccountPage";
import { WaitingAccounts } from "./pages/withdrawal/waitingAccounts/WaitingAccounts";
import {
  CloseSavingsAccountStory,
  CLOSE_SAVINGS_ACCOUNT_URL,
} from "./pages/closeSavingsAccountStory/CloseSavingsAccountStory";
import {
  WITHDRAWAL_ISK_TO_SAVINGS_PAGE,
  WithdrawalIskToSavingsStory,
} from "../../pages/withdrawal/internalRequest/WithdrawalIskToSavingsStory";
import {
  WithdrawalPage,
  WITHDRAWAL_PAGE_URL,
} from "../../pages/withdrawal/overview/WithdrawalPage";
import {
  BannerRedirect,
  REDIRECT_TO_APP,
} from "./pages/bannerRedirect/BannerRedirect";
import {
  MigrationKfRequestPage,
  MIGRATION_REQUEST_KF_PAGE_URL,
} from "./pages/corporation/migration/request/MigrationKfRequestPage";
import {
  CreateAccountCorporationPage,
  CREATE_ACCOUNT_CORPORATION_PAGE_URL,
} from "./pages/corporation/kf/CreateAccountCorporationPage";
import {
  MONTHLY_INTERNAL_TRANSFER_PAGE_URL,
  MonthlyInternalTransferStory,
} from "../../pages/monthlyInternalTransfer/MonthlyInternalTransferStory";
import {
  MOVE_ACCOUNTING_PAGE_URL,
  MoveAccounting,
} from "./pages/corporation/moveAccounting/MoveAccounting";
import { ExternalAccount } from "../../data/dataWithdrawals";
import {
  TRANSFER_PENSIONS_URL,
  TransfersPensionsStory,
} from "./pages/transferPensions/TransferPensionsStory";

import { experimentKeys } from "../../experimentConfig";
import { useExperiment } from "@lysaab/shared";
import {
  MIGRATION_KF_INFO_PAGE_URL,
  MigrationInfo,
} from "./pages/corporation/migration/info/MigrationInfo";
import {
  MIGRATION_KF_INFO_SHARES_PAGE_URL,
  MigrationInfoShares,
} from "./pages/corporation/migration/info/MigrationInfoShares";

const CURRENCY = Currency.SEK;

export function Router() {
  const localizationContext = useContext(LocalizationContext);
  const userContext = useContext(UserContext);
  useWrongCountryRedirect(LysaCountry.SWEDEN);
  usePreferredCountry(LysaCountry.SWEDEN);
  const loaded = useRef(false);

  useEffect(() => {
    if (!loaded.current) {
      localizationContext.setState({
        currency: CURRENCY,
        language: userContext.state.language ?? Language.SWEDISH,
        country: LysaCountry.SWEDEN,
      });
    }
    loaded.current = true;
  }, [localizationContext, userContext.state.language]);

  const isPerson = userContext.state.legalEntityType === LegalEntityType.PERSON;

  // To hide the route for those not in the experiment, remove (or comment out) the next line and uncomment the useExperiment below
  // const pensionMoveExperimentEnabled = true;
  const { value: pensionMoveExperimentEnabled } = useExperiment({
    propertyKey: experimentKeys.PENSION_MOVE,
    disableExposure: true,
  });

  return (
    <Switch>
      <NonPrivateRoute path={getNavLink(LOGIN_SWEDEN_PAGE_URL)} exact>
        <LoginPage />
      </NonPrivateRoute>
      <PrivateRoute path={getNavLink(OVERVIEW_PAGE_URL)} exact>
        <OverviewPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(PROFILE_PAGE_URL)} exact>
        <ProfilePage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(ACCOUNT_PAGE_URL)} exact>
        <AccountPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(SHARED_ACCOUNT_PAGE_URL)} exact>
        <SharedAccountPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(SHARED_SAVINGS_ACCOUNT_PAGE_URL)} exact>
        <SharedSavingsAccountPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(STORE_PAGE_URL)} exact>
        <StorePage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(TRANSFERS_MENU_PAGE_URL)} exact>
        <TransfersMenuPage />
      </PrivateRoute>
      <PublicRoute path={getNavLink(LOGOUT_PAGE_URL)} exact>
        <LogoutPage />
      </PublicRoute>
      <PrivateRoute path={getNavLink(KYC_PAGE_URL)} exact>
        <KycViewPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(KYC_ACCOUNT_UPDATE_PAGE_URL)} exact>
        <AccountKycUpdate />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(SUITABILITY_ASSESSMENT_EDIT_PAGE)}>
        <SuitabilityAssessmentStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(REVIEW_ACCOUNT_URL)}>
        <ReviewAccountStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(ACCOUNT_SITUATION_EDIT_PAGE)}>
        <AccountSituationStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(KYC_UPDATE_PEP_STORY)}>
        <PepUpdateStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(OFFLINE_URL)} exact>
        <OfflinePage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(CREATE_ACCOUNT_SELECTION_PAGE_URL)} exact>
        <CreateAccountSelection />
      </PrivateRoute>
      <PrivateRoute condition={isPerson} path={getNavLink(ISK_TRANSFER_URL)}>
        <IskTransferStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(MONTHLY_DEPOSITS_URL)}>
        <MonthlyStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(ADD_EXTERNAL_KLARNA_URL)}>
        <AddAccountKlarnaStory
          missingBank={getNavLink(ADD_EXTERNAL_AUTOGIRO_URL)}
        />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(ADD_EXTERNAL_AUTOGIRO_URL)}>
        <AddAccountAutogiroStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(GRID_PAGE_URL)}>
        <GridPage />
      </PrivateRoute>

      <PublicRoute path={getNavLink(CREATE_ACCOUNT_CORPORATION_PAGE_URL)}>
        <CreateAccountCorporationPage />
      </PublicRoute>

      <PublicRoute
        exact
        path="/se/create-account/corporation-new/creation-overview/:creationToken"
        render={(props) => (
          <Redirect
            to={`${getNavLink(
              CREATE_ACCOUNT_CORPORATION_PAGE_URL
            )}/creation-overview/${props.match.params.creationToken}`}
          />
        )}
      />

      <PrivateRoute path={getNavLink(CREATE_ACCOUNT_URL)}>
        <CreateAccountStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(HISTORIC_TRANSACTIONS_PAGE_URL)}>
        <HistoricTransactionsPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(INVOICE_PAGE_URL)}>
        <InvoicePage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(WITHDRAWALS_REQUEST_PAGE)}>
        <WithdrawalStory
          addWithdrawalAccountUrl={
            isPerson
              ? getNavLink(ADD_EXTERNAL_KLARNA_URL)
              : getNavLink(COMPANY_SETTINGS_PAGE_URL)
          }
          waitingAccountsComponent={<WaitingAccounts />}
        />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(WITHDRAWAL_ISK_TO_SAVINGS_PAGE)}>
        <WithdrawalIskToSavingsStory />
      </PrivateRoute>
      {/** Public to allow KF accounts to withdraw */}
      <PublicRoute path={getNavLink(WITHDRAWAL_PAGE_URL)}>
        <WithdrawalPage
          customMissingExternalAccounts={(
            externalAccounts: ExternalAccount[]
          ) => (
            <WaitingAccounts
              externalAccounts={externalAccounts}
              showMissingAccounts
            />
          )}
        />
      </PublicRoute>
      {/** Public to allow for non customer owners to sign */}
      <PublicRoute path={getNavLink(WITHDRAWAL_REQUEST_KF_PAGE_URL)}>
        <WithdrawalKfRequestPage />
      </PublicRoute>
      {/** Public to allow for non customer owners to sign */}
      <PublicRoute path={getNavLink(MIGRATION_REQUEST_KF_PAGE_URL)}>
        <MigrationKfRequestPage />
      </PublicRoute>
      {/**
       * Temporary redirects to allow for login redirects of a public page
       */}
      <PrivateRoute
        path={getNavLink("/redirect" + MIGRATION_REQUEST_KF_PAGE_URL)}
      >
        <Redirect to={getNavLink(MIGRATION_REQUEST_KF_PAGE_URL)} />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(MIGRATION_KF_INFO_PAGE_URL)}>
        <MigrationInfo />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(MIGRATION_KF_INFO_SHARES_PAGE_URL)}>
        <MigrationInfoShares />
      </PrivateRoute>

      <PrivateRoute path={getNavLink(MESSAGES_PAGE_URL)}>
        <MessagesPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(INVITE_PAGE_URL)}>
        <InvitePage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(UPDATE_ACCOUNT_PAGE_URL)}>
        <UpdateAccountPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(TAX_INFORMATION_PAGE_URL)}>
        <Tax />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(ADD_DEPOSIT_AUTOGIRO_URL)}>
        <AddDepositAccountAutogiroStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(AUTOGIRO_DEPOSIT_PAGE_URL)}>
        <AutogiroDepositPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(SWISH_DEPOSIT_PAGE_URL)}>
        {/* fallbackUrl must support SavingsAccountId */}
        <SwishDepositPage fallbackUrl={getNavLink(DEPOSITS_OVERVIEW_URL)} />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(BANKGIRO_DEPOSIT_PAGE_URL)}>
        <BankgiroDepositStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(DEPOSITS_OVERVIEW_URL)}>
        <DepositsOverviewWrapper />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(MONTHLY_OVERVIEW_PAGE)}>
        <MonthlyOverview />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(ACCOUNTING_PAGE_URL)}>
        <AccountingPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(MOVE_ACCOUNTING_PAGE_URL)}>
        <MoveAccounting />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(SWITCH_USER_SELECTION_PAGE_URL)}>
        <SwitchUserSelectionPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(SWITCH_USER_PAGE_URL)}>
        <SwitchUserPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(DOCUMENT_PAGE_URL)}>
        <DocumentsPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(INTERNAL_TRANSFER_PAGE_URL)}>
        <InternalTransferPage />
      </PrivateRoute>
      <PrivateRoute
        condition={isPerson}
        path={getNavLink(MONTHLY_INTERNAL_TRANSFER_PAGE_URL)}
      >
        <MonthlyInternalTransferStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(YEARLY_REVIEW_URL)}>
        <YearlyReviewStory crsComponent={Crs} />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(USER_MANAGEMENT_URL)}>
        <UserManagementStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(COMPANY_SETTINGS_PAGE_URL)}>
        <CompanySettingsPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(CLOSE_LYSA_CUSTOMER_URL)}>
        <CloseLysaCustomerAccountStory
          addWithdrawalAccountUrl={getNavLink(ADD_EXTERNAL_KLARNA_URL)}
        />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(CLOSE_SAVINGS_ACCOUNT_URL)}>
        <CloseSavingsAccountStory
          addWithdrawalAccountUrl={getNavLink(ADD_EXTERNAL_KLARNA_URL)}
        />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(CLOSE_SAVINGS_ACCOUNT_URL)}>
        <AccountStatementPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(SETTINGS_PAGE_URL)}>
        <SettingsPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(END_INVESTMENT_URL)} exact>
        <EndInvestment />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(CLOSE_ACCOUNT_URL)}>
        <CloseAccountStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(YOUR_DATA_PAGE_URL)}>
        <YourDataPage />
      </PrivateRoute>
      <PrivateRoute condition={isPerson} path={getNavLink(CRS_PAGE_URL)} exact>
        <CrsPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(UPDATE_CRS_URL)}>
        <UpdateCrsStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(CHANGE_EMAIL_PAGE_URL)} exact>
        <ChangeEmailPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(WITHDRAWALS_PERIODIC_STORY_URL)}>
        <WithdrawalPeriodicStory
          addWithdrawalAccountUrl={
            isPerson
              ? getNavLink(ADD_EXTERNAL_KLARNA_URL)
              : getNavLink(COMPANY_SETTINGS_PAGE_URL)
          }
        />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(ACCOUNT_STATEMENT_PAGE)}>
        <AccountStatementPage />
      </PrivateRoute>
      <PrivateRoute
        condition={isPerson}
        path={getNavLink(CREATE_SAVINGS_ACCOUNT_URL)}
      >
        <CreateSavingsAccountStory />
      </PrivateRoute>
      <PrivateRoute
        condition={isPerson}
        path={getNavLink(CREATE_ACCOUNT_INTRO_URL)}
      >
        <CreateAccountIntroStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(SAVINGS_ACCOUNT_PAGE_URL)}>
        <SavingsAccountPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(MONTHLY_CONSENTS_PAGE_URL)}>
        <MonthlyAgreements />
      </PrivateRoute>
      <PrivateRoute condition={isPerson} path={getNavLink(SHARE_ACCOUNT_URL)}>
        <ShareAccountStory />
      </PrivateRoute>
      <PrivateRoute
        condition={isPerson}
        path={getNavLink(SHARE_ACCOUNT_SENT_INVITATION_URL)}
      >
        <ShareAccountSentInvitationPage />
      </PrivateRoute>
      <PrivateRoute
        condition={isPerson}
        path={getNavLink(SHARE_ACCOUNT_INVITATIONS_URL)}
        exact
      >
        <ShareAccountInvitationsPage />
      </PrivateRoute>
      <PrivateRoute condition={isPerson} path={getNavLink(SHARED_ACCOUNTS_URL)}>
        <SharedAccountsPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(SHARE_ACCOUNT_INVITATION_URL)}>
        <ShareAccountInvitationPage />
      </PrivateRoute>
      <PrivateRoute
        path={getNavLink(TRANSFER_PENSIONS_URL)}
        condition={pensionMoveExperimentEnabled === "true"}
      >
        <TransfersPensionsStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(CHUNK_LOAD_ERROR_PAGE_URL)}>
        <ChunkLoadErrorPage />
      </PrivateRoute>
      <PrivateRoute condition={isPerson} path={getNavLink(REDIRECT_TO_APP)}>
        <BannerRedirect />
      </PrivateRoute>
      {/**
       * These redirect are new urls for pages that
       * haven't been migrated yet or handling of urls
       * that might have old links in emails and such
       */}
      <Redirect
        from={getNavLink("/isk-flytt")}
        to={getNavLink(ISK_TRANSFER_URL)}
      />
      <Redirect
        from={getNavLink("/savings-account-redirect")}
        to={getNavLink(CREATE_SAVINGS_ACCOUNT_URL)}
      />
      <Redirect
        from={getNavLink(CREATE_ACCOUNT_INTRO_URL)}
        to={getNavLink(CREATE_ACCOUNT_URL)}
      />
      <Redirect
        from={getNavLink("/tax")}
        to={getNavLink(TAX_INFORMATION_PAGE_URL)}
      />
      <Redirect
        from={getNavLink("/insättningar")}
        to={getNavLink(DEPOSITS_OVERVIEW_URL)}
      />
      <Redirect
        from={getNavLink("/månadssparande")}
        to={getNavLink(MONTHLY_OVERVIEW_PAGE)}
      />
      <Redirect
        from={getNavLink("/starta-månadssparande")}
        to={getNavLink(MONTHLY_DEPOSITS_URL)}
      />
      <Redirect
        from={getNavLink("/bokföringsunderlag")}
        to={getNavLink(ACCOUNTING_PAGE_URL)}
      />
      <Redirect from={getNavLink("/profil")} to={getNavLink(KYC_PAGE_URL)} />
      <Redirect
        from={getNavLink("/ekonomisk-situation")}
        to={getNavLink(SUITABILITY_ASSESSMENT_EDIT_PAGE)}
      />
      <Redirect
        from={getNavLink("/hantera-användare")}
        to={getNavLink(USER_MANAGEMENT_URL)}
      />
      <Redirect
        from={getNavLink("/företags-information")}
        to={getNavLink(COMPANY_SETTINGS_PAGE_URL)}
      />
      <Redirect
        from={getNavLink("/uttag/återkommande")}
        to={getNavLink(WITHDRAWALS_PERIODIC_STORY_URL)}
      />

      <PrivateRoute path={getNavLink(FEES_PAGE_URL)}>
        <FeesPage />
      </PrivateRoute>
      {/**
       * Redirects from old URLS
       */}
      <PublicRoute>
        <NotFoundPage />
      </PublicRoute>
    </Switch>
  );
}
