import { useEffect, useState, VoidFunctionComponent } from "react";
import { SavingsAccountId } from "../../../../../data/dataAccounts";
import { BASE_ROUTES as CLOSE_ACCOUNT_ROUTES } from "../../../../../countries/sweden/pages/closeSavingsAccountStory/CloseSavingsAccountStory";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { TranslatedText } from "../../../../../components/TranslatedText";
import {
  Button,
  Card,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import { generatePath, Link } from "react-router-dom";
import { useLoadingContext } from "../../../../../context/LoadingContext";
import { useIntl } from "react-intl";
import { CloseAccountReasonsList } from "../../../../../components/closeAccountReasonsList/CloseAccountReasonsList";
import { useSavingsAccountCloseable } from "../../../../../hooks/useSavingsAccountCloseable";

import "./CloseSavingsAccount.scss";

interface Props {
  accountId?: SavingsAccountId;
}

export const CloseSavingsAccount: VoidFunctionComponent<Props> = ({
  accountId,
}) => {
  const [isLocalLoading, setIsLocalLoading] = useState(true);
  const { isLoading: isLoadingSync } = useLoadingContext(false);
  const intl = useIntl();
  const isSavingsAccountCloseable = useSavingsAccountCloseable();

  useEffect(() => {
    if (
      typeof isSavingsAccountCloseable === "function" ||
      isSavingsAccountCloseable === "ERROR"
    ) {
      setIsLocalLoading(false);
    }
  }, [isSavingsAccountCloseable]);

  if (isSavingsAccountCloseable === "ERROR") {
    return null;
  }

  if (
    isLoadingSync ||
    isLocalLoading ||
    isSavingsAccountCloseable === "LOADING" ||
    typeof accountId === "undefined"
  ) {
    return <Spinner />;
  }

  const closeableState = isSavingsAccountCloseable(accountId);

  if (!closeableState.isCloseable) {
    return (
      <Card className="close-savings-account">
        <Snackbar type={SNACKBAR_TYPES.INFO}>
          <CloseAccountReasonsList
            reasons={closeableState.reasons}
            className="close-savings-account__snackbar"
          />
        </Snackbar>
      </Card>
    );
  }

  return (
    <Card>
      <Typography type="body">
        <TranslatedText id="savingsAccountPage.delete-account.text" />
      </Typography>
      <Button
        component={Link}
        variant="negative"
        size="small"
        to={getNavLink(
          generatePath(
            CLOSE_ACCOUNT_ROUTES.CLOSE_SAVINGS_ACCOUNT_CONFIRMATION,
            {
              accountId,
            }
          )
        )}
        block
        label={intl.formatMessage({
          id: "savingsAccountPage.delete-account.link",
        })}
      />
    </Card>
  );
};
