import { useContext } from "react";
import { UserContext } from "../context/UserContext";

/**
 * This keeps signed in checks consistent.
 * TODO: Name is probably not a great value to check against.
 */

export function useIsSignedIn() {
  const userContext = useContext(UserContext);
  return !!userContext.state.name;
}
