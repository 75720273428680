import { Button, Card, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { FormattedMessage, FormattedRelativeTime } from "react-intl";
import { useFeatureConfigurationContext } from "../../context/FeatureConfigurationContext";
import {
  ShareAccountInvitationStatus,
  ShareAccountReceivedInvitation,
} from "../../data/dataAttorney";

interface Props {
  invitation: ShareAccountReceivedInvitation;
  onAccept: () => Promise<boolean>;
  onDecline: () => Promise<boolean>;
}

enum InternalStatus {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
}

export const ShareAccountInviteSummary: React.VFC<Props> = ({
  invitation,
  onAccept,
  onDecline,
}) => {
  const [status, setStatus] = useState<InternalStatus>();
  const [isLoading, setIsLoading] = useState(false);
  const { setState: setFeatureConfigurationContext } =
    useFeatureConfigurationContext();

  const handleDecline = () => {
    setIsLoading(true);
    onDecline()
      .then(() => {
        setStatus(InternalStatus.DECLINED);
      })
      .finally(() => setIsLoading(false));
  };
  const handleAccept = () => {
    setIsLoading(true);
    onAccept()
      .then(() => {
        setStatus(InternalStatus.ACCEPTED);
        setFeatureConfigurationContext({ sharedAccountAccepted: true });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (invitation.status === ShareAccountInvitationStatus.ACCEPTED_INVITEE) {
      setStatus(InternalStatus.ACCEPTED);
    }
    if (invitation.status === ShareAccountInvitationStatus.DECLINED_INVITEE) {
      setStatus(InternalStatus.DECLINED);
    }
  }, [invitation.status]);

  return (
    <motion.div layout>
      <Card className="summary-card" showLoading={isLoading}>
        <motion.div layoutId="static">
          <div className="list-item">
            <strong>
              <FormattedMessage id="shareAccountInvitation.summaryCard.accountNameLabel" />
            </strong>
            <div className="list-item-value">{invitation.accountName}</div>
          </div>
          <div className="list-item">
            <strong>
              <FormattedMessage id="shareAccountInvitation.summaryCard.ownerNameLabel" />
            </strong>
            <div>{invitation.ownerName}</div>
          </div>
          <div className="list-item">
            <strong>
              <FormattedMessage id="shareAccountInvitation.summaryCard.ownerDateOfBirthLabel" />
            </strong>
            <div>{invitation.ownerDoB}</div>
          </div>
          <div className="list-item">
            <strong>
              <FormattedMessage id="shareAccountInvitation.summaryCard.expiryLabel" />
            </strong>
            {invitation.expiresAt && (
              <div>
                <FormattedRelativeTime
                  value={
                    new Date(invitation.expiresAt).getTime() / 1000 -
                    Date.now() / 1000
                  }
                  numeric="auto"
                  updateIntervalInSeconds={1}
                />
              </div>
            )}
          </div>
          <hr />
        </motion.div>
        <div>
          {status && (
            <motion.div layoutId={status}>
              {status === InternalStatus.ACCEPTED && (
                <>
                  <Snackbar type={SNACKBAR_TYPES.INFO} icon>
                    <FormattedMessage id="shareAccountInvitation.summaryCard.acceptedText" />
                  </Snackbar>
                  <p className="small">
                    <FormattedMessage
                      id="shareAccountInvitation.summaryCard.acceptedInfoText"
                      values={{
                        name: invitation.ownerName,
                      }}
                    />
                  </p>
                </>
              )}
              {status === InternalStatus.DECLINED && (
                <Snackbar type={SNACKBAR_TYPES.INFO} icon>
                  <FormattedMessage id="shareAccountInvitation.summaryCard.declinedText" />
                </Snackbar>
              )}
            </motion.div>
          )}

          {!status && (
            <div>
              <Button
                block
                onClick={handleAccept}
                label={
                  <FormattedMessage id="shareAccountInvitation.summaryCard.acceptButtonText" />
                }
              />
              <Button
                variant="negative"
                block
                onClick={handleDecline}
                label={
                  <FormattedMessage id="shareAccountInvitation.summaryCard.declineButtonText" />
                }
              />
            </div>
          )}
        </div>
      </Card>
    </motion.div>
  );
};
