import { Button } from "@lysaab/ui-2";
import { FunctionComponent, useContext } from "react";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { CREATE_ACCOUNT_INTRO_URL } from "../../countries/sweden/pages/createAccount/createAccountIntroStory/CreateAccountIntroStory";
import { CREATE_ACCOUNT_SELECTION_PAGE_URL } from "../../countries/sweden/pages/CreateAccountSelection";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
} from "../../data/dataCustomerTracking";
import { LegalEntityType } from "../../data/dataLogin";
import { getNavLink } from "../../hooks/useCountryUrls";

export const CreateAccountLink: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const userContext = useContext(UserContext);
  const location = useLocation();

  if (userContext.state.legalEntityType === LegalEntityType.CORPORATION) {
    return (
      <Button
        variant="secondary"
        size="small"
        block
        component={Link}
        to={{
          pathname: getNavLink(CREATE_ACCOUNT_SELECTION_PAGE_URL),
          state: { prevPath: location.pathname },
        }}
        label={children}
        icon="Plus"
      />
    );
  } else {
    return (
      <Button
        variant="secondary"
        size="small"
        block
        component={Link}
        to={getNavLink(CREATE_ACCOUNT_INTRO_URL)}
        onClick={() => {
          dataCustomerTrackingService.postEvent({
            eventName: "clickedOpenAccountButton",
            domain: FeatureDomain.SAVINGS_ACCOUNT,
            subDomain: SubDomain.CREATE_ACCOUNT,
          });
        }}
        label={children}
        icon="Plus"
      />
    );
  }
};
