import {
  useContext,
  useEffect,
  useRef,
  useState,
  VoidFunctionComponent,
} from "react";
import {
  Button,
  LysaFormRef,
  Form,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Card,
} from "@lysaab/ui-2";
import {
  CrsResponse,
  dataCrs,
  mapCrsResponseToRequest,
} from "../../../../../data/dataCrs";
import { TranslatedText } from "../../../../../components/TranslatedText";
import "./Crs.scss";
import { EventTracker } from "../../../../../components/eventTracker/EventTracker";
import { hasCrsBeenUpdated } from "../../../../../pages/updateCrsStory/updateCrsInformation/UpdateCrsInformation";
import {
  CrsContext,
  CrsContextProvider,
} from "../../../../../pages/updateCrsStory/context/CrsContext";
import { CrsFormFields } from "../../../components/crsFormFields/CrsFormFields";
import { TrackerEvent } from "../../../../../data/dataCustomerTracking";
import { useCountry } from "../../../../../context/LocalizationContext";

export interface CrsProps {
  next: () => void;
}

const CrsInstance: VoidFunctionComponent<CrsProps> = ({ next }) => {
  const crsContext = useContext(CrsContext);
  const [serverCrs, setServerCrs] = useState<CrsResponse>();
  const [hasCrsChanged, setHasCrsChanged] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { crsInformation } = crsContext.state;
  const crsSetState = crsContext.setState;
  const country = useCountry();
  const currentCountry = useRef(country);

  const formRef = useRef<LysaFormRef>();

  useEffect(() => {
    if (crsInformation) {
      return;
    }

    setIsLoading(true);
    dataCrs
      .getLatestCrs()
      .then((serverCrs) => {
        if (!currentCountry.current) {
          return;
        }
        const temp = JSON.parse(JSON.stringify(serverCrs)) as CrsResponse;
        const hasSigningCountryAsTaxCountry = temp.countries.some(
          (country) => country.country === currentCountry.current
        );
        if (!hasSigningCountryAsTaxCountry) {
          temp.countries = [
            {
              country: currentCountry.current,
              tin: "",
            },
            ...temp.countries,
          ];
        }
        setServerCrs(temp);
        crsSetState({ crsInformation: temp });
      })
      .catch(() => setError(true))
      .finally(() => setIsLoading(false));
  }, [crsInformation, crsSetState]);

  useEffect(() => {
    if (
      serverCrs &&
      typeof crsInformation !== "undefined" &&
      !!crsInformation
    ) {
      setHasCrsChanged(hasCrsBeenUpdated(crsInformation, serverCrs));
    }
  }, [crsInformation, hasCrsChanged, serverCrs]);

  if (isLoading) {
    return <Spinner />;
  }

  const postCrsInformation = (crsInformation: CrsResponse) => {
    setIsLoading(true);
    dataCrs
      .postUpdateCrs(mapCrsResponseToRequest(crsInformation, country))
      .then(() => {
        EventTracker.track({ event: TrackerEvent.YEARLY_REVIEW_CRS });
        next();
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (
    typeof crsInformation === "undefined" ||
    typeof crsContext.state.crsInformation === "undefined"
  ) {
    return null;
  }

  return (
    <div className="update-crs">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (formRef.current?.isValid && crsInformation) {
            postCrsInformation(crsInformation);
          }
        }}
      >
        <h2>
          <TranslatedText id="denmark.yearly.crs.header" />
        </h2>

        <Card>
          {error && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="denmark.yearly.crs.error" />
            </Snackbar>
          )}
          <CrsFormFields
            data={crsContext.state.crsInformation}
            setData={crsContext.setState}
          />
        </Card>
        <p>
          <TranslatedText id="denmark.yearly.crs.nextConfirmText" />
        </p>
        <Button
          type="submit"
          block
          label={<TranslatedText id="denmark.yearly.crs.button.next" />}
        />
      </Form>
    </div>
  );
};

export const Crs: VoidFunctionComponent<CrsProps> = (props) => {
  return (
    <CrsContextProvider>
      <CrsInstance {...props} />
    </CrsContextProvider>
  );
};
