import { Icon } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { Amount } from "../../../../components/amount/Amount";
import { FormattedPercentage } from "../../../../components/formattedPercentage/FormattedPercentage";
import { SmallPosition } from "../../../../data/dataFunds";

interface Props {
  positions: SmallPosition[];
  percent: number;
  worth?: number;
}

export const RemainingPositions: React.VFC<Props> = ({
  positions,
  percent,
  worth,
}) => {
  if (positions.length === 0) {
    return null;
  }

  return (
    <>
      <dt>
        <b>
          <Icon.Add size={10} />
          <FormattedMessage
            id="accountPage.positions.remaining.other"
            values={{ count: positions.length }}
          />
        </b>
      </dt>
      <dd>
        <span>
          <FormattedPercentage value={percent || 0} decimals={2} />
        </span>
        <span className="positions-currency">
          {worth && <Amount amount={(worth / 100) * percent} decimals={2} />}
        </span>
      </dd>
    </>
  );
};
