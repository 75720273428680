// Based on https://github.com/mattyork/fuzzy

// If no match, return null
function match(pattern: string, str: string) {
  let patternIndex = 0;
  let totalScore = 0;
  let currScore = 0;
  let startOfStrWord = 0;
  let startOfPatternWord = 0;
  let wholePatternWords = [];

  const compareString = str.toLowerCase();
  const lowercasePattern = pattern.toLowerCase();

  if (compareString === lowercasePattern) {
    // if the string is an exact match with pattern, totalScore should be maxed
    return Infinity;
  }

  // For each character in the string, calculate the score
  for (var i = 0; i < str.length; i++) {
    if (i === 0 || str[i - 1] === " ") {
      startOfStrWord = i;
    }

    if (patternIndex === 0 || pattern[patternIndex - 1] === " ") {
      startOfPatternWord = patternIndex;
    }

    if (compareString[i] === lowercasePattern[patternIndex]) {
      patternIndex += 1;

      // consecutive characters should increase the score more than linearly,
      // so we double it and add one
      currScore += 1 + currScore;

      // Favor matches early in the string
      // I want the increase here to be >= 0 and the "bonus" for a match on
      // a given index should be the same no matter how long the string is
      // So the score for matching 'a' on index 2 should be the same in both
      // strings here (and the earlier the match is, the higher the score
      // should be):
      //   foam
      //   transportation
      const earlyBonus = 30 - i;
      currScore += earlyBonus < 0 ? 0 : earlyBonus;
    } else {
      currScore = 0;
    }

    if (
      patternIndex === pattern.length - 1 ||
      pattern[patternIndex + 1] === " "
    ) {
      wholePatternWords.push(
        lowercasePattern.slice(startOfPatternWord, patternIndex + 1)
      );
    }

    if (i === str.length - 1 || str[i + 1] === " ") {
      // We're at the end of a word in str
      const wholeWord = compareString.slice(startOfStrWord, i + 1);

      if (wholePatternWords.includes(wholeWord)) {
        // I've found 50_000 to be a good number through trial and error.
        // Feel free to change it if you find something better, but please
        // add tests to demonstrate that your new number is better
        totalScore += 50_000;
      }
    }

    totalScore += currScore;
  }

  // patternIndex === pattern.length means every character in the pattern
  // had a match
  return patternIndex === pattern.length ? totalScore : null;
}

export interface Match {
  score: number;
  index: number;
  element: string;
}

// Filters `arr` for matches against `pattern`.
// It returns an array with matching values of the type:
//
//     [{
//       score: 5 // The search match score
//       index: 2 // The index of the element in `arr`
//       element: 'blah' // The original element in `arr`
//     }]
//
export function metadataFilter(
  pattern: string,
  arr: Array<string>
): Array<Match> {
  return (
    arr
      .reduce<Array<Match>>(function (acc, element, index) {
        const score = match(pattern, element);

        if (score !== null) {
          acc[acc.length] = { score, index, element };
        }

        return acc;
      }, [])

      // Sort by score. Browsers are inconsistent wrt stable/unstable
      // sorting, so force stable by using the index in the case of tie.
      // See http://ofb.net/~sethml/is-sort-stable.html
      .sort(function (a, b) {
        const compare = b.score - a.score;
        return compare ? compare : a.index - b.index;
      })
  );
}

export function filter(pattern: string, arr: Array<string>) {
  return metadataFilter(pattern, arr).map((match) => match.element);
}
