import { Checkbox, RequiredValidator, DocModalLink } from "@lysaab/ui-2";
import { useContext, useState } from "react";
import AnimateHeight from "react-animate-height";
import { defineMessages, useIntl } from "react-intl";
import { TranslatedText } from "../../../../components/TranslatedText";
import { UserContext } from "../../../../context/UserContext";
import { LegalEntityType } from "../../../../data/dataLogin";

const messages = defineMessages({
  acceptTerms: {
    id: "sweden.autogiroConditions.terms-accept",
  },
  termsRequired: {
    id: "sweden.autogiroConditions.terms-required",
  },
});

export const AutogiroConditions = ({
  showConsentConditions,
  hideDescription,
}: {
  showConsentConditions: boolean;
  hideDescription?: boolean;
}) => {
  const intl = useIntl();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const userContext = useContext(UserContext);

  return (
    <AnimateHeight height={showConsentConditions ? "auto" : 0} animateOpacity>
      {!hideDescription && (
        <TranslatedText id="sweden.autogiroConditions.autogiro-agreement-details" />
      )}
      <p>
        <DocModalLink
          modalAnnouncement=""
          document={
            userContext.state.legalEntityType === LegalEntityType.PERSON
              ? "legal/se/sv/villkor-för-insättning-via-autogiro.md"
              : "legal/se/sv/villkor-för-insättning-och-betalning-via-autogiro.md"
          }
        >
          <TranslatedText id="sweden.autogiroConditions.autogiro-agreement-details.link" />
        </DocModalLink>
      </p>
      <Checkbox
        alternative={{
          text: intl.formatMessage(messages.acceptTerms),
          value: true,
        }}
        validators={
          showConsentConditions
            ? [
                new RequiredValidator(
                  intl.formatMessage(messages.termsRequired)
                ),
              ]
            : []
        }
        checked={acceptedTerms}
        onChange={() => {
          setAcceptedTerms(!acceptedTerms);
        }}
      />
    </AnimateHeight>
  );
};
