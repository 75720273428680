import { FunctionComponent } from "react";
import { Page, PageHeader } from "../../../Page";
import { TranslatedText } from "../../../../components/TranslatedText";
import { Mandates } from "./mandates/Mandates";

export const MONTHLY_AGREEMENTS_PAGE_URL = "/monthly-agreements";

export const MonthlyAgreements: FunctionComponent = () => {
  return (
    <Page>
      <PageHeader>
        <h1>
          <TranslatedText id="monthlyAgreements.header" />
        </h1>
      </PageHeader>
      <Mandates />
    </Page>
  );
};
