import { Alternative } from "@lysaab/ui-2";
import { defineMessages, IntlShape } from "react-intl";

export enum WithdrawalReason {
  WONT_STATE = "Do not want to state the reason",
  USE_MONEY = "I am going to use the money",
  DECLINING_MARKET = "I believe the market will decline",
  LOWER_RISK = "I want to lower my risk and put the money in a savings account",
  MORE_ACTIVE = "The service is not for me, I want to be more active",
  BETTER_RETURN = "I expected a better return on my investment",
  TRANSFER_TO_BANK = "The bank wants me to transfer all my savings to them",
  NOT_SATISFIED = "Not satisfied with the customer experience/support",
  OTHER = "Other reason",
}

export const NoReasonSelected = "No reason selected";

interface MessageWithId {
  id: string;
}

export const WithdrawalReasonMessages = defineMessages<
  WithdrawalReason,
  MessageWithId,
  Record<WithdrawalReason, MessageWithId>
>({
  [WithdrawalReason.WONT_STATE]: {
    id: "withdrawal.request.story.reason.WONT_STATE",
  },
  [WithdrawalReason.USE_MONEY]: {
    id: "withdrawal.request.story.reason.USE_MONEY",
  },
  [WithdrawalReason.DECLINING_MARKET]: {
    id: "withdrawal.request.story.reason.DECLINING_MARKET",
  },
  [WithdrawalReason.LOWER_RISK]: {
    id: "withdrawal.request.story.reason.LOWER_RISK",
  },
  [WithdrawalReason.MORE_ACTIVE]: {
    id: "withdrawal.request.story.reason.MORE_ACTIVE",
  },
  [WithdrawalReason.BETTER_RETURN]: {
    id: "withdrawal.request.story.reason.BETTER_RETURN",
  },
  [WithdrawalReason.TRANSFER_TO_BANK]: {
    id: "withdrawal.request.story.reason.TRANSFER_TO_BANK",
  },
  [WithdrawalReason.NOT_SATISFIED]: {
    id: "withdrawal.request.story.reason.NOT_SATISFIED",
  },
  [WithdrawalReason.OTHER]: { id: "withdrawal.request.story.reason.OTHER" },
});

export function getWithdrawalReasonAlternatives(
  intl: IntlShape
): Alternative<WithdrawalReason>[] {
  return Object.values(WithdrawalReason).map((reason) => {
    return {
      text: intl.formatMessage(WithdrawalReasonMessages[reason]),
      value: reason,
    };
  });
}
