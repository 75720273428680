import { createContext } from "../../../context/CreateContext";

export interface AccountSituationState {
  forceErrorsAccountsAllocation: boolean;
  directAccessGuard?: boolean;
  returnUrl?: string;
}

export const [AccountSituationContextProvider, useAccountSituationContext] =
  createContext<AccountSituationState>("AccountSituationContext", {
    forceErrorsAccountsAllocation: false,
  });
