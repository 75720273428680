import { useEffect, useRef, useState } from "react";
import {
  Card,
  Form,
  LysaFormRef,
  Button,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  TextInput,
} from "@lysaab/ui-2";
import { TranslatedText } from "../../../../components/TranslatedText";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { dataWithdrawals } from "../../../../data/dataWithdrawals";
import { UniqueValidator } from "../../../../components/validators/UniqueValidator";
import {
  dataLogin,
  InitVerificationResult,
  VerificationType,
} from "../../../../data/dataLogin";
import { useLocalizationContext } from "../../../../context/LocalizationContext";
import { LysaCountry } from "@lysaab/shared";

interface Props {
  next: (
    result: InitVerificationResult.EMAIL_CODE | InitVerificationResult.TOTP_CODE
  ) => void;
}

const messages = defineMessages({
  label: { id: "withdrawal.add.manual.account-information.number.label" },
  required: { id: "withdrawal.add.manual.account-information.number.required" },
  unique: { id: "withdrawal.add.manual.account-information.number.unique" },
});

export function AccountInformation({ next }: Props) {
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [accountNumber, setAccountNumber] = useState("");
  const [accounts, setAccounts] = useState<string[]>();
  const { country } = useLocalizationContext().state;
  const elevioArticleId: Record<LysaCountry, number> = {
    [LysaCountry.SWEDEN]: 149,
    [LysaCountry.FINLAND]: 149,
    [LysaCountry.DENMARK]: 149,
    [LysaCountry.GERMANY]: 290,
    [LysaCountry.SPAIN]: 149,
  };

  const twoFaUrlPostfix: Record<LysaCountry, string> = {
    [LysaCountry.SWEDEN]: "",
    [LysaCountry.FINLAND]: "/fi",
    [LysaCountry.DENMARK]: "",
    [LysaCountry.GERMANY]: "",
    [LysaCountry.SPAIN]: "",
  };

  useEffect(() => {
    dataWithdrawals.getExternalAccounts().then((resp) => {
      const accountNumbers = resp.map((account) => account.externalBankAccount);
      setAccounts(accountNumbers);
      setIsLoading(false);
    });
  }, []);

  if (isLoading || !country) {
    return <Spinner />;
  }

  return (
    <div>
      <h2>
        <TranslatedText id="withdrawal.add.manual.account-information.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            return;
          }
          setIsLoading(true);
          dataLogin
            .postDataWithVerification(
              {
                verificationScenario: VerificationType.SINGLE_EXTERNAL_ACCOUNT,
                payload: accountNumber,
              },
              twoFaUrlPostfix[country]
            )
            .then((response) => {
              if (
                response?.result === InitVerificationResult.EMAIL_CODE ||
                response?.result === InitVerificationResult.TOTP_CODE
              ) {
                next(response.result);
                return;
              } else {
                setIsLoading(false);
              }
            });
        }}
      >
        <Card>
          <TextInput
            label={intl.formatMessage(messages.label)}
            value={accountNumber}
            onChange={(value) => setAccountNumber(value)}
            validators={[
              new RequiredValidator(intl.formatMessage(messages.required)),
              new UniqueValidator(
                accounts || [],
                intl.formatMessage(messages.unique)
              ),
            ]}
          />
          <Snackbar type={SNACKBAR_TYPES.INFO}>
            <p>
              <FormattedMessage
                id="withdrawal.add.manual.account-information.info"
                values={{
                  help: (parts) => {
                    return (
                      <span data-elevio-article={elevioArticleId[country]}>
                        <span className="loner link">{parts.join()}</span>
                      </span>
                    );
                  },
                }}
              />
            </p>
          </Snackbar>
        </Card>
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="withdrawal.add.manual.account-information.next" />
          }
        />
      </Form>
    </div>
  );
}
