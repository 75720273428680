import { Button } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { LOGIN_USERNAME_PASSWORD_PAGE_URL } from "../../login/LoginUsernamePasswordPage";

export const Done = () => {
  return (
    <div>
      <h2>
        <FormattedMessage id="username-reminder.done.header" />
      </h2>
      <p>
        <FormattedMessage id="username-reminder.done.paragraph" />
      </p>
      <Button
        block
        component={Link}
        to={getNavLink(LOGIN_USERNAME_PASSWORD_PAGE_URL)}
        label={<FormattedMessage id="username-reminder.done.button" />}
      />
    </div>
  );
};
