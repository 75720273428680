import { BANK_ICON_CLASS } from "../../BankIcon";
import Icon from "./handelsbanken.svg";

export function Handelsbanken() {
  return (
    <div className={BANK_ICON_CLASS}>
      <img src={Icon} alt="Handelsbanken icon" />
    </div>
  );
}
