import { EMPTY_PEP_STATE, PepContextProps, PepType } from "@lysaab/lysa-pep";
import { EventTracker } from "../../components/eventTracker/EventTracker";
import { TrackerEvent } from "../../data/dataCustomerTracking";
import { LocationDescriptorObject } from "history";
import { useMultiPepContext, MultiPepState } from "./contexts/MultiPepContext";

export function getNextPepIsPep(
  ROUTE_PATHS: any,
  [multiPepState, setMultiPepState]: ReturnType<typeof useMultiPepContext>,
  pepContext: PepContextProps
): string {
  const pepStates = multiPepState.pepStates;

  if (!pepStates) {
    throw new Error("YearlyReviewContext doesn't contain any pep states");
  }

  if (pepContext.state.type === PepType.NOT_PEP) {
    pepStates[multiPepState.index] = {
      ...pepContext.state,
    };
    pepContext.setState({
      ...EMPTY_PEP_STATE,
      ...pepStates[pepStates.length - 1],
    });
    setMultiPepState({
      pepStates,
      index: pepStates.length - 1,
      isEditing: false,
    });
    return ROUTE_PATHS.PEP_OVERVIEW;
  } else if (pepContext.state.type === PepType.ME) {
    return ROUTE_PATHS.PEP_STATUS;
  } else {
    return ROUTE_PATHS.PEP_RELATION;
  }
}

export function getNextPepIsPepWrapper(
  ROUTE_PATHS: any,
  [multiPepState, setMultiPepState]: ReturnType<typeof useMultiPepContext>,
  pepContext: PepContextProps
): string {
  const pepStates = multiPepState.pepStates;

  if (!pepStates) {
    throw new Error("YearlyReviewContext doesn't contain any pep states");
  }

  const index = pepStates.findIndex(
    (pep) => !pep.locked && pep.type !== PepType.NOT_PEP
  );
  const firstNotLockedPep = pepStates[index];

  if (firstNotLockedPep && firstNotLockedPep.type) {
    if (firstNotLockedPep.type === PepType.ME) {
      pepContext.setState({
        ...EMPTY_PEP_STATE,
        ...firstNotLockedPep,
      });
      setMultiPepState({ index });

      return ROUTE_PATHS.PEP_STATUS;
    } else if (
      firstNotLockedPep.type === PepType.RELATIVE ||
      firstNotLockedPep.type === PepType.COLLEAGUE
    ) {
      pepContext.setState({
        ...EMPTY_PEP_STATE,
        ...firstNotLockedPep,
      });
      setMultiPepState({ index });

      return ROUTE_PATHS.PEP_RELATION;
    }
  }

  if (pepStates.length > 1) {
    return ROUTE_PATHS.PEP_OVERVIEW;
  }

  EventTracker.track({
    event: TrackerEvent.YEARLY_REVIEW_PEP_EDIT,
    message: multiPepState.pepStates?.map((pepState) => ({
      tin: pepState.tin,
      locked: pepState.locked,
    })),
  });
  return ROUTE_PATHS.SITUATION;
}

export function getNextPepStatus(ROUTE_PATHS: any): string {
  return ROUTE_PATHS.PEP_ROLE;
}

export function getNextPepRole(
  ROUTE_PATHS: any,
  [multiPepState, setMultiPepState]: ReturnType<typeof useMultiPepContext>,
  pepContext: PepContextProps
): string {
  const pepStates = multiPepState.pepStates;
  const currentIndex = multiPepState.index;
  if (!pepStates) {
    throw new Error("YearlyReviewContext doesn't contain any pep states");
  }

  pepStates[currentIndex] = { ...pepContext.state };
  setMultiPepState({ pepStates });

  if (pepStates.length === 1) {
    EventTracker.track({
      event: TrackerEvent.YEARLY_REVIEW_PEP_EDIT,
      message: multiPepState.pepStates?.map((pepState) => ({
        tin: pepState.tin,
        locked: pepState.locked,
      })),
    });
    return ROUTE_PATHS.SITUATION;
  }

  let newIndex = currentIndex + 1;

  if (newIndex === pepStates.length || multiPepState.isEditing) {
    pepContext.setState({
      ...EMPTY_PEP_STATE,
      ...pepStates[pepStates.length - 1],
    });
    setMultiPepState({
      index: pepStates.length - 1,
      isEditing: false,
    });

    return ROUTE_PATHS.PEP_OVERVIEW;
  }

  while (newIndex < pepStates.length) {
    if (pepStates[newIndex].type === PepType.ME) {
      pepContext.setState({
        ...EMPTY_PEP_STATE,
        ...pepStates[newIndex],
      });
      setMultiPepState({
        index: newIndex,
      });

      return ROUTE_PATHS.PEP_STATUS;
    } else if (
      pepStates[newIndex].type === PepType.RELATIVE ||
      pepStates[newIndex].type === PepType.COLLEAGUE
    ) {
      pepContext.setState({
        ...EMPTY_PEP_STATE,
        ...pepStates[newIndex],
      });
      setMultiPepState({
        index: newIndex,
      });

      return ROUTE_PATHS.PEP_RELATION;
    }

    if (newIndex === pepStates.length - 1) {
      break;
    } else {
      newIndex++;
    }
  }
  return ROUTE_PATHS.PEP_OVERVIEW;
}

export function getRoutePepOverview(
  ROUTE_PATHS: any,
  multiPepState: MultiPepState
): string {
  EventTracker.track({
    event: TrackerEvent.YEARLY_REVIEW_PEP_EDIT,
    message: multiPepState.pepStates?.map((pepState) => ({
      tin: pepState.tin,
      locked: pepState.locked,
    })),
  });
  return ROUTE_PATHS.SITUATION;
}

export function navigateIsPepFromPepOverview(
  ROUTE_PATHS: any,
  [multiPepState, setMultiPepState]: ReturnType<typeof useMultiPepContext>,
  pepContext: PepContextProps,
  safeNavigation: <T>(routeObj: string | LocationDescriptorObject<T>) => void
) {
  return (index: number) => {
    const pepStates = multiPepState.pepStates;
    if (!pepStates) {
      throw new Error("YearlyReviewContext doesn't contain any pep states");
    }
    pepContext.setState({
      ...EMPTY_PEP_STATE,
      ...pepStates[index],
    });
    setMultiPepState({
      index,
      isEditing: true,
    });
    safeNavigation(ROUTE_PATHS.PEP_IS_PEP);
  };
}
