import { FunctionComponent, useRef, useEffect } from "react";
import { Alternative, Form, LysaFormRef, Button } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { SavingsHorizonLengthInput } from "./questions/SavingsHorizonLengthInput";
import { NeedEarlierProbabilityInput } from "./questions/NeedEarlierProbabilityInput";
import {
  SavingsHorizonLength,
  NeedEarlierProbability,
} from "../../data/dataInvestments";
import { AccountType } from "../../data/dataAccounts";
import { KfHorizonDissuasion } from "../../components/kfDissuasion/KfHorizonDissuasion";

interface Props {
  horizonLength?: SavingsHorizonLength | Alternative<SavingsHorizonLength>;
  setHorizonLength: (horizonLength: Alternative<SavingsHorizonLength>) => void;
  horizonProbability?:
    | NeedEarlierProbability
    | Alternative<NeedEarlierProbability>;
  setHorizonProbability: (
    horizonProbability: Alternative<NeedEarlierProbability>
  ) => void;
  next: () => void;
  accountType: AccountType;
}

export const Horizon: FunctionComponent<Props> = ({
  horizonLength,
  setHorizonLength,
  horizonProbability,
  setHorizonProbability,
  next,
  accountType,
}) => {
  const formRef = useRef<LysaFormRef>();

  useEffect(() => {
    /**
     * Make sure we are allowed to be here
     */
  }, []);

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={(event) => {
        event.preventDefault();
        if (formRef.current?.isValid) {
          next();
        }
      }}
    >
      <div>
        <h2>
          <FormattedMessage id="horizon.header" />
        </h2>

        <section>
          <SavingsHorizonLengthInput
            savingsHorizonLength={horizonLength}
            setSavingsHorizonLength={setHorizonLength}
          />
          <NeedEarlierProbabilityInput
            needEarlierProbability={horizonProbability}
            setNeedEarlierProbability={setHorizonProbability}
          />
        </section>

        <KfHorizonDissuasion
          accountType={accountType}
          horizonLength={
            typeof horizonLength === "string"
              ? horizonLength
              : horizonLength?.value
          }
        />

        <Button
          block
          type="submit"
          label={<FormattedMessage id="horizon.button.next" />}
        />
      </div>
    </Form>
  );
};
