import { defineMessages } from "react-intl";
import { Interval } from "../data/dataPerformance";

interface MessageWithId {
  id: string;
}

export const summaryHeaderIntervalMessages = defineMessages<
  Interval,
  MessageWithId,
  Record<Interval, MessageWithId>
>({
  [Interval.LAST_THREE_MONTHS]: {
    id: "summaryHeader.interval.lastThreeMonths",
  },
  [Interval.LAST_SIX_MONTHS]: {
    id: "summaryHeader.interval.lastSixMonths",
  },
  [Interval.LAST_YEAR]: {
    id: "summaryHeader.interval.lastYear",
  },
  [Interval.CURRENT_YEAR]: {
    id: "summaryHeader.interval.currentYear",
  },
  [Interval.SINCE_REGISTRATION]: {
    id: "summaryHeader.interval.sinceRegistration",
  },
  [Interval.CUSTOM]: {
    id: "summaryHeader.interval.custom",
  },
});

export const overviewAccountIntervalMessages = defineMessages<
  Interval,
  MessageWithId,
  Record<Interval, MessageWithId>
>({
  [Interval.LAST_THREE_MONTHS]: {
    id: "overview.accounts.interval.lastThreeMonths",
  },
  [Interval.LAST_SIX_MONTHS]: {
    id: "overview.accounts.interval.lastSixMonths",
  },
  [Interval.LAST_YEAR]: {
    id: "overview.accounts.interval.lastYear",
  },
  [Interval.CURRENT_YEAR]: {
    id: "overview.accounts.interval.currentYear",
  },
  [Interval.SINCE_REGISTRATION]: {
    id: "overview.accounts.interval.sinceRegistration",
  },
  [Interval.CUSTOM]: {
    id: "overview.accounts.interval.custom",
  },
});
