import { IntervalCustomDates } from "../../../../../../overview/IntervalCustomDates";
import { IntervalSwitch } from "../../../../../../overview/IntervalSwitch";

import "./IntervalSwitches.scss";

export const IntervalSwitches = () => {
  return (
    <div className="interval-switches">
      <IntervalSwitch />
      <IntervalCustomDates />
    </div>
  );
};
