import { Spinner, CardList, CardListItem, cache } from "@lysaab/ui-2";
import { Page } from "@react-pdf/renderer";
import { useContext, useEffect, useState } from "react";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import {
  dataLogin,
  LysaRight,
  useBootstrapUser,
} from "../../../../../../data/dataLogin";
import NumberFormat from "react-number-format";
import "./CompanyUserList.scss";
import { useUserStorage } from "../../../../../../context/UserStorageContext";
import {
  setUserState,
  UserContext,
} from "../../../../../../context/UserContext";
import { EventTracker } from "../../../../../../components/eventTracker/EventTracker";
import { queryClient } from "../../../../../../ReactQueryProvider";

export const CompanyUserList = () => {
  const [rights, setRights] = useState<LysaRight[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<boolean>(false);
  const userContext = useContext(UserContext);
  const { setUserStorage } = useUserStorage();
  const onUserBootstrap = useBootstrapUser();

  const loadRights = () => {
    dataLogin
      .getRights()
      .then((response) => {
        setRights(
          response.sort((a, b) => {
            if (a.active) {
              return -1;
            } else if (b.active) {
              return 1;
            }

            return a.name.localeCompare(b.name);
          })
        );

        setIsLoading(false);
      })
      .catch((e) => {
        console.warn(e);
        setError(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadRights();
  }, []);

  if (isLoading) {
    return (
      <div className="switch-user-selection">
        <Spinner />
      </div>
    );
  }

  if (error || rights.length < 2) {
    return null;
  }

  return (
    <Page>
      <div className="switch-user-selection">
        <h3>Välj en företagsanvändare</h3>
        {rights
          .filter(
            (entity) =>
              !entity.active &&
              !entity.tin.startsWith("19") &&
              !entity.tin.startsWith("20")
          )
          .map((entity) => (
            <button
              className="transparent-button user-card"
              type="button"
              onClick={() => {
                setIsLoading(true);
                dataLogin
                  .switchUser(entity.accessRightsId)
                  .then(() => {
                    onUserBootstrap().then(
                      ([bootstrap, legalEntity, userStates]) => {
                        cache.clear();
                        queryClient.invalidateQueries();
                        setUserState(
                          bootstrap,
                          legalEntity,
                          userContext.setState
                        );
                        setUserStorage(userStates);
                        dataLogin.keepAlive();
                        EventTracker.reset();
                        setError(false);
                      }
                    );
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }}
            >
              <CardList>
                <CardListItem>
                  <div className="button-wrapper button-list-item">
                    <div className="left-wrapper">
                      <div className="button-text-wrapper">
                        <span className="list-name">{entity.name}</span>
                        <NumberFormat
                          className="list-id"
                          displayType="text"
                          format={
                            entity.tin.length === 10
                              ? "######-####"
                              : "########-####"
                          }
                          value={entity.tin}
                        />
                      </div>
                    </div>
                    <span>
                      <TranslatedText id="switchUser.login.login" />
                    </span>
                  </div>
                </CardListItem>
              </CardList>
            </button>
          ))}
      </div>
    </Page>
  );
};
