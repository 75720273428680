import { AnimatePresence, motion } from "framer-motion";
import { InvestmentAccount, getAccountAmount } from "../../data/dataAccounts";
import { Amount } from "../amount/Amount";
import { Typography } from "@lysaab/ui-2";
import { useAccountInvestmentHorizon } from "../../hooks/useAccountInvestmentHorizon";
import { savingsHorizonLengthMessages } from "../../pageComponents/horizon/questions/SavingsHorizonLengthInput";
import { useIntl } from "react-intl";

interface Props {
  account: InvestmentAccount;
}

export const LysaAccountSelectionCardAccount: React.VFC<Props> = ({
  account,
}) => {
  const intl = useIntl();
  const accountHorizon = useAccountInvestmentHorizon(account.accountId);

  return (
    <>
      <div>
        <Typography type="label" component="span">
          <Amount amount={getAccountAmount(account)} />
        </Typography>
      </div>
      <AnimatePresence>
        {accountHorizon && (
          <motion.span
            transition={{ duration: 0.15 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <Typography type="body-small" component="span">
              {intl.formatMessage(savingsHorizonLengthMessages[accountHorizon])}
            </Typography>
          </motion.span>
        )}
      </AnimatePresence>
      {!accountHorizon && <span>&nbsp;</span>}
    </>
  );
};
