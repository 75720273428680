import { useEffect, useState } from "react";
import { SavingsAccountId } from "../data/dataAccounts";
import {
  dataSavingsAccountDistribution,
  ExternalRebalancingTransferResponse,
} from "../data/dataSavingsAccountDistribution";

export function useHasOngoingPartnerBalancing(accountId?: SavingsAccountId) {
  const [useHasOngoingPartnerBalancing, setuseHasOngoingPartnerBalancing] =
    useState<ExternalRebalancingTransferResponse | null>();

  useEffect(() => {
    if (typeof accountId === "undefined") return;

    dataSavingsAccountDistribution
      .getOngoingBalancing(accountId)
      .then(setuseHasOngoingPartnerBalancing);
  }, [accountId]);

  return useHasOngoingPartnerBalancing;
}
