import { RefObject, VoidFunctionComponent } from "react";
import { Button, RequiredValidator, HiddenInput } from "@lysaab/ui-2";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { CardPaneListItem } from "../../../../components/cardPane/CardPaneListItem";
import { UpdateInvestmentAccountComposition } from "../AccountAdviceCard";
import { useAccountName } from "../../../../hooks/useAccountName";
import { AccountUpdateAction } from "../../../../pages/reviewAccount/ReviewAccountContext";

const messages = defineMessages({
  formErrorRequired: {
    id: "summary.accountCardButtons.UpdateOrKeep.error.required",
  },
});

type Props = {
  containerRef: RefObject<HTMLDivElement>;
  account: UpdateInvestmentAccountComposition;
  updateAccount: (accountAction: AccountUpdateAction) => void;
};

export const UpdateOrKeep: VoidFunctionComponent<Props> = ({
  containerRef,
  account,
  updateAccount,
}) => {
  const intl = useIntl();
  const accountName = useAccountName(account.accountId, true);

  return (
    <CardPaneListItem>
      <p>
        <FormattedMessage id="summary.accountCard.lysa.advice.updated" />
      </p>

      <Button
        block
        onClick={() => updateAccount(AccountUpdateAction.ADVICE)}
        label={
          <FormattedMessage id="summary.accountCard.button.UpdateOrKeep.lysa.advice.updated" />
        }
      />

      <div className="button-middle-text">
        <FormattedMessage id="summary.accountCard.or" />
        <span> *</span>
      </div>

      <Button
        block
        onClick={() => updateAccount(AccountUpdateAction.KEEP)}
        label={
          <FormattedMessage id="summary.accountCard.button.UpdateOrKeep.keep" />
        }
      />

      <HiddenInput
        label={accountName}
        scrollToRef={containerRef}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.formErrorRequired)),
        ]}
      />
    </CardPaneListItem>
  );
};
