import { API } from "@lysaab/ui-2";
import { CompoundAccountId } from "./dataAccounts";

export type BankgiroResponse = Record<CompoundAccountId, string>;

export const dataDeposit = {
  getBankgiros: () => {
    return API.get<BankgiroResponse>(`/bankgiro`);
  },
};
