import { useCallback } from "react";
import { Redirect } from "react-router";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { BASE_ROUTES } from "../../../../../../pages/deposits/monthly/create/MonthlyStory";
import { useMonthlyContext } from "../../../../../../pages/deposits/monthly/create/MonthlyContext";
import { TinkAccount } from "../../../../../../data/dataTink";
import { AccountLoading } from "../../../../../../components/tink/accountLoading/AccountLoading";
import { TranslatedText } from "../../../../../../components/TranslatedText";

interface Props {
  next: () => void;
}

export function AccountLoadingWrapper({ next }: Props) {
  const monthlyContext = useMonthlyContext();
  const bank = monthlyContext.state.tinkBank;
  const setMonthlyContext = monthlyContext.setState;

  const onComplete = useCallback(
    (accounts: TinkAccount[], accountVerificationReportId: string) => {
      setMonthlyContext({
        tinkAccounts: accounts,
        accountVerificationReportId,
      });
      setTimeout(() => {
        next();
      }, 0);
    },
    [next, setMonthlyContext]
  );

  if (!bank) {
    return <Redirect to={getNavLink(BASE_ROUTES.INTRO)} />;
  }
  return (
    <AccountLoading
      fallbackOptions={{
        locationDescriptor: {
          pathname: getNavLink(BASE_ROUTES.MANUAL_ACCOUNT_SELECTION),
        },
        buttonText: (
          <TranslatedText id="sweden.deposits.monthly.story.account-loading-wrapper.fallbackButton" />
        ),
      }}
      bank={bank}
      onComplete={onComplete}
    />
  );
}
