import { Link } from "react-router-dom";
import { Card, Button } from "@lysaab/ui-2";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
} from "../../../../../../../../data/dataCustomerTracking";
import { getNavLink } from "../../../../../../../../hooks/useCountryUrls";
import { TranslatedText } from "../../../../../../../../components/TranslatedText";
import { TRANSFER_PENSIONS_URL } from "../../../../../transferPensions/TransferPensionsStory";

import "./MovePensionCard.scss";

export const MovePensionCard = () => {
  return (
    <Card className="move-pension-card">
      <h2>
        <TranslatedText id="sweden.createAccountIntroStory.movePensionCard.header" />
      </h2>
      <p>
        <TranslatedText id="sweden.createAccountIntroStory.movePensionCard.intro" />
      </p>
      <Button
        component={Link}
        to={getNavLink(TRANSFER_PENSIONS_URL)}
        onClick={() =>
          dataCustomerTrackingService.postEvent({
            eventName: "clickedMovePensionButton",
            domain: FeatureDomain.GENERAL,
            subDomain: SubDomain.TRANSFERS,
          })
        }
        block
        label={
          <TranslatedText id="sweden.createAccountIntroStory.movePensionCard.button" />
        }
      />
    </Card>
  );
};
