import { Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import {
  ShareAccountInvitation,
  ShareAccountSentInvitation,
} from "../../../data/dataAttorney";
import { ListItemAccountName } from "../listItems/ListItemAccountName";

interface Props {
  invitation: ShareAccountInvitation | ShareAccountSentInvitation;
}

export const TimedOutState: React.VFC<Props> = ({ invitation }) => {
  return (
    <div>
      {invitation.accountName && (
        <ListItemAccountName accountName={invitation.accountName} />
      )}
      <hr />
      <Snackbar type={SNACKBAR_TYPES.ERROR}>
        <FormattedMessage id="shareAccountStory.inviteCard.inviteTimedOutText" />
      </Snackbar>
    </div>
  );
};
