import { CardList } from "@lysaab/ui-2";
import { CountrySelectItem } from "./countrySelectItem/CountrySelectItem";
import { defineMessages, useIntl } from "react-intl";
import { LOGIN_NETS_PAGE_URL } from "../../pages/login/LoginNetsPage";
import { LOGIN_SWEDEN_PAGE_URL } from "../../countries/sweden/pages/LoginPage";
import { LysaCountry } from "@lysaab/shared";
import { countryRoutesMap } from "../../TopLevelRouter";
import { generatePath } from "react-router";
import { LOGIN_USERNAME_PASSWORD_PAGE_URL } from "../../pages/login/LoginUsernamePasswordPage";

const messages = defineMessages({
  header: { id: "country-select-page.header" },
});

export function CountrySelect() {
  const intl = useIntl();

  return (
    <div className="country-select">
      <h1>{intl.formatMessage(messages.header)}</h1>
      <CardList>
        <CountrySelectItem
          country={LysaCountry.SWEDEN}
          url={generatePath(
            countryRoutesMap[LysaCountry.SWEDEN] + LOGIN_SWEDEN_PAGE_URL
          )}
        />
        <CountrySelectItem
          country={LysaCountry.FINLAND}
          url={generatePath(
            countryRoutesMap[LysaCountry.FINLAND] + LOGIN_NETS_PAGE_URL
          )}
        />
        <CountrySelectItem
          country={LysaCountry.DENMARK}
          url={generatePath(
            countryRoutesMap[LysaCountry.DENMARK] + LOGIN_NETS_PAGE_URL
          )}
        />
        {
          <CountrySelectItem
            country={LysaCountry.GERMANY}
            url={generatePath(
              countryRoutesMap[LysaCountry.GERMANY] +
                LOGIN_USERNAME_PASSWORD_PAGE_URL
            )}
          />
        }
        {/* TODO: Enable when we're live in Spain */}
        {/* <CountrySelectItem
          country={LysaCountry.SPAIN}
          url={generatePath(
            countryRoutesMap[LysaCountry.SPAIN] + LOGIN_TIN_PASSWORD_PAGE_URL
          )}
        /> */}
      </CardList>
    </div>
  );
}
