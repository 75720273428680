import { Form, LysaFormRef, Button, Spinner } from "@lysaab/ui-2";
import { useEffect, useRef } from "react";
import { TranslatedText } from "../../../components/TranslatedText";
import { LysaAccountSelectionCard } from "../../../components/lysaAccountSelectionCard/LysaAccountSelectionCard";
import { useAccounts } from "../../../hooks/useAccounts";
import { useMonthlyInternalTransferContext } from "../MonthlyInternalTransferContext";
import { useHistory } from "react-router-dom";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { BASE_ROUTES } from "../MonthlyInternalTransferStory";

interface Props {
  next: () => void;
}

export function ToAccount({ next }: Props) {
  const history = useHistory();
  const { accounts } = useAccounts();
  const formRef = useRef<LysaFormRef>();
  const monthlyInternalTransferContext = useMonthlyInternalTransferContext();

  useEffect(() => {
    if (
      typeof monthlyInternalTransferContext.state.fromAccountId === "undefined"
    ) {
      return history.replace(getNavLink(BASE_ROUTES.FROM_ACCOUNT));
    }
  }, [history, monthlyInternalTransferContext.state.fromAccountId]);

  if (!accounts) {
    return <Spinner />;
  }

  const mergedAccounts = [
    ...accounts.investmentAccounts,
    ...accounts.savingsAccounts,
  ];

  const selectedAccount = mergedAccounts.find(
    (account) =>
      account.accountId === monthlyInternalTransferContext.state.toAccountId
  );

  return (
    <div>
      <h2>
        <TranslatedText id="monthly-internal-transfer.story.to-account-selection.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            return;
          }
          next();
        }}
      >
        <LysaAccountSelectionCard
          accounts={mergedAccounts.filter(
            (account) =>
              account.accountId !==
              monthlyInternalTransferContext.state.fromAccountId
          )}
          selectedAccount={selectedAccount}
          onChange={(account) => {
            monthlyInternalTransferContext.setState({
              toAccountId: account.accountId,
            });
          }}
          legend={
            <TranslatedText id="monthly-internal-transfer.story.to-account-selection.label" />
          }
        />
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="monthly-internal-transfer.story.to-account-selection.button" />
          }
        />
      </Form>
    </div>
  );
}
