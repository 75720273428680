import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { PerformanceContext } from "../../../context/PerformanceContext";
import {
  AllAccountResponse,
  CompoundAccountId,
} from "../../../data/dataAccounts";
import {
  dataPerformance,
  Interval,
  PerformanceAccount,
} from "../../../data/dataPerformance";
import { useAccounts } from "../../../hooks/useAccounts";

const TIMER = 10000;

export function useAccountPerformance(accountId: CompoundAccountId) {
  const [accountPerformance, setAccountPerformance] =
    useState<PerformanceAccount>({
      change: 0,
      earnings: 0,
    });
  const { interval, customStartDate, customEndDate } =
    useContext(PerformanceContext);
  const { accounts } = useAccounts();
  const timer = useRef<number | null>(null);

  const load = useCallback(
    (performanceInterval: { start: Date; end: Date }) => {
      dataPerformance
        .getOverviewPerformance(
          performanceInterval.start,
          performanceInterval.end
        )
        .then((accountPerformance) => {
          const accountData = { ...accountPerformance.accounts[accountId] };
          setAccountPerformance({
            change: isNaN(accountData.change) ? 0 : accountData.change,
            earnings: isNaN(accountData.earnings) ? 0 : accountData.earnings,
          });
        })
        .catch(() => {
          timer.current = window.setTimeout(() => {
            load(performanceInterval);
          }, TIMER);
        });
    },
    [accountId]
  );

  useEffect(() => {
    if (typeof accounts === "undefined") {
      return;
    }
    const performanceInterval = getPerformanceInterval(
      accounts,
      interval,
      customStartDate,
      customEndDate
    );

    if (typeof performanceInterval === "undefined") {
      return;
    }
    load(performanceInterval);
    return () => {
      if (timer.current) {
        window.clearTimeout(timer.current);
      }
    };
  }, [accounts, customEndDate, customStartDate, interval, load]);

  return accountPerformance;
}

const getPerformanceInterval = (
  accounts: AllAccountResponse,
  interval: Interval,
  customStartDate: Date | null,
  customEndDate: Date | null
) => {
  let start, end;
  if (interval === Interval.CUSTOM) {
    if (!customStartDate || !customEndDate || customStartDate > customEndDate) {
      return;
    }
    start = customStartDate;
    end = customEndDate;
  } else {
    start = dataPerformance.getStartDateFromUser(interval, [
      ...accounts.investmentAccounts,
      ...accounts.savingsAccounts,
      ...accounts.sharedInvestmentAccounts,
      ...accounts.sharedSavingsAccounts,
    ]);
    end = dataPerformance.getEndDate();
  }
  return { start, end };
};
