import * as React from "react";
import cx from "classnames";
import { Amount } from "../../components/amount/Amount";

import { CompoundAccount } from "../../data/dataAccounts";
import { PendingInternalTransfer } from "../../data/dataTransfer";

import "./PendingInternalTransferEntry.scss";
import { TranslatedText } from "../../components/TranslatedText";
import { DateTime } from "luxon";
import { Typography } from "@lysaab/ui-2";

export interface PendingInternalTransferEntryProps
  extends PendingInternalTransfer {
  fromAccount: CompoundAccount | undefined;
  toAccount: CompoundAccount | undefined;
}
interface Props {
  account: CompoundAccount | undefined;
  pendingInternalTransfer: PendingInternalTransferEntryProps;
}

export const PendingInternalTransferEntry: React.FC<Props> = ({
  account,
  pendingInternalTransfer,
}) => {
  const isDeposit = pendingInternalTransfer.toAccountId === account?.accountId;
  const isWithdrawal =
    pendingInternalTransfer.fromAccountId === account?.accountId;
  const classes = cx(
    "pending-internal-transfer-entry",
    `${
      isDeposit
        ? "pending-internal-transfer-entry-deposit"
        : "pending-internal-transfer-entry-withdrawal"
    }`
  );

  if (
    !account ||
    !pendingInternalTransfer.fromAccount ||
    !pendingInternalTransfer.toAccount
  ) {
    return null;
  }

  return (
    <div className={classes}>
      <div>
        <Typography type="body" variant="secondary" component="span">
          {DateTime.fromJSDate(
            new Date(pendingInternalTransfer.created)
          ).toFormat("yyyy-MM-dd")}
        </Typography>

        <div>
          <span className="pending-internal-transfer-entry-indicator" />{" "}
          {isDeposit ? (
            <TranslatedText
              id="accountPage.pending-internal-transfers.internal-deposit"
              values={{ fromName: pendingInternalTransfer.fromAccount.name }}
            />
          ) : (
            <TranslatedText
              id="accountPage.pending-internal-transfers.internal-withdrawal"
              values={{ toName: pendingInternalTransfer.toAccount.name }}
            />
          )}
        </div>
      </div>

      <div className="text-right">
        <Typography type="body" variant="secondary" component="span">
          <TranslatedText id="accountPage.pending-internal-transfers.amount" />
        </Typography>
        <div className="pending-internal-transfer-entry-amount">
          {typeof pendingInternalTransfer.amount === "undefined" ? (
            isDeposit ? (
              <TranslatedText id="accountPage.pending-internal-transfers.drain.amount" />
            ) : (
              isWithdrawal && (
                <TranslatedText id="accountPage.pending-internal-transfers.drain.amount" />
              )
            )
          ) : isDeposit ? (
            <Amount amount={pendingInternalTransfer.amount} decimals={2} />
          ) : (
            isWithdrawal && (
              <Amount
                amount={pendingInternalTransfer.amount * -1}
                decimals={2}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};
