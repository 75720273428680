import { Alternative, Select } from "@lysaab/ui-2";
import { useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { dataAccounts, CompoundAccountId } from "../../data/dataAccounts";
import "./AccountSelect.scss";

interface Props {
  onChange: (value: CompoundAccountId | string) => void;
  value?: CompoundAccountId | string;
}

const messages = defineMessages({
  label: {
    id: "shareAccountInvitationsPage.accountSelect.label",
  },
  placeholder: {
    id: "shareAccountInvitationsPage.accountSelect.placeholder",
  },
  allAccounts: {
    id: "shareAccountInvitationsPage.accountSelect.allAccounts",
  },
});

export const AccountSelect: React.VFC<Props> = ({ onChange, value }) => {
  const intl = useIntl();
  const [alternatives, setAlternatives] =
    useState<Alternative<CompoundAccountId | string>[]>();

  useEffect(() => {
    dataAccounts.getAllAccounts().then((accounts) => {
      setAlternatives(
        [
          ...accounts.investmentAccounts,
          ...accounts.savingsAccounts,
          ...accounts.sharedInvestmentAccounts,
          ...accounts.sharedSavingsAccounts,
        ].map(({ accountId, name }) => ({
          text: name,
          value: accountId,
        }))
      );
    });
  }, []);

  return (
    <div className="share-account-invitation-account-select">
      <Select
        alternatives={[
          { text: intl.formatMessage(messages.allAccounts), value: "" },
          ...(alternatives ? alternatives : []),
        ]}
        label={intl.formatMessage(messages.label)}
        placeholder={intl.formatMessage(messages.placeholder)}
        onChange={({ value }) => onChange(value)}
        value={{
          text: "",
          value: value ?? "",
        }}
      />
    </div>
  );
};
