export enum Browser {
  IE = "ie",
  EDGE = "edge",
  FIREFOX = "firefox",
  SAFARI = "safari",
  CHROME = "chrome",
  OTHER = "other",
}

export enum Device {
  WINDOWS_PHONE = "windows-phone",
  ANDROID = "android",
  IOS = "ios",
  OTHER = "other",
}

declare global {
  interface Window {
    InstallTrigger?: any;
    StyleMedia?: any;
  }

  interface Document {
    documentMode?: any;
  }
}

export function getDevice() {
  let device = Device.OTHER;
  let userAgent = window.navigator.userAgent.toLowerCase();
  let isIphone = userAgent.indexOf("iphone") > -1;
  let isAndroid = userAgent.indexOf("android") > -1;
  let isWindows = /windows phone/i.test(userAgent);
  if (isIphone) {
    device = Device.IOS;
  }
  if (isAndroid) {
    device = Device.ANDROID;
  }
  if (isWindows) {
    device = Device.WINDOWS_PHONE;
  }
  return device;
}

export function getBrowser() {
  let browser = Browser.OTHER;
  let userAgent = window.navigator.userAgent.toLowerCase();
  let isIphone = userAgent.indexOf("iphone") > -1;

  // https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
  // Firefox 1.0+
  if (typeof window["InstallTrigger"] !== "undefined") {
    browser = Browser.FIREFOX;
  }

  if (userAgent.indexOf("safari") !== -1 && userAgent.indexOf("chrome") < 0) {
    browser = Browser.SAFARI;
  }

  // Internet Explorer 6-11
  if (/*@cc_on!@*/ false || !!document.documentMode) {
    browser = Browser.IE;
  }

  // Edge 20+
  if (
    userAgent.indexOf("edgios/") > -1 ||
    userAgent.indexOf("edga/") > -1 ||
    (browser !== "ie" && !!window.StyleMedia)
  ) {
    browser = Browser.EDGE;
  }

  if (/chrome/.test(userAgent) && /Google Inc/i.test(window.navigator.vendor)) {
    browser = Browser.CHROME;
  }
  // https://stackoverflow.com/questions/13807810/ios-chrome-detection
  if (isIphone && userAgent.match("crios")) {
    browser = Browser.CHROME;
  }
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/User-Agent/Firefox#Firefox_for_iOS
  if (isIphone && userAgent.match("fxios")) {
    browser = Browser.CHROME;
  }

  return browser;
}
