import { VoidFunctionComponent } from "react";

interface Props {
  size?: number;
}

export const SwishIcon: VoidFunctionComponent<Props> = ({ size = 20 }) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 420 420"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>03 Symbol/Colour/Swish_Symbol_SVG</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <linearGradient
          x1="80.8150544%"
          y1="59.6365716%"
          x2="59.8678229%"
          y2="8.14952521%"
          id="linearGradient-1"
        >
          <stop stopColor="#EF2131" offset="0%"></stop>
          <stop stopColor="#FECF2C" offset="100%"></stop>
        </linearGradient>
        <linearGradient
          x1="58.8181173%"
          y1="0%"
          x2="9.24395007%"
          y2="94.0712606%"
          id="linearGradient-2"
        >
          <stop stopColor="#FBC52C" offset="0%"></stop>
          <stop stopColor="#F87130" offset="26.3921199%"></stop>
          <stop stopColor="#EF52E2" offset="56.079734%"></stop>
          <stop stopColor="#661EEC" offset="100%"></stop>
        </linearGradient>
        <linearGradient
          x1="20.5690473%"
          y1="41.0176198%"
          x2="40.5972643%"
          y2="91.2765373%"
          id="linearGradient-3"
        >
          <stop stopColor="#78F6D8" offset="0%"></stop>
          <stop stopColor="#77D1F6" offset="26.5705044%"></stop>
          <stop stopColor="#70A4F3" offset="55.447147%"></stop>
          <stop stopColor="#661EEC" offset="100%"></stop>
        </linearGradient>
        <linearGradient
          x1="42.4403836%"
          y1="100%"
          x2="91.6136906%"
          y2="6.27757791%"
          id="linearGradient-4"
        >
          <stop stopColor="#536EED" offset="0%"></stop>
          <stop stopColor="#54C3EC" offset="24.6657524%"></stop>
          <stop stopColor="#64D769" offset="56.4199509%"></stop>
          <stop stopColor="#FECF2C" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g
        id="03-Symbol/Colour/Swish_Symbol"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Symbol/Colour">
          <g id="Right-Swirl" transform="translate(119.332186, 49.114212)">
            <path
              d="M0,350.04439 C84.2884683,390.356195 188.31202,370.40599 251.156312,295.526341 C325.643824,206.7744 314.074361,74.4625171 225.315044,-5.68434189e-14 L166.309346,70.306361 C235.651961,128.480254 244.690771,231.848605 186.49762,301.186302 C140.096429,356.473463 63.6990439,374.933385 0,350.04439"
              id="Base-Shape"
              fill="url(#linearGradient-1)"
            ></path>
            <path
              d="M1.13686838e-13,350.04439 C84.2884683,390.356195 188.31202,370.40599 251.156312,295.526341 C258.852761,286.356 265.627668,276.719766 271.496605,266.731141 C281.420898,205.05998 259.596059,139.875161 208.256898,96.8056976 C195.213132,85.8627512 181.092937,77.0468488 166.318361,70.3145561 C235.65278,128.489678 244.687902,231.851883 186.49762,301.186302 C140.096429,356.473463 63.6990439,374.933385 1.13686838e-13,350.04439"
              id="Light-Cast"
              fill="url(#linearGradient-2)"
            ></path>
          </g>
          <g id="Left-Swirl">
            <path
              d="M300.254951,20.4289088 C215.966893,-19.8828961 111.943342,0.0668990353 49.0990489,74.9465478 C-25.3884633,163.698899 -13.8189999,296.010782 74.9399074,370.473299 L133.946015,300.166938 C64.6029903,241.993045 55.5645903,138.624694 113.757742,69.2865868 C160.158932,13.9994259 236.556317,-4.46049609 300.254951,20.4289088"
              id="Base-shape"
              fill="url(#linearGradient-3)"
            ></path>
            <path
              d="M300.254951,20.4289088 C215.966893,-19.8828961 111.943342,0.0668990353 49.0990489,74.9465478 C41.4021903,84.117299 34.627283,93.7535332 28.7587562,103.742158 C18.8340538,165.413319 40.6593025,230.597728 91.9984635,273.667601 C105.042229,284.610138 119.162424,293.42645 133.93659,300.158333 C64.6025806,241.983621 55.5670489,138.621416 113.757742,69.2865868 C160.158932,13.9994259 236.556317,-4.46049609 300.254951,20.4289088"
              id="Shadow"
              fill="url(#linearGradient-4)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
