import { useEffect, useState, VoidFunctionComponent } from "react";
import { Card, Icon, Spinner } from "@lysaab/ui-2";
import { FormattedMessage, useIntl } from "react-intl";
import { Amount } from "../../../../../../components/amount/Amount";
import { LysaTable } from "../../../../../../components/lysaTable/LysaTable";
import { LysaTableTextCell } from "../../../../../../components/lysaTableTextCell/LysaTableTextCell";
import {
  InvestmentAccount,
  dataAccounts,
} from "../../../../../../data/dataAccounts";
import { dataFees, PaidFee } from "../../../../../../data/dataFees";
import { paidFeesMessages } from "../Messages";
import { ClosedAccount, dataProfile } from "../../../../../../data/dataProfile";

export const PaidFees: VoidFunctionComponent = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [paidFees, setPaidFees] = useState<PaidFee[]>([]);
  const [accounts, setAccounts] =
    useState<(InvestmentAccount | ClosedAccount)[]>();

  useEffect(() => {
    Promise.all([
      dataFees.getPaidFees(),
      dataAccounts.getAccounts(),
      dataProfile.getClosedAccounts(),
    ])
      .then(([paidFees, accounts, closedAccounts]) => {
        setPaidFees(paidFees);
        setAccounts([...accounts, ...closedAccounts]);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <div className="company-settings--paid-fees">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="company-settings--paid-fees">
      <h2>
        <FormattedMessage id="sweden.companySettings.paidFees.title" />
      </h2>
      <Card>
        <LysaTable small>
          <thead>
            <tr>
              <LysaTableTextCell
                className="text-left"
                value={intl.formatMessage(paidFeesMessages.tableDate)}
                header
              />
              <LysaTableTextCell
                className="text-left"
                value={intl.formatMessage(paidFeesMessages.tableAccount)}
                header
              />
              <LysaTableTextCell
                className="text-right"
                value={intl.formatMessage(
                  paidFeesMessages.tableFeeExcludingVat
                )}
                header
              />
              <LysaTableTextCell
                className="text-right"
                value={intl.formatMessage(
                  paidFeesMessages.tableFeeIncludingVat
                )}
                header
              />
              <th className="text-right"></th>
            </tr>
          </thead>
          <tbody>
            {paidFees.map((fee) => {
              const account = (accounts || [])?.find(
                (account) => account.accountId === fee.accountId
              );

              return (
                <tr key={fee.transactionId}>
                  <LysaTableTextCell
                    className="text-left"
                    label={intl.formatMessage(paidFeesMessages.tableDate)}
                    value={fee.date}
                  />
                  <LysaTableTextCell
                    className="text-left"
                    label={intl.formatMessage(paidFeesMessages.tableAccount)}
                    value={account ? account.name : fee.accountId}
                  />
                  <LysaTableTextCell
                    className="text-right"
                    label={intl.formatMessage(
                      paidFeesMessages.tableFeeExcludingVat
                    )}
                  >
                    <Amount amount={fee.feeExcludingVat} decimals={2} />
                  </LysaTableTextCell>
                  <LysaTableTextCell
                    className="text-right"
                    label={intl.formatMessage(
                      paidFeesMessages.tableFeeIncludingVat
                    )}
                  >
                    <Amount amount={fee.fee} decimals={2} />
                  </LysaTableTextCell>
                  <LysaTableTextCell
                    className="text-right"
                    label={intl.formatMessage(
                      paidFeesMessages.tableFetchReceipt
                    )}
                  >
                    <a
                      download
                      href={`${process.env.REACT_APP_API}/receipt?transactionId=${fee.transactionId}`}
                    >
                      <Icon.ArrowDown size={14} />
                    </a>
                  </LysaTableTextCell>
                </tr>
              );
            })}
            {paidFees.length === 0 && (
              <tr>
                <td colSpan={5} className="text-center">
                  <FormattedMessage id="sweden.companySettings.paidFees.table.noData" />
                </td>
              </tr>
            )}
          </tbody>
        </LysaTable>
      </Card>
    </div>
  );
};
