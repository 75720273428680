import { useEffect, useState } from "react";
import { dataAccounts } from "../../../data/dataAccounts";
import {
  MergedInternalTransferAccount,
  dataTransfer,
} from "../../../data/dataTransfer";

interface Completed {
  data: MergedInternalTransferAccount[];
  status: "COMPLETED";
}
interface Loading {
  status: "LOADING";
}
interface Error {
  status: "ERROR";
}

export function useMergedInternalTransferAccounts():
  | Completed
  | Loading
  | Error {
  const [mergedInternalTransferAccounts, setMergedInternalTransferAccounts] =
    useState<Completed | Loading | Error>({ status: "LOADING" });

  useEffect(() => {
    Promise.all([
      dataAccounts.getAllAccounts(),
      dataTransfer.getInternalTransferAccounts(),
    ])
      .then(([accounts, internalTransferAccounts]) => {
        const investmentAndSavingsAccounts = [
          ...accounts.investmentAccounts,
          ...accounts.savingsAccounts,
        ];
        const accountsTest = internalTransferAccounts.reduce(
          (acc, internalTransferAccount) => {
            const correspondingAccount = investmentAndSavingsAccounts.find(
              (account) =>
                account.accountId === internalTransferAccount.accountId
            );

            if (correspondingAccount) {
              acc.push({
                ...correspondingAccount,
                ...internalTransferAccount,
              } as MergedInternalTransferAccount);
            }
            return acc;
          },
          [] as MergedInternalTransferAccount[]
        );
        setMergedInternalTransferAccounts({
          data: accountsTest,
          status: "COMPLETED",
        });
      })
      .catch(() => setMergedInternalTransferAccounts({ status: "ERROR" }));
  }, []);

  return mergedInternalTransferAccounts;
}
