import { FunctionComponent, useEffect } from "react";
import { Alternative, Button, Select, Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { defineMessages, useIntl } from "react-intl";
import { DEFAULT_WITHDRAWAL_MONTHS, useTransfer } from "../TransferContext";
import { useHistory } from "react-router";
import { useUser } from "../../../../../context/UserContext";
import { getUserAge } from "../utils/userAge";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { TRANSFER_PENSIONS_URL } from "../TransferPensionsStory";
import { GlidePathGraph } from "../../../../../components/glidePathGraph/GlidePathGraph";
import { usePensionAllocation } from "../../../../../hooks/usePensionAllocation";

import "./WithdrawalPeriod.scss";

const messages = defineMessages({
  customPayoutTimePreference: {
    id: "sweden.transfer-pension.advice.payout-time.alternative",
  },
  customPayoutTimeDefaultPreference: {
    id: "sweden.transfer-pension.advice.payout-time.default-alternative",
  },
});

const PAYOUT_INTERVAL_START = 5;
const PAYOUT_INTERVAL_END = 20;

export const WithdrawalPeriod: FunctionComponent = () => {
  const intl = useIntl();
  const [transfer, setTransfer] = useTransfer();
  const history = useHistory();
  const user = useUser();
  const age = getUserAge(user.tin);
  const { allocation } = usePensionAllocation({ ...transfer, age });

  /**
   * Safe guard in case we loose context. On refresh etc. Send user back to start of story.
   */
  useEffect(() => {
    if (typeof transfer?.moves[0]?.institute === "undefined") {
      history.replace(getNavLink(TRANSFER_PENSIONS_URL));
    }
  }, [history, transfer?.moves]);

  if (!age) {
    return null;
  }

  const timeAlternatives = generateYearArray(
    PAYOUT_INTERVAL_START,
    PAYOUT_INTERVAL_END
  ).map(
    (year): Alternative<number> => ({
      text: intl.formatMessage(
        year === DEFAULT_WITHDRAWAL_MONTHS / 12
          ? messages.customPayoutTimeDefaultPreference
          : messages.customPayoutTimePreference,
        {
          years: year,
        }
      ),
      value: year,
    })
  );

  return (
    <article className="transfer-pension-story-withdrawal-period">
      <Typography type="h3" className="header">
        <TranslatedText id="sweden.transfer-pension.withdrawal-period.header" />
      </Typography>
      <section className="glide-path">
        {allocation && (
          <GlidePathGraph
            glidePath={allocation.glidePath}
            reallocationAge={allocation.reallocationAge}
            withdrawalAge={allocation.withdrawalAge}
          />
        )}
      </section>
      <Typography type="h3" className="heading">
        <TranslatedText id="sweden.transfer-pension.withdrawal-period.heading" />
      </Typography>
      <Select
        alternatives={timeAlternatives}
        label=" "
        placeholder={intl.formatMessage({
          id: "sweden.transfer-pension.advice.payout-period.select.placeholder",
        })}
        value={timeAlternatives.find(
          (alternative) => alternative.value === transfer.withdrawalMonths / 12
        )}
        onChange={(newValue) =>
          setTransfer({ withdrawalMonths: newValue.value * 12 })
        }
      />
      <Button
        className="save-button"
        block
        type="button"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.withdrawal-period.save",
        })}
        onClick={history.goBack}
      />
    </article>
  );
};

function generateYearArray(start: number, end: number) {
  const years = [];
  for (let i = start; i <= end; i++) {
    years.push(i);
  }
  return years;
}
