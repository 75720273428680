import { useCallback, useRef, VoidFunctionComponent } from "react";
import { Button, Form, LysaFormRef } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import {
  SustainabilityImportance,
  SustainabilityQuestionsImportantNone,
  SustainabilityQuestionsImportantSpecific,
} from "../../../data/dataInvestments";
import { SustainabilityPreferenceQuestion } from "../../../pageComponents/sustainability/SustainabilityPreferenceQuestion";
import { useAccountContext } from "../context/AccountContext";
import { AccountPreferences } from "../../../context/AccountsContext";

interface Props {
  next(): void;
}

export const SustainabilityPreference: VoidFunctionComponent<Props> = ({
  next,
}) => {
  const formRef = useRef<LysaFormRef>();
  const [accountState, setAccountState] = useAccountContext();

  const onSubmit = useCallback(() => {
    if (formRef.current?.isValid) {
      next();
    }
  }, [next]);

  if (
    accountState.account?.sustainability !== SustainabilityImportance.IMPORTANT
  ) {
    return null;
  }

  return (
    <Form lysaFormRef={formRef} onSubmit={onSubmit}>
      <SustainabilityPreferenceQuestion
        sustainabilityPreference={
          (
            accountState.account as
              | SustainabilityQuestionsImportantNone
              | SustainabilityQuestionsImportantSpecific
          ).sustainabilityPreference
        }
        setSustainabilityPreference={(sustainabilityPreference) =>
          setAccountState({
            account: {
              ...accountState.account,
              sustainabilityPreference,
            } as AccountPreferences,
          })
        }
      />
      <Button
        block
        type="submit"
        label={<FormattedMessage id="accountSituation.preference.next" />}
      />
    </Form>
  );
};
