import { Story } from "@lysaab/ui-2";
import { useEffect, useMemo, useState } from "react";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router";
import { Route } from "../../../../../components/route/Route";
import { Switch } from "../../../../../components/route/Switch";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { useStoryValues } from "../../../../../hooks/useStoryValues";
import { DEPOSITS_OVERVIEW_URL } from "../../../../../pages/deposits/overview/Recommendation";
import { PageStripped } from "../../../../../pages/PageStripped";
import { BankgiroAccountSelectPage } from "./accounts/BankgiroAccountSelectPage";
import { useSafeNavigation } from "../../../../../hooks/useSafeNavigation";
import {
  BankgiroResponse,
  dataDeposit,
} from "../../../../../data/dataBankgiro";
import { CompoundAccount } from "../../../../../data/dataAccounts";
import { BankgiroInformationPage } from "./information/BankgiroInformationPage";
import { Retry, Status } from "../../../../../components/retry/Retry";
import { OVERVIEW_PAGE_URL } from "../../../../../pages/overview/OverviewPage";

export const BANKGIRO_DEPOSIT_PAGE_URL = "/deposits/bankgiro";

const messages = defineMessages({
  header: {
    id: "sweden.deposits.bankgiro.story.header",
  },
  ariaProgressLabel: {
    id: "sweden.deposits.bankgiro.story.ariaProgressLabel",
  },
  failedFetchingBankgirosText: {
    id: "sweden.deposits.bankgiro.story.failedFetchingBankgirosText",
  },
});

const BASE_ROUTES = {
  ACCOUNT_SELECTION: `${BANKGIRO_DEPOSIT_PAGE_URL}`,
  INFORMATION: `${BANKGIRO_DEPOSIT_PAGE_URL}/information`,
};

export const BankgiroDepositStory: React.VFC = () => {
  const location = useLocation();
  const intl = useIntl();
  const safeNavigation = useSafeNavigation();
  const history = useHistory();
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);
  const [account, setAccount] = useState<CompoundAccount>();
  const [bankgiros, setBankgiros] = useState<BankgiroResponse>();
  const [status, setStatus] = useState<Status>(Status.PENDING);

  const referenceNumber = useMemo(() => {
    if (typeof bankgiros === "undefined" || typeof account === "undefined") {
      return;
    }

    return bankgiros[account.accountId];
  }, [account, bankgiros]);

  const fetchBankgiros = () => {
    setStatus(Status.PENDING);
    dataDeposit
      .getBankgiros()
      .then((response) => {
        setBankgiros(response);
        setStatus(Status.SUCCESS);
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  };

  useEffect(() => {
    fetchBankgiros();
  }, []);

  return (
    <PageStripped>
      <Story
        ariaLabelProgress={() =>
          intl.formatMessage(messages.ariaProgressLabel, {
            current: currentIndex,
            total: storyLength,
          })
        }
        header={intl.formatMessage(messages.header)}
        onExit={() => {
          safeNavigation(getNavLink(OVERVIEW_PAGE_URL));
        }}
        showClose
        showBack
        onBack={() => {
          if (currentIndex === 0) {
            safeNavigation(getNavLink(DEPOSITS_OVERVIEW_URL));
          } else {
            history.goBack();
          }
        }}
        transitionKey={currentIndex.toString()}
        progress={storyProgress}
      >
        <Switch
          location={location}
          {...{
            order: currentIndex,
          }}
        >
          <Route path={ROUTES.ACCOUNT_SELECTION} exact>
            <BankgiroAccountSelectPage
              next={(account) => {
                setAccount(account);
                safeNavigation(ROUTES.INFORMATION);
              }}
              preselectedAccount={account}
            />
          </Route>
          <Route path={ROUTES.INFORMATION} exact>
            <Retry
              status={status}
              retry={fetchBankgiros}
              text={intl.formatMessage(messages.failedFetchingBankgirosText)}
            >
              <BankgiroInformationPage
                accountName={account?.name}
                referenceNumber={referenceNumber}
              />
            </Retry>
          </Route>
        </Switch>
      </Story>
    </PageStripped>
  );
};
