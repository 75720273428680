import { DateTime } from "luxon";
import {
  dataAccounts,
  AccountOrder,
  AccountOrderDirection,
  CompoundAccount,
} from "./dataAccounts";

export enum EntityType {
  PERSON = "PERSON",
  CORPORATION = "CORPORATION",
}

export type TrackId = string & { readonly isTrackId: true };
export type AccessRightsId = string & { readonly isAccessRightsId: true };
export type LegalEntityId = string & { readonly isLegalEntityId: true };

export const dataUser = {
  getCreated(accounts: CompoundAccount[]) {
    if (accounts.length === 0) {
      return new Date();
    }
    const clone = accounts.slice(); // dont modify original accounts order
    return DateTime.fromISO(
      dataAccounts.accountSort(
        clone,
        AccountOrder.CREATED,
        AccountOrderDirection.ASCENDING
      )[0].created
    ).toJSDate();
  },
};
