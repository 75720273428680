import {
  Card,
  Typography,
  LysaLink,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import { VoidFunctionComponent } from "react";
import { FormattedPercentage } from "../../../../components/formattedPercentage/FormattedPercentage";
import { TranslatedText } from "../../../../components/TranslatedText";
import { TransparentButton } from "../../../../components/transparentButton/TransparentButton";
import {
  EffectiveInterestRateResponse,
  EffectiveInterestRateWithAccountId,
} from "../../../../data/dataSavingsAccount";
import { client as elevioClient } from "elevio";

import "./SavingsInterestCard.scss";

export const OPEN_SAVINGS_ACCOUNT_ELEVIO_CATEGORY_ID = "19";

interface Props {
  interestRate?:
    | EffectiveInterestRateResponse
    | EffectiveInterestRateWithAccountId;
  isError: boolean;
  isLoading?: boolean;
  showElevioLink?: boolean;
}

export const SavingsInterestCard: VoidFunctionComponent<Props> = ({
  interestRate,
  isError,
  isLoading,
  showElevioLink = true,
}) => {
  return (
    <Card
      className="savings-interest-card__interest-card"
      showLoading={isLoading}
    >
      {isError && (
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <Typography type="body" component="span">
            <TranslatedText id="sweden.savingsInterestCard.error" />
          </Typography>
        </Snackbar>
      )}
      <div className="savings-interest-card__row-wrapper">
        <div className="savings-interest-card__row">
          <Typography type="body" component="span">
            <TranslatedText id="sweden.savingsInterestCard.effectiveInterest" />
          </Typography>
          <span className="savings-interest-card__row-text savings-interest-card__row-text--bold">
            {interestRate ? (
              <FormattedPercentage
                value={Math.floor(interestRate.interestRate * 100_00) / 100}
                decimals={2}
              />
            ) : (
              "-"
            )}
          </span>
        </div>
        <div className="savings-interest-card__row">
          <Typography type="body" component="span">
            <TranslatedText id="sweden.savingsInterestCard.paymentOfInterestTerm" />
          </Typography>
          <span className="savings-interest-card__row-text savings-interest-card__row-text--bold">
            <TranslatedText id="sweden.savingsInterestCard.paymentOfInterestDetail" />
          </span>
        </div>
        <div className="savings-interest-card__row">
          <Typography type="body" component="span">
            <TranslatedText id="sweden.savingsInterestCard.fixedTimeTerm" />
          </Typography>
          <span className="savings-interest-card__row-text savings-interest-card__row-text--bold">
            <TranslatedText id="sweden.savingsInterestCard.fixedTimeDetail" />
          </span>
        </div>
      </div>
      <Typography type="body-small" component="p">
        <TranslatedText id="sweden.savingsInterestCard.body" />
      </Typography>
      {showElevioLink && (
        <div className="savings-interest-card___elevio-link-wrapper">
          <LysaLink
            component={TransparentButton}
            onClick={() =>
              elevioClient.openCategory(OPEN_SAVINGS_ACCOUNT_ELEVIO_CATEGORY_ID)
            }
          >
            <TranslatedText id="sweden.savingsInterestCard.elevioLink" />
          </LysaLink>
        </div>
      )}
    </Card>
  );
};
