import { LysaCountry } from "@lysaab/shared";
import { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { LocalizationContext } from "../../context/LocalizationContext";
import { ContactPhoneNumbers, OpeningHoursUTC } from "../Contact";
import "./Contact.scss";

export function Contact() {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);

  return (
    <div className="contact-footer">
      <p>
        <FormattedMessage
          id="component.contact"
          values={{
            opening: () => {
              return intl.formatTime(
                OpeningHoursUTC[
                  localizationContext.state.country || LysaCountry.SWEDEN
                ].OPENING
              );
            },
            closing: () => {
              return intl.formatTime(
                OpeningHoursUTC[
                  localizationContext.state.country || LysaCountry.SWEDEN
                ].CLOSING
              );
            },
            phone: () => {
              const phoneNumber = intl.formatMessage(
                ContactPhoneNumbers[
                  localizationContext.state.country || LysaCountry.SWEDEN
                ]
              );

              return <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>;
            },
          }}
        />
      </p>
    </div>
  );
}
