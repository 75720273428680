import { useContext, useCallback } from "react";
import { Toggle } from "@lysaab/ui-2/components/toggle/Toggle";
import { PerformanceContext } from "../../context/PerformanceContext";
import "./EventsSwitch.scss";
import { FormattedMessage } from "react-intl";

export function EventsSwitch() {
  const { setState, showEvents } = useContext(PerformanceContext);

  const onChange = useCallback(() => {
    setState({
      showEvents: !showEvents,
    });
  }, [setState, showEvents]);

  return (
    <div className="events-switch">
      <div className="flex">
        <label htmlFor="show-transactions-toggle">
          <b>
            <FormattedMessage
              id="eventswitch.header"
              description="Show transaction events"
              defaultMessage="Show transactions"
            />
          </b>
        </label>
        <Toggle
          id="show-transactions-toggle"
          value={showEvents}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
