import { useContext } from "react";
import * as React from "react";
import { Signature } from "../signature/Signature";
import { MessagesAuthor, Message, Thread } from "../../data/dataMessages";
import { HtmlEntityDecoder } from "./HtmlEntityDecoder";
import { DateTime } from "luxon";
import { UserContext } from "../../context/UserContext";
import "./ThreadItem.scss";
import { FormattedDate, FormattedRelativeTime } from "react-intl";
import { LegalEntityType } from "../../data/dataLogin";
import { useUsers } from "../../hooks/useUsers";
import { NewIcon } from "@lysaab/ui-2";

export const dynamicDateText = (dateString: string) => {
  const date = DateTime.fromISO(dateString);

  if (DateTime.local().diff(date).as("hours") < 48) {
    return (
      <FormattedRelativeTime
        value={date.toMillis() / 1000 - Date.now() / 1000}
        numeric="auto"
        updateIntervalInSeconds={1}
      />
    );
  }

  return <FormattedDate value={date.toMillis()} dateStyle="medium" />;
};

interface Props {
  message: Message;
  thread: Thread;
  isLast: boolean;
}

export const ThreadItem: React.FunctionComponent<Props> = ({
  message,
  thread,
  isLast,
}) => {
  const userContext = useContext(UserContext);
  const user = userContext.state;
  const users = useUsers();
  const sender = users?.find(
    (user) => user.identificationNumber === message.tin
  );

  return (
    <div className="thread-item">
      <Signature
        user={MessagesAuthor.ADMIN === message.author ? message.author : user}
      />
      <div className="arrow" />
      <div className="message-body">
        {message.isGenerated ? (
          message.message
        ) : (
          <HtmlEntityDecoder text={message.message as string} />
        )}
        <ul className="message-details">
          {message.attachments.map((file) => (
            <li key={file.id} className="message-file">
              <a
                href={`${process.env.REACT_APP_API}/messages/attachment/${file.id}`}
                target="_blank"
                className="attachment-link"
                rel="noopener noreferrer"
                download={file.filename}
              >
                {file.filename}
                <span className="attachment-icon">
                  <NewIcon.Attachment size={16} />
                </span>
              </a>
            </li>
          ))}
          <li className="message-date">
            {user.legalEntityType === LegalEntityType.CORPORATION && sender && (
              <React.Fragment>
                {sender.name}
                <br />
              </React.Fragment>
            )}
            {dynamicDateText(message.date)}
          </li>
        </ul>
      </div>
    </div>
  );
};
