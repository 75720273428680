import { FunctionComponent } from "react";
import { Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { ReactComponent as CalendarIllustration } from "./calendar-illustration.svg";

import "./MoveTimeInfo.scss";

export const MoveTimeInfo: FunctionComponent = () => {
  return (
    <div className="transfer-pension-move-time-info">
      <CalendarIllustration />
      <Typography type="h2">
        <TranslatedText id="sweden.transfer-pension.done.info.header" />
      </Typography>
      <Typography>
        <TranslatedText id="sweden.transfer-pension.done.info.body.one" />
      </Typography>
      <Typography>
        <TranslatedText id="sweden.transfer-pension.done.info.body.two" />
      </Typography>
    </div>
  );
};
