import { Button, ConfirmCard, CONFIRM_BUTTON_TYPES } from "@lysaab/ui-2";
import { useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { TranslatedText } from "../../components/TranslatedText";
import {
  AccountShareByMe,
  AccountShareWithMe,
  dataAttorney,
} from "../../data/dataAttorney";
import { dataPerformance } from "../../data/dataPerformance";
import "./AccountShareCard.scss";

enum Type {
  ACCOUNT_SHARE_BY_ME = "ACCOUNT_SHARE_BY_ME",
  ACCOUNT_SHARE_WITH_ME = "ACCOUNT_SHARE_WITH_ME",
}

interface MessageWithId {
  id: string;
}

const sharedByMeMessages = defineMessages({
  confirmDeleteButton: {
    id: "sharedAccountsPage.accountShareCard.shared.confirmDeleteButton",
  },
  confirmDeleteCancelButton: {
    id: "sharedAccountsPage.accountShareCard.shared.confirmDeleteCancelButton",
  },
  confirmDeleteText: {
    id: "sharedAccountsPage.accountShareCard.shared.confirmDeleteText",
  },
  deleteButton: {
    id: "sharedAccountsPage.accountShareCard.shared.deleteButton",
  },
});

const sharedWithMeMessages = defineMessages({
  confirmDeleteButton: {
    id: "sharedAccountsPage.accountShareCard.followed.confirmDeleteButton",
  },
  confirmDeleteCancelButton: {
    id: "sharedAccountsPage.accountShareCard.followed.confirmDeleteCancelButton",
  },
  confirmDeleteText: {
    id: "sharedAccountsPage.accountShareCard.followed.confirmDeleteText",
  },
  deleteButton: {
    id: "sharedAccountsPage.accountShareCard.followed.deleteButton",
  },
});

const messages: Record<Type, Record<string, MessageWithId>> = {
  [Type.ACCOUNT_SHARE_BY_ME]: sharedByMeMessages,
  [Type.ACCOUNT_SHARE_WITH_ME]: sharedWithMeMessages,
};

interface Props {
  accountShare: AccountShareWithMe | AccountShareByMe;
  onDelete: () => Promise<void>;
}

export const AccountShareCard: React.VFC<Props> = ({
  accountShare,
  onDelete,
}) => {
  const intl = useIntl();
  const [showConfirm, setShowConfirm] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const type = useMemo(
    () =>
      isAccountShareWithMe(accountShare)
        ? Type.ACCOUNT_SHARE_WITH_ME
        : Type.ACCOUNT_SHARE_BY_ME,
    [accountShare]
  );

  const handleConfirm = async () => {
    try {
      await dataAttorney.cancelAccountShare(accountShare.accountShareId);
      await onDelete();
    } finally {
      setShowLoading(false);
    }
  };

  return (
    <ConfirmCard
      cancelButtonText={intl.formatMessage(
        messages[type].confirmDeleteCancelButton
      )}
      confirmButtonText={intl.formatMessage(messages[type].confirmDeleteButton)}
      confirmText={
        <h5>{intl.formatMessage(messages[type].confirmDeleteText)}</h5>
      }
      onConfirm={(confirmed) => {
        if (confirmed) {
          handleConfirm();
          setShowLoading(true);
        }
        setShowConfirm(false);
      }}
      showConfirm={showConfirm}
      showLoading={showLoading}
      className="account-share-card"
      confirmButtonSize="small"
      confirmButtonType={CONFIRM_BUTTON_TYPES.NEGATIVE}
    >
      <div className="card-header">
        <div className="card-header__text">
          <strong>{accountShare.accountName}</strong>
          <span className="date">
            {dataPerformance.toStringDate(
              dataPerformance.toJsDate(accountShare.created)
            )}
          </span>
        </div>
        <Button
          size="small"
          variant="negative"
          onClick={() => setShowConfirm(true)}
          className="delete-button"
          label={intl.formatMessage(messages[type].deleteButton)}
        />
      </div>
      <div className="info">
        {"ownerName" in accountShare && (
          <span>
            <TranslatedText
              id="sharedAccountsPage.accountShareCard.sharedByLabel"
              values={{
                name: accountShare.ownerName,
              }}
            />
          </span>
        )}
        {"inviteeName" in accountShare && (
          <span>
            <TranslatedText
              id="sharedAccountsPage.accountShareCard.sharedWithLabel"
              values={{
                name: accountShare.inviteeName,
              }}
            />
          </span>
        )}
      </div>
    </ConfirmCard>
  );
};

const isAccountShareWithMe = (
  accountShare: AccountShareWithMe | AccountShareByMe
): accountShare is AccountShareWithMe => "ownerName" in accountShare;
