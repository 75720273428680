import { useEffect, useCallback, useState } from "react";
import {
  CardList,
  Spinner,
  TextInput,
  Typography,
  useDebounceFn,
} from "@lysaab/ui-2";
import { AvailableBank, dataKlarna } from "../../../data/dataKlarna";
import * as H from "history";
import "./BankSelection.scss";
import { TranslatedText } from "../../TranslatedText";
import * as fuzzyFind from "./fuzzyfind";
import { BankListItem } from "./BankListItem";
import { useIntl, defineMessages } from "react-intl";
import { MissingBankCard } from "./MissingBankCard";
import { RefineSearchCard } from "./RefineSearchCard";

export const messages = defineMessages({
  searchPlaceholder: {
    id: "klarna.bank-selection.search.placeholder",
  },
  searchLabel: {
    id: "klarna.bank-selection.search.label",
  },
});

export interface BankMissing {
  isMissingBankItem: boolean;
}

const MAX_BANKS = 10;

interface Props {
  onComplete: (bank: AvailableBank) => void;
  missingBank?: H.LocationDescriptor<H.LocationState>;
  filterBanksFunction?: (bank: AvailableBank) => boolean;
  intro?: JSX.Element | string;
  missingBankHeader: JSX.Element | string;
  missingBankDescription: JSX.Element | string;
}

export function BankSelection({
  onComplete,
  missingBank,
  filterBanksFunction,
  intro,
  missingBankHeader,
  missingBankDescription,
}: Props) {
  const intl = useIntl();
  const [allBanks, setAllBanks] = useState<Array<AvailableBank>>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [fuzzyResult, setFuzzyResult] = useState<Array<AvailableBank>>();

  const fetchAllBanks = useCallback(() => {
    dataKlarna.getAllBanks().then((banks) => {
      const filteredAndSortedBanks = banks.filter(
        filterBanksFunction ?? (() => true)
      );

      setAllBanks(filteredAndSortedBanks);
      // Show all banks until we have a search result available
      // (This is for the first search after the page has loaded)
      setFuzzyResult(filteredAndSortedBanks);
    });
  }, [filterBanksFunction]);

  useEffect(() => {
    fetchAllBanks();
  }, [fetchAllBanks]);

  const search = useCallback(
    (term: string) => {
      const result = fuzzyFind
        .metadataFilter(
          term,
          allBanks.map((bank) => bank.displayName)
        )
        .map((res: fuzzyFind.Match) => allBanks[res.index]);

      setFuzzyResult(result);
    },
    [allBanks]
  );

  const debouncedSearch = useDebounceFn(search, 200);

  useEffect(() => {
    if (!searchTerm) {
      return;
    }

    debouncedSearch(searchTerm);
  }, [debouncedSearch, searchTerm]);

  if (allBanks.length === 0) {
    return <Spinner />;
  }

  const banks = (searchTerm && fuzzyResult) || allBanks;
  const banksToShow = banks.slice(0, MAX_BANKS);
  const isSearchable = allBanks.length > MAX_BANKS;

  return (
    <div className="bank-selection">
      <Typography type="h2">
        <TranslatedText id="klarna.bank-selection.choose-bank" />
      </Typography>

      {intro && <p className="bank-search-intro">{intro}</p>}
      {isSearchable && (
        <div className="search-input">
          <TextInput
            label={intl.formatMessage(messages.searchLabel)}
            value={searchTerm}
            placeholder={intl.formatMessage(messages.searchPlaceholder)}
            onChange={(value) => setSearchTerm(value)}
          />
        </div>
      )}
      <CardList className="bank-selection-list">
        {isSearchable && <RefineSearchCard matches={banks.length} />}
        {banksToShow.map((bank) => (
          <BankListItem
            bank={bank}
            onComplete={onComplete}
            key={bank.bankCode}
          />
        ))}
        {missingBank && (
          <MissingBankCard
            missingBank={missingBank}
            header={missingBankHeader}
            description={missingBankDescription}
          />
        )}
      </CardList>
    </div>
  );
}
