import { API, InvestmentType } from "@lysaab/ui-2";
import {
  AccountType,
  InvestmentAccountId,
  SavingsAccountId,
} from "./dataAccounts";
import { EntityType } from "./dataUser";

export interface DocumentsCustomer {
  name: string;
  identificationNumber: string;
  /**
   * ISO-date YYYY-MM-DD
   */
  date: string;
  entityType: EntityType;
  /**
   * ISO-datetime 2022-01-24T12:33:17.230447Z
   */
  created: string;
}

interface DocumentsAccount {
  accountType: AccountType;
  accountName: string;
  accountId: InvestmentAccountId;
  name: string;
  identificationNumber: string;
  /**
   * ISO-date YYYY-MM-DD
   */
  accountSigning: string;
  /**
   * ISO-datetime 2022-01-24T12:33:17.230447Z
   */
  created: string;
}

export interface DocumentsActiveSavingsAccount {
  accountName: string;
  accountId: SavingsAccountId;
  name: string;
  identificationNumber: string;
  accountSigning: string;
  created: string;
}

export interface DocumentsClosedSavingsAccount
  extends DocumentsActiveSavingsAccount {
  /**
   * ISO-date YYYY-MM-DD
   */
  closed: string;
}

export interface DocumentsClosedAccount extends DocumentsAccount {
  /**
   * ISO-date YYYY-MM-DD
   */
  closed: string;
}

export interface DocumentsActiveAccount extends DocumentsAccount {
  /**
   * ISO-date YYYY-MM-DD
   */
  guidelinesSigning: string;
  stocks: number;
  bonds: number;
  investmentType: InvestmentType;
}

export interface Documents {
  customer: DocumentsCustomer;
  accounts: (DocumentsClosedAccount | DocumentsActiveAccount)[];
  savingsAccounts: (
    | DocumentsActiveSavingsAccount
    | DocumentsClosedSavingsAccount
  )[];
}

export type AllDocumentsAccounts = (
  | DocumentsClosedAccount
  | DocumentsActiveAccount
  | DocumentsActiveSavingsAccount
  | DocumentsClosedSavingsAccount
)[];

export interface DocumentInformation {
  fileName: string;
  title: string;
  type: string;
  lang: string;
  country: string;
  created: string;
}

const GET_DOCUMENTS_URL = "/documents";

export const dataDocuments = {
  getDocuments: () => {
    return API.get<Documents>(GET_DOCUMENTS_URL);
  },
  getDocumentsInformation: (refreshCache = false) => {
    return API.get<DocumentInformation[]>(
      `${process.env.REACT_APP_LYSA_DOCS_URL}/docs.json`,
      refreshCache,
      {
        credentials: undefined,
        headers: {},
      }
    );
  },
};

export function isActiveAccountDocument(
  doc: DocumentsClosedAccount | DocumentsActiveAccount
): doc is DocumentsActiveAccount {
  return (
    typeof (doc as DocumentsActiveAccount).guidelinesSigning !== "undefined"
  );
}

export const getLatestDocuments = (
  information: DocumentInformation[],
  ...groups: string[][]
) => {
  return groups
    .map(
      (documents) =>
        documents
          .filter((name) => {
            const documentInformation = getDocumentInformation(
              name,
              information
            );
            if (typeof documentInformation === "undefined") {
              throw new Error(
                `getLatestDocuments - Missing document: ${name}}`
              );
            }

            return (
              new Date().getTime() >=
              new Date(documentInformation.created).getTime()
            );
          })
          .sort((a, b) => {
            const docAInfo = getDocumentInformation(a, information);
            const docBInfo = getDocumentInformation(b, information);
            if (
              typeof docAInfo === "undefined" ||
              typeof docBInfo === "undefined"
            ) {
              return -1;
            }
            return (
              new Date(docBInfo.created).getTime() -
              new Date(docAInfo.created).getTime()
            );
          })?.[0]
    )
    .filter(Boolean);
};

const getDocumentInformation = (
  name: string,
  information: DocumentInformation[]
) =>
  information.find(
    ({ fileName }) => fileName.replace(".md", "") === name.replace(".md", "")
  );
