import { RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { useCreateKFAccount } from "../../KFAccountContext";

export const KnowsFee = () => {
  const intl = useIntl();
  const [KFAccount, setKFAccount] = useCreateKFAccount();

  return (
    <RadioGroup
      header={intl.formatMessage({ id: "sweden.kf.intro.fee.label" })}
      alternatives={[
        {
          text: intl.formatMessage({ id: "sweden.kf.intro.fee.yes" }),
          value: true,
        },
        {
          text: intl.formatMessage({ id: "sweden.kf.intro.fee.no" }),
          value: false,
        },
      ]}
      value={KFAccount.knowsFee}
      onChange={(value) =>
        setKFAccount({
          knowsFee: value,
        })
      }
      validators={[
        new RequiredValidator(
          intl.formatMessage({ id: "sweden.kf.intro.fee.required" })
        ),
      ]}
    />
  );
};
