import { API } from "@lysaab/ui-2";

interface InviteResponse {
  inviteId: string;
}

interface RebateMonths {
  rebateMonths: number;
}

export const dataInvite = {
  getId: () => {
    return API.get<InviteResponse>("/invite/invite-id");
  },
  getRebateMonths: () => {
    return API.get<RebateMonths>("/invite/rebate-months").then((response) => {
      return response.rebateMonths;
    });
  },
};
