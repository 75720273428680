import { useCallback, useContext, useEffect, useState } from "react";
import { useIsSignedIn } from "./useIsSignedIn";
import { dataYearlyReview, YearlyReviewStatus } from "../data/dataYearlyReview";
import { UserContext } from "../context/UserContext";
import { matchPath, useLocation } from "react-router";
import { getNavLink } from "./useCountryUrls";
import { BASE_ROUTES } from "../pages/yearlyReview/YearlyReviewStory";

export function useYearlyReviewStatus() {
  const isSignedIn = useIsSignedIn();
  const userContext = useContext(UserContext);
  const [status, setStatus] = useState<YearlyReviewStatus>();
  const [deadline, setDeadline] = useState<string>();
  const [hash, setHash] = useState<string>();
  const location = useLocation();

  const fetch = useCallback(() => {
    dataYearlyReview
      .getYearlyReviewDate()
      .then(({ yearlyReviewDeadline, yearlyReviewHash, status }) => {
        if (!yearlyReviewDeadline || userContext.state.readOnly) {
          return;
        }
        setDeadline(yearlyReviewDeadline);
        setHash(yearlyReviewHash);
        setStatus(status);
      });
  }, [userContext.state.readOnly]);

  useEffect(() => {
    const pathIsFinalInYearly = !!matchPath(location.pathname, {
      path: getNavLink(BASE_ROUTES.DONE),
      exact: true,
    });

    if (!pathIsFinalInYearly) {
      return;
    }

    fetch();
  }, [fetch, location.pathname]);

  useEffect(() => {
    if (!isSignedIn) {
      setStatus(undefined);
      setDeadline(undefined);
      return;
    }

    fetch();
  }, [
    isSignedIn,
    userContext.state.readOnly,
    userContext.state.tin,
    userContext.state.name,
    fetch,
  ]);

  return { status, deadline, hash };
}
