import {
  CheckboxGroup,
  Form,
  Datepicker,
  MaxDateValidator,
  MinDateValidator,
  Alternative,
  LysaFormRef,
  Button,
  NewIcon,
} from "@lysaab/ui-2";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { TranslatedText } from "../../../../components/TranslatedText";
import {
  ClosedAccount,
  ClosedSavingsAccount,
} from "../../../../data/dataProfile";
import {
  dataTransactions,
  Filter,
  TransactionType,
} from "../../../../data/dataTransactions";
import {
  AllAccountResponse,
  CompoundAccountId,
} from "../../../../data/dataAccounts";
import { useIntl, defineMessages } from "react-intl";
import cx from "classnames";
import { AnimateHeight } from "../../../../components/animateHeight/AnimateHeight";

import "./Filters.scss";

// TransactionTypes to be used as user-selectable filters.
// Excluded types should be added to a non-excluded type.
type TransactionTypeFilter = Exclude<
  TransactionType,
  "MOVE_OUT" | "SWITCH_SELL" | "TAX"
>;

const messages = defineMessages({
  dateFromLabel: {
    id: "historicTransactionsPage.historicTransactions.filters.dateFromLabel",
  },
  dateToLabel: {
    id: "historicTransactionsPage.historicTransactions.filters.dateToLabel",
  },
  dateFromError: {
    id: "historicTransactionsPage.historicTransactions.filters.dateFromError",
  },
  dateToError: {
    id: "historicTransactionsPage.historicTransactions.filters.dateToError",
  },
  dateTodayError: {
    id: "historicTransactionsPage.historicTransactions.filters.dateTodayError",
  },
  activeAccountsLegend: {
    id: "historicTransactionsPage.historicTransactions.filters.activeAccountsLegend",
  },
  sharedAccountsLegend: {
    id: "historicTransactionsPage.historicTransactions.filters.sharedAccountsLegend",
  },
  closedAccountsLegend: {
    id: "historicTransactionsPage.historicTransactions.filters.closedAccountsLegend",
  },
  transactionTypeLegend: {
    id: "historicTransactionsPage.historicTransactions.filters.transactionTypeLegend",
  },
});

const defaultToDate = DateTime.local().toJSDate();
const defaultFromDate = DateTime.local().minus({ year: 1 }).toJSDate();

export const Filters = ({
  setFilter,
  activeAccounts,
  closedAccounts,
  defaultAccountId,
}: {
  setFilter: (filter: Filter) => void;
  activeAccounts: AllAccountResponse;
  closedAccounts: (ClosedAccount | ClosedSavingsAccount)[];
  defaultAccountId?: CompoundAccountId;
}) => {
  const intl = useIntl();
  const formRef = useRef<LysaFormRef>();
  const [dateFrom, setDateFrom] = useState<Date>(defaultFromDate);
  const [dateTo, setDateTo] = useState<Date>(defaultToDate);
  const [selectedTransactionTypes, setSelectedTransactionTypes] = useState<
    Alternative<TransactionType[]>[]
  >([]);
  const [selectedClosedAccounts, setSelectedClosedAccounts] = useState<
    Alternative<CompoundAccountId>[]
  >([]);
  const [selectedSharedAccounts, setSelectedSharedAccounts] = useState<
    Alternative<CompoundAccountId>[]
  >([]);
  const [selectedActiveAccounts, setSelectedActiveAccounts] = useState<
    Alternative<CompoundAccountId>[]
  >([]);
  const [selectedDates, setSelectedDates] = useState({
    start: defaultFromDate,
    end: defaultToDate,
  });
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [filterQueryString, setFilterQueryString] = useState("");

  const filterRecord: Record<
    TransactionTypeFilter,
    Alternative<TransactionType[]>
  > = {
    [TransactionType.WITHDRAWAL]: {
      text: intl.formatMessage({
        id: "historicTransactionsPage.historicTransactions.filters.transactionAlternatives.withdrawal",
      }),
      value: [TransactionType.WITHDRAWAL],
    },
    [TransactionType.DEPOSIT]: {
      text: intl.formatMessage({
        id: "historicTransactionsPage.historicTransactions.filters.transactionAlternatives.deposit",
      }),
      value: [TransactionType.DEPOSIT],
    },
    [TransactionType.BUY]: {
      text: intl.formatMessage({
        id: "historicTransactionsPage.historicTransactions.filters.transactionAlternatives.fundBuy",
      }),
      value: [TransactionType.BUY],
    },
    [TransactionType.SELL]: {
      text: intl.formatMessage({
        id: "historicTransactionsPage.historicTransactions.filters.transactionAlternatives.fundSell",
      }),
      value: [TransactionType.SELL],
    },
    [TransactionType.INTEREST]: {
      text: intl.formatMessage({
        id: "historicTransactionsPage.historicTransactions.filters.transactionAlternatives.interest",
      }),
      value: [TransactionType.INTEREST],
    },
    [TransactionType.FEE]: {
      text: intl.formatMessage({
        id: "historicTransactionsPage.historicTransactions.filters.transactionAlternatives.fee",
      }),
      value: [TransactionType.TAX, TransactionType.FEE],
    },
    [TransactionType.SWITCH_BUY]: {
      text: intl.formatMessage({
        id: "historicTransactionsPage.historicTransactions.filters.transactionAlternatives.fundSwitch",
      }),
      value: [TransactionType.SWITCH_BUY, TransactionType.SWITCH_SELL],
    },
    [TransactionType.MOVE_IN]: {
      text: intl.formatMessage({
        id: "historicTransactionsPage.historicTransactions.filters.transactionAlternatives.move",
      }),
      value: [TransactionType.MOVE_IN, TransactionType.MOVE_OUT],
    },
    [TransactionType.CORRECTION]: {
      text: intl.formatMessage({
        id: "historicTransactionsPage.historicTransactions.filters.transactionAlternatives.correction",
      }),
      value: [TransactionType.CORRECTION],
    },
  };

  useEffect(() => {
    if (activeAccounts.investmentAccounts.length === 0) {
      return;
    }
    setSelectedActiveAccounts(
      [...activeAccounts.investmentAccounts, ...activeAccounts.savingsAccounts]
        .map((account) => ({
          value: account.accountId,
          text: account.name,
        }))
        .filter(({ value }) => !defaultAccountId || value === defaultAccountId)
    );
    setSelectedSharedAccounts(
      [
        ...activeAccounts.sharedInvestmentAccounts,
        ...activeAccounts.sharedSavingsAccounts,
      ]
        .map((account) => ({
          value: account.accountId,
          text: account.name,
        }))
        .filter(({ value }) => !defaultAccountId || value === defaultAccountId)
    );
  }, [activeAccounts, defaultAccountId]);

  useEffect(() => {
    const filter = {
      start: selectedDates.start,
      end: selectedDates.end,
      types: selectedTransactionTypes.flatMap((value) => value.value),
      accountIds: [
        ...selectedActiveAccounts.map((account) => account.value),
        ...selectedSharedAccounts.map((account) => account.value),
        ...selectedClosedAccounts.map((account) => account.value),
      ],
    };
    setFilter(filter);
    setFilterQueryString(
      dataTransactions.filterToQueryParameters(filter).toString()
    );
  }, [
    selectedActiveAccounts,
    selectedSharedAccounts,
    selectedClosedAccounts,
    selectedDates,
    selectedTransactionTypes,
    setFilter,
  ]);

  return (
    <div className="filters">
      <p>
        <TranslatedText id="historicTransactionsPage.historicTransactions.filters.ingress" />
      </p>
      <h2>
        <TranslatedText id="historicTransactionsPage.historicTransactions.filters.filter" />
      </h2>
      <CheckboxGroup
        alternatives={[
          ...activeAccounts.investmentAccounts,
          ...activeAccounts.savingsAccounts,
        ].map((account) => {
          return {
            text: account.name,
            value: account.accountId,
          };
        })}
        header={intl.formatMessage(messages.activeAccountsLegend)}
        onChange={setSelectedActiveAccounts}
        values={selectedActiveAccounts}
      />
      {activeAccounts.sharedInvestmentAccounts.length > 0 && (
        <CheckboxGroup
          alternatives={[
            ...activeAccounts.sharedInvestmentAccounts,
            ...activeAccounts.sharedSavingsAccounts,
          ].map((account) => {
            return {
              text: account.name,
              value: account.accountId,
            };
          })}
          header={intl.formatMessage(messages.sharedAccountsLegend)}
          onChange={setSelectedSharedAccounts}
          values={selectedSharedAccounts}
        />
      )}
      <div>
        <button
          onClick={() => setShowMoreFilters(!showMoreFilters)}
          aria-expanded={showMoreFilters}
          aria-controls="more-filters"
          className="show-more-button"
        >
          <h3>
            <div className={cx("rotate", { "rotate--down": showMoreFilters })}>
              <NewIcon.ChevronRight />
            </div>
            <span>
              <TranslatedText id="historicTransactionsPage.historicTransactions.filters.showMoreFilters" />
            </span>
          </h3>
        </button>
        <AnimateHeight
          id="more-filters"
          isOpen={showMoreFilters}
          className="more-filters"
        >
          {closedAccounts.length > 0 && (
            <CheckboxGroup
              alternatives={closedAccounts.map((account) => {
                const created = DateTime.fromISO(account.created);
                const closed = DateTime.fromISO(account.closed);
                return {
                  text: `${account.name} (${intl.formatDate(
                    created.toString()
                  )} - ${intl.formatDate(closed.toString())})`,
                  value: account.accountId,
                };
              })}
              header={intl.formatMessage(messages.closedAccountsLegend)}
              onChange={setSelectedClosedAccounts}
              values={selectedClosedAccounts}
            />
          )}
          <CheckboxGroup
            alternatives={Object.values(filterRecord)}
            header={intl.formatMessage(messages.transactionTypeLegend)}
            onChange={setSelectedTransactionTypes}
            values={selectedTransactionTypes}
          />
        </AnimateHeight>
      </div>
      <p className="period-filter-header">
        <TranslatedText id="historicTransactionsPage.historicTransactions.filters.filterPeriod" />
      </p>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (formRef.current?.isValid) {
            setSelectedDates({ start: dateFrom, end: dateTo });
          }
          return;
        }}
      >
        <Datepicker
          label={intl.formatMessage(messages.dateFromLabel)}
          selected={dateFrom}
          onChange={(date: Date) => {
            date && setDateFrom(date);
          }}
          validators={[
            new MaxDateValidator(
              new Date(),
              intl.formatMessage(messages.dateTodayError)
            ),
            new MaxDateValidator(
              dateTo,
              intl.formatMessage(messages.dateFromError)
            ),
          ]}
        />
        <Datepicker
          label={intl.formatMessage(messages.dateToLabel)}
          selected={dateTo}
          onChange={(date: Date) => {
            date && setDateTo(date);
          }}
          validators={[
            new MinDateValidator(
              dateFrom,
              intl.formatMessage(messages.dateToError)
            ),
          ]}
        />
        <Button
          variant="secondary"
          type="submit"
          label={
            <TranslatedText id="historicTransactionsPage.historicTransactions.filters.filterPeriodSubmit" />
          }
        />
      </Form>
      <Button
        variant="secondary"
        onClick={() => {
          setSelectedActiveAccounts(
            activeAccounts.investmentAccounts.map((account) => {
              return { value: account.accountId, text: account.name };
            })
          );
          setSelectedClosedAccounts([]);
          setSelectedTransactionTypes([]);
          setSelectedDates({
            start: defaultFromDate,
            end: defaultToDate,
          });
          setDateFrom(defaultFromDate);
          setDateTo(defaultToDate);
        }}
        className="reset-button"
        label={
          <TranslatedText id="historicTransactionsPage.historicTransactions.filters.resetFilter" />
        }
      />

      <Button
        component="a"
        variant="secondary"
        block
        href={
          process.env.REACT_APP_API +
          `/gdpr-export/transactions?type=excel&${filterQueryString}`
        }
        icon="Download"
        download
        label={
          <TranslatedText id="historicTransactionsPage.historicTransactions.filters.download" />
        }
      />
    </div>
  );
};
