import { useLocation } from "react-router-dom";
import { useRef, useEffect } from "react";
import { useLocalizationContext } from "../context/LocalizationContext";

window.plausible =
  window.plausible ||
  function () {
    (window.plausible.q = window.plausible.q || []).push(arguments);
  };

const uuidRegExp =
  /\/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/gi;

export function usePageViewTracker() {
  const prevPathname = useRef<string>();
  const location = useLocation();

  const country = useLocalizationContext().state.country;

  useEffect(() => {
    if (!country) {
      return;
    }

    const sanitizedUrl = location.pathname.replace(uuidRegExp, "");
    const searchParams = new URLSearchParams(location.search);
    const utmParams = Array.from(searchParams.entries()).filter(([key]) =>
      key.startsWith("utm_")
    );
    const sanitizedParams = new URLSearchParams(utmParams).toString();

    const sanitizedUrlWithParams =
      sanitizedUrl + (sanitizedParams ? "?" + sanitizedParams : "");

    if (prevPathname.current !== sanitizedUrl) {
      window.plausible("pageview", {
        u: window.location.origin + sanitizedUrlWithParams,
      });
      prevPathname.current = sanitizedUrl;
    }
  }, [country, location]);
}
