import { Story } from "@lysaab/ui-2";
import { useState } from "react";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { generatePath, useLocation } from "react-router";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../hooks/useSafeNavigation";
import { PageStripped } from "../../PageStripped";
import { VirtualAccountInformationPage } from "./VirtualAccountInformationPage";
import { VirtualAccountSelectPage } from "./VirtualAccountSelectPage";
import "./VirtualAccountStory.scss";
import { Route } from "../../../components/route/Route";
import { Switch } from "../../../components/route/Switch";
import { useStoryValues } from "../../../hooks/useStoryValues";
import { InvestmentAccount } from "../../../data/dataAccounts";
import { OVERVIEW_PAGE_URL } from "../../overview/OverviewPage";

export const VIRTUAL_ACCOUNT_PAGE_URL = "/deposits/iban";

export const BASE_ROUTES = {
  HOME: `${VIRTUAL_ACCOUNT_PAGE_URL}/`,
  ACCOUNT: `${VIRTUAL_ACCOUNT_PAGE_URL}/:accountId`,
};

const messages = defineMessages({
  header: {
    id: "deposits.virtual-account.story.header",
    defaultMessage: "Deposits",
  },
  ariaProgressLabel: {
    id: "deposits.virtual-account.story.ariaProgressLabel",
  },
});

export const VirtualAccountStory: React.VFC = () => {
  const intl = useIntl();
  const safeNavigation = useSafeNavigation();
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);
  const location = useLocation();
  const [account, setAccount] = useState<InvestmentAccount>();

  const onBack = (currentIndex: number) => {
    if (currentIndex === 0) {
      return;
    }
    safeNavigation(generatePath(Object.values(ROUTES)[currentIndex - 1]));
  };

  return (
    <PageStripped>
      <div className="virtual-account-deposits-story">
        <Story
          ariaLabelProgress={() =>
            intl.formatMessage(messages.ariaProgressLabel, {
              current: currentIndex + 1,
              total: storyLength,
            })
          }
          header={intl.formatMessage(messages.header)}
          progress={storyProgress}
          showBack={currentIndex > 0}
          showClose={true}
          transitionKey={currentIndex.toString()}
          onExit={() => safeNavigation(getNavLink(OVERVIEW_PAGE_URL))}
          onBack={() => onBack(currentIndex)}
        >
          <Switch
            location={location}
            {...{
              order: currentIndex,
            }}
          >
            <Route
              path={ROUTES.HOME}
              exact
              render={() => (
                <VirtualAccountSelectPage
                  preselectedAccount={account}
                  next={(account) => {
                    setAccount(account);
                    safeNavigation(
                      ROUTES.ACCOUNT.replace(":accountId", account.accountId)
                    );
                  }}
                />
              )}
            />
            <Route
              path={ROUTES.ACCOUNT}
              exact
              render={() => <VirtualAccountInformationPage />}
            />
          </Switch>
        </Story>
      </div>
    </PageStripped>
  );
};
