const promises: Record<string, Promise<undefined> | undefined> = {};

/**
 * This is a promise based script loaded that can be called
 * many times with the same script url but will only load it once
 */
export class AsyncScriptLoader {
  static load(url: string) {
    const hashCode = this.getHashCode(url);

    const existingPromise = promises[hashCode];
    if (existingPromise) {
      return existingPromise;
    }

    const promise = new Promise<undefined>((resolve) => {
      const script = document.createElement("script");
      script.id = hashCode;
      script.src = url;

      script.addEventListener("load", function () {
        resolve(undefined);
      });

      document.head.appendChild(script);
    });
    promises[hashCode] = promise;
    return promise;
  }

  private static getHashCode(string: string) {
    let hash = 0,
      i,
      chr;
    for (i = 0; i < string.length; i++) {
      chr = string.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0;
    }
    return hash.toString();
  }
}
