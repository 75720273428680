import { Card, Button, Select, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { useCallback, useContext, useState } from "react";
import * as React from "react";
import AnimateHeight from "react-animate-height";
import { generatePath, Link, Redirect, useHistory } from "react-router-dom";
import { TrackerEvent } from "../../../../../../data/dataCustomerTracking";
import { EventTracker } from "../../../../../../components/eventTracker/EventTracker";
import { KfWithdrawalContext, WithdrawalType } from "./KfWithdrawalContext";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { WithdrawalItem } from "../../../../../../components/withdrawalItem/WithdrawalItem";
import { ROUTES } from "./WithdrawalKfRequestPage";
import { defineMessages, useIntl } from "react-intl";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { LocalizationContext } from "../../../../../../context/LocalizationContext";
import {
  ContactEmailAddresses,
  ContactPhoneNumbers,
} from "../../../../../../components/Contact";
import { Disclaimer } from "./Disclaimer";
import { dataDanica } from "../../../../../../data/dataDanica";
import { getWithdrawalReasonAlternatives } from "../../../../../../components/withdrawal/WithdrawalReasons/Reasons";
import { UserContext } from "../../../../../../context/UserContext";
import { LOGIN_SWEDEN_PAGE_URL } from "../../../LoginPage";
import { LysaCountry } from "@lysaab/shared";

const NoReasonGiven = "no_reason_given";

const withdrawalMessages = defineMessages({
  reason: {
    id: "withdrawalRequest.confirm.select",
    description: "Withdrawal request page - withdrawal reason header",
    defaultMessage: "What is the reason to the withdrawal",
  },
});

export const Confirm: React.FunctionComponent = () => {
  const { state: kfWithdrawalState, setState: setKfWithdrawalState } =
    useContext(KfWithdrawalContext);

  const { account, from, to } = kfWithdrawalState;
  const userContext = useContext(UserContext);
  const user = userContext.state;
  const localizationContext = useContext(LocalizationContext);

  const history = useHistory();
  const intl = useIntl();

  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);

  const save = useCallback(() => {
    if (disabled) {
      return;
    }

    if (!from || !to || !kfWithdrawalState.withdrawalType) {
      return;
    }

    setError(false);
    setDisabled(true);

    dataDanica
      .putKfWithdrawal({
        from: from.accountId,
        to: to.externalBankAccount,
        amount:
          kfWithdrawalState.withdrawalType.value === WithdrawalType.AMOUNT
            ? Number(kfWithdrawalState.amount)
            : undefined,
        complete:
          kfWithdrawalState.withdrawalType.value === WithdrawalType.DRAIN,
      })
      .then((data) => {
        EventTracker.track({
          event: TrackerEvent.WITHDRAWAL,
          message: {
            reason: kfWithdrawalState.reason?.value ?? NoReasonGiven,
          },
        });

        history.push(
          generatePath(getNavLink(ROUTES.SIGNERS), {
            signingId: data.signingId,
          })
        );
      })
      .catch(() => {
        setDisabled(false);
        setError(true);
      });
  }, [disabled, from, to, kfWithdrawalState, history]);

  if (!account || !from || !to) {
    return <Redirect to={getNavLink(ROUTES.FROM)} />;
  }

  if (!user.name) {
    return <Redirect to={generatePath(getNavLink(LOGIN_SWEDEN_PAGE_URL))} />;
  }

  return (
    <div className="withdrawal-request-page-confirm">
      <h1>
        <TranslatedText
          id="withdrawalRequest.kf.confirm.header"
          defaultMessage="Confirm withdrawal"
          description="withdrawal request kf - confirm header"
        />
      </h1>

      <WithdrawalItem
        moneyOnAccount={account.worth}
        name={account.name}
        externalBank={to.bank}
        externalBankAccount={to.externalBankAccount}
        withdrawalAmount={
          kfWithdrawalState.withdrawalType?.value === WithdrawalType.DRAIN
            ? account.worth.toString()
            : kfWithdrawalState.amount
        }
      />

      {kfWithdrawalState.withdrawalType?.value === WithdrawalType.DRAIN && (
        <Snackbar type={SNACKBAR_TYPES.WARNING}>
          <div>
            <TranslatedText
              id="withdrawalRequest.kf.confirm.emptying"
              description="withdrawal request kf - confirm emptying"
              defaultMessage="When emptying your account it will be terminated. You are welcome to contact us for more information on <phone></phone> or <email></email>"
              values={{
                phone: () => {
                  const phoneNumber = intl.formatMessage(
                    ContactPhoneNumbers[
                      localizationContext.state.country || LysaCountry.SWEDEN
                    ]
                  );
                  return <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>;
                },
                email: (chunks) => {
                  const emailAddress = intl.formatMessage(
                    ContactEmailAddresses[
                      localizationContext.state.country || LysaCountry.SWEDEN
                    ]
                  );
                  return <a href={`mailto:${emailAddress}`}>{emailAddress}</a>;
                },
              }}
            />
          </div>
        </Snackbar>
      )}

      <Card>
        <p>
          <TranslatedText
            id="withdrawalRequest.kf.confirm.ingress"
            defaultMessage="In order for us to improve Lysa we'd like to know the reason for the withdrawal although it's voluntary. Your response is treated anonymously."
            description="withdrawal request kf - confirm ingress"
          />
        </p>

        <Select
          placeholder={intl.formatMessage(withdrawalMessages.reason)}
          alternatives={getWithdrawalReasonAlternatives(intl)}
          value={kfWithdrawalState.reason}
          onChange={(reason) => setKfWithdrawalState({ reason })}
          label=""
        />
      </Card>

      <AnimateHeight height={error ? "auto" : 0} animateOpacity>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <div>
            <TranslatedText id="withdrawalRequest.kf.confirm.error" />
          </div>
        </Snackbar>
      </AnimateHeight>

      <Button
        disabled={disabled}
        block
        onClick={save}
        label={<TranslatedText id="withdrawalRequest.kf.confirm.save" />}
      />
      <Button
        variant="secondary"
        disabled={disabled}
        component={Link}
        block
        to={getNavLink(ROUTES.TRANSACTION)}
        label={<TranslatedText id="withdrawalRequest.kf.confirm.change" />}
      />

      <Disclaimer />
    </div>
  );
};
