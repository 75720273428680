import {
  EMPTY_PEP_STATE,
  EntityType,
  PepAnswers,
  PepState,
  requestToState,
  stateToRequest,
} from "@lysaab/lysa-pep";
import { Button, Card, Spinner } from "@lysaab/ui-2";
import { useContext, useEffect, useState, VoidFunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { GridCol } from "../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../components/grid/gridRow/GridRow";
import { LocalizationContext } from "../../../context/LocalizationContext";
import { UserContext } from "../../../context/UserContext";
import { dataCorporate } from "../../../data/dataCorporate";
import { dataKyc } from "../../../data/dataKyc";
import { LegalEntityType } from "../../../data/dataLogin";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { useIsReadOnly } from "../../../hooks/useIsReadOnly";
import { KYC_PAGE_URL } from "../KycViewPage";
import { KYC_UPDATE_PEP_STORY } from "./pepUpdate/PepUpdateStory";

export const Pep: VoidFunctionComponent = () => {
  const userContext = useContext(UserContext);
  const localizationContext = useContext(LocalizationContext);
  const [loading, setLoading] = useState(true);
  const [pepStates, setPepStates] = useState<PepState[]>([]);
  const isReadOnly = useIsReadOnly();

  const country = localizationContext.state.country;
  const legalEntityType = userContext.state.legalEntityType;

  useEffect(() => {
    if (!country) {
      setLoading(false);
      return;
    }

    dataKyc.getPep().then((pepResponseList) => {
      if (legalEntityType === LegalEntityType.PERSON) {
        if (pepResponseList.length === 1) {
          setPepStates([
            {
              ...requestToState(pepResponseList[0].answers, country),
              legalEntityType: EntityType.PERSON,
              locked: pepResponseList[0].locked,
            },
          ]);
        } else {
          setPepStates([
            {
              ...EMPTY_PEP_STATE,
              legalEntityType: EntityType.PERSON,
            },
          ]);
        }
        return setLoading(false);
      }

      if (legalEntityType === LegalEntityType.CORPORATION) {
        dataCorporate.getCompanyData().then((companyData) => {
          const pepStatesList = companyData.owners.reduce(
            (pepStateList, owner) => {
              const ownerPepAnswers = pepResponseList.find(
                (answer) => answer.tin === owner.tin
              );

              if (ownerPepAnswers) {
                return [
                  ...pepStateList,
                  {
                    ...requestToState(ownerPepAnswers.answers, country),
                    tin: owner.tin,
                    ownerName: owner.name,
                    legalEntityType: EntityType.CORPORATION,
                    locked: ownerPepAnswers.locked,
                  },
                ];
              }

              return [
                ...pepStateList,
                {
                  ...EMPTY_PEP_STATE,
                  tin: owner.tin,
                  ownerName: owner.name,
                  legalEntityType: EntityType.CORPORATION,
                },
              ];
            },
            [] as PepState[]
          );

          setPepStates(pepStatesList);
          return setLoading(false);
        });
      }
    });
  }, [country, legalEntityType]);

  return (
    <section>
      <h2 style={{ marginTop: 40 }}>
        <FormattedMessage id="kyc.pep.header" />
      </h2>
      <GridRow>
        {loading && <Spinner />}
        {!loading &&
          pepStates.map((pepState, index) => (
            <GridCol disabledFlexGrow xsmall={12} key={index}>
              {pepStates.length > 1 && <h3>{pepState.ownerName}</h3>}
              <Card>
                <PepAnswers answer={stateToRequest(pepState)} />
                {!pepState.locked && !isReadOnly && (
                  <Button
                    block
                    size="small"
                    component={Link}
                    to={{
                      pathname: getNavLink(KYC_UPDATE_PEP_STORY),
                      search: `?returnUrl=${getNavLink(KYC_PAGE_URL)}&tin=${
                        pepState.tin ?? ""
                      }`,
                    }}
                    label={<FormattedMessage id="kyc.pep.button.edit" />}
                  />
                )}
              </Card>
            </GridCol>
          ))}
      </GridRow>
    </section>
  );
};
