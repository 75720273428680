import { useCallback, VoidFunctionComponent } from "react";
import { Spinner } from "@lysaab/ui-2";
import { RiskWarning } from "../../../../pageComponents/riskWarning/RiskWarning";
import { defineMessages, useIntl } from "react-intl";
import {
  AccountUpdateAction,
  useReviewAccountContext,
} from "../../ReviewAccountContext";
import { UpdateInvestmentAccountComposition } from "../../../../pageComponents/accountsAllocation/accountAdviseCard/AccountAdviceCard";

const messages = defineMessages({
  confirm: {
    id: "yearly.riskWarning.confirm",
  },
});

interface Props {
  navigateToConfirm: () => void;
  navigateToOrigin: (reviewAccount: UpdateInvestmentAccountComposition) => void;
}

export const RiskWarningWrapper: VoidFunctionComponent<Props> = ({
  navigateToConfirm,
  navigateToOrigin,
}) => {
  const intl = useIntl();
  const [reviewAccountState, setReviewAccountState] = useReviewAccountContext();

  const onConfirmSelectedRisk = useCallback(() => {
    if (!reviewAccountState.reviewAccount) {
      throw new Error("onNext - RiskWarningWrapper - Missing reviewAccount");
    }

    if (
      reviewAccountState?.reviewAccount?.action === AccountUpdateAction.KEEP
    ) {
      navigateToOrigin({
        ...reviewAccountState.reviewAccount,
        risk: reviewAccountState.reviewAccount.newAdvice.takenRisk,
        action: AccountUpdateAction.KEEP,
      });
      return;
    }

    setReviewAccountState({
      reviewAccount: {
        ...reviewAccountState.reviewAccount,
        action: AccountUpdateAction.CUSTOM,
      },
    });
    navigateToConfirm();
  }, [
    reviewAccountState.reviewAccount,
    setReviewAccountState,
    navigateToConfirm,
    navigateToOrigin,
  ]);

  const onNextLysa = useCallback(() => {
    if (!reviewAccountState.reviewAccount) {
      throw new Error(
        "onNextLysa - RiskWarningWrapper - Missing reviewAccount"
      );
    }

    setReviewAccountState({
      reviewAccount: {
        ...reviewAccountState.reviewAccount,
        action: AccountUpdateAction.ADVICE,
      },
    });
    navigateToConfirm();
  }, [
    navigateToConfirm,
    reviewAccountState.reviewAccount,
    setReviewAccountState,
  ]);

  if (!reviewAccountState.reviewAccount) {
    return <Spinner />;
  }

  return (
    <RiskWarning
      risk={reviewAccountState.reviewAccount.risk}
      advisedRisk={reviewAccountState.reviewAccount.newAdvice.advice}
      nextButtonText={
        reviewAccountState.reviewAccount.action === AccountUpdateAction.KEEP
          ? intl.formatMessage(messages.confirm)
          : undefined
      }
      confirmSelectedRisk={onConfirmSelectedRisk}
      takeAdvice={onNextLysa}
    />
  );
};
