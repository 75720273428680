import { LysaLink } from "@lysaab/ui-2";
import { useContext, useState } from "react";
import { defineMessages, FormattedDate, useIntl } from "react-intl";
import { getFundName } from "../../../../components/fundName/FundName";
import { LysaTable } from "../../../../components/lysaTable/LysaTable";
import { LysaTableTextCell } from "../../../../components/lysaTableTextCell/LysaTableTextCell";
import { Modal } from "../../../../components/modal/Modal";
import { TranslatedText } from "../../../../components/TranslatedText";
import { TransparentButton } from "../../../../components/transparentButton/TransparentButton";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import { Isin } from "../../../../data/dataFunds";
import { DkTaxRealized } from "../../../../data/dataTax";
import { FundTransactionType } from "../../../../data/dataTransactions";
import { messages } from "./messages";
import "./RealizedTaxCell.scss";

interface MessageWithId {
  id: string;
}

const fundTypeMessages = defineMessages<
  FundTransactionType,
  MessageWithId,
  Record<FundTransactionType, MessageWithId>
>({
  [FundTransactionType.BUY]: {
    id: "denmark.tax.fund.BUY",
  },
  [FundTransactionType.CORRECTION]: {
    id: "denmark.tax.fund.CORRECTION",
  },
  [FundTransactionType.MOVE_IN]: {
    id: "denmark.tax.fund.MOVE_IN",
  },
  [FundTransactionType.MOVE_OUT]: {
    id: "denmark.tax.fund.MOVE_OUT",
  },
  [FundTransactionType.SELL]: {
    id: "denmark.tax.fund.SELL",
  },
  [FundTransactionType.SWITCH_BUY]: {
    id: "denmark.tax.fund.SWITCH_BUY",
  },
  [FundTransactionType.SWITCH_SELL]: {
    id: "denmark.tax.fund.SWITCH_SELL",
  },
});

interface Props {
  realizedTax: DkTaxRealized[];
  isin: Isin;
}

export const RealizedTaxCell: React.VFC<Props> = ({ realizedTax, isin }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <LysaLink
        component={TransparentButton}
        onClick={() => {
          setShowModal(true);
        }}
      >
        <TranslatedText id="denmark.tax.details.view.transactions" />
      </LysaLink>
      <RealizedTaxModal
        isin={isin}
        realizedTax={realizedTax}
        setShowModal={setShowModal}
        showModal={showModal}
      />
    </div>
  );
};

interface ModalProps {
  realizedTax: DkTaxRealized[];
  isin: Isin;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}

const RealizedTaxModal: React.VFC<ModalProps> = ({
  realizedTax,
  isin,
  showModal,
  setShowModal,
}) => {
  const intl = useIntl();

  return (
    <Modal
      printEnabled
      showModal={showModal}
      onClose={() => setShowModal(false)}
      header={<TranslatedText id="denmark.tax.details.realized" />}
      width={800}
      closeOnOverlayClick
    >
      <div className="dk-realized-tax-cell">
        <h4>
          <TranslatedText id="denmark.tax.details.realized" /> -{" "}
          {getFundName(isin, intl)} - {isin}
        </h4>
        <div className="tax-wrapper">
          <LysaTable>
            <thead>
              <tr>
                <LysaTableTextCell
                  className="text-left"
                  value={intl.formatMessage(messages.realizedType)}
                  header
                />
                <LysaTableTextCell
                  className="text-left"
                  value={intl.formatMessage(messages.realizedTradeDate)}
                  header
                />
                <LysaTableTextCell
                  className="text-right"
                  value={intl.formatMessage(messages.realizedVolume)}
                  header
                />
                <LysaTableTextCell
                  className="text-right"
                  value={intl.formatMessage(messages.realizedPrice)}
                  header
                />
              </tr>
            </thead>
            <tbody>
              {realizedTax.map((entry) => (
                <RealizedTaxEntry
                  key={entry.referenceNumber}
                  realizedTax={entry}
                />
              ))}

              {realizedTax.length === 0 && (
                <tr>
                  <td colSpan={6} className="text-center">
                    {intl.formatMessage(messages.noEntriesFound)}
                  </td>
                </tr>
              )}
            </tbody>
          </LysaTable>
        </div>
      </div>
    </Modal>
  );
};

interface RealizedTaxEntryProps {
  realizedTax: DkTaxRealized;
}

const RealizedTaxEntry: React.VFC<RealizedTaxEntryProps> = ({
  realizedTax,
}) => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);

  return (
    <tr>
      <LysaTableTextCell
        className="text-left"
        label={intl.formatMessage(messages.realizedType)}
        value={intl.formatMessage(
          fundTypeMessages[realizedTax.fundTransactionType]
        )}
      />
      <LysaTableTextCell
        className="text-left"
        label={intl.formatMessage(messages.realizedTradeDate)}
      >
        <FormattedDate value={realizedTax.tradeDate} />
      </LysaTableTextCell>
      <LysaTableTextCell
        className="text-right"
        label={intl.formatMessage(messages.realizedVolume)}
        value={intl.formatNumber(realizedTax.volume, {
          minimumFractionDigits: 4,
          maximumFractionDigits: 4,
        })}
      />
      <LysaTableTextCell
        className="text-right"
        label={intl.formatMessage(messages.realizedPrice)}
        value={intl.formatNumber(realizedTax.amount, {
          currency: localizationContext.state.currency,
          style: "currency",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}
      />
    </tr>
  );
};
