import { FunctionComponent, useEffect, useState } from "react";
import "./BannerRedirect.scss";
import GooglePlayStoreBadge from "./play-store-badge.svg";
import AppStoreBadge from "./app-store-badge.svg";
import LysaApp from "./mobile-app.webp";
import { NewIcon, Spinner, Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../components/TranslatedText";
import { HideNav } from "../../../../pages/HideNav";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

export const REDIRECT_TO_APP = "/redirect-to-app";

export const BannerRedirect: FunctionComponent = () => {
  const [showSpinner, setShowSpinner] = useState(true);
  const intl = useIntl();

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      window.location.href = "https://apps.apple.com/se/app/lysa/id6448445720";
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(false);
    }, 700);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {showSpinner ? (
        <Spinner />
      ) : (
        <div className="banner-redirect">
          <HideNav />
          <div className="content">
            <Typography type="h1">
              <TranslatedText id="banner.redirect.title" />
            </Typography>
            <Typography className="body">
              <TranslatedText id="banner.redirect.body" />
            </Typography>
            <div className="badges">
              <a
                href="https://apps.apple.com/se/app/lysa/id6448445720"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={AppStoreBadge}
                  alt={intl.formatMessage({
                    id: "banner.redirect.alt.text.app.store",
                  })}
                  className="app-store-badge"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.lysaapp.prod"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={GooglePlayStoreBadge}
                  alt={intl.formatMessage({
                    id: "banner.redirect.alt.text.play.store",
                  })}
                />
              </a>
            </div>
            <Link to="/" className="go-back">
              <NewIcon.ChevronLeft />
              <TranslatedText id="banner.redirect.back" />
            </Link>
          </div>
          <img className="mobile" src={LysaApp} alt="Lysa app" />
        </div>
      )}
    </>
  );
};
