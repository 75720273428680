import { API, cache } from "@lysaab/ui-2";
import { CompoundAccountId } from "./dataAccounts";
import { banks } from "./dataBanks";

export type MonthlyPayment =
  | MonthlyPaymentWithBankInfo
  | MonthlyPaymentWithoutBankInfo;

interface MonthlyPaymentBase {
  accountId: CompoundAccountId;
  amount: number;
  /**
   * Format YYYY-MM-DD
   */
  nextDeduction: string;
  depositDay: number;
  updateAmountCutoff: string;
  updateDayCutoff: string;
  isSkipped: boolean;
  amountLimit?: number;
}

export interface MonthlyPaymentWithBankInfo extends MonthlyPaymentBase {
  externalBankAccount: string;
  bank: keyof typeof banks;
}

export type MonthlyPaymentWithoutBankInfo = MonthlyPaymentBase;

export interface MonthlyChangeRequest {
  externalBankAccount: string;
  accountId: CompoundAccountId;
}

export interface MonthlyUpdateRequest {
  externalBankAccount: string;
  accountId: CompoundAccountId;
  amount: number;
  depositDay: number;
}

export interface AllMonthlyPaymentResponse {
  monthlyPayments: MonthlyPaymentWithBankInfo[];
  monthlyPaymentsSharedAccounts: MonthlyPaymentWithoutBankInfo[];
}

export const dataMonthlyPayments = {
  getMonthlyPayments: () => {
    return API.get<MonthlyPaymentWithBankInfo[]>("/monthly-payments");
  },
  getAllMonthlyPayments: () => {
    return API.get<AllMonthlyPaymentResponse>("/monthly-payments/all");
  },
  skipMonthlyPayment: (data: MonthlyChangeRequest) => {
    return API.post<MonthlyPaymentWithBankInfo[]>(
      "/monthly-payments/skip",
      data
    ).then((resp) => {
      cache.delete("/monthly-payments");
      cache.delete("/autogiro");
      cache.delete("/direct-debit");
      return resp;
    });
  },
  resetMonthlyPayment: (data: MonthlyChangeRequest) => {
    return API.post<MonthlyPaymentWithBankInfo[]>(
      "/monthly-payments/skip/reset",
      data
    ).then((resp) => {
      cache.delete("/monthly-payments");
      cache.delete("/autogiro");
      cache.delete("/direct-debit");
      return resp;
    });
  },
  deleteMonthlyPayment: (data: MonthlyChangeRequest) => {
    return API.delete<MonthlyPaymentWithBankInfo[]>(
      "/monthly-payments",
      data
    ).then((resp) => {
      cache.delete("/monthly-payments");
      cache.delete("/autogiro");
      cache.delete("/direct-debit");
      return resp;
    });
  },
  updateMonthlyPayment: (data: MonthlyUpdateRequest) => {
    return API.post<MonthlyPaymentWithBankInfo[]>(
      "/monthly-payments",
      data
    ).then((resp) => {
      cache.delete("/monthly-payments");
      cache.delete("/autogiro");
      cache.delete("/direct-debit");
      return resp;
    });
  },
};

export const isMonthlyPaymentWithBankInfo = (
  monthlyPayment: MonthlyPayment
): monthlyPayment is MonthlyPaymentWithBankInfo => "bank" in monthlyPayment;

export const isMonthlyPaymentWithoutBankInfo = (
  monthlyPayment: MonthlyPayment
): monthlyPayment is MonthlyPaymentWithoutBankInfo =>
  !("bank" in monthlyPayment);
