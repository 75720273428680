import { LysaCountry } from "@lysaab/shared";
import { API, cache, InvestmentType, encode } from "@lysaab/ui-2";
import {
  InvestmentAccount,
  InvestmentAccountId,
  AccountType,
  SavingsAccountId,
} from "./dataAccounts";
import { Isin } from "./dataFunds";
import { DateTime } from "luxon";

export interface FeeEstimation {
  discretionary: number;
  fundManagement: number;
  fundAssets: number;
  transactionFees: number;
  insurancePremium?: number;
  insuranceRiskPremium?: number;
  total: number;
}

export interface AccountFeeResponse {
  historic: FeeEstimation;
  future: FeeEstimation;
  cost: FeeEstimation;
  rebate?: number;
  rebateExpiry?: string;
}

interface FeesEstimatedParams {
  amount: number;
  risk: number;
  investmentType: InvestmentType;
  country: LysaCountry;
  accountType: AccountType;
  insured?: string;
}

export interface FeesEstimated {
  future: FeeEstimation;
  cost: FeeEstimation;
}
export interface FeesEstimatedSignedIn {
  future: FeeEstimation;
  cost: FeeEstimation;
  rebate?: number;
  rebateExpiry?: string;
  insurancePremium?: number;
}
export interface Tax {
  taxPercentage: number;
  taxAmount: number;
}

export interface HistoricFeeResponse {
  summary: HistoricFeeSummary;
  accounts: HistoricFeeAccountResponse[];
  closedAccounts: HistoricFeeClosedAccountResponse[];
  rebates: RebatePeriodResponse[];
  savingsAccounts: HistoricFeeSavingsAccountResponse[];
  closedSavingsAccounts: HistoricFeeClosedSavingsAccountResponse[];
  savingsSummary: HistoricFeeSavingsAccountsSummary;
}
export interface HistoricFeeSummary {
  averageTotalCost: number;
  averageManagementCost: number;
  avgDiscretionaryCost: number;
  averageUnderlyingAssetsCost: number;
  underlyingTransactionsCost: number;
  fundManagementFee: number;
  paidDiscretionaryFee: number;
  rebatedDiscretionaryFee: number;
  underlyingAssetsFee: number;
  underlyingTransactionsFee: number;
  total: number;
}

export interface HistoricFeeSavingsAccountsSummary {
  avgDiscretionaryCost: number;
  paidDiscretionaryFee: number;
  rebatedDiscretionaryFee: number;
}

export interface HistoricFeeSavingsAccountResponse {
  accountId: SavingsAccountId;
  avgCost: number;
  fee: number;
  rebatedFee: number;
}

export interface HistoricFeeClosedSavingsAccountResponse
  extends HistoricFeeSavingsAccountResponse {
  accountName: string;
  created: string;
  closed: string;
}
export interface HistoricFeeAccountResponse {
  accountId: InvestmentAccountId;
  discretionary: Fee;
  fund: FundFees;
  total: number;
  averageTotalCost: number;
}

export interface HistoricFeeClosedAccountResponse
  extends HistoricFeeAccountResponse {
  accountName: string;
  created: string;
  closed: string;
}

export interface Fee {
  avgCost: number;
  paidFee: number;
  rebatedFee: number;
  daysInvested: number;
  averageWorth: number;
}

export interface FundFees {
  averageTotalCost: number;
  underlyingTransactionsCost: number;
  averageManagementCost: number;
  averageUnderlyingAssetsCost: number;
  managementFee: number;
  underlyingAssetsFee: number;
  underlyingTransactionsFee: number;
  total: number;
  details: FundFeeDetails[];
}

export interface FundFeeDetails {
  averageTotalCost: number;
  averageManagementCost: number;
  averageUnderlyingAssetsCost: number;
  underlyingTransactionsCost: number;
  underlyingTransactionsFee: number;
  fundManagementFee: number;
  underlyingAssetsFee: number;
  isin: Isin;
  daysInvested: number;
  averageWorth: number;
}

export type RebatePeriodResponse = {
  rebatedCost: number;
  rebatedFee: number;
  start: string;
  end: string;
};

export interface PaidFee {
  accountId: InvestmentAccountId;
  date: string;
  fee: number;
  feeExcludingVat: number;
  transactionId: string;
}

export interface SavingsAccountFeeResponse {
  historic: number;
  future: number;
  cost: number;
  rebateExpiry?: string;
  rebate?: number;
}

export const defaultAnswer = {
  historic: {
    discretionary: "-",
    fundManagement: "-",
    fundAssets: "-",
    transactionFees: "-",
    total: "-",
  },
  future: {
    discretionary: "-",
    fundManagement: "-",
    fundAssets: "-",
    transactionFees: "-",
    total: "-",
  },
  cost: {
    discretionary: "-",
    fundManagement: "-",
    fundAssets: "-",
    transactionFees: "-",
    total: "-",
  },
};

const GET_BASE_FEES_URL = "/fees/account";

export const dataFees = {
  getEstimatedFeesSignedIn: ({
    amount,
    risk,
    investmentType,
    country,
    accountType,
    insured,
  }: FeesEstimatedParams) => {
    const searchParams = new URLSearchParams();
    searchParams.append("amount", amount.toString());
    searchParams.append("risk", risk.toString());
    searchParams.append("investmentType", investmentType);
    searchParams.append("country", country);
    searchParams.append("accountType", accountType);

    if (insured) {
      const birthDate = DateTime.fromFormat(
        insured.substring(0, 8),
        "yyyyMMdd"
      );
      searchParams.append("insuredBirthday", birthDate.toFormat("yyyy-MM-dd"));
    }

    return API.get<FeesEstimatedSignedIn>(
      `/fees/estimate/active-user/yearly?${searchParams.toString()}`
    );
  },

  getFees: (account: InvestmentAccount) => {
    return API.get<AccountFeeResponse>(
      GET_BASE_FEES_URL + encode`/${account.accountId}`
    );
  },

  clearGetFees: (account: InvestmentAccount) => {
    cache.delete(`${GET_BASE_FEES_URL}/${account.accountId}`);
  },

  getEstimatedTaxSingleDeposit: (oneTimeDeposit: string) => {
    return API.get<Tax>(
      encode`/danica/accounts/estimate-tax?oneTimeDeposit=${oneTimeDeposit}`
    );
  },

  getEstimatedTaxMonthlyDeposit: (monthlyDeposit: string) => {
    return API.get<Tax>(
      encode`/danica/accounts/estimate-tax?monthlyDeposit=${monthlyDeposit}`
    );
  },

  getHistoricFees: (year: number) => {
    return API.get<HistoricFeeResponse>(encode`/fees/historic?year=${year}`);
  },

  getPaidFees: () => {
    return API.get<PaidFee[]>(encode`/fees/paid`);
  },

  getSavingsAccountFees: (accountId: SavingsAccountId) => {
    return API.get<SavingsAccountFeeResponse>(
      encode`/fees/savings-account/${accountId}`
    );
  },
};
