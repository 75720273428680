import { SwedishTin } from "@lysaab/ui-2";
import { DateTime } from "luxon";

export function getUserAge(tin: string | undefined) {
  if (!tin) {
    return;
  }
  const birthDate = new SwedishTin(tin).getBirthDate();
  if (!birthDate) {
    return;
  }

  return Math.floor(
    DateTime.now().diff(DateTime.fromFormat(birthDate, "yyyy-MM-dd"), "years")
      .years
  );
}
