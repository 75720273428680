import {
  LysaFormRef,
  Spinner,
  Form,
  RequiredValidator,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
  Typography,
  RadioGroupCard,
} from "@lysaab/ui-2";
import { useEffect, useRef, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../../components/TranslatedText";
import { SavingsAccount } from "../../../../data/dataAccounts";
import { MergedInternalTransferAccount } from "../../../../data/dataTransfer";
import {
  dataWithdrawals,
  ExternalAccount,
  getBankName,
  getBankText,
} from "../../../../data/dataWithdrawals";
import { useAccounts } from "../../../../hooks/useAccounts";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { FromInternalTransfer } from "../../../internalTransfer/InternalTransferPage";
import { AddKlarnaAccountLocationState } from "../../addAccountKlarna/AddAccountKlarnaStory";
import { Disclaimer } from "../components/disclaimer/Disclaimer";
import { useWithdrawalContext } from "../WithdrawalContext";
import { WITHDRAWALS_REQUEST_PAGE } from "../WithdrawalStory";
import * as H from "history";
import HorizontalDivider from "../../../../components/horizontalDivider/HorizontalDivider";
import { WithdrawalHelp } from "../components/withdrawalHelp/WithdrawalHelp";
import { BankLogo } from "../../../../components/bankLogo/BankLogo";
import { dataBanks } from "../../../../data/dataBanks";

import "./SelectExternalAccount.scss";

interface Props {
  next: () => void;
  addWithdrawalAccountUrl?: string;
}

export interface ToInternalTransfer {
  fromAccount: MergedInternalTransferAccount;
  toAccount: SavingsAccount;
  returnLocation: H.History.LocationDescriptor<FromInternalTransfer>;
}

const messages = defineMessages({
  lysaAccountLabel: {
    id: "withdrawal.story.external-account-selection.lysa.label",
  },
  lysaAccountRequired: {
    id: "withdrawal.story.external-account-selection.lysa.required",
  },
  lysaSavingsAccountLabel: {
    id: "withdrawal.story.external-account-selection.savingsAccount.label",
  },
});

export function SelectExternalAccount({
  next,
  addWithdrawalAccountUrl,
}: Props) {
  const intl = useIntl();
  const { accounts } = useAccounts();
  const formRef = useRef<LysaFormRef>();
  const history = useHistory<ToInternalTransfer>();
  const location = useLocation<FromInternalTransfer>();
  const [externalAccounts, setExternalAccounts] = useState<ExternalAccount[]>();
  const { state: withdrawalContext, setState: setWithdrawalContext } =
    useWithdrawalContext();
  const selectedExternalAccount = withdrawalContext.selectedExternalAccount;
  const selectedLysaAccount = withdrawalContext.selectedLysaAccount;
  const selectedLysaSavingsAccount =
    withdrawalContext.selectedLysaSavingsAccount;

  useEffect(() => {
    if (selectedLysaAccount || selectedLysaSavingsAccount) {
      return;
    } else if (location.state?.fromAccount) {
      setWithdrawalContext({
        selectedLysaAccount: location.state.fromAccount,
      });
    } else {
      history.push(getNavLink(WITHDRAWALS_REQUEST_PAGE));
    }
  }, [
    history,
    location,
    selectedLysaAccount,
    selectedLysaSavingsAccount,
    setWithdrawalContext,
  ]);

  useEffect(() => {
    dataWithdrawals.getExternalAccounts().then(setExternalAccounts);
  }, []);

  if (!externalAccounts || !accounts) {
    return <Spinner />;
  }

  return (
    <div className="withdrawal-story-external">
      <Typography type="h2">
        <TranslatedText id="withdrawal.story.external-account-selection.header" />
      </Typography>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (formRef.current?.isInvalid) {
            return;
          }
          next();
        }}
      >
        <div className="withdrawal-story-external__external-accounts-wrapper">
          {externalAccounts.length === 0 ? (
            <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
              <FormattedMessage id="withdrawal.story.external-account-selection.no-accounts" />
            </Snackbar>
          ) : (
            <RadioGroupCard
              alternatives={externalAccounts.map((account) => {
                return {
                  header: account.externalBankAccount,
                  description: (
                    <Typography type="label" component="span">
                      {getBankName(account)}
                    </Typography>
                  ),
                  logo: <BankLogo bank={dataBanks.getBank(account.bank)} />,
                  value: {
                    text: getBankText(account),
                    value: account.externalBankAccount,
                  },
                };
              })}
              legend={intl.formatMessage(messages.lysaAccountLabel)}
              onChange={(alt) => {
                setWithdrawalContext({
                  selectedExternalAccount: externalAccounts.find(
                    (account) => account.externalBankAccount === alt.value
                  ),
                });
              }}
              value={
                selectedExternalAccount && {
                  text: getBankText(selectedExternalAccount),
                  value: selectedExternalAccount.externalBankAccount,
                }
              }
              validators={[
                new RequiredValidator(
                  intl.formatMessage(messages.lysaAccountRequired)
                ),
              ]}
            />
          )}
          {addWithdrawalAccountUrl && (
            <div className="add-withdrawal-account-wrapper">
              <Link<AddKlarnaAccountLocationState>
                className="lysa-ui-button block button-medium button-outlined"
                to={{
                  pathname: addWithdrawalAccountUrl,
                  state: { returnUrl: getNavLink(WITHDRAWALS_REQUEST_PAGE) },
                }}
              >
                <Typography type="label" component="span">
                  <TranslatedText id="withdrawal.story.external-account-selection.add" />
                </Typography>
              </Link>
            </div>
          )}
        </div>

        <Button
          type="submit"
          block
          label={
            <TranslatedText id="withdrawal.story.external-account-selection.button" />
          }
        />
      </Form>
      <HorizontalDivider />
      <WithdrawalHelp />
      <Disclaimer />
    </div>
  );
}
