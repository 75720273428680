import {
  API,
  encode,
  Alternative,
  BankIdStandardResponses,
  BankIdInitResponse,
  OrderRef,
} from "@lysaab/ui-2";
import { InvestmentAccountId } from "./dataAccounts";

export interface SaveTransferRequest {
  accountId: InvestmentAccountId;
  institute: string;
  accountNumber: string;
  fullTransfer: boolean;
  amount?: number;
  closeAccount?: boolean;
}

export interface SavedTransfer {
  transferId: number;
  accountId: InvestmentAccountId;
  institute: string;
  accountNumber: string;
  amount?: number;
  fullTransfer: boolean;
  created: string;
  signed: string;
  sent?: string;
  completed?: string;
}

export interface SaveTransferResponse {
  transferId: number;
}

export const dataIsk = {
  saveTransfer: (request: SaveTransferRequest) => {
    return API.post<SaveTransferResponse>("/isk/transfer", request);
  },
  startSigning: (transferId: number) => {
    return API.post<BankIdInitResponse>("/bankid/isk-transfer", { transferId });
  },
  pollSigning: (orderRef: OrderRef, transferId: number) => {
    return API.get<BankIdStandardResponses>(
      encode`/bankid/isk-transfer/${orderRef}?transferId=${transferId}`,
      true
    );
  },
  pollQrCode: (orderRef?: OrderRef) => {
    if (!orderRef) {
      return Promise.resolve(undefined);
    }

    return API.get<{ code: string }>(
      encode`/bankid/isk-transfer/qr/${orderRef}`,
      true
    );
  },
  getInstitutions: () => {
    return API.get<Alternative<string>[]>(`/isk/transfer/institutes`);
  },
  getTransferHistory: () => {
    return API.get<SavedTransfer[]>(`/isk/transfer`);
  },
};
