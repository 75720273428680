import { Icon } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { accountNumberHints } from "./AccountInformationHints";
import "./AccountInformationInputHint.scss";

interface Props {
  value: string;
}

export const AccountInformationInputHint: React.VFC<Props> = ({ value }) => {
  const intl = useIntl();

  const helpMessage = accountNumberHints.find((hint) => {
    if (hint.test(value)) {
      return true;
    }
    return false;
  })?.message;

  if (!helpMessage) {
    return null;
  }

  return (
    <div className="account-information-help">
      <Icon.Error color="#1840e3" size={16} />
      <span>{intl.formatMessage(helpMessage)}</span>
    </div>
  );
};
