import { useRef, VoidFunctionComponent, useEffect } from "react";

import {
  SustainabilityImportance,
  SustainabilityQuestionsImportantNone,
  SustainabilityQuestionsImportantSpecific,
} from "../../../../../data/dataInvestments";
import { FormattedMessage } from "react-intl";
import { useTransfer } from "../TransferContext";
import { Button, Form, LysaFormRef } from "@lysaab/ui-2";
import { SustainabilityPreferenceQuestion } from "../../../../../pageComponents/sustainability/SustainabilityPreferenceQuestion";
import { useHistory } from "react-router";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { TRANSFER_PENSIONS_URL } from "../TransferPensionsStory";

interface Props {
  next(): void;
}

export const Preference: VoidFunctionComponent<Props> = ({ next }) => {
  const formRef = useRef<LysaFormRef>();
  const [transfer, setTransfer] = useTransfer();
  const history = useHistory();

  /**
   * Safe guard in case we loose context. On refresh etc. Send user back to start of story.
   */
  useEffect(() => {
    if (typeof transfer?.moves[0]?.institute === "undefined") {
      history.replace(getNavLink(TRANSFER_PENSIONS_URL));
    }
  }, [history, transfer?.moves]);

  if (transfer.sustainability !== SustainabilityImportance.IMPORTANT) {
    return null;
  }

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={() => {
        if (formRef.current?.isValid) {
          next();
        }
      }}
    >
      <SustainabilityPreferenceQuestion
        sustainabilityPreference={
          (
            transfer as
              | SustainabilityQuestionsImportantNone
              | SustainabilityQuestionsImportantSpecific
          ).sustainabilityPreference
        }
        setSustainabilityPreference={(sustainabilityPreference) =>
          setTransfer({ sustainabilityPreference })
        }
      />
      <Button
        block
        type="submit"
        label={
          <FormattedMessage id="sweden.transfer-pension.suitability-preference.button" />
        }
      />
    </Form>
  );
};
