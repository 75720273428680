import { API, encode } from "@lysaab/ui-2";

export enum MailStatus {
  // ERROR means Mailchimp could definitely not deliver the email. It might
  // have bounced. Or the recipient has chosen not to receive emails from us
  ERROR = "ERROR",
  // SENT only means Mailchimp has sent the email. Does not mean the email is
  // valid, or anything like that
  SENT = "SENT",
  // OPEN is meant to mean the user has opened the email, but with new privacy
  // measures in place by Apple and Google this is no longer guaranteed. Treat
  // this the same as SENT
  OPEN = "OPEN",
  // PENDING is the first status we get, before Mailchimp sends the email
  PENDING = "PENDING",
}

interface MailStatusResponse {
  timestamp: string;
  state: MailStatus;
}

export function mailStatus(statusId: string) {
  return API.get<MailStatusResponse>(
    encode`/mail/verify-status?statusId=${statusId}`,
    true
  );
}
