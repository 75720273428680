import { VoidFunctionComponent } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Amount } from "../../../../../../components/amount/Amount";
import { Fee } from "../../../../../../data/dataFees";
import { CostBodyCell } from "../../../components/costBodyCell/CostBodyCell";
import { CostHeaderCell } from "../../../components/costHeaderCell/CostHeaderCell";
import { ExpandableTable } from "../../components/expandableTable/ExpandableTable";
import { FeeBodyCell } from "../../../components/feeBodyCell/FeeBodyCell";
import { FeeHeaderCell } from "../../../components/feeHeaderCell/FeeHeaderCell";

const messages = defineMessages({
  paidFee: {
    id: "fees.feeTables.activeAccount.accountRow.discretionaryFeesTable.paidFee",
  },
  rebatedFee: {
    id: "fees.feeTables.activeAccount.accountRow.discretionaryFeesTable.rebatedFee",
  },
  daysInvested: {
    id: "fees.feeTables.activeAccount.accountRow.discretionaryFeesTable.daysInvested",
  },
  averageWorth: {
    id: "fees.feeTables.activeAccount.accountRow.discretionaryFeesTable.averageWorth",
  },
  avgCost: {
    id: "fees.feeTables.activeAccount.accountRow.discretionaryFeesTable.avgCost",
  },
});

interface Props {
  accountData: Fee;
  isOpen: boolean;
  columns: number;
}

export const DiscretionaryFeesTable: VoidFunctionComponent<Props> = ({
  accountData,
  isOpen,
  columns,
}) => {
  const intl = useIntl();

  return (
    <ExpandableTable isOpen={isOpen} parentCells={columns}>
      <thead>
        <tr>
          <FeeHeaderCell text={intl.formatMessage(messages.paidFee)} />
          <FeeHeaderCell text={intl.formatMessage(messages.rebatedFee)} />
          <CostHeaderCell text={intl.formatMessage(messages.avgCost)} />
          <th>{intl.formatMessage(messages.daysInvested)}</th>
          <th>{intl.formatMessage(messages.averageWorth)}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <FeeBodyCell
            value={accountData.paidFee}
            label={intl.formatMessage(messages.paidFee)}
          />
          <FeeBodyCell
            value={accountData.rebatedFee}
            label={intl.formatMessage(messages.rebatedFee)}
          />
          <CostBodyCell
            value={accountData.avgCost}
            label={intl.formatMessage(messages.avgCost)}
          />
          <td data-label={intl.formatMessage(messages.daysInvested)}>
            {accountData.daysInvested}
          </td>
          <td data-label={intl.formatMessage(messages.averageWorth)}>
            <Amount amount={accountData.averageWorth} decimals={2} />
          </td>
        </tr>
      </tbody>
    </ExpandableTable>
  );
};
