import { FC } from "react";
import { useIntl } from "react-intl";
import { Typography } from "@lysaab/ui-2";
import { Modal } from "../../modal/Modal";
import { TranslatedText } from "../../TranslatedText";

interface Props {
  reallocationYears: number;
  showModal: boolean;
  onClose: () => void;
}

export const AllocationStagesModal: FC<Props> = ({
  reallocationYears,
  showModal,
  onClose,
}) => {
  const intl = useIntl();

  return (
    <Modal
      showModal={showModal}
      onClose={onClose}
      closeOnOverlayClick
      header={intl.formatMessage({
        id: "glidePathGraph.allocationStagesModal.header",
      })}
    >
      <Typography>
        <TranslatedText
          id="glidePathGraph.allocationStagesModal.explanation"
          values={{ years: reallocationYears }}
        />
      </Typography>
    </Modal>
  );
};
