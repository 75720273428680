import { Typography, NewIcon } from "@lysaab/ui-2";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
  VoidFunctionComponent,
} from "react";
import "./WorthSummaryHeader.scss";
import { ReturnInformationModal } from "./ReturnInformationModal";
import { FormattedMessage, defineMessages } from "react-intl";
import { InvestmentAccount } from "../../data/dataAccounts";
import { Amount } from "../../components/amount/Amount";
import { FormattedStyledPercentage } from "../../components/formattedPercentage/FormattedStyledPercentage";
import cx from "classnames";
import { HolidayAnimations } from "./holidays/valentines/HolidayAnimations";
import { useBalanceAndEarnings } from "./hooks/useBalanceAndEarnings";
import { Store, STORE_SHOW_PERFORMANCE_GRAPH } from "../../Store";
import { PercentageCurrencySwitch } from "../../components/percentageCurrencySwitch/PercentageCurrencySwitch";
import { useShowCurrency } from "../../context/LayoutContext";
import { useAccountsWorth } from "../../hooks/useAccountsWorth";
import { summaryHeaderIntervalMessages } from "../../components/IntervalMessages";

interface Props {
  account?: InvestmentAccount;
  showGraph: boolean;
  setShowGraph: Dispatch<SetStateAction<boolean>>;
}

const messages = defineMessages({
  balanceHeader: {
    id: "overview.summaryCard.balance",
  },
  balanceHeaderAccount: {
    id: "overview.summaryCard.balance.account",
  },
});

export const WorthSummaryHeader: VoidFunctionComponent<Props> = ({
  account,
  showGraph,
  setShowGraph,
}) => {
  const [showReturnInformationModal, setShowReturnInformationModal] =
    useState(false);
  const { showCurrency, toggleCurrency } = useShowCurrency();
  const { data, interval } = useBalanceAndEarnings(account);
  const accountsWorth = useAccountsWorth();

  const totalWorth =
    typeof account !== "undefined"
      ? account.isSharedAccount
        ? accountsWorth.sharedInvestmentAccounts?.[account.accountId] ?? 0
        : accountsWorth.investmentAccounts?.[account.accountId] ?? 0
      : accountsWorth.totalWorth;

  const handleGraphToggle = useCallback(() => {
    setShowGraph(!showGraph);
    Store.setValue(STORE_SHOW_PERFORMANCE_GRAPH, !showGraph);
  }, [setShowGraph, showGraph]);

  return (
    <>
      <div
        className={cx("layout", {
          "account-page": typeof account !== "undefined",
          "hidden-graph": !showGraph,
        })}
      >
        <HolidayAnimations />

        <div className="card-actions">
          <PercentageCurrencySwitch
            value={showCurrency}
            onChange={() => {
              toggleCurrency();
            }}
            variant={account ? "secondary" : "primary"}
          />
          <button
            className={cx("header-circle-button", {
              "showing-graph": showGraph,
            })}
            onClick={handleGraphToggle}
          >
            <NewIcon.Investment size={20} />
          </button>
        </div>

        <div
          className={cx("wrapper", {
            "is-loaded": typeof data !== "undefined",
          })}
        >
          <div className="balance-and-earnings">
            <div className="total-worth-wrapper">
              <div className="total-worth-header">
                <Typography className="header" type="label" component="span">
                  {account ? (
                    <FormattedMessage {...messages.balanceHeaderAccount} />
                  ) : (
                    <FormattedMessage {...messages.balanceHeader} />
                  )}
                </Typography>
              </div>
              <div className="number">
                <Typography type="display-large" component="span">
                  {data ? <Amount amount={totalWorth} decimals={0} /> : "-"}
                </Typography>
              </div>
            </div>

            <div className="yield-wrapper">
              <div className="yield-header">
                <Typography className="header" type="label" component="span">
                  <FormattedMessage
                    {...summaryHeaderIntervalMessages[interval]}
                  />
                </Typography>
                <button
                  className="yield-circle-button"
                  onClick={() => setShowReturnInformationModal(true)}
                >
                  <NewIcon.InformationOutline size={20} />
                </button>
              </div>

              <div className="yields">
                {showCurrency ? (
                  <div className="absolute-amount number">
                    <Typography type="display-small" component="span">
                      {data ? (
                        <Amount
                          amount={data.totalEarnings}
                          showSign={true}
                          decimals={0}
                        />
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </div>
                ) : (
                  <div className="percentage number">
                    <Typography type="display-small" component="span">
                      {data ? (
                        <FormattedStyledPercentage
                          value={data.totalChange}
                          showSign={true}
                          decimals={2}
                        />
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </div>

          <ReturnInformationModal
            showModal={showReturnInformationModal}
            onClose={() => setShowReturnInformationModal(false)}
          />
        </div>
      </div>
    </>
  );
};
