import { Typography } from "@lysaab/ui-2";
import { getAccountAmount, SavingsAccount } from "../../data/dataAccounts";
import { AccountType } from "../accountType/AccountType";
import { Amount } from "../amount/Amount";

interface Props {
  account: SavingsAccount;
}

export const LysaAccountSelectionCardSavingsAccount: React.VFC<Props> = ({
  account,
}) => {
  return (
    <>
      <div>
        <Amount amount={getAccountAmount(account)} />
      </div>
      <Typography type="body-small" component="span">
        <AccountType account={account} />
      </Typography>
    </>
  );
};
