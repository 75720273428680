import { VoidFunctionComponent } from "react";
import { Card, Button, Icon } from "@lysaab/ui-2";
import { FormattedMessage, useIntl } from "react-intl";
import { TranslatedText } from "../../components/TranslatedText";
import "./RiskWarning.scss";

export const ROUTE = "/risk-warning";

interface Props {
  risk: number;
  advisedRisk: number;
  nextButtonText?: string;
  confirmSelectedRisk: () => void;
  takeAdvice: () => void;
}

export const RiskWarning: VoidFunctionComponent<Props> = ({
  risk,
  advisedRisk,
  nextButtonText,
  confirmSelectedRisk,
  takeAdvice,
}) => {
  const intl = useIntl();

  const stocks = risk;
  const bonds = 100 - risk;
  const stocksAdvised = advisedRisk;
  const bondsAdvised = 100 - advisedRisk;

  return (
    <div className="risk-warning-page">
      <h2>
        <FormattedMessage id="riskwarning.headline" />
      </h2>
      <section>
        <Card>
          <Icon.Error size={48} color="#FDDE4A" />
          <p className="risk-warning-message">
            <TranslatedText
              id="riskwarning.text"
              values={{
                stocks: intl.formatNumber(stocks / 100, { style: "percent" }),
                bonds: intl.formatNumber(bonds / 100, { style: "percent" }),
                stocksAdvice: intl.formatNumber(stocksAdvised / 100, {
                  style: "percent",
                }),
                bondsAdvice: intl.formatNumber(bondsAdvised / 100, {
                  style: "percent",
                }),
              }}
            />
          </p>
        </Card>
      </section>
      <Button
        onClick={confirmSelectedRisk}
        block
        label={
          nextButtonText || <FormattedMessage id="riskwarning.buttons.next" />
        }
      />

      <Button
        variant="secondary"
        onClick={takeAdvice}
        block
        label={<FormattedMessage id="riskwarning.buttons.lysasuggestion" />}
      />
    </div>
  );
};
