import { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { useEffect } from "react";
import { getUserAge } from "../utils/userAge";
import { Button, Slider, Typography } from "@lysaab/ui-2";
import { useUser } from "../../../../../context/UserContext";
import { DEFAULT_RISK_DEVIATION, useTransfer } from "../TransferContext";
import { GlidePathGraph } from "../../../../../components/glidePathGraph/GlidePathGraph";
import { useHistory } from "react-router";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { TRANSFER_PENSIONS_URL } from "../TransferPensionsStory";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { usePensionAllocation } from "../../../../../hooks/usePensionAllocation";

import "./TakenRiskDeviation.scss";

export const TakenRiskDeviation: FunctionComponent = () => {
  const intl = useIntl();
  const [transfer, setTransfer] = useTransfer();
  const history = useHistory();
  const user = useUser();
  const age = getUserAge(user.tin);
  const { allocation } = usePensionAllocation({ ...transfer, age });

  /**
   * Safe guard in case we loose context. On refresh etc. Send user back to start of story.
   */
  useEffect(() => {
    if (typeof transfer?.moves[0]?.institute === "undefined") {
      history.replace(getNavLink(TRANSFER_PENSIONS_URL));
    }
  }, [history, transfer?.moves]);

  if (!age) {
    return null;
  }

  return (
    <article className="transfer-pension-story-taken-risk-deviation">
      <Typography type="h3" className="header">
        <TranslatedText id="sweden.transfer-pension.taken-risk-deviation.header" />
      </Typography>
      <section className="glide-path">
        {allocation && (
          <GlidePathGraph
            glidePath={allocation.glidePath}
            reallocationAge={allocation.reallocationAge}
            withdrawalAge={allocation.withdrawalAge}
          />
        )}
      </section>
      <section className="information">
        <Typography type="h3">
          <TranslatedText id="sweden.transfer-pension.taken-risk-deviation.heading" />
        </Typography>
        <Typography type="body">
          <TranslatedText id="sweden.transfer-pension.taken.risk-deviation.ingress" />
        </Typography>
      </section>
      <Slider
        label=""
        min={-20}
        max={20}
        value={transfer.takenRiskDeviation}
        onChange={(value) => {
          setTransfer({ takenRiskDeviation: value });
        }}
      />
      <section className="slider-scale">
        <span>-20%</span>
        <span>0%</span>
        <span>+20</span>
      </section>
      <Button
        block
        type="button"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.risk-deviation.save",
        })}
        onClick={history.goBack}
      />
      <Button
        block
        variant="secondary"
        type="submit"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.risk-deviation.cancel",
        })}
        onClick={() => {
          setTransfer({ takenRiskDeviation: DEFAULT_RISK_DEVIATION });
          history.goBack();
        }}
      />
    </article>
  );
};
