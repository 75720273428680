import { FC, ReactElement } from "react";
import { useHistory } from "react-router";
import { BASE_ROUTES as CREATE_ACCOUNTS_BASE_ROUTES } from "../../pages/createAccount/CreateAccountStory";

interface Props {
  oldComponent: () => ReactElement;
  newComponent: () => ReactElement;
}

/**
 * We are gradually moving towards FeesInformation as the new fee simulator component,
 * but it is still not compatible with some pages. RenderFeeSimulator picks the compatible one
 * for the current page.
 * @param oldComponent - Render function for pages that should have the old fee simulator component
 * @param newComponent - Render function for pages that should have the new fee simulator component
 */
export const RenderFeeSimulator: FC<Props> = ({
  oldComponent,
  newComponent,
}) => {
  const history = useHistory();
  const isCreateAccountPage = history.location.pathname.includes(
    CREATE_ACCOUNTS_BASE_ROUTES.EDIT_ALLOCATION
  );
  return isCreateAccountPage ? newComponent() : oldComponent();
};
