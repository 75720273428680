import { LysaCountry } from "@lysaab/shared";
import { defineMessages, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { FlagIcon } from "../../flagIcon/FlagIcon";
import "./CountrySelectItem.scss";

interface MessageWithId {
  id: string;
}

const messages = defineMessages<
  LysaCountry,
  MessageWithId,
  Record<LysaCountry, MessageWithId>
>({
  [LysaCountry.SWEDEN]: {
    id: "country-select.sweden",
  },
  [LysaCountry.DENMARK]: {
    id: "country-select.denmark",
  },
  [LysaCountry.FINLAND]: {
    id: "country-select.finland",
  },
  [LysaCountry.GERMANY]: {
    id: "country-select.germany",
  },
  [LysaCountry.SPAIN]: {
    id: "country-select.spain",
  },
});

interface CountryProps {
  country: LysaCountry;
  url: string;
}

export function CountrySelectItem({ country, url }: CountryProps) {
  const intl = useIntl();
  return (
    <Link to={url} className="country-select-item">
      <span className="padding">
        <span className="content">
          <span className="flag">
            <FlagIcon code={country.toLocaleLowerCase()} size="2x" />
          </span>
          {intl.formatMessage(messages[country])}
        </span>
      </span>
    </Link>
  );
}
