import { FunctionComponent } from "react";
import { Redirect } from "react-router";
import { useLocalizationContext } from "../../context/LocalizationContext";
import { getNavLink } from "../../hooks/useCountryUrls";
import { loginPages } from "./loginPages";

interface Props {
  location: {
    search: string;
    pathname: string;
  };
}

export const RedirectLogin: FunctionComponent<Props> = ({ location }) => {
  /**
   * We want to keep all our query params here because they can contain
   * configuration or ga tags that are needed on the login page as well
   */
  const searchParams = new URLSearchParams(location.search);
  searchParams.set("redirect", location.pathname + location.search);

  const localizationContext = useLocalizationContext();
  const country = localizationContext.state.country;

  // TODO: Ignoring a redirect without feedback is inconsisten behaviour.
  // We also catches an empty country in the actual Redirect with pathname.
  // Investigate if this check can be removed? Can we move to useCountry instead?
  if (!country) {
    return null;
  }

  return (
    <Redirect
      to={{
        pathname: country ? getNavLink(loginPages[country]) : "/",
        search:
          location.pathname.length > 4 ? searchParams.toString() : undefined,
      }}
    />
  );
};
