import {
  API,
  InvestmentType,
  encode,
  PendingHintCode,
  FailedHintCode,
  Status,
  OrderRef,
  BankIdStandardResponses,
} from "@lysaab/ui-2";
import { Language } from "../context/LocalizationContext";
import {
  InvestmentAccountQuestions,
  EsgResultResponse,
  isValidAccountQuestions,
} from "./dataInvestments";

export enum KFPreconditionState {
  PENDING_INSURANCE_APPLICATION = "PENDING_INSURANCE_APPLICATION",
  NOT_SUPPORTED_CORPORATION_TYPE = "NOT_SUPPORTED_CORPORATION_TYPE",
}

export enum KFAccountCreationState {
  PENDING_LYSA_SIGN = "PENDING_LYSA_SIGN",
  INSURANCE_APPLICATION_PENDING = "INSURANCE_APPLICATION_PENDING",
  INSURANCE_APPLICATION_PENDING_MANUAL_VALIDATION = "INSURANCE_APPLICATION_PENDING_MANUAL_VALIDATION",
  SIGNING_PENDING = "SIGNING_PENDING",
  ACCOUNT_CREATION_PENDING = "ACCOUNT_CREATION_PENDING",
  ACCOUNT_CREATED = "ACCOUNT_CREATED",
  REJECTED = "REJECTED",
  ERROR = "ERROR",
  EXPIRED = "EXPIRED",
}

enum KFRole {
  INSURED = "INSURED",
  SIGNATORY = "SIGNATORY",
  BENEFICIALOWNER = "BENEFICIALOWNER",
}

export enum KFSignStatus {
  COMPLETED = "COMPLETED",
  WAITING = "WAITING",
}

export interface KFPrecondition {
  failedPrecondition: KFPreconditionState;
  ongoingSigningId: string;
}

export type SuitabilityAssessmentRequest = InvestmentAccountQuestions & {
  language: Language;
};

export function isValidSuitabilityAssessmentRequest(
  data: Partial<SuitabilityAssessmentRequest>
): data is SuitabilityAssessmentRequest {
  if (typeof data.language === "undefined") {
    return false;
  }

  if (!isValidAccountQuestions(data)) {
    return false;
  }

  return true;
}

export interface SuitabilityAssessment extends EsgResultResponse {
  declaration: string;
  savingsEnd: number;
  advisedRisk: number;
  investmentType: InvestmentType;
}

export interface KFDocument {
  documentId: string;
  documentName: string;
  fileName: string;
}

export interface KFPresaleInfo {
  documents: KFDocument[];
}

export interface KFCandidate {
  tin: string;
  name: string;
}

export interface CorporateKyc {
  insurancePurpose?: string;
  insurancePurposeDescription?: string;
  initialPremiumAmount?: string;
  initialPremiumApproximateAmount?: string;
  yearlyPremiumAmount?: string;
  yearlyPremiumApproximateAmount?: string;
  sourceOfFunds: string[];
  sourceOfFundsOtherDescription?: string;
  typeOfBusiness?: string;
  corporateIsPassive?: boolean;
  legalEntityWith25PercentControl?: boolean;
  controllingEntityHasSoleNaturalOwner?: boolean;
  holderIsListedCorporation?: boolean;
  holderIsMajorityOwnedByListedCorporation?: boolean;
}

type KFCreateAccountRequest = InvestmentAccountQuestions & {
  takenRisk?: number;
  corporateKyc: CorporateKyc;
  lysaKyc: {
    version: string;
    questionAnswers: {
      question: string;
      answers: string[];
    }[];
  };
  insured: {
    identificationNumber?: string;
    citizenships: string[];
  };
  accountName: string;
};

export interface KFCreateAccountResponse {
  signingId: string;
}

export interface KFLysaSignResponse {
  orderRef: OrderRef;
  autoStartToken: string;
  hintCode: string;
  status: string;
}

export interface KFSigningStatusResponse {
  accountCreationState: KFAccountCreationState;
}

interface KFSignRequests {
  fullName: string;
  userId: number;
  roles: KFRole[];
  signStatus: KFSignStatus;
}

export interface KFSigningStatusPendingResponse
  extends KFSigningStatusResponse {
  initiatorName: string;
  corporationName: string;
  tin: string;
  takenRisk: number;
  showShareByEmail: boolean;
  signRequests: KFSignRequests[];
  latestSigningDate: string;
  investmentType: InvestmentType;
}

export interface KFSigningStatusRejectResponse extends KFSigningStatusResponse {
  insuranceApplicationId: string;
}

export interface KFFuturSignResponse {
  transactionId: string;
  autostartToken: string;
}

export enum KFExtendedStatus {
  ERROR = "error",
}

export interface KFExtendedBankidResponse {
  hintCode: PendingHintCode | FailedHintCode;
  status: Status | KFExtendedStatus;
}

export const dataCreateAccount = {
  getKFPreconditions: () => {
    return API.get<KFPrecondition[]>(
      "/danica/accounts/check-preconditions",
      true
    );
  },

  getKFPresaleInfo: () => {
    return API.get<KFPresaleInfo>("/danica/accounts/presale-info");
  },

  getKFSuitabilityAssessment: (data: SuitabilityAssessmentRequest) => {
    return API.post<SuitabilityAssessment>(
      "/danica/accounts/advice/calculate",
      data
    );
  },

  getKFCandidates: () => {
    return API.get<KFCandidate[]>("/danica/accounts/insurance-candidates");
  },

  createKFAccount: (data: KFCreateAccountRequest) => {
    return API.post<KFCreateAccountResponse>(
      "/danica/accounts/start-account-creation",
      data
    );
  },

  shareKFByEmail: (email: string, signupId: string) => {
    return API.post(`/danica/accounts/signing/${signupId}/share-by-email`, {
      email,
    });
  },

  getKFSigningStatus: (signupId: string) => {
    return API.get<
      | KFSigningStatusResponse
      | KFSigningStatusPendingResponse
      | KFSigningStatusRejectResponse
    >(`/danica/accounts/signing/${signupId}`, true);
  },

  kfLysaSign: (signingId: string) => {
    return API.post<KFLysaSignResponse>(
      encode`/bankid/danica?signingId=${signingId}`
    );
  },

  kfLysaSignPoll: (signingId: string, orderRef: OrderRef) => {
    return API.get<BankIdStandardResponses>(
      encode`/bankid/danica/${orderRef}?signingId=${signingId}`,
      true
    );
  },

  kfFuturSign: (signingId: string, userId: string) => {
    return API.post<KFFuturSignResponse>(
      `/danica/accounts/signing/${signingId}/start/${userId}`
    );
  },

  kfFuturSignPoll: (signingId: string, transactionId: string) => {
    return API.get<KFExtendedBankidResponse>(
      `/danica/accounts/signing/${signingId}/poll/${transactionId}`,
      true
    );
  },
};
