import { useCallback, useContext, useMemo, VoidFunctionComponent } from "react";
import {
  BankIDStatus,
  SwedishTin,
  PendingHintCode,
  FailedHintCode,
  OrderRef,
  useBankId,
  useDisablePollInBackground,
} from "@lysaab/ui-2";
import { Location } from "history";
import { useHistory, useLocation } from "react-router";
import { BASE_ROUTES, UserManagementBasePaths } from "../UserManagementStory";
import { UserManagementContext } from "../UserManagementContext";
import { IntlShape, useIntl } from "react-intl";
import { messages, signFailedMessages, signPendingMessages } from "../Messages";
import { dataCorporate } from "../../../../../../data/dataCorporate";
import { parse, stringify } from "query-string";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";

type SearchParams = {
  orderRef?: OrderRef;
  at?: string;
};

const upsertCompanyUser = (
  identificationNumber: string,
  email: string,
  admin: boolean
) => {
  const normalizedTin = new SwedishTin(identificationNumber).getNormalizedTin();
  return dataCorporate.upsertCompanyUser(normalizedTin, email, admin);
};

const createUseBankIdHook =
  (initFn: typeof upsertCompanyUser | typeof dataCorporate.deleteCompanyUser) =>
  (onComplete: () => void, signPath: string) => {
    const history = useHistory();
    const location = useLocation<SearchParams>();
    const urlParams = getParamsFromUrl(location);
    const { orderRef, autoStartToken } = urlParams;

    const qrCodePollFn = useCallback(
      () => dataCorporate.pollQrCode(orderRef),
      [orderRef]
    );

    const pollFnRaw = useMemo(() => {
      return orderRef
        ? () => dataCorporate.upsertCompanyUserPoll(orderRef)
        : undefined;
    }, [orderRef]);
    const pollFn = useDisablePollInBackground(pollFnRaw);

    const { initiate, pollStatus, ...rest } = useBankId({
      onComplete,
      initPollFn: initFn,
      pollFn,
      qrCodePollFn,
    });

    const startSign = useCallback(
      (...args: Parameters<typeof initFn>) => {
        if (pollStatus === "IDLE" || pollStatus === "FAILED") {
          initiate(...args)
            .then((response) => {
              if (response) {
                history.push(
                  getUrlWithParams(
                    location,
                    response.orderRef,
                    response.autoStartToken,
                    signPath
                  )
                );
              }
            })
            .catch(() => {
              // If we get an error while initiating, we still want to transition
              // to the next step so BankIDStatus can show the error message.
              history.push(signPath);
            });
        }
      },
      [history, initiate, location, pollStatus, signPath]
    );

    return {
      startSign,
      pollStatus,
      autoStartToken,
      ...rest,
    };
  };

export const useAddBankIdSign = createUseBankIdHook(upsertCompanyUser);
export const useUpdateBankIdSign = createUseBankIdHook(upsertCompanyUser);
export const useDeleteBankIdSign = createUseBankIdHook(
  dataCorporate.deleteCompanyUser
);

type Props = {
  action: UserManagementBasePaths;
} & (
  | ReturnType<typeof useAddBankIdSign>
  | ReturnType<typeof useUpdateBankIdSign>
  | ReturnType<typeof useDeleteBankIdSign>
);

export const UserManagementSign: VoidFunctionComponent<Props> = ({
  action,
  autoStartToken,
  latestResponse,
  startSign,
  setOpenOnOtherDevice,
  qrCode,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const userManagementContext = useContext(UserManagementContext);
  const updateUser = userManagementContext.state.updatedUser;

  return (
    <BankIDStatus
      qrCode={qrCode}
      setOpenOnOtherDevice={setOpenOnOtherDevice}
      getMessages={getMessages(intl)}
      getPendingMessages={getPendingMessages(intl)}
      getFailedMessages={getFailedMessages(intl)}
      retry={() => {
        if (!updateUser) {
          // If we no longer have the user on the context because of a refresh or
          // because BankID redirected back to a new tab/browser, there is no way
          // to retry, so this is the best we can do
          history.replace(getNavLink(BASE_ROUTES.HOME));
          return;
        }

        if (
          action === UserManagementBasePaths.ADD ||
          action === UserManagementBasePaths.UPDATE
        ) {
          const normalizedTin = new SwedishTin(
            updateUser.identificationNumber
          ).getNormalizedTin();
          startSign(normalizedTin, updateUser.email, updateUser.admin);
          return;
        } else if (action === UserManagementBasePaths.DELETE) {
          startSign(updateUser.identificationNumber);
          return;
        }
      }}
      response={latestResponse}
      autoStartToken={autoStartToken}
    />
  );
};

function getMessages(intl: IntlShape) {
  return () => {
    return {
      qrInfo1: intl.formatMessage(messages.signQrInfo1),
      qrInfo2: intl.formatMessage(messages.signQrInfo2),
      qrInfo3: intl.formatMessage(messages.signQrInfo3),
      buttonOpen: intl.formatMessage(messages.signButtonOpen),
      buttonErrorHeader: intl.formatMessage(messages.signButtonErrorHeader),
      buttonRetry: intl.formatMessage(messages.signButtonRetry),
      buttonClose: intl.formatMessage(messages.signButtonClose),
      buttonOtherDevice: intl.formatMessage(messages.signButtonOtherDevice),
    };
  };
}
function getPendingMessages(intl: IntlShape) {
  return (hintCode: PendingHintCode) =>
    intl.formatMessage(signPendingMessages[hintCode]);
}

function getFailedMessages(intl: IntlShape) {
  return (hintCode: FailedHintCode) =>
    intl.formatMessage(signFailedMessages[hintCode]);
}

function getUrlWithParams(
  location: Location,
  orderRef: OrderRef | undefined,
  autoStartToken: string,
  pathname?: string
) {
  const search = parse(location.search) as SearchParams;
  search.orderRef = orderRef;
  search.at = autoStartToken;

  return {
    pathname: pathname ?? location.pathname,
    search: stringify(search as Record<string, any>, { skipEmptyString: true }),
  };
}

function getParamsFromUrl(location: Location) {
  const search = parse(location.search) as SearchParams;
  return {
    orderRef: search.orderRef,
    autoStartToken: search.at,
  };
}
