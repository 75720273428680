import { useContext, useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { LysaTable } from "../../../../components/lysaTable/LysaTable";
import { LysaTableTextCell } from "../../../../components/lysaTableTextCell/LysaTableTextCell";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import { RedemptionPart } from "../../../../data/dataTax";

const messages = defineMessages({
  isin: {
    id: "finland.tax.redemptions.isin",
  },
  redemptionAmount: {
    id: "finland.tax.redemptions.redemptionAmount",
  },
  redemptionDate: {
    id: "finland.tax.redemptions.redemptionDate",
  },
  redemptionPrice: {
    id: "finland.tax.redemptions.redemptionPrice",
  },
  redemptionVolume: {
    id: "finland.tax.redemptions.redemptionVolume",
  },
  subscriptionAmount: {
    id: "finland.tax.redemptions.subscriptionAmount",
  },
  subscriptionDate: {
    id: "finland.tax.redemptions.subscriptionDate",
  },
  subscriptionPrice: {
    id: "finland.tax.redemptions.subscriptionPrice",
  },
  noEntries: {
    id: "finland.tax.redemptions.noEntries",
  },
});

interface Props {
  redemptionParts?: RedemptionPart[];
}

export const RedemptionsTable: React.VFC<Props> = ({ redemptionParts }) => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);

  const sortedRedemptionParts = useMemo(() => {
    return redemptionParts?.sort(
      (a, b) =>
        new Date(a.redemptionDate).getTime() -
        new Date(b.redemptionDate).getTime()
    );
  }, [redemptionParts]);

  if (typeof sortedRedemptionParts === "undefined") {
    return null;
  }

  return (
    <LysaTable>
      <thead>
        <tr>
          <LysaTableTextCell
            className="text-left"
            value={intl.formatMessage(messages.isin)}
            header
          />
          <LysaTableTextCell
            className="text-left"
            value={intl.formatMessage(messages.subscriptionDate)}
            header
          />
          <LysaTableTextCell
            className="text-right"
            value={intl.formatMessage(messages.subscriptionPrice)}
            header
          />
          <LysaTableTextCell
            className="text-right"
            value={intl.formatMessage(messages.subscriptionAmount)}
            header
          />
          <LysaTableTextCell
            className="text-right"
            value={intl.formatMessage(messages.redemptionDate)}
            header
          />
          <LysaTableTextCell
            className="text-right"
            value={intl.formatMessage(messages.redemptionVolume)}
            header
          />
          <LysaTableTextCell
            className="text-right"
            value={intl.formatMessage(messages.redemptionPrice)}
            header
          />
          <LysaTableTextCell
            className="text-right"
            value={intl.formatMessage(messages.redemptionAmount)}
            header
          />
        </tr>
      </thead>
      <tbody>
        {sortedRedemptionParts.map((redemption) => (
          <tr>
            <LysaTableTextCell
              className="text-left"
              label={intl.formatMessage(messages.isin)}
              value={redemption.isin}
            />
            <LysaTableTextCell
              className="text-left"
              label={intl.formatMessage(messages.subscriptionDate)}
              value={intl.formatDate(redemption.subscriptionDate)}
            />
            <LysaTableTextCell
              className="text-right"
              label={intl.formatMessage(messages.subscriptionPrice)}
              value={intl.formatNumber(redemption.subscriptionPrice, {
                currency: localizationContext.state.currency,
                style: "currency",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            />
            <LysaTableTextCell
              className="text-right"
              label={intl.formatMessage(messages.subscriptionAmount)}
              value={intl.formatNumber(redemption.subscriptionAmount, {
                currency: localizationContext.state.currency,
                style: "currency",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            />
            <LysaTableTextCell
              className="text-right"
              label={intl.formatMessage(messages.redemptionDate)}
              value={intl.formatDate(redemption.redemptionDate)}
            />
            <LysaTableTextCell
              className="text-right"
              label={intl.formatMessage(messages.redemptionVolume)}
              value={redemption.redemptionVolume.toString()}
            />
            <LysaTableTextCell
              className="text-right"
              label={intl.formatMessage(messages.redemptionPrice)}
              value={intl.formatNumber(redemption.redemptionPrice, {
                currency: localizationContext.state.currency,
                style: "currency",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            />
            <LysaTableTextCell
              className="text-right"
              label={intl.formatMessage(messages.redemptionAmount)}
              value={intl.formatNumber(redemption.redemptionAmount, {
                currency: localizationContext.state.currency,
                style: "currency",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            />
          </tr>
        ))}

        {sortedRedemptionParts.length === 0 && (
          <tr>
            <td colSpan={8} className="text-center">
              {intl.formatMessage(messages.noEntries)}
            </td>
          </tr>
        )}
      </tbody>
    </LysaTable>
  );
};
