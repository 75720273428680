import {
  MutableRefObject,
  useCallback,
  useContext,
  useRef,
  useState,
  VoidFunctionComponent,
} from "react";
import {
  BankAccountInput,
  BankAccountNumberValidator,
  Card,
  Checkbox,
  DocModalLink,
  FlashContext,
  Form,
  LysaFormRef,
  Button,
  RequiredValidator,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { FormattedMessage, useIntl } from "react-intl";
import { dataAutogiro } from "../../../../../../data/dataAutogiro";
import { addAccountMessages } from "../Messages";
import "./AddAccount.scss";

interface Props {
  reloadRef: MutableRefObject<{ reloadConsents?: () => void }>;
}

export const AddAccount: VoidFunctionComponent<Props> = ({ reloadRef }) => {
  const intl = useIntl();
  const flashContext = useContext(FlashContext);
  const formRef = useRef<LysaFormRef>();

  const [loading, setLoading] = useState(false);
  const [bankAccount, setBankAccount] = useState("");
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

  const onSubmit = useCallback(() => {
    if (!formRef.current?.isValid) {
      return;
    }

    setLoading(true);
    dataAutogiro
      .addAutogiro(bankAccount)
      .then(() => {
        setBankAccount("");
        setHasAcceptedTerms(false);
        setLoading(false);
        flashContext.pushFlash({
          text: intl.formatMessage(addAccountMessages.snackbarSuccess),
          type: SNACKBAR_TYPES.SUCCESS,
        });
        reloadRef.current.reloadConsents?.();
      })
      .catch(() => {
        setLoading(false);
        flashContext.pushFlash({
          text: intl.formatMessage(addAccountMessages.snackbarError),
          type: SNACKBAR_TYPES.ERROR,
        });
      });
  }, [bankAccount, flashContext, intl, reloadRef]);

  if (loading) {
    return (
      <div className="company-settings--add-account">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="company-settings--add-account">
      <h2>
        <FormattedMessage id="sweden.companySettings.addAccount.title" />
      </h2>
      <Card>
        <Form lysaFormRef={formRef} onSubmit={onSubmit}>
          <p>
            <FormattedMessage id="sweden.companySettings.addAccount.text" />
          </p>
          <BankAccountInput
            label={intl.formatMessage(addAccountMessages.accountInputLabel)}
            value={bankAccount}
            onChange={setBankAccount}
            validators={[
              new BankAccountNumberValidator(
                intl.formatMessage(addAccountMessages.accountInputErrorNumber)
              ),
              new RequiredValidator(
                intl.formatMessage(addAccountMessages.accountInputErrorRequired)
              ),
            ]}
          />
          <div className="terms-link">
            <DocModalLink
              document="legal/se/sv/villkor-för-insättning-och-betalning-via-autogiro.md"
              modalAnnouncement={intl.formatMessage(
                addAccountMessages.termsAnnouncement
              )}
            >
              <FormattedMessage id="sweden.companySettings.addAccount.terms.title" />
            </DocModalLink>
          </div>
          <Checkbox
            checked={hasAcceptedTerms}
            onChange={() => setHasAcceptedTerms(!hasAcceptedTerms)}
            alternative={{
              text: intl.formatMessage(addAccountMessages.termsTitle),
              value: true,
            }}
            validators={[
              new RequiredValidator(
                intl.formatMessage(addAccountMessages.termsErrorRequired)
              ),
            ]}
          />
          <Button
            block
            type="submit"
            label={
              <FormattedMessage id="sweden.companySettings.addAccount.submit" />
            }
          />
        </Form>
      </Card>
    </div>
  );
};
