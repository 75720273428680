import * as React from "react";
import cx from "classnames";
import { Button, CardList, RiskIndicator, Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../components/TranslatedText";
import {
  InvestmentAccount,
  AccountType,
  InvestmentType,
} from "../../data/dataAccounts";
import {
  getEligibility,
  EligibilityResponse,
  InvestmentAdviseAccount,
} from "../../data/dataInvestments";
import "./AccountFocus.scss";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { SuitabilityDownload } from "../../pageComponents/advise/SuitabilityDownload";
import { CardBottom } from "./CardBottom";
import { savingsHorizonLengthMessages } from "../../pageComponents/horizon/questions/SavingsHorizonLengthInput";
import { HideIfReadOnly } from "../../components/hideIfReadOnly/HideIfReadOnly";
import { Link, generatePath } from "react-router-dom";
import {
  ACCOUNT_SITUATION_EDIT_PAGE,
  AccountSituationInstanceLocationState,
} from "../accountSituation/AccountSituationStory";
import { getNavLink } from "../../hooks/useCountryUrls";

const investmentTypeMessages = defineMessages<InvestmentType>({
  [InvestmentType.BROAD]: {
    id: "account.account-focus.type.BROAD",
  },
  [InvestmentType.SUSTAINABLE]: {
    id: "account.account-focus.type.SUSTAINABLE",
  },
});

interface Props {
  account?: InvestmentAccount;
  adviseAccount?: InvestmentAdviseAccount;
  eligibility?: EligibilityResponse;
}

export const AccountFocus: React.FC<Props> = ({
  adviseAccount,
  account,
  eligibility,
}) => {
  const intl = useIntl();

  if (!adviseAccount || !account) {
    return <div className="account-account-focus" />;
  }

  return (
    <section
      className={cx("account-account-focus", {
        "is-insurance-account": account.type === AccountType.DANICA_KF,
      })}
    >
      <div className="account-account-focus--header">
        <Typography type="h2">
          <TranslatedText id="account.account-focus.title" />
        </Typography>

        {!!eligibility && (
          <div>
            <SuitabilityDownload
              esgQuestions={adviseAccount.esgResult}
              accountQuestions={adviseAccount}
              advisedRisk={adviseAccount.advisedRisk}
              eligibility={getEligibility(eligibility)}
              accountType={account.type}
              render={(url, filename) => (
                <Button
                  component="a"
                  href={url}
                  download={filename}
                  variant="secondary"
                  size="small"
                  label={
                    <FormattedMessage id="account.account-focus.suitabilityPdfDownload.download" />
                  }
                  icon="Deposit"
                />
              )}
            />
          </div>
        )}
      </div>
      <CardList>
        <div className="account-page-padder">
          <div className="account-page-card-body">
            <dl>
              <dt>
                {intl.formatMessage({
                  id: "account.account-focus.horizon",
                })}
              </dt>
              <dd data-test-id="account-savings-horizon">
                {intl.formatMessage(
                  savingsHorizonLengthMessages[adviseAccount.savingsHorizon]
                )}
              </dd>
            </dl>
            <dl>
              <dt>
                {intl.formatMessage({ id: "account.account-focus.type" })}
              </dt>
              <dd data-test-id="account-investment-type">
                {intl.formatMessage(
                  investmentTypeMessages[adviseAccount.investmentType]
                )}
                <RiskIndicator
                  size={20}
                  risk={adviseAccount.takenRisk}
                  investmentType={adviseAccount.investmentType}
                />
              </dd>
            </dl>

            <div>
              <p data-test-id="account-advised-declaration">
                {adviseAccount?.declaration ?? ""}
              </p>
            </div>

            {(account.type === AccountType.DANICA_KF ||
              account.type === AccountType.KF_SWE) && (
              <div className="kf-info">
                <p>
                  <TranslatedText id="account.account-focus.declaration.insurance.paragraph" />
                </p>
              </div>
            )}
          </div>

          <HideIfReadOnly>
            {!account.isSharedAccount && (
              <CardBottom>
                <Link<AccountSituationInstanceLocationState>
                  to={{
                    pathname: generatePath(
                      getNavLink(ACCOUNT_SITUATION_EDIT_PAGE),
                      {
                        accountId: account.accountId,
                      }
                    ),
                    state: { returnUrl: window.location.pathname },
                  }}
                >
                  <TranslatedText id="account.account-focus.cta.update-focus" />
                </Link>
              </CardBottom>
            )}
          </HideIfReadOnly>
        </div>
      </CardList>
    </section>
  );
};
