import { useEffect, useState } from "react";
import { CoporateUser, dataCorporate } from "../data/dataCorporate";
import { useUser } from "../context/UserContext";
import { LegalEntityType } from "../data/dataLogin";

export function useUsers() {
  const user = useUser();
  const [users, setUsers] = useState<CoporateUser[]>();

  useEffect(() => {
    setUsers(undefined);

    if (user.legalEntityType !== LegalEntityType.CORPORATION) {
      return;
    }

    dataCorporate
      .getCompanyData()
      .then((companyData) => setUsers(companyData.users));
  }, [user.legalEntityType]);

  return users;
}
