import { FunctionComponent } from "react";

import { useIntl } from "react-intl";
import { LysaTable } from "../../../../components/lysaTable/LysaTable";
import { LysaTableTextCell } from "../../../../components/lysaTableTextCell/LysaTableTextCell";
import { FeeHeaderCell } from "../components/feeHeaderCell/FeeHeaderCell";
import { FeeBodyCell } from "../components/feeBodyCell/FeeBodyCell";
import { CostHeaderCell } from "../components/costHeaderCell/CostHeaderCell";
import { CostBodyCell } from "../components/costBodyCell/CostBodyCell";
import { HistoricFeeSavingsAccountResponse } from "../../../../data/dataFees";
import { useAccounts } from "../../../../hooks/useAccounts";

interface Props {
  accountsData: HistoricFeeSavingsAccountResponse[];
}

export const ActiveSavingsAccountTable: FunctionComponent<Props> = ({
  accountsData,
}) => {
  const intl = useIntl();
  const { accounts } = useAccounts();

  return (
    <LysaTable>
      <thead>
        <tr>
          <LysaTableTextCell
            value={intl.formatMessage({
              id: "fees.feeTables.savingsAccountTable.activeAccount.accountHeader",
            })}
            header={true}
          />
          <FeeHeaderCell
            text={intl.formatMessage({
              id: "fees.feeTables.savingsAccountTable.activeAccount.rebatedFeeHeader",
            })}
          />
          <FeeHeaderCell
            text={intl.formatMessage({
              id: "fees.feeTables.savingsAccountTable.activeAccount.feeHeader",
            })}
          />
          <CostHeaderCell
            text={intl.formatMessage({
              id: "fees.feeTables.savingsAccountTable.activeAccount.avgCostHeader",
            })}
          />
        </tr>
      </thead>
      <tbody>
        {accountsData &&
          accountsData.map((account) => {
            const accountWithName = accounts?.savingsAccounts.find(
              (savingsAccount) => savingsAccount.accountId === account.accountId
            );

            return (
              <tr key={account.accountId}>
                <LysaTableTextCell
                  value={
                    accountWithName ? accountWithName?.name : account.accountId
                  }
                  label={intl.formatMessage({
                    id: "fees.feeTables.savingsAccountTable.activeAccount.accountHeader",
                  })}
                />
                <FeeBodyCell
                  value={account.rebatedFee}
                  label={intl.formatMessage({
                    id: "fees.feeTables.savingsAccountTable.activeAccount.rebatedFeeHeader",
                  })}
                />
                <FeeBodyCell
                  value={account.fee}
                  label={intl.formatMessage({
                    id: "fees.feeTables.savingsAccountTable.activeAccount.feeHeader",
                  })}
                />
                <CostBodyCell
                  value={account.avgCost}
                  label={intl.formatMessage({
                    id: "fees.feeTables.savingsAccountTable.activeAccount.avgCostHeader",
                  })}
                />
              </tr>
            );
          })}
      </tbody>
    </LysaTable>
  );
};
