import * as React from "react";
import { AllocationBarIntl, CardList, Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../components/TranslatedText";
import { InvestmentAccount } from "../../data/dataAccounts";
import {
  CombinedAdviseAccount,
  isInvestmentAdviseAccount,
} from "../../data/dataInvestments";
import { useIntl } from "react-intl";
import "./AccountAllocation.scss";
import { CardBottom } from "./CardBottom";
import { HideIfReadOnly } from "../../components/hideIfReadOnly/HideIfReadOnly";
import { EditAccounrRiskParams } from "../../pageComponents/editAccountRiskConfirm/EditAccountRiskConfirm";
import { UPDATE_ACCOUNT_PAGE_URL } from "../updateAccount/UpdateAccountPage";
import { getNavLink } from "../../hooks/useCountryUrls";
import { stringify } from "query-string";
import { useUser } from "../../context/UserContext";
import { useLocalizationContext } from "../../context/LocalizationContext";
import { Link } from "react-router-dom";
import { MESSAGES_PAGE_URL } from "../messages/MessagesPage";

interface Props {
  account?: InvestmentAccount;
  adviseAccount?: CombinedAdviseAccount;
}

export const AccountAllocation: React.VFC<Props> = ({
  adviseAccount,
  account,
}) => {
  const intl = useIntl();
  const user = useUser();
  const language = useLocalizationContext().state.language;

  if (!adviseAccount || !account) {
    return <div className="account-allocation" />;
  }

  return (
    <section className="account-account-allocation">
      <Typography type="h2">
        <TranslatedText id="account.account-allocation.title" />
      </Typography>
      <CardList>
        <div className="account-page-padder">
          <div className="account-page-card-body">
            <div>
              <strong>
                {intl.formatMessage({
                  id: "account.account-allocation.advice.label",
                })}
              </strong>
              <AllocationBarIntl
                messages={{
                  stocks: intl.formatMessage(
                    { id: "account.account-allocation.advice.stocks" },
                    {
                      stocks: intl.formatNumber(
                        adviseAccount.advisedRisk / 100,
                        {
                          style: "percent",
                        }
                      ),
                    }
                  ),
                  bonds: intl.formatMessage(
                    { id: "account.account-allocation.advice.bonds" },
                    {
                      bonds: intl.formatNumber(
                        (100 - adviseAccount.advisedRisk) / 100,
                        {
                          style: "percent",
                        }
                      ),
                    }
                  ),
                }}
                risk={adviseAccount.advisedRisk || 0}
                data-test-id="advised-risk"
              />
            </div>

            <div>
              <strong>
                {intl.formatMessage({
                  id: "account.account-allocation.allocation.label",
                })}
              </strong>
              <AllocationBarIntl
                messages={{
                  stocks: intl.formatMessage(
                    { id: "account.account-allocation.allocation.stocks" },
                    {
                      stocks: intl.formatNumber(adviseAccount.takenRisk / 100, {
                        style: "percent",
                      }),
                    }
                  ),
                  bonds: intl.formatMessage(
                    { id: "account.account-allocation.allocation.bonds" },
                    {
                      bonds: intl.formatNumber(
                        (100 - adviseAccount.takenRisk) / 100,
                        { style: "percent" }
                      ),
                    }
                  ),
                }}
                risk={adviseAccount.takenRisk || 0}
                data-test-id="taken-risk"
              />
            </div>

            {typeof account.actualRisk !== "undefined" && (
              <div>
                <strong>
                  {intl.formatMessage({
                    id: "account.account-allocation.exact-allocation.label",
                  })}
                </strong>
                <AllocationBarIntl
                  messages={{
                    stocks: intl.formatMessage(
                      {
                        id: "account.account-allocation.exact-allocation.stocks",
                      },
                      {
                        stocks: intl.formatNumber(account.actualRisk / 100, {
                          style: "percent",
                          maximumFractionDigits: 2,
                        }),
                      }
                    ),
                    bonds: intl.formatMessage(
                      {
                        id: "account.account-allocation.exact-allocation.bonds",
                      },
                      {
                        bonds: intl.formatNumber(
                          (100 - account.actualRisk) / 100,
                          {
                            style: "percent",
                            maximumFractionDigits: 2,
                          }
                        ),
                      }
                    ),
                  }}
                  risk={account.actualRisk}
                />
              </div>
            )}
          </div>
          {isInvestmentAdviseAccount(adviseAccount) ? (
            <HideIfReadOnly>
              {!account.isSharedAccount && (
                <CardBottom>
                  <a
                    href={(() => {
                      const params: EditAccounrRiskParams = {
                        accountId: account.accountId,
                        advisedRisk: adviseAccount.advisedRisk.toString(),
                        takenRisk: adviseAccount.takenRisk.toString(),
                        language,
                        investmentType: adviseAccount.investmentType,
                        accountName: account.name,
                        name: user.name || "",
                      };

                      return `${getNavLink(
                        UPDATE_ACCOUNT_PAGE_URL
                      )}?${stringify(params as Record<string, any>)}`;
                    })()}
                  >
                    <TranslatedText id="account.account-allocation.cta.update" />
                  </a>
                </CardBottom>
              )}
            </HideIfReadOnly>
          ) : (
            <CardBottom>
              <Link to={getNavLink(MESSAGES_PAGE_URL)}>
                <TranslatedText id="account.account-allocation.cta.update.pension"></TranslatedText>
              </Link>
            </CardBottom>
          )}
        </div>
      </CardList>
    </section>
  );
};
