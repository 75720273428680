import { LysaCountry } from "@lysaab/shared";
import { DocModal } from "@lysaab/ui-2";
import { useContext, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { LocalizationContext } from "../../context/LocalizationContext";
import { UserContext } from "../../context/UserContext";
import { CompoundAccountId } from "../../data/dataAccounts";
import { TranslatedText } from "../TranslatedText";

interface DocumentVariables {
  accountId: CompoundAccountId;
  accountName: string;
  inviteeName: string;
}

interface Props {
  documentVariables: DocumentVariables;
}

const messages = defineMessages({
  header: {
    id: "shareAccountInviteCard.docs.header",
  },
  placeholder: {
    id: "shareAccountInviteCard.docs.placeholder",
  },
  modalAnnouncement: {
    id: "shareAccountInviteCard.docs.modalAnnouncement",
  },
});

export const documentList: Record<LysaCountry, string[]> = {
  [LysaCountry.DENMARK]: [
    "legal/all/en/shared-account.md",
    "legal/all/en/privacy-note.md",
  ],
  [LysaCountry.FINLAND]: [
    "legal/all/en/shared-account.md",
    "legal/all/en/privacy-note.md",
  ],
  [LysaCountry.GERMANY]: [
    "legal/all/en/shared-account.md",
    "legal/all/en/privacy-note.md",
  ],
  [LysaCountry.SPAIN]: [],
  [LysaCountry.SWEDEN]: [
    "legal/se/sv/shared-account.md",
    "legal/se/sv/personuppgiftspolicy.md",
  ],
};

export const ShareAccountInviteCardDocs: React.FC<Props> = ({
  documentVariables,
}) => {
  const [document, setDocument] = useState<string>();
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const {
    state: { name },
  } = useContext(UserContext);
  const { country } = localizationContext.state;

  if (typeof country === "undefined" || typeof name === "undefined") {
    return null;
  }

  const variables = {
    SIGNING_DATE: intl.formatDate(new Date()),
    ACCOUNT_NAME: documentVariables.accountName,
    ACCOUNT_ID: documentVariables.accountId || "",
    OWNER_NAME: name,
    INVITEE_NAME: documentVariables.inviteeName,
  };

  return (
    <>
      <DocModal
        header={intl.formatMessage(messages.header)}
        active={!!document}
        onRequestClose={() => setDocument(undefined)}
        variableValues={variables}
        docName={document}
        selectPlaceholder={intl.formatMessage(messages.placeholder)}
        docList={documentList[country]}
        modalAnnouncement={intl.formatMessage(messages.modalAnnouncement)}
      />
      <button
        className="as-link"
        type="button"
        onClick={() => {
          setDocument(documentList[country][0]);
        }}
      >
        <FormattedMessage id="shareAccountInviteCard.docs.documentLink" />
      </button>
      <p>
        <ul>
          <li>
            <TranslatedText
              id="shareAccountInviteCard.docs.documentDisclaimer"
              values={{
                policy: (parts) => (
                  <button
                    className="as-link"
                    type="button"
                    onClick={() => {
                      setDocument(documentList[country][1]);
                    }}
                  >
                    {parts}
                  </button>
                ),
              }}
            />
          </li>
        </ul>
      </p>
    </>
  );
};
