import { useCallback, useContext, useEffect, useRef, useState } from "react";
import cx from "classnames";
import { Datepicker } from "@lysaab/ui-2/components/datepicker/Datepicker";
import {
  EARLIEST_START_DATE,
  PerformanceContext,
} from "../../context/PerformanceContext";
import { Interval, dataPerformance } from "../../data/dataPerformance";
import { defineMessages, useIntl } from "react-intl";
import "./IntervalCustomDates.scss";

const messages = defineMessages({
  from: {
    id: "performance.graph.interval.from",
    description: "Interval custom dates - from",
    defaultMessage: "From",
  },
  to: {
    id: "performance.graph.interval.to",
    description: "Interval custom dates - to",
    defaultMessage: "To",
  },
});

export function IntervalCustomDates() {
  const datesElem = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);
  const [active, setActive] = useState<boolean>(false);
  const intl = useIntl();
  const { setState, interval, customStartDate, customEndDate } =
    useContext(PerformanceContext);

  const onStartDateChange = useCallback(
    (customStartDate: Date) => {
      let date = null;
      if (
        customEndDate &&
        dataPerformance.isValidDate(customEndDate) &&
        dataPerformance.isValidDate(customStartDate)
      ) {
        date =
          customStartDate > customEndDate ? customEndDate : customStartDate;
      } else if (dataPerformance.isValidDate(customStartDate)) {
        const currentDate = new Date();
        date = customStartDate > currentDate ? currentDate : customStartDate;
      }
      setState({
        customStartDate: date,
      });
    },
    [setState, customEndDate]
  );

  const onEndDateChange = useCallback(
    (customEndDate: Date) => {
      let date = null;
      let startDate = customStartDate;
      if (dataPerformance.isValidDate(customEndDate)) {
        const currentDate = new Date();
        date = customEndDate > currentDate ? currentDate : customEndDate;
        if (customStartDate && date < customStartDate) {
          startDate = date;
        }
      }

      setState({
        customEndDate: date,
        customStartDate: startDate,
      });
    },
    [setState, customStartDate]
  );

  useEffect(() => {
    if (!datesElem.current) {
      return;
    }
    const elem = datesElem.current;
    if (interval === Interval.CUSTOM) {
      setHeight(60);
    } else {
      setHeight(0);
      setActive(false);
    }
    const onTransitionEnd = (ev: TransitionEvent) => {
      if (ev.propertyName !== "height") {
        return;
      }
      if (height !== 0) {
        setActive(true);
      }
    };

    elem.addEventListener("transitionend", onTransitionEnd);

    return () => {
      elem.removeEventListener("transitionend", onTransitionEnd);
    };
  }, [interval, height]);

  const style = {
    height,
  };

  return (
    <div
      className={cx("interval-custom-dates", {
        "is-active": active,
      })}
      ref={datesElem}
      style={style}
    >
      <div>
        <Datepicker
          label={intl.formatMessage(messages.from)}
          onChange={onStartDateChange}
          selected={customStartDate}
          showPopperArrow={false}
          popperPlacement="bottom-end"
          minDate={new Date(EARLIEST_START_DATE)}
          maxDate={
            dataPerformance.isValidDate(customEndDate)
              ? customEndDate
              : new Date()
          }
        />
        <Datepicker
          label={intl.formatMessage(messages.to)}
          onChange={onEndDateChange}
          selected={customEndDate}
          showPopperArrow={false}
          popperPlacement="bottom-start"
          minDate={new Date(EARLIEST_START_DATE)}
          maxDate={new Date()}
        />
      </div>
    </div>
  );
}
