import { CardListItem } from "@lysaab/ui-2";
import * as React from "react";
import "./CardListActionItem.scss";

interface Props {
  label?: string | JSX.Element;
  text?: string;
  action?: JSX.Element;
}

export const CardListActionItem: React.FC<React.PropsWithChildren<Props>> = ({
  label,
  action = true,
  text,
  children,
}) => {
  return (
    <CardListItem>
      <div className="card-list-action-item">
        <div className="card-list-action-item__content">
          {typeof label === "string" ? (
            <span className="card-list-action-item__content__label">
              {label}
            </span>
          ) : (
            label
          )}
          <span className="card-list-action-item__content__text">
            {children || text}
          </span>
        </div>
        {action && typeof text !== "undefined" && (
          <div className="card-list-action-item__action">{action}</div>
        )}
      </div>
    </CardListItem>
  );
};
