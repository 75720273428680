import { DateTime } from "luxon";
import { useCallback, useEffect, useRef } from "react";

const oneDayInMillis = 1000 * 60 * 60 * 24;

export function useRefreshPageReturningUsers() {
  const lastVisitRef = useRef(DateTime.now());

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === "visible") {
      const elapsedTime = DateTime.now().diff(
        lastVisitRef.current
      ).milliseconds;
      if (elapsedTime > oneDayInMillis) {
        window.location.reload();
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleVisibilityChange]);
}
