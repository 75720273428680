import { useCallback, useEffect, useState } from "react";
import {
  dataAccounts,
  InvestmentAccountId,
  InvestmentAccount,
} from "../data/dataAccounts";

export function useSharedInvestmentAccount(accountId?: InvestmentAccountId) {
  const [account, setAccount] = useState<InvestmentAccount>();
  const [error, setError] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);

  const getAccount = useCallback(() => {
    dataAccounts
      .getAllAccounts()
      .then((data) => {
        setError(false);
        const account = data.sharedInvestmentAccounts.find(
          (account) => account.accountId === accountId
        );
        if (typeof account !== "undefined") {
          setNotFound(false);
          setAccount(account);
        } else {
          setNotFound(true);
        }
      })
      .catch(() => setError(true));
  }, [accountId]);

  useEffect(() => {
    if (typeof accountId === "undefined") {
      return;
    }
    getAccount();
  }, [accountId, getAccount]);

  return { account, getAccount, error, accountNotFound: notFound };
}
