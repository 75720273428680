import * as React from "react";
import { Card, Icon, Typography } from "@lysaab/ui-2";
import cx from "classnames";
import "./CardButton.scss";

interface CardButtonProps {
  text: string;
  secondaryText?: string;
  badge?: number;
  showChevron?: boolean;
  noMargin?: boolean;
  boldPrimaryText?: boolean;
  className?: string;
  onClick?: () => void;
}

export const CardButton: React.FC<CardButtonProps> = ({
  text,
  secondaryText,
  badge,
  showChevron,
  noMargin,
  boldPrimaryText,
  className,
  onClick,
}) => {
  return (
    <button onClick={onClick} className={cx("card-button", className)}>
      <Card>
        <div className="flex-horizontal">
          <Typography component="span" className="ellipsis">
            {text}
          </Typography>

          <div className="flex-horizontal">
            {secondaryText && (
              <Typography component="span">{secondaryText}</Typography>
            )}
            <Icon.ChevronRight />
          </div>
        </div>
      </Card>
    </button>
  );
};
