import { FunctionComponent, useEffect, useState } from "react";
import { KfActiveMigration, dataDanica } from "../../../../data/dataDanica";
import { useIsPerson } from "../../../../hooks/useIsPerson";
import { SNACKBAR_TYPES, Snackbar } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { useCurrency } from "../../../../context/LocalizationContext";
import "./KfActiveMigrationInfo.scss";

export const KfActiveMigrationInfo: FunctionComponent = () => {
  const intl = useIntl();
  const isPerson = useIsPerson();
  const [activeMigration, setActiveMigration] = useState<KfActiveMigration>({
    accounts: 0,
    sum: 0,
  });
  const currency = useCurrency();

  useEffect(() => {
    if (isPerson) {
      return;
    }
    dataDanica.getKfActiveMigrations().then(setActiveMigration);
  }, [isPerson]);

  if (isPerson || activeMigration.accounts === 0) {
    return null;
  }

  return (
    <div className="kf-active-migration-info">
      <Snackbar type={SNACKBAR_TYPES.INFO}>
        <div>
          Just nu flyttas{" "}
          {intl.formatNumber(activeMigration.sum, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            style: "currency",
            currency,
          })}{" "}
          från din KF i futur till en ny KF hos Lysa Life - pengarna är inte
          synliga i kontosammanställningen nedan. Bokföringsunderlag kommer vara
          tillgängliga i början av januari.
        </div>
      </Snackbar>
    </div>
  );
};
