import { API, cache } from "@lysaab/ui-2";

export enum YearlyReviewStatus {
  NOT_NEEDED = "NOT_NEEDED",
  CAN_START = "CAN_START",
  FORCE = "FORCE",
}

export interface YearlyReviewResponse {
  yearlyReviewDeadline?: string;
  yearlyReviewHash: string;
  status: YearlyReviewStatus;
}

export const dataYearlyReview = {
  getYearlyReviewDate: () => {
    return API.get<YearlyReviewResponse>("/yearly-review");
  },
  postYearlyReviewDate: () => {
    return API.post("/yearly-review/save").then((response) => {
      cache.delete("/yearly-review");
      return response;
    });
  },
};
