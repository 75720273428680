import { Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { useCallback, useContext, useMemo, useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import { TranslatedText } from "../../../components/TranslatedText";
import { LocalizationContext } from "../../../context/LocalizationContext";
import { LysaCountry } from "@lysaab/shared";

const inviteUrlPrefix = {
  [LysaCountry.DENMARK]: "https://signup.lysa.se/dk?inviteId=",
  [LysaCountry.FINLAND]: "https://signup.lysa.se/fi?inviteId=",
  [LysaCountry.GERMANY]: "https://signup.lysa.se/de?inviteId=",
  [LysaCountry.SPAIN]: "https://signup.lysa.se/es?inviteId=",
  [LysaCountry.SWEDEN]: "https://signup.lysa.se/se?inviteId=",
};

interface Props {
  inviteId: string;
  error?: boolean;
}

export const InviteQrCode: React.VFC<Props> = ({ inviteId, error = false }) => {
  const localizationContext = useContext(LocalizationContext);
  const [isInternalError, setIsInternalError] = useState(false);

  const createInviteUrl = useCallback(() => {
    const country = localizationContext.state.country;
    if (!country) {
      setIsInternalError(true);
      return "";
    }
    setIsInternalError(false);
    return `${inviteUrlPrefix[country]}${inviteId}`;
  }, [inviteId, localizationContext.state.country]);

  const inviteUrl = useMemo(() => createInviteUrl(), [createInviteUrl]);

  return (
    <>
      <div className="invite-link-qrcode">
        <QRCodeCanvas includeMargin={true} value={inviteUrl} size={150} />
      </div>
      {(error || isInternalError) && (
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <TranslatedText id="invite.inviteLink.error" />
        </Snackbar>
      )}
    </>
  );
};
