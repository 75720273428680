import { Button, Card, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Amount } from "../../../../../../components/amount/Amount";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import {
  CompoundAccount,
  dataAccounts,
  getAccountAmount,
} from "../../../../../../data/dataAccounts";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { MONTHLY_DEPOSITS_URL } from "../../../../../../pages/deposits/monthly/create/MonthlyStory";
import { DEPOSITS_OVERVIEW_URL } from "../../../../../../pages/deposits/overview/Recommendation";
import { OVERVIEW_PAGE_URL } from "../../../../../../pages/overview/OverviewPage";
import { SwishDepositContext, SwishDepositState } from "../SwishDepositContext";
import { SWISH_DEPOSIT_PAGE_URL } from "../SwishDepositPage";

import "./SwishDepositDone.scss";

export function SwishDepositDone() {
  const swishDepositContext = useContext(SwishDepositContext);
  const setSwishState = swishDepositContext.setState;
  const [depositData, setDepositData] = useState<SwishDepositState>();
  const [accounts, setAccounts] = useState<CompoundAccount[]>();
  const [account, setAccount] = useState<CompoundAccount>();
  const history = useHistory();

  useEffect(() => {
    if (!swishDepositContext.state.amount && !depositData?.amount) {
      history.replace(getNavLink(SWISH_DEPOSIT_PAGE_URL));
    }
  }, [depositData, history, swishDepositContext.state]);

  if (!depositData) {
    setDepositData(swishDepositContext.state);
  }

  useEffect(() => {
    if (depositData) {
      setSwishState({
        accountId: undefined,
        amount: undefined,
        swishSuccessResponse: undefined,
      });
      dataAccounts
        .getAllAccounts()
        .then((response) =>
          setAccounts([
            ...response.investmentAccounts,
            ...response.savingsAccounts,
          ])
        );
    }
  }, [setSwishState, depositData]);

  useEffect(() => {
    if (!accounts || !depositData) {
      return;
    }
    setAccount(
      accounts.find((account) => account.accountId === depositData.accountId)
    );
  }, [accounts, depositData]);

  if (!depositData || !depositData.amount || !depositData.accountId) {
    return null;
  }

  return (
    <div className="swish-deposit-done">
      <h2>
        <TranslatedText id="sweden.deposits.swish.story.done.header" />
      </h2>
      <Card>
        <Snackbar type={SNACKBAR_TYPES.SUCCESS} icon>
          <TranslatedText id="sweden.deposits.swish.story.done.success" />
        </Snackbar>
        <dl>
          {account && (
            <div className="done-row">
              <dt>
                <TranslatedText id="sweden.deposits.swish.story.done.account.label" />
              </dt>
              <dd>
                <b>{account.name}</b>
                {` – `}
                <Amount amount={getAccountAmount(account)} />
              </dd>
            </div>
          )}
          {depositData.amount && (
            <div className="done-row">
              <dt>
                <TranslatedText id="sweden.deposits.swish.story.done.amount.label" />
              </dt>
              <dd>
                <b>
                  <Amount amount={depositData.amount} />
                </b>
              </dd>
            </div>
          )}
        </dl>
      </Card>
      <Button
        component={Link}
        block
        to={getNavLink(OVERVIEW_PAGE_URL)}
        label={<TranslatedText id="sweden.deposits.swish.story.done.button" />}
      />
      <Button
        component={Link}
        block
        to={getNavLink(DEPOSITS_OVERVIEW_URL)}
        variant="secondary"
        label={
          <TranslatedText id="sweden.deposits.swish.story.done.button-deposit" />
        }
      />
      <Button
        component={Link}
        block
        to={getNavLink(MONTHLY_DEPOSITS_URL)}
        variant="secondary"
        label={
          <TranslatedText id="sweden.deposits.swish.story.done.button-monthly" />
        }
      />
    </div>
  );
}
