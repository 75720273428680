import { useState } from "react";
import { useHistory } from "react-router";
import { parse, stringify } from "query-string";
import { Button } from "@lysaab/ui-2";
import ReactModal from "react-modal";
import { defineMessages, useIntl } from "react-intl";
import { Modal } from "../../components/modal/Modal";
import "./LoginTimeoutModal.scss";

ReactModal.setAppElement("#root");

const messages = defineMessages({
  header: {
    id: "timeout.modal.header",
    defaultMessage: "You've been signed out",
  },
  close: {
    id: "timeout.modal.close",
    defaultMessage: "Close",
  },
});

export function LoginTimeoutModal() {
  const history = useHistory();
  const searchParams = parse(history.location.search) as { timeout?: null };
  const [isOpen, setIsOpen] = useState(searchParams.timeout === null);
  const intl = useIntl();

  const close = () => {
    setIsOpen(false);
    delete searchParams.timeout;
    history.replace({
      pathname: history.location.pathname,
      search: stringify(searchParams),
    });
    window.location.search = stringify(searchParams);
  };

  return (
    <Modal
      showModal={isOpen}
      onClose={close}
      width={500}
      hideHeader
      closeOnOverlayClick
      className="login-timeout-modal"
    >
      <h2 className={"login-timeout-modal-header"}>
        {intl.formatMessage(messages.header)}
      </h2>
      <Button
        block
        onClick={close}
        label={intl.formatMessage(messages.close)}
      />
    </Modal>
  );
}
