import {
  Form,
  LysaFormRef,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { useContext, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { LysaAccountSelectionCard } from "../../../components/lysaAccountSelectionCard/LysaAccountSelectionCard";
import { TranslatedText } from "../../../components/TranslatedText";
import { InvestmentAccountId } from "../../../data/dataAccounts";
import {
  isDanicaKF,
  isKF,
  isMergedInternalTransferInvestmentAccount,
  TransferableGeneral,
  TransferableInvestment,
  TransferableSavings,
} from "../../../data/dataTransfer";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { useIsReadOnly } from "../../../hooks/useIsReadOnly";
import { OVERVIEW_PAGE_URL } from "../../overview/OverviewPage";
import { useMergedInternalTransferAccounts } from "../hooks/useMergedInternalTransferAccounts";
import { InternalTransferContext } from "../InternalTransferContext";

interface Props {
  next: () => void;
}

interface WithAccountId {
  accountId?: InvestmentAccountId;
}

export function InternalTransferFromAccount({ next }: Props) {
  const {
    state: internalTransferContextState,
    setState: setInternalTransferContextState,
  } = useContext(InternalTransferContext);
  const selectedAccount = internalTransferContextState.fromAccount;
  const formRef = useRef<LysaFormRef>();
  const isReadOnly = useIsReadOnly();
  const location = useLocation<WithAccountId | undefined>();
  const mergedInternalTransferAccounts = useMergedInternalTransferAccounts();

  useEffect(() => {
    if (
      typeof location.state?.accountId === "undefined" ||
      mergedInternalTransferAccounts.status !== "COMPLETED"
    ) {
      return;
    }
    setInternalTransferContextState({
      fromAccount: mergedInternalTransferAccounts.data.find(
        (account) => account.accountId === location.state?.accountId
      ),
      // Reset toAccount in case the user has already been at the next story step
      // but have stepped back to change the from account.
      // The user will have to step through the "ToAccount" step again,
      // and by doing this we sidestep any potential inconsistencies like
      // selecting the same account in formAccount as toAccount
      toAccount: undefined,
    });
  }, [
    setInternalTransferContextState,
    location.state,
    mergedInternalTransferAccounts,
  ]);

  if (mergedInternalTransferAccounts.status === "LOADING") {
    return (
      <div className="internal-transfer-from-account">
        <Spinner />
      </div>
    );
  }

  if (mergedInternalTransferAccounts.status === "ERROR") {
    return (
      <div className="internal-transfer-from-account">
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <TranslatedText id="internal-transfer.story.from-account.error" />
        </Snackbar>
      </div>
    );
  }

  if (mergedInternalTransferAccounts.data.length === 1) {
    return (
      <div>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <TranslatedText id="internal-transfer.story.from-account.error.mergedInternalTransferAccounts" />
        </Snackbar>
      </div>
    );
  }

  return (
    <div className="internal-transfer-from-account">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (
            formRef.current?.isInvalid ||
            selectedAccount?.transferable !== TransferableGeneral.OK ||
            isDanicaKF(selectedAccount) ||
            isReadOnly
          ) {
            return;
          }
          next();
        }}
      >
        <LysaAccountSelectionCard
          accounts={mergedInternalTransferAccounts.data}
          selectedAccount={internalTransferContextState.fromAccount}
          onChange={(fromAccount) => {
            setInternalTransferContextState({
              fromAccount,
              // Reset toAccount in case the user has already been at the next story step
              // but have stepped back to change the from account.
              // The user will have to step through the "ToAccount" step again,
              // and by doing this we sidestep any potential inconsistencies like
              // selecting the same account in formAccount as toAccount
              toAccount: undefined,
            });
          }}
          legend={
            <h2>
              <TranslatedText id="internal-transfer.story.from-account.header" />
            </h2>
          }
        />

        {isKF(selectedAccount) ? (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="internal-transfer.story.from-account.error.kf" />
          </Snackbar>
        ) : (
          <>
            {selectedAccount?.transferable ===
              TransferableGeneral.LACKS_FUNDS && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                {isMergedInternalTransferInvestmentAccount(selectedAccount) &&
                selectedAccount.worth > 0 &&
                selectedAccount.uninvestedMoney === selectedAccount.worth ? (
                  <TranslatedText id="internal-transfer.story.from-account.error.lacks-funds-with-cash" />
                ) : (
                  <TranslatedText id="internal-transfer.story.from-account.error.lacks-funds" />
                )}
              </Snackbar>
            )}
            {selectedAccount?.transferable ===
              TransferableInvestment.PENDING_ORDER && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                <TranslatedText id="internal-transfer.story.from-account.error.pending-order" />
              </Snackbar>
            )}
            {selectedAccount?.transferable ===
              TransferableGeneral.PENDING_WITHDRAWAL && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                <TranslatedText id="internal-transfer.story.from-account.error.pending-withdrawal" />
              </Snackbar>
            )}
            {selectedAccount?.transferable ===
              TransferableGeneral.PENDING_INTERNAL_TRANSFER && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                <TranslatedText id="internal-transfer.story.from-account.error.pending-internal-transfer" />
              </Snackbar>
            )}
            {selectedAccount?.transferable ===
              TransferableSavings.PENDING_CASH_TRANSFER && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                <TranslatedText id="internal-transfer.story.from-account.error.pending-cash-transfer" />
              </Snackbar>
            )}
            {selectedAccount?.transferable ===
              TransferableSavings.PENDING_REBALANCING && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                <TranslatedText id="internal-transfer.story.from-account.error.pending-rebalancing" />
              </Snackbar>
            )}
          </>
        )}

        {isReadOnly ? (
          <>
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="internal-transfer.story.from-account.error.read-only" />
            </Snackbar>
            <Button
              component={Link}
              to={getNavLink(OVERVIEW_PAGE_URL)}
              block
              label={
                <TranslatedText id="internal-transfer.story.from-account.back" />
              }
            />
          </>
        ) : (
          <Button
            type="submit"
            block
            label={
              <TranslatedText id="internal-transfer.story.from-account.button" />
            }
          />
        )}
      </Form>
    </div>
  );
}
