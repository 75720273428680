import { Button, Form, LysaFormRef, Spinner } from "@lysaab/ui-2";
import { useEffect, useMemo, useRef } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router";
import { LysaAccountSelectionCard } from "../../components/lysaAccountSelectionCard/LysaAccountSelectionCard";
import { useAccounts } from "../../hooks/useAccounts";
import { useShareAccountContext } from "./ShareAccountContext";
import { ShareAccountLocationState } from "./ShareAccountStory";

const messages = defineMessages({
  lysaAccountLabel: {
    id: "shareAccountStory.selection.lysaAccountLabel",
  },
});

interface Props {
  next: () => void;
}

export const ShareAccountSelection: React.VFC<Props> = ({ next }) => {
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();
  const { state: shareAccountState, setState: setShareAccountState } =
    useShareAccountContext();
  const { accounts } = useAccounts();
  const location = useLocation<ShareAccountLocationState | undefined>();

  const allAccounts = useMemo(
    () =>
      accounts
        ? [...accounts.investmentAccounts, ...accounts.savingsAccounts]
        : undefined,
    [accounts]
  );

  useEffect(() => {
    if (!shareAccountState.account && location.state && allAccounts) {
      const account = allAccounts.find(
        (account) => account.accountId === location.state?.accountId
      );
      if (account) {
        setShareAccountState({ account });
        next();
      }
    }
  }, [
    allAccounts,
    location.state,
    next,
    setShareAccountState,
    shareAccountState.account,
  ]);

  if (typeof allAccounts === "undefined") {
    return <Spinner />;
  }

  return (
    <div>
      <h2>
        <FormattedMessage id="shareAccountStory.selection.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            return;
          }
          next();
        }}
      >
        <LysaAccountSelectionCard
          accounts={allAccounts}
          selectedAccount={shareAccountState.account}
          onChange={(account) => setShareAccountState({ account })}
          legend={intl.formatMessage(messages.lysaAccountLabel)}
        />
        <Button
          type="submit"
          block
          label={
            <FormattedMessage id="shareAccountStory.selection.nextButton" />
          }
        />
      </Form>
    </div>
  );
};
