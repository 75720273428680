import { API, encode } from "@lysaab/ui-2";
import {
  ExperimentAPIInterfaceType,
  ExperimentValueType,
} from "@lysaab/shared";

type ExperimentAPIResponse = {
  value: ExperimentValueType;
};

/*
  The fetch and expose functions use the same API endpoint. Fetch sets
  exposed=false to avoid exposing the experiment when fetching it.

  The expose function conceptually also does a fetch, but with exposed=true,
  only reason this is split into two functions in the FE is for clarity.

  subjectId is optional, if:
  - User is logged in - legalEntityId will used as subjectId in the BE
  - User is not logged in - the request will fail with status 400
*/
export const dataExperiments: ExperimentAPIInterfaceType = {
  fetchExperiment(experiment) {
    const fetchUrl = encode`/experimentation/property/${
      experiment.propertyKey
    }?exposed=false${
      experiment.subjectId ? `&subjectId=${experiment.subjectId}` : ""
    }`;

    return API.get<ExperimentAPIResponse>(fetchUrl, false)
      .then(({ value }) => {
        return {
          ...experiment,
          value,
        };
      })
      .catch((error) => {
        console.error(
          "Failed to load experiment",
          experiment?.propertyKey,
          error
        );
        return undefined;
      });
  },
  exposeExperiment(experiment) {
    // &expose=true is the default, so preferred not to pass it
    const exposeUrl = encode`/experimentation/property/${
      experiment.propertyKey
    }${experiment.subjectId ? `?subjectId=${experiment.subjectId}` : ""}`;

    return API.get<ExperimentAPIResponse>(exposeUrl, false)
      .then(() => {
        // exposeExperiment should ignore the response
        return undefined;
      })
      .catch((error) => {
        console.error(
          "Failed to expose experiment",
          experiment?.propertyKey,
          error
        );
      });
  },
};
