import { Button, Card, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { FunctionComponent, useCallback, useContext, useState } from "react";
import AnimateHeight from "react-animate-height";
import { generatePath, Redirect } from "react-router-dom";
import { KfMigrationContext } from "./KfMigrationContext";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { dataDanica, MoveType } from "../../../../../../data/dataDanica";
import { UserContext } from "../../../../../../context/UserContext";
import { LOGIN_SWEDEN_PAGE_URL } from "../../../LoginPage";

interface Props {
  startLink: string;
  next: (signingId: string) => void;
}

export const Confirm: FunctionComponent<Props> = ({ next, startLink }) => {
  const { state: kfWithdrawalState } = useContext(KfMigrationContext);

  const { account, from, moveType } = kfWithdrawalState;
  const userContext = useContext(UserContext);
  const user = userContext.state;
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);

  const save = useCallback(() => {
    if (disabled) {
      return;
    }

    if (!from || !kfWithdrawalState.moveType) {
      return;
    }

    setError(false);
    setErrorMessage(null);
    setDisabled(true);

    dataDanica
      .putKfMigration({
        from: from.accountId,
        type: kfWithdrawalState.moveType,
      })
      .then((data) => {
        next(data.signingId);
      })
      .catch((error) => {
        setDisabled(false);
        if (error?.data?.message === "No withdrawal account found") {
          setErrorMessage(
            "Uttagskonto saknas. Du behöver uttagskonto för att kunna genomföra flytten. Lägg till ett uttagskonto. Vänta ett par bankdagar innan uttagskontot är på plats och försök sen igen."
          );
        }
        setError(true);
      });
  }, [disabled, from, kfWithdrawalState.moveType, next]);

  if (!account || !from) {
    return <Redirect to={startLink} />;
  }

  if (!user.name) {
    return <Redirect to={generatePath(getNavLink(LOGIN_SWEDEN_PAGE_URL))} />;
  }

  return (
    <div className="migration-request-page-confirm">
      <h1>Information om flytten</h1>

      <AnimateHeight height={error ? "auto" : 0} animateOpacity>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <div>
            {errorMessage ? (
              errorMessage
            ) : (
              <TranslatedText id="withdrawalRequest.kf.confirm.error" />
            )}
          </div>
        </Snackbar>
      </AnimateHeight>

      <Card>
        <p>Jag godkänner/är medveten om att:</p>
        {moveType === MoveType.SHARES && (
          <ul>
            <li>
              Fondandelarna i företagets kapitalförsäkring hos Futur Pension
              Försäkringsaktiebolag (publ.) tillfälligt - över årsskiftet -
              överförs till en värdepappersdepå hos Lysa AB som är registrerad
              på företaget.
            </li>
            <li>
              Lysa AB - direkt efter årsskiftet - placerar fondandelarna i en
              nyöppnad kapitalförsäkring för företaget hos Lysa Life Försäkring
              AB.
            </li>
            <li>
              Flytten av fondandelarna innebär en viss schablonbeskattning för
              företaget som företaget ska deklarera till Skatteverket.
              Schablonskatten uppgår till ca 0,08 % av fondandelarnas värde.
            </li>
            <li>
              Lysakoncernen inte ansvarar för eventuella skattemässiga
              konsekvenser som flytten av fondandelarna kan ha för företaget.
            </li>
            <li>
              Lysa AB kan, trots din begäran och oavsett anledningen till det,
              besluta att inte genomföra flytten. Om så sker kommer försäkringen
              att vara kvar hos Futur, precis som idag.
            </li>
            <li>
              Lysa AB inte har gett någon rådgivning med anledning av flytten
              och har således inte kunnat bedöma om flytten är lämplig för
              företaget.
            </li>
          </ul>
        )}
        {moveType === MoveType.CASH && (
          <ul>
            <li>
              Fondandelarna i företagets kapitalförsäkring hos Futur Pension
              Försäkringsaktiebolag (publ.) säljs så sent som möjligt i
              december.
            </li>
            <li>
              Lysa AB överför tillfälligt - över årsskiftet - likviden som
              erhålls efter försäljningen till Lysas klientmedelskonto hos
              Danske Bank, där vi håller dem för företagets räkning.
            </li>
            <li>
              Lysa AB köper så snart som möjligt efter årskiftet nya fondandelar
              för likviden i enlighet med det investeringsfokus och
              målfördelning mellan aktier och räntor som företaget tidigare har
              valt.
            </li>
            <li>
              De nya fondandelarna placeras i en nyöppnad kapitalförsäkring för
              företaget hos Lysa Life Försäkring AB.
            </li>
            <li>
              Företaget genom åtgärderna kommer att stå utanför marknaden ett
              par dagar och att det kan få till följd att företaget går miste om
              en värdeökning med avseende på de sålda fondandelarna.
            </li>
            <li>
              Lysakoncernen inte tar ansvar för hur fondandelarnas värde kommer
              att röra sig under den tid då företaget står utanför marknaden.
            </li>
            <li>
              Lysakoncernen inte kommer att kompensera företaget för eventuell
              utebliven ökning eller minskning av värdet på de sålda
              fondandelarna som kan uppstå under de dagar då företagets medel
              inte är investerade.
            </li>
            <li>
              Lysakoncernen inte ansvarar för eventuella skattemässiga
              konsekvenser som åtgärderna kan ha för företaget.
            </li>
            <li>
              Lysa AB kan, trots din begäran och oavsett anledningen till det,
              besluta att inte genomföra flytten. Om så sker kommer försäkringen
              att vara kvar hos Futur, precis som idag.
            </li>
            <li>
              Lysa AB inte har gett någon rådgivning med anledning av flytten
              och har således inte kunnat bedöma om flytten är lämplig för
              företaget.
            </li>
          </ul>
        )}
      </Card>

      <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
        Utbetalning av belopp till företagets anmälda bankkonto kommer inte att
        ske, trots att detta anges i BankID-signeringen (den informationen
        gäller för det ordinarie uttagsflödet och har av tekniska skäl fått vara
        kvar i denna tillfälliga flyttjänst).
      </Snackbar>

      <Button
        disabled={disabled}
        block
        onClick={save}
        label={<TranslatedText id="withdrawalRequest.kf.confirm.save" />}
      />
    </div>
  );
};
