import { Button, Card, Snackbar, SNACKBAR_TYPES, Spinner } from "@lysaab/ui-2";
import { Fragment, VoidFunctionComponent } from "react";
import { generatePath, Link } from "react-router-dom";
import { RouteAwareToggle } from "../../components/route/RouteAwareToggle";
import { TranslatedText } from "../../components/TranslatedText";
import { InvestmentAccount, AccountType } from "../../data/dataAccounts";
import { getNavLink } from "../../hooks/useCountryUrls";
import {
  BASE_ROUTES as CLOSE_ACCOUNT_ROUTES,
  CLOSE_ACCOUNT_URL,
} from "../closeAccountStory/CloseAccountStory";
import "./CloseAccount.scss";
import { GridCol } from "../../components/grid/gridCol/GridCol";
import { useIsReadOnly } from "../../hooks/useIsReadOnly";
import { CloseAccountReasonsList } from "../../components/closeAccountReasonsList/CloseAccountReasonsList";
import { useInvestmentAccountCloseable } from "../../hooks/useInvestmentAccountCloseable";

interface Props {
  account?: InvestmentAccount;
}

export const CloseAccount: VoidFunctionComponent<Props> = ({ account }) => {
  const isReadonly = useIsReadOnly();
  const isInvestmentAccountClsoeable = useInvestmentAccountCloseable();

  const isLoading =
    typeof account === "undefined" ||
    isInvestmentAccountClsoeable === "LOADING";

  const isFuturKF = account?.type === AccountType.DANICA_KF;

  if (isFuturKF || isReadonly || isInvestmentAccountClsoeable === "ERROR") {
    return null;
  }

  if (isLoading) {
    return (
      <RouteAwareToggle path={getNavLink(CLOSE_ACCOUNT_URL)}>
        <section className="account-page-close-account">
          <h2>
            <TranslatedText id="accountPage.delete-account.header" />
          </h2>
          <Card>
            <Spinner />
          </Card>
        </section>
      </RouteAwareToggle>
    );
  }

  const closeableState = isInvestmentAccountClsoeable(account.accountId);

  if (!closeableState.isCloseable) {
    return (
      <RouteAwareToggle path={getNavLink(CLOSE_ACCOUNT_URL)}>
        <GridCol xsmall={12} medium={6} large={4}>
          <section className="account-page-close-account">
            <h2>
              <TranslatedText id="accountPage.delete-account.header" />
            </h2>
            <Card>
              <Snackbar type={SNACKBAR_TYPES.INFO}>
                <CloseAccountReasonsList
                  reasons={closeableState.reasons}
                  className="account-page-close-account__snackbar"
                />
              </Snackbar>
            </Card>
          </section>
        </GridCol>
      </RouteAwareToggle>
    );
  }

  return (
    <RouteAwareToggle path={getNavLink(CLOSE_ACCOUNT_URL)}>
      <GridCol xsmall={12} medium={6} large={4}>
        <section className="account-page-close-account">
          <h2>
            <TranslatedText id="accountPage.delete-account.header" />
          </h2>
          <Card>
            <Fragment>
              <p>
                <TranslatedText id="accountPage.delete-account.text" />
              </p>
              <Button
                component={Link}
                variant="negative"
                size="small"
                to={getNavLink(
                  generatePath(
                    CLOSE_ACCOUNT_ROUTES.CLOSE_ACCOUNT_CONFIRMATION,
                    {
                      accountId: account.accountId,
                    }
                  )
                )}
                block
                label={<TranslatedText id="accountPage.delete-account.link" />}
              />
            </Fragment>
          </Card>
        </section>
      </GridCol>
    </RouteAwareToggle>
  );
};
