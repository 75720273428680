import { Form, LysaFormRef, Button, Spinner } from "@lysaab/ui-2";
import { useEffect, useRef } from "react";
import { TranslatedText } from "../../../components/TranslatedText";
import { LysaAccountSelectionCard } from "../../../components/lysaAccountSelectionCard/LysaAccountSelectionCard";
import { useAccounts } from "../../../hooks/useAccounts";
import { useMonthlyInternalTransferContext } from "../MonthlyInternalTransferContext";
import { CompoundAccountId } from "../../../data/dataAccounts";
import { useLocation } from "react-router";

interface Props {
  next: () => void;
}

interface InitialParams {
  id?: string;
  accountId?: CompoundAccountId;
}

export function FromAccount({ next }: Props) {
  const { accounts } = useAccounts();
  const formRef = useRef<LysaFormRef>();
  const monthlyInternalTransferContext = useMonthlyInternalTransferContext();
  const location = useLocation<InitialParams>();

  useEffect(() => {
    if (
      typeof location.state?.accountId === "undefined" ||
      typeof monthlyInternalTransferContext.state.fromAccountId !== "undefined"
    ) {
      return;
    }

    if (typeof location.state.id === "undefined") {
      monthlyInternalTransferContext.setState({
        fromAccountId: location.state.accountId,
      });
    }
  }, [location.state, monthlyInternalTransferContext]);

  if (!accounts) {
    return <Spinner />;
  }

  const mergedAccounts = [
    ...accounts.investmentAccounts,
    ...accounts.savingsAccounts,
  ];

  const selectedAccount = mergedAccounts.find(
    (account) =>
      account.accountId === monthlyInternalTransferContext.state.fromAccountId
  );

  return (
    <div>
      <h2>
        <TranslatedText id="monthly-internal-transfer.story.from-account-selection.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            return;
          }
          next();
        }}
      >
        <LysaAccountSelectionCard
          accounts={mergedAccounts.filter(
            (account) =>
              account.accountId !==
              monthlyInternalTransferContext.state.toAccountId
          )}
          selectedAccount={selectedAccount}
          onChange={(account) => {
            monthlyInternalTransferContext.setState({
              fromAccountId: account.accountId,
            });
          }}
          legend={
            <TranslatedText id="monthly-internal-transfer.story.from-account-selection.label" />
          }
        />
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="monthly-internal-transfer.story.from-account-selection.button" />
          }
        />
      </Form>
    </div>
  );
}
