import { useEffect, useState } from "react";
import {
  dataInvestments,
  getPensionAccountQuestions,
  GetPensionSuitabilityAssessmentRequest,
  GetSuitabilityAssessmentResponse,
} from "../../../../../data/dataInvestments";
import { dataFees, FeesEstimatedSignedIn } from "../../../../../data/dataFees";
import { LysaCountry } from "@lysaab/shared";
import { AccountType } from "../../../../../data/dataAccounts";
import { useLanguage } from "../../../../../context/LocalizationContext";
import { useTransfer } from "../TransferContext";
import { useUser } from "../../../../../context/UserContext";
import { getUserAge } from "../utils/userAge";
import { useHistory } from "react-router";

const EXAMPLE_AMOUNT = 100000;

export const useFees = () => {
  const [advise, setAdvise] = useState<GetSuitabilityAssessmentResponse>();
  const [fees, setFees] = useState<FeesEstimatedSignedIn>();
  const language = useLanguage();
  const [transfer] = useTransfer();
  const user = useUser();
  const age = getUserAge(user.tin);
  const history = useHistory();

  /**
   * accountType is hardcoded for now. Each transfer can have several moves(pensions).
   * Each move has accountType of either LYSA_PPF or LYSA_TJP.
   * Since the fees apply to all moves(pensions) we can't distinguise a specific accountType.
   * The fee we present is the same for the whole transfer.
   * The backend treat the accountType the same. Thus, hardcoded solution works for now.
   */
  useEffect(() => {
    if (advise?.investmentType && advise?.advisedRisk) {
      const takenRisk = Math.min(
        advise.advisedRisk + transfer.takenRiskDeviation,
        100
      );

      dataFees
        .getEstimatedFeesSignedIn({
          amount: EXAMPLE_AMOUNT,
          risk: takenRisk,
          investmentType: advise?.investmentType,
          country: LysaCountry.SWEDEN,
          accountType: AccountType.LYSA_TJP,
        })
        .then((fees) => {
          setFees(fees);
        });
    }
  }, [
    transfer.sustainability,
    transfer.takenRiskDeviation,
    advise?.investmentType,
    advise?.advisedRisk,
  ]);

  useEffect(() => {
    if (typeof transfer?.sustainability !== "undefined") {
      const data: Partial<GetPensionSuitabilityAssessmentRequest> = {
        language: language,
        age: age,
        ...getPensionAccountQuestions(transfer),
      };

      dataInvestments.getNewPensionAccountSuitability(data).then((advise) => {
        setAdvise(advise);
      });
    }
  }, [transfer, language, age, history]);

  return fees;
};
