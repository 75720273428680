export interface TabProps {
  tabroute: string;
}

export const Tab: React.FC<React.PropsWithChildren<TabProps>> = ({
  children,
  ...props
}) => {
  return <div {...props}>{children}</div>;
};
