import { useContext, useEffect, useState } from "react";
import "./NewsBanner.scss";
import { Button, Icon, Typography } from "@lysaab/ui-2";
import {
  BannerContent,
  ExternalLink,
  dataBanner,
} from "../../../../data/dataBanner";
import { useHistory, useLocation } from "react-router";
import { useUser } from "../../../../context/UserContext";
import { LayoutContext } from "../../../../context/LayoutContext";

export const IS_NEWSBANNER_CLOSED_SESSION_KEY = "IS_NEWSBANNER_CLOSED";

export const NewsBanner = () => {
  const [banner, setBanner] = useState<BannerContent | null>();
  const [showBanner, setShowBanner] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const { language } = useUser();
  const layoutContext = useContext(LayoutContext);
  const showNewsBanner = layoutContext.state.showBanner;

  useEffect(() => {
    setShowBanner(true);
    if (language !== undefined && showNewsBanner) {
      dataBanner
        .getContent(language)
        .then((data) => {
          setBanner(data.banner);
        })
        .catch(() => {
          setShowBanner(false);
        });
    }
  }, [showNewsBanner, language]);

  if (!banner || banner === null || !showBanner || !showNewsBanner) {
    return null;
  }

  const close = () => {
    setShowBanner(false);
    dataBanner
      .saveDismiss(banner.id)
      .catch(() => {})
      .finally(() => {
        setBanner(null);
      });

    layoutContext.setState({ showBanner: false });
  };

  const saveClick = () => {
    dataBanner
      .saveClick(banner.id)
      .catch(() => {})
      .finally(() => {
        if (isHttpLink(banner.buttonUri)) {
          window.open(banner.buttonUri, "_blank");
        } else {
          history.push(createUrl(location.pathname, banner.buttonUri));
        }
      });
  };

  return (
    <div className="newsbanner">
      <div className="newsbanner-header">
        <Typography type="h3">{banner.title}</Typography>
        <button className="newsbanner-close-button" onClick={close}>
          <Icon.Close color="black" />{" "}
        </button>
      </div>
      <Typography>{banner.body}</Typography>
      <div>
        <Button
          label={banner.buttonText}
          size="small"
          variant="secondary"
          onClick={saveClick}
        />
      </div>
    </div>
  );
};

function isHttpLink(link: unknown): link is ExternalLink {
  return typeof link === "string" && link.startsWith("http");
}

function removeLeadingSlash(link: string): string {
  if (link.charAt(0) === "/") {
    return link.substring(1);
  }
  return link;
}

function createUrl(baseUrl: string, additionalUrl: string): string {
  if (
    baseUrl.charAt(baseUrl.length - 1) === "/" &&
    additionalUrl.charAt(0) === "/"
  ) {
    return baseUrl + removeLeadingSlash(additionalUrl);
  }

  if (
    baseUrl.charAt(baseUrl.length - 1) !== "/" &&
    additionalUrl.charAt(0) !== "/"
  ) {
    return baseUrl + "/" + additionalUrl;
  }

  return baseUrl + additionalUrl;
}
