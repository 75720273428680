import { useCallback, useContext } from "react";
import { Redirect } from "react-router";
import { AccountLoading } from "../../../../../../components/tink/accountLoading/AccountLoading";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { ADD_DEPOSIT_AUTOGIRO_URL } from "../../Autogiro/addAccount/AddDepositAccountAutogiroStory";
import { MonthlyContext } from "../MonthlyContext";
import { BASE_ROUTES } from "../MonthlyStory";
import { TinkAccount } from "../../../../../../data/dataTink";

interface Props {
  next: () => void;
}

export function TinkAccountLoadingWrapper({ next }: Props) {
  const monthlyContext = useContext(MonthlyContext);
  const bank = monthlyContext.state.tinkBank;
  const setState = monthlyContext.setState;

  const onComplete = useCallback(
    (accounts: TinkAccount[]) => {
      setState({ tinkAccounts: accounts });
      setTimeout(() => {
        next();
      }, 0);
    },
    [next, setState]
  );

  if (!bank) {
    return <Redirect to={getNavLink(BASE_ROUTES.INTRO)} />;
  }
  return (
    <AccountLoading
      bank={bank}
      onComplete={onComplete}
      fallbackOptions={{
        locationDescriptor: {
          pathname: getNavLink(ADD_DEPOSIT_AUTOGIRO_URL),
          state: {
            returnUrl: getNavLink(BASE_ROUTES.EXTERNAL_ACCOUNT),
            returnState: monthlyContext.state,
          },
        },
        buttonText: (
          <TranslatedText id="sweden.deposits.monthly.story.account-loading-wrapper.fallbackButton" />
        ),
      }}
    />
  );
}
