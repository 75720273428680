import {
  Alternative,
  RadioGroup,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { useEffect, useState } from "react";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { generatePath, Link, useParams } from "react-router-dom";
import { TranslatedText } from "../../../../../../../components/TranslatedText";
import { SavingsAccountId } from "../../../../../../../data/dataAccounts";
import {
  dataWithdrawals,
  ExternalAccount,
  getBankText,
} from "../../../../../../../data/dataWithdrawals";
import { getNavLink } from "../../../../../../../hooks/useCountryUrls";
import { AddKlarnaAccountLocationState } from "../../../../../../../pages/withdrawal/addAccountKlarna/AddAccountKlarnaStory";
import { BASE_ROUTES } from "../../../CloseSavingsAccountStory";

const messages = defineMessages({
  lysaAccountLabel: {
    id: "closeSavingsAccountStory.confirmation.externalAccounts.externalAccountlabel",
  },
  lysaAccountRequired: {
    id: "closeSavingsAccountStory.confirmation.externalAccounts.externalAccountRequired",
  },
});

interface Props {
  addWithdrawalAccountUrl?: string;
  selectedExternalAccount?: Alternative<string>;
  setSelectedExternalAccount: React.Dispatch<
    React.SetStateAction<Alternative<string> | undefined>
  >;
}

export const ExternalAccounts: React.VoidFunctionComponent<Props> = ({
  addWithdrawalAccountUrl,
  selectedExternalAccount,
  setSelectedExternalAccount,
}) => {
  const [externalAccounts, setExternalAccounts] = useState<ExternalAccount[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const intl = useIntl();
  const { accountId } = useParams<{ accountId: SavingsAccountId }>();

  useEffect(() => {
    dataWithdrawals
      .getExternalAccounts()
      .then(setExternalAccounts)
      .catch(() => setError(true))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <Spinner />;
  }
  if (error) {
    return (
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <TranslatedText id="closeSavingsAccountStory.confirmation.externalAccounts.error" />
      </Snackbar>
    );
  }
  return (
    <React.Fragment>
      {externalAccounts.length > 0 ? (
        <RadioGroup
          alternatives={externalAccounts.map(
            (account): Alternative<string> => ({
              text: getBankText(account),
              value: account.externalBankAccount,
            })
          )}
          value={selectedExternalAccount}
          header={intl.formatMessage(messages.lysaAccountLabel)}
          onChange={setSelectedExternalAccount}
          validators={[
            new RequiredValidator(
              intl.formatMessage(messages.lysaAccountRequired)
            ),
          ]}
        />
      ) : (
        <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
          <TranslatedText id="closeSavingsAccountStory.confirmation.externalAccounts.noExternalAccount" />
        </Snackbar>
      )}
      {addWithdrawalAccountUrl && (
        <Link<AddKlarnaAccountLocationState>
          to={{
            pathname: addWithdrawalAccountUrl,
            state: {
              returnUrl: generatePath(
                getNavLink(BASE_ROUTES.CLOSE_SAVINGS_ACCOUNT_CONFIRMATION),
                { accountId: accountId }
              ),
            },
          }}
          className="lysa-link"
        >
          <TranslatedText id="closeSavingsAccountStory.confirmation.externalAccounts.addExternalAccount" />
        </Link>
      )}
    </React.Fragment>
  );
};
