import { VoidFunctionComponent } from "react";
import { accountNumberInfo } from "@lysaab/ui-2";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { Amount } from "../../../../../../../components/amount/Amount";
import { GridCol } from "../../../../../../../components/grid/gridCol/GridCol";
import { CashTransaction } from "../../../../../../../data/dataCorporate";
import { CashTransactionType } from "../../../../../../../data/dataTransactions";
import { DataDisplay } from "../../../../../../../components/dataDisplay/DataDisplay";
import { cashTypesMessages } from "../TransactionUtils";

export const CashDepositWithdrawal: VoidFunctionComponent<{
  transaction: CashTransaction;
}> = ({ transaction }) => {
  const intl = useIntl();
  const accountInfo = accountNumberInfo(transaction.counterpart);
  let banktName = "";
  if (accountInfo) {
    banktName = accountInfo.bank_name;
  }

  return (
    <>
      <GridCol xsmall={2} className="text-container">
        <DataDisplay
          type="list-item"
          title={
            <FormattedMessage id="sweden.accountingPage.dataDisplay.cashDepositWithdrawal.title.date" />
          }
          text={<FormattedDate value={transaction.booked} />}
        />
      </GridCol>

      <GridCol xsmall={5} className="text-container">
        <DataDisplay
          type="list-item"
          title={
            transaction.transactionType === CashTransactionType.DEPOSIT ? (
              <FormattedMessage id="sweden.accountingPage.dataDisplay.cashDepositWithdrawal.title.counterpart.from" />
            ) : (
              <FormattedMessage id="sweden.accountingPage.dataDisplay.cashDepositWithdrawal.title.counterpart.to" />
            )
          }
          text={
            banktName
              ? `${transaction.counterpart}, ${banktName}`
              : transaction.counterpart
          }
        />
      </GridCol>

      <GridCol xsmall={3} className="text-container text-right">
        <DataDisplay
          type="list-item"
          title={
            <FormattedMessage id="sweden.accountingPage.dataDisplay.cashDepositWithdrawal.title.amount" />
          }
          text={<Amount amount={transaction.amount} decimals={2} />}
        />
      </GridCol>

      <GridCol xsmall={2} className="text-container text-right">
        <DataDisplay
          type="list-item"
          title={
            <FormattedMessage id="sweden.accountingPage.dataDisplay.cashDepositWithdrawal.title.type" />
          }
          text={intl.formatMessage(
            cashTypesMessages[transaction.transactionType]
          )}
        />
      </GridCol>
    </>
  );
};
