import { useEffect, useRef, VoidFunctionComponent } from "react";
import { useCallback } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import {
  Alternative,
  Button,
  Card,
  Form,
  LysaFormRef,
  RadioGroup,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import { EventTracker } from "../../../../components/eventTracker/EventTracker";
import { TrackerEvent } from "../../../../data/dataCustomerTracking";
import { useYearlyReviewContext } from "../../contexts/YearlyReviewContext";
import { useNavigateToCloseAccount } from "../../hooks/useNavigateToCloseAccount";
import { CloseCustomerScenario } from "../../../../data/dataProfile";

interface Props {
  next: () => void;
}

const messages = defineMessages({
  fatcaHeader: {
    id: "yearly.fatca.header",
  },
  fatcaAlternativeYes: {
    id: "yearly.fatca.alt.yes",
  },
  fatcaAlternativeNo: {
    id: "yearly.fatca.alt.no",
  },
  fatcaErrorRequired: {
    id: "yearly.fatca.error.required",
  },
});

export const Fatca: VoidFunctionComponent<Props> = ({ next }) => {
  const intl = useIntl();
  const formRef = useRef<LysaFormRef>();
  const postFatcaEvent = useFatcaTracking();
  const navigateToCloseAccount = useNavigateToCloseAccount();
  const [yearlyReviewState, setYearlyReviewState] = useYearlyReviewContext();

  const fatcaAlternatives: Alternative<boolean>[] = [
    {
      text: intl.formatMessage(messages.fatcaAlternativeYes),
      value: true,
    },
    {
      text: intl.formatMessage(messages.fatcaAlternativeNo),
      value: false,
    },
  ];

  const onSubmit = useCallback(() => {
    if (formRef.current?.isValid && yearlyReviewState.fatca === false) {
      EventTracker.track({
        event: TrackerEvent.YEARLY_REVIEW_FATCA,
        message: { fatca: yearlyReviewState.fatca },
      });
      next();
    }
  }, [next, yearlyReviewState.fatca]);

  const onCloseAccount = useCallback(() => {
    postFatcaEvent();
    navigateToCloseAccount(CloseCustomerScenario.FATCA);
  }, [postFatcaEvent, navigateToCloseAccount]);

  return (
    <Form lysaFormRef={formRef} onSubmit={onSubmit}>
      <h2>
        <FormattedMessage id="yearly.fatca.title" />
      </h2>
      <Card>
        <RadioGroup
          header={intl.formatMessage(messages.fatcaHeader)}
          alternatives={fatcaAlternatives}
          value={fatcaAlternatives.find(
            (alt) => alt.value === yearlyReviewState.fatca
          )}
          onChange={(answer) => {
            setYearlyReviewState({ fatca: answer.value });
          }}
          validators={[
            new RequiredValidator(
              intl.formatMessage(messages.fatcaErrorRequired)
            ),
          ]}
          data-test-id="fatca"
        />
        {yearlyReviewState.fatca && (
          <Snackbar type={SNACKBAR_TYPES.ERROR}>
            <FormattedMessage id="yearly.fatca.error.yes" />
          </Snackbar>
        )}
      </Card>
      {yearlyReviewState.fatca && (
        <Button
          block
          variant="negative"
          onClick={onCloseAccount}
          label={
            <FormattedMessage id="yearly.fatca.closeLysaCustomerConfirmation" />
          }
        />
      )}
      {!yearlyReviewState.fatca && (
        <Button
          block
          type="submit"
          label={<FormattedMessage id="yearly.fatca.next" />}
        />
      )}
    </Form>
  );
};

export function useFatcaTracking() {
  const eventTrackerTimout = useRef<NodeJS.Timeout | "POSTED">();
  const [yearlyReviewState] = useYearlyReviewContext();

  const postFatcaEvent = useCallback(() => {
    if (eventTrackerTimout.current && eventTrackerTimout.current !== "POSTED") {
      clearTimeout(eventTrackerTimout.current);
      EventTracker.track({
        event: TrackerEvent.YEARLY_REVIEW_FATCA,
        message: { fatca: !!yearlyReviewState?.fatca },
      });
    }
  }, [yearlyReviewState.fatca]);

  useEffect(() => {
    if (yearlyReviewState.fatca && !eventTrackerTimout.current) {
      eventTrackerTimout.current = setTimeout(() => {
        if (yearlyReviewState.fatca) {
          eventTrackerTimout.current = "POSTED";
          EventTracker.track({
            event: TrackerEvent.YEARLY_REVIEW_FATCA,
            message: { fatca: yearlyReviewState.fatca },
          });
        } else {
          eventTrackerTimout.current = undefined;
        }
      }, 20000);
    }
  }, [yearlyReviewState.fatca]);

  return postFatcaEvent;
}
