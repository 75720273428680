import cx from "classnames";
import { Signature } from "../signature/Signature";
import { HtmlEntityDecoder } from "./HtmlEntityDecoder";

import "./Thread.scss";
import "./SkeletonThread.scss";

export const SkeletonThread = () => {
  return (
    <div className="thread skeleton-thread">
      <button className={cx("unstyled-button", "thread-button")}>
        <div className="skeleton-animation-first" />
        <div className="skeleton-animation-second" />
        <div className="all-but-date">
          <Signature />
          <span className="subject">&nbsp;</span>
          &nbsp;&ndash;&nbsp;
          <span className="short-message">
            <HtmlEntityDecoder text="Lysa loves you" />
          </span>
        </div>
        <span className="date-time">1970-01-01</span>
      </button>
    </div>
  );
};
