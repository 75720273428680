import { Alternative } from "@lysaab/ui-2";
import { defineMessages, IntlShape } from "react-intl";

export enum CloseCustomerReason {
  ANOTHER_SERVICE = "I use another service",
  TECHNICAL_PROBLEMS = "I have technical problems with the service",
  SERVICE_NOT_RIGHT = "The service is not right for me",
  SERVICE_TOO_DIFFICULT = "The service is too difficult to use",
  SERVICE_TOO_EXPENSIVE = "The service is too expensive",
  MONEY_LOST_VALUE = "My money has lost too much in value",
  OTHER = "Other reason",
}

export const NoReasonSelected = "No reason selected";

interface MessageWithId {
  id: string;
}

export const closeCustomerReasonsMessages = defineMessages<
  CloseCustomerReason,
  MessageWithId,
  Record<CloseCustomerReason, MessageWithId>
>({
  [CloseCustomerReason.ANOTHER_SERVICE]: {
    id: "closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.reason.ANOTHER_SERVICE",
  },
  [CloseCustomerReason.TECHNICAL_PROBLEMS]: {
    id: "closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.reason.TECHNICAL_PROBLEMS",
  },
  [CloseCustomerReason.SERVICE_NOT_RIGHT]: {
    id: "closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.reason.SERVICE_NOT_RIGHT",
  },
  [CloseCustomerReason.SERVICE_TOO_DIFFICULT]: {
    id: "closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.reason.SERVICE_TOO_DIFFICULT",
  },
  [CloseCustomerReason.SERVICE_TOO_EXPENSIVE]: {
    id: "closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.reason.SERVICE_TOO_EXPENSIVE",
  },
  [CloseCustomerReason.MONEY_LOST_VALUE]: {
    id: "closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.reason.MONEY_LOST_VALUE",
  },
  [CloseCustomerReason.OTHER]: {
    id: "closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.reason.OTHER",
  },
});

export function getCloseLysaCustomerReasonsAlternatives(
  intl: IntlShape
): Alternative<CloseCustomerReason>[] {
  return Object.values(CloseCustomerReason).map((reason) => {
    return {
      text: intl.formatMessage(closeCustomerReasonsMessages[reason]),
      value: reason,
    };
  });
}
