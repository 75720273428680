import {
  useState,
  useRef,
  useCallback,
  useEffect,
  FunctionComponent,
} from "react";
import { CardList, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import {
  InvestmentAccount,
  dataAccounts,
  isPensionAccountType,
} from "../../../../data/dataAccounts";
import { Retry, Status } from "../../../../components/retry/Retry";
import {
  dataFees,
  AccountFeeResponse,
  FeeEstimation,
} from "../../../../data/dataFees";
import { useInView } from "react-intersection-observer";
import { Disclaimer } from "../../Disclaimer";
import { Amount } from "../../../../components/amount/Amount";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { FormattedPercentage } from "../../../../components/formattedPercentage/FormattedPercentage";
import { CardBottom } from "../../CardBottom";
import { Link } from "react-router-dom";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { FEES_PAGE_URL } from "../../../fees/FeesPage";
import { RouteAwareToggle } from "../../../../components/route/RouteAwareToggle";
import "./FeesData.scss";

interface Props {
  account: InvestmentAccount | undefined;
  showAction?: boolean;
}

function getHistoricFees(
  feeData: FeeEstimation | null,
  showInsurance: boolean,
  showPensionFees: boolean
) {
  return (
    <dl>
      <dt>
        <FormattedMessage id="accountPage.fees.list.historic.lysa" />
      </dt>
      <dd>
        {feeData ? (
          <Amount
            amount={feeData.discretionary + feeData.fundManagement}
            decimals={2}
          />
        ) : (
          "-"
        )}
      </dd>
      {showPensionFees && feeData?.insurancePremium ? (
        <>
          <dt>
            <FormattedMessage id="accountPage.fees.list.historic.pension" />
          </dt>
          <dd>
            <Amount amount={feeData.insurancePremium} decimals={2} />
          </dd>
        </>
      ) : null}
      <dt>
        {" "}
        <FormattedMessage id="accountPage.fees.list.historic.funds" />
      </dt>
      <dd>
        {feeData ? <Amount amount={feeData.fundAssets} decimals={2} /> : "-"}
      </dd>
      <dt>
        <FormattedMessage id="accountPage.fees.list.historic.transactions" />
      </dt>
      <dd>
        {feeData ? (
          <Amount amount={feeData.transactionFees} decimals={2} />
        ) : (
          "-"
        )}
      </dd>
      {showInsurance ? (
        <>
          <dt>
            <FormattedMessage id="accountPage.fees.list.historic.insurance" />
          </dt>
          <dd>
            {feeData?.insurancePremium ? (
              <Amount amount={feeData.insurancePremium} decimals={2} />
            ) : (
              "-"
            )}
          </dd>
          <dt>
            <FormattedMessage id="accountPage.fees.list.historic.insuranceRisk" />
          </dt>
          <dd>
            {feeData?.insuranceRiskPremium ? (
              <Amount amount={feeData.insuranceRiskPremium} decimals={2} />
            ) : (
              "-"
            )}
          </dd>
        </>
      ) : null}
      <dt className="summary-cell">
        <b>
          <FormattedMessage id="accountPage.fees.list.historic.total" />
        </b>
      </dt>
      <dd className="summary-cell">
        {feeData ? <Amount amount={feeData.total} decimals={2} /> : "-"}
      </dd>
    </dl>
  );
}

function getFutureFees(
  costData: FeeEstimation | null,
  futureData: FeeEstimation | null,
  showInsurance: boolean,
  showPensionFees: boolean
) {
  return (
    <dl>
      <dt>
        <FormattedMessage id="accountPage.fees.list.future.lysa" /> -{" "}
        {costData ? (
          <FormattedPercentage
            value={costData.discretionary + costData.fundManagement}
            decimals={3}
          />
        ) : (
          "-"
        )}
      </dt>
      <dd>
        {futureData ? (
          <Amount
            amount={futureData.discretionary + futureData.fundManagement}
            decimals={2}
          />
        ) : (
          "-"
        )}
      </dd>
      {showPensionFees &&
      typeof futureData?.insurancePremium !== "undefined" &&
      typeof costData?.insurancePremium !== "undefined" ? (
        <>
          <dt>
            <FormattedMessage id="accountPage.fees.list.future.pension" /> -{" "}
            <FormattedPercentage
              value={costData.insurancePremium}
              decimals={3}
            />
          </dt>
          <dd>
            <Amount amount={futureData.insurancePremium} decimals={2} />
          </dd>
        </>
      ) : null}
      <dt>
        <FormattedMessage id="accountPage.fees.list.future.funds" /> -{" "}
        {costData ? (
          <FormattedPercentage value={costData.fundAssets} decimals={3} />
        ) : (
          "-"
        )}
      </dt>
      <dd>
        {futureData ? (
          <Amount amount={futureData.fundAssets} decimals={2} />
        ) : (
          "-"
        )}
      </dd>
      <dt>
        <FormattedMessage id="accountPage.fees.list.future.transactions" /> -{" "}
        {costData ? (
          <FormattedPercentage value={costData.transactionFees} decimals={3} />
        ) : (
          "-"
        )}
      </dt>
      <dd>
        {futureData ? (
          <Amount amount={futureData.transactionFees} decimals={2} />
        ) : (
          "-"
        )}
      </dd>
      {showInsurance &&
      costData &&
      typeof costData.insurancePremium !== "undefined" ? (
        <>
          <dt>
            <FormattedMessage id="accountPage.fees.list.future.insurance" /> -{" "}
            {costData ? (
              <FormattedPercentage
                value={costData.insurancePremium}
                decimals={3}
              />
            ) : (
              "-"
            )}
          </dt>
          <dd>
            {futureData?.insurancePremium ? (
              <Amount amount={futureData.insurancePremium} decimals={2} />
            ) : (
              "-"
            )}
          </dd>
        </>
      ) : null}
      {showInsurance &&
      costData &&
      typeof costData.insuranceRiskPremium !== "undefined" ? (
        <>
          <dt>
            <FormattedMessage id="accountPage.fees.list.future.insurance-risk" />{" "}
            -{" "}
            {costData ? (
              <FormattedPercentage
                value={costData.insuranceRiskPremium}
                decimals={3}
              />
            ) : (
              "-"
            )}
          </dt>
          <dd>
            {futureData?.insuranceRiskPremium ? (
              <Amount amount={futureData.insuranceRiskPremium} decimals={2} />
            ) : (
              "-"
            )}
          </dd>
        </>
      ) : null}
      <dt className="summary-cell">
        <b>
          <FormattedMessage id="accountPage.fees.list.future.total" />
        </b>
      </dt>
      <dd className="summary-cell">
        {futureData ? <Amount amount={futureData.total} decimals={2} /> : "-"}
      </dd>
    </dl>
  );
}

export const FeesData: FunctionComponent<Props> = ({
  account,
  showAction = true,
}) => {
  const intl = useIntl();
  const [fees, setFees] = useState<AccountFeeResponse>();
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const year = useRef<number>(new Date().getFullYear());
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const load = useCallback((account: InvestmentAccount) => {
    dataFees
      .getFees(account)
      .then((data) => {
        setFees(data);
        setStatus(Status.SUCCESS);
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, []);

  const retry = useCallback(() => {
    if (!account) {
      return;
    }
    setStatus(Status.PENDING);
    setTimeout(() => {
      load(account);
    }, 600);
  }, [load, account]);

  useEffect(() => {
    if (!inView) {
      return;
    }
    if (!account) {
      return;
    }
    load(account);
  }, [inView, load, account]);

  const showInsuranceFees =
    dataAccounts.isInsuranceAccount(account) &&
    fees?.cost.insurancePremium !== undefined;
  const showPensionFees =
    isPensionAccountType(account?.type) &&
    fees?.cost.insurancePremium !== undefined;

  return (
    <section className="account-page-fees-data" ref={ref}>
      <h2>
        <FormattedMessage id="accountPage.fees.header" />
      </h2>
      <CardList>
        <Retry retry={retry} status={status}>
          <div className="account-page-padder">
            <div className="account-page-card-body">
              <strong className="account-page-card-subheader">
                <FormattedMessage id="accountPage.fees.paid" /> {year.current}
              </strong>
              {getHistoricFees(
                fees ? fees.historic : null,
                showInsuranceFees,
                showPensionFees
              )}
              {fees && fees.rebate && (
                <Snackbar type={SNACKBAR_TYPES.SUCCESS}>
                  <span>
                    <FormattedMessage
                      id="accountPage.fees.discount"
                      values={{
                        discount: intl.formatNumber(fees.rebate / 100, {
                          style: "percent",
                        }),
                        endDate: fees.rebateExpiry
                          ? intl.formatDate(fees.rebateExpiry)
                          : "-",
                      }}
                    />
                  </span>
                </Snackbar>
              )}
              <strong className="account-page-card-subheader">
                <FormattedMessage id="accountPage.fees.estimation" />{" "}
                <FormattedDate value={new Date()} year="numeric" /> -{" "}
                {fees?.cost.total ? (
                  <FormattedPercentage value={fees.cost.total} decimals={3} />
                ) : (
                  "-"
                )}
              </strong>
              {getFutureFees(
                fees ? fees.cost : null,
                fees ? fees.future : null,
                showInsuranceFees,
                showPensionFees
              )}
            </div>
            {showAction && (
              <RouteAwareToggle path={getNavLink(FEES_PAGE_URL)}>
                <CardBottom>
                  <Link to={getNavLink(FEES_PAGE_URL)}>
                    <FormattedMessage id="accountPage.fees.historical.link" />
                  </Link>
                </CardBottom>
              </RouteAwareToggle>
            )}
          </div>
        </Retry>
      </CardList>
      <Disclaimer>
        <FormattedMessage id="accountPage.fees.disclaimer" />
      </Disclaimer>
    </section>
  );
};
