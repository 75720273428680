import { useCallback, useEffect, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import { dataAccounts, SavingsAccountId } from "../data/dataAccounts";
import { dataTransfer } from "../data/dataTransfer";
import {
  CloseableAccount,
  NotCloseableAccount,
} from "./useInvestmentAccountCloseable";
import { useIsReadOnly } from "./useIsReadOnly";
import { usePendingDeposits } from "./usePendingDeposits";

type CloseableAccounts = Record<
  SavingsAccountId,
  CloseableAccount | NotCloseableAccount
>;

export function useSavingsAccountCloseable() {
  const loadPendingDeposits = usePendingDeposits();
  const [accountsCloseable, setAccountsCloseable] =
    useState<CloseableAccounts>();
  const [status, setStatus] = useState<"SUCCESS" | "LOADING" | "ERROR">(
    "LOADING"
  );
  const isReadonly = useIsReadOnly();
  const intl = useIntl();

  useEffect(() => {
    if (typeof loadPendingDeposits === "undefined") {
      return;
    }
    Promise.all([
      dataAccounts.getAllAccounts(),
      loadPendingDeposits(),
      dataTransfer.getPendingInternalTransfers(),
    ])
      .then(([accounts, pendingDeposits, pendingInternalTransfers]) => {
        const accountsCloseableState: CloseableAccounts = {};
        accounts.savingsAccounts.forEach((account) => {
          const isMoneyOnAccount = account.totalBalance > 0;
          const hasPendingDeposit = pendingDeposits.some(
            (pendingDeposit) => pendingDeposit.accountId === account.accountId
          );
          const hasIncomingInternalTransfer = pendingInternalTransfers.some(
            (pendingInternalTransfer) =>
              pendingInternalTransfer.toAccountId === account.accountId
          );

          const disabledState = getSavingsAccountCloseDisabledState(
            isMoneyOnAccount,
            hasPendingDeposit,
            hasIncomingInternalTransfer,
            isReadonly,
            intl
          );
          accountsCloseableState[account.accountId] = disabledState;
        });
        setAccountsCloseable({ ...accountsCloseableState });
        setStatus("SUCCESS");
      })
      .catch(() => {
        setStatus("ERROR");
      });
  }, [intl, isReadonly, loadPendingDeposits]);

  const getSavingsAccountCloseableState = useCallback(
    (accountId: SavingsAccountId) => {
      const closeableState = accountsCloseable?.[accountId];
      if (typeof closeableState === "undefined") {
        throw new Error(
          "getSavingsAccountCloseableState - no closeable state for account"
        );
      }
      return closeableState;
    },
    [accountsCloseable]
  );

  if (status === "ERROR") {
    return "ERROR";
  } else if (status === "LOADING") {
    return "LOADING";
  }

  return getSavingsAccountCloseableState;
}

export function getSavingsAccountCloseDisabledState(
  isMoneyOnAccount: boolean,
  hasPendingDeposit: boolean,
  hasIncomingInternalTransfer: boolean,
  isReadonly: boolean,
  intl: IntlShape
): CloseableAccount | NotCloseableAccount {
  const reasons: Array<string> = [];

  const isCloseable =
    !isMoneyOnAccount &&
    !hasPendingDeposit &&
    !hasIncomingInternalTransfer &&
    !isReadonly;

  if (isCloseable) {
    return { isCloseable };
  }

  if (isMoneyOnAccount) {
    reasons.push(
      intl.formatMessage({
        id: "closeSavingsAccount.reasons.isMoneyOnAccount",
      })
    );
  }
  if (hasPendingDeposit) {
    reasons.push(
      intl.formatMessage({
        id: "closeSavingsAccount.reasons.isPendingDeposit",
      })
    );
  }
  if (hasIncomingInternalTransfer) {
    reasons.push(
      intl.formatMessage({
        id: "closeSavingsAccount.reasons.hasIncomingInternalTransfer",
      })
    );
  }

  return { isCloseable, reasons };
}
