import { useCallback, VoidFunctionComponent } from "react";
import { KycQuestion } from "../../../data/dataKyc";
import { AccountKycAccountCorporationInternal } from "../../../hooks/useAccountsKyc";
import { DepositIntervalQuestion } from "../account/DepositIntervalQuestion";
import { PurposeQuestionAccountCoporation } from "../account/PurposeQuestionAccountCoporation";
import { WithdrawalIntervalQuestion } from "../account/WithdrawalIntervalQuestion";
import { Button, Card } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { KYC_ACCOUNT_UPDATE_PAGE_URL } from "../../../pages/kyc/accountsKyc/accountKycUpdate/AccountKycUpdate";
import "./AccountType.scss";

interface Props {
  accountKyc: AccountKycAccountCorporationInternal;
  setAccountKyc?: (accountKyc: AccountKycAccountCorporationInternal) => void;
  display?: boolean;
  editReturnUrl?: string;
}

export const AccountCorporationKyc: VoidFunctionComponent<Props> = ({
  accountKyc,
  setAccountKyc,
  display,
  editReturnUrl,
}) => {
  const updateData = useCallback(
    (newData: Partial<AccountKycAccountCorporationInternal>) => {
      setAccountKyc?.({ ...accountKyc, ...newData });
    },
    [accountKyc, setAccountKyc]
  );

  if (display) {
    return (
      <>
        <h3>{accountKyc.accountName}</h3>
        <Card className="account-type">
          <PurposeQuestionAccountCoporation
            value={accountKyc[KycQuestion.PURPOSE]}
            setValue={(purpose) =>
              updateData({ [KycQuestion.PURPOSE]: purpose })
            }
            display={display}
          />
          <DepositIntervalQuestion
            value={accountKyc[KycQuestion.DEPOSIT_INTERVAL]}
            setValue={(depositInterval) =>
              updateData({ [KycQuestion.DEPOSIT_INTERVAL]: depositInterval })
            }
            display={display}
          />
          <WithdrawalIntervalQuestion
            value={accountKyc[KycQuestion.WITHDRAWAL_INTERVAL]}
            setValue={(withdrawalInterval) =>
              updateData({
                [KycQuestion.WITHDRAWAL_INTERVAL]: withdrawalInterval,
              })
            }
            display={display}
          />
          {editReturnUrl && (
            <Button
              block
              size="small"
              className="edit-button"
              component={Link}
              to={{
                pathname: getNavLink(
                  generatePath(KYC_ACCOUNT_UPDATE_PAGE_URL, {
                    accountId: accountKyc.accountId,
                  })
                ),
                search: `?returnUrl=${editReturnUrl}`,
              }}
              label={
                <FormattedMessage id="account-kyc.edit.ACCOUNT_CORPORATION" />
              }
            />
          )}
        </Card>
      </>
    );
  }

  return (
    <div>
      <h3>{accountKyc.accountName}</h3>
      <PurposeQuestionAccountCoporation
        value={accountKyc[KycQuestion.PURPOSE]}
        setValue={(purpose) => updateData({ [KycQuestion.PURPOSE]: purpose })}
        display={display}
      />
      <DepositIntervalQuestion
        value={accountKyc[KycQuestion.DEPOSIT_INTERVAL]}
        setValue={(depositInterval) =>
          updateData({ [KycQuestion.DEPOSIT_INTERVAL]: depositInterval })
        }
        display={display}
      />
      <WithdrawalIntervalQuestion
        value={accountKyc[KycQuestion.WITHDRAWAL_INTERVAL]}
        setValue={(withdrawalInterval) =>
          updateData({
            [KycQuestion.WITHDRAWAL_INTERVAL]: withdrawalInterval,
          })
        }
        display={display}
      />
    </div>
  );
};
