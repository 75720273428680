import {
  AllDocumentsAccounts,
  DocumentInformation,
  DocumentsCustomer,
} from "../../data/dataDocuments";

export const getSignedDocuments = (
  documents: DocumentInformation[],
  accounts: AllDocumentsAccounts,
  customer?: DocumentsCustomer
) => {
  const documentsByDate = [...documents].sort(
    (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
  );

  const signingTimestamps = [
    ...(customer?.created ? [new Date(customer.created).getTime()] : []),
    ...accounts.map(({ created }) => new Date(created).getTime()),
  ];

  const signedDocuments = signingTimestamps.reduce(
    (docs: DocumentInformation[], viewTime) => {
      const signedDocument = documentsByDate.find(
        (doc) => new Date(doc.created).getTime() < viewTime
      );

      if (
        signedDocument &&
        !docs.some(({ fileName }) => fileName === signedDocument.fileName)
      ) {
        docs.push(signedDocument);
      }
      return docs;
    },
    []
  );
  return signedDocuments;
};
