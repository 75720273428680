import { FormattedMessage } from "react-intl";

interface Props {
  accountName: string;
}

export const ListItemAccountName: React.VFC<Props> = ({ accountName }) => {
  return (
    <div className="list-item">
      <strong>
        <FormattedMessage id="shareAccountInviteCard.accountNameLabel" />
      </strong>
      <div className="list-item-value">{accountName}</div>
    </div>
  );
};
