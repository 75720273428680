import { useEffect, useState, useCallback, FC } from "react";
import {
  Card,
  Typography,
  STORY_ANIMATION_DURATION,
  useDebounceFn,
  ServerError,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import { TranslatedText } from "../TranslatedText";
import { Currency } from "../../context/LocalizationContext";
import { useLocalizationContext } from "../../context/LocalizationContext";
import { AccountType, InvestmentType } from "../../data/dataAccounts";
import { dataFees, FeesEstimatedSignedIn } from "../../data/dataFees";
import { useAccounts } from "../../hooks/useAccounts";
import { ScrollIntoViewOnMount } from "./ScrollIntoViewOnMount";
import { FeesInformationEditor } from "./FeesInformationEditor";
import { FeesInformationSimulation } from "./FeesInformationSimulation";

import "./FeesInformation.scss";

interface AllocationConfig {
  defaultInvestment: number;
  monthly: number;
}

export const DEFAULT_VALUES: { [key in Currency]: AllocationConfig } = {
  [Currency.DKK]: {
    defaultInvestment: 10000,
    monthly: 200,
  },
  [Currency.EUR]: {
    defaultInvestment: 1000,
    monthly: 20,
  },
  [Currency.SEK]: {
    defaultInvestment: 10000,
    monthly: 200,
  },
};

const INITIAL_FEES = {
  future: {
    discretionary: 0,
    fundManagement: 0,
    fundAssets: 0,
    transactionFees: 0,
    total: 0,
  },
  cost: {
    discretionary: 0,
    fundManagement: 0,
    fundAssets: 0,
    transactionFees: 0,
    total: 0,
  },
};

interface Props {
  risk: number;
  investmentType: InvestmentType;
  accountType: AccountType;
  insured?: string;
}

export const FeesInformation: FC<Props> = ({
  risk,
  investmentType,
  accountType,
  insured,
}) => {
  const localizationContext = useLocalizationContext();
  const defaultInvestment =
    DEFAULT_VALUES[localizationContext.state.currency].defaultInvestment;
  const defaultMonthly =
    DEFAULT_VALUES[localizationContext.state.currency].monthly;
  // Use tmpAmount to avoid calling the fees API too rapidly
  const [tmpAmount, setTmpAmount] = useState<number>(defaultInvestment);
  // When tmpAmount has settled by debounce + API response, we commit it to the actual amount
  const [amount, setAmount] = useState(tmpAmount);
  // These values are not used in the API communication, so we don't need any temporary values
  const [monthly, setMonthly] = useState<number>(defaultMonthly);
  const [expectedYield, setExpectedYield] = useState(7);
  const [horizonYears, setHorizonYears] = useState(5);
  const [apiError, setApiError] = useState<Error | null>(null);

  const [estimatedFees, setEstimatedFees] =
    useState<FeesEstimatedSignedIn>(INITIAL_FEES);
  const { accounts } = useAccounts();

  const getEstimatedFees = useCallback(
    ({
      amount,
      risk,
      investmentType,
      country,
      accountType,
      insured,
    }: Parameters<typeof dataFees.getEstimatedFeesSignedIn>[0]) => {
      dataFees
        .getEstimatedFeesSignedIn({
          amount,
          risk,
          investmentType,
          country,
          accountType,
          insured,
        })
        .then((fees) => {
          setAmount(amount);
          setEstimatedFees(fees);
        })
        .catch((error) => {
          if (error instanceof ServerError) {
            setApiError(error);
          } else {
            // Unknown error, rethrow
            throw error;
          }
        });
    },
    [setEstimatedFees, setAmount]
  );
  const debouncedGetEstimatedFees = useDebounceFn(getEstimatedFees, 200);

  useEffect(() => {
    if (!localizationContext.state.country) {
      return;
    }

    debouncedGetEstimatedFees({
      amount: tmpAmount,
      risk,
      investmentType: investmentType,
      country: localizationContext.state.country,
      accountType: accountType,
      insured: insured,
    });
  }, [
    risk,
    investmentType,
    localizationContext.state.currency,
    localizationContext.state.country,
    tmpAmount,
    accountType,
    insured,
    debouncedGetEstimatedFees,
  ]);

  // Don't render until accounts has been fetched
  if (!accounts) {
    return null;
  }

  const fees = estimatedFees || INITIAL_FEES;

  return (
    <Card className="fees-information-card">
      <ScrollIntoViewOnMount
        ifQueryParam="fees"
        afterDelay={STORY_ANIMATION_DURATION}
      />
      <Typography type="h3">
        <TranslatedText id={"feesInformation.card.header"} />
      </Typography>
      {apiError ? (
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <TranslatedText
            id="feesInformation.card.apiError"
            values={{ br: <br /> }}
          />
        </Snackbar>
      ) : (
        <>
          <FeesInformationEditor
            amount={tmpAmount}
            onAmountChange={setTmpAmount}
            monthly={monthly}
            onMonthlyChange={setMonthly}
            horizonYears={horizonYears}
            onHorizonYearsChange={setHorizonYears}
            expectedYield={expectedYield}
            onExpectedYieldChange={setExpectedYield}
          />
          <FeesInformationSimulation
            horizonYears={horizonYears}
            amount={amount}
            monthly={monthly}
            expectedYield={expectedYield}
            accounts={accounts}
            fees={fees}
          />
        </>
      )}
    </Card>
  );
};
