import { createContext } from "../../../context/CreateContext";

export interface SuitabilityAssessmentState {
  forceErrorsAccountsAllocation: boolean;
  directAccessGuard?: boolean;
  returnUrl?: string;
}

export const [
  SuitabilityAssessmentContextProvider,
  useSuitabilityAssessmentContext,
] = createContext<SuitabilityAssessmentState>("SuitabilityAssessmentContext", {
  forceErrorsAccountsAllocation: false,
});
