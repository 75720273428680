import { useRef, useContext, useEffect, useState } from "react";
import * as React from "react";
import AnimateHeight from "react-animate-height";
import {
  Card,
  Button,
  Form,
  LysaFormRef,
  Snackbar,
  SNACKBAR_TYPES,
  RequiredValidator,
  OtpInput,
  EmailValidator,
  EmailInput,
  OtpValidator,
  useFlash,
  Spinner,
} from "@lysaab/ui-2";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { LocalizationContext } from "../../context/LocalizationContext";
import {
  dataLegalEntity,
  EmailVerificationCodeResult,
} from "../../data/dataLegalEntity";
import { Page, PageHeader } from "../Page";
import "./ChangeEmailPage.scss";
import { UserContext } from "../../context/UserContext";
import { useHistory } from "react-router";
import { useInterval } from "../../hooks/useInterval";
import { MailStatus, mailStatus } from "../../data/dataMail";

const messages = defineMessages({
  label_email: {
    id: "change-email.email.label",
    description: "Email input label",
    defaultMessage: "Email",
  },
  email_required_msg: {
    id: "change-email.email.req",
    description: "Email required validator message",
    defaultMessage: "You must enter an email address",
  },
  email_valid_msg: {
    id: "change-email.email.valid",
    description: "Email format validator message",
    defaultMessage: "You must enter a valid email address",
  },
  email_suggestion: {
    id: "change-email.email.suggestion",
    description: "Email suggestion text",
    defaultMessage:
      "It looks like you might have misspelled the email address. Did you mean {suggestion}?",
  },
  label_otp: {
    id: "change-email.otp.label",
    description: "Otp input label",
    defaultMessage: "Email verification code",
  },
  otp_required_msg: {
    id: "change-email.otp.req",
    description: "Otp required validator message",
    defaultMessage: "You must enter a verification code",
  },
  otp_valid_msg: {
    id: "change-email.otp.valid",
    description: "Otp format validator message",
    defaultMessage: "You must enter a valid verification code",
  },
  error_not_valid: {
    id: "change-email.otp.response.not_valid",
    description: "Server said the OTP was invalid",
    defaultMessage:
      "Invalid code. Make sure you use the latest code from us, if you got multiple.",
  },
  error_expired: {
    id: "change-email.otp.response.expired",
    description: "Server said the OTP had expired",
    defaultMessage: 'Expired code. Click "Send verification code" again.',
  },
  error_invalid_state: { id: "change-email.error.invalid_state" },
  error_finalize: { id: "change-email.error.finalize" },
  error_send: { id: "change-email.error.send" },
  change_email_success: {
    id: "change-email.success",
    defaultMessage: "Email successfully changed",
  },
  noCurrentEmail: {
    id: "change-email.no-current-email",
    defaultMessage: "(You don't have an email adress registered)",
  },
});

export const CHANGE_EMAIL_PAGE_URL = "/profile/change-email";

export const ChangeEmailPage: React.VFC = () => {
  const intl = useIntl();
  const history = useHistory();
  const localizationContext = useContext(LocalizationContext);
  const formRef = useRef<LysaFormRef>();
  const formRef2 = useRef<LysaFormRef>();
  const isExpired = useRef<boolean>(true);
  const confirmationIdRef = useRef<string>("");
  const emailRef = useRef<HTMLInputElement>(null);
  const [mailStatusId, setMailStatusId] = useState("");
  const [email, setEmail] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [totp, setTotp] = useState("");
  const [error, setError] = useState("");
  const [sendError, setSendError] = useState("");
  const pushFlash = useFlash();
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);

  useInterval(
    () => {
      mailStatus(mailStatusId).then(({ state }) => {
        if (state === MailStatus.ERROR) {
          setSendError(intl.formatMessage(messages.error_send));
        }
      });
    },
    mailStatusId ? 1000 : null
  );

  useEffect(() => {
    dataLegalEntity
      .getProfileSettings()
      .then((settings) => {
        setCurrentEmail(settings.contactDetails.email);
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (!confirmationIdRef.current && !email) {
      const search = new URLSearchParams(history.location.search);

      const confirmationId = search.get("confirmationId");
      const email = search.get("email");

      if (confirmationId && email) {
        isExpired.current = false;
        confirmationIdRef.current = confirmationId;
        setEmail(decodeURIComponent(email));

        return;
      }
    }
  }, [email, history.location.search]);

  const saveReset = (signedEmail: string) => {
    if (!signedEmail) {
      pushFlash({
        text: intl.formatMessage(messages.error_invalid_state),
        type: SNACKBAR_TYPES.ERROR,
        timer: 3000,
      });

      return;
    }

    dataLegalEntity
      .finalizeEmailChange({ signedEmail })
      .then(() => {
        setCurrentEmail(email);

        // We expire the cookie in one year + one month. We ask the customers to
        // login at least once a year to fill out the yearly-review, so this should
        // be long enough for them to login again and refresh the cookie
        const maxAge = 60 * 60 * 24 * 395;

        // signup.lysa.se -> lysa.se
        // signup.lysa-test.se -> lysa-test.se
        // localhost -> localhost
        const domain = window.location.hostname.split(".").slice(-2).join(".");
        document.cookie = `lysa_nets_email=${email}; domain=${domain}; path=/; max-age=${maxAge}`;

        pushFlash({
          text: intl.formatMessage(messages.change_email_success),
          type: SNACKBAR_TYPES.SUCCESS,
        });
      })
      .catch((error) => {
        pushFlash({
          text: intl.formatMessage(messages.error_finalize),
          type: SNACKBAR_TYPES.ERROR,
          timer: 3000,
        });

        console.error("error", error);
      })
      .finally(() => {
        setEmail("");
        setTotp("");
        confirmationIdRef.current = "";
        history.replace(history.location.pathname);
      });
  };

  useEffect(() => {
    setTimeout(() => emailRef.current?.focus());
  }, []);

  return (
    <Page className="change-email-page">
      <PageHeader>
        <h1>
          <FormattedMessage id="change-email.page.header" />
        </h1>
      </PageHeader>

      {isLoading ? (
        <Spinner />
      ) : (
        <div className="content-wrapper">
          <Form
            lysaFormRef={formRef}
            onSubmit={(event) => {
              event.preventDefault();
              setMailStatusId("");
              setSendError("");

              if (
                isExpired.current &&
                formRef.current?.isValid &&
                localizationContext.state.country
              ) {
                dataLegalEntity
                  .initEmailChange({
                    language: localizationContext.state.language,
                    country: localizationContext.state.country,
                    email,
                  })
                  .then(({ confirmationId, statusId }) => {
                    setMailStatusId(statusId);
                    isExpired.current = false;
                    confirmationIdRef.current = confirmationId;
                    setError("");
                    setTotp("");
                    history.replace({
                      pathname: history.location.pathname,
                      search: `?confirmationId=${confirmationId}&email=${encodeURIComponent(
                        email
                      )}`,
                    });
                  })
                  .catch(() => {
                    setSendError(intl.formatMessage(messages.error_send));
                  });
              }
            }}
          >
            <Card>
              <h2>
                <FormattedMessage
                  id="change-email.new-email.email.header"
                  defaultMessage="Your email"
                />
              </h2>
              <p>
                <FormattedMessage
                  id="change-email.new-email.email.description"
                  defaultMessage={
                    "To communicate with you we need a valid email address. " +
                    "Your current address is <b>{email}</b>. If you want to change it " +
                    "you can do so below. Please enter your new email address " +
                    "and click the button to send a verification code."
                  }
                  values={{
                    email:
                      currentEmail ||
                      intl.formatMessage(messages.noCurrentEmail),
                    b: (text) => <strong>{text}</strong>,
                  }}
                />
              </p>
              <EmailInput
                label={intl.formatMessage(messages.label_email)}
                value={email}
                ref={emailRef}
                onChange={(email) => {
                  if (!isExpired.current) {
                    isExpired.current = true;
                  }
                  setEmail(email);
                }}
                suggestionMessage={intl.formatMessage(
                  messages.email_suggestion,
                  {
                    // This replacement is made inside <EmailInput>, so pass it along
                    suggestion: "{suggestion}",
                  }
                )}
                validators={[
                  new RequiredValidator(
                    intl.formatMessage(messages.email_required_msg)
                  ),
                  new EmailValidator(
                    intl.formatMessage(messages.email_valid_msg)
                  ),
                ]}
              />
              {sendError && <p className="send-error">{sendError}</p>}
              {!userContext.state.readOnly ? (
                <Button
                  block
                  type="submit"
                  label={
                    <FormattedMessage id="change-email.verifyemail.button.send_code" />
                  }
                />
              ) : (
                <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                  <FormattedMessage
                    id="change-email.verifyemail.read_only.error"
                    description="Corporation non-admin warning"
                    defaultMessage="Unfortunately you can not change your email-address"
                  />
                </Snackbar>
              )}
            </Card>
          </Form>

          <AnimateHeight
            key={confirmationIdRef.current}
            duration={300}
            height={confirmationIdRef.current ? "auto" : 0}
            animateOpacity
          >
            <Form
              lysaFormRef={formRef2}
              onSubmit={(event) => {
                event.preventDefault();

                if (formRef2.current?.isValid) {
                  dataLegalEntity
                    .confirmEmailCode({
                      confirmationId: confirmationIdRef.current,
                      code: totp,
                    })
                    .then((response) => {
                      if (response.result === EmailVerificationCodeResult.OK) {
                        saveReset(response.signedEmail);
                      } else if (
                        response.result === EmailVerificationCodeResult.EXPIRED
                      ) {
                        setError(intl.formatMessage(messages.error_expired));
                        setTotp("");
                        isExpired.current = true;
                      } else {
                        setError(intl.formatMessage(messages.error_not_valid));
                      }
                    });
                }
              }}
            >
              <Card>
                <h2>
                  <FormattedMessage
                    id="change-email.verifyemail.code.header"
                    defaultMessage="Verification code"
                  />
                </h2>
                <p>
                  <FormattedMessage
                    id="change-email.verifyemail.code.description"
                    defaultMessage={
                      "Within a few minutes you should get a verification code " +
                      "to the email address you provided above. Please enter " +
                      "the code below."
                    }
                  />
                </p>
                <OtpInput
                  key={confirmationIdRef.current}
                  label={intl.formatMessage(messages.label_otp)}
                  value={totp}
                  onChange={(totp) => {
                    setTotp(totp);
                    setError("");
                  }}
                  validators={[
                    new RequiredValidator(
                      intl.formatMessage(messages.otp_required_msg)
                    ),
                    new OtpValidator(
                      intl.formatMessage(messages.otp_valid_msg)
                    ),
                  ]}
                />
              </Card>

              {error && (
                <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                  {error}
                </Snackbar>
              )}

              <Button
                block
                type="submit"
                label={
                  <FormattedMessage id="change-email.verifyemail.button.save" />
                }
              />
            </Form>
          </AnimateHeight>
        </div>
      )}
    </Page>
  );
};
