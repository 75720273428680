import { useRouteMatch, matchPath } from "react-router-dom";
import { ROUTES } from "../TopLevelRouter";

const NO_MATCH_URL = "";

export interface DynamicValues {
  [key: string]: string;
}

export function useCountryUrl() {
  const match = useRouteMatch<{ country: string }>({ path: "/:country" });
  const country = match?.params.country ?? "";
  return getCountryPrefix(country);
}

export function getDynamicLink(
  dynamicUrl: string,
  dynamicValues: DynamicValues
) {
  let url = dynamicUrl;
  Object.keys(dynamicValues).forEach((key) => {
    const regEx = new RegExp(`:${key}`, "gi");
    url = url.replace(regEx, dynamicValues[key]);
  });
  return getNavLink(url);
}

export function getNavLink(pageUrl: string) {
  return `${getLinkPrefix()}${pageUrl}`;
}

export function getLinkPrefix(pathname: string = window.location.pathname) {
  const match = matchPath<{ country: string }>(pathname, { path: "/:country" });
  const country = match?.params.country ?? "";
  return getCountryPrefix(country);
}

function getCountryPrefix(country: string) {
  if (!country || !Object.values(ROUTES).includes(`/${country}`)) {
    return NO_MATCH_URL;
  }
  return `/${country}`;
}
