import { RefObject, VoidFunctionComponent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  RequiredValidator,
  HiddenInput,
  Typography,
} from "@lysaab/ui-2";
import { CardPaneListItem } from "../../../../components/cardPane/CardPaneListItem";
import { useAccountName } from "../../../../hooks/useAccountName";
import { UpdateInvestmentAccountComposition } from "../AccountAdviceCard";
import { AccountUpdateAction } from "../../../../pages/reviewAccount/ReviewAccountContext";

interface Props {
  containerRef: RefObject<HTMLDivElement>;
  account: UpdateInvestmentAccountComposition;
  updateAccount: (accountAction: AccountUpdateAction) => void;
}

export const ReviewUnmatchedEsg: VoidFunctionComponent<Props> = ({
  containerRef,
  account,
  updateAccount,
}) => {
  const intl = useIntl();
  const accountName = useAccountName(account.accountId, true);

  return (
    <CardPaneListItem>
      <Typography type="body">
        <FormattedMessage id="summary.accountCardButtons.ReviewUnmatchedEsg.reason" />
      </Typography>

      <Button
        block
        onClick={() => updateAccount(AccountUpdateAction.ADVICE)}
        label={
          <FormattedMessage id="summary.accountCardButtons.ReviewUnmatchedEsg.advice" />
        }
      />

      <HiddenInput
        label={accountName}
        scrollToRef={containerRef}
        validators={[
          new RequiredValidator(
            intl.formatMessage({
              id: "summary.accountCardButtons.ReviewUnmatchedEsg.error.required",
            })
          ),
        ]}
      />
    </CardPaneListItem>
  );
};
