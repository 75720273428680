import {
  API,
  encode,
  BankIdStandardResponses,
  BankIdInitResponse,
  OrderRef,
} from "@lysaab/ui-2";

const BASE_URL = "/bankid";

export interface QrCode {
  code: string;
}

export const dataBankid = {
  login: () => {
    return API.post<BankIdInitResponse>(
      BASE_URL + encode`/login?hash=${process.env.REACT_APP_GIT_HASH}`
    );
  },
  qrCode: (orderRef?: OrderRef) => {
    if (!orderRef) {
      return Promise.resolve(undefined);
    }

    return API.get<QrCode>(BASE_URL + encode`/login/qr/${orderRef}`, true);
  },
  loginPoll: (orderRef: OrderRef) => {
    return API.get<BankIdStandardResponses>(
      BASE_URL +
        encode`/login/${orderRef}?hash=${process.env.REACT_APP_GIT_HASH}`,
      true
    );
  },
};
