import {
  Alternative,
  Card,
  SegmentedSelect,
  Select,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import { Fragment, useContext, useEffect, useState } from "react";
import { dataFees, HistoricFeeResponse } from "../../../data/dataFees";
import { defineMessages, useIntl } from "react-intl";
import { Currency, useCurrency } from "../../../context/LocalizationContext";
import {
  GridRow,
  JustifyContentValues,
} from "../../../components/grid/gridRow/GridRow";
import { DateTime } from "luxon";
import AnimateHeight from "react-animate-height";
import { GridCol } from "../../../components/grid/gridCol/GridCol";
import { FeesContext, FeesContextProvider } from "./context/FeesContext";
import { TranslatedText } from "../../../components/TranslatedText";
import { dataLegalEntity } from "../../../data/dataLegalEntity";
import { RebateTable } from "./rebate/RebateTable";
import { AccountTable } from "./accountTables/AccountTable";
import { SummaryTable } from "./summary/SummaryTable";
import { ShowMoreButton } from "./components/showMoreButton/ShowMoreButton";
import { SummarySavingsAccountsTable } from "./summary/SummarySavingsAccountsTable";
import { ActiveSavingsAccountTable } from "./savingsAccountTables/ActiveSavingsAccountTable";
import { ClosedSavingsAccountTable } from "./savingsAccountTables/ClosedSavingsAccountTable";

import "./FeeTables.scss";

interface MessageWithId {
  id: string;
}
interface ClosedAccount {
  created: string;
  closed: string;
}

const CurrencyMessages = defineMessages<
  Currency,
  MessageWithId,
  Record<Currency, MessageWithId>
>({
  [Currency.DKK]: {
    id: "fees.feeTables.currency.DKK",
  },
  [Currency.EUR]: {
    id: "fees.feeTables.currency.EUR",
  },
  [Currency.SEK]: {
    id: "fees.feeTables.currency.SEK",
  },
});

const recentToOldest = (accountA: ClosedAccount, accountB: ClosedAccount) =>
  DateTime.fromISO(accountB.created).diff(DateTime.fromISO(accountA.created))
    .milliseconds;

export const FeesInternal = () => {
  const intl = useIntl();
  const currency = useCurrency();
  const feesContext = useContext(FeesContext);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState<Alternative<number>>();
  const [feeData, setFeeData] = useState<HistoricFeeResponse>();
  const [yearsAlts, setYearsAlts] = useState<Alternative<number>[]>([]);
  const [error, setError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSavingsSummaryOpen, setIsSavingsSummaryOpen] = useState(false);

  const toggleAlts = [
    {
      text: intl.formatMessage(CurrencyMessages[currency]),
      value: false,
    },
    {
      text: intl.formatMessage({
        id: "fees.feeTables.percent",
      }),
      value: true,
    },
  ];

  // Get and set options for available years based on accounts creation dates.
  useEffect(() => {
    dataLegalEntity.getLegalEntity().then((data) => {
      const alts: Alternative<number>[] = [];
      const createdYear = DateTime.fromISO(data.created).year;
      const nowYear = DateTime.local().year;
      for (let year = createdYear; year < nowYear; year++) {
        alts.push({ text: year.toString(), value: year });
      }
      setYearsAlts(alts);
    });
  }, [selectedYear]);

  // Get, sort and set data for historical fees
  useEffect(() => {
    async function fetchData(year: number) {
      setIsLoading(true);
      setError(false);

      try {
        const historicalFees = await dataFees.getHistoricFees(year);

        historicalFees.accounts.sort((a, b) => b.total - a.total);
        historicalFees.closedAccounts.sort((a, b) => b.total - a.total);
        historicalFees.closedSavingsAccounts.sort(recentToOldest);

        setFeeData(historicalFees);
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    }

    if (selectedYear) {
      fetchData(selectedYear.value);
    }
  }, [selectedYear]);

  const showSummary =
    feeData?.summary || feeData?.savingsSummary?.avgDiscretionaryCost;

  if (isLoading) return <Spinner />;

  return (
    <div className="fee-tables">
      <Card className="select">
        <GridRow justifyContent={JustifyContentValues.SPACE_BETWEEN}>
          <GridCol xsmall={12} medium={6}>
            <Select
              placeholder={intl.formatMessage({
                id: "fees.feeTables.selectPlaceholder",
              })}
              alternatives={yearsAlts}
              value={selectedYear}
              onChange={(value) => setSelectedYear(value)}
              label={intl.formatMessage({
                id: "fees.feeTables.selectLabel",
              })}
            />
          </GridCol>
          <GridCol xsmall={3}>
            <SegmentedSelect
              alternatives={toggleAlts}
              value={feesContext.state.isPercent}
              onChange={(value) => feesContext.setState({ isPercent: value })}
            />
          </GridCol>
        </GridRow>

        {error && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="fees.feeTables.error" />
          </Snackbar>
        )}
      </Card>

      {!error && (
        <>
          {showSummary && (
            <Card className="summary-table">
              {feeData?.summary && (
                <div>
                  <Typography type="h2">
                    <TranslatedText id="fees.feeTables.summaryHeader" />
                  </Typography>
                  <SummaryTable summary={feeData.summary} />
                </div>
              )}

              {selectedYear && (
                <Typography type="body">
                  <ShowMoreButton
                    isOpen={isOpen}
                    onClick={() => setIsOpen(!isOpen)}
                    openText={intl.formatMessage({
                      id: "fees.feeTables.openButtonText",
                    })}
                    closedText={intl.formatMessage({
                      id: "fees.feeTables.closeButtonText",
                    })}
                  />
                </Typography>
              )}
              <AnimateHeight height={isOpen ? "auto" : 0} animateOpacity>
                <Typography type="body">
                  <TranslatedText id="fees.feeTables.ingress" />
                </Typography>
                <Typography type="body">
                  <TranslatedText id="fees.feeTables.discretionaryFee" />
                </Typography>
                <Typography type="body">
                  <TranslatedText id="fees.feeTables.managementfee" />
                </Typography>
                <Typography type="body">
                  <TranslatedText id="fees.feeTables.underlyingManagementFee" />
                </Typography>
                <Typography type="body">
                  <TranslatedText id="fees.feeTables.transactionFee" />
                </Typography>
              </AnimateHeight>

              {feeData.savingsSummary &&
                feeData.savingsSummary.paidDiscretionaryFee > 0 && (
                  <>
                    <div>
                      <Typography type="h2">
                        <TranslatedText id="fees.feeTables.summarySavingsHeader" />
                      </Typography>
                      <SummarySavingsAccountsTable
                        summary={feeData.savingsSummary}
                      />
                    </div>
                    <Typography type="body">
                      <ShowMoreButton
                        isOpen={isSavingsSummaryOpen}
                        onClick={() =>
                          setIsSavingsSummaryOpen(!isSavingsSummaryOpen)
                        }
                        openText={intl.formatMessage({
                          id: "fees.feeTables.openButtonText",
                        })}
                        closedText={intl.formatMessage({
                          id: "fees.feeTables.closeButtonText",
                        })}
                      />
                    </Typography>
                    <AnimateHeight
                      height={isSavingsSummaryOpen ? "auto" : 0}
                      animateOpacity
                    >
                      <Typography type="body">
                        <TranslatedText id="fees.feeTables.savingsAccounts.discretionaryFee" />
                      </Typography>
                    </AnimateHeight>
                  </>
                )}
            </Card>
          )}

          {feeData?.accounts && feeData?.accounts.length > 0 && (
            <Fragment>
              <Typography type="h2">
                <TranslatedText id="fees.feeTables.activeAccountHeader" />
              </Typography>
              <Card>
                <AccountTable accountsData={feeData.accounts} />
              </Card>
            </Fragment>
          )}

          {feeData?.closedAccounts && feeData?.closedAccounts.length > 0 && (
            <Fragment>
              <Typography type="h2">
                <TranslatedText id="fees.feeTables.closedAccountHeader" />
              </Typography>
              <Card>
                <AccountTable accountsData={feeData.closedAccounts} />
              </Card>
            </Fragment>
          )}

          {feeData?.savingsAccounts && feeData?.savingsAccounts.length > 0 && (
            <Fragment>
              <Typography type="h2">
                <TranslatedText id="fees.feeTables.activeSavingsAccountHeader" />
              </Typography>
              <Card>
                <ActiveSavingsAccountTable
                  accountsData={feeData.savingsAccounts}
                />
              </Card>
            </Fragment>
          )}

          {feeData?.closedSavingsAccounts &&
            feeData?.closedSavingsAccounts.length > 0 && (
              <Fragment>
                <Typography type="h2">
                  <TranslatedText id="fees.feeTables.closedSavingsAccountHeader" />
                </Typography>
                <Card>
                  <ClosedSavingsAccountTable
                    accountsData={feeData.closedSavingsAccounts}
                  />
                </Card>
              </Fragment>
            )}

          {feeData?.rebates && feeData.rebates.length > 0 && (
            <Fragment>
              <Typography type="h2">
                <TranslatedText id="fees.feeTables.rebateHeader" />
              </Typography>
              <Card>
                <RebateTable rebates={feeData.rebates} />
              </Card>
            </Fragment>
          )}
        </>
      )}
    </div>
  );
};

export const FeeTables = () => (
  <FeesContextProvider>
    <FeesInternal />
  </FeesContextProvider>
);
