import { useEffect, useState, useContext } from "react";
import { Spinner } from "@lysaab/ui-2";
import { useRouteMatch, useHistory } from "react-router";
import { dataLogin } from "../../data/dataLogin";
import { UserContext } from "../../context/UserContext";

export const IMPERSONATION_PAGE_URL = "/impersonation/:token";

interface MatchParams {
  token: string;
}

export function ImpersonationPage() {
  const match = useRouteMatch<MatchParams>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    dataLogin
      .impersonateUser(match.params.token)
      .then(() => {
        window.location.href = "/";
      })
      .catch(() => {
        history.push("/");
      });
  }, [history, isLoading, match.params.token, userContext]);

  return (
    <div className="impersonation-page">
      <Spinner />
    </div>
  );
}
