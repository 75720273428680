import * as React from "react";
import { Link } from "react-router-dom";
import { CardList, Typography } from "@lysaab/ui-2";
import { CardBottom } from "../../CardBottom";
import { TranslatedText } from "../../../../components/TranslatedText";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { MESSAGES_PAGE_URL } from "../../../messages/MessagesPage";
import { HideIfReadOnly } from "../../../../components/hideIfReadOnly/HideIfReadOnly";

export const PensionBeneficiary: React.FC = () => {
  return (
    <section className="account-page-pension-beneficiary">
      <Typography type="h2">
        <TranslatedText id="account.pension-beneficiary.header" />
      </Typography>
      <CardList>
        <div className="account-page-card-body">
          <Typography>
            <TranslatedText id="account.pension-beneficiary.body" />
          </Typography>
          <Typography>
            <TranslatedText id="account.pension-beneficiary.beneficiaries" />
          </Typography>
          <Typography>
            <TranslatedText id="account.pension-beneficiary.change" />
          </Typography>
        </div>
        <HideIfReadOnly>
          <CardBottom>
            <Link to={getNavLink(MESSAGES_PAGE_URL)}>
              <TranslatedText id="account.pension-beneficiary.change-button" />
            </Link>
          </CardBottom>
        </HideIfReadOnly>
      </CardList>
    </section>
  );
};
