import { LysaLink } from "@lysaab/ui-2";
import * as React from "react";
import { TransparentButton } from "../transparentButton/TransparentButton";
import { client as elevioClient } from "elevio";

interface Props {
  articleId: string | number;
  linkText: React.ReactNode;
}

export const ElevioLink: React.VFC<Props> = ({ articleId, linkText }) => {
  return (
    <LysaLink
      component={TransparentButton}
      onClick={() => elevioClient.openArticle("" + articleId)}
    >
      {linkText}
    </LysaLink>
  );
};
