import { FunctionComponent, useContext } from "react";
import * as React from "react";
import { RouteContext } from "./RouteContext";

interface Props {
  path: string;
}

export const RouteAwareToggle: FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ path, children }) => {
  const routeContext = useContext(RouteContext);
  const exists = routeContext.exists(path);

  if (!exists) {
    return null;
  }

  return <React.Fragment>{children}</React.Fragment>;
};
