import {
  VoidFunctionComponent,
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext,
} from "react";

import * as React from "react";
import { Form, LysaFormRef, Button, Spinner, Story } from "@lysaab/ui-2";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { AccountKyc } from "../../../../components/kyc/accountType/AccountKyc";
import { AccountCorporationKyc } from "../../../../components/kyc/accountType/AccountCorporationKyc";
import { InvestmentAccountId } from "../../../../data/dataAccounts";
import { AccountKycType, dataKyc } from "../../../../data/dataKyc";
import {
  AccountKycAccountCorporationInternal,
  AccountKycAccountInteral,
  AccountKycInternal,
  AccountKycSavingsAccountInternal,
  mapAccountKycToExternal,
  useAccountKyc,
} from "../../../../hooks/useAccountsKyc";
import { useHistory, useParams } from "react-router";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { KYC_PAGE_URL } from "../../KycViewPage";
import { useSafeNavigation } from "../../../../hooks/useSafeNavigation";
import { PageStripped } from "../../../PageStripped";
import { OVERVIEW_PAGE_URL } from "../../../overview/OverviewPage";
import { SavingsAccountKyc } from "../../../../components/kyc/accountType/SavingsAccountKyc";
import { UserContext } from "../../../../context/UserContext";

export const KYC_ACCOUNT_UPDATE_PAGE_URL = "/kyc/account/:accountId";

const messages = defineMessages({
  header: {
    id: "account-kyc.edit.header",
  },
  ariaProgressLabel: {
    id: "account-kyc.edit.ariaProgressLabel",
  },
});

const typeMapping: Record<
  AccountKycType,
  (
    accountKyc: AccountKycInternal,
    setAccountKyc: (accountKyc: Partial<AccountKycInternal>) => void
  ) => React.ReactElement
> = {
  [AccountKycType.ACCOUNT]: (accountKyc, setAccountKyc) => (
    <AccountKyc
      key={accountKyc.accountId}
      accountKyc={accountKyc as AccountKycAccountInteral}
      setAccountKyc={setAccountKyc}
      editReturnUrl={getNavLink(KYC_PAGE_URL)}
    />
  ),
  [AccountKycType.SAVINGS_ACCOUNT]: (accountKyc, setAccountKyc) => (
    <SavingsAccountKyc
      key={accountKyc.accountId}
      accountKyc={accountKyc as AccountKycSavingsAccountInternal}
      setAccountKyc={setAccountKyc}
      editReturnUrl={getNavLink(KYC_PAGE_URL)}
    />
  ),
  [AccountKycType.ACCOUNT_CORPORATION]: (accountKyc, setAccountKyc) => (
    <AccountCorporationKyc
      key={accountKyc.accountId}
      accountKyc={accountKyc as AccountKycAccountCorporationInternal}
      setAccountKyc={setAccountKyc}
      editReturnUrl={getNavLink(KYC_PAGE_URL)}
    />
  ),
};

export const AccountKycUpdate: VoidFunctionComponent = () => {
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();
  const history = useHistory();
  const safeNavigation = useSafeNavigation();
  const { accountId } = useParams<{ accountId: InvestmentAccountId }>();
  const [loading, setLoading] = useState(false);
  const [accountsKyc, setAccountsKyc] = useAccountKyc();
  const search = new URLSearchParams(history.location.search);
  const { state: user } = useContext(UserContext);

  let returnPath = getNavLink(OVERVIEW_PAGE_URL);
  if (search.has("returnUrl")) {
    returnPath = search.get("returnUrl") ?? returnPath;
  }

  useEffect(() => {
    if (!accountId) {
      safeNavigation(returnPath);
    }
  }, [accountId, returnPath, safeNavigation]);

  const onSubmit = useCallback(() => {
    if (user.readOnly) {
      return;
    }
    if (formRef.current?.isValid && accountsKyc) {
      setLoading(true);
      const accountKyc = accountsKyc?.find(
        (accountKyc) => accountKyc.accountId === accountId
      );

      if (!accountKyc) {
        setLoading(false);
        return;
      }

      return dataKyc
        .saveAccountKyc(
          accountKyc.accountId,
          mapAccountKycToExternal(accountKyc)
        )
        .then(() => {
          safeNavigation(returnPath);
        })
        .finally(() => setLoading(false));
    }
  }, [accountId, accountsKyc, returnPath, safeNavigation, user.readOnly]);

  const accountKyc = accountsKyc?.find(
    (accountKyc) => accountKyc.accountId === accountId
  );

  if (loading || !accountsKyc || !accountKyc) {
    return <Spinner />;
  }

  return (
    <PageStripped>
      <Story
        ariaLabelProgress={() =>
          intl.formatMessage(messages.ariaProgressLabel, {
            current: 1,
            total: 1,
          })
        }
        header={intl.formatMessage(messages.header)}
        progress={100}
        showBack={true}
        showClose={true}
        transitionKey="notNeeded"
        onExit={() => {
          safeNavigation(getNavLink(KYC_PAGE_URL));
        }}
        onBack={history.goBack}
      >
        <Form lysaFormRef={formRef} onSubmit={onSubmit}>
          {typeMapping[accountKyc.accountKycType](accountKyc, setAccountsKyc)}
          <Button
            type="submit"
            block
            label={<FormattedMessage id="account-kyc.edit.save" />}
          />
        </Form>
      </Story>
    </PageStripped>
  );
};
