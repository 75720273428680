import { useExperiment } from "@lysaab/shared";
import { experimentConfig, experimentKeys } from "../../experimentConfig";
import { useIsPerson } from "../../hooks/useIsPerson";

const defaultMinDepositString =
  experimentConfig[experimentKeys.MIN_DEPOSIT].defaultValue;
const defaultMinDeposit = parseInt(defaultMinDepositString, 10);

export function useMinDepositAmount() {
  // For simplicities sake, we fetch a value at login even for companies, but
  // this makes sure we never expose or use it, instead we use the default value
  const isPerson = useIsPerson();

  const { value: minDepositAmountString } = useExperiment({
    propertyKey: experimentKeys.MIN_DEPOSIT,
    disableExposure: !isPerson,
  });

  const minDepositAmount = parseInt(minDepositAmountString, 10);

  if (!isPerson || isNaN(minDepositAmount)) {
    return defaultMinDeposit;
  }

  return minDepositAmount;
}
