import { useCallback, useMemo } from "react";
import * as React from "react";

import {
  BankIDStatus,
  FailedHintCode,
  OrderRef,
  PendingHintCode,
  useBankId,
  useDisablePollInBackground,
} from "@lysaab/ui-2";
import { useHistory, useLocation } from "react-router-dom";
import { Location } from "history";
import "./LysaSign.scss";
import {
  dataLife,
  InitiateSigningRequest,
  InsuranceCreationToken,
} from "../../../../../../data/dataLife";
import { IntlShape, useIntl } from "react-intl";
import {
  signFailedMessages,
  signingMessages,
  signPendingMessages,
} from "./BankIdMessages";
import { parse, stringify } from "query-string";
import { ROUTES } from "../CreateAccountCorporationPage";

interface SearchParams {
  orderRef?: OrderRef;
  creationToken?: InsuranceCreationToken;
  at?: string;
}

export type CreateKFSignOnCompleteType = (
  response: { creationToken: InsuranceCreationToken },
  reset: () => void
) => void;

export const useCreateKFSignBankId = (
  onComplete: CreateKFSignOnCompleteType
) => {
  const history = useHistory();
  const location = useLocation();
  const urlParams = getParamsFromUrl(location);
  const { orderRef, autoStartToken, creationToken } = urlParams;

  const qrCodePollFn = useCallback(
    () => dataLife.pollQrCode(orderRef),
    [orderRef]
  );

  const pollFnRaw = useMemo(() => {
    return orderRef && creationToken
      ? () => dataLife.pollSigning(creationToken, orderRef)
      : undefined;
  }, [orderRef, creationToken]);
  const pollFn = useDisablePollInBackground(pollFnRaw);

  const { initiate, pollStatus, ...rest } = useBankId({
    onComplete,
    initPollFn: dataLife.initiateSigning,
    pollFn,
    qrCodePollFn,
  });

  const startSign = useCallback(
    (creationToken: InsuranceCreationToken, data: InitiateSigningRequest) => {
      if (pollStatus === "IDLE" || pollStatus === "FAILED") {
        initiate(creationToken, data)
          .then((response) => {
            if (response) {
              history.push(
                getUrlWithParams(
                  location,
                  response.orderRef,
                  creationToken,
                  response.autoStartToken
                )
              );
            }
          })
          .catch(() => {
            // If we get an error while initiating, we still want to transition
            // to the next step so BankIDStatus can show the error message.
            history.push(ROUTES.LYSA_SIGN);
          });
      }
    },
    [history, initiate, location, pollStatus]
  );

  return { startSign, pollStatus, autoStartToken, creationToken, ...rest };
};

type Props = ReturnType<typeof useCreateKFSignBankId>;

export const LysaSign: React.FunctionComponent<Props> = ({
  autoStartToken,
  latestResponse,
  qrCode,
  setOpenOnOtherDevice,
}) => {
  const history = useHistory();
  const intl = useIntl();

  return (
    <div className="create-account-corporation-lysa-sign">
      <BankIDStatus
        qrCode={qrCode}
        setOpenOnOtherDevice={setOpenOnOtherDevice}
        retry={() => {
          history.goBack();
        }}
        response={latestResponse}
        getMessages={getMessages(intl)}
        getPendingMessages={getPendingMessages(intl)}
        getFailedMessages={getFailedMessages(intl)}
        autoStartToken={autoStartToken}
      />
    </div>
  );
};

function getMessages(intl: IntlShape) {
  return () => {
    return {
      qrInfo1: intl.formatMessage(signingMessages.qrInfo1),
      qrInfo2: intl.formatMessage(signingMessages.qrInfo2),
      qrInfo3: intl.formatMessage(signingMessages.qrInfo3),
      buttonOpen: intl.formatMessage(signingMessages.buttonOpen),
      buttonErrorHeader: intl.formatMessage(signingMessages.buttonErrorHeader),
      buttonRetry: intl.formatMessage(signingMessages.buttonRetry),
      buttonClose: intl.formatMessage(signingMessages.buttonClose),
      buttonOtherDevice: intl.formatMessage(signingMessages.buttonOtherDevice),
    };
  };
}

function getPendingMessages(intl: IntlShape) {
  return (hintCode: PendingHintCode) =>
    intl.formatMessage(signPendingMessages[hintCode]);
}

function getFailedMessages(intl: IntlShape) {
  return (hintCode: FailedHintCode) =>
    intl.formatMessage(signFailedMessages[hintCode]);
}

function getUrlWithParams(
  location: Location,
  orderRef: OrderRef | undefined,
  creationToken: InsuranceCreationToken | undefined,
  autoStartToken: string
) {
  const search = parse(location.search) as SearchParams;
  search.orderRef = orderRef;
  search.creationToken = creationToken;
  search.at = autoStartToken;

  return {
    pathname: ROUTES.LYSA_SIGN,
    search: stringify(search as Record<string, any>, { skipEmptyString: true }),
  };
}

function getParamsFromUrl(location: Location) {
  const search = parse(location.search) as SearchParams;
  return {
    orderRef: search.orderRef,
    autoStartToken: search.at,
    creationToken: search.creationToken,
  };
}
