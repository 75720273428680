export interface Bank {
  key: string;
  short: string;
  long: string;
  nameInKontonummer?: string;
}

export interface UnknownBank {
  key: "UNKNOWN";
  short: string;
  long: string;
}

const MAX_SHORT_NAME_LENGTH = 7;

export const banks = {
  AMFA: {
    key: "AMFA",
    long: "Svea Bank",
    short: "Svea",
    nameInKontonummer: "Svea Bank",
  },
  AVANZA: {
    key: "AVANZA",
    long: "Avanza",
    short: "Avanza",
    nameInKontonummer: "Avanza Bank",
  },
  BLUESTEP: {
    key: "BLUESTEP",
    long: "Bluestep",
    short: "Bluestep",
    nameInKontonummer: "BlueStep Finans",
  },
  BNP: {
    key: "BNP",
    long: "BNP Paribas",
    short: "BNP",
    nameInKontonummer: "BNP Paribas SA.",
  },
  CITIBANK: {
    key: "CITIBANK",
    long: "Citibank",
    short: "Citibank",
    nameInKontonummer: "Citibank",
  },
  DANSKE_BANK: {
    key: "DANSKE_BANK",
    long: "Danske Bank",
    short: "Danske",
    nameInKontonummer: "Danske Bank",
  },
  DNB: {
    key: "DNB",
    long: "DnB",
    short: "DnB",
    nameInKontonummer: "DNB Bank",
  },
  FOREX: {
    key: "FOREX",
    long: "Forex Bank",
    short: "Forex",
    nameInKontonummer: "Forex Bank",
  },
  HANDELSBANKEN: {
    key: "HANDELSBANKEN",
    long: "Handelsbanken",
    short: "Handelsb.",
    nameInKontonummer: "Handelsbanken",
  },
  ICA: {
    key: "ICA",
    long: "Ica Banken",
    short: "ICA",
    nameInKontonummer: "ICA Banken",
  },
  IKANO: {
    key: "IKANO",
    long: "Ikano Bank",
    short: "Ikano",
    nameInKontonummer: "IKANO Bank",
  },
  LANSFORSAKRINGAR: {
    key: "LANSFORSAKRINGAR",
    long: "Länsförsäkringar",
    short: "Länsf.",
    nameInKontonummer: "Länsförsäkringar Bank",
  },
  LANDSHYPOTEK: {
    key: "LANDSHYPOTEK",
    long: "Landshypotek Bank",
    short: "Landsh.",
    nameInKontonummer: "Landshypotek",
  },
  LUNAR: {
    key: "LUNAR",
    long: "Lunar Bank",
    short: "Lunar",
    nameInKontonummer: "Lunar Bank",
  },
  MARGINALEN: {
    key: "MARGINALEN",
    long: "Marginalen Bank",
    short: "Marginalen",
    nameInKontonummer: "Marginalen Bank",
  },
  NORDAX: {
    key: "NORDAX",
    long: "Nordax",
    short: "Nordax",
    nameInKontonummer: "Nordax Bank",
  },
  NORDEA: {
    key: "NORDEA",
    long: "Nordea",
    short: "Nordea",
    nameInKontonummer: "Nordea",
  },
  NORDNET: {
    key: "NORDNET",
    long: "Nordnet",
    short: "Nordnet",
    nameInKontonummer: "Nordnet Bank",
  },
  RESURS: {
    key: "RESURS",
    long: "Resurs Bank",
    short: "Resurs",
    nameInKontonummer: "Resurs Bank",
  },
  RBS: {
    key: "RBS",
    long: "RBS",
    short: "RBS", // no name in kontonummer.js
  },
  SANTANDER: {
    key: "SANTANDER",
    long: "Santander Bank",
    short: "Santander",
    nameInKontonummer: "Santander Consumer Bank",
  },
  SBAB: {
    key: "SBAB",
    long: "SBAB",
    short: "SBAB",
    nameInKontonummer: "SBAB",
  },
  SEB: {
    key: "SEB",
    long: "SEB",
    short: "SEB",
    nameInKontonummer: "SEB",
  },
  SKANDIABANKEN: {
    key: "SKANDIABANKEN",
    long: "Skandiabanken",
    short: "Skandia",
    nameInKontonummer: "Skandiabanken",
  },
  SPARBANKEN_SYD: {
    key: "SPARBANKEN_SYD",
    long: "Sparbanken Syd",
    short: "Sparb. Syd",
    nameInKontonummer: "Sparbanken Syd",
  },
  SPARBANKERNA: {
    key: "SPARBANKERNA",
    long: "Sparbankerna",
    short: "Sparb.", // no name in kontonummer.js
  },
  SWEDBANK: {
    key: "SWEDBANK",
    long: "Swedbank",
    short: "Swedbank",
    nameInKontonummer: "Swedbank",
  },
  ALANDSBANKEN: {
    key: "ALANDSBANKEN",
    long: "Ålandsbanken",
    short: "Ålandsb.",
    nameInKontonummer: "Ålandsbanken",
  },
  MOBILEPAY: {
    key: "MOBILEPAY",
    long: "MobilePay",
    short: "MobilePay",
    nameInKontonummer: "MobilePay",
  },
  NEM_ACCOUNT: {
    key: "NEM_ACCOUNT",
    long: "Your NemKonto",
    short: "Your NemKonto",
  },
  UNKNOWN: undefined,
};

function getUnknownBank(key: string): UnknownBank {
  const name = key
    .toLocaleLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
  let shortName = name;
  if (key.length > MAX_SHORT_NAME_LENGTH) {
    shortName = `${shortName.substring(0, MAX_SHORT_NAME_LENGTH)}.`;
  }

  return {
    key: "UNKNOWN",
    long: name,
    short: shortName,
  };
}

export class dataBanks {
  static getBank(name: string): Bank | UnknownBank {
    return banks[name as keyof typeof banks] || getUnknownBank(name);
  }
}
