import {
  useState,
  useRef,
  useCallback,
  useEffect,
  FunctionComponent,
  Fragment,
} from "react";
import { CompoundAccount } from "../../data/dataAccounts";
import { Retry, Status } from "../../components/retry/Retry";
import { CardList, Spinner, Typography } from "@lysaab/ui-2";
import { CardBottom } from "./CardBottom";
import { getNavLink } from "../../hooks/useCountryUrls";
import { Link } from "react-router-dom";

import "./MonthlyInternalTransfers.scss";
import { TranslatedText } from "../../components/TranslatedText";
import {
  MonthlyInternalTransfer,
  dataPeriodicInternalTransfer,
} from "../../data/dataPeriodicInternalTransfer";
import { MonthlyInternalTransferEntry } from "./MonthlyInternalTransferEntry";
import { useAccounts } from "../../hooks/useAccounts";
import { MONTHLY_INTERNAL_TRANSFER_PAGE_URL } from "../monthlyInternalTransfer/MonthlyInternalTransferStory";
import HorizontalDivider from "../../components/horizontalDivider/HorizontalDivider";
import { HideIfReadOnly } from "../../components/hideIfReadOnly/HideIfReadOnly";

interface Props {
  account: CompoundAccount | undefined;
  showAction?: boolean;
}

export const MonthlyInternalTransfers: FunctionComponent<Props> = ({
  account,
  showAction = true,
}) => {
  const { accounts } = useAccounts();
  const [monthlyInternalTransfers, setMonthlyInternalTransfers] = useState<
    MonthlyInternalTransfer[]
  >([]);
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const timer = useRef<number | undefined>();

  const loadData = useCallback((account: CompoundAccount) => {
    dataPeriodicInternalTransfer
      .getMonthlyInternalTransfers(account.accountId)
      .then((monthlyInternalTransfers) => {
        const itemsRelevantToThisAccount = monthlyInternalTransfers.filter(
          (monthlyInternalTransfer) =>
            monthlyInternalTransfer.fromAccountId === account.accountId ||
            monthlyInternalTransfer.toAccountId === account.accountId
        );
        setMonthlyInternalTransfers(itemsRelevantToThisAccount);
        setStatus(Status.SUCCESS);
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, []);

  useEffect(() => {
    if (!account) {
      return;
    }
    loadData(account);
  }, [account, loadData]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    timer.current = window.setTimeout(() => {
      if (!account) {
        return;
      }
      loadData(account);
    }, 500);
  }, [account, loadData]);

  useEffect(() => {
    return () => {
      window.clearTimeout(timer.current);
    };
  }, []);

  if (!accounts || !account) {
    return <Spinner />;
  }

  const mergedAccounts = [
    ...accounts.investmentAccounts,
    ...accounts.savingsAccounts,
  ];

  const sortedMonthlyInternalTransfers = monthlyInternalTransfers.sort(
    (a, b) => {
      if (a.nextTransferDate < b.nextTransferDate) {
        return -1;
      }
      if (a.nextTransferDate > b.nextTransferDate) {
        return 1;
      }
      return b.amount - a.amount;
    }
  );

  return (
    <section className="account-page-monthly-internal-transfers">
      <Typography type="h2">
        <TranslatedText id="accountPage.monthly-internal-transfers.header" />
      </Typography>

      <CardList>
        <Retry retry={retry} status={status}>
          <div className="account-page-padder">
            {sortedMonthlyInternalTransfers.length ? (
              sortedMonthlyInternalTransfers.map(
                (monthlyInternalTransfer, index) => (
                  <Fragment key={monthlyInternalTransfer.id + index}>
                    <MonthlyInternalTransferEntry
                      monthlyInternalTransfer={monthlyInternalTransfer}
                      accounts={mergedAccounts}
                      reloadData={() => loadData(account)}
                      index={
                        sortedMonthlyInternalTransfers.length !== 1
                          ? index + 1
                          : undefined
                      }
                    />
                    {index < monthlyInternalTransfers.length - 1 ? (
                      <HorizontalDivider />
                    ) : null}
                  </Fragment>
                )
              )
            ) : (
              <section className="no-entries">
                <TranslatedText id="accountPage.monthly-internal-transfers.noEntries" />
              </section>
            )}
            <HideIfReadOnly>
              {showAction && (
                <CardBottom>
                  <Link
                    to={{
                      pathname: getNavLink(MONTHLY_INTERNAL_TRANSFER_PAGE_URL),
                      state: { accountId: account.accountId },
                    }}
                  >
                    <TranslatedText id="accountPage.monthly-internal-transfers.add" />
                  </Link>
                </CardBottom>
              )}
            </HideIfReadOnly>
          </div>
        </Retry>
      </CardList>
    </section>
  );
};
