import { useState, useEffect } from "react";
import { Spinner, Snackbar, SNACKBAR_TYPES, Button } from "@lysaab/ui-2";
import { SwitchUserCard } from "./SwitchUserCard";
import { dataLogin, LysaRight } from "../../../data/dataLogin";
import { TranslatedText } from "../../../components/TranslatedText";

import "./SwitchUserSelection.scss";
import { Page } from "../../../pages/Page";

export const SWITCH_USER_SELECTION_PAGE_URL = "/switch-user-selection";

export function SwitchUserSelectionPage() {
  const [rights, setRights] = useState<LysaRight[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<boolean>(false);

  const loadRights = () => {
    dataLogin
      .getRights()
      .then((response) => {
        setRights(
          response.sort((a, b) => {
            if (a.active) {
              return -1;
            } else if (b.active) {
              return 1;
            }

            return a.name.localeCompare(b.name);
          })
        );

        setIsLoading(false);
      })
      .catch((e) => {
        console.warn(e);
        setError(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadRights();
  }, []);

  if (isLoading) {
    return (
      <div className="switch-user-selection">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="switch-user-selection">
        <h1>
          <TranslatedText id="switchUser.pickUser.header" defaultMessage="" />
        </h1>
        <Snackbar type={SNACKBAR_TYPES.ERROR}>
          <div className="snackbar">
            <TranslatedText id="switchUser.pickUser.error" />
          </div>
        </Snackbar>
        <Button
          size="small"
          className="retry-button"
          onClick={loadRights}
          label={<TranslatedText id="switchUser.pickUser.retry" />}
        />
      </div>
    );
  }

  return (
    <Page>
      <div className="switch-user-selection">
        <h1>
          <TranslatedText id="switchUser.pickUser.header" defaultMessage="" />
        </h1>
        {rights.map((entity) => (
          <SwitchUserCard key={entity.accessRightsId} entity={entity} />
        ))}
      </div>
    </Page>
  );
}
