import { Card, NewIcon } from "@lysaab/ui-2";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import "./ShareAccountIntroList.scss";

export const ShareAccountIntroList: React.VFC = () => {
  return (
    <div className="share-account-intro-list">
      <Card>
        <ol>
          <li>
            <div>
              <NewIcon.Check />
            </div>
            <div>
              <FormattedMessage id="shareAccountStory.introList.invitee" />
            </div>
          </li>
          <li>
            <div>
              <NewIcon.Profile />
            </div>
            <div>
              <FormattedMessage id="shareAccountStory.introList.owner" />
            </div>
          </li>
          <li>
            <div>
              <NewIcon.Search />
            </div>
            <div>
              <FormattedMessage id="shareAccountStory.introList.insight" />
            </div>
          </li>
          <li>
            <div>
              <NewIcon.Lock />
            </div>
            <div>
              <FormattedMessage id="shareAccountStory.introList.changes" />
            </div>
          </li>
          <li>
            <div>
              <NewIcon.Transfer />
            </div>
            <div>
              <FormattedMessage id="shareAccountStory.introList.transfers" />
            </div>
          </li>
        </ol>
      </Card>
    </div>
  );
};
