import { useEffect } from "react";
import * as React from "react";
import { client as elevioClient } from "elevio";
import { useLocalizationContext } from "../../context/LocalizationContext";
import { useUser } from "../../context/UserContext";

export const ELEVIO_ACCOUNT_ID = "5a7cb3ba2c89b";

export const ElevioLoader: React.VFC = () => {
  const localizationContext = useLocalizationContext();
  const { country, legalEntityType, name } = useUser();
  const language = localizationContext.state.language;

  useEffect(() => {
    if (!country || !legalEntityType) {
      return;
    }

    elevioClient.load(ELEVIO_ACCOUNT_ID).then((elevio) => {
      const groups = [
        `country_${country?.toLocaleLowerCase()}`,
        `type_${legalEntityType?.toLocaleLowerCase()}`,
      ];

      const user = name ? { groups } : {};

      elevio.setUser(user);
      elevio.setLanguage(language);
    });
  }, [country, legalEntityType, name, language]);

  return null;
};
