import { FunctionComponent, useEffect, useState } from "react";

import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import {
  GetPensionSuitabilityAssessmentRequest,
  GetSuitabilityAssessmentResponse,
  dataInvestments,
  getPensionAccountQuestions,
} from "../../../../../data/dataInvestments";
import { LysaCountry } from "@lysaab/shared";
import { getUserAge } from "../utils/userAge";
import { useTransfer } from "../TransferContext";
import { Button, Spinner, Typography } from "@lysaab/ui-2";
import { useUser } from "../../../../../context/UserContext";
import { AccountType } from "../../../../../data/dataAccounts";
import { Amount } from "../../../../../components/amount/Amount";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { TRANSFER_PENSIONS_URL } from "../TransferPensionsStory";
import { useLanguage } from "../../../../../context/LocalizationContext";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { FeesEstimatedSignedIn, dataFees } from "../../../../../data/dataFees";
import { FormattedPercentage } from "../../../../../components/formattedPercentage/FormattedPercentage";

import "./Fees.scss";

const EXAMPLE_AMOUNT = 100000;

export const Fees: FunctionComponent = () => {
  const [advise, setAdvise] = useState<GetSuitabilityAssessmentResponse>();
  const [estimatedFees, setEstimatedFees] = useState<FeesEstimatedSignedIn>();
  const language = useLanguage();
  const [transfer] = useTransfer();
  const user = useUser();
  const age = getUserAge(user.tin);
  const history = useHistory();
  const intl = useIntl();

  /**
   * Safe guard in case we loose context. On refresh etc. Send user back to start of story.
   */
  useEffect(() => {
    if (typeof transfer?.moves[0]?.institute === "undefined") {
      history.replace(getNavLink(TRANSFER_PENSIONS_URL));
    }
  }, [history, transfer?.moves]);

  /**
   * accountType is hardcoded for now. Each transfer can have several moves(pensions).
   * Each move has accountType of either LYSA_PPF or LYSA_TJP.
   * Since the fees apply to all moves(pensions) we can't distinguise a specific accountType.
   * The fee we present is the same for the whole transfer.
   * The backend treat the accountType the same. Thus, hardcoded solution works for now.
   */
  useEffect(() => {
    if (advise?.investmentType && advise?.advisedRisk) {
      const takenRisk = Math.min(
        advise.advisedRisk + transfer.takenRiskDeviation,
        100
      );

      dataFees
        .getEstimatedFeesSignedIn({
          amount: EXAMPLE_AMOUNT,
          risk: takenRisk,
          investmentType: advise?.investmentType,
          country: LysaCountry.SWEDEN,
          accountType: AccountType.LYSA_TJP,
        })
        .then((fees) => {
          setEstimatedFees(fees);
        });
    }
  }, [
    transfer.sustainability,
    transfer.takenRiskDeviation,
    advise?.investmentType,
    advise?.advisedRisk,
  ]);

  useEffect(() => {
    if (typeof transfer?.sustainability !== "undefined") {
      const data: Partial<GetPensionSuitabilityAssessmentRequest> = {
        language: language,
        age: age,
        ...getPensionAccountQuestions(transfer),
      };

      dataInvestments.getNewPensionAccountSuitability(data).then((advise) => {
        setAdvise(advise);
      });
    }
  }, [transfer, language, age, history]);

  if (!advise || !estimatedFees) {
    return <Spinner />;
  }

  const {
    cost: {
      insurancePremium,
      fundManagement,
      discretionary,
      fundAssets,
      transactionFees,
      total,
    },
  } = estimatedFees;

  // Fees (Kr)
  const insuranceFee =
    EXAMPLE_AMOUNT * (insurancePremium ? insurancePremium / 100 : 0);
  const lysaFee = EXAMPLE_AMOUNT * ((discretionary + fundManagement) / 100);
  const underlyingFee = EXAMPLE_AMOUNT * (fundAssets / 100);
  const transactionFee = EXAMPLE_AMOUNT * (transactionFees / 100);
  const totalFee = EXAMPLE_AMOUNT * (total / 100);

  // Costs (%) (displayed next to label for transparency)
  const insuranceCost = insurancePremium;
  const lysaCost = discretionary + fundManagement;
  const underlyingCost = fundAssets;
  const transactionCosts = transactionFees;
  const totalCost = total;

  if (!insuranceCost) {
    return null;
  }

  return (
    <article className="transfer-pension-fees">
      <section>
        <Typography type="h2">
          <TranslatedText id="sweden.transfer-pension.fees.header" />
        </Typography>
        <Typography type="h3">
          <TranslatedText id="sweden.transfer-pension.fees.sub-title" />
        </Typography>
        <Typography type="body" className="ingress">
          <TranslatedText id="sweden.transfer-pension.fees.ingress" />
        </Typography>
        <dl>
          <dt>
            <TranslatedText
              id={"sweden.transfer-pension.fees.insurance-fee.label"}
            />
            <FormattedPercentage value={insuranceCost} decimals={3} />
          </dt>
          <dd>
            <Amount amount={insuranceFee} />
          </dd>
        </dl>
        <dl>
          <dt>
            <TranslatedText
              id={"sweden.transfer-pension.fees.lysa-fee.label"}
            />
            <FormattedPercentage value={lysaCost} decimals={3} />
          </dt>
          <dd>
            <Amount amount={lysaFee} />
          </dd>
        </dl>
        <dl>
          <dt>
            <TranslatedText
              id={"sweden.transfer-pension.fees.underlying-fee.label"}
            />
            <FormattedPercentage value={underlyingCost} decimals={3} />
          </dt>
          <dd>
            <Amount amount={underlyingFee} />
          </dd>
        </dl>
        <dl>
          <dt>
            <TranslatedText
              id={"sweden.transfer-pension.fees.transaction-fee.label"}
            />
            <FormattedPercentage value={transactionCosts} decimals={3} />
          </dt>
          <dd>
            <Amount amount={transactionFee} />
          </dd>
        </dl>
        <dl className="total">
          <dt>
            <TranslatedText id={"sweden.transfer-pension.fees.total.label"} />
            <FormattedPercentage value={totalCost} decimals={3} />
          </dt>
          <dd>
            <Amount amount={totalFee} />
          </dd>
        </dl>
        <Typography type="body-small">
          <TranslatedText id="sweden.transfer-pension.fees.footnote" />
        </Typography>
        <Typography type="label">
          <TranslatedText id="sweden.transfer-pension.fees.transfer-fee.header" />
        </Typography>
        <Typography type="body">
          <TranslatedText id="sweden.transfer-pension.fees.transfer-fee.body" />
        </Typography>
      </section>
      <section>
        <Button
          block
          type="button"
          label={intl.formatMessage({
            id: "sweden.transfer-pension.fees.positions.back",
          })}
          onClick={history.goBack}
        />
      </section>
    </article>
  );
};
