import { VoidFunctionComponent } from "react";

interface Props {
  size?: number;
}

export const AutogiroIcon: VoidFunctionComponent<Props> = ({ size = 40 }) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6563_10631)">
        <circle cx="20" cy="20" r="20" fill="#1840E3" />
        <path
          d="M10 15H15V24.54C15 24.805 15.215 25.02 15.48 25.02L25 25V30H18.625C13.86 30 10 26.14 10 21.375V15Z"
          fill="white"
        />
        <path
          d="M30 25H25V15.46C25 15.195 24.785 14.98 24.52 14.98L15 15V10H21.375C26.14 10 30 13.86 30 18.625V25Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6563_10631">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
