import { Typography } from "@lysaab/ui-2";
import { DateTime } from "luxon";
import * as React from "react";
import { defineMessages, FormatNumberOptions, useIntl } from "react-intl";
import { getFundName } from "../../../../../../../../components/fundName/FundName";
import { LysaTable } from "../../../../../../../../components/lysaTable/LysaTable";
import { LysaTableTextCell } from "../../../../../../../../components/lysaTableTextCell/LysaTableTextCell";
import { Modal } from "../../../../../../../../components/modal/Modal";
import { TranslatedText } from "../../../../../../../../components/TranslatedText";
import { useCurrency } from "../../../../../../../../context/LocalizationContext";
import { Isin } from "../../../../../../../../data/dataFunds";
import { SubVapPartResponse } from "../../../../../../../../data/dataTax";

import "./SubVapModal.scss";

export const tableLabelMessages = defineMessages({
  subscriptionDate: {
    id: "germany.tax.details.subVapModal.subscriptionDate",
  },
  redemptionDate: {
    id: "germany.tax.details.subVapModal.redemptionDate",
  },
  shares: {
    id: "germany.tax.details.subVapModal.shares",
  },
  vapPerFundUnit: {
    id: "germany.tax.details.subVapModal.vapPerFundUnit",
  },
  totalVap: {
    id: "germany.tax.details.subVapModal.totalVap",
  },
});

interface ModalProps {
  subVapPart: SubVapPartResponse[];
  isin: Isin;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}

export const SubVapModal: React.VFC<ModalProps> = ({
  subVapPart,
  isin,
  showModal,
  setShowModal,
}) => {
  const intl = useIntl();
  const currency = useCurrency();

  return (
    <Modal
      printEnabled
      showModal={showModal}
      onClose={() => setShowModal(false)}
      header={<TranslatedText id="germany.tax.details.subVapModal.header" />}
      width={1200}
      closeOnOverlayClick
      className="sub-vap-modal"
    >
      <Typography type="h4">
        {getFundName(isin, intl)} - {isin}
      </Typography>
      <div className="sub-vap-modal__table-wrapper">
        <LysaTable className="sub-vap-modal__table" fixed>
          <thead>
            <tr>
              <LysaTableTextCell
                value={intl.formatMessage(tableLabelMessages.subscriptionDate)}
                header
              />
              <LysaTableTextCell
                value={intl.formatMessage(tableLabelMessages.redemptionDate)}
                header
              />
              <LysaTableTextCell
                value={intl.formatMessage(tableLabelMessages.shares)}
                header
              />
              <LysaTableTextCell
                value={intl.formatMessage(tableLabelMessages.vapPerFundUnit)}
                header
              />
              <LysaTableTextCell
                value={intl.formatMessage(tableLabelMessages.totalVap)}
                header
              />
            </tr>
          </thead>
          <tbody>
            {subVapPart.length === 0 ? (
              <tr>
                <td colSpan={5}>
                  <TranslatedText id="germany.tax.details.noEntriesFound" />
                </td>
              </tr>
            ) : (
              [...subVapPart]
                .sort((entryA, entryB) => {
                  const comparisonDateA = entryA.redemptionDate
                    ? entryA.redemptionDate
                    : entryA.subscriptionDate;
                  const comparisonDateB = entryB.redemptionDate
                    ? entryB.redemptionDate
                    : entryB.subscriptionDate;

                  return (
                    DateTime.fromISO(comparisonDateA).toMillis() -
                    DateTime.fromISO(comparisonDateB).toMillis()
                  );
                })
                .map((entry) => (
                  <TaxRedemptionEntry key={entry.id} subVapPart={entry} />
                ))
            )}
          </tbody>
        </LysaTable>
        <div className="sub-vap-modal__table-total-wrapper">
          <div className="sub-vap-modal__table-total">
            <Typography type="label-large">
              <TranslatedText id="germany.tax.details.subVapModal.totalHeader" />
            </Typography>
            <p>
              {intl.formatNumber(
                Math.trunc(
                  subVapPart.reduce((acc, { totalVap }) => acc + totalVap, 0)
                ),
                {
                  style: "currency",
                  currency: currency,
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }
              )}
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

interface TaxRedemptionEntryProps {
  subVapPart: SubVapPartResponse;
}

const TaxRedemptionEntry: React.VFC<TaxRedemptionEntryProps> = ({
  subVapPart,
}) => {
  const intl = useIntl();
  const currency = useCurrency();

  const currencyOptions: FormatNumberOptions = {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return (
    <tr>
      <LysaTableTextCell
        label={intl.formatMessage(tableLabelMessages.subscriptionDate)}
        value={intl.formatDate(subVapPart.subscriptionDate)}
      />
      <LysaTableTextCell
        label={intl.formatMessage(tableLabelMessages.redemptionDate)}
        value={
          subVapPart.redemptionDate
            ? intl.formatDate(subVapPart.redemptionDate)
            : "-"
        }
      />
      <LysaTableTextCell
        label={intl.formatMessage(tableLabelMessages.shares)}
        value={intl.formatNumber(subVapPart.shares)}
      />
      <LysaTableTextCell
        label={intl.formatMessage(tableLabelMessages.vapPerFundUnit)}
        value={intl.formatNumber(subVapPart.vapPerFundUnit, currencyOptions)}
      />
      <LysaTableTextCell
        label={intl.formatMessage(tableLabelMessages.totalVap)}
        value={intl.formatNumber(subVapPart.totalVap, currencyOptions)}
      />
    </tr>
  );
};
