import { Button, NewIcon, Spinner, Typography } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { TranslatedText } from "../../../../components/TranslatedText";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../../overview/OverviewPage";
import { useCurrency } from "../../../../context/LocalizationContext";
import { AccountTypeLogo } from "../../../../components/accountType/AccountTypeLogo";
import HorizontalDivider from "../../../../components/horizontalDivider/HorizontalDivider";
import { Link, useLocation } from "react-router-dom";
import { useWithdrawalIskToSavingsContext } from "../WithdrawalIskToSavingsContext";
import { TransferType } from "../../../../data/dataTransfer";
import "./Done.scss";

export function Done() {
  const intl = useIntl();
  const currency = useCurrency();
  const internalWithdrawalContext = useWithdrawalIskToSavingsContext();
  const location = useLocation<{ transferType: TransferType }>();
  const fromAccount = internalWithdrawalContext.state.selectedLysaAccount;
  const toAccount = internalWithdrawalContext.state.selectedSavingsAccount;
  const isDrain = location.state.transferType === TransferType.DRAIN;

  if (!internalWithdrawalContext || !fromAccount || !toAccount) {
    return <Spinner />;
  }

  return (
    <div className="isk-to-savings-done-page-wrapper">
      <NewIcon.Check size={56} className="done-page__check-icon" />
      <Typography type="h2">
        <TranslatedText id="withdrawal.isk-to-savings-story.done.header" />
      </Typography>
      <div className="done-page-info-wrapper">
        <div className="row">
          <Typography type="label">
            <TranslatedText id="withdrawal.isk-to-savings-story.done.time.header" />
          </Typography>
          <Typography type="label">
            <TranslatedText id="withdrawal.isk-to-savings-story.done.time.body" />
          </Typography>
        </div>
        <div className="row">
          <Typography type="label">
            <TranslatedText id="withdrawal.isk-to-savings-story.done.amount.header" />
          </Typography>
          <Typography type="label">
            {isDrain ? (
              <TranslatedText id="withdrawal.isk-to-savings-story.done.amount.drain" />
            ) : (
              typeof internalWithdrawalContext.state.amount !== "undefined" &&
              intl.formatNumber(
                Number(internalWithdrawalContext.state.amount),
                {
                  style: "currency",
                  currency: currency,
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                }
              )
            )}
          </Typography>
        </div>
        <div className="row">
          <Typography type="label">
            <TranslatedText id="withdrawal.isk-to-savings-story.done.from-account.header" />
          </Typography>
          <div className="account-wrapper">
            <AccountTypeLogo account={fromAccount} size={24} />
            <Typography type="label">{fromAccount.name}</Typography>
          </div>
        </div>
        <div className="row">
          <Typography type="label">
            <TranslatedText id="withdrawal.isk-to-savings-story.done.to-account.header" />
          </Typography>
          <div className="account-wrapper">
            <AccountTypeLogo account={toAccount} size={24} />
            <Typography type="label">{toAccount.name}</Typography>
          </div>
        </div>
        <HorizontalDivider />
        <Typography type="body" variant="secondary" component="span">
          {isDrain ? (
            <TranslatedText id="withdrawal.isk-to-savings-story.done.drain-disclaimer" />
          ) : (
            <TranslatedText id="withdrawal.isk-to-savings-story.done.disclaimer" />
          )}
        </Typography>
      </div>
      <Button
        block
        component={Link}
        to={getNavLink(OVERVIEW_PAGE_URL)}
        label={
          <TranslatedText id="withdrawal.isk-to-savings-story.done.button" />
        }
      />
    </div>
  );
}
