import { FC, useMemo } from "react";
import { DateTime } from "luxon";
import { FormattedMessage } from "react-intl";

// TODO: This should be deleted after 2024-11-19.
export const AllocationUpdatedSince: FC = () => {
  const show = useMemo(() => {
    const now = DateTime.utc();
    const end = DateTime.utc(2024, 11, 19).endOf("day");

    return now < end;
  }, []);

  if (!show) {
    return null;
  }

  return <FormattedMessage id="allocationUpdatedSince.updated-since" />;
};
