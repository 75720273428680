import { VoidFunctionComponent } from "react";
import { Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { useIntl, defineMessages } from "react-intl";

export enum CorporationInsolvencyRisk {
  yes,
  no,
}

const messages = defineMessages({
  header: {
    id: "situation.insolvencyRisk.header",
  },
  required: {
    id: "situation.insolvencyRisk.required",
  },
});

interface MessageWithId {
  id: string;
}

const alternativeMessages = defineMessages<
  CorporationInsolvencyRisk,
  MessageWithId,
  Record<CorporationInsolvencyRisk, MessageWithId>
>({
  [CorporationInsolvencyRisk.yes]: {
    id: "situation.insolvencyRisk.alt.1",
  },
  [CorporationInsolvencyRisk.no]: {
    id: "situation.insolvencyRisk.alt.2",
  },
});

interface Props {
  value?: CorporationInsolvencyRisk;
  setValue: (newValue: CorporationInsolvencyRisk) => void;
}

export const InsolvencyRisk: VoidFunctionComponent<Props> = ({
  value,
  setValue,
}) => {
  const intl = useIntl();

  const alternatives = Object.values(CorporationInsolvencyRisk)
    .filter((val) => typeof val === "number")
    .map((value) => {
      return {
        text: intl.formatMessage(
          alternativeMessages[Number(value) as CorporationInsolvencyRisk]
        ),
        value: Number(value),
      };
    });

  return (
    <Card>
      <RadioGroup
        header={intl.formatMessage(messages.header)}
        alternatives={alternatives}
        onChange={(riskReaction) => setValue(riskReaction.value)}
        value={alternatives.find((alt) => alt.value === value)}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.required)),
        ]}
      />
    </Card>
  );
};
