import { Fragment, useEffect, useRef, useState } from "react";
import {
  cache,
  Card,
  Form,
  LysaFormRef,
  MinLengthValidator,
  Button,
  RequiredValidator,
  TextInput,
} from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { useMonthlyContext } from "../MonthlyContext";
import { GET_ACOUNT_RESTRICTIONS_URL } from "../../../../../data/dataDirectDebit";
import { UnconfirmedExternalAccountValidator } from "../externalAccountSelection/unconfirmedExternalAccountValidator/UnconfirmedExternalAccountValidator";

interface Props {
  next: () => void;
}

const messages = defineMessages({
  label: {
    id: "deposits.monthly.story.manual-account-selection.number.label",
  },
  required: {
    id: "deposits.monthly.story.manual-account-selection.number.required",
  },
  minLength: {
    id: "deposits.monthly.story.manual-account-selection.number.minLength",
  },
  ibanError: {
    id: "deposits.monthly.story.manual-account-selection.number.ibanError",
  },
  invalidAccountError: {
    id: "deposits.monthly.story.manual-account-selection.number.invalidAccountError",
  },
});

export function ManualAccountSelection({ next }: Props) {
  const monthlyContext = useMonthlyContext();
  const setMonthlyContext = monthlyContext.setState;
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();
  const [accountNumber, setAccountNumber] = useState("");

  useEffect(() => {
    setMonthlyContext({
      externalAccount: undefined,
    });
  }, [setMonthlyContext]);

  return (
    <Fragment>
      <h2>
        <TranslatedText id="deposits.monthly.story.manual-account-selection.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            return;
          }
          setMonthlyContext({
            externalAccount: accountNumber,
          });
          cache.delete(GET_ACOUNT_RESTRICTIONS_URL);
          next();
        }}
      >
        <Card>
          <TextInput
            label={intl.formatMessage(messages.label)}
            value={accountNumber}
            onChange={(value) => setAccountNumber(value)}
            validators={[
              new RequiredValidator(intl.formatMessage(messages.required)),
              new MinLengthValidator(5, intl.formatMessage(messages.minLength)),
              new UnconfirmedExternalAccountValidator(
                intl.formatMessage(messages.ibanError),
                intl.formatMessage(messages.invalidAccountError)
              ),
            ]}
          />
        </Card>
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="deposits.monthly.story.manual-account-selection.header.next" />
          }
        />
      </Form>
    </Fragment>
  );
}
