import { API, cache, encode } from "@lysaab/ui-2";
import { Language } from "../context/LocalizationContext";

export type BannerId = string & { readonly isBannerId: true };
export type ExternalLink = string & { readonly isExternalLink: true };

export interface BannerContent {
  id: BannerId;
  title: string;
  body: string;
  buttonUri: string | ExternalLink;
  buttonText: string;
}

interface BannerResponse {
  banner: BannerContent | null;
}

export const dataBanner = {
  getContent: (languageCode: Language) => {
    return API.get<BannerResponse>(
      encode`/banner?platform=WEB&languageCode=${languageCode}`
    );
  },
  saveDismiss: (id: BannerId) => {
    return API.post(encode`/banner/${id}/dismiss`).then((response) => {
      cache.delete("/banner");
      return response;
    });
  },
  saveClick: (id: BannerId) => {
    return API.post(encode`/banner/${id}/click`);
  },
};
