import { SegmentedSelect } from "@lysaab/ui-2";
import * as React from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router";
import { GridCol } from "../../components/grid/gridCol/GridCol";
import { GridRow } from "../../components/grid/gridRow/GridRow";
import { Tab } from "../../components/tab/tab/Tab";
import { TabView } from "../../components/tab/tabView/TabView";
import { CompoundAccountId } from "../../data/dataAccounts";
import { getNavLink } from "../../hooks/useCountryUrls";
import { useQuery } from "../../hooks/useQuery";
import { Page, PageHeader } from "../Page";
import { SharedByMe } from "./tabs/SharedByMe";
import { SharedWithMe } from "./tabs/SharedWithMe";
import "./SharedAccountsPage.scss";

export const SHARED_ACCOUNTS_URL = "/shared-accounts";

export enum SharedAccountsView {
  SHARED_WITH_ME = "with-me",
  SHARED_BY_ME = "by-me",
}

export const SHARED_ACCOUNTS_VIEW_URL = (
  view = SharedAccountsView.SHARED_WITH_ME,
  accountId?: CompoundAccountId
) => {
  const search = new URLSearchParams();
  search.set("view", view);
  if (accountId) {
    search.set("accountId", accountId);
  }
  return `${SHARED_ACCOUNTS_URL}?${search.toString()}`;
};

const messages = defineMessages({
  sharedWithMeButton: {
    id: "sharedAccountsPage.sharedWithMeButton",
  },
  sharedByMeButton: {
    id: "sharedAccountsPage.sharedByMeButton",
  },
});

interface QueryParams {
  view?: SharedAccountsView;
  accountId?: CompoundAccountId;
}

export const SharedAccountsPageInternal: React.VFC = React.memo(() => {
  const query = useQuery<QueryParams>();
  const history = useHistory();
  const intl = useIntl();

  const view = query.view || SharedAccountsView.SHARED_WITH_ME;
  const selectedAccount = query.accountId;

  const alternatives = [
    {
      text: intl.formatMessage(messages.sharedWithMeButton),
      value: SharedAccountsView.SHARED_WITH_ME,
    },
    {
      text: intl.formatMessage(messages.sharedByMeButton),
      value: SharedAccountsView.SHARED_BY_ME,
    },
  ];

  const handleViewChange = (value: SharedAccountsView) => {
    const search = new URLSearchParams(history.location.search);
    search.set("view", value);
    history.replace({
      pathname: getNavLink(SHARED_ACCOUNTS_URL),
      search: search.toString(),
    });
  };

  return (
    <div className="shared-accounts-page">
      <GridRow>
        <GridCol medium={6} large={4}>
          <SegmentedSelect
            alternatives={alternatives}
            value={{
              text: "",
              value: view,
            }}
            onChange={({ value }) => handleViewChange(value)}
          />
        </GridCol>
      </GridRow>
      <TabView activeTab={view} onTabChange={handleViewChange}>
        <Tab tabroute={SharedAccountsView.SHARED_WITH_ME}>
          <SharedWithMe />
        </Tab>
        <Tab tabroute={SharedAccountsView.SHARED_BY_ME}>
          <SharedByMe selectedAccount={selectedAccount} />
        </Tab>
      </TabView>
    </div>
  );
});

export const SharedAccountsPage: React.VFC = React.memo(() => {
  return (
    <Page>
      <PageHeader>
        <h1>
          <FormattedMessage id="sharedAccountsPage.header" />
        </h1>
      </PageHeader>
      <SharedAccountsPageInternal />
    </Page>
  );
});
