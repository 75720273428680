import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

export const useAccountParams = (): [Date, Date, string] => {
  const location = useLocation();
  const [state, setState] = useState<{
    fromDate: Date;
    toDate: Date;
    accountId: string;
  }>({
    fromDate: DateTime.now().minus({ months: 1, days: 1 }).toJSDate(),
    toDate: DateTime.now().minus({ days: 1 }).toJSDate(),
    accountId: "",
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const fromDate = searchParams.get("fromDate")
      ? DateTime.fromISO(searchParams.get("fromDate")!).toJSDate()
      : DateTime.now().minus({ years: 1, days: 1 }).toJSDate();

    const toDate = searchParams.get("toDate")
      ? DateTime.fromISO(searchParams.get("toDate")!).toJSDate()
      : DateTime.now().minus({ days: 1 }).toJSDate();

    const accountId = searchParams.get("accountId") || "";

    setState({ fromDate, toDate, accountId });
  }, [location.search]);

  return [state.fromDate, state.toDate, state.accountId];
};
