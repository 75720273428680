import {
  useContext,
  useEffect,
  useRef,
  useState,
  VoidFunctionComponent,
} from "react";
import {
  Button,
  LysaFormRef,
  Form,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import {
  CrsContext,
  CrsContextProvider,
} from "../../updateCrsStory/context/CrsContext";
import {
  CrsSwedishResponse,
  dataCrs,
  mapCrsSwedishResponseToRequest,
} from "../../../../../data/dataCrs";
import { EventTracker } from "../../../../../components/eventTracker/EventTracker";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { CrsFormFields } from "../../../components/crsFormFields/CrsFormFields";
import { CrsProps } from "../../../../../pages/yearlyReview/pages/crs/Crs";
import { TrackerEvent } from "../../../../../data/dataCustomerTracking";

const CrsInstance: VoidFunctionComponent<CrsProps> = ({ next }) => {
  const crsContext = useContext(CrsContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { crsInformation } = crsContext.state;
  const crsSetState = crsContext.setState;
  const formRef = useRef<LysaFormRef>();

  useEffect(() => {
    if (crsInformation !== undefined) {
      return;
    }

    setIsLoading(true);
    dataCrs
      .getLatestCrsSwedish()
      .then((response) => {
        crsSetState({ crsInformation: response });
      })
      .catch(() => setError(true))
      .finally(() => setIsLoading(false));
  }, [crsInformation, crsSetState]);

  if (isLoading) {
    return <Spinner />;
  }

  const postCrsInformation = (crsInformation: CrsSwedishResponse) => {
    setIsLoading(true);
    dataCrs
      .postUpdateCrsSwedish(mapCrsSwedishResponseToRequest(crsInformation))
      .then(() => {
        EventTracker.track({ event: TrackerEvent.YEARLY_REVIEW_CRS });
        next();
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (crsInformation === undefined) {
    return null;
  }

  return (
    <div className="update-crs">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (formRef.current?.isValid && crsInformation) {
            postCrsInformation(crsInformation);
          }
        }}
      >
        <h2>
          <TranslatedText id="sweden.yearly.crs.header" />
        </h2>

        {error && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="sweden.yearly.crs.error" />
          </Snackbar>
        )}
        <CrsFormFields data={crsInformation} setData={crsSetState} />
        <p>
          <TranslatedText id="sweden.yearly.crs.nextConfirmText" />
        </p>
        <Button
          type="submit"
          block
          label={<TranslatedText id="sweden.yearly.crs.button.next" />}
        />
      </Form>
    </div>
  );
};

export const Crs: VoidFunctionComponent<CrsProps> = (props) => {
  return (
    <CrsContextProvider>
      <CrsInstance {...props} />
    </CrsContextProvider>
  );
};
