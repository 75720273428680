import { useEffect, useState, useCallback, useContext, useRef } from "react";
import * as React from "react";
import cx from "classnames";
import { wrap } from "../../utils/promise-worker";
import { Graph } from "./Graph";
import { InvestmentAccount, CompoundAccount } from "../../data/dataAccounts";
import {
  GraphData,
  GraphDataIndexes,
  Markets,
  Interval,
  dataPerformance,
} from "../../data/dataPerformance";
import { Status, Retry } from "../retry/Retry";
import { PerformanceContext } from "../../context/PerformanceContext";
import { GraphLegend } from "./GraphLegend";
import { LocalizationContext } from "../../context/LocalizationContext";
import { dataUser } from "../../data/dataUser";
import { dataTransactions, Filter } from "../../data/dataTransactions";
import "./PerformanceGraph.scss";
import {
  Events,
  EventsTooltip,
  GraphTooltip,
  IndexChanges,
  IndexTooltip,
} from "./PerformanceGraphTypes";
import { WorkerFunctions } from "./worker";
import { cache } from "@lysaab/ui-2";
import { DateTime } from "luxon";

interface Props {
  account?: InvestmentAccount;
  accounts: CompoundAccount[];
}

function getStartDate(
  interval: Interval,
  accounts: CompoundAccount[],
  account?: InvestmentAccount
) {
  if (account) {
    return dataPerformance.getStartDateFromAccount(interval, account);
  } else {
    return dataPerformance.getStartDateFromUser(interval, accounts);
  }
}

function getDefaultData() {
  const date = new Date();
  const nextMinute = new Date(date.getTime() + 60 * 1000);
  return {
    points: [
      {
        date,
        change: 0,
      },
      {
        date: nextMinute,
        change: 0,
      },
    ],
    xDomain: [date, nextMinute],
    yDomain: [-1, 1],
  };
}

function getDefaultIndexesData() {
  return {
    indexes: [],
    yDomain: [1000000, -1000000],
  };
}

function getDefaultEventsData() {
  return {
    events: [],
    yDomain: [1000000, -1000000],
  };
}

function getDefaultEventsTooltipData() {
  return {
    depositsPeriod: 0,
    withdrawalsPeriod: 0,
    nettoDepositsPeriod: 0,
    nettoInternalTransfers: 0,
    DEPOSIT: {},
    WITHDRAWAL: {},
    INTERNAL: {},
  };
}

const workerPromise = wrap<WorkerFunctions>(
  new Worker(new URL("./worker.ts", import.meta.url))
);

export const PerformanceGraph: React.FC<Props> = (props) => {
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [graphData, setGraphData] = useState<GraphData>(getDefaultData());
  const [graphDataIndexes, setGraphDataIndexes] = useState<GraphDataIndexes>(
    getDefaultIndexesData()
  );
  const [events, setEvents] = useState<Events>(getDefaultEventsData());
  const [graphTooltip, setGraphTooltip] = useState<GraphTooltip>({});
  const [indexTooltip, setIndexTooltip] = useState<IndexTooltip>({});
  const [indexChanges, setIndexChanges] = useState<IndexChanges>({});
  const [eventsTooltip, setEventsTooltip] = useState<EventsTooltip>(
    getDefaultEventsTooltipData()
  );
  const {
    interval,
    indexes,
    customStartDate,
    customEndDate,
    showOverlay,
    showEvents,
    setState: setPerformanceState,
  } = useContext(PerformanceContext);
  const localizationContext = useContext(LocalizationContext);
  const { currency } = localizationContext.state;
  const { accounts, account } = props;
  const delayedRequest = useRef<number>(500);
  const timer = useRef<number | undefined>();
  const indexesTimer = useRef<number | undefined>();
  const animatedEventElementTimer = useRef<number | undefined>();
  const [showAnimatedEventElement, setShowAnimatedEventElement] =
    useState(showEvents);
  const [markets, setMarkets] = useState<Markets>();

  useEffect(() => {
    if (account) {
      dataPerformance
        .getComparisonMarketsForAccount(account.accountId)
        .then(setMarkets)
        .catch(() => {});
      return;
    }
    dataPerformance
      .getComparisonMarkets()
      .then(setMarkets)
      .catch(() => {});
  }, [account]);

  useEffect(() => {
    if (accounts.length === 0 || !markets) {
      return;
    }
    window.clearTimeout(indexesTimer.current);
    indexesTimer.current = window.setTimeout(() => {
      const availableIndexes = indexes.filter((index) => {
        const key = `id_${index}` as keyof Markets;
        return typeof markets[key] !== "undefined";
      });

      if (!availableIndexes.length) {
        setGraphDataIndexes(getDefaultIndexesData());
        setIndexTooltip({});
        setIndexChanges({});
        return;
      }

      let start;
      let end;
      if (interval === Interval.CUSTOM) {
        if (!customStartDate) {
          return;
        }
        if (!customEndDate) {
          return;
        }
        start = customStartDate;
        end = customEndDate;
      } else {
        start = getStartDate(interval, accounts, account);
        end = dataPerformance.getEndDate();
      }

      Promise.all([
        dataPerformance.getMarketPerformances(start, end, availableIndexes),
        workerPromise,
      ])
        .then(([data, worker]) => {
          worker.streamlineIndexes(data).then((result: GraphDataIndexes) => {
            result.indexes.forEach((indexItem) => {
              const key = `id_${indexItem.id}` as keyof Markets;
              indexItem.color = markets[key].color;
            });
            setGraphDataIndexes(result);

            // yes, workout the points first and then the tooltip
            worker
              .streamlineIndexTooltip(data, markets, currency)
              .then(
                (result: {
                  indexTooltip: IndexTooltip;
                  indexChanges: IndexChanges;
                }) => {
                  setIndexTooltip(result.indexTooltip);
                  setIndexChanges(result.indexChanges);
                }
              );
          });
        })
        .catch(console.error); // fail silently _change_?
    }, delayedRequest.current);
  }, [
    indexes,
    account,
    accounts,
    interval,
    customStartDate,
    customEndDate,
    currency,
    markets,
  ]);

  const load = useCallback(
    (
      account: InvestmentAccount | undefined,
      accounts: CompoundAccount[],
      interval: Interval,
      customStartDate: Date | null,
      customEndDate: Date | null,
      // TODO: Type this
      currency: any
    ) => {
      let promise;
      let start;
      let end;
      if (interval === Interval.CUSTOM) {
        if (!customStartDate) {
          return;
        }
        if (!customEndDate) {
          return;
        }
        start = customStartDate;
        end = customEndDate;
      } else {
        start = getStartDate(interval, accounts, account);
        end = dataPerformance.getEndDate();
      }

      if (account) {
        promise = dataPerformance.getAccountPerformance(
          start,
          end,
          account.accountId
        );
      } else {
        promise = dataPerformance.getOverviewPerformance(start, end);
      }
      Promise.all([promise, workerPromise])
        .then(([data, worker]) => {
          worker.streamlinePoints(data.graph).then((result: GraphData) => {
            setGraphData(result);
            setStatus(Status.SUCCESS);
            delayedRequest.current = 0;
            // yes, workout the points first and then the tooltip
            worker
              .streamlineGraphTooltip(data.graph, currency)
              .then((result: GraphTooltip) => {
                setGraphTooltip(result);
              });
          });
        })
        .catch(() => {
          getDefaultData();
          setStatus(Status.ERROR);
        });
    },
    []
  );

  useEffect(() => {
    if (accounts.length === 0) {
      return;
    }
    setStatus(Status.PENDING);
    window.clearTimeout(timer.current);
    timer.current = window.setTimeout(() => {
      load(
        account,
        accounts,
        interval,
        customStartDate,
        customEndDate,
        currency
      );
    }, delayedRequest.current);
  }, [
    load,
    account,
    accounts,
    interval,
    customStartDate,
    customEndDate,
    currency,
  ]);

  const retry = useCallback(() => {
    if (!accounts) {
      return;
    }
    cache.delete("/accounts/performance");
    setStatus(Status.PENDING);
    setTimeout(() => {
      load(
        account,
        accounts,
        interval,
        customStartDate,
        customEndDate,
        currency
      );
    }, 800);
  }, [
    load,
    account,
    accounts,
    interval,
    customStartDate,
    customEndDate,
    currency,
  ]);

  useEffect(() => {
    if (accounts.length === 0) {
      return;
    }
    if (!showEvents) {
      setEvents(getDefaultEventsData());
      setEventsTooltip(getDefaultEventsTooltipData());
      return;
    }

    const filter: Filter = { ...dataTransactions.getDefaultFilter() };
    /**
     * Adjusted for the performance data being aggregated on UTC dates
     */
    filter.start = DateTime.fromISO(
      DateTime.fromJSDate(dataUser.getCreated(accounts))
        .toUTC()
        .toFormat("yyyy-MM-dd")
    ).toJSDate();
    filter.accountIds = accounts.map((account) => account.accountId);

    Promise.all([
      dataTransactions.getTransactions(filter),
      dataTransactions.getInternalTransfers(filter.start, filter.end),
    ])
      .then(([transactions, internalTransfers]) => {
        let start: Date;
        let end: Date;

        if (interval === Interval.CUSTOM) {
          if (!customStartDate || !customEndDate) {
            return;
          }

          start = customStartDate;
          end = customEndDate;
        } else {
          start = getStartDate(interval, accounts, account);
          end = dataPerformance.getEndDate();
        }

        workerPromise.then((worker) => {
          worker
            .streamlineEvents(
              [...transactions, ...internalTransfers],
              account,
              start,
              end
            )
            .then((result) => {
              setEvents(result);
              worker
                .streamlineEventsTooltip(
                  result.events,
                  accounts,
                  currency,
                  account
                )
                .then((streamlinedEventsTooltip: EventsTooltip) => {
                  setEventsTooltip(streamlinedEventsTooltip);
                });
            });
        });
      })
      .catch(() => {}); // fail silently _change_?
  }, [
    showEvents,
    accounts,
    account,
    interval,
    customStartDate,
    customEndDate,
    currency,
  ]);

  useEffect(() => {
    window.clearTimeout(animatedEventElementTimer.current);
    if (showEvents) {
      setShowAnimatedEventElement(true);
    } else {
      animatedEventElementTimer.current = window.setTimeout(() => {
        setShowAnimatedEventElement(false);
      }, 800);
    }
  }, [showEvents]);

  const startDate = graphData.points[0].date;
  const end = graphData.points[graphData.points.length - 1];
  const endDate = end.date;
  const endChange = end.change;

  return (
    <div
      className={cx("performance-graph-wrapper", {
        "show-animated-event-element": showAnimatedEventElement,
      })}
    >
      <Retry
        retry={retry}
        status={status}
        childrenAnimationControls={{
          opacity: status !== Status.ERROR ? 1 : 0,
        }}
      >
        <div className="performance-graph-info">
          <GraphLegend
            account={account}
            indexes={indexes}
            indexChanges={indexChanges}
            lysaChange={endChange}
            showEvents={showEvents}
            eventsTooltip={eventsTooltip}
            startDate={startDate}
            endDate={endDate}
            interval={interval}
          />
        </div>

        <Graph
          account={account}
          showOverlay={showOverlay}
          showEvents={showEvents}
          setPerformanceState={setPerformanceState}
          points={graphData.points}
          indexes={graphDataIndexes.indexes}
          events={events}
          yMin={Math.min(graphData.yDomain[0], graphDataIndexes.yDomain[0])}
          yMax={Math.max(graphData.yDomain[1], graphDataIndexes.yDomain[1])}
          xMin={startDate}
          xMax={endDate}
          graphTooltip={graphTooltip}
          indexTooltip={indexTooltip}
          eventsTooltip={eventsTooltip}
        />

        <div className="animated-event-element" />
      </Retry>
    </div>
  );
};
