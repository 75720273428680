import { useLocation } from "react-router-dom";

export function useQuery<
  Params extends { [K in keyof Params]?: string } = {}
>() {
  const params = new URLSearchParams(useLocation().search);
  const keys: { [key: string]: string } = {};
  params.forEach((value, key) => {
    keys[key] = value;
  });
  return keys as Params;
}
