import { VoidFunctionComponent, useContext } from "react";
import {
  EntityType,
  IsPepQuestion,
  PepAnswers,
  PepState,
  PepType,
  stateToRequest,
} from "@lysaab/lysa-pep";
import { Card } from "@lysaab/ui-2";
import { UserContext } from "../../../../context/UserContext";

export const IsPepQuestionCard: VoidFunctionComponent<{
  pepState: PepState;
  display?: boolean;
  onChange: (type: PepType) => void;
}> = ({ pepState, display, onChange }) => {
  const userContext = useContext(UserContext);

  if (display && pepState.type) {
    return (
      <Card>
        <h3 className="pep-card-header">
          {pepState.legalEntityType === EntityType.CORPORATION
            ? pepState?.ownerName
            : userContext.state.name}
        </h3>
        <PepAnswers answer={stateToRequest(pepState)} />
      </Card>
    );
  }

  return (
    <Card>
      <h3 className="pep-card-header">{pepState?.ownerName}</h3>
      <IsPepQuestion
        ownerName={pepState.ownerName}
        entityType={pepState.legalEntityType || EntityType.PERSON}
        type={pepState.type}
        onChange={(type) => onChange(type)}
      />
    </Card>
  );
};
