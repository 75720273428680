import { Button } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";

interface Props {
  onCancel: () => void;
}

export const ListItemCancel: React.VFC<Props> = ({ onCancel }) => {
  return (
    <div className="list-item">
      <strong>
        <FormattedMessage id="shareAccountInviteCard.cancelLabel" />
      </strong>
      <div>
        <Button
          size="small"
          variant="negative"
          onClick={onCancel}
          label={<FormattedMessage id="shareAccountInviteCard.cancelButton" />}
        />
      </div>
    </div>
  );
};
