import { VoidFunctionComponent } from "react";
import { Button } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { CardPaneListItem } from "../../../../components/cardPane/CardPaneListItem";
import { AccountUpdateAction } from "../../../../pages/reviewAccount/ReviewAccountContext";

type Props = {
  updateAccount: (accountAction: AccountUpdateAction) => void;
};

export const InvestedAsAdvisedOrActionTaken: VoidFunctionComponent<Props> = ({
  updateAccount,
}) => {
  return (
    <CardPaneListItem>
      <Button
        block
        variant="secondary"
        onClick={() => updateAccount(AccountUpdateAction.ADVICE)}
        label={
          <FormattedMessage id="summary.accountCard.button.InvestedAsAdvisedOrActionTaken.lysa.advice" />
        }
      />
    </CardPaneListItem>
  );
};
