import {
  Button,
  Card,
  FlashContext,
  Form,
  LysaFormRef,
  RequiredValidator,
  SNACKBAR_TYPES,
  Spinner,
  TextInput,
} from "@lysaab/ui-2";
import {
  VoidFunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { InvoiceRequest, dataLife } from "../../../../../../data/dataLife";
import { useAccounts } from "../../../../../../hooks/useAccounts";
import { AccountType } from "../../../../../../data/dataAccounts";

export const InvoiceAddress: VoidFunctionComponent = () => {
  const intl = useIntl();
  const flashContext = useContext(FlashContext);
  const formRef = useRef<LysaFormRef>();

  const [loading, setLoading] = useState(false);
  const [addressData, setAddressData] = useState<InvoiceRequest>();
  const accounts = useAccounts();

  useEffect(() => {
    if (
      accounts.accounts?.investmentAccounts.find(
        (account) => account.type === AccountType.KF_SWE
      ) === undefined
    ) {
      return;
    }
    dataLife.getInvoiceAddress().then(setAddressData);
  }, [accounts.accounts?.investmentAccounts]);

  const onSubmit = useCallback(() => {
    if (!formRef.current?.isValid || !addressData) {
      return;
    }

    setLoading(true);
    dataLife
      .setInvoiceAddress(addressData)
      .then((updatedAddress) => {
        setAddressData(updatedAddress);
        setLoading(false);
        flashContext.pushFlash({
          text: intl.formatMessage({
            id: "sweden.companySettings.invoice-address.snackbar-success",
          }),
          type: SNACKBAR_TYPES.SUCCESS,
        });
      })
      .catch(() => {
        setLoading(false);
        flashContext.pushFlash({
          text: intl.formatMessage({
            id: "sweden.companySettings.invoice-address.snackbar-error",
          }),
          type: SNACKBAR_TYPES.ERROR,
        });
      });
  }, [addressData, flashContext, intl]);

  if (
    accounts.accounts?.investmentAccounts.find(
      (account) => account.type === AccountType.KF_SWE
    ) === undefined
  ) {
    return null;
  }

  if (!addressData) {
    return (
      <div className="company-settings--invoice-address">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="company-settings--invoice-address">
      <h2>
        <FormattedMessage id="sweden.companySettings.invoice-address.title" />
      </h2>
      <Card>
        <Form lysaFormRef={formRef} onSubmit={onSubmit}>
          <p>
            <FormattedMessage id="sweden.companySettings.invoice-address.text" />
          </p>
          <TextInput
            label={intl.formatMessage({
              id: "sweden.companySettings.invoice-address.address.label",
            })}
            value={addressData.address}
            onChange={(newData) =>
              setAddressData({ ...addressData, address: newData })
            }
            validators={[
              new RequiredValidator(
                intl.formatMessage({
                  id: "sweden.companySettings.invoice-address.address.required",
                })
              ),
            ]}
          />
          <TextInput
            label={intl.formatMessage({
              id: "sweden.companySettings.invoice-address.zip-code.label",
            })}
            value={addressData.zipCode}
            onChange={(newData) =>
              setAddressData({ ...addressData, zipCode: newData })
            }
            validators={[
              new RequiredValidator(
                intl.formatMessage({
                  id: "sweden.companySettings.invoice-address.zip-code.required",
                })
              ),
            ]}
          />
          <TextInput
            label={intl.formatMessage({
              id: "sweden.companySettings.invoice-address.city.label",
            })}
            value={addressData.city}
            onChange={(newData) =>
              setAddressData({ ...addressData, city: newData })
            }
            validators={[
              new RequiredValidator(
                intl.formatMessage({
                  id: "sweden.companySettings.invoice-address.city.required",
                })
              ),
            ]}
          />
          {!loading && (
            <Button
              block
              type="submit"
              label={
                <FormattedMessage id="sweden.companySettings.addAccount.submit" />
              }
            />
          )}
        </Form>
      </Card>
    </div>
  );
};
