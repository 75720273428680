import { defineMessages } from "react-intl";

export const messages = defineMessages({
  realizedTradeDate: {
    id: "denmark.tax.details.realized.tradeDate",
  },
  realizedType: {
    id: "denmark.tax.details.realized.type",
  },
  realizedVolume: {
    id: "denmark.tax.details.realized.volume",
  },
  realizedPrice: {
    id: "denmark.tax.details.realized.price",
  },
  summaryVolume: {
    id: "denmark.tax.details.summary.volume",
  },
  summaryAcquisitionValue: {
    id: "denmark.tax.details.summary.acquisitionValue",
  },
  summaryTotalValue: {
    id: "denmark.tax.details.summary.totalValue",
  },
  summaryTotalGain: {
    id: "denmark.tax.details.summary.totalGain",
  },
  summaryTransactions: {
    id: "denmark.tax.details.summary.transactions",
  },
  summaryInitialValue: {
    id: "denmark.tax.details.summary.initialValue",
  },
  summaryValueDate: {
    id: "denmark.tax.details.summary.valueDate",
  },
  noEntriesFound: {
    id: "denmark.tax.details.noEntriesFound",
  },
  isin: {
    id: "denmark.tax.details.isin",
  },
  fundName: {
    id: "denmark.tax.details.fundName",
  },
});
