import { Card } from "@lysaab/ui-2";
import {
  Fragment,
  useContext,
  useEffect,
  useState,
  FunctionComponent,
} from "react";
import {
  SavingsAccountId,
  SavingsAccount,
} from "../../../../../data/dataAccounts";
import { PerformanceContext } from "../../../../../context/PerformanceContext";
import { useAccounts } from "../../../../../hooks/useAccounts";
import { useAccountPerformance } from "../../../../overview/hooks/useAccountPerformance";
import { IntervalSwitches } from "./components/intervalSwitches/IntervalSwitches";
import "./OverviewCard.scss";
import { SavingsSummaryHeader } from "./components/savingsSummaryHeader/SavingsSummaryHeader";
import { dataSavingsAccountInterest } from "../../../../../data/dataSavingsAccountInterest";

export interface SavingsAccountDataProps {
  balance: number;
  totalEarnings: number;
  totalChange: number;
  availableForWithdrawal: number;
}

interface Props {
  accountId: SavingsAccountId;
}

export const OverviewCard: FunctionComponent<Props> = ({ accountId }) => {
  const [account, setAccount] = useState<SavingsAccount>();
  const { accounts } = useAccounts();
  const { interval, customStartDate, customEndDate } =
    useContext(PerformanceContext);

  const [accruedInterestAfterFee, setAccruedInterestAfterFee] =
    useState<number>();

  const accountPerformance = useAccountPerformance(accountId);
  const [savingsAccountData, setSavingsAccountData] =
    useState<SavingsAccountDataProps>();

  useEffect(() => {
    if (typeof accounts === "undefined") {
      return;
    }

    const matchingAccount = [
      ...accounts.savingsAccounts,
      ...accounts.sharedSavingsAccounts,
    ].find((account) => account.accountId === accountId);

    if (matchingAccount) {
      setAccount(matchingAccount);
    }
  }, [accountId, accounts]);

  useEffect(() => {
    if (typeof accounts === "undefined") {
      return;
    }

    dataSavingsAccountInterest
      .getAccruedInterest(accountId)
      .then((accruedInterestResponse) => {
        setAccruedInterestAfterFee(
          accruedInterestResponse.accruedInterest - accruedInterestResponse.fee
        );
      });
  }, [accountId, accounts, customEndDate, customStartDate, interval]);

  useEffect(() => {
    if (
      typeof accruedInterestAfterFee !== "undefined" &&
      typeof accountPerformance !== "undefined" &&
      typeof account !== "undefined"
    ) {
      setSavingsAccountData({
        balance: account.totalBalance + accruedInterestAfterFee,
        totalEarnings: accountPerformance.earnings,
        totalChange: accountPerformance.change,
        availableForWithdrawal: account.totalBalance,
      });
    }
  }, [accountPerformance, accruedInterestAfterFee, account]);

  return (
    <Fragment>
      <Card className="overview-card">
        <SavingsSummaryHeader
          savingsAccountData={savingsAccountData}
          interval={interval}
        />
        <IntervalSwitches />
      </Card>
    </Fragment>
  );
};
