import { useCallback, useContext, useEffect } from "react";
import {
  useLocation,
  useHistory,
  matchPath,
  Redirect,
  generatePath,
} from "react-router-dom";
import { Story } from "@lysaab/ui-2";
import { OVERVIEW_PAGE_URL } from "../../../../../pages/overview/OverviewPage";
import { Intro } from "./intro/Intro";
import { HorizonWrapper } from "./horizon/HorizonWrapper";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { AdviseWrapper } from "./advise/Advise";
import { Candidate } from "./candidate/Candidate";
import { LysaSummary } from "./lysaSummary/LysaSummary";
import { Done } from "./done/Done";
import { LysaKyc } from "./lysaKyc/LysaKyc";
import { UserContext } from "../../../../../context/UserContext";
import { EditAllocationWrapper } from "./editAllocation/EditAllocationWrapper";
import { RiskWarningWrapper } from "./warning/RiskWarningWrapper";
import {
  LysaSign,
  CreateKFSignOnCompleteType,
  useCreateKFSignBankId,
} from "./lysaSign/LysaSign";
import { LayoutContext } from "../../../../../context/LayoutContext";
import { LegalEntityType } from "../../../../../data/dataLogin";
import "./CreateAccountCorporationPage.scss";
import { Route } from "../../../../../components/route/Route";
import { Switch } from "../../../../../components/route/Switch";
import { defineMessage, useIntl } from "react-intl";
import { LysaCountry } from "@lysaab/shared";
import {
  getAccountQuestions,
  isSustainabilityImportantSpecific,
  SustainabilityImportance,
} from "../../../../../data/dataInvestments";
import { useSafeNavigation } from "../../../../../hooks/useSafeNavigation";
import { Sustainability } from "./kfAccount/sustainability/Sustainability";
import { SustainabilityImportantQuestions } from "./kfAccount/sustainabilityImportantQuestions/SustainabilityImportantQuestions";
import { Preference } from "./kfAccount/preference/Preference";
import {
  dataCreateAccount,
  isValidSuitabilityAssessmentRequest,
  SuitabilityAssessmentRequest,
} from "../../../../../data/dataCreateAccount";
import { ConfirmEsgUpdateWrapper } from "./confirmEsgUpdateWrapper/ConfirmEsgUpdateWrapper";
import { PageStripped } from "../../../../../pages/PageStripped";
import { LocalizationContext } from "../../../../../context/LocalizationContext";
import { SuitabilityDownloadLazy } from "../../../../../pageComponents/advise/SuitabilityDownload";
import {
  CreateKFAccountProvider,
  useCreateKFAccount,
} from "./KFAccountContext";
import { CreationStatusOverview } from "./creationStatusOverview/CreationStatusOverview";
import { getLoginCookie } from "../../../../../utils/LoginCookie";
import { RedirectLogin } from "../../../../../components/router/RedirectLogin";

interface LocationStatePrevPath {
  prevPath?: string;
}

export const CREATE_ACCOUNT_CORPORATION_PAGE_URL =
  "/create-account/corporation";

const PREFIX = `/${LysaCountry.SWEDEN.toLocaleLowerCase()}${CREATE_ACCOUNT_CORPORATION_PAGE_URL}`;

export const ROUTES = {
  INTRO: `${PREFIX}/`,
  LYSA_KYC: `${PREFIX}/lysa-kyc`,
  CANDIDATE: `${PREFIX}/candidate`,
  HORIZON: `${PREFIX}/horizon`,
  SUSTAINABILITY: `${PREFIX}/sustainability`,
  PREFERENCE: `${PREFIX}/preference`,
  SUSTAINABILITY_QUESTIONS: `${PREFIX}/sustainability-questions`,
  CONFIRM_ESG_UPDATE: `${PREFIX}/confirm-esg-update`,
  ADVICE: `${PREFIX}/advice`,
  EDIT_ALLOCATION: `${PREFIX}/edit-allocation`,
  WARNING: `${PREFIX}/warning`,
  LYSA_SUMMARY: `${PREFIX}/lysa/summary`,
  CREATION_OVERVIEW: `${PREFIX}/creation-overview/:creationToken`,
  LYSA_SIGN: `${PREFIX}/lysa/sign/`,
  DONE: `${PREFIX}/done`,
};

const privateRoutesIndex = Object.values(ROUTES).findIndex(
  (path) => path === ROUTES.LYSA_SUMMARY
);

const ariaProgressLabel = defineMessage({
  id: "sweden.CreateAccountCorporationPage.story.ariaProgressLabel",
});

function getCurrentRouteIndex(location: ReturnType<typeof useLocation>) {
  return Object.values(ROUTES).findIndex((path) => {
    const match = matchPath(location.pathname, {
      path,
      exact: true,
    });
    return match !== null;
  });
}

function useIsPrivateRoute() {
  const location = useLocation();
  const currentIndex = getCurrentRouteIndex(location);
  return currentIndex < privateRoutesIndex;
}

function CreateAccountCorporationPageInner() {
  const history = useHistory();
  const location = useLocation();
  const [kfAccount] = useCreateKFAccount();
  const safeNavigation = useSafeNavigation();
  const intl = useIntl();
  const isLoggedIn = Boolean(getLoginCookie());
  const localizationContext = useContext(LocalizationContext);

  const storyLength = Object.values(ROUTES).length;
  const currentIndex = getCurrentRouteIndex(location);
  const isPrivateRoute = useIsPrivateRoute();

  const toOverview: CreateKFSignOnCompleteType = useCallback(
    ({ creationToken }, reset) => {
      safeNavigation(generatePath(ROUTES.CREATION_OVERVIEW, { creationToken }));
      reset();
    },
    [safeNavigation]
  );

  const { startSign, ...bankIdRest } = useCreateKFSignBankId(toOverview);

  if (isPrivateRoute && !isLoggedIn) {
    return <RedirectLogin location={location} />;
  }

  const exitRoute =
    (location.state as LocationStatePrevPath)?.prevPath ||
    getNavLink(OVERVIEW_PAGE_URL);

  return (
    <PageStripped className="create-account-corporation">
      <Story
        ariaLabelProgress={() =>
          intl.formatMessage(ariaProgressLabel, {
            current: currentIndex + 1,
            total: storyLength,
          })
        }
        header={intl.formatMessage({ id: "sweden.kf.story.header" })}
        onExit={() => {
          safeNavigation(exitRoute);
        }}
        showBack={
          !kfAccount.ongoing &&
          currentIndex > 0 &&
          currentIndex < Object.values(ROUTES).indexOf(ROUTES.LYSA_SIGN)
        }
        showClose={
          currentIndex !== Object.values(ROUTES).indexOf(ROUTES.LYSA_SIGN)
        }
        onBack={history.goBack}
        transitionKey={currentIndex.toString()}
        progress={(100 / storyLength) * (currentIndex + 1)}
      >
        <Switch {...{ order: currentIndex }} location={location}>
          <Route path={ROUTES.INTRO} exact>
            <Intro
              next={() => safeNavigation(ROUTES.LYSA_KYC)}
              toSigning={(token) =>
                safeNavigation(
                  generatePath(ROUTES.CREATION_OVERVIEW, {
                    creationToken: token,
                  })
                )
              }
            />
          </Route>
          <Route path={ROUTES.LYSA_KYC}>
            <LysaKyc next={() => safeNavigation(ROUTES.CANDIDATE)} />
          </Route>
          <Route path={ROUTES.CANDIDATE}>
            <Candidate next={() => safeNavigation(ROUTES.HORIZON)} />
          </Route>
          <Route path={ROUTES.HORIZON}>
            <HorizonWrapper
              next={() => safeNavigation(ROUTES.SUSTAINABILITY)}
            />
          </Route>
          <Route exact path={ROUTES.SUSTAINABILITY}>
            <Sustainability
              next={() => {
                if (
                  kfAccount.sustainability ===
                  SustainabilityImportance.IMPORTANT
                ) {
                  safeNavigation(ROUTES.PREFERENCE);
                } else {
                  safeNavigation(ROUTES.ADVICE);
                }
              }}
            />
          </Route>
          <Route exact path={ROUTES.PREFERENCE}>
            <Preference
              next={() => {
                if (isSustainabilityImportantSpecific(kfAccount)) {
                  safeNavigation(ROUTES.SUSTAINABILITY_QUESTIONS);
                } else {
                  safeNavigation(ROUTES.ADVICE);
                }
              }}
            />
          </Route>
          <Route exact path={ROUTES.SUSTAINABILITY_QUESTIONS}>
            <SustainabilityImportantQuestions
              next={() => {
                const data: Partial<SuitabilityAssessmentRequest> = {
                  language: localizationContext.state.language,
                  ...getAccountQuestions(kfAccount),
                };

                if (!isValidSuitabilityAssessmentRequest(data)) {
                  throw new Error(
                    "kf account - CreateAccountCorporationPage - not valid data in isValidAccountQuestions"
                  );
                }

                dataCreateAccount
                  .getKFSuitabilityAssessment(data)
                  .then((advise) => {
                    if (advise.esgResult.esgBestMatch) {
                      safeNavigation(ROUTES.CONFIRM_ESG_UPDATE);
                    } else {
                      safeNavigation(ROUTES.ADVICE);
                    }
                  });
              }}
            />
          </Route>
          <Route path={ROUTES.CONFIRM_ESG_UPDATE}>
            <ConfirmEsgUpdateWrapper
              next={() => {
                safeNavigation(ROUTES.ADVICE);
              }}
            />
          </Route>
          <Route path={ROUTES.ADVICE}>
            <AdviseWrapper
              next={() => safeNavigation(ROUTES.LYSA_SUMMARY)}
              navigateToEditAllocation={() =>
                safeNavigation(ROUTES.EDIT_ALLOCATION)
              }
              navigateToFees={() => safeNavigation(ROUTES.EDIT_ALLOCATION)}
            />
          </Route>
          <Route path={ROUTES.EDIT_ALLOCATION}>
            <EditAllocationWrapper
              nextRiskWarning={() => safeNavigation(ROUTES.WARNING)}
              next={() => safeNavigation(ROUTES.LYSA_SUMMARY)}
            />
          </Route>
          <Route path={ROUTES.WARNING}>
            <RiskWarningWrapper
              next={() => safeNavigation(ROUTES.LYSA_SUMMARY)}
            />
          </Route>
          <Route path={ROUTES.LYSA_SUMMARY}>
            <LysaSummary
              next={(creationToken) => {
                safeNavigation(
                  generatePath(ROUTES.CREATION_OVERVIEW, { creationToken })
                );
              }}
            />
          </Route>
          <Route path={ROUTES.CREATION_OVERVIEW}>
            <CreationStatusOverview
              next={() => safeNavigation(ROUTES.DONE)}
              sign={(creationToken, data) => {
                startSign(creationToken, data);
              }}
            />
          </Route>
          <Route path={ROUTES.LYSA_SIGN}>
            <LysaSign startSign={startSign} {...bankIdRest} />
          </Route>
          <Route path={ROUTES.DONE}>
            <Done />
          </Route>
        </Switch>
      </Story>
    </PageStripped>
  );
}

export function CreateAccountCorporationPage() {
  const layoutContext = useContext(LayoutContext);
  const { setState } = layoutContext;
  const userContext = useContext(UserContext);
  const user = userContext.state;

  useEffect(() => {
    // Preload the suitability PDF component early since it may reload the page which would result in loss of state
    SuitabilityDownloadLazy.preload();
  }, []);

  useEffect(() => {
    setState({
      hideNav: true,
    });
    return () => {
      setState({
        hideNav: false,
      });
    };
  }, [setState]);

  if (user.legalEntityType === LegalEntityType.PERSON) {
    return <Redirect to={getNavLink(OVERVIEW_PAGE_URL)} />;
  }

  return (
    <CreateKFAccountProvider>
      <CreateAccountCorporationPageInner />
    </CreateKFAccountProvider>
  );
}
