import { VoidFunctionComponent } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Alternative, Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { Employment } from "../../../../data/dataKyc";

const messages = defineMessages({
  employmentHeader: {
    id: "kyc.employment.header",
  },
  employmentRequired: {
    id: "kyc.employment.required",
  },
});

interface MessageWithId {
  id: string;
}

export const employmentMessages = defineMessages<
  Employment,
  MessageWithId,
  Record<Employment, MessageWithId>
>({
  [Employment.EMPLOYEE]: {
    id: "kyc.employment.alt.EMPLOYEE",
  },
  [Employment.SELF_EMPLOYED]: {
    id: "kyc.employment.alt.SELF_EMPLOYED",
  },
  [Employment.RETIRED]: {
    id: "kyc.employment.alt.RETIRED",
  },
  [Employment.STUDENT]: {
    id: "kyc.employment.alt.STUDENT",
  },
  [Employment.OTHER]: {
    id: "kyc.employment.alt.OTHER",
  },
});

interface Props {
  employment?: Employment;
  setEmployment: (employment: Employment) => void;
}

export const EmploymentQuestion: VoidFunctionComponent<Props> = ({
  employment,
  setEmployment,
}) => {
  const intl = useIntl();

  const alternatives = Object.values(Employment).map((employment) => {
    return {
      text: intl.formatMessage(employmentMessages[employment]),
      value: employment,
    } as Alternative<Employment>;
  });

  return (
    <Card>
      <RadioGroup
        header={intl.formatMessage(messages.employmentHeader)}
        onChange={(employment) => setEmployment(employment.value)}
        value={alternatives.find((alt) => employment === alt.value)}
        alternatives={alternatives}
        validators={[
          new RequiredValidator(
            intl.formatMessage(messages.employmentRequired)
          ),
        ]}
      />
    </Card>
  );
};
