import { useRef } from "react";
import * as React from "react";
import { Button } from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { AccountName } from "../../../components/accountName/AccountName";
import { Amount } from "../../../components/amount/Amount";
import { Change } from "../../../components/amount/Change";
import { TranslatedText } from "../../../components/TranslatedText";
import {
  InvestmentAccountId,
  InvestmentAccount,
} from "../../../data/dataAccounts";
import { dataBanks } from "../../../data/dataBanks";
import { dataPerformance } from "../../../data/dataPerformance";
import {
  PeriodicWithdrawal,
  PERIODIC_TYPE,
} from "../../../data/dataWithdrawals";
import {
  ConfirmActionOverlay,
  ConfirmRef,
} from "../../../components/confirmActionOverlay/ConfirmActionOverlay";

import "./PendingPeriodicWithdrawal.scss";

interface Props {
  pendingPeriodicWithdrawal: PeriodicWithdrawal;
  account?: InvestmentAccount;
  removePeriodic: (accountId: InvestmentAccountId) => void;
  periodicError: InvestmentAccountId | string;
}

const messages = defineMessages({
  [PERIODIC_TYPE.FIXED_AMOUNT]: {
    id: "withdrawals.periodic.fixed",
    description: "Withdrawals page - periodic withdrawal fixed amount",
    defaultMessage: "Fixed amount",
  },
  [PERIODIC_TYPE.PERCENTAGE]: {
    id: "withdrawals.periodic.percentage",
    description: "Withdrawals page - periodic withdrawal percentage",
    defaultMessage: "Yearly percentage",
  },
  [PERIODIC_TYPE.PROFIT]: {
    id: "withdrawals.periodic.profit",
    description: "Withdrawals page - periodic withdrawal profit",
    defaultMessage: "Profit",
  },
  [PERIODIC_TYPE.TARGET_DATE]: {
    id: "withdrawals.periodic.target",
    description: "Withdrawals page - periodic withdrawal target date",
    defaultMessage: "Target date",
  },
  info: {
    id: "withdrawals.periodic.remove.confirm",
  },
  cancelButton: {
    id: "withdrawals.periodic.abort",
  },
  confirmButton: {
    id: "withdrawals.periodic.removeConfirm",
  },
});

export const PendingPeriodicWithdrawal: React.FunctionComponent<Props> = ({
  pendingPeriodicWithdrawal,
  account,
  removePeriodic,
  periodicError,
}) => {
  const intl = useIntl();
  const confirmRef = useRef<ConfirmRef>();

  if (!account) {
    return null;
  }

  const bank = dataBanks.getBank(pendingPeriodicWithdrawal.bank);
  let elem = null;

  if (pendingPeriodicWithdrawal.type === PERIODIC_TYPE.FIXED_AMOUNT) {
    elem = (
      <li>
        <div className="pending-withdrawal-label">
          <TranslatedText
            id="withdrawals.periodic.amount"
            description="withdrawals page - periodic tag amount"
            defaultMessage="Amount"
          />
        </div>
        <div className="pending-withdrawal-bank-and-number">
          <Amount amount={pendingPeriodicWithdrawal.amount} />
        </div>
      </li>
    );
  } else if (pendingPeriodicWithdrawal.type === PERIODIC_TYPE.PERCENTAGE) {
    elem = (
      <li>
        <div className="pending-withdrawal-label">
          <TranslatedText
            id="withdrawals.periodic.percentage"
            description="Withdrawals page - periodic withdrawal percentage"
            defaultMessage="Yearly percentage"
          />
        </div>
        <div className="pending-withdrawal-bank-and-number">
          <Change change={pendingPeriodicWithdrawal.amount} digits={1} />
        </div>
      </li>
    );
  } else if (pendingPeriodicWithdrawal.type === PERIODIC_TYPE.TARGET_DATE) {
    elem = (
      <li>
        <div className="pending-withdrawal-label">
          <TranslatedText
            id="withdrawals.periodic.endDate"
            description="withdrawals page - periodic tag end date"
            defaultMessage="End date"
          />
        </div>
        <div className="pending-withdrawal-bank-and-number">
          {pendingPeriodicWithdrawal.endDate
            ? dataPerformance.toStringDate(
                dataPerformance.toJsDate(pendingPeriodicWithdrawal.endDate)
              )
            : "-"}
        </div>
      </li>
    );
  }

  return (
    <div className="pending-withdrawal pending-withdrawal-periodic">
      <ConfirmActionOverlay
        confirmRef={confirmRef}
        negative
        info={intl.formatMessage(messages.info)}
        cancelButtonText={intl.formatMessage(messages.cancelButton)}
        confirmButtonText={intl.formatMessage(messages.confirmButton)}
        errorMessage={
          periodicError === pendingPeriodicWithdrawal.accountId ? (
            <TranslatedText
              id="withdrawals.periodic.error"
              description="withdrawals page - remove periodic error message"
              defaultMessage="<bold>Ouch!</bold> Something went wrong."
            />
          ) : undefined
        }
        onConfirm={() => removePeriodic(pendingPeriodicWithdrawal.accountId)}
      >
        <ul>
          <li>
            <div className="pending-withdrawal-label">
              <TranslatedText
                id="withdrawals.periodic.from"
                description="withdrawals page - pending header from"
                defaultMessage="From"
              />
              :
            </div>
            <AccountName account={account} showAmount={true} showRisk={true} />
          </li>
          <li>
            <div className="pending-withdrawal-label">
              <TranslatedText
                id="withdrawals.periodic.to"
                description="withdrawals page - pending header to"
                defaultMessage="To"
              />
              :
            </div>
            <div className="pending-withdrawal-bank-and-number">
              {pendingPeriodicWithdrawal.externalBankAccount} -{" "}
              <strong>{bank.short}</strong>
            </div>
          </li>

          <li>
            <div className="pending-withdrawal-label">
              <TranslatedText
                id="withdrawals.periodic.plan"
                description="withdrawals page - pending periodic plan"
                defaultMessage="Plan:"
              />
            </div>
            <div className="pending-withdrawal-bank-and-number">
              {intl.formatMessage(messages[pendingPeriodicWithdrawal.type])}
            </div>
          </li>

          <li>
            <div className="pending-withdrawal-label">
              <TranslatedText
                id="withdrawals.periodic.next"
                description="withdrawals page - pending periodic next"
                defaultMessage="Next transaction:"
              />
            </div>
            <div className="pending-withdrawal-bank-and-number">
              {pendingPeriodicWithdrawal.nextDeduction}
            </div>
          </li>

          {elem}
        </ul>

        <div className="remove-button">
          <Button
            size="small"
            variant="negative"
            onClick={() => {
              confirmRef.current?.setConfirm(true);
            }}
            label={<TranslatedText id="withdrawals.periodic.remove" />}
          />
        </div>
      </ConfirmActionOverlay>
    </div>
  );
};
