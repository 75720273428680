import { useReducer } from "react";
import * as React from "react";
import { Thread } from "../data/dataMessages";

export interface MessagesState {
  messages: Thread[];
}

export interface MessagesContextProps {
  state: MessagesState;
  setState: (newState: Partial<MessagesState>) => void;
}

export const MessagesContext = React.createContext<MessagesContextProps>(
  {} as MessagesContextProps
);

function stateReducer(state: MessagesState, newState: Partial<MessagesState>) {
  return { ...state, ...newState };
}

export const MessagesContextProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {
    messages: [],
  });

  return (
    <MessagesContext.Provider value={{ state, setState }}>
      {children}
    </MessagesContext.Provider>
  );
};
