import { Button, CardList, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  VoidFunctionComponent,
} from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { generatePath, Link, useLocation } from "react-router-dom";
import { Retry, Status } from "../../components/retry/Retry";
import { RouteAwareToggle } from "../../components/route/RouteAwareToggle";
import {
  InvestmentAccount,
  CompoundAccountId,
  SavingsAccount,
} from "../../data/dataAccounts";
import {
  AccountShareByMe,
  dataAttorney,
  ShareAccountInvitationStatus,
  ShareAccountSentInvitation,
} from "../../data/dataAttorney";
import { getNavLink } from "../../hooks/useCountryUrls";
import { SHARE_ACCOUNT_SENT_INVITATION_URL } from "../shareAccountSentInvitation/ShareAccountSentInvitationPage";
import {
  ShareAccountLocationState,
  SHARE_ACCOUNT_URL,
} from "../shareAccountStory/ShareAccountStory";
import {
  SharedAccountsView,
  SHARED_ACCOUNTS_URL,
  SHARED_ACCOUNTS_VIEW_URL,
} from "../sharedAccountsPage/SharedAccountsPage";
import { CardBottom } from "./CardBottom";
import "./ShareAccountCard.scss";

interface Props {
  account?: InvestmentAccount | SavingsAccount;
  showAction?: boolean;
}

export const ShareAccountCard: VoidFunctionComponent<Props> = ({
  account,
  showAction = true,
}) => {
  const location = useLocation();
  const [shares, setShares] = useState<AccountShareByMe[]>([]);
  const [invitations, setInvitations] = useState<ShareAccountSentInvitation[]>(
    []
  );

  const [status, setStatus] = useState<Status>(Status.PENDING);
  const timer = useRef<number | undefined>();

  const load = useCallback((accountId: CompoundAccountId) => {
    Promise.all([
      dataAttorney.getAccountSharesByMe(),
      dataAttorney.getSentInvitations(),
    ])
      .then(([accountShares, invitations]) => {
        setShares(
          accountShares.filter((share) => share.accountId === accountId)
        );
        setInvitations(
          invitations
            .filter((invitation) => invitation.accountId === accountId)
            .filter(
              ({ status }) =>
                status === ShareAccountInvitationStatus.ACCEPTED_INVITEE
            )
        );
        setStatus(Status.SUCCESS);
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, []);

  useEffect(() => {
    if (!account) {
      return;
    }
    load(account.accountId);
  }, [account, load]);

  const retry = useCallback(() => {
    if (account) {
      setStatus(Status.PENDING);
      timer.current = window.setTimeout(() => {
        load(account.accountId);
      }, 500);
    }
  }, [account, load]);

  useEffect(() => {
    return () => {
      window.clearTimeout(timer.current);
    };
  }, []);

  return (
    <CardList className="account-page-share-account">
      <div className="account-page-card-body">
        <Retry retry={retry} status={status}>
          <>
            {shares.length > 0 || invitations.length > 0 ? (
              <>
                <strong>
                  <FormattedMessage id="accountPage.sharedAccount.sharesHeader" />
                </strong>
                <ul>
                  {invitations.map((share) => (
                    <li key={share.invitationId}>
                      <span>{share.inviteeName}</span>
                      <div>
                        <Button
                          component={Link}
                          to={{
                            pathname: getNavLink(
                              generatePath(SHARE_ACCOUNT_SENT_INVITATION_URL, {
                                invitationId: invitations[0].invitationId,
                              })
                            ),
                          }}
                          size="small"
                          label={
                            <FormattedMessage id="accountPage.sharedAccount.confirmInviteButton" />
                          }
                        />
                      </div>
                    </li>
                  ))}
                  {shares.slice(0, 3).map((share) => (
                    <li key={share.accountShareId}>
                      <span>{share.inviteeName}</span>
                      <div className="date">
                        <FormattedDate value={share.created} />
                      </div>
                    </li>
                  ))}

                  {shares.length > 2 && (
                    <li>
                      <FormattedMessage
                        id="accountPage.sharedAccount.additionalShares"
                        values={{
                          amount: shares.length - 3,
                        }}
                      />
                    </li>
                  )}
                </ul>
              </>
            ) : (
              <Snackbar type={SNACKBAR_TYPES.INFO}>
                <div style={{ textAlign: "center" }}>
                  <FormattedMessage id="accountPage.sharedAccount.noActiveShares" />
                </div>
              </Snackbar>
            )}
          </>
        </Retry>

        <Button
          component={Link}
          to={{
            pathname: getNavLink(SHARE_ACCOUNT_URL),
            state: {
              accountId: account?.accountId,
              returnUrl: location.pathname,
            } as ShareAccountLocationState,
          }}
          size="small"
          block
          label={
            <FormattedMessage id="accountPage.sharedAccount.createInvitationButton" />
          }
        />
      </div>
      {showAction && (
        <RouteAwareToggle path={getNavLink(SHARED_ACCOUNTS_URL)}>
          <CardBottom>
            <Link
              to={getNavLink(
                SHARED_ACCOUNTS_VIEW_URL(
                  SharedAccountsView.SHARED_BY_ME,
                  account?.accountId
                )
              )}
            >
              <FormattedMessage id="accountPage.sharedAccount.manageSharedAccount" />
            </Link>
          </CardBottom>
        </RouteAwareToggle>
      )}
    </CardList>
  );
};
