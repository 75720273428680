import { cache, encode } from "@lysaab/ui-2";
import { API } from "@lysaab/ui-2/network/API";
import { SavingsAccountId } from "./dataAccounts";
import {
  SavingsAccountDepositInterval,
  SavingsAccountPurpose,
  SavingsAccountWithdrawalInterval,
} from "./dataKyc";
import { queryClient } from "../ReactQueryProvider";

export type CreationId = string & { readonly isCreationId: true };

interface NewAccountResponse {
  creationId: CreationId;
}

interface KycAnswerRequest {
  version: "3";
  questionAnswers: [
    {
      question: "PURPOSE";
      answers: SavingsAccountPurpose[];
    },
    {
      question: "DEPOSIT_INTERVAL";
      answers: [SavingsAccountDepositInterval];
    },
    {
      question: "WITHDRAWAL_INTERVAL";
      answers: [SavingsAccountWithdrawalInterval];
    }
  ];
}

export interface NewAccountRequest {
  accountName: string;
  kyc: {
    accountKyc: KycAnswerRequest;
  };
}

interface CreationStatusResponse {
  isCompleted: boolean;
}

export interface EffectiveInterestRateResponse {
  interestRate: number;
  fee: number;
}

export interface EffectiveInterestRateWithAccountId
  extends EffectiveInterestRateResponse {
  accountId: SavingsAccountId;
}

export const dataSavingsAccount = {
  addNewAccount: (data: NewAccountRequest) => {
    return API.post<NewAccountResponse>("/savings-account/new", data).then(
      (response) => {
        cache.clear();
        queryClient.invalidateQueries();
        return response;
      }
    );
  },
  getNewAccountStatus: (creationId: CreationId) => {
    return API.get<CreationStatusResponse>(
      encode`/savings-account/new/status/${creationId}`,
      true
    );
  },
  getInterestRate: () => {
    return API.get<EffectiveInterestRateResponse>(
      `/savings-account/interest-rate/effective`
    );
  },

  getEffectiveInterestRateByAccount: (accountIds: SavingsAccountId[]) => {
    const params = new URLSearchParams();

    accountIds.forEach((accountId) => {
      params.append("accountIds", accountId);
    });
    return API.get<EffectiveInterestRateWithAccountId[]>(
      `/savings-account/interest-rate/effective?${params.toString()}`
    );
  },
};
