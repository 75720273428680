import { Fragment, useEffect, useState, VoidFunctionComponent } from "react";
import { defineMessages, useIntl } from "react-intl";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { Disclaimer } from "../disclaimer/Disclaimer";
import { AccountPageCard } from "../accountPageCard/AccountPageCard";
import { Snackbar, SNACKBAR_TYPES, Spinner } from "@lysaab/ui-2";
import { useLoadingContext } from "../../../../../context/LoadingContext";
import { SavingsAccountId } from "../../../../../data/dataAccounts";
import { Amount } from "../../../../../components/amount/Amount";
import {
  dataFees,
  SavingsAccountFeeResponse,
} from "../../../../../data/dataFees";
import { RouteAwareToggle } from "../../../../../components/route/RouteAwareToggle";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { Link } from "react-router-dom";
import { FEES_PAGE_URL } from "../../../../fees/FeesPage";

import "./FeesCard.scss";

const messages = defineMessages({
  details: {
    id: "savingsAccountPage.feesCard.lysa",
  },
});

interface Props {
  accountId: SavingsAccountId;
  showAction?: boolean;
}

export const FeesCard: VoidFunctionComponent<Props> = ({
  accountId,
  showAction = true,
}) => {
  const intl = useIntl();
  const { isLoading, setIsLoading } = useLoadingContext();
  const [savingsAccountFees, setsavingsAccountFees] =
    useState<SavingsAccountFeeResponse>();

  useEffect(() => {
    dataFees
      .getSavingsAccountFees(accountId)
      .then(setsavingsAccountFees)
      .finally(() => setIsLoading(false));
  }, [accountId, setIsLoading]);

  if (isLoading) {
    return <Spinner />;
  }

  if (typeof savingsAccountFees === "undefined") {
    return null;
  }

  return (
    <Fragment>
      <AccountPageCard
        className="fees-card"
        bottomLink={
          showAction ? (
            <RouteAwareToggle path={getNavLink(FEES_PAGE_URL)}>
              <Link to={getNavLink(FEES_PAGE_URL)}>
                <TranslatedText id="savingsAccountPage.feesCard.historicLink" />
              </Link>
            </RouteAwareToggle>
          ) : null
        }
      >
        <p className="fees-card__details-header">
          <TranslatedText
            id="savingsAccountPage.feesCard.paidFeesHeader"
            values={{
              year: intl.formatDate(new Date(), { year: "numeric" }),
            }}
          />
        </p>
        <div className="fees-card__details">
          <span>{intl.formatMessage(messages.details)}</span>
          <Amount amount={0} decimals={2} />
        </div>
        {savingsAccountFees && savingsAccountFees.rebate && (
          <Snackbar type={SNACKBAR_TYPES.SUCCESS}>
            <TranslatedText
              id="savingsAccountPage.feesCard.rebate"
              values={{
                discount: intl.formatNumber(savingsAccountFees.rebate / 100, {
                  style: "percent",
                }),
                endDate: savingsAccountFees.rebateExpiry
                  ? intl.formatDate(savingsAccountFees.rebateExpiry)
                  : "-",
              }}
            />
          </Snackbar>
        )}
        <p className="fees-card__details-header">
          <TranslatedText
            id="savingsAccountPage.feesCard.approximateHeader"
            values={{
              year: intl.formatDate(new Date(), { year: "numeric" }),
              feePercentage: intl.formatNumber(savingsAccountFees.cost, {
                style: "percent",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            }}
          />
        </p>
        <div className="fees-card__details">
          <span>{`${intl.formatMessage(messages.details)} - ${intl.formatNumber(
            savingsAccountFees.cost,
            {
              style: "percent",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}`}</span>
          <Amount amount={savingsAccountFees.future} decimals={2} />
        </div>
      </AccountPageCard>
      <Disclaimer>
        <TranslatedText id="savingsAccountPage.feesCard.disclaimer" />
      </Disclaimer>
    </Fragment>
  );
};
