import { Story } from "@lysaab/ui-2";
import { useCallback, useContext, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useLocation, generatePath } from "react-router";
import { Route } from "../../../../../components/route/Route";
import { Switch } from "../../../../../components/route/Switch";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { useAccountFilterParams } from "../../../../../hooks/useAccountFilterParams";
import { useSafeNavigation } from "../../../../../hooks/useSafeNavigation";
import { useStoryValues } from "../../../../../hooks/useStoryValues";
import { MONTHLY_DEPOSITS_URL } from "../../../../../pages/deposits/monthly/create/MonthlyStory";
import { OVERVIEW_PAGE_URL } from "../../../../../pages/overview/OverviewPage";
import { PageStripped } from "../../../../../pages/PageStripped";
import { KlarnaIntegrationClient } from "../../../../../utils/KlarnaIntegrationClient";
import { AccountLoadingWrapper } from "./accountLoading/AccountLoadingWrapper";
import { AccountSelection } from "./accountSelection/AccountSelection";
import { BankSelectionWrapper } from "./bankSelection/BankSelectionWrapper";
import { Confirm } from "./confirm/Confirm";
import { Confirm as TinkConfirm } from "./confirm/tink/Confirm";
import { Done } from "./done/Done";
import { ExternalAccountSelection } from "./externalAccountSelection/ExternalAccountSelection";
import { Intro } from "./Intro/Intro";
import { LysaAccountSelection } from "./lysaAccountSelection/LysaAccountSelection";
import { MonthlyAmount } from "./monthlyAmount/MonthlyAmount";
import { MonthlyContext, MonthlyContextProvider } from "./MonthlyContext";
import { Variant } from "@lysaab/shared";
import { experimentKeys } from "../../../../../experimentConfig";
import { TinkBankSelectionWrapper } from "./bankSelection/TinkBankSelectionWrapper";
import { TinkAccountLoadingWrapper } from "./accountLoading/TinkAccountLoadingWrapper";
import { TinkAccountSelection } from "./accountSelection/TinkAccountSelection";

export const BASE_ROUTES = {
  INTRO: `${MONTHLY_DEPOSITS_URL}`,
  LYSA_ACCOUNT: `${MONTHLY_DEPOSITS_URL}/lysa-account/:accountId?`,
  EXTERNAL_ACCOUNT: `${MONTHLY_DEPOSITS_URL}/external-account/`,
  BANK_SELECTION: `${MONTHLY_DEPOSITS_URL}/bank-selection`,
  ACCOUNT_LOADING: `${MONTHLY_DEPOSITS_URL}/account-loading`,
  ACCOUNT_SELECTION: `${MONTHLY_DEPOSITS_URL}/account-selection`,
  AMOUNT: `${MONTHLY_DEPOSITS_URL}/amount`,
  CONFIRM: `${MONTHLY_DEPOSITS_URL}/confirm`,
  DONE: `${MONTHLY_DEPOSITS_URL}/done`,
};

const messages = defineMessages({
  header: {
    id: "sweden.deposits.monthly.story.header",
  },
  ariaProgressLabel: {
    id: "sweden.deposits.monthly.story.ariaProgressLabel",
  },
});

function InternalMonthlyStory() {
  const monthlyContext = useContext(MonthlyContext);
  const location = useLocation();
  const safeNavigation = useSafeNavigation();
  const intl = useIntl();
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);
  const { hasAccountFilter, accountFilterParams } = useAccountFilterParams();

  useEffect(() => {
    KlarnaIntegrationClient.preLoad();
  }, []);

  const onBack = (currentIndex: number) => {
    if (currentIndex === 0) {
      return;
    } else if (location.pathname === ROUTES.ACCOUNT_SELECTION) {
      safeNavigation(ROUTES.EXTERNAL_ACCOUNT);
      return;
    } else if (location.pathname === ROUTES.AMOUNT) {
      if (monthlyContext.state.klarnaAccounts) {
        safeNavigation(ROUTES.ACCOUNT_SELECTION);
      } else {
        safeNavigation(ROUTES.EXTERNAL_ACCOUNT);
      }
      return;
    } else {
      safeNavigation(generatePath(Object.values(ROUTES)[currentIndex - 1]));
      return;
    }
  };

  const accountLoadingNext = useCallback(() => {
    safeNavigation(ROUTES.ACCOUNT_SELECTION);
  }, [ROUTES.ACCOUNT_SELECTION, safeNavigation]);

  return (
    <PageStripped>
      <div className="monthly-deposits">
        <Story
          ariaLabelProgress={() =>
            intl.formatMessage(messages.ariaProgressLabel, {
              current: currentIndex + 1,
              total: storyLength,
            })
          }
          header={intl.formatMessage(messages.header)}
          progress={storyProgress}
          showBack={
            location.pathname !== ROUTES.INTRO &&
            location.pathname !== ROUTES.DONE
          }
          showClose={true}
          transitionKey={currentIndex.toString()}
          onExit={() => safeNavigation(getNavLink(OVERVIEW_PAGE_URL))}
          onBack={() => onBack(currentIndex)}
        >
          <Switch
            location={location}
            {...{
              order: currentIndex,
            }}
          >
            <Route
              path={ROUTES.INTRO}
              exact
              render={() => (
                <Intro
                  next={() =>
                    safeNavigation({
                      pathname: generatePath(ROUTES.LYSA_ACCOUNT),
                      search: hasAccountFilter
                        ? accountFilterParams
                        : undefined,
                    })
                  }
                />
              )}
            />
            <Route
              path={ROUTES.LYSA_ACCOUNT}
              exact
              render={() => (
                <LysaAccountSelection
                  next={() => safeNavigation(ROUTES.EXTERNAL_ACCOUNT)}
                />
              )}
            />
            <Route
              path={ROUTES.EXTERNAL_ACCOUNT}
              exact
              render={() => (
                <ExternalAccountSelection
                  next={() => safeNavigation(generatePath(ROUTES.AMOUNT))}
                />
              )}
            />
            <Route
              path={ROUTES.BANK_SELECTION}
              exact
              render={() => (
                <>
                  <Variant
                    propertyKey={experimentKeys.TINK_ENABLED}
                    value="true"
                  >
                    <TinkBankSelectionWrapper
                      next={() =>
                        safeNavigation(generatePath(ROUTES.ACCOUNT_LOADING))
                      }
                    />
                  </Variant>
                  <Variant
                    propertyKey={experimentKeys.TINK_ENABLED}
                    value="false"
                  >
                    <BankSelectionWrapper
                      next={() =>
                        safeNavigation(generatePath(ROUTES.ACCOUNT_LOADING))
                      }
                    />
                  </Variant>
                </>
              )}
            />
            <Route
              path={ROUTES.ACCOUNT_LOADING}
              exact
              render={() => (
                <>
                  <Variant
                    propertyKey={experimentKeys.TINK_ENABLED}
                    value="true"
                  >
                    <TinkAccountLoadingWrapper next={accountLoadingNext} />
                  </Variant>
                  <Variant
                    propertyKey={experimentKeys.TINK_ENABLED}
                    value="false"
                  >
                    <AccountLoadingWrapper next={accountLoadingNext} />
                  </Variant>
                </>
              )}
            />
            <Route
              path={ROUTES.ACCOUNT_SELECTION}
              exact
              render={() => (
                <>
                  <Variant
                    propertyKey={experimentKeys.TINK_ENABLED}
                    value="true"
                  >
                    <TinkAccountSelection
                      next={() => safeNavigation(ROUTES.AMOUNT)}
                    />
                  </Variant>
                  <Variant
                    propertyKey={experimentKeys.TINK_ENABLED}
                    value="false"
                  >
                    <AccountSelection
                      next={() => safeNavigation(ROUTES.AMOUNT)}
                    />
                  </Variant>
                </>
              )}
            />
            <Route
              path={ROUTES.AMOUNT}
              exact
              render={() => (
                <MonthlyAmount next={() => safeNavigation(ROUTES.CONFIRM)} />
              )}
            />
            <Route
              path={ROUTES.CONFIRM}
              exact
              render={() => (
                <>
                  <Variant
                    propertyKey={experimentKeys.TINK_ENABLED}
                    value="true"
                  >
                    <TinkConfirm next={() => safeNavigation(ROUTES.DONE)} />
                  </Variant>
                  <Variant
                    propertyKey={experimentKeys.TINK_ENABLED}
                    value="false"
                  >
                    <Confirm next={() => safeNavigation(ROUTES.DONE)} />
                  </Variant>
                </>
              )}
            />
            <Route path={ROUTES.DONE} exact render={() => <Done />} />
          </Switch>
        </Story>
      </div>
    </PageStripped>
  );
}

export const MonthlyStory = () => (
  <MonthlyContextProvider>
    <InternalMonthlyStory />
  </MonthlyContextProvider>
);
