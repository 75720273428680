import { Button } from "@lysaab/ui-2";

import "./ShowMoreButton.scss";

interface Props {
  isOpen: boolean;
  onClick: () => void;
  openText: string;
  closedText: string;
}

export const ShowMoreButton = ({
  isOpen,
  onClick,
  openText,
  closedText,
}: Props) => {
  return (
    <Button
      size="small"
      onClick={onClick}
      className="show-more-button"
      label={isOpen ? closedText : openText}
      icon={isOpen ? "ChevronUp" : "ChevronDown"}
    />
  );
};
